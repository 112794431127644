import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonInput,
  IonButton,
  IonIcon,
  IonListHeader,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle
} from "@ionic/react";
import { trashOutline } from 'ionicons/icons';

import MESSAGE from '../../constances/Messages.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import { ToastStatus } from './../toasts/ToastStatus';
import { AlertStatus } from './../alerts/AlertStatus';
import { AlertPrompt } from '../alerts/AlertPrompt';

import CategoryService from "../../services/CategoryService";

export const CategoriesForm: React.FC<{
  data?: any;
  name?: string;
  isSubmit?: boolean;
  onDidSubmit?: (data?: any) => any;
}> = ({ data = null, name = '', isSubmit = false, onDidSubmit }) => {

  const [ready, setReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const [formGroup, setFormGroup] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const initialize = async () => {
    resetFormValues();
    setFormGroup(
      {
        name: register('name', {
          value: name ? name : data && data.name ? data.name : '',
          required: MESSAGE.error.input_required
        })
      }
    );
  }

  const remove = async (id: any, name: string) => {

    const success = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    setPresentAlertPrompt({
      isPresent: true,
      message: `${TEXT.removePrompt} "${name}"?`,
      onDismiss: (data: boolean) => {
        if (ready) {
          setPresentAlertPrompt(
            {
              isPresent: false,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          if (data) {
            CategoryService.delete(id).then(async (result: any) => {
              success(result);
            }).catch((error) => {
              setPresentToast({
                isPresent: true,
                status: false,
                message: error,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            });
          }
        }
      }
    });

  }

  const resetFormValues = () => {
    reset();
  }

  const onSubmit = async (value: any) => {

    setShowLoading(true);

    const onSuccess = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    const onError = (message: any) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: message,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit();
      }
    }

    if (data) {
      await CategoryService.update(data.id, value).then(async (result: any) => {
        onSuccess(result);
      }).catch((error) => {
        onError(error);
      });
    } else {
      await CategoryService.create(value).then(async (result: any) => {
        onSuccess(result);
        resetFormValues();
      }).catch((error) => {
        onError(error);
      });
    }

    setShowLoading(false);

  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
    if (onDidSubmit) {
      onDidSubmit();
    }
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit, onError)();
    }
  }, [isSubmit]);

  return (
    <>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <AlertPrompt
        isPresent={presentAlertPrompt.isPresent}
        message={presentAlertPrompt.message}
        onDismiss={presentAlertPrompt.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      {
        formGroup ?
          <form onSubmit={handleSubmit(onSubmit, onError)}>

            <IonGrid>
              <IonRow>
                <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>{TEXT.generalData}</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.categoryName}<span className='form-required'>*</span></IonLabel>
                        <IonInput type="text" {...formGroup.name}></IonInput>
                      </IonItem>

                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>

              {
                data ?
                  <IonRow className='ion-margin-top'>
                    <IonCol size='12' className='ion-text-center'>
                      <IonButton type="button" color='danger' size='default' onClick={() => { remove(data.id, data.name); }}>
                        <IonIcon slot="start" icon={trashOutline} />
                        {TEXT.removeCategory}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  : <></>
              }

            </IonGrid>

          </form>
          : <></>

      }

    </>
  );

};