import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonBackButton
} from "@ionic/react"
import { useState, useContext, useEffect } from "react";

import Authentication from "./providers/AuthenticationProvider";
import BUTTON from '../constances/Button.json';

interface MenuNameProps {
  menuName: string;
}

export const ToolbarWithBack: React.FC<MenuNameProps> = ({ menuName }) => {

  const [title, setTitle] = useState<string>('');

  const authenticationProvider = useContext(Authentication);

  useEffect(() => {
    if (authenticationProvider.vendor) {
      setTitle(authenticationProvider.vendor.name);
    }
  }, [authenticationProvider.vendor]);

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start" className="ion-hide-lg-up">
          <IonBackButton text={BUTTON.back} />
        </IonButtons>
        <IonTitle>{menuName}</IonTitle>
      </IonToolbar>
    </IonHeader>
  )
}