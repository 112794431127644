import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import {
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonInput,
  IonButton,
  IonIcon,
  IonButtons,
  IonCard,
  IonCardContent,
  IonModal,
  IonText,
  IonImg,
  IonSlide,
  IonSlides,
  IonLabel
} from "@ionic/react";
import {
  alertCircleOutline,
  archiveOutline,
  qrCodeOutline,
  trashOutline
} from 'ionicons/icons';

import MESSAGE from '../../constances/Messages.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import { ToastStatus } from '../toasts/ToastStatus';
import { AlertStatus } from '../alerts/AlertStatus';
import { AlertPrompt } from '../alerts/AlertPrompt';
import { ModalLocation } from '../modal/Location';
import { ModalLocationScanner } from '../modal/LocationScanner';

import ASNProductPutawayService from '../../services/ASNProductPutawayService';
import WithdrawProductTakeawayService from '../../services/WithdrawProductTakeawayService';
import NumberFormat from 'react-number-format';
import ProductWarehouseService from '../../services/ProductWarehouseService';

export const CountedForm: React.FC<{
  data?: any;
  isASN?: boolean;
  isWithdraw?: boolean;
  isSubmit?: boolean;
  isAppend?: boolean;
  onDidSubmit?: (data?: any) => any;
}> = ({ data = null, isASN = false, isWithdraw = false, isSubmit = false, isAppend = false, onDidSubmit }) => {

  const slideOptsImage = {
    initialSlide: 0
  };

  const [showLoading, setShowLoading] = useState(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const [ready, setReady] = useState(false);

  const { register, handleSubmit, setValue, getValues, trigger, clearErrors, reset, control, formState: { errors } } = useForm();
  const { fields, append, remove } = useFieldArray(
    {
      name: "items",
      control
    }
  );
  const [formGroup, setFormGroup] = useState<any>(null);

  const appendInput = async () => {
    append({
      location: '',
      quantity: ''
    });
  };

  const [modalLocationData, setModalLocationData] = useState<any>(null);
  const [modalScannerData, setModalScannerData] = useState<any>(null);

  const initialize = async () => {
    setFormGroup(
      {
        items: register('items', {
          value: []
        })
      }
    );
    if (data) {
      if (isASN) {
        loadASNPutawayProduct(data.id);
      } else if (isWithdraw) {
        loadWithdrawTakeawayProduct(data.id);
      }
    }
  }

  const resetFormValues = () => {
    reset();
  }

  const loadASNPutawayProduct = async (id: string) => {
    if (id) {
      await ASNProductPutawayService.list(
        id,
        null,
        null,
        null,
        null,
        null,
        null
      ).then(async (data: any) => {
        for (let item of data) {
          let locationCode: string = (item.warehouse ? item.warehouse : '')
            + '.' + (item.floor ? item.floor : '')
            + '.' + (item.aisle ? item.aisle : '')
            + '.' + (item.bay ? item.bay : '')
            + '.' + (item.level ? item.level : '')
            + '.' + (item.bin ? item.bin : '');
          append({
            location: locationCode,
            quantity: item.quantity
          });
        }

      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  const loadWithdrawTakeawayProduct = async (id: string) => {
    if (id) {
      await WithdrawProductTakeawayService.list(
        id,
        null,
        null,
        null,
        null,
        null,
        null,
        ['product_warehouse']
      ).then(async (data: any) => {
        for (let item of data) {
          let locationCode: string = '';
          if (item.product_warehouse_reference) {
            locationCode = (item.product_warehouse_reference.warehouse ? item.product_warehouse_reference.warehouse : '')
            + '.' + (item.product_warehouse_reference.floor ? item.product_warehouse_reference.floor : '')
            + '.' + (item.product_warehouse_reference.aisle ? item.product_warehouse_reference.aisle : '')
            + '.' + (item.product_warehouse_reference.bay ? item.product_warehouse_reference.bay : '')
            + '.' + (item.product_warehouse_reference.level ? item.product_warehouse_reference.level : '')
            + '.' + (item.product_warehouse_reference.bin ? item.product_warehouse_reference.bin : '');
          }
          append({
            location: locationCode,
            quantity: item.quantity
          });
        }

      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  const onSubmit = async (value: any) => {

    setShowLoading(true);

    const onSuccess = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    const onError = (message: any) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: message,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit();
      }
    }

    if (data) {
      if (isASN) {
        await ASNProductPutawayService.import(data.id, value).then(async (result: any) => {
          onSuccess(result);
        }).catch((error) => {
          onError(error);
        });
      } else if (isWithdraw) {
        await WithdrawProductTakeawayService.import(data.id, value).then(async (result: any) => {
          onSuccess(result);
        }).catch((error) => {
          onError(error);
        });
      }
    }

    setShowLoading(false);

  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
    if (onDidSubmit) {
      onDidSubmit();
    }
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    resetFormValues();
    initialize();
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit, onError)();
    }
  }, [isSubmit]);

  useEffect(() => {
    if (isAppend) {
      appendInput();
    }
  }, [isAppend]);

  return (
    <>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <AlertPrompt
        isPresent={presentAlertPrompt.isPresent}
        message={presentAlertPrompt.message}
        onDismiss={presentAlertPrompt.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      {
        formGroup ?
          <form onSubmit={handleSubmit(onSubmit, onError)}>

            {/* Card Content */}
            <IonGrid>
              {
                data ?
                  <IonRow>
                    <IonCol>
                      <div className='scanner-items ion-margin-top'>
                        <IonCard>
                          <IonCardContent>
                            <IonRow className='product-info small'>
                              <IonCol size="10">
                                <h3 className='ion-text-left'>{data.product_reference.name}</h3>
                                <IonText color="medium" className='ion-text-left'>
                                  {
                                    data.product_reference.category_reference ?
                                      <p>{TEXT.category}: {data.product_reference.category_reference.name}</p>
                                      : <></>
                                  }
                                  <p>{TEXT.barcode}: {data.product_reference.barcode}</p>
                                </IonText>
                              </IonCol>
                              <IonCol size='2'>
                                {
                                  data.product_reference.figure_reference && data.product_reference.figure_reference.length ?
                                    <IonSlides options={slideOptsImage} className="product-info-figure-slide">
                                      {data.product_reference.figure_reference.map((item: any, figureI: number) => {
                                        return (
                                          <IonSlide key={`image-slide-${data}-${figureI}`}>
                                            <div>
                                              <IonImg className='content profile-image' src={item.original} />
                                            </div>
                                          </IonSlide>
                                        )
                                      })}
                                    </IonSlides>
                                    : <></>
                                }
                                <h3 className='quantity text-danger ion-text-right'>
                                  <NumberFormat value={data.quantity} displayType={'text'} thousandSeparator={true}
                                    className="text-number" />
                                </h3>
                              </IonCol>
                            </IonRow>
                          </IonCardContent>
                        </IonCard>
                      </div>
                    </IonCol>
                  </IonRow>
                  :
                  <></>
              }
              {
                getValues('items').length ? 
                <IonRow className='ion-hide-md-down'>
                  <IonCol size='12' sizeMd='12'>
                    <IonCard className='table-header'>
                      <IonGrid>
                        <IonRow className='ion-align-items-center'>
                          <IonCol size='12' sizeMd='6'>
                            <IonLabel>{TEXT.location}</IonLabel>
                          </IonCol>
                          <IonCol size='12' sizeMd='4'>
                            <IonLabel>{TEXT.qty}</IonLabel>
                          </IonCol>
                          <IonCol size='12' sizeMd='2'>
                            <IonLabel>{TEXT.manage}</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCard>
                  </IonCol>
                </IonRow>
                : <></>
              }
              {fields.map((element, index: number) => (
                <IonRow key={element.id} >
                  <IonCol size='12'>
                    <IonCard>

                      <IonCardContent>
                        <IonGrid>
                          <IonRow>
                            <IonCol size='12' sizeMd='6'>
                              <IonLabel className='ion-hide-md-up'>{TEXT.location}</IonLabel>
                              <IonItem className="item-no-padding">
                                <IonIcon slot="start" size='small' icon={archiveOutline} />
                                <IonInput
                                  type='text'
                                  placeholder='Ex. W.F.A.B.L.B'
                                  readonly={true}
                                  {...register(`items.${index}.location`, {
                                    required: MESSAGE.error.input_required
                                  })}
                                  onClick={() => {
                                    setModalLocationData(index);
                                  }}>
                                </IonInput>
                                <IonButtons slot="end">
                                  <IonButton color="tertiary"
                                    onClick={() => setModalScannerData(index)}>
                                    <IonIcon slot="icon-only" icon={qrCodeOutline}></IonIcon>
                                  </IonButton>
                                </IonButtons>
                              </IonItem>
                              <ErrorMessage errors={errors} name={`items.${index}.location`}
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                }
                              />
                            </IonCol>

                            <IonCol size='12' sizeMd='4'>
                              <IonLabel className='ion-hide-md-up'>{TEXT.qty}</IonLabel>
                              <IonItem>
                                <IonInput
                                  type='number'
                                  min={1}
                                  placeholder={TEXT.qty}
                                  {...register(`items.${index}.quantity`, {
                                    required: MESSAGE.error.input_required
                                  })}
                                >
                                </IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name={`items.${index}.quantity`}
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                }
                              />
                            </IonCol>

                            <IonCol size='12' sizeMd='2'>
                              <IonButton fill='outline' color='danger' expand='block' onClick={() => {
                                remove(index);
                                trigger();
                              }}>
                                <IonIcon icon={trashOutline} color='danger' slot="start" size='small' />
                                {BUTTON.delete}
                              </IonButton>
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
              ))}

            </IonGrid>

            {/* Modal PutAway */}
            <IonModal canDismiss swipeToClose isOpen={modalLocationData || modalLocationData === 0 ? true : false}
              onDidDismiss={() => setModalLocationData(null)}>
              <ModalLocation
                reference={modalLocationData}
                lockWarehouse={false}
                currentLocationCode={getValues(`items.${modalLocationData}.location`)}
                onDismiss={async (locationData: any, reference: any) => {
                  if (locationData) {
                    if (isASN) {
                      const locationCode: string =
                        (locationData.warehouse ? locationData.warehouse : '')
                        + '.' + (locationData.floor ? locationData.floor : '')
                        + '.' + (locationData.aisle ? locationData.aisle : '')
                        + '.' + (locationData.bay ? locationData.bay : '')
                        + '.' + (locationData.level ? locationData.level : '')
                        + '.' + (locationData.bin ? locationData.bin : '');
                      if (reference || reference === 0) {
                        setValue(`items.${reference}.location`, locationCode);
                      }
                    } else if (isWithdraw) {
                      if (data) {
                        ProductWarehouseService.count(
                          true,
                          null,
                          null,
                          null,
                          data.product,
                          (locationData.floor ? locationData.floor : ''),
                          (locationData.aisle ? locationData.aisle : ''),
                          (locationData.bay ? locationData.bay : ''),
                          (locationData.level ? locationData.level : ''),
                          (locationData.bin ? locationData.bin : ''),
                          (locationData.warehouse ? locationData.warehouse : '')
                        ).then(async (productWarehouseData: any) => {
                          if (productWarehouseData > 0) {
                            const locationCode: string =
                              (locationData.warehouse ? locationData.warehouse : '')
                              + '.' + (locationData.floor ? locationData.floor : '')
                              + '.' + (locationData.aisle ? locationData.aisle : '')
                              + '.' + (locationData.bay ? locationData.bay : '')
                              + '.' + (locationData.level ? locationData.level : '')
                              + '.' + (locationData.bin ? locationData.bin : '');
                            if (reference || reference === 0) {
                              setValue(`items.${reference}.location`, locationCode);
                            }
                          } else {
                            setPresentToast({
                              isPresent: true,
                              status: false,
                              message: MESSAGE.error.product_location_not_found,
                              onDismiss: () => {
                                if (ready) {
                                  setPresentToast(
                                    {
                                      isPresent: false,
                                      status: presentToast.status,
                                      message: presentToast.message,
                                      onDismiss: presentToast.onDismiss
                                    }
                                  );
                                }
                              }
                            });
                          }
                        }).catch((error) => {
                          setPresentToast({
                            isPresent: true,
                            status: false,
                            message: error,
                            onDismiss: () => {
                              if (ready) {
                                setPresentToast(
                                  {
                                    isPresent: false,
                                    status: presentToast.status,
                                    message: presentToast.message,
                                    onDismiss: presentToast.onDismiss
                                  }
                                );
                              }
                            }
                          });
                        });
                      }
                    }
                  }
                  setModalLocationData(null);
                }}
              />
            </IonModal>

            {/* Scanner */}
            <IonModal canDismiss swipeToClose isOpen={modalScannerData || modalScannerData === 0 ? true : false}
              onDidDismiss={() => setModalScannerData(null)}>
              <ModalLocationScanner
                reference={modalScannerData}
                onDismiss={(locationCode: any = null, reference: any = null) => {
                  if (isASN) {
                    if (reference || reference === 0) {
                      setValue(`items.${reference}.location`, locationCode);
                    }
                  } else if (isWithdraw) {
                    if (data) {

                      const locationCodeParts: any = locationCode.split('.');

                      ProductWarehouseService.count(
                        true,
                        null,
                        null,
                        null,
                        data.product,
                        (locationCodeParts[1] ? locationCodeParts[1] : ''),
                        (locationCodeParts[2] ? locationCodeParts[2] : ''),
                        (locationCodeParts[3] ? locationCodeParts[3] : ''),
                        (locationCodeParts[4] ? locationCodeParts[4] : ''),
                        (locationCodeParts[5] ? locationCodeParts[5] : ''),
                        (locationCodeParts[0] ? locationCodeParts[0] : '')
                      ).then(async (productWarehouseData: any) => {
                        if (productWarehouseData > 0) {
                          if (reference || reference === 0) {
                            setValue(`items.${reference}.location`, locationCode);
                          }
                        } else {
                          setPresentToast({
                            isPresent: true,
                            status: false,
                            message: MESSAGE.error.product_location_not_found,
                            onDismiss: () => {
                              if (ready) {
                                setPresentToast(
                                  {
                                    isPresent: false,
                                    status: presentToast.status,
                                    message: presentToast.message,
                                    onDismiss: presentToast.onDismiss
                                  }
                                );
                              }
                            }
                          });
                        }
                      }).catch((error) => {
                        setPresentToast({
                          isPresent: true,
                          status: false,
                          message: error,
                          onDismiss: () => {
                            if (ready) {
                              setPresentToast(
                                {
                                  isPresent: false,
                                  status: presentToast.status,
                                  message: presentToast.message,
                                  onDismiss: presentToast.onDismiss
                                }
                              );
                            }
                          }
                        });
                      });
                    }
                  }
                  setModalScannerData(null);
                }}
              />
            </IonModal>

          </form>
          : <></>

      }

    </>
  );

};