import '../css/Menu.scss';

import { useContext, useEffect, useState } from 'react';

import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonPopover,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';
import {
  storefront,
  reader,
  arrowRedoCircle,
  arrowUndoCircleSharp,
  backspace,
  logOut,
  rocket,
  settings,
  appsOutline,
  cubeOutline,
  storefrontOutline,
  bulbOutline,
  briefcaseOutline,
  pricetagOutline,
  shieldOutline,
  paperPlaneOutline,
  documentOutline,
  clipboardOutline,
  personOutline,
  callOutline,
  arrowRedo,
  cartOutline,
  personCircle,
  timeOutline
} from 'ionicons/icons';

import packageJson from '../../package.json';
import MENU from '../constances/Menu.json';
import TEXT from '../constances/Text.json';
import MESSAGE from '../constances/Messages.json';
import BUTTON from '../constances/Button.json';
import { RoutePath } from './RoutePath';
import { Imagires } from './ImageVariable';

import Authentication from './providers/AuthenticationProvider';

import UserService from '../services/UserService';
import VendorService from '../services/VendorService';

interface Menu {
  url: string;
  splitText: string;
  activeCheck: any;
  iosIcon: string;
  mdIcon: string;
  title: string;
  module: string | null;
  handler: any;
}

const Menu: React.FC = () => {

  const history = useHistory();

  const location = useLocation();

  const authenticationProvider = useContext(Authentication);

  const [user, setUser] = useState<any>(null);
  const [vendor, setVendor] = useState<any>(null);
  const [members, setMembers] = useState<Array<any>>([]);

  const [presentAlert, dismissAlert] = useIonAlert();

  const CheckUrlActive = (path: string, set: string) => {
    if (path === '/' + set) {
      return true;
    } else {
      return false;
    }
  }

  const CheckUrlActive2 = (path: string, set: string) => {
    const splitLocation = path.split("/");
    const locationCheck = splitLocation[1] + '/' + splitLocation[2];

    if (locationCheck === set) {
      return true;
    } else {
      return false;
    }
  }

  const initialize = async (user: any = null) => {
    let userData: any = user;
    if (!userData) {
      userData = await UserService.getSession();
    }
    if (userData) {
      VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
      }).catch(() => { });
    }
  }

  const menu: Menu[] = [
    // {
    //   title: MENU.dashboard,
    //   url: RoutePath.home,
    //   splitText: '',
    //   activeCheck: CheckUrlActive,
    //   iosIcon: storefront,
    //   mdIcon: storefront,
    //   module: null,
    //   handler: () => { }
    // },
    {
      title: MENU.product,
      url: RoutePath.product,
      splitText: 'products',
      activeCheck: CheckUrlActive,
      iosIcon: reader,
      mdIcon: reader,
      module: '101',
      handler: () => { }
    },
    {
      title: MENU.productImport,
      url: RoutePath.productImport,
      splitText: 'products/import',
      activeCheck: CheckUrlActive2,
      iosIcon: arrowRedoCircle,
      mdIcon: arrowRedoCircle,
      module: '111',
      handler: () => { }
    },
    {
      title: MENU.productHistory,
      url: RoutePath.productHistory,
      splitText: 'products/history',
      activeCheck: CheckUrlActive2,
      iosIcon: rocket,
      mdIcon: rocket,
      module: '114',
      handler: () => { }
    },
    {
      title: MENU.productCancel,
      url: RoutePath.productCancel,
      splitText: 'products/cancel',
      activeCheck: CheckUrlActive2,
      iosIcon: arrowRedo,
      mdIcon: arrowRedo,
      module: '115',
      handler: () => { }
    },
    {
      title: MENU.productWithdraw,
      url: RoutePath.productWithdraw,
      splitText: 'products/withdraw',
      activeCheck: CheckUrlActive2,
      iosIcon: arrowUndoCircleSharp,
      mdIcon: arrowUndoCircleSharp,
      module: '112',
      handler: () => { }
    }
  ];

  const menuAdmin: Menu[] = [
    {
      title: MENU.job,
      url: `${RoutePath.job}`,
      splitText: 'jobs',
      activeCheck: CheckUrlActive,
      iosIcon: clipboardOutline,
      mdIcon: clipboardOutline,
      module: '114',
      handler: () => { }
    },
    {
      title: MENU.pick,
      url: RoutePath.pick,
      splitText: 'pick',
      activeCheck: CheckUrlActive,
      iosIcon: cartOutline,
      mdIcon: cartOutline,
      module: '109',
      handler: () => { }
    },
    {
      title: MENU.courierNotify,
      url: RoutePath.courierNotify,
      splitText: 'courier',
      activeCheck: CheckUrlActive,
      iosIcon: callOutline,
      mdIcon: callOutline,
      module: '109',
      handler: () => { }
    },
    {
      title: MENU.order,
      url: RoutePath.order,
      splitText: 'orders',
      activeCheck: CheckUrlActive,
      iosIcon: documentOutline,
      mdIcon: documentOutline,
      module: '110',
      handler: () => { }
    },
    {
      title: MENU.sendParcel,
      url: RoutePath.sendParcel,
      splitText: 'pack',
      activeCheck: CheckUrlActive,
      iosIcon: paperPlaneOutline,
      mdIcon: paperPlaneOutline,
      module: '109',
      handler: () => { }
    },
    {
      title: MENU.warehouse,
      url: RoutePath.warehouse,
      splitText: 'warehouses',
      activeCheck: CheckUrlActive,
      iosIcon: cubeOutline,
      mdIcon: cubeOutline,
      module: '118',
      handler: () => { }
    },
    {
      title: MENU.vendor,
      url: RoutePath.vendor,
      splitText: 'vendors',
      activeCheck: CheckUrlActive,
      iosIcon: storefrontOutline,
      mdIcon: storefrontOutline,
      module: '108',
      handler: () => { }
    },
    {
      title: MENU.manufacturer,
      url: RoutePath.manufacturer,
      splitText: 'manufacturers',
      activeCheck: CheckUrlActive,
      iosIcon: bulbOutline,
      mdIcon: bulbOutline,
      module: '106',
      handler: () => { }
    },
    {
      title: MENU.distributor,
      url: RoutePath.distributor,
      splitText: 'distributors',
      activeCheck: CheckUrlActive,
      iosIcon: briefcaseOutline,
      mdIcon: briefcaseOutline,
      module: '107',
      handler: () => { }
    },
    {
      title: MENU.brand,
      url: RoutePath.brand,
      splitText: 'brands',
      activeCheck: CheckUrlActive,
      iosIcon: pricetagOutline,
      mdIcon: pricetagOutline,
      module: '119',
      handler: () => { }
    },
    {
      title: MENU.category,
      url: RoutePath.category,
      splitText: 'categories',
      activeCheck: CheckUrlActive,
      iosIcon: appsOutline,
      mdIcon: appsOutline,
      module: '119',
      handler: () => { }
    },
    {
      title: MENU.administrator,
      url: RoutePath.administrator,
      splitText: 'administrators',
      activeCheck: CheckUrlActive,
      iosIcon: personOutline,
      mdIcon: personOutline,
      module: '6',
      handler: () => { }
    },
    {
      title: MENU.log,
      url: RoutePath.log,
      splitText: 'logs',
      activeCheck: CheckUrlActive,
      iosIcon: timeOutline,
      mdIcon: timeOutline,
      module: '11',
      handler: () => { }
    }
  ];

  const logout = () => {
    let option: any = {
      message: MESSAGE.info.logout,
      translucent: true,
      buttons: [
        {
          text: BUTTON.cancel,
          role: 'cancel'
        },
        {
          text: BUTTON.ok,
          handler: () => {
            UserService.logout().then(() => {
              authenticationProvider.update(false);
              authenticationProvider.setUser(null);
              history.replace(RoutePath.index);
            }).catch(() => { });
          }
        }
      ],
      cssClass: 'error'
    }
    presentAlert(option);
  }

  useEffect(() => {
    if (authenticationProvider.user) {
      setUser(authenticationProvider.user);
      initialize(authenticationProvider.user);
      if (
        authenticationProvider.user.members
        && authenticationProvider.user.members.length
      ) {
        setMembers(
          authenticationProvider.user.members.map(
            ({ group_id }: any) => (
              group_id
            )
          )
        );
      }
    }
  }, [authenticationProvider.user]);

  return (
    <IonMenu contentId="main" type="overlay">

      <IonHeader>
        <IonToolbar>
          <div className="logo-top">
            <IonImg src={Imagires.logoText}></IonImg>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        <IonList id="inbox-list">
          {
            (
              menu.length
              && vendor
            ) ?
              <>
                <IonListHeader>
                  <IonItem id="vendor-menu">
                    {/* <IonIcon icon={personOutline}></IonIcon> */}
                    <IonLabel className='clickable'>
                      <h2><b>{vendor.name}</b></h2>
                    </IonLabel>
                  </IonItem>
                  <IonPopover trigger="vendor-menu" triggerAction="click">
                    <IonContent class="ion-padding">{TEXT.vendorCode}: {vendor.id}</IonContent>
                  </IonPopover>
                </IonListHeader>
                {
                  menu.map((item, index: number) => {
                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        {
                          (!item.module
                            || (
                              user && user.controls
                              && user.controls[item.module]
                              && (
                                user.controls[item.module].create
                                || user.controls[item.module].delete
                                || user.controls[item.module].update
                                || user.controls[item.module].view
                              )
                            )
                          ) ?
                          <IonItem button lines="none" detail={false}
                            className={item.activeCheck(location.pathname, item.splitText) === true ? 'selected' : ''}
                            routerLink={item.url} routerDirection="none" onClick={item.handler}>
                            <IonIcon slot="start" ios={item.iosIcon} md={item.mdIcon} />
                            <IonLabel className='text-full'>{item.title}</IonLabel>
                          </IonItem>
                          : <></>
                        }
                      </IonMenuToggle>
                    );
                  })
                }
              </>
              : <></>
          }
          {
            (
              menuAdmin.length
              && members.length
              && (
                members.includes('1')
                || members.includes('2')
                || members.includes('3')
                || members.includes('5')
              )
            ) ?
              <>
                <IonListHeader>
                  <IonItem>
                    <IonLabel>
                      <h2>
                        {MENU.administration}
                      </h2>
                    </IonLabel>
                  </IonItem>
                </IonListHeader>
                {
                  menuAdmin.map((item, index: number) => {
                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        {
                          (!item.module
                            || (
                              user && user.controls
                              && user.controls[item.module]
                              && (
                                user.controls[item.module].create
                                || user.controls[item.module].delete
                                || user.controls[item.module].update
                                || user.controls[item.module].view
                              )
                            )
                          ) &&
                          <IonItem button lines="none" detail={false}
                            className={item.activeCheck(location.pathname, item.splitText) === true ? 'selected' : ''}
                            routerLink={item.url} routerDirection="none" onClick={item.handler}>
                            <IonIcon slot="start" ios={item.iosIcon} md={item.mdIcon} />
                            <IonLabel className='text-full'>{item.title}</IonLabel>
                          </IonItem>
                        }
                      </IonMenuToggle>
                    );
                  })
                }
              </>
              : <></>
          }
          <IonMenuToggle autoHide={false}>
            <IonItem button lines="none" detail={false}
              className={CheckUrlActive(location.pathname, 'setting') === true ? 'selected' : ''}
              routerLink={RoutePath.setting} routerDirection="none">
              <IonIcon slot="start" ios={settings} md={settings} />
              <IonLabel>{MENU.setting}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem button lines="none" detail={false}
              className={CheckUrlActive(location.pathname, 'logout') === true ? 'selected' : ''}
              onClick={() => logout()}>
              <IonIcon slot="start" ios={logOut} md={logOut} />
              <IonLabel>{MENU.logout}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>

        <p className='version ion-text-center'>{TEXT.version} {packageJson.version}</p>

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
