import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle
} from "@ionic/react"
import { useContext, useEffect, useState } from "react";

import Authentication from "./providers/AuthenticationProvider";

interface MenuNameProps {
  menuName: string;
}

export const ToolbarStart: React.FC<MenuNameProps> = ({ menuName }) => {

  const [title, setTitle] = useState<string>('');

  const authenticationProvider = useContext(Authentication);

  useEffect(() => {
    if (authenticationProvider.vendor) {
      setTitle(authenticationProvider.vendor.name);
    }
  }, [authenticationProvider.vendor]);

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>{menuName}</IonTitle>
      </IonToolbar>
    </IonHeader>
  )
}