import {
  IonRouterOutlet,
  IonSplitPane
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";

import UserService from '../services/UserService';
import VendorService from '../services/VendorService';
import StorageService from '../services/StorageService';

import Authentication from '../components/providers/AuthenticationProvider';

import { RoutePath, RoutePathPublic } from '../components/RoutePath';

import Menu from '../components/Menu';

import { AlertPrompt } from '../components/alerts/AlertPrompt';
import MESSAGE from '../constances/Messages.json';

import Home from './Home';
import Products from './Products';
import ProductsAdd from './ProductsAdd';
import ProductEdit from './ProductsEdit';
import ProductLot from './ProductsLot';
import ProductVIew from './ProductsView';
import Setting from './Setting';
import Test from './Test';
import ASNs from './ASNs';
import ASNsAdd from './ASNsAdd';
import ProductHistory from './ProductHistory';
import ProductHistoryView from './ProductHistoryView';
import ProductCancel from './ProductCancel';
import ASNsView from './ASNsView';
import Categories from './Categories';
import Brands from './Brands';
import BrandsAdd from './BrandsAdd';
import BrandsEdit from './BrandsEdit';
import ProductCancelAdd from './ProductCancelAdd';
import ProductCancelView from './ProductCancelView';
import ProductCancelAddCheck from './ProductCancelAddCheck';
import CategoriesAdd from './CategoriesAdd';
import CategoriesEdit from './CategoriesEdit';
import SendParcel from './SendParcel';
import SendParcelAdd from './SendParcelAdd';
import SendParcelAddCheck from './SendParcelAddCheck';
import SendParcelView from './SendParcelView';
import Orders from './Orders';
import OrdersView from './OrdersView';
import Warehouses from './Warehouses';
import WarehousesAdd from './WarehousesAdd';
import WarehousesEdit from './WarehousesEdit';
import WarehouseProducts from './WarehouseProducts';
import Jobs from './Jobs';
import JobsView from './JobsView';
import SendParcelEdit from './SendParcelEdit';
import Administrators from './Administrators';
import AdministratorsAdd from './AdministratorsAdd';
import AdministratorsEdit from './AdministratorsEdit';
import CourierNotify from './CourierNotify';
import Vendors from './Vendors';
import Manufacturers from './Manufacturers';
import Distributors from './Distributors';
import Withdraws from './Withdraws';
import WithdrawsAdd from './WithdrawsAdd';
import WithdrawsView from './WithdrawsView';
import TransfersAdd from './TransfersAdd';
import TransfersEdit from './TransfersEdit';
import Login from './Login';
import ProductCancelEdit from './ProductCancelEdit';
import ASNsEdit from './ASNsEdit';
import Pick from './Pick';
import WithdrawsEdit from './WithdrawsEdit';
import DistributorsAdd from './DistributorsAdd';
import DistributorsEdit from './DistributorsEdit';
import ManufacturersAdd from './ManufacturersAdd';
import ManufacturersEdit from './ManufacturersEdit';
import VendorsAdd from './VendorsAdd';
import VendorsEdit from './VendorsEdit';
import Logs from './Logs';

const Index: React.FC = () => {

  const history = useHistory();
  const location = useLocation();

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const authenticationProvider = useContext(Authentication);

  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const initialize = async () => {
    setInitialized(false);
    await checkAuthentication();
    setInitialized(true);
  }

  const checkAuthentication = async () => {
    if (await StorageService.get('token')) {
      await UserService.authenticate().then(async () => {
        authenticationProvider.update(true);
        const userData: any = await UserService.getSession();
        authenticationProvider.setUser(userData);
        if (userData) {
          let members = userData.members.map(
            ({ group_id }: any) => (
              group_id
            )
          );
          await VendorService.getByUser(userData.id).then((vendorData: any) => {
            if (vendorData) {
              authenticationProvider.setVendor(vendorData);
            }
          }).catch(() => { });
          if (location.pathname === '/') {
            if (
              members.length && (
                members.includes('1')
                || members.includes('2')
                || members.includes('3')
              )
            ) {
              history.replace(`${RoutePath.job}`);
            }
          }
        }
      }).catch(() => {
        setPresentAlert({
          isPresent: true,
          message: MESSAGE.error.error_require_logout,
          onDismiss: (data: any) => {
            if (ready) {
              if (data) {
                UserService.logout().catch(() => { });
                authenticationProvider.update(false);
                authenticationProvider.setUser(null);
                if (!RoutePathPublic.includes(location.pathname)) {
                  history.replace(RoutePath.index);
                }
              }
              setPresentAlert(
                {
                  isPresent: false,
                  message: presentAlert.message,
                  onDismiss: presentAlert.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  useEffect(() => {
    setAuthenticated(authenticationProvider.status);
  }, [authenticationProvider.status]);

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  return (
    <>
      {
        authenticated ?
          <IonSplitPane contentId="main">

            <AlertPrompt
              isPresent={presentAlert.isPresent}
              message={presentAlert.message}
              onDismiss={presentAlert.onDismiss}
            />

            <Menu />

            <IonRouterOutlet id="main">

              {/* Home */}
              <Route path={RoutePath.home} exact={true} component={Home}></Route>
              <Redirect exact from={RoutePath.home} to={RoutePath.product} />

              {/* Product */}
              <Route path={RoutePath.product} exact={true} component={Products}></Route>
              <Route path={RoutePath.productAdd} exact={true} component={ProductsAdd}></Route>
              <Route path={RoutePath.productView} exact={true} component={ProductVIew}></Route>
              <Route path={RoutePath.productEdit} exact={true} component={ProductEdit}></Route>
              <Route path={RoutePath.productLot} exact={true} component={ProductLot}></Route>

              {/* ASN */}
              <Route path={RoutePath.productImport} exact={true} component={ASNs}></Route>
              <Route path={RoutePath.productImportAdd} exact={true} component={ASNsAdd}></Route>
              <Route path={RoutePath.productImportEdit} exact={true} component={ASNsEdit}></Route>
              <Route path={RoutePath.productImportView} exact={true} component={ASNsView}></Route>

              {/* History */}
              <Route path={RoutePath.productHistory} exact={true} component={ProductHistory}></Route>
              <Route path={RoutePath.productHistoryView} exact={true} component={ProductHistoryView}></Route>

              {/* Cancel */}
              <Route path={RoutePath.productCancel} exact={true} component={ProductCancel}></Route>
              <Route path={RoutePath.productCancelAdd} exact={true} component={ProductCancelAdd}></Route>
              <Route path={RoutePath.productCancelEdit} exact={true} component={ProductCancelEdit}></Route>
              <Route path={RoutePath.productCancelAddCheck} exact={true} component={ProductCancelAddCheck}></Route>
              <Route path={RoutePath.productCancelView} exact={true} component={ProductCancelView}></Route>

              {/* Withdraw */}
              <Route path={RoutePath.productWithdraw} exact={true} component={Withdraws}></Route>
              <Route path={RoutePath.productWithdrawAdd} exact={true} component={WithdrawsAdd}></Route>
              <Route path={RoutePath.productWithdrawEdit} exact={true} component={WithdrawsEdit}></Route>
              <Route path={RoutePath.productWithdrawView} exact={true} component={WithdrawsView}></Route>

              {/* Setting */}
              <Route path={RoutePath.setting} exact={true} component={Setting}></Route>

              {/* Categories */}
              <Route path={RoutePath.category} exact={true} component={Categories}></Route>
              <Route path={RoutePath.categoryAdd} exact={true} component={CategoriesAdd}></Route>
              <Route path={RoutePath.categoryEdit} exact={true} component={CategoriesEdit}></Route>

              {/* Brands */}
              <Route path={RoutePath.brand} exact={true} component={Brands}></Route>
              <Route path={RoutePath.brandAdd} exact={true} component={BrandsAdd}></Route>
              <Route path={RoutePath.brandEdit} exact={true} component={BrandsEdit}></Route>

              {/* Jobs */}
              <Route path={RoutePath.job} exact={true} component={Jobs}></Route>
              {/* <Route path={RoutePath.jobAdd} exact={true} component={JobsAdd}></Route> */}
              {/* <Route path={RoutePath.jobEdit} exact={true} component={JobsEdit}></Route> */}
              <Route path={RoutePath.jobView} exact={true} component={JobsView}></Route>
              <Route path={RoutePath.orderPackAdd} exact={true} component={SendParcelAdd}></Route>
              <Route path={RoutePath.orderPackEdit} exact={true} component={SendParcelEdit}></Route>

              {/* Warehouses */}
              <Route path={RoutePath.warehouse} exact={true} component={Warehouses}></Route>
              <Route path={RoutePath.warehouseAdd} exact={true} component={WarehousesAdd}></Route>
              <Route path={RoutePath.warehouseEdit} exact={true} component={WarehousesEdit}></Route>
              <Route path={RoutePath.warehouseProducts} exact={true} component={WarehouseProducts}></Route>

              {/* Send Parcel */}
              <Route path={RoutePath.sendParcel} exact={true} component={SendParcel}></Route>
              <Route path={RoutePath.sendParcelAdd} exact={true} component={SendParcelAdd}></Route>
              <Route path={RoutePath.sendParcelAddCheck} exact={true} component={SendParcelAddCheck}></Route>
              <Route path={RoutePath.sendParcelView} exact={true} component={SendParcelView}></Route>

              {/* Orders */}
              <Route path={RoutePath.order} exact={true} component={Orders}></Route>
              <Route path={RoutePath.orderView} exact={true} component={OrdersView}></Route>

              {/* Administrator */}
              <Route path={RoutePath.administrator} exact={true} component={Administrators}></Route>
              <Route path={RoutePath.administratorAdd} exact={true} component={AdministratorsAdd}></Route>
              <Route path={RoutePath.administratorEdit} exact={true} component={AdministratorsEdit}></Route>

              {/* Courier Notify */}
              <Route path={RoutePath.courierNotify} exact={true} component={CourierNotify}></Route>

              {/* Vendors */}
              <Route path={RoutePath.vendor} exact={true} component={Vendors}></Route>
              <Route path={RoutePath.vendorAdd} exact={true} component={VendorsAdd}></Route>
              <Route path={RoutePath.vendorEdit} exact={true} component={VendorsEdit}></Route>

              {/* Manufacturers */}
              <Route path={RoutePath.manufacturer} exact={true} component={Manufacturers}></Route>
              <Route path={RoutePath.manufacturerAdd} exact={true} component={ManufacturersAdd}></Route>
              <Route path={RoutePath.manufacturerEdit} exact={true} component={ManufacturersEdit}></Route>

              {/* Distributors */}
              <Route path={RoutePath.distributor} exact={true} component={Distributors}></Route>
              <Route path={RoutePath.distributorAdd} exact={true} component={DistributorsAdd}></Route>
              <Route path={RoutePath.distributorEdit} exact={true} component={DistributorsEdit}></Route>

              {/* Transfer */}
              <Route path={RoutePath.transferAdd} exact={true} component={TransfersAdd}></Route>
              <Route path={RoutePath.transferEdit} exact={true} component={TransfersEdit}></Route>

              {/* Test */}
              <Route path="/test" exact={true} component={Test}></Route>

              {/* Pick */}
              <Route path={RoutePath.pick} exact={true} component={Pick}></Route>

              {/* Log */}
              <Route path={RoutePath.log} exact={true} component={Logs}></Route>

            </IonRouterOutlet>

          </IonSplitPane>
          :
          initialized &&
          <Login />
      }
    </>
  );
};

export default Index;
