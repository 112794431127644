import { 
  URL_LOG,
} from '../constances/Rest';

import axios from '../configs/axios';

import RestService from './RestService';

const LogService = {
  list: async (
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    module: string|null = null,
    user: string|null = null,
    dateFrom: any = null,
    dateTo: any = null,
    returnReferences: any = false
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (module) {
        options.module_id = module;
			}
			if (user) {
        options.user_id = user;
			}
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (dateFrom) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '>=',
            value: `'${dateFrom}'`
          }
        );
        conjunction = 'AND';
			}
			if (dateTo) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '<=',
            value: `'${dateTo}'`
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_LOG}list${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  count: async (
    start: number|null = null,
    limit: number|null = null,
    module: string|null = null,
    user: string|null = null,
    dateFrom: any = null,
    dateTo: any = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (module) {
        options.module_id = module;
			}
			if (user) {
        options.user_id = user;
			}
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (dateFrom) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '>=',
            value: `'${dateFrom}'`
          }
        );
        conjunction = 'AND';
			}
			if (dateTo) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '<=',
            value: `'${dateTo}'`
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_LOG}count${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response.result);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  }
};

export default LogService;