import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";

import {
  IonPage,
  IonContent,
  IonCol,
  IonRow,
  IonLabel,
  IonButton,
  IonIcon,
  IonGrid,
  IonButtons,
  IonItem,
  IonToolbar,
  IonInput,
  useIonAlert,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonCard,
  IonCardContent,
  IonTitle,
  IonModal,
  IonHeader,
  IonList,
  IonListHeader,
  IonTextarea,
  IonBadge,
  IonFooter
} from "@ionic/react";
import { 
  addCircle, 
  banOutline, 
  callOutline, 
  closeOutline, 
  paperPlaneOutline, 
  removeCircle, 
  searchOutline 
} from "ionicons/icons";

import { ToolbarStart } from "../components/ToolbarStart";
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import MESSAGE from '../constances/Messages.json';

import { ToastStatus } from "../components/toasts/ToastStatus";
import { RoutePath } from "../components/RoutePath";

import moment from "moment";

import CourierService from "../services/CourierService";
import UtilitiesService from "../services/UtilitiesService";
import LocationService from '../services/LocationService';
import WarehouseService from "../services/WarehouseService";
import { ModalWarehouses } from "../components/modal/Warehouses";
import NumberFormat from "react-number-format";

const CourierNotify: React.FC = () => {

  const [modalNotifyIsOpen, setModalNotifyIsOpen] = useState<boolean>(false);
  const [modalWarehousesIsOpen, setModalWarehousesIsOpen] = useState<boolean>(false);

  const [couriers, setCouriers] = useState<any>([]);
  const [notifications, setNotifications] = useState<any>([]);
  const [warehouseAddress, setWarehouseAddress] = useState<any>(null);
  const [courierWarehouses, setCourierWarehouses] = useState<any>([]);
  const [specificAddress, setSpecificAddress] = useState<boolean>(true);

  const defaultCountry: string = 'TH';
  
  const [countries, setCountries] = useState<Array<any>>([]);
  const [provinces, setProvinces] = useState<Array<any>>([]);
  const [cities, setCities] = useState<Array<any>>([]);
  const [subDistricts, setSubDistricts] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState<boolean>(false);

  const { register, formState: { errors }, setValue, getValues } = useForm({
    mode: 'onChange',
  });
  const [formGroup, setFormGroup] = useState<any>(null);
  const [formGroupNotify, setFormGroupNotify] = useState<any>(null);

  const [presentAlert] = useIonAlert();
  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  const location = useLocation();

  const initialize = async () => {
    if (!initialized) {

      setInitialized(false);

      const urlParams = new URLSearchParams(location.search);
      const courierParam = urlParams.get('courier')
      if (courierParam) {
        await setValue('courier', courierParam);
      }
      const dateParam = urlParams.get('date')
      if (dateParam) {
        await setValue('date', dateParam);
      }

      await load(courierParam ? courierParam : '1', dateParam ? dateParam : moment().format('YYYY-MM-DD'));
      await loadCouriers();
      await loadCourierWarehouses(courierParam ? courierParam : '1');

      setFormGroup(
        {
          courier: register('courier', {
            value: '1',
            required: MESSAGE.error.input_required,
          }),
          date: register('date', {
            value: moment().format('YYYY-MM-DD'),
            required: MESSAGE.error.input_required
          })
        }
      );
      await getCountries().then(async (countriesData: any) => {
        setCountries(countriesData);
        await getProvinces(defaultCountry).then(async (provincesData: any) => {
          setProvinces(provincesData);
          setFormGroupNotify(
            {
              warehouseNo: register('warehouseNo', {
                value: (courierWarehouses.length) ? courierWarehouses[0].warehouseNo : '',
                required: MESSAGE.error.input_required
              }),
              estimate_parcel_number: register('estimate_parcel_number', {
                value: '1',
                required: MESSAGE.error.input_required
              }),
              pickup_staff_infoId: register('pickup_staff_infoId', {
                value: ''
              }),
              remark: register('remark', {
                value: ''
              }),
              name: register('name', {
                value: '',
                required: MESSAGE.error.input_required
              }),
              address: register('address', {
                value: '',
                required: MESSAGE.error.input_required
              }),
              subdistrict: register('subdistrict', {
                value: '',
                required: MESSAGE.error.input_required,
                onChange: async (e) => {
                  if (e.target.value) {
                    await getSubDistricts(
                      getValues('country'), 
                      getValues('city')
                    ).then((subDistrictsData: any) => {
                      if (subDistrictsData && subDistrictsData.length) {
                        for (let subDistrict of subDistrictsData) {
                          if (subDistrict.id === e.target.value && subDistrict.zip_code) {
                            setValue('postcode', subDistrict.zip_code.toString());
                          }
                        }
                      }
                    });
                  }
                }
              }),
              city: register('city', {
                value: '',
                required: MESSAGE.error.input_required,
                onChange: async (e) => {
                  if (e.target.value) {
                    await getSubDistricts(getValues('country'), e.target.value).then((subDistrictsData: any) => {
                      setValue('subdistrict', '');
                      setValue('postcode', '');
                      setSubDistricts(subDistrictsData);
                    });
                  }
                }
              }),
              province: register('province', {
                value: '',
                required: MESSAGE.error.input_required,
                onChange: async (e) => {
                  if (e.target.value) {
                    await getCities(getValues('country'), e.target.value).then((citiesData: any) => {
                      setValue('city', '');
                      setValue('subdistrict', '');
                      setValue('postcode', '');
                      setCities(citiesData);
                    });
                  }
                }
              }),
              country: register('country', {
                value: defaultCountry,
                required: MESSAGE.error.input_required,
                onChange: async (e) => {
                  if (e.target.value) {
                    await getProvinces(e.target.value).then((provincesData: any) => {
                      setValue('province', '');
                      setValue('city', '');
                      setValue('subdistrict', '');
                      setValue('postcode', '');
                      setProvinces(provincesData);
                    });
                  }
                }
              }),
              postcode: register('postcode', {
                value: '',
                required: MESSAGE.error.input_required
              }),
              phone: register('phone', {
                value: '',
              })
            }
          );
        });
      });

      setInitialized(true);

    }
  }

  const load = async (id: any = getValues('courier'), date: any = getValues('date')) => {
    setShowLoading(true);
    await CourierService.notifications(id, date).then((data: any) => {
      setNotifications(data);
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });
    setShowLoading(false);
  }

  const search = (id: any = getValues('courier'), date: any = getValues('date')) => {
    load(id, date);
    UtilitiesService.updateURL('courier', getValues('courier'));
    UtilitiesService.updateURL('date', getValues('date'));
  }

  const loadCouriers = async () => {
    await CourierService.list(
      true,
      { by: 'name', direction: 'asc' }
    ).then((data: any) => {
      setCouriers(data);
    }).catch(() => { });
  }

  const loadCourierWarehouses = async (id: any = getValues('courier')) => {
    await CourierService.warehouses(id).then((data: any) => {
      setCourierWarehouses(data);
    }).catch(() => { });
  }

  const notify = async (
    id: any, 
    warehouseNo: any = null, 
    specificAddress: any = {}, 
    estimateParcelNumber: number|string|null, 
    pickupStaffInfoId: string|null, 
    remark: string = ''
  ) => {

    setShowLoading(true);
        
    specificAddress.postcode = specificAddress.postcode.toString();

    const searchProvince = async (id: number) => {
      return new Promise(resolve => {
        for (let province of provinces) {
          if (province.id === id) {
            if (specificAddress.country === 'TH') {
              resolve(province.name_th);
            } else {
              resolve(province.name_en);
            }
          }
        }
      });
    }
    if (specificAddress.province) {
      specificAddress.province = await searchProvince(specificAddress.province);
    }

    const searchCity = async (id: number) => {
      return new Promise(resolve => {
        for (let city of cities) {
          if (city.id === id) {
            if (specificAddress.country === 'TH') {
              resolve(city.name_th);
            } else {
              resolve(city.name_en);
            }
          }
        }
      });
    }
    if (specificAddress.city) {
      specificAddress.city = await searchCity(specificAddress.city);
    }

    const searchSubDistrict = async (id: number) => {
      return new Promise(resolve => {
        for (let subDistrict of subDistricts) {
          if (subDistrict.id === id) {
            if (specificAddress.country === 'TH') {
              resolve(subDistrict.name_th);
            } else {
              resolve(subDistrict.name_en);
            }
          }
        }
      });
    }
    if (specificAddress.subdistrict) {
      specificAddress.subdistrict = await searchSubDistrict(specificAddress.subdistrict);
    }

    delete specificAddress.country;

    await CourierService.notify(
      id,
      warehouseNo, 
      specificAddress, 
      estimateParcelNumber, 
      pickupStaffInfoId, 
      remark
    ).then(() => {
      setModalNotifyIsOpen(false);
      load();
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });

    setShowLoading(false);
            
  }

  const cancelNotification = (id: any, ticketPickupID: any) => {
    presentAlert({
      message: MESSAGE.info.courierCancel,
      buttons: [
        {
          text: BUTTON.cancel,
          role: 'cancel',
          handler: () => { }
        },
        {
          text: BUTTON.ok,
          role: 'confirm',
          handler: async () => {
            setShowLoading(true);
            await CourierService.cancelNotification(
              id,
              ticketPickupID
            ).then(() => {
              load();
              setPresentToast({
                isPresent: true,
                status: true,
                message: MESSAGE.success.complete,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            }).catch((error) => {
              setPresentToast({
                isPresent: true,
                status: false,
                message: error,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            });
            setShowLoading(false);
          }
        }
      ]
    })
  }

  const setFormWarehouseAddress = async (value: any) => {
    return new Promise(async resolve => {

      let countryValue: any;
      let provinceValue: any;
      let cityValue: any;
      let subdistrictValue: any;

      await getCountries().then(async (countriesData: any) => {

        if (value) {

          const searchCountry = (countryTarget: string) => {
            return new Promise(resolve => {
              for (let country of countriesData) {
                if (
                  country.name === countryTarget 
                  || country.enName === countryTarget
                  || country.alpha2 === countryTarget
                ) {
                  resolve(country.alpha2);
                }
              }
            });
          }
          await searchCountry(value.country).then(async (countryData: any) => {
            countryValue = countryData;
            const searchProvince = (provinceTarget: string) => {
              return new Promise(resolve => {
                getProvinces(countryValue).then(async (provincesData: any) => {
                  setProvinces(provincesData);
                  for (let province of provincesData) {
                    if (
                      province.name_th === provinceTarget 
                      || province.name_en === provinceTarget
                    ) {
                      resolve(province.id);
                    }
                  }
                });
              });
            }
            await searchProvince(value.province).then(async (provinceData: any) => {
              provinceValue = provinceData;
              const searchCity = (cityTarget: string) => {
                return new Promise(resolve => {
                  getCities(countryValue, provinceValue).then(async (citiesData: any) => {
                    setCities(citiesData);
                    for (let city of citiesData) {
                      if (
                        city.name_th === cityTarget 
                        || city.name_en === cityTarget
                      ) {
                        resolve(city.id);
                      }
                    }
                  });
                });
              }
              await searchCity(value.city).then(async (cityData: any) => {
                cityValue = cityData;
                const searchSubDistrict = async (subdistrictTarget: string) => {
                  return new Promise(resolve => {
                    getSubDistricts(countryValue, cityValue).then(async (subDistrictsData: any) => {
                      setSubDistricts(subDistrictsData);
                      for (let subDistrict of subDistrictsData) {
                        if (
                          subDistrict.name_th === subdistrictTarget 
                          || subDistrict.name_en === subdistrictTarget
                        ) {
                          resolve(subDistrict.id);
                        }
                      }
                    });
                  });
                }
                await searchSubDistrict(value.subdistrict).then((subdistrictData: any) => {
                  subdistrictValue = subdistrictData;
                });
              });
            });
          });

        }

      });
      
      resolve(
        {
          country: countryValue,
          province: provinceValue,
          city: cityValue,
          subdistrict: subdistrictValue
        }
      );

    });

  }

  const removeWarehouse = () => {
    setWarehouseAddress(null);
    setCities([]);
    setProvinces([]);
    setSubDistricts([]);
    setValue('name', '');
    setValue('address', '');
    setValue('country', defaultCountry);
    setValue('province', '');
    setValue('city', '');
    setValue('subdistrict', '');
    setValue('postcode', '');
    setValue('phone', '');
  }

  const getCountries = async () => {
    return new Promise(async (resolve) => {
      LocationService.getCountries().then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getProvinces = async (countryCode: string) => {
    return new Promise(async (resolve) => {
      LocationService.getProvincesByCountry(countryCode).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getCities = async (countryCode: string, provinceID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getCitiesByProvince(
        countryCode, 
        provinceID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getSubDistricts = async (countryCode: string, cityID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getSubDistrictsByCity(
        countryCode, 
        cityID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.category) {
      if (initialized) {
        initialize();
      }
    }
  }, [location]);

  return (
    <IonPage>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <ToolbarStart menuName={MENU.courierNotify} />

      <IonContent>

        <IonModal canDismiss swipeToClose isOpen={modalNotifyIsOpen} onDidDismiss={() => setModalNotifyIsOpen(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{BUTTON.courierNotifyAdd}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setModalNotifyIsOpen(false)}>
                  <IonIcon slot="start" icon={closeOutline} />
                  {BUTTON.close}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {
              formGroupNotify ?
              <form>
                <IonList>
                  <IonItem>
                    <IonLabel position="stacked">{TEXT.courierEstimateParcel}</IonLabel>
                    <IonInput type="number" min="1"
                    {...formGroupNotify.estimate_parcel_number}
                    >
                    </IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">{TEXT.courierSpecificPerson}</IonLabel>
                    <IonInput 
                    {...formGroupNotify.pickup_staff_infoId}
                    >
                    </IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">{TEXT.courierRemark}</IonLabel>
                    <IonTextarea
                    {...formGroupNotify.remark}
                    >
                    </IonTextarea>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">{TEXT.warehouse}</IonLabel>
                    <IonSelect
                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                    placeholder={TEXT.courierWarehouse}
                    {...formGroupNotify.warehouseNo}
                    onIonChange={(e: any) => e.detail.value ? setSpecificAddress(false) : setSpecificAddress(true) }
                    >
                      <IonSelectOption value="">
                        {TEXT.manualSpecify}
                      </IonSelectOption>
                      {
                        courierWarehouses.map((item: any, index: number) => (
                          <IonSelectOption key={`courier-warehouse-${index}`} value={item.warehouseNo}
                          disabled={ item.warehouseNo ? false : true }>
                            {TEXT.courierWarehouse}: {item.name} ({item.warehouseNo ? item.warehouseNo : TEXT.settingRequire })
                          </IonSelectOption>
                        ))
                      }
                    </IonSelect>
                  </IonItem>
                  {
                    specificAddress === true ? 
                    <IonList>
                      
                      <IonRow>

                        <IonCol size='12'>
                          <IonListHeader>
                            <h2>{TEXT.selectFromWarehouseAccount}</h2>
                            {
                              warehouseAddress ?
                              <IonButton type="button" fill='clear'
                              className='button-compact'
                              onClick={() => removeWarehouse()}>
                                <IonIcon slot='icon-only' icon={removeCircle} />
                              </IonButton>
                              :
                              <IonButton type="button" fill='clear'
                              className='button-compact' onClick={() => setModalWarehousesIsOpen(true)}>
                                <IonIcon slot='icon-only' icon={addCircle} />
                              </IonButton>
                            }
                          </IonListHeader>
                        </IonCol>
    
                        <IonCol size='12'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.name}</IonLabel>
                            <IonInput type="text" {...formGroupNotify.name}></IonInput>
                          </IonItem>
                        </IonCol>
    
                        <IonCol size='12'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.address}</IonLabel>
                            <IonInput type="text" {...formGroupNotify.address}></IonInput>
                          </IonItem>
                        </IonCol>
    
                        <IonCol size='12' sizeMd='6'>
                          {/* ประเทศ */}
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.country}</IonLabel>
                            <IonSelect 
                            interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel} 
                            placeholder="เลือกประเทศ"
                              value={getValues('country')} disabled
                              onIonChange={(e: any) => { !addressLoading && formGroupNotify.country.onChange(e) }}
                              {...formGroupNotify.country}
                            >
                              {countries.map((item: any, index: number) => (
                                <IonSelectOption key={`country-${index}`} value={item.alpha2}>{item.name}</IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonCol>
    
                        <IonCol size='12' sizeMd='6'>
                          {/* จังหวัด */}
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.province}</IonLabel>
                            <IonSelect 
                            interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel} 
                            placeholder="เลือกจังหวัด" 
                            value={getValues('province')} 
                            {...formGroupNotify.province}
                            disabled={!provinces.length}
                            onIonChange={(e: any) => { !addressLoading && formGroupNotify.province.onChange(e) }}
                            >
                              {provinces.map((item: any, index: number) => (
                                <IonSelectOption key={`province-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonCol>
    
                        <IonCol size='12' sizeMd='6'>
                          {/* อำเภอ/เขต */}
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.city}</IonLabel>
                            <IonSelect 
                            interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel} 
                            placeholder="เลือกเขต/อำเภอ" 
                            value={getValues('city')} 
                            {...formGroupNotify.city}
                            disabled={!cities.length}
                            onIonChange={(e: any) => { !addressLoading && formGroupNotify.city.onChange(e) }}
                            >
                              {cities.map((item: any, index: number) => (
                                <IonSelectOption key={`city-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonCol>
    
                        <IonCol size='12' sizeMd='6'>
                          {/* ตำบล/แขวง */}
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.subDistrict}</IonLabel>
                            <IonSelect 
                            interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel} 
                            placeholder="เลือกแขวง/ตำบล" 
                            value={getValues('subdistrict')} 
                            {...formGroupNotify.subdistrict}
                            disabled={!subDistricts.length}
                            onIonChange={(e: any) => { !addressLoading && formGroupNotify.subdistrict.onChange(e) }}
                            >
                              {subDistricts.map((item: any, index: number) => (
                                <IonSelectOption key={`subdistrict-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonCol>
    
                        <IonCol size='12' sizeMd='6'>
                          {/* รหัสไปรษณีย์ */}
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.postcode}</IonLabel>
                            <IonInput type="text" {...formGroupNotify.postcode}></IonInput>
                          </IonItem>
                        </IonCol>
  
                        <IonCol size='12' sizeMd='6'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.phone}</IonLabel>
                            <IonInput type="text" {...formGroupNotify.phone}></IonInput>
                          </IonItem>
                        </IonCol>
                      
                      </IonRow>
    
                    </IonList>
                    : <></>
                  }
                </IonList>
              </form>
              : <></>
            }
          </IonContent>

          <IonFooter className="ion-text-center">
            <IonToolbar>
              <IonButton onClick={() => notify(
                getValues('courier'), 
                getValues('warehouseNo'), 
                {
                  country: getValues('country'),
                  province: getValues('province'),
                  city: getValues('city'),
                  subdistrict: getValues('subdistrict'),
                  postcode: getValues('postcode'),
                  address: getValues('address'),
                  name: getValues('name'),
                  phone: getValues('phone')
                }, 
                getValues('estimate_parcel_number'), 
                getValues('pickupStaffInfoId'), 
                getValues('remark')
              )}>
                <IonIcon slot="start" icon={paperPlaneOutline} />
                {BUTTON.courierSend}
              </IonButton>
            </IonToolbar>
          </IonFooter>

        </IonModal>

        <IonModal canDismiss swipeToClose isOpen={modalWarehousesIsOpen} onDidDismiss={() => setModalWarehousesIsOpen(false)}>
          <ModalWarehouses
            title={TEXT.selectFromWarehouseAccount}
            selected={warehouseAddress ? warehouseAddress.id : null}
            onDismiss={async (warehouseData: any) => {
              if (warehouseData) {
                setWarehouseAddress(warehouseData);
                setValue('name', warehouseData.name);
                setValue('address', warehouseData.address);
                setValue('phone', warehouseData.phone);
                setShowLoading(true);
                setAddressLoading(true);
                await setFormWarehouseAddress(warehouseData).then((valueAddress: any) => {
                  setValue('country', valueAddress.country);
                  setValue('province', valueAddress.province);
                  setValue('city', valueAddress.city);
                  setValue('subdistrict', valueAddress.subdistrict);
                  setValue('postcode', warehouseData.postcode);
                  setShowLoading(false);
                  setAddressLoading(false);
                });
              } else if (warehouseData === false) {
                setWarehouseAddress(null);
                setValue('name', '');
                setValue('address', '');
                setValue('country', defaultCountry);
                setValue('province', '');
                setValue('city', '');
                setValue('subdistrict', '');
                setValue('postcode', '');
                setValue('phone', '');
              }
              setModalWarehousesIsOpen(false);
            }}
          />
        </IonModal>

        <IonGrid>
          {
            formGroup ?
            <IonRow className='row-toolbar ion-margin-top'>

              <IonCol size='12' sizeMd='3'>
                <IonToolbar className='toolbar-page'>
                  <IonItem lines='none' className="item-hidden-label">
                    <IonLabel position="fixed">{TEXT.courier}</IonLabel>
                      <IonSelect
                      interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                      placeholder={TEXT.courier}
                      value={getValues('courier')}
                      {...formGroup.courier}
                      onIonChange={ (e: any) => search(e.detail.value) }
                      >
                        {
                          couriers.map((item: any, index: number) => (
                            <IonSelectOption key={`courier-${index}`} value={item.id}>{item.name}</IonSelectOption>
                          ))
                        }
                      </IonSelect>
                  </IonItem>
                </IonToolbar>
              </IonCol>

              <IonCol size='12' sizeMd='4'>
                <IonToolbar className='toolbar-page'>
                  <IonItem lines='none'>
                    <IonLabel position="fixed">{TEXT.date}</IonLabel>
                    <IonInput type="date"
                      value={getValues('date')}
                      {...formGroup.date}>
                    </IonInput>
                  </IonItem>
                </IonToolbar>
              </IonCol>

              <IonCol size='12' sizeMd='2'>
                <IonToolbar className='toolbar-page'>
                  <IonButtons>
                    <IonButton fill='outline' expand="block" color="primary" onClick={() => search()}>
                      <IonIcon slot="start" icon={searchOutline} /> {BUTTON.courierNotifySearch}
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonCol>

              <IonCol size='12' sizeMd="3">
                <IonToolbar className='toolbar-page'>
                  <IonButtons slot='end'>
                    <IonButton fill='outline' color="success" onClick={() => setModalNotifyIsOpen(true)}>
                      <IonIcon slot="start" icon={callOutline} /> {BUTTON.courierNotifyAdd}
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonCol>
            </IonRow>
            : <></>
          }
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="12">
              {
                notifications.length ? 
                notifications.map((item: any, index: number) => (
                  <IonCard key={`notification-${index}`}>
                    <IonToolbar mode="md">
                      <IonTitle slot='start' color={item.status && item.status === 4 ? 'danger' : ''}>
                        <h4 className="text-full">
                        {item.name ? item.name : index}
                        {
                          item.status && item.status === 4 ? 
                          ` [${item.status_text}]` : ''
                        }
                        </h4>
                      </IonTitle>
                      {
                        !item.status || item.status < 4 ? 
                        <IonButtons slot="end">
                          <IonButton fill='clear' color="danger"
                          onClick={() => cancelNotification(getValues('courier'), item.ticket_pickup_id)}>
                            <IonIcon icon={banOutline} slot="start" />
                            <IonLabel>
                              {BUTTON.cancel}
                            </IonLabel>
                          </IonButton>
                        </IonButtons>
                        : <></>
                      }
                    </IonToolbar>
                    <IonCardContent>
                      <IonGrid className="no-padding">
                        <IonRow>
                          <IonCol size="12" sizeMd="6">
                            <dl>
                              <dt><b>{TEXT.courierTicketPickupId}</b></dt>
                              <dd>{item.ticket_pickup_id}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.courierCreatedAt}</b></dt>
                              <dd>
                                {
                                  `${moment.unix(item.created_at).format('LT')} - ${moment.unix(item.created_at).format('LL')}`
                                }
                              </dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.courierEstimateParcelNumber}</b></dt>
                              <dd>
                                <NumberFormat value={item.estimate_parcel_number} displayType={'text'} thousandSeparator={true} />
                              </dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.courierParcelNumber}</b></dt>
                              <dd>
                                <NumberFormat value={item.parcel_number} displayType={'text'} thousandSeparator={true} />
                              </dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.courierStaffInfoName}</b></dt>
                              <dd>{item.staff_name ? item.staff_name : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.courierStaffInfomobile}</b></dt>
                              <dd>{item.staff_phone ? item.staff_phone : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.courierStateText}</b></dt>
                              <dd>
                                <IonBadge color={
                                  item.status === 0 ? 'medium' : 
                                  item.status === 1 ? 'secondary' :
                                  item.status === 2 ? 'success' :
                                  item.status === 4 ? 'danger' : 
                                  ''
                                }>
                                  {item.status_text}
                                </IonBadge>
                              </dd>
                            </dl>
                          </IonCol>
                          <IonCol size="12" sizeMd="6">
                            <dl>
                              <dt><b>{TEXT.courierSrcName}</b></dt>
                              <dd>{item.name}</dd>
                            </dl>
      
                            <dl>
                              <dt><b>{TEXT.courierPhone}</b></dt>
                              <dd>{item.phone}</dd>
                            </dl>
      
                            <dl>
                              <dt><b>{TEXT.courierSrcDetailAddress}</b></dt>
                              <dd>{item.address}</dd>
                            </dl>
      
                            <dl>
                              <dt><b>{TEXT.courierSrcDistrictName}</b></dt>
                              <dd>{item.subdistrict}</dd>
                            </dl>
      
                            <dl>
                              <dt><b>{TEXT.courierSrcCityName}</b></dt>
                              <dd>{item.city}</dd>
                            </dl>
      
                            <dl>
                              <dt><b>{TEXT.courierPostalCode}</b></dt>
                              <dd>{item.postcode}</dd>
                            </dl>
      
                            <dl>
                              <dt><b>{TEXT.courierSrcProvinceName}</b></dt>
                              <dd>{item.province}</dd>
                            </dl>
      
                            <dl>
                              <dt><b>{TEXT.courierRemark}</b></dt>
                              <dd>{item.remark ? item.remark : '-'}</dd>
                            </dl>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                    {
                      item.staff_name || item.staff_phone ?
                      <IonToolbar mode="md">
                        <IonTitle slot='start'>
                          <h5>
                          {item.staff_name ? item.staff_name : ''}
                          </h5>
                        </IonTitle>
                        {
                          item.staff_phone ? 
                          <IonButtons slot="end">
                            <a href={`tel:${item.staff_phone.replace('-', '').replace(' ', '')}`}>
                              <IonButton fill='clear' color="success">
                                <IonIcon icon={callOutline} slot="start" />
                                <IonLabel>
                                  {BUTTON.courierCall}
                                </IonLabel>
                              </IonButton>
                            </a>
                          </IonButtons>
                          : <></>
                        }
                      </IonToolbar>
                      : <></>
                    }
                  </IonCard>
                ))
                : 
                initialized ? 
                <p className="ion-text-center">
                  {TEXT.noDataComponent}
                </p>
                : <></>
              }
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
}

export default CourierNotify;