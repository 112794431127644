import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { 
  IonPage, 
  IonContent, 
  IonButton, 
  IonIcon, 
  IonBackButton, 
  IonButtons, 
  IonTitle, 
  IonToolbar, 
  IonHeader 
} from "@ionic/react";
import { 
  cubeOutline
} from "ionicons/icons";

import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';

import { Direction, RoutePath } from "../components/RoutePath";
import Order from "../components/Order";
import UserService from "../services/UserService";
import VendorService from "../services/VendorService";

const OrdersView: React.FC = () => {

  const { id } = useParams<{ id: string; }>();

  const [vendor, setVendor] = useState<any>(null);
  const [orderStatus, setOrderStatus] = useState<any>(null);
  const [packAvailable, setPackAvailable] = useState<any>(false);

  const [reload, setReload] = useState<any>(new Date().getTime());
  const [showProgressLoading, setShowProgressLoading] = useState(false);

  const location = useLocation();

  const initialize = async () => {
    const userData: any = await UserService.getSession();
    if (userData) {
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.productImportView.replace(':id', id)) {
      setReload(new Date().getTime());
    }
  }, [location]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className='ion-hide-lg-up'>
            <IonBackButton defaultHref={RoutePath.sendParcelAdd} text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.orderView}</IonTitle>
          <IonButtons slot="end">
          {
            orderStatus === 'pending' ?
            <IonButton routerLink={RoutePath.orderPackAdd.replace(':id', packAvailable)}
            disabled={!packAvailable ? true : false} 
            routerDirection={Direction()}>
              <IonIcon slot="start" icon={cubeOutline} />
              {BUTTON.sendParcelAdd}
            </IonButton>
            : <></>
          }
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {
          id ? 
          <Order id={id} className="ion-margin-top"
          onStatusChange={(status: any) => setOrderStatus(status)} 
          onCheckPackAvailable={(available: any) => setPackAvailable(available)}
          reload={reload}
          onLoad={(data: any) => setShowProgressLoading(data)}
          />
          : <></>
        }
      </IonContent>
    </IonPage>
  );
}

export default OrdersView;