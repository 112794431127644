import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import {
  IonContent,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle
} from '@ionic/react';
import {
  alertCircleOutline, documentOutline, personOutline, shieldOutline, storefrontOutline
} from 'ionicons/icons';

import { Imagires } from '../components/ImageVariable';
import { RoutePath } from '../components/RoutePath';
import { AlertStatus } from '../components/alerts/AlertStatus';
import { ToastStatus } from '../components/toasts/ToastStatus';

import { PATTERN_EMAIL } from '../constances/RegularExpressions';
import MESSAGE from '../constances/Messages.json';
import BUSINESS_TYPES from '../constances/BusinessTypes.json';
import BUTTON from '../constances/Button.json';
import TEXT from '../constances/Text.json';

import LocationService from '../services/LocationService';
import UserService from '../services/UserService';

const Register: React.FC = () => {

  const defaultCountry: string = 'TH';

  const [countries, setCountries] = useState<Array<any>>([]);
  const [provinces, setProvinces] = useState<Array<any>>([]);
  const [cities, setCities] = useState<Array<any>>([]);
  const [subDistricts, setSubDistricts] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const { register, getValues, setValue, handleSubmit, formState: { errors } } = useForm();
  const [formGroup, setFormGroup] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  const history = useHistory();

  const getCountries = async () => {
    return new Promise(async (resolve) => {
      LocationService.getCountries().then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getProvinces = async (countryCode: string) => {
    return new Promise(async (resolve) => {
      LocationService.getProvincesByCountry(countryCode).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getCities = async (countryCode: string, provinceID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getCitiesByProvince(
        countryCode,
        provinceID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getSubDistricts = async (countryCode: string, cityID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getSubDistrictsByCity(
        countryCode,
        cityID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const initialize = async () => {
    setShowLoading(true);
    await getCountries().then(async (countriesData: any) => {
      setCountries(countriesData);
      await getProvinces(defaultCountry).then(async (provincesData: any) => {
        setProvinces(provincesData);
        setFormGroup(
          {
            name: register('name', {
              value: '',
              required: MESSAGE.error.input_required
            }),
            tax_id: register('tax_id', {
              value: '',
              required: MESSAGE.error.input_required
            }),
            address: register('address', {
              value: '',
              required: MESSAGE.error.input_required
            }),
            subdistrict: register('subdistrict', {
              value: '',
              required: MESSAGE.error.input_required,
              onChange: (e) => {
                if (e.target.value) {
                  getSubDistricts(
                    getValues('country'),
                    getValues('city')
                  ).then((subDistrictsData: any) => {
                    if (subDistrictsData && subDistrictsData.length) {
                      for (let subDistrict of subDistrictsData) {
                        if (subDistrict.id === e.target.value && subDistrict.zip_code) {
                          setValue('postcode', subDistrict.zip_code.toString());
                        }
                      }
                    }
                  });
                }
              }
            }),
            city: register('city', {
              value: '',
              required: MESSAGE.error.input_required,
              onChange: (e) => {
                if (e.target.value) {
                  getSubDistricts(getValues('country'), e.target.value).then((subDistrictsData: any) => {
                    setValue('subdistrict', '');
                    setValue('postcode', '');
                    setSubDistricts(subDistrictsData);
                  });
                }
              }
            }),
            province: register('province', {
              value: '',
              required: MESSAGE.error.input_required,
              onChange: async (e) => {
                if (e.target.value) {
                  getCities(getValues('country'), e.target.value).then((citiesData: any) => {
                    setValue('city', '');
                    setValue('subdistrict', '');
                    setValue('postcode', '');
                    setCities(citiesData);
                  });
                }
              }
            }),
            country: register('country', {
              value: defaultCountry,
              required: MESSAGE.error.input_required,
              onChange: (e) => {
                if (e.target.value) {
                  getProvinces(e.target.value).then((provincesData: any) => {
                    setValue('province', '');
                    setValue('city', '');
                    setValue('subdistrict', '');
                    setValue('postcode', '');
                    setProvinces(provincesData);
                  });
                }
              }
            }),
            postcode: register('postcode', {
              value: '',
              required: MESSAGE.error.input_required
            }),
            phone: register('phone', {
              value: '',
            }),
            email: register('email', {
              value: '',
              required: MESSAGE.error.input_required,
              pattern: {
                value: PATTERN_EMAIL,
                message: MESSAGE.error.email_invalid,
              },
            }),
            password: register('password', {
              value: '',
              required: MESSAGE.error.input_required
            }),
            confirm_password: register('confirm_password', {
              value: '',
              required: MESSAGE.error.input_required
            }),
            business_type: register('business_type', {
              value: '',
              required: MESSAGE.error.input_required
            }),
            contact_person_name: register('contact_person_name', {
              value: ''
            }),
            contact_person_phone: register('contact_person_phone', {
              value: ''
            }),
            contact_person_email: register('contact_person_email', {
              value: '',
              pattern: {
                value: PATTERN_EMAIL,
                message: MESSAGE.error.email_invalid,
              },
            }),
            contact_person_secondary_name: register('contact_person_secondary_name', {
              value: ''
            }),
            contact_person_secondary_phone: register('contact_person_secondary_phone', {
              value: ''
            }),
            contact_person_secondary_email: register('contact_person_secondary_email', {
              value: '',
              pattern: {
                value: PATTERN_EMAIL,
                message: MESSAGE.error.email_invalid,
              },
            }),
            company_certificate: register('company_certificate', {
              value: '',
              required: MESSAGE.error.file_required
            }),
            vat_license: register('vat_license', {
              value: '',
              required: MESSAGE.error.file_required
            }),
            contract: register('contract', {
              value: '',
              required: MESSAGE.error.file_required
            }),
            active: register('active', {
              value: '1',
              required: MESSAGE.error.input_required
            }),
          }
        );
      });
    });
    setShowLoading(false);
  }

  const onSubmit = async (value: any) => {
    if (value.password === value.confirm_password) {

      setShowLoading(true);

      const formData = new FormData();
      await formData.append('company_certificate', value.company_certificate.item(0));
      await formData.append('vat_license', value.vat_license.item(0));
      await formData.append('contract', value.contract.item(0));

      delete value.company_certificate;
      delete value.vat_license;
      delete value.contract;

      value.postcode = value.postcode.toString();

      const searchProvince = async (id: number) => {
        return new Promise(resolve => {
          for (let province of provinces) {
            if (province.id === id) {
              if (value.country === 'TH') {
                resolve(province.name_th);
              } else {
                resolve(province.name_en);
              }
            }
          }
        });
      }
      if (value.province) {
        value.province = await searchProvince(value.province);
      }

      const searchCity = async (id: number) => {
        return new Promise(resolve => {
          for (let city of cities) {
            if (city.id === id) {
              if (value.country === 'TH') {
                resolve(city.name_th);
              } else {
                resolve(city.name_en);
              }
            }
          }
        });
      }
      if (value.city) {
        value.city = await searchCity(value.city);
      }

      const searchSubDistrict = async (id: number) => {
        return new Promise(resolve => {
          for (let subDistrict of subDistricts) {
            if (subDistrict.id === id) {
              if (value.country === 'TH') {
                resolve(subDistrict.name_th);
              } else {
                resolve(subDistrict.name_en);
              }
            }
          }
        });
      }
      if (value.subdistrict) {
        value.subdistrict = await searchSubDistrict(value.subdistrict);
      }

      const searchCountry = async (countryCode: string) => {
        return new Promise(resolve => {
          for (let country of countries) {
            if (country.alpha2 === countryCode) {
              if (countryCode === 'TH') {
                resolve(country.name);
              } else {
                resolve(country.enName);
              }
            }
          }
        });
      }
      if (value.country) {
        value.country = await searchCountry(value.country);
      }

      value.last_name = '';
      value.nick_name = '';
      value.passcode = '';
      value.phone = value.contact_person_phone ? value.contact_person_phone : '';

      const assign = async (value: any) => {
        for (let item of Object.keys(value)) {
          await formData.append(item, value[item]);
        }
      }
      await assign(value);

      await UserService.signup(formData).then(async (data: any) => {
        if (data) {
          history.replace(RoutePath.login);
          setPresentToast({
            isPresent: true,
            status: true,
            message: MESSAGE.success.register_complete,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        } else {
          setPresentToast({
            isPresent: true,
            status: false,
            message: MESSAGE.error.unidentified_error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        }
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });

      setShowLoading(false);

    } else {
      setPresentToast({
        isPresent: true,
        status: false,
        message: MESSAGE.error.password_mismatched,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    }
  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
  }

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  return (
    <IonPage>
      <IonContent className="ion-padding term">

        <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        />

        <AlertStatus
          isPresent={presentAlert.isPresent}
          status={presentAlert.status}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
              <IonGrid>
                <IonRow>

                  <IonCol size='6' sizeMd='4' sizeLg='2'>
                    <div className="logo">
                      <img src={Imagires.logo}></img>
                    </div>
                  </IonCol>

                  <IonCol size='12' sizeMd='8' sizeLg='10' className='ion-text-center ion-text-md-right'>
                    <h2>{TEXT.regsiterForm}</h2>
                  </IonCol>

                </IonRow>
                {
                  formGroup ?
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                      <IonCard>
                        <IonCardHeader>
                          <IonCardTitle>
                            <IonItem lines='none' className='item-no-padding-all'>
                              <IonIcon icon={storefrontOutline} slot="start" size='small' />
                              <IonLabel>{TEXT.generalData}</IonLabel>
                            </IonItem>
                          </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          {/* ข้อมูลผู้ค้า */}
                          <IonRow>

                            <IonCol size='12' sizeMd='6'>
                              {/* ชื่อผู้ค้า */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.vendorName}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="text" {...formGroup.name}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="name"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* เลขประจำตัวผู้เสียภาษีผู้ค้า */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.vendorTaxID}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="text" {...formGroup.tax_id}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="tax_id"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12'>
                              {/* ที่อยู่ผู้ค้า */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.vendorAddress}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="text" {...formGroup.address}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="address"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* ประเทศ */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.country}<span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder="เลือกประเทศ"
                                  value={getValues('country')} disabled
                                  onIonChange={(e: any) => { formGroup.country.onChange(e) }}
                                  {...formGroup.country}
                                >
                                  {countries.map((item: any, index: number) => (
                                    <IonSelectOption key={`country-${index}`} value={item.alpha2}>{item.name}</IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                              <ErrorMessage errors={errors} name="country"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* จังหวัด */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.province}<span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder="เลือกจังหวัด"
                                  value={getValues('province')}
                                  {...formGroup.province}
                                  disabled={!provinces.length}
                                  onIonChange={(e: any) => { formGroup.province.onChange(e) }}
                                >
                                  {provinces.map((item: any, index: number) => (
                                    <IonSelectOption key={`province-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                              <ErrorMessage errors={errors} name="province"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* อำเภอ/เขต */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.city}<span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder="เลือกเขต/อำเภอ"
                                  value={getValues('city')}
                                  {...formGroup.city}
                                  disabled={!cities.length}
                                  onIonChange={(e: any) => { formGroup.city.onChange(e) }}
                                >
                                  {cities.map((item: any, index: number) => (
                                    <IonSelectOption key={`city-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                              <ErrorMessage errors={errors} name="city"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* ตำบล/แขวง */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.subDistrict}<span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder="เลือกแขวง/ตำบล"
                                  value={getValues('subdistrict')}
                                  {...formGroup.subdistrict}
                                  disabled={!subDistricts.length}
                                  onIonChange={(e: any) => { formGroup.subdistrict.onChange(e) }}
                                >
                                  {subDistricts.map((item: any, index: number) => (
                                    <IonSelectOption key={`subdistrict-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                              <ErrorMessage errors={errors} name="subdistrict"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* รหัสไปรษณีย์ */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.postcode}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="text" {...formGroup.postcode}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="postcode"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='6'>
                              {/* ประเภทธุรกิจ */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.vendorBusinessType}<span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder={TEXT.vendorBusinessType}
                                  {...formGroup.business_type}
                                >
                                  {BUSINESS_TYPES.map((item: any, index: number) => (
                                    <IonSelectOption key={`business-${index}`} value={item.key}>{item.value}</IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                              <ErrorMessage errors={errors} name="business_type"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>
                          </IonRow>

                        </IonCardContent>
                      </IonCard>

                      {/* บุคคลสำหรับติดต่อ */}
                      <IonCard>
                        <IonCardHeader>
                          <IonCardTitle>
                            <IonItem lines='none' className='item-no-padding-all'>
                              <IonIcon icon={personOutline} slot="start" size='small' />
                              <IonLabel>{TEXT.vendorContactInfo}</IonLabel>
                            </IonItem>
                          </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonRow>

                            {/* Name 1 */}
                            <IonCol size='12'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.vendorContactName}</IonLabel>
                                <IonInput type="text" {...formGroup.contact_person_name}></IonInput>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.phone}</IonLabel>
                                <IonInput type="text" {...formGroup.contact_person_phone}></IonInput>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.email}</IonLabel>
                                <IonInput type="email" {...formGroup.contact_person_email}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="contact_person_email"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>


                            {/* Name 2 */}

                            <IonCol size='12'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.vendorContactName2}</IonLabel>
                                <IonInput type="text" {...formGroup.contact_person_secondary_name}></IonInput>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.phone}</IonLabel>
                                <IonInput type="text" {...formGroup.contact_person_secondary_phone}></IonInput>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.email}</IonLabel>
                                <IonInput type="email" {...formGroup.contact_person_secondary_email}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="contact_person_secondary_email"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCard>

                      {/* กำหนดชื่อผู้ใช้งาน และ รหัสผ่าน */}
                      <IonCard>
                        <IonCardHeader>
                          <IonCardTitle>
                            <IonItem lines='none' className='item-no-padding-all'>
                              <IonIcon icon={shieldOutline} slot="start" size='small' />
                              <IonLabel>{TEXT.usernameAndPasswordHeader}</IonLabel>
                            </IonItem>
                          </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonRow>

                            <IonCol size='12' sizeMd='12'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.email}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="email" {...formGroup.email}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="email"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.password}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="password" {...formGroup.password}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="password"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.passwordConfirm}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="password" {...formGroup.confirm_password}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="confirm_password"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCard>

                      {/* อัพโหลดเอกสาร */}
                      <IonCard>
                        <IonCardHeader>
                          <IonCardTitle>
                            <IonItem lines='none' className='item-no-padding-all'>
                              <IonIcon icon={documentOutline} slot="start" size='small' />
                              <IonLabel>{TEXT.uploadFileHeader}</IonLabel>
                            </IonItem>
                          </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonRow>
                            <IonCol size='12'>
                              <IonItem lines="none">
                                <IonGrid>
                                  <IonRow>
                                    <IonCol size='12' sizeLg='4'>
                                      <IonLabel className='ion-text-wrap'>1. {TEXT.uploadComCerFile}<span className='form-required'>*</span></IonLabel>
                                    </IonCol>
                                    <IonCol size='12' sizeLg='8'>
                                      <input type="file" className='file-register' {...formGroup.company_certificate}></input>
                                    </IonCol>
                                    <ErrorMessage errors={errors} name="company_certificate"
                                      render={({ message }) =>
                                        <div className='form-help'>
                                          <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                          {message ? message : ''}
                                        </div>
                                      } />
                                  </IonRow>
                                </IonGrid>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12'>
                              <IonItem lines="none">
                                <IonGrid>
                                  <IonRow>
                                    <IonCol size='12' sizeLg='4'>
                                      <IonLabel className='ion-text-wrap'>2. {TEXT.upload20File}<span className='form-required'>*</span></IonLabel>
                                    </IonCol>
                                    <IonCol size='12' sizeLg='8'>
                                      <input type="file" {...formGroup.vat_license}></input>
                                    </IonCol>
                                    <ErrorMessage errors={errors} name="vat_license"
                                      render={({ message }) =>
                                        <div className='form-help'>
                                          <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                          {message ? message : ''}
                                        </div>
                                      } />
                                  </IonRow>
                                </IonGrid>
                              </IonItem>

                            </IonCol>

                            <IonCol size='12'>
                              <IonItem lines="none">
                                <IonGrid>
                                  <IonRow>
                                    <IonCol size='12' sizeLg='4'>
                                      <IonLabel className='ion-text-wrap'>3. {TEXT.uploadWarehouseFile}<span className='form-required'>*</span></IonLabel>
                                    </IonCol>
                                    <IonCol size='12' sizeLg='8'>
                                      <input type="file" {...formGroup.contract}></input>
                                    </IonCol>

                                    <ErrorMessage errors={errors} name="contract"
                                      render={({ message }) =>
                                        <div className='form-help'>
                                          <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                          {message ? message : ''}
                                        </div>
                                      } />
                                  </IonRow>
                                </IonGrid>
                              </IonItem>
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCard>

                      <IonRow>
                        <IonCol className='ion-text-center'>
                          <IonButton type="submit" color="primary">{BUTTON.submitRegister}</IonButton>
                          {/* <IonButton type="button" routerLink={RoutePath.index} routerDirection={Direction()} color="medium">{BUTTON.cancelRegister}</IonButton> */}
                        </IonCol>
                      </IonRow>
                    </form>
                    : <></>
                }
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent >
    </IonPage >
  );
};

export default Register;