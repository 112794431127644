import { IonPage, IonContent, IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonTitle, IonToolbar } from "@ionic/react";
import { useLocation, useParams } from "react-router-dom";

import { Direction, RoutePath } from "../components/RoutePath";

import MENU from '../constances/Menu.json';
import BUTTON from '../constances/Button.json';

import { createOutline } from "ionicons/icons";
import { useState, useEffect } from "react";
import Cancel from "../components/Cancel";
import UserService from "../services/UserService";
import VendorService from "../services/VendorService";

const ProductCancelView: React.FC = () => {

  const { id } = useParams<{ id: string; }>();

  const [vendor, setVendor] = useState<any>(null);
  const [cancelStatus, setOrderStatus] = useState<any>(null);

  const [reload, setReload] = useState<any>(new Date().getTime());
  const [showProgressLoading, setShowProgressLoading] = useState(false);

  const location = useLocation();

  const initialize = async () => {
    const userData: any = await UserService.getSession();
    if (userData) {
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.productImportView.replace(':id', id)) {
      setReload(new Date().getTime());
    }
  }, [location]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{MENU.productCancelView}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {
          id ? 
          <Cancel id={id} className="ion-margin-top"
          onStatusChange={(status: any) => setOrderStatus(status)} 
          reload={reload}
          onLoad={(data: any) => setShowProgressLoading(data)}
          />
          : <></>
        }
      </IonContent>
    </IonPage>
  );
}

export default ProductCancelView;