import { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';

import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
  SearchbarCustomEvent
} from '@ionic/react';
import {
  add,
  alertCircleOutline,
  arrowDownOutline,
  arrowUpOutline,
  banOutline,
  createOutline,
  moveOutline,
  removeOutline,
  trashOutline
} from 'ionicons/icons';

import moment from 'moment';

import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import MESSAGE from '../constances/Messages.json';
import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TYPES from '../constances/Types.json';


import { ModalJobAdd } from '../components/modal/JobAdd';
import { Direction, RoutePath } from '../components/RoutePath';
import { pageTable } from '../components/TableStyle';
import { AlertPrompt } from '../components/alerts/AlertPrompt';
import { ToastStatus } from '../components/toasts/ToastStatus';
import Error from "../components/Error";

import JobService from '../services/JobService';
import ASNService from '../services/ASNService';
import OrderService from '../services/OrderService';
import UtilitiesService from '../services/UtilitiesService';
import UserService from '../services/UserService';
import WithdrawService from '../services/WithdrawService';
import TransferService from '../services/TransferService';
import CancelService from '../services/CancelService';

const Jobs: React.FC = () => {

  const offset: number = 50;

  const defaultSort: any = {
    by: 'create_at',
    direction: 'desc'
  }

  const [members, setMembers] = useState<Array<any>>([]);
  const [jobs, setJobs] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showProgressLoading, setShowProgressLoading] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [sort, setSort] = useState<any>(defaultSort);
  const [total, setTotal] = useState<number>(0);
  const [totalAll, setTotalAll] = useState<number>(0);
  const [totalInbound, setTotalInbound] = useState<number>(0);
  const [totalOutbound, setTotalOutbound] = useState<number>(0);
  const [totalTransfer, setTotalTransfer] = useState<number>(0);
  const [totalWithdraw, setTotalWithdraw] = useState<number>(0);
  const [totalCancel, setTotalCancel] = useState<number>(0);

  const [modalAddIsOpen, setModalAddIsOpen] = useState<boolean>(false);

  const location = useLocation();

  const { register, getValues, setValue } = useForm();
  const [filterType] = useState<any>(
    register('type', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, e.detail.value, getValues('status'), getValues('search'), getValues('dateFrom'), getValues('dateTo'));
        UtilitiesService.updateURL('type', e.detail.value);
      }
    })
  );
  const [filterStatus] = useState<any>(
    register('status', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('type'), e.detail.value, getValues('search'), getValues('dateFrom'), getValues('dateTo'));
        UtilitiesService.updateURL('status', e.detail.value);
      }
    })
  );
  const [filterSearch] = useState<any>(
    register('search', {
      value: ''
    })
  );
  const [filterDateFrom] = useState<any>(
    register('dateFrom', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('type'), getValues('status'), getValues('search'), e.detail.value, getValues('dateTo'));
        UtilitiesService.updateURL('from', e.detail.value);
      }
    })
  );
  const [filterDateTo] = useState<any>(
    register('dateTo', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('type'), getValues('status'), getValues('search'), getValues('dateFrom'), e.detail.value);
        UtilitiesService.updateURL('to', e.detail.value);
      }
    })
  );

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const columns: Array<any> = [
    {
      id: 'id',
      name: TEXT.jobNo,
      selector: (row: { id: number }) => row.id,
      cell: (row: { id: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.jobView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='ion-text-center'>
            {row.id}
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      width: '120px'
    },
    {
      id: 'reference',
      name: '',
      selector: (row: { id: number }) => row.id,
      cell: (row: { id: any, reference: any, reference_detail: any }) => (
        row.reference ?
          <IonItem lines="none" detail={false} routerLink={RoutePath.jobView.replace(':id', row.id)}
            routerDirection={Direction()}>
            <IonLabel className='text-full'>
              {row.reference}
              {
                row.reference_detail ?
                  <div className='subtitle'>
                    ({row.reference_detail})
                  </div>
                  : <></>
              }
            </IonLabel>
          </IonItem>
          : <></>
      ),
      width: '260px',
    },
    {
      id: 'create_at',
      name: TEXT.date,
      selector: (row: { create_at: any }) => row.create_at,
      cell: (row: { id: any; create_at: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.jobView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-center'>
            {row.create_at ? moment.utc(row.create_at).local().format('LL') : ''}
            <div className='subtitle'>
              {row.create_at ? moment.utc(row.create_at).local().format('LT') : ''}
            </div>
          </IonLabel>
        </IonItem>
      ),
      sortable: true,
      center: true,
      width: '250px'
    },
    {
      id: 'type',
      name: TEXT.type,
      selector: (row: { type: any }) => row.type,
      cell: (row: { id: any; type: any }) => (
        <IonButtons>
          <IonButton fill='clear' onClick={() => setValue('type', row.type)}>
            <IonIcon slot="start" icon={
              row.type === 'inbound' ? arrowDownOutline
                : row.type === 'outbound' ? arrowUpOutline
                  : row.type === 'transfer' ? moveOutline
                    : row.type === 'withdraw' ? removeOutline
                      : row.type === 'cancel' ? banOutline
                        : alertCircleOutline
            } />
            <IonLabel>{types[row.type]}</IonLabel>
          </IonButton>
        </IonButtons>
      ),
      center: true,
      sortable: true,
    },
    {
      id: 'status',
      name: TEXT.status,
      selector: (row: { status: any }) => row.status,
      cell: (row: { id: any; status: any }) => (
        <IonButtons>
          <IonButton fill='solid' color={colors[row.status]} onClick={() => setValue('status', row.status)}>
            <IonLabel>{statuses[row.status]}</IonLabel>
          </IonButton>
        </IonButtons>
      ),
      center: true,
      sortable: true,
      width: '150px'
    },
    {
      id: 'action',
      name: '',
      cell: (row: { id: any, name: string }) => (
        <IonButtons>
          <IonButton fill='outline' routerLink={RoutePath.jobView.replace(':id', row.id)}
            routerDirection={Direction()}>
            <IonIcon slot="start" icon={createOutline} />
            <IonLabel>{BUTTON.manage}</IonLabel>
          </IonButton>
          {
            members.length && (
              members.includes('1')
              || members.includes('2')
              || members.includes('3')
              || members.includes('5')
            )
              ?
              <IonButton fill='outline' onClick={() => remove(row.id, row.id)}>
                <IonIcon slot="start" icon={trashOutline} />
                <IonLabel>{BUTTON.delete}</IonLabel>
              </IonButton>
              : <></>
          }
        </IonButtons>
      ),
      right: true,
      width: '220px'
    }
  ];
  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const colors: any = COLORS.job;
  const statuses: any = STATUS.job;
  const types: any = TYPES.job;

  const history = useHistory();

  const initialize = async () => {

    setInitialized(false);
    setShowLoading(true);
    const urlParams = new URLSearchParams(location.search);
    const typeParam = urlParams.get('type')
    if (typeParam) {
      await setValue('type', typeParam);
    }
    const statusParam = urlParams.get('status');
    if (statusParam !== null) {
      await setValue('status', statusParam);
    } else {
      await setValue('status', 'pending');
      UtilitiesService.updateURL('status', 'pending');
    }
    const searchParam = urlParams.get('search')
    if (searchParam) {
      await setValue('search', searchParam);
    }
    const dateFromParam = urlParams.get('from')
    if (dateFromParam) {
      await setValue('dateFrom', dateFromParam);
    }
    const dateToParam = urlParams.get('to')
    if (dateToParam) {
      await setValue('dateTo', dateToParam);
    }

    const userData: any = await UserService.getSession();
    if (userData) {
      setMembers(
        userData.members.map(
          ({ group_id }: any) => (
            group_id
          )
        )
      );
      await load(
        0,
        pageLimit,
        defaultSort.by,
        defaultSort.direction,
        getValues('type'),
        getValues('status'),
        getValues('search'),
        getValues('dateFrom'),
        getValues('dateTo')
      );
    }

    setInitialized(true);
    setShowLoading(false);

  }

  const load = async (
    start: number = 0,
    limit: number | null = pageLimit,
    sortBy: string = defaultSort.by,
    sortDirection: string = defaultSort.direction,
    type: string = getValues('type'),
    status: string = getValues('status'),
    search: string = getValues('search'),
    dateFrom: string = getValues('dateFrom'),
    dateTo: string = getValues('dateTo')
  ) => {
    setShowProgressLoading(true);
    const count = (countType: any = '') => {
      return new Promise(async (resolve) => {
        await JobService.count(
          true,
          null,
          null,
          countType,
          status,
          search,
          dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
          dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null
        ).then(async (rows: any) => {
          resolve(rows);
        });
      });
    }
    await count(type).then(async (rows: any) => {
      setTotal(rows);
      if (rows < offset) {
        start = 0;
        limit = null;
        sortBy = defaultSort.by;
        sortDirection = defaultSort.direction;
      }
      await JobService.list(
        true,
        { by: sortBy, direction: sortDirection },
        start,
        limit,
        type,
        status,
        search,
        dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        true
      ).then(async (data: any) => {
        const setInstrument = async (items: any) => {
          return new Promise(async (resolve) => {
            let i: number = 0;
            for (let item of items) {
              if (item.type === 'inbound') {
                await ASNService.listByJob(item.id, null, null, 0, 1, null, true).then(async (data: any) => {
                  const asnData: any = data[0];
                  items[i].reference = `${TEXT.asn} - ${asnData.id}`;
                  items[i].reference_detail = asnData.vendor_reference ? asnData.vendor_reference.name : '';
                }).catch(() => { });
              } else if (item.type === 'outbound') {
                await OrderService.list(null, null, 0, 1, null, null, null, null, null, item.id).then(async (data: any) => {
                  const orderData: any = data[0];
                  items[i].reference = `${TEXT.order} - ${orderData.id}`;
                  items[i].reference_detail = orderData.order_no;
                }).catch(() => { });
              } else if (item.type === 'withdraw') {
                await WithdrawService.listByJob(item.id, null, null, 0, 1, null, true).then(async (data: any) => {
                  const withdrawData: any = data[0];
                  items[i].reference = `${TEXT.withdraw} - ${withdrawData.id}`;
                  items[i].reference_detail = '';
                }).catch(() => { });
              } else if (item.type === 'transfer') {
                await TransferService.listByJob(item.id, null, null, 0, 1, null, true).then(async (data: any) => {
                  const transferData: any = data[0];
                  items[i].reference = `${TEXT.transfer} - ${transferData.id}`;
                  items[i].reference_detail = '';
                }).catch(() => { });
              } else if (item.type === 'cancel') {
                await CancelService.listByJob(item.id, null, null, 0, 1, null, true).then(async (data: any) => {
                  const cancelData: any = data[0];
                  items[i].reference = `${TEXT.cancel} - ${cancelData.id}`;
                  items[i].reference_detail = '';
                }).catch(() => { });
              }
              i++;
            }
            resolve(items);
          });
        }
        await setInstrument(data).then((jobs: any) => {
          setJobs(jobs);
        });
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    });
    setShowProgressLoading(false);
    count().then(async (rows: any) => {
      setTotalAll(rows);
    });
    count('inbound').then(async (rows: any) => {
      setTotalInbound(rows);
    });
    count('outbound').then(async (rows: any) => {
      setTotalOutbound(rows);
    });
    count('transfer').then(async (rows: any) => {
      setTotalTransfer(rows);
    });
    count('withdraw').then(async (rows: any) => {
      setTotalWithdraw(rows);
    });
    count('cancel').then(async (rows: any) => {
      setTotalCancel(rows);
    });
  }

  const remove = async (id: any, name: any) => {

    const success = (result: any) => {

      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      for (let i = 0; i < jobs.length; i++) {
        if (result.id === jobs[i].id) {
          const data = [...jobs];
          data.splice(i, 1);
          setJobs(data);
          break;
        }
      }
      const count = (countType: any = '') => {
        return new Promise(async (resolve) => {
          await JobService.count(
            true,
            null,
            null,
            countType,
            getValues('status'),
            getValues('search'),
            getValues('dateFrom') ? moment(`${getValues('dateFrom')} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
            getValues('dateTo') ? moment(`${getValues('dateTo')} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null
          ).then(async (rows: any) => {
            resolve(rows);
          });
        });
      }
      count().then(async (rows: any) => {
        setTotalAll(rows);
      });
      count('inbound').then(async (rows: any) => {
        setTotalInbound(rows);
      });
      count('outbound').then(async (rows: any) => {
        setTotalOutbound(rows);
      });
      count('transfer').then(async (rows: any) => {
        setTotalTransfer(rows);
      });
      count('withdraw').then(async (rows: any) => {
        setTotalWithdraw(rows);
      });
      count('cancel').then(async (rows: any) => {
        setTotalCancel(rows);
      });
    }

    setPresentAlert({
      isPresent: true,
      message: `${TEXT.removePrompt} "${name}"?`,
      onDismiss: (data: boolean) => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          if (data) {
            JobService.delete(id).then(async (result: any) => {
              success(result);
            }).catch((error) => {
              setPresentToast({
                isPresent: true,
                status: false,
                message: error,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            });
          }
        }
      }
    });

  }

  const handlePageChange = async (currentPage: number) => {
    setPage(currentPage);
    load((currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
  };

  const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
    setPage(currentPage);
    setPageLimit(newPerPage);
    load((currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSort({ by: column.id, direction: sortDirection });
    load((page - 1) * pageLimit, pageLimit, column.id, sortDirection);
  };

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname.indexOf(RoutePath.job) >= 0) {
      if (initialized) {

        const urlParams = new URLSearchParams(location.search);
        const statusParam = urlParams.get('status');
        if (statusParam !== null) {
          setValue('status', statusParam);
        } else {
          setValue('status', 'pending');
          UtilitiesService.updateURL('status', 'pending');
        }

        load();

      }
    }
  }, [location]);

  return (
    members.length && (
      members.includes('1')
      || members.includes('2')
      || members.includes('3')
      || members.includes('5')
    )
      ?
      <IonPage>

        <IonModal canDismiss swipeToClose isOpen={modalAddIsOpen} onDidDismiss={() => setModalAddIsOpen(false)}>
          <ModalJobAdd
            onDismiss={
              (data: any) => {
                setModalAddIsOpen(false);
              }
            }
          />
        </IonModal>

        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>{MENU.job}</IonTitle>
            <IonButtons slot='end'>
              <IonButton slot="end" onClick={() => { setModalAddIsOpen(true); }}>
                <IonIcon slot="start" icon={add} /> {BUTTON.add}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          {
            showProgressLoading &&
            <IonProgressBar type="indeterminate" className='fixed'></IonProgressBar>
          }
        </IonHeader>

        <IonContent fullscreen>

          <IonLoading
            isOpen={showLoading}
            message={TEXT.pleaseWait}
          />

          <AlertPrompt
            isPresent={presentAlert.isPresent}
            message={presentAlert.message}
            onDismiss={presentAlert.onDismiss}
          />

          <ToastStatus
            isPresent={presentToast.isPresent}
            status={presentToast.status}
            message={presentToast.message}
            onDismiss={presentToast.onDismiss}
          />

          <IonGrid className='grid-main grid-has-ios-header'>
            <IonRow>
              <IonCol>
                <IonHeader collapse="condense">
                  <IonToolbar>
                    <IonTitle size="large" slot="start">{MENU.job}</IonTitle>
                  </IonToolbar>
                </IonHeader>
              </IonCol>
            </IonRow>
          </IonGrid>

          {
            initialized ?
              <>

                <IonGrid>
                  <IonRow className='row-toolbar ion-margin-top'>
                    <IonCol size='12' sizeMd='3'>
                      <IonToolbar className='toolbar-page'>
                        <IonItem lines='none'>
                          <IonSearchbar
                            placeholder={TEXT.jobNo}
                            inputmode="search"
                            {...filterSearch}
                            onIonChange={(e: SearchbarCustomEvent) => { setValue('search', e.detail.value) }}
                            onKeyUp={(e: KeyboardEvent) => {
                              if (e.key === "Enter") {
                                load(
                                  0,
                                  pageLimit,
                                  sort.by,
                                  sort.direction,
                                  getValues('type'),
                                  getValues('status'),
                                  (e.target as HTMLTextAreaElement).value,
                                  getValues('dateFrom'),
                                  getValues('dateTo')
                                );
                                UtilitiesService.updateURL('search', (e.target as HTMLTextAreaElement).value);
                              }
                            }}
                            onIonClear={async () => {
                              setValue('search', '');
                              load(
                                0,
                                pageLimit,
                                sort.by,
                                sort.direction,
                                getValues('type'),
                                getValues('status'),
                                '',
                                getValues('dateFrom'),
                                getValues('dateTo')
                              );
                              UtilitiesService.updateURL('search', '');
                            }}
                          >
                          </IonSearchbar>
                        </IonItem>
                      </IonToolbar>
                    </IonCol>
                    <IonCol size='12' sizeMd='3'>
                      <IonToolbar className='toolbar-page'>
                        <IonItem lines='none' className="item-hidden-label">
                          <IonLabel position="fixed">{TEXT.status}</IonLabel>
                          <IonSelect
                            interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                            placeholder={TEXT.status} value={getValues('status')}
                            {...filterStatus}
                            onIonChange={(e: any) => { filterStatus.onChange(e) }}>
                            <IonSelectOption value="">{TEXT.allStatus}</IonSelectOption>
                            {
                              Object.keys(statuses).map((item: any, index: number) => (
                                <IonSelectOption key={`status-${index}`} value={item}>{statuses[item]}</IonSelectOption>
                              ))
                            }
                          </IonSelect>
                        </IonItem>
                      </IonToolbar>
                    </IonCol>
                    <IonCol size='12' sizeMd='3'>
                      <IonToolbar className='toolbar-page'>
                        <IonItem lines='none'>
                          <IonLabel position="fixed">{TEXT.dateFrom}</IonLabel>
                          <IonInput type="date" value={getValues('dateFrom')}
                            {...filterDateFrom}
                            onIonChange={(e: any) => { filterDateFrom.onChange(e) }}>
                          </IonInput>
                        </IonItem>
                      </IonToolbar>
                    </IonCol>
                    <IonCol size='12' sizeMd='3'>
                      <IonToolbar className='toolbar-page'>
                        <IonItem lines='none'>
                          <IonLabel position="fixed">{TEXT.dateTo}</IonLabel>
                          <IonInput type="date" value={getValues('dateTo')}
                            {...filterDateTo}
                            onIonChange={(e: any) => { filterDateTo.onChange(e) }}>
                          </IonInput>
                        </IonItem>
                      </IonToolbar>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonGrid className='grid-main'>
                  <IonSegment className='table-segment' mode='md' scrollable={true}
                    value={getValues('type') ? getValues('type') : ''}
                    disabled={showProgressLoading}
                    {...filterType}
                    onIonChange={(e: any) => {

                      filterType.onChange(e);
                    }}>
                    <IonSegmentButton value="">
                      <IonLabel>
                        {TEXT.all}
                        {
                          totalAll ?
                            <IonBadge>
                              <NumberFormat value={totalAll} displayType={'text'} thousandSeparator={true} />
                            </IonBadge>
                            : <></>
                        }
                      </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="inbound">
                      <IonLabel>
                        {types['inbound']}
                        {
                          totalInbound ?
                            <IonBadge>
                              <NumberFormat value={totalInbound} displayType={'text'} thousandSeparator={true} />
                            </IonBadge>
                            : <></>
                        }
                      </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="outbound">
                      <IonLabel>
                        {types['outbound']}
                        {
                          totalOutbound ?
                            <IonBadge>
                              <NumberFormat value={totalOutbound} displayType={'text'} thousandSeparator={true} />
                            </IonBadge>
                            : <></>
                        }
                      </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="transfer">
                      <IonLabel>
                        {types['transfer']}
                        {
                          totalTransfer ?
                            <IonBadge>
                              <NumberFormat value={totalTransfer} displayType={'text'} thousandSeparator={true} />
                            </IonBadge>
                            : <></>
                        }
                      </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="withdraw">
                      <IonLabel>
                        {types['withdraw']}
                        {
                          totalWithdraw ?
                            <IonBadge>
                              <NumberFormat value={totalWithdraw} displayType={'text'} thousandSeparator={true} />
                            </IonBadge>
                            : <></>
                        }
                      </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="cancel">
                      <IonLabel>
                        {types['cancel']}
                        {
                          totalCancel ?
                            <IonBadge>
                              <NumberFormat value={totalCancel} displayType={'text'} thousandSeparator={true} />
                            </IonBadge>
                            : <></>
                        }
                      </IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </IonGrid>

                {
                  (total < offset) ?
                    <DataTable
                      columns={columns}
                      data={jobs}
                      customStyles={pageTable}
                      pagination
                      noDataComponent={TEXT.noDataComponent}
                      paginationComponentOptions={paginationComponentOptions}
                      defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                      defaultSortFieldId={defaultSort.by}
                      progressPending={showProgressLoading}
                      progressComponent={<IonSpinner color="primary" />}
                    />
                    :
                    <DataTable
                      columns={columns}
                      data={jobs}
                      customStyles={pageTable}
                      pagination
                      noDataComponent={TEXT.noDataComponent}
                      paginationComponentOptions={paginationComponentOptions}
                      paginationServer
                      paginationTotalRows={total}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      sortServer
                      defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                      defaultSortFieldId={defaultSort.by}
                      onSort={handleSort}
                      progressPending={showProgressLoading}
                      progressComponent={<IonSpinner color="primary" />}
                    />
                }

              </>
              : <></>
          }

        </IonContent>
      </IonPage>
      :
      <IonPage>
        <IonContent fullscreen>
          <Error header={MESSAGE.error.not_found} />
        </IonContent>
      </IonPage>
  );
};

export default Jobs;
