import '../../css/Modal.scss';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  SearchbarCustomEvent
} from "@ionic/react";
import {
  closeOutline
} from "ionicons/icons";

import { useEffect, useState } from "react";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import WarehouseService from "../../services/WarehouseService";

export const ModalWarehouses: React.FC<{
  title: string;
  selected?: string;
  onDismiss: (warehouse?: any) => any;
}> = ({ title = TEXT.warehouse, selected = '', onDismiss }) => {

  const pageLimit = 30;

  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [warehouses, setWarehouses] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState<string>('');

  const searchWarehouses = async (search = '', event: any = null) => {
    setSearchText(search);
    let start = 0;
    if (event) {
      start = warehouses.length;
    }
    WarehouseService.list(
      true,
      { by: 'name', direction: 'asc' },
      start, pageLimit,
      search,
      ['logo']
    ).then((data: any) => {
      if (event) {
        setWarehouses([
          ...warehouses,
          ...data
        ]);
        event.target.complete();
        if (!data.length) {
          setInfiniteDisabled(true);
        } else {
          setInfiniteDisabled(false);
        }
      } else {
        setWarehouses(data);
      }
    });
  }

  const setWarehouse = (warehouse: any) => {
    onDismiss(warehouse);
  }

  useEffect(() => {
    searchWarehouses();
  }, []);

  return (
    <>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder={TEXT.warehouseName}
            inputmode="none"
            onIonChange={(e: SearchbarCustomEvent) => searchWarehouses(e.detail.value)}
          >
          </IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent className="search">
        <IonList>
          {
            warehouses.map((item, index: number) => (
              <IonItem button detail={false} key={`warehouse-${index}`} onClick={() => setWarehouse(item)}
                className={selected && selected === item.id ? 'selected' : ''}>
                <IonLabel>{item.name}</IonLabel>
              </IonItem>
            ))
          }
        </IonList>

        <IonInfiniteScroll
          onIonInfinite={e => searchWarehouses(searchText, e)}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>

      </IonContent>

    </>
  );

};