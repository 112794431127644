import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect } from "react";

import {
  useIonAlert
} from "@ionic/react";

import MESSAGE from '../../constances/Messages.json';
import BUTTON from '../../constances/Button.json';

export const AlertPrompt: React.FC<{
  message?: string;
  isPresent?: boolean;
  isDismiss?: boolean;
  onDismiss?: (data?: any) => any;
}> = ({ message = '', isPresent = false, isDismiss = false, onDismiss }) => {

  const [presentAlert, dismissAlert] = useIonAlert();
  const present = async (message: string = '', icon: string = '') => {
    if (!message) {
      message = MESSAGE.success.complete;
    }
    let option: any = {
      message: `<div>${message}</div>`,
      translucent: true,
      buttons: [
        {
          text: BUTTON.cancel,
          role: 'cancel',
          handler: () => {
            onDismiss && onDismiss(false);
          }
        },
        {
          text: BUTTON.ok,
          handler: () => {
            onDismiss && onDismiss(true);
          }
        }
      ],
      cssClass: 'success'
    }
    presentAlert(option);
  }

  useEffect(() => {
    if (isPresent) {
      present(message);
    }
  }, [isPresent]);

  useEffect(() => {
    if (isDismiss === true) {
      dismissAlert();
    }
  }, [isDismiss]);

  return (
    <></>
  );

};