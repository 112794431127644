import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';

import moment from 'moment';

import TEXT from '../constances/Text.json';
import { pageTable } from './TableStyle';
import { Direction, RoutePath } from './RoutePath';

import UserService from '../services/UserService';
import { ToastStatus } from './toasts/ToastStatus';
import { copyOutline } from 'ionicons/icons';

const ProductLots: React.FC<{data: any;}> = ({ data = [] }) => {
  
  const [members, setMembers] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => {}
  });

  const columns: any = [
    {
      name: TEXT.asnNumber,
      selector: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.asn ? row.asn_product_reference.asn : '-'
      ),
      cell: (row: { asn_product_reference: any, job: any }) => (
        row.asn_product_reference && row.asn_product_reference.asn ? 
        <IonItem lines="none" detail={false} routerLink={RoutePath.productImportView.replace(':id', row.asn_product_reference.asn)}
          routerDirection={Direction()}>
          <IonLabel className='text-full'>
            {row.asn_product_reference.asn}
          </IonLabel>
        </IonItem>
        : '-'
      ),
      sortable: true,
    },
    {
      name: TEXT.lotNumber,
      selector: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.lot_number ? row.asn_product_reference.lot_number : '-'
      ),
      cell: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.lot_number ? row.asn_product_reference.lot_number : '-'
      ),
      sortable: true,
    },
    {
      name: TEXT.expireDate,
      selector: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.expire_date ? row.asn_product_reference.expire_date : '-'
      ),
      cell: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.expire_date ? 
        <IonItem lines="none" detail={false}>
          <IonLabel className='text-full ion-text-center'>
            { moment.utc(row.asn_product_reference.expire_date).local().format('LL')}
            <div className='subtitle'>
              { moment.utc(row.asn_product_reference.expire_date).local().format('LT') }
            </div>
          </IonLabel>
        </IonItem>
        : '-'
      ),
      center: true,
      sortable: true,
    },
    {
      name: TEXT.qtyPiece,
      selector: (row: { quantity: any; }) => row.quantity,
      cell: (row: { quantity: any }) => (
        <NumberFormat className={
          (parseInt(row.quantity) <= 0 ? 'text-danger' : 'text-success') + ' text-number' 
        } value={row.quantity} displayType={'text'} thousandSeparator={true} />
      ),
      right: true,
      sortable: true,
    },
    {
      name: TEXT.qtyLeft,
      selector: (row: { left: any; }) => row.left,
      cell: (row: { left: any }) => (
        <NumberFormat className={
          (parseInt(row.left) <= 0 ? 'text-danger' : 'text-success') + ' text-number' 
        } value={row.left} displayType={'text'} thousandSeparator={true} />
      ),
      right: true,
      sortable: true,
    }
  ];

  const columnsAdmin: any = [
    {
      name: TEXT.asnNumber,
      selector: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.asn ? row.asn_product_reference.asn : '-'
      ),
      cell: (row: { asn_product_reference: any, job: any }) => (
        row.asn_product_reference && row.asn_product_reference.asn && row.asn_product_reference.asn_reference ? 
        <IonItem lines="none" detail={false} routerLink={RoutePath.jobView.replace(':id', row.asn_product_reference.asn_reference.job)}
          routerDirection={Direction()}>
          <IonLabel className='text-full'>
            {row.asn_product_reference.asn}
          </IonLabel>
        </IonItem>
        : '-'
      ),
      sortable: true,
    },
    {
      name: TEXT.lotNumber,
      selector: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.lot_number ? row.asn_product_reference.lot_number : '-'
      ),
      cell: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.lot_number ? row.asn_product_reference.lot_number : '-'
      ),
      center: true,
      sortable: true,
    },
    {
      name: TEXT.expireDate,
      selector: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.expire_date ? row.asn_product_reference.expire_date : '-'
      ),
      cell: (row: { asn_product_reference: any }) => (
        row.asn_product_reference && row.asn_product_reference.expire_date ? 
        <IonItem detail={false}>
          <IonLabel className='text-full ion-text-center'>
            { moment.utc(row.asn_product_reference.expire_date).local().format('LL')}
            <div className='subtitle'>
              { moment.utc(row.asn_product_reference.expire_date).local().format('LT') }
            </div>
          </IonLabel>
        </IonItem>
        : '-'
      ),
      center: true,
      sortable: true,
    },
    {
      name: TEXT.location,
      selector: (row: { warehouses: any; }) => row.warehouses,
      cell: (row: { id: any, warehouses: any; }) => (
        row.warehouses ?
        row.warehouses.map(
          (item: any, index: number) => 
          <CopyToClipboard key={`location-${row.id}-${index}`} text={
            item.warehouse
            + '.' + item.floor
            + '.' +  item.aisle
            + '.' +  item.bay
            + '.' +  item.level 
            + '.' +  item.bin 
          } onCopy={() => {
            setPresentToast({
              isPresent: true, 
              status: true, 
              message: `${
                item.warehouse
                + '.' +  item.floor
                + '.' +  item.aisle
                + '.' +  item.bay
                + '.' +  item.level 
                + '.' +  item.bin 
              } ${TEXT.wasCopied}`,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          }}>
            <IonButton fill='clear'>
              {
                item.warehouse
                + '.' +  item.floor
                + '.' +  item.aisle
                + '.' +  item.bay
                + '.' +  item.level 
                + '.' +  item.bin 
              }
              <IonIcon size="small" slot="end" icon={copyOutline} />
            </IonButton>
          </CopyToClipboard>
        )
        : '-'
      ),
      center: true,
      sortable: true,
    },
    {
      name: TEXT.qtyPiece,
      selector: (row: { quantity: any; }) => row.quantity,
      cell: (row: { quantity: any }) => (
        <NumberFormat className={
          (parseInt(row.quantity) <= 0 ? 'text-danger' : 'text-success') + ' text-number' 
        } value={row.quantity} displayType={'text'} thousandSeparator={true} />
      ),
      right: true,
      sortable: true,
    },
    {
      name: TEXT.qtyLeft,
      selector: (row: { left: any; }) => row.left,
      cell: (row: { left: any }) => (
        <NumberFormat className={
          (parseInt(row.left) <= 0 ? 'text-danger' : 'text-success') + ' text-number' 
        } value={row.left} displayType={'text'} thousandSeparator={true} />
      ),
      right: true,
      sortable: true,
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const initialize = async () => {
    const userData: any = await UserService.getSession();
    if (userData) {
      setMembers(
        userData.members.map(
          ({ group_id }: any) => (
            group_id
          )
        )
      );
    }
    setInitialized(true);
  }

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  return (
    <>

      <ToastStatus 
      isPresent={presentToast.isPresent} 
      status={presentToast.status} 
      message={presentToast.message} 
      onDismiss={presentToast.onDismiss} 
      />

      {
        data && initialized ? 
        !members.length || (
          !members.includes('1')
          && !members.includes('2')
          && !members.includes('3')
        )
        ? 
        <DataTable
          columns={columns}
          data={data}
          customStyles={pageTable}
          pagination
          noDataComponent={TEXT.noDataComponent}
          paginationComponentOptions={paginationComponentOptions}
        />
        :
        <DataTable
          columns={columnsAdmin}
          data={data}
          customStyles={pageTable}
          pagination
          noDataComponent={TEXT.noDataComponent}
          paginationComponentOptions={paginationComponentOptions}
        />
      : <></>
      }

    </>
  );
};

export default ProductLots;