import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonBackButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader
} from "@ionic/react";
import {
  banOutline
} from "ionicons/icons";

import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';

import { RoutePath } from "../components/RoutePath";

import Withdraw from "../components/Withdraw";

const WithdrawsView: React.FC = () => {

  const { id } = useParams<{ id: string; }>();

  const [withdrawStatus, setASNStatus] = useState<any>(null);
  const [doChangeStatus, setDoChangeStatus] = useState<any>(null);

  const [reload, setReload] = useState<any>(new Date().getTime());
  const [showProgressLoading, setShowProgressLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === RoutePath.productWithdrawView.replace(':id', id)) {
      setReload(new Date().getTime());
    }
  }, [location]);

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className="ion-hide-lg-up">
            <IonBackButton defaultHref={RoutePath.cancel} text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.productWithdraw}</IonTitle>
          <IonButtons slot="end">
            {
              withdrawStatus !== 'cancel' ?
                <IonButton onClick={() => setDoChangeStatus('cancel')}>
                  <IonIcon slot="start" icon={banOutline} />
                  {BUTTON.cancel}
                </IonButton>
                : <></>
            }
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        {
          id ?
            <Withdraw className="ion-margin-top"
              id={id}
              onStatusChange={(status: any) => { setDoChangeStatus(null); setASNStatus(status); }}
              doChangeStatus={doChangeStatus}
              reload={reload}
              onLoad={(data: any) => setShowProgressLoading(data)}
              onCancelStatusChange={() => setDoChangeStatus(null)}
            />
            : <></>
        }

      </IonContent>
    </IonPage>
  );
}

export default WithdrawsView;