import { useState } from "react";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonBackButton,
  IonPage,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import {
  saveOutline
} from "ionicons/icons";

import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';

import { GoBack, RoutePath } from '../components/RoutePath';
import { AdministratorForm } from "../components/forms/AdministratorForm";

const AdministratorsAdd: React.FC = () => {

  const [doSubmit, setDoSubmit] = useState<boolean>(false);

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className='ion-hide-lg-up'>
            <IonBackButton defaultHref={RoutePath.brand} text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.administratorAdd}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setDoSubmit(true)}>
              <IonIcon slot="start" icon={saveOutline} />
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
              <AdministratorForm isSubmit={doSubmit} onDidSubmit={(data: any) => { data && GoBack(); setDoSubmit(false) }} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

    </IonPage>
  );

};

export default AdministratorsAdd;