import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DataTable from 'react-data-table-component';

import {
  IonAccordion,
  IonAccordionGroup,
  IonBadge,
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonSearchbar,
  IonRow,
  IonCol,
  useIonModal,
  IonChip,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle,
  isPlatform,
  IonMenuButton,
  IonSelect,
  IonSelectOption,
  IonInput,
  SearchbarCustomEvent
} from '@ionic/react';
import {
  add,
  cloudUploadOutline,
  copyOutline
} from 'ionicons/icons';

import moment from 'moment';

import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import MESSAGE from '../constances/Messages.json';

import { pageTable } from '../components/TableStyle';
import { Direction, RoutePath } from '../components/RoutePath';
import { ToolbarStart } from '../components/ToolbarStart';

import { ModalASNImport } from '../components/modal/ASNImport';
import { AlertPrompt } from '../components/alerts/AlertPrompt';
import { ToastStatus } from '../components/toasts/ToastStatus';

import ASNService from '../services/ASNService';
import UserService from '../services/UserService';
import VendorService from '../services/VendorService';

const ASNs: React.FC = () => {

  const offset: number = 50;

  const defaultSort: any = {
    by: ['expect_date', 'create_at'],
    direction: 'desc'
  }

  const [vendor, setVendor] = useState<any>(null);
  const [ASNs, setASNs] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [importResult, setImportResult] = useState<any>(null);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [sort, setSort] = useState<any>(defaultSort);
  const [total, setTotal] = useState<number>(0);

  const { register, getValues, setValue } = useForm();
  const [filterStatus] = useState<any>(
    register('status', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, e.detail.value, getValues('search'), getValues('dateFrom'), getValues('dateTo'), e.vendorID);
      }
    })
  );
  const [filterSearch] = useState<any>(
    register('search', {
      value: ''
    })
  );
  const [filterDateFrom] = useState<any>(
    register('dateFrom', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('status'), getValues('search'), e.detail.value, getValues('dateTo'), e.vendorID);
      }
    })
  );
  const [filterDateTo] = useState<any>(
    register('dateTo', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('status'), getValues('search'), getValues('dateFrom'), e.detail.value, e.vendorID);
      }
    })
  );

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const columns: Array<any> = [
    {
      name: TEXT.asnNumber,
      selector: (row: { id: number }) => row.id,
      cell: (row: { id: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productImportView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='ion-text-center'>
            {row.id}
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      width: '120px'
    },
    {
      name: TEXT.submitCreateDate,
      selector: (row: { create_at: any }) => row.create_at,
      cell: (row: { id: any, create_at: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productImportView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-center'>
            {row.create_at ? moment.utc(row.create_at).local().format('LL') : '-'}
            <div className='subtitle'>
              {row.create_at ? moment.utc(row.create_at).local().format('LT') : ''}
            </div>
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      minWidth: '250px',
    },
    {
      name: TEXT.expectedDate,
      selector: (row: { expect_date: any }) => row.expect_date,
      cell: (row: { id: any, expect_date: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productImportView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-center'>
            {row.expect_date ? moment.utc(row.expect_date).local().format('LL') : '-'}
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      minWidth: '250px',
    },
    {
      name: TEXT.receivedDate,
      selector: (row: { receive_date: any }) => row.receive_date,
      cell: (row: { id: any, receive_date: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productImportView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-center'>
            {row.receive_date ? moment.utc(row.receive_date).local().format('LL') : '-'}
            <div className='subtitle'>
              {row.receive_date ? moment.utc(row.receive_date).local().format('LT') : ''}
            </div>
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      minWidth: '250px',
    },
    {
      name: TEXT.status,
      selector: (row: { status: any }) => row.status,
      cell: (row: { status: any }) => (
        <IonButtons>
          <IonButton fill='solid' color={colors[row.status]}>
            <IonLabel>{statuses[row.status]}</IonLabel>
          </IonButton>
        </IonButtons>
      ),
      width: '150px',
      center: true,
      sortable: true
    },
  ];

  const location = useLocation();

  const colors: any = COLORS.asn;
  const statuses: any = STATUS.asn;

  const [presentCSV, dismissImport] = useIonModal(ModalASNImport, {
    options: {
      canDismiss: true,
      swipeToClose: true
    },
    asnID: null,
    onDismiss: async (data: any) => {
      dismissImport();
      if (data) {
        setShowLoading(true);
        setImportResult(data);
        setPage(1);
        await load(1, pageLimit, sort.by, sort.direction);
        setShowLoading(false);
      }
    }
  });

  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const initialize = async () => {
    setInitialized(false);
    setShowLoading(true);
    setImportResult(null);
    const userData: any = await UserService.getSession();
    if (userData) {
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
        await load(
          0,
          pageLimit,
          defaultSort.by,
          defaultSort.direction,
          getValues('status'),
          getValues('search'),
          getValues('dateFrom'),
          getValues('dateTo'),
          vendorData.id
        );
      }).catch(() => { });
    }
    setInitialized(true);
    setShowLoading(false);
  }

  const load = async (
    start: number = 0,
    limit: number | null = pageLimit,
    sortBy: string = defaultSort.by,
    sortDirection: string = defaultSort.direction,
    status: string = getValues('status'),
    search: string = getValues('search'),
    dateFrom: string = getValues('dateFrom'),
    dateTo: string = getValues('dateTo'),
    vendorID: string = (vendor ? vendor.id : null)
  ) => {
    if (vendorID) {
      await ASNService.count(
        null,
        null,
        null,
        status,
        search,
        dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        vendorID
      ).then(async (rows: any) => {
        setTotal(rows);
        if (rows < offset) {
          start = 0;
          limit = null;
          sortBy = defaultSort.by;
          sortDirection = defaultSort.direction;
        }
        await ASNService.list(
          null,
          { by: sortBy, direction: sortDirection },
          start,
          limit,
          status,
          search,
          dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
          dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
          true,
          vendorID
        ).then((data: any) => {
          setASNs(data);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      });
    }
  }

  const remove = async (id: any, name: string) => {

    const success = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.remove_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      for (let i = 0; i < ASNs.length; i++) {
        if (result.id === ASNs[i].id) {
          const data = [...ASNs];
          data.splice(i, 1);
          setASNs(data);
          break;
        }
      }
    }

    setPresentAlert({
      isPresent: true,
      message: `${TEXT.removePrompt} "${name}"?`,
      onDismiss: (data: boolean) => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          if (data) {
            ASNService.delete(id).then(async (result: any) => {
              success(result);
            }).catch((error) => {
              setPresentToast({
                isPresent: true,
                status: false,
                message: error,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            });
          }
        }
      }
    });

  }

  const handlePageChange = async (currentPage: number) => {
    setPage(currentPage);
    load((currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
  };

  const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
    setPage(currentPage);
    setPageLimit(newPerPage);
    load((currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSort({ by: column.id, direction: sortDirection });
    load((page - 1) * pageLimit, pageLimit, column.id, sortDirection);
  };

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.productImport) {
      load();
    }
  }, [location]);

  return (
    <IonPage className='product-view'>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertPrompt
        isPresent={presentAlert.isPresent}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{MENU.productImport}</IonTitle>
          <IonButtons slot='end'>
            <IonButton routerLink={RoutePath.productImportAdd} slot="end" 
            routerDirection={Direction()}>
              <IonIcon slot="start" icon={add} /> {BUTTON.add}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <IonGrid className='grid-main grid-has-ios-header'>
          <IonRow>
            <IonCol>
              <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large" slot="start">{MENU.productImport}</IonTitle>
                </IonToolbar>
              </IonHeader>
            </IonCol>
          </IonRow>
        </IonGrid>

        {
          vendor ?
            <IonGrid>
              <IonRow className='row-toolbar ion-margin-top'>
                <IonCol size='12' sizeMd='3'>
                  <IonToolbar className='toolbar-page'>
                    <IonItem lines="none">
                      <IonSearchbar
                        placeholder={TEXT.asnNumber}
                        inputmode="search"
                        {...filterSearch}
                        onIonChange={(e: SearchbarCustomEvent) => { setValue('search', e.detail.value); }}
                        onKeyUp={(e: KeyboardEvent) => {
                          if (e.key === "Enter") {
                            load(
                              0,
                              pageLimit,
                              sort.by,
                              sort.direction,
                              getValues('status'),
                              (e.target as HTMLTextAreaElement).value,
                              getValues('dateFrom'),
                              getValues('dateTo'),
                              vendor.id
                            );
                          }
                        }}
                        onIonClear={async () => {
                          setValue('search', '');
                          load(
                            0,
                            pageLimit,
                            sort.by,
                            sort.direction,
                            getValues('status'),
                            '',
                            getValues('dateFrom'),
                            getValues('dateTo'),
                            vendor.id
                          );
                        }}
                      >
                      </IonSearchbar>
                    </IonItem>
                  </IonToolbar>
                </IonCol>
                <IonCol size='12' sizeMd='2'>
                  <IonToolbar className='toolbar-page'>
                    <IonItem lines='none' className="item-hidden-label">
                      <IonLabel position="fixed">{TEXT.status}</IonLabel>
                      <IonSelect
                        interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                        placeholder={TEXT.status} value={getValues('status')}
                        {...filterStatus}
                        onIonChange={(e: any) => { e.vendorID = vendor.id; filterStatus.onChange(e) }}>
                        <IonSelectOption value="">{TEXT.allStatus}</IonSelectOption>
                        {
                          Object.keys(statuses).map((item: any, index: number) => (
                            <IonSelectOption key={`status-${index}`} value={item}>{statuses[item]}</IonSelectOption>
                          ))
                        }
                      </IonSelect>
                    </IonItem>
                  </IonToolbar>
                </IonCol>
                <IonCol size='12' sizeMd='2'>
                  <IonToolbar className='toolbar-page'>
                    <IonItem lines='none'>
                      <IonLabel position="fixed">{TEXT.dateFrom}</IonLabel>
                      <IonInput type="date" value={getValues('dateFrom')}
                        {...filterDateFrom}
                        onIonChange={(e: any) => { e.vendorID = vendor.id; filterDateFrom.onChange(e) }}>
                      </IonInput>
                    </IonItem>
                  </IonToolbar>
                </IonCol>
                <IonCol size='12' sizeMd='2'>
                  <IonToolbar className='toolbar-page'>
                    <IonItem lines='none'>
                      <IonLabel position="fixed">{TEXT.dateTo}</IonLabel>
                      <IonInput type="date" value={getValues('dateTo')}
                        {...filterDateTo}
                        onIonChange={(e: any) => { e.vendorID = vendor.id; filterDateTo.onChange(e) }}>
                      </IonInput>
                    </IonItem>
                  </IonToolbar>
                </IonCol>
                <IonCol size='12' sizeMd='3'>
                  <IonToolbar className='toolbar-page'>
                    <IonButtons>
                      <IonButton fill='outline' color="tertiary" className='w-100' onClick={() => { presentCSV(); }}>
                        <IonIcon slot="start" icon={cloudUploadOutline} />&nbsp;{BUTTON.uploadCSV}
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonCol>
              </IonRow>
            </IonGrid>
            : <></>
        }

        {
          importResult ?
            <IonAccordionGroup>
              <IonAccordion value="creates">
                <IonItem slot="header" color="success">
                  <IonLabel>{importResult.creates ? importResult.creates.length : 0} {TEXT.creates}</IonLabel>
                </IonItem>
                {
                  importResult.creates && importResult.creates.length ?
                    <div className="ion-padding" slot="content">
                      {
                        importResult.creates.map((item: any, index: number) => (
                          <CopyToClipboard text={item.sku ? item.sku : ''} key={`creates-${index}`}
                            onCopy={() => {
                              setPresentToast({
                                isPresent: true,
                                status: true,
                                message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                onDismiss: () => {
                                  if (ready) {
                                    setPresentToast(
                                      {
                                        isPresent: false,
                                        status: presentToast.status,
                                        message: presentToast.message,
                                        onDismiss: presentToast.onDismiss
                                      }
                                    );
                                  }
                                }
                              });
                            }}>
                            <IonChip color="success">
                              <IonLabel>{item.sku ? item.sku : ''}</IonLabel>
                              <IonIcon icon={copyOutline}></IonIcon>
                            </IonChip>
                          </CopyToClipboard>
                        ))
                      }
                    </div>
                    : <></>
                }
              </IonAccordion>
              <IonAccordion value="updates">
                <IonItem slot="header" color="success">
                  <IonLabel>{importResult.updates ? importResult.updates.length : 0} {TEXT.updates}</IonLabel>
                </IonItem>
                {
                  importResult.updates && importResult.updates.length ?
                    <div className="ion-padding" slot="content">
                      {
                        importResult.updates.map((item: any, index: number) => (
                          <CopyToClipboard text={item.sku ? item.sku : ''} key={`updates-${index}`}
                            onCopy={() => {
                              setPresentToast({
                                isPresent: true,
                                status: true,
                                message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                onDismiss: () => {
                                  if (ready) {
                                    setPresentToast(
                                      {
                                        isPresent: false,
                                        status: presentToast.status,
                                        message: presentToast.message,
                                        onDismiss: presentToast.onDismiss
                                      }
                                    );
                                  }
                                }
                              });
                            }}>
                            <IonChip color="success">
                              <IonLabel>{item.sku ? item.sku : ''}</IonLabel>
                              <IonIcon icon={copyOutline}></IonIcon>
                            </IonChip>
                          </CopyToClipboard>
                        ))
                      }
                    </div>
                    : <></>
                }
              </IonAccordion>
              <IonAccordion value="errors">
                <IonItem slot="header" color="danger">
                  <IonLabel>{importResult.errors ? importResult.errors.length : 0} {TEXT.errors}</IonLabel>
                </IonItem>
                {
                  importResult.errors && importResult.errors.length ?
                    <div className="ion-padding" slot="content">
                      {
                        importResult.errors.map((item: any, index: number) => (
                          <CopyToClipboard text={item.sku ? item.sku : ''} key={`errors-${index}`}
                            onCopy={() => {
                              setPresentToast({
                                isPresent: true,
                                status: true,
                                message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                onDismiss: () => {
                                  if (ready) {
                                    setPresentToast(
                                      {
                                        isPresent: false,
                                        status: presentToast.status,
                                        message: presentToast.message,
                                        onDismiss: presentToast.onDismiss
                                      }
                                    );
                                  }
                                }
                              });
                            }}>
                            <IonChip color="danger">
                              <IonLabel>{`${item.sku ? item.sku : ''} ${item.reason ? `(${item.reason})` : ''}`}</IonLabel>
                              <IonIcon icon={copyOutline}></IonIcon>
                            </IonChip>
                          </CopyToClipboard>
                        ))
                      }
                    </div>
                    : <></>
                }
              </IonAccordion>
            </IonAccordionGroup>
            : <></>
        }

        {
          initialized ?
            (total < offset) ?
              <DataTable
                columns={columns}
                data={ASNs}
                customStyles={pageTable}
                pagination
                noDataComponent={TEXT.noDataComponent}
                paginationComponentOptions={paginationComponentOptions}
                defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                defaultSortFieldId={defaultSort.by}
              />
              :
              <DataTable
                columns={columns}
                data={ASNs}
                customStyles={pageTable}
                pagination
                noDataComponent={TEXT.noDataComponent}
                paginationComponentOptions={paginationComponentOptions}
                paginationServer
                paginationTotalRows={total}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                sortServer
                defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                defaultSortFieldId={defaultSort.by}
                onSort={handleSort}
              />
            : <></>
        }

      </IonContent>
    </IonPage>
  );
};

export default ASNs;