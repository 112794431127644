import { TableStyles } from "react-data-table-component";

export const tableStylesE5: TableStyles = {
  headCells: {
    style: {
      backgroundColor: '#E5E5E5',
      fontWeight: '700',
      fontSize: '14px',
      borderRadius: '10px'
    },
  },
};

export const tableStylesE5Hover = {
  rows: {
    style: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(0,0,0,.05)',
      },
    },
  },
  headCells: {
    style: {
      backgroundColor: '#E5E5E5',
      fontWeight: '700',
      fontSize: '14px',
    },
  },
};

export const pageTable: TableStyles = {
  progress: {
    style: {
      background: 'transparent',
      minHeight: '50px'
    }
  },
  table: {
    style: {
      marginTop: '16px',
      paddingInline: '20px',
      border: 'none',
      background: 'transparent'
    },
  },
  tableWrapper: {
    style: {
      position: 'relative',
      paddingBottom: '14px',
      transform: 'rotateX(180deg)'
    }
  },
  responsiveWrapper: {
    style: {
      transform: 'rotateX(180deg)'
    }
  },
  rows: {
    style: {
      boxShadow: 'rgba(0, 0, 0, 0.05) 0 0 0 1px',
      borderRadius: '10px',
      marginBottom: '10px',
      transition: '.2s',
      '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
        position: 'relative'
      },
      ':not(:last-of-type)': {
        border: 'none',
      }
    },
  },
  cells: {
    style: {
      borderBottom: 'none',
      paddingLeft: '9px',
      paddingRight: '9px',
      '&:first-child': {
        borderTopLeftRadius: '14px',
        borderBottomLeftRadius: '14px'
      },
      '&:last-child': {
        borderTopRightRadius: '14px',
        borderBottomRightRadius: '14px',
        '&:first-child': {
          borderRadius: '14px'
        }
      },
      'ion-thumbnail': {
        'ion-img': {
          '--border-radius': '5px'
        }
      },
      'ion-item': {
        width: '100%',
        '--background': 'transparent',
        '--border-color': 'transparent'
      },
      'ion-buttons': {
        'ion-button': {
          '--color': 'var(--ion-color-primary) !important',
          '--padding-start': '1em',
          '--padding-end': '1em',
          '--padding-inline-start': '1em',
          '--padding-inline-end': '1em',
          height: '36px !important',
          marginBottom: '0',
          '--border-radius': '14px !important',
          borderRadius: 'var(--border-radius) !important',
          marginInline: '5px !important',
          marginLeft: '5px !important',
          marginRight: '5px !important',
          '&:last-child': {
            marginBottom: '0'
          },
          '@media only screen and (min-width: 768px)': {
            width: 'initial',
            marginBottom: '0'
          }
        }
      },
      'ion-icon': {
        fontSize: '24px'
      },
      '.barcode': {
        'svg': {
          width: 'auto',
          height: '100%',
          maxWidth: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) !important'
        }
      }
    },
  },
  headRow: {
    style: {
      background: 'transparent',
      borderBottom: 'none',
      fontWeight: 'initial',
      '.rdt_TableCol': {
        '&:first-child': {
          borderTopLeftRadius: '14px',
          borderBottomLeftRadius: '14px'
        },
        '&:last-child': {
          borderTopRightRadius: '14px',
          borderBottomRightRadius: '14px',
          '&:first-child': {
            borderRadius: '14px'
          }
        }
      }
    },
  },
  headCells: {
    style: {
      height: '42px',
      color: 'rgba(var(--ion-color-dark-rgb), 0.5)',
      background: 'rgba(var(--ion-color-medium-rgb), 0.1)',
      fontSize: '14px',
      paddingLeft: '9px',
      paddingRight: '9px',
    },
  },
  pagination: {
    style: {
      background: 'transparent',
      border: 'none'
    }
  },
  noData: {
    style: {
      background: 'transparent'
    }
  }
};

export const pageTableExpandable: TableStyles = {
  progress: {
    style: {
      background: 'transparent',
      minHeight: '50px'
    }
  },
  table: {
    style: {
      marginTop: '16px',
      paddingInline: '20px',
      border: 'none',
      background: 'transparent'
    },
  },
  tableWrapper: {
    style: {
      position: 'relative',
      paddingBottom: '14px',
      transform: 'rotateX(180deg)'
    }
  },
  responsiveWrapper: {
    style: {
      transform: 'rotateX(180deg)'
    }
  },
  rows: {
    style: {
      boxShadow: 'rgba(0, 0, 0, 0.05) 0 0 0 1px',
      borderRadius: '10px',
      marginBottom: '10px',
      transition: '.2s',
      '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
        position: 'relative'
      },
      ':not(:last-of-type)': {
        border: 'none',
      }
    },
  },
  cells: {
    style: {
      borderBottom: 'none',
      paddingLeft: '9px',
      paddingRight: '9px',
      '&:first-child': {
        borderTopLeftRadius: '14px',
        borderBottomLeftRadius: '14px'
      },
      '&:last-child': {
        borderTopRightRadius: '14px',
        borderBottomRightRadius: '14px',
        '&:first-child': {
          borderRadius: '14px'
        }
      },
      'ion-thumbnail': {
        'ion-img': {
          '--border-radius': '5px'
        }
      },
      'ion-item': {
        width: '100%',
        '--background': 'transparent',
        '--border-color': 'transparent'
      },
      'ion-buttons': {
        'ion-button': {
          '--color': 'var(--ion-color-primary) !important',
          '--padding-start': '1em',
          '--padding-end': '1em',
          '--padding-inline-start': '1em',
          '--padding-inline-end': '1em',
          height: '36px !important',
          marginBottom: '0',
          '--border-radius': '14px !important',
          borderRadius: 'var(--border-radius) !important',
          marginInline: '5px !important',
          marginLeft: '5px !important',
          marginRight: '5px !important',
          '&:last-child': {
            marginBottom: '0'
          },
          '@media only screen and (min-width: 768px)': {
            width: 'initial',
            marginBottom: '0'
          }
        }
      },
      'ion-icon': {
        fontSize: '24px'
      },
      '.barcode': {
        'svg': {
          width: 'auto',
          height: '100%',
          maxWidth: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) !important'
        }
      }
    },
  },
  headRow: {
    style: {
      background: 'transparent',
      borderBottom: 'none',
      fontWeight: 'initial',
      '.rdt_TableCol': {
        '&:nth-child(2)': {
          borderTopLeftRadius: '14px',
          borderBottomLeftRadius: '14px'
        },
        '&:last-child': {
          borderTopRightRadius: '14px',
          borderBottomRightRadius: '14px',
          '&:first-child': {
            borderRadius: '14px'
          }
        }
      }
    },
  },
  headCells: {
    style: {
      height: '42px',
      color: 'rgba(var(--ion-color-dark-rgb), 0.5)',
      background: 'rgba(var(--ion-color-medium-rgb), 0.1)',
      fontSize: '14px',
      paddingLeft: '9px',
      paddingRight: '9px',
    },
  },
  pagination: {
    style: {
      background: 'transparent',
      border: 'none'
    }
  },
  expanderRow: {
    style: {
      background: 'transparent',
      'ion-card': {
        margin: '0 0 20px 0'
      }
    }
  },
  expanderButton: {
    style: {
      borderRadius: '10px'
    }
  },
  noData: {
    style: {
      background: 'transparent'
    }
  }
};

export const pageTableCompact: TableStyles = {
  progress: {
    style: {
      background: 'transparent',
      minHeight: '50px'
    }
  },
  table: {
    style: {
      marginTop: '14px',
      paddingLeft: '1px',
      paddingRight: '1px',
      background: 'transparent',
      border: 'none'
    },
  },
  tableWrapper: {
    style: {
      position: 'relative',
      paddingBottom: '0',
      transform: 'rotateX(180deg)'
    }
  },
  responsiveWrapper: {
    style: {
      transform: 'rotateX(180deg)'
    }
  },
  rows: {
    style: {
      boxShadow: 'rgba(0, 0, 0, 0.05) 0 0 0 1px',
      borderRadius: '10px',
      marginBottom: '10px',
      transition: '.2s',
      ':not(:last-of-type)': {
        border: 'none',
      }
    },
  },
  cells: {
    style: {
      borderBottom: 'none',
      paddingLeft: '9px',
      paddingRight: '9px',
      '&:first-child': {
        borderTopLeftRadius: '14px',
        borderBottomLeftRadius: '14px'
      },
      '&:last-child': {
        borderTopRightRadius: '14px',
        borderBottomRightRadius: '14px',
        '&:first-child': {
          borderRadius: '14px'
        }
      },
      'ion-thumbnail': {
        'ion-img': {
          '--border-radius': '5px'
        }
      },
      'ion-item': {
        width: '100%',
        '--background': 'transparent',
        '--border-color': 'transparent'
      },
      'ion-buttons': {
        'ion-button': {
          '--color': 'var(--ion-color-primary) !important',
          '--padding-start': '1em',
          '--padding-end': '1em',
          '--padding-inline-start': '1em',
          '--padding-inline-end': '1em',
          height: '36px !important',
          marginBottom: '0',
          '--border-radius': '14px !important',
          borderRadius: 'var(--border-radius) !important',
          marginInline: '5px !important',
          marginLeft: '5px !important',
          marginRight: '5px !important',
          '&:last-child': {
            marginBottom: '0'
          },
          '@media only screen and (min-width: 768px)': {
            width: 'initial',
            marginBottom: '0'
          }
        }
      },
      'ion-icon': {
        fontSize: '24px'
      },
      '.barcode': {
        'svg': {
          width: 'auto',
          height: '100%',
          maxWidth: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) !important'
        }
      }
    },
  },
  headRow: {
    style: {
      borderBottom: 'none',
      fontWeight: 'initial',
      '.rdt_TableCol': {
        '&:first-child': {
          borderTopLeftRadius: '14px',
          borderBottomLeftRadius: '14px'
        },
        '&:last-child': {
          borderTopRightRadius: '14px',
          borderBottomRightRadius: '14px',
          '&:first-child': {
            borderRadius: '14px'
          }
        }
      }
    },
  },
  headCells: {
    style: {
      height: '42px',
      color: 'rgba(var(--ion-color-dark-rgb), 0.5)',
      background: 'rgba(var(--ion-color-medium-rgb), 0.1)',
      fontSize: '14px',
      paddingLeft: '9px',
      paddingRight: '9px',
    },
  },
  pagination: {
    style: {
      background: 'transparent',
      border: 'none'
    }
  },
  noData: {
    style: {
      background: 'transparent'
    }
  }
};