import '../../css/Modal.scss';

import { useEffect, useRef, useState } from "react";
import NumberFormat from 'react-number-format';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonCard,
  IonCardContent,
  IonCol,
  IonImg,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
  IonItem,
  IonInput,
  IonFooter,
} from "@ionic/react";
import {
  archiveOutline,
  barcodeOutline,
  closeOutline
} from "ionicons/icons";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';
import MESSAGE from '../../constances/Messages.json';
import { ToastStatus } from '../toasts/ToastStatus';
import { AlertStatus } from '../alerts/AlertStatus';
import { AlertPrompt } from '../alerts/AlertPrompt';
import ProductService from '../../services/ProductService';
import UserService from '../../services/UserService';
import VendorService from '../../services/VendorService';
import ASNProductPutawayService from '../../services/ASNProductPutawayService';
import ASNService from '../../services/ASNService';

export const ModalBarcode: React.FC<{
  productsData: Array<any>;
  checksData?: Array<any>
  checkedData?: Array<any>;
  focusBarcode?: boolean;
  locationSet?: any;
  asnData?: any;
  onCheck: (product?: any) => any;
  onUpdate?: (result?: any) => any;
  onDismiss: (checks?: any) => any;
  onLocationSet?: (locationCode?: any) => any;
}> = ({
  productsData = [],
  checksData = [],
  checkedData = [],
  focusBarcode = false,
  locationSet = null,
  asnData = null,
  onCheck,
  onUpdate,
  onLocationSet,
  onDismiss
}) => {

    const slider = useRef<HTMLIonSlidesElement>(null);
    const barcodeInputRef = useRef<any>(null);

    const [vendor, setVendor] = useState<any>(null);
    const [products, setProducts] = useState<Array<any>>([]);
    const [checks, setChecks] = useState<Array<any>>([]);
    const [checked, setChecked] = useState<Array<any>>([]);
    const [ready, setReady] = useState(false);
    const [currentInput, setCurrentInput] = useState<string>('');
    const [currentLocation, setCurrentLocation] = useState<any>(null);

    const [presentToast, setPresentToast] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlert, setPresentAlert] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
      isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, message: '', onDismiss: () => { }
    });

    const slideOptsImage = {
      initialSlide: 0
    };

    const initialize = async () => {
      const userData: any = await UserService.getSession();
      if (userData) {
        await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
          setVendor(vendorData);
        }).catch(() => { });
      }
    }

    const check = async (text: string = '') => {
      if (currentLocation) {
        if (!text) {
          if (barcodeInputRef) {
            text = barcodeInputRef.current.value;
          }
        }
        if (
          products.map(
            (product: any) => product.product_reference ? product.product_reference.sku : null
          ).includes(text)
          || products.map(
            (product: any) => product.product_reference ? product.product_reference.barcode : null
          ).includes(text)
        ) {
          for (let i = 0; i < products.length; i++) {
            if (
              products[i].product_reference.barcode === text
              || products[i].product_reference.sku === text
            ) {
              if (
                countCheckedProduct(
                  products[i].product,
                  [
                    ...checks,
                    ...[{ products: checked }]
                  ]
                ) < parseInt(products[i].quantity)
              ) {
                onCheck && onCheck(products[i]);
                slider && slider.current! && slider.current!.slideTo(i);
                if (barcodeInputRef) {
                  barcodeInputRef.current.value = '';
                  barcodeInputRef.current.setFocus();
                }
              } else {
                setPresentAlertPrompt({
                  isPresent: true,
                  message: MESSAGE.error.product_all_added + '?',
                  onDismiss: (data: boolean) => {
                    if (ready) {
                      setPresentAlertPrompt(
                        {
                          isPresent: false,
                          message: presentAlertPrompt.message,
                          onDismiss: presentAlertPrompt.onDismiss
                        }
                      );
                      if (data) {
                        onCheck && onCheck(products[i]);
                      }
                      if (barcodeInputRef) {
                        barcodeInputRef.current.value = '';
                        barcodeInputRef.current.setFocus();
                      }
                    }
                  }
                });
              }
              break;
            }
          }
        } else {
          if (asnData) {
            setPresentAlertPrompt({
              isPresent: true,
              message: MESSAGE.error.product_not_in_list_force,
              onDismiss: (data: boolean) => {
                if (ready) {
                  setPresentAlertPrompt(
                    {
                      isPresent: false,
                      message: presentAlertPrompt.message,
                      onDismiss: presentAlertPrompt.onDismiss
                    }
                  );
                  if (data) {
                    ProductService.list(
                      true,
                      { by: 'name', direction: 'asc' },
                      0,
                      1,
                      text,
                      null,
                      vendor ? vendor.id : null
                    ).then(async (products: any) => {
                      if (products && products.length) {
                        const product: any = products[0];
                        await ASNService.import(
                          {
                            items: [
                              {
                                product: product.id,
                                quantity: 1,
                                manufactured_date: null,
                                expire_date: null,
                                lot_number: null
                              },
                              ...productsData.map((item: any) => (
                                {
                                  product: item.product,
                                  quantity: item.quantity,
                                  manufactured_date: item.manufactured_date,
                                  expire_date: item.expire_date,
                                  lot_number: item.lot_number
                                }
                              ))
                            ]
                          },
                          asnData.id,
                          vendor ? vendor.id : null
                        ).then(async (result: any) => {
                          let asnProductID: any = null;
                          if (result.creates && result.creates.length) {
                            asnProductID = result.creates[0].asn_product_id;
                          } else if (result.updates && result.updates.length) {
                            asnProductID = result.updates[0].asn_product_id;
                          }
                          if (asnProductID) {
                            await ASNProductPutawayService.import(asnProductID, {
                              items: [
                                {
                                  location: currentLocation,
                                  quantity: 1
                                }
                              ]
                            }).then(() => {
                              onUpdate && onUpdate(true);
                            }).catch((error) => {
                              setPresentAlert({
                                isPresent: true,
                                status: false,
                                message: error,
                                onDismiss: () => {
                                  if (ready) {
                                    setPresentAlert(
                                      {
                                        isPresent: false,
                                        status: presentToast.status,
                                        message: presentToast.message,
                                        onDismiss: presentToast.onDismiss
                                      }
                                    );
                                  }
                                }
                              });
                            });
                          }
                        }).catch((error) => {
                          setPresentAlert({
                            isPresent: true,
                            status: false,
                            message: error,
                            onDismiss: () => {
                              if (ready) {
                                setPresentAlert(
                                  {
                                    isPresent: false,
                                    status: presentToast.status,
                                    message: presentToast.message,
                                    onDismiss: presentToast.onDismiss
                                  }
                                );
                              }
                            }
                          });
                        });
                      } else {
                        setPresentAlert({
                          isPresent: true,
                          status: false,
                          message: MESSAGE.error.not_found,
                          onDismiss: () => {
                            if (ready) {
                              setPresentAlert(
                                {
                                  isPresent: false,
                                  status: presentToast.status,
                                  message: presentToast.message,
                                  onDismiss: presentToast.onDismiss
                                }
                              );
                            }
                          }
                        });
                      }
                    }).catch((error) => {
                      setPresentAlert({
                        isPresent: true,
                        status: false,
                        message: error,
                        onDismiss: () => {
                          if (ready) {
                            setPresentAlert(
                              {
                                isPresent: false,
                                status: presentToast.status,
                                message: presentToast.message,
                                onDismiss: presentToast.onDismiss
                              }
                            );
                          }
                        }
                      });
                    });
                  }
                  if (barcodeInputRef) {
                    barcodeInputRef.current.value = '';
                    barcodeInputRef.current.setFocus();
                  }
                }
              }
            });
          } else {
            setPresentAlert({
              isPresent: true,
              status: false,
              message: MESSAGE.error.product_not_in_list,
              onDismiss: () => {
                if (ready) {
                  if (barcodeInputRef) {
                    barcodeInputRef.current.value = '';
                    barcodeInputRef.current.setFocus();
                  }
                  setPresentAlert(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          }
        }
      } else {
        setPresentAlert({
          isPresent: true,
          status: false,
          message: MESSAGE.error.location_required,
          onDismiss: () => {
            if (ready) {
              if (barcodeInputRef) {
                barcodeInputRef.current.value = '';
              }
              setPresentAlert(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }
    }

    const countCheckedProduct = (productID: any, checksData: Array<any> = checks) => {
      return checksData.length ? checksData.map((pack: any) => {
        return pack.products.length ? pack.products.map((packProduct: any) => {
          return packProduct.product === productID
            ? packProduct.quantity ? parseInt(packProduct.quantity) : 0 : 0
        }).reduce((partialSum: any, a: any) => partialSum + a, 0) : 0;
      }).reduce((partialSum: any, a: any) => partialSum + a, 0) : 0;
    }

    const updateSlides = (productsCheck: any = products, checksCheck: any = checks, checkedCheck: any = checked) => {
      if (productsCheck || checksCheck || checkedCheck) {
        for (let i = 0; i < productsCheck.length; i++) {
          if (countCheckedProduct(productsCheck[i].product, [...checksCheck, ...[{ products: checkedCheck }]]) < parseInt(productsCheck[i].quantity)) {
            slider && slider.current! && slider.current!.slideTo(i);
            break;
          }
        }
      }
    }

    useEffect(() => {
      setReady(true);
      if (currentLocation) {
        setTimeout(() => {
          if (barcodeInputRef) {
            barcodeInputRef.current.setFocus();
          }
        }, 1000);
      }
      initialize();
      return () => {
        setReady(false);
      }
    }, []);

    useEffect(() => {
      if (
        JSON.stringify(productsData) !== JSON.stringify(products)
        || JSON.stringify(checksData) !== JSON.stringify(checks)
        || JSON.stringify(checkedData) !== JSON.stringify(checked)
      ) {
        setProducts(productsData);
        setChecks(checksData);
        setChecked(checkedData);
        updateSlides(productsData, checksData, checkedData);
      }
    }, [productsData, checksData, checkedData]);

    useEffect(() => {
      if (barcodeInputRef && focusBarcode && currentLocation) {
        setTimeout(() => {
          if (barcodeInputRef) {
            barcodeInputRef.current.setFocus();
          }
        }, 1000);
      }
    }, [focusBarcode]);

    useEffect(() => {
      if (locationSet && locationSet !== true) {
        setCurrentLocation(locationSet);
        if (barcodeInputRef) {
          barcodeInputRef.current.value = '';
          barcodeInputRef.current.setFocus();
        }
      }
    }, [locationSet]);

    return (
      <>

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <AlertStatus
          isPresent={presentAlert.isPresent}
          status={presentAlert.status}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <AlertPrompt
          isPresent={presentAlertPrompt.isPresent}
          message={presentAlertPrompt.message}
          onDismiss={presentAlertPrompt.onDismiss}
        />

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton className='ios-only' onClick={() => onDismiss()}>
                <IonIcon slot="start" icon={closeOutline} />
                {BUTTON.close}
              </IonButton>
            </IonButtons>
            <IonTitle>{TEXT.scanBarcode}</IonTitle>
            <IonButtons slot="end">
              <IonButton className='md-only' onClick={() => onDismiss()}>
                <IonIcon slot="start" icon={closeOutline} />
                {BUTTON.close}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <div className='scanner-items ion-margin-top'>
            <IonSlides options={slideOptsImage} ref={slider}>
              {
                products.filter((item: any) => item.product_reference ? true : false).map((item: any, index: number) =>
                  <IonSlide key={index}>
                    <IonCard className='w-100'>
                      <IonCardContent>
                        <IonRow className='product-info small'>
                          <IonCol size="10">
                            <h3 className='ion-text-left'>{item.product_reference.name}</h3>
                            <IonText color="medium" className='ion-text-left'>
                              {
                                item.product_reference.category_reference ?
                                  <p>{TEXT.category}: {item.product_reference.category_reference.name}</p>
                                  : <></>
                              }
                              <p>{TEXT.barcode}: {item.product_reference.barcode}</p>
                            </IonText>
                          </IonCol>
                          <IonCol size='2'>
                            {
                              item.product_reference.figure_reference && item.product_reference.figure_reference.length ?
                                <IonSlides options={slideOptsImage} className="product-info-figure-slide">
                                  {item.product_reference.figure_reference.map((item: any, figureI: number) => {
                                    return (
                                      <IonSlide key={`image-slide-${index}-${figureI}`}>
                                        <div>
                                          <IonImg className='content profile-image' src={item.original} />
                                        </div>
                                      </IonSlide>
                                    )
                                  })}
                                </IonSlides>
                                : <></>
                            }
                            <h3 className='quantity text-danger ion-text-right'>
                              <NumberFormat value={
                                countCheckedProduct(item.product, [...checks, ...[{ products: checked }]])
                              } displayType={'text'} thousandSeparator={true}
                                className="text-number" />{"/"}{
                                item.product_reference ?
                                  <NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true}
                                    className="text-number" />
                                  : <></>
                              }
                            </h3>
                          </IonCol>
                        </IonRow>
                      </IonCardContent>
                    </IonCard>
                  </IonSlide>
                )
              }
            </IonSlides>
          </div>
        </IonContent>
        <IonFooter>
          {
            locationSet ?
              <IonToolbar>
                <IonItem className='w-100' lines='none'>
                  <IonIcon slot="start" size='small' icon={archiveOutline} />
                  <IonInput
                    placeholder={TEXT.putawayLocation}
                    readonly={true}
                    value={currentLocation}
                    onClick={() => {
                      onLocationSet && onLocationSet(locationSet);
                    }}
                  >
                  </IonInput>
                </IonItem>
              </IonToolbar>
              : <></>
          }
          <IonToolbar>
            <IonItem slot='start' className='w-100' lines='none'>
              <IonIcon slot="start" size='small' icon={barcodeOutline} />
              <IonInput
                placeholder={TEXT.enterBarcode}
                ref={barcodeInputRef}
                onIonChange={async (e: any) => {
                  if (e.detail.value && e.detail.value.length > 1 && !currentInput) {
                    check(e.detail.value);
                  }
                  setCurrentInput(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
            <IonButton slot='end' color="primary" fill='solid' onClick={(e: any) => check()}>{TEXT.add}</IonButton>
          </IonToolbar>
        </IonFooter>

      </>
    );

  };