import '../../css/Modal.scss';

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonItem,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonList,
  IonSelect,
  IonSelectOption,
  IonBadge,
  IonCard,
  IonCardContent
} from "@ionic/react";
import { alertCircleOutline, closeOutline, moveOutline, removeCircleOutline } from "ionicons/icons";


import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';
import TYPES from '../../constances/Types.json';

import { ErrorMessage } from '@hookform/error-message';
import { Direction, RoutePath } from '../RoutePath';

export const ModalJobAdd: React.FC<{
  onDismiss: (data?: any) => any;
}> = ({ onDismiss }) => {

  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async (value: any) => {
    if (value.jobType == "inbound") {
      console.log('inbound');
    }

    if (value.jobType == "outbound") {
      console.log('outbound');
    }

    if (value.jobType == "transfer") {
      history.push(RoutePath.transferAdd);
    }

    if (value.jobType == "withdraw") {
      console.log('withdraw');
    }

    if (value.jobType == "cancel") {
      console.log('cancel');
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{BUTTON.add}{BUTTON.job}</IonTitle>
          <IonButtons slot="end">
            <IonButton className='md-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className='ion-text-center'>
          <div>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonCard>
                    <IonCardContent>{TEXT.jobSelect}</IonCardContent>
                  </IonCard>

                  <IonList>
                    <IonItem routerLink={RoutePath.transferAdd} routerDirection={Direction()} onClick={onDismiss}>
                      <IonLabel><IonIcon icon={moveOutline}></IonIcon> {TYPES.job.transfer}{TEXT.product}</IonLabel>
                    </IonItem>
                    {/* <IonItem>
                      <IonLabel>{Types.job.inbound}</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>{Types.job.outbound}</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>{Types.job.withdraw}</IonLabel>
                    </IonItem> */}
                    <IonItem routerLink={RoutePath.productCancelAdd} routerDirection={Direction()} onClick={onDismiss}>
                      <IonLabel><IonIcon icon={removeCircleOutline}></IonIcon> {TYPES.job.cancel}</IonLabel>
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </>
  );
};