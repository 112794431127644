import {
  getPlatforms,
  IonApp,
  isPlatform,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Main Beyond */
import './css/App.scss';

/* Providers */
import Authentication from './components/providers/AuthenticationProvider';

/* Import Page */
import Index from './pages/Index';
import Login from './pages/Login';
import Register from './pages/Register';
import TermCondition from './pages/TermCondition';
import RegisterWaitApprove from './pages/RegisterWaitApprove';
import HomeFirstTime from './pages/HomeFirstTime';

import { RoutePath } from './components/RoutePath';

import { useState } from 'react';

setupIonicReact(
  {
    animated: !isPlatform('desktop') ? true : true
  }
);

const App: React.FC = () => {

  const [authentication, setAuthentication] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [vendor, setVendor] = useState<any>(null);
  const [warehouse, setWarehouse] = useState<any>(null);

  const updateAuthentication = (status: boolean) => {
    setAuthentication(status);
  }

  const authenticationProvider: any = {
    status: authentication,
    user: user,
    vendor: vendor,
    warehouse: warehouse,
    update: updateAuthentication,
    setUser,
    setVendor,
    setWarehouse
  }

  return (
    <IonApp>
      <IonReactRouter>

        <Authentication.Provider value={authenticationProvider}>

          <Index />

          <Route path={RoutePath.login} >
            <Login />
          </Route>

        </Authentication.Provider>

        <Route path={RoutePath.termCondition} >
          <TermCondition />
        </Route>

        <Route path={RoutePath.register} >
          <Register />
        </Route>

        {/* <Redirect exact from={RoutePath.registerWait} to={RoutePath.login} />
        <Redirect exact from={RoutePath.registerWelcome} to={RoutePath.login} /> */}

        {/* <Route path={RoutePath.registerWait} >
          <RegisterWaitApprove />
        </Route>

        <Route path={RoutePath.registerWelcome} >
          <HomeFirstTime />
        </Route> */}

      </IonReactRouter>
    </IonApp>
  );
};

export default App;
