import { useEffect, useState } from "react";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonBackButton,
  IonPage,
  IonSegment,
  IonLabel,
  IonSegmentButton
} from "@ionic/react";
import {
  saveOutline
} from "ionicons/icons";

import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

import TEXT from '../constances/Text.json';
import MESSAGE from '../constances/Messages.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';

import { ProductsForm } from '../components/forms/ProductsForm';
import { RoutePath } from '../components/RoutePath';
import { AlertPrompt } from "../components/alerts/AlertPrompt";
import { ToastStatus } from "../components/toasts/ToastStatus";
import Error from "../components/Error";

import ProductService from "../services/ProductService";

const ProductsEdit: React.FC<{ data?: any; }> = ({ data = null }) => {

  const location = useLocation();

  const { id } = useParams<{ id: string; }>();
  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [doSubmit, setDoSubmit] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(null);

  const [segment, setSegment] = useState<string>('general');

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const initialize = async () => {
    setInitialized(false);
    setProduct(null);
    if (data) {
      setProduct(data);
    } else {
      await load(id);
    }
    setInitialized(true);
  }

  const load = async (id: string | null | undefined) => {
    if (id) {
      await ProductService.get(id, null, true).then((data) => {
        setProduct(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  const handleSegmentChange = (e: any) => {
    setSegment(e.detail.value);
  };

  // const handleSlideChange = (e: any) => {
  //   setSegment(e);
  // };

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data, id]);

  useEffect(() => {
    if (location.pathname === RoutePath.productEdit.replace(':id', id)) {
      if (initialized) {
        initialize();
      }
    }
  }, [location]);

  return (
    <IonPage>

      <AlertPrompt
        isPresent={presentAlert.isPresent}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonHeader>

        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.productEdit}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => { setDoSubmit(true); }}>
              <IonIcon slot="start" icon={saveOutline} />
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonToolbar>
          <IonSegment value={segment} onIonChange={e => handleSegmentChange(e)}>
            <IonSegmentButton value="general">
              <IonLabel>{TEXT.generalData}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="attribute">
              <IonLabel>{TEXT.appearance}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="warning">
              <IonLabel>{TEXT.warning}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

      </IonHeader>

      <IonContent>
        {
          product ?
            <ProductsForm slide={segment} data={product} isSubmit={doSubmit} onDidSubmit={(data: any) => { setDoSubmit(false) }} />
            :
            initialized ?
              <Error header={MESSAGE.error.not_found} />
              : <></>
        }
      </IonContent>

    </IonPage>
  );

};

export default ProductsEdit;