import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonIcon
} from "@ionic/react";
import { personOutline, shieldOutline, storefrontOutline, trashOutline } from 'ionicons/icons';

import MESSAGE from '../../constances/Messages.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';
import USER_LEVEL from '../../constances/UserLevel.json';
import { PATTERN_EMAIL } from '../../constances/RegularExpressions';

import { ToastStatus } from '../toasts/ToastStatus';
import { AlertStatus } from '../alerts/AlertStatus';
import { AlertPrompt } from '../alerts/AlertPrompt';
import { GoBack } from '../RoutePath';

import UserService from "../../services/UserService";

export const AdministratorForm: React.FC<{
  data?: any;
  isSubmit?: boolean;
  onDidSubmit?: (data?: any) => any;
}> = ({ data = null, isSubmit = false, onDidSubmit }) => {

  const [ready, setReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm();
  const [formGroup, setFormGroup] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const initialize = async () => {
    setFormGroup(
      {
        username: register('username', {
          value: data && data.username ? data.username : ''
        }),
        nick_name: register('nick_name', {
          value: data && data.nick_name ? data.nick_name : ''
        }),
        name: register('name', {
          value: data && data.name ? data.name : '',
          required: MESSAGE.error.input_required
        }),
        last_name: register('last_name', {
          value: data && data.last_name ? data.last_name : ''
        }),
        phone: register('phone', {
          value: data && data.phone ? data.phone : ''
        }),
        email: register('email', {
          value: data && data.email ? data.email : '',
          pattern: {
            value: PATTERN_EMAIL,
            message: MESSAGE.error.email_invalid,
          }
        }),
        password: register('password', {
          value: '',
          required: MESSAGE.error.input_required
        }),
        confirm_password: register('confirm_password', {
          value: '',
          required: MESSAGE.error.input_required
        }),
        passcode: register('passcode', {
          value: ''
        }),
        groups: register('groups', {
          value: data && data.members ? data.members.map((item: any) => item.group_id) : '',
          required: MESSAGE.error.input_required
        })
      }
    );
  }

  const resetFormValues = () => {
    reset();
  }

  const removeAdministrator = async (id: any, name: string) => {

    const success = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.remove_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      GoBack();
    }

    setPresentAlertPrompt({
      isPresent: true,
      message: `${TEXT.removePrompt} "${name}"?`,
      onDismiss: (data: boolean) => {
        if (ready) {
          setPresentAlertPrompt(
            {
              isPresent: false,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          if (data) {
            UserService.delete(id).then(async (result: any) => {
              success(result);
            }).catch((error) => {
              setPresentToast({
                isPresent: true,
                status: false,
                message: error,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            });
          }
        }
      }
    });

  }

  const onSubmit = async (value: any) => {

    setShowLoading(true);

    const onSuccess = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    const onError = (message: any) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: message,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit();
      }
    }
    if (value.password === value.confirm_password) {
      if (data) {
        await UserService.update(data.id, value).then(async (result: any) => {
          onSuccess(result);
        }).catch((error) => {
          onError(error);
        });
      } else {
        let formData = new FormData();
        const assign = async (value: any) => {
          for (let item of Object.keys(value)) {
            await formData.append(item, value[item]);
          }
        }
        await assign(value);
        await UserService.create(formData).then(async (result: any) => {
          onSuccess(result);
          resetFormValues();
        }).catch((error) => {
          onError(error);
        });
      }
    } else {
      setPresentToast({
        isPresent: true,
        status: false,
        message: MESSAGE.error.password_mismatched,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    }

    setShowLoading(false);

  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
    if (onDidSubmit) {
      onDidSubmit();
    }
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit, onError)();
    }
  }, [isSubmit]);

  return (
    <>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <AlertPrompt
        isPresent={presentAlertPrompt.isPresent}
        message={presentAlertPrompt.message}
        onDismiss={presentAlertPrompt.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      {
        formGroup ?
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <IonGrid>
              <IonRow>
                <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>

                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>
                        <IonItem lines='none' className='item-no-padding-all'>
                          <IonIcon icon={shieldOutline} slot="start" size='small' />
                          <IonLabel>{TEXT.personalUserAccount}</IonLabel>
                        </IonItem>
                      </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                      <IonRow>
                        <IonCol size='12'>
                          <IonItem>
                            <IonLabel position="fixed">{TEXT.personalPermissionLevel}<span className='form-required'>*</span></IonLabel>
                            <IonSelect interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel} placeholder={TEXT.personalPermissionLevel} multiple {...formGroup.groups}
                              value={getValues('groups')}
                            >
                              {USER_LEVEL.map((item: any, index: number) => (
                                <IonSelectOption key={`business-${index}`} value={item.key}>{item.value}</IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol size='12'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.email}<span className='form-required'>*</span></IonLabel>
                            <IonInput type="email" autocomplete="off"
                              {...formGroup.email}
                            >
                            </IonInput>
                          </IonItem>
                        </IonCol>

                        <IonCol size='12' sizeMd='6'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.password}<span className='form-required'>*</span></IonLabel>
                            <IonInput type="password" autocomplete="off"
                              {...formGroup.password}
                            >
                            </IonInput>
                          </IonItem>
                        </IonCol>

                        <IonCol size='12' sizeMd='6'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.passwordConfirm}<span className='form-required'>*</span></IonLabel>
                            <IonInput type="password" autocomplete="off"
                              {...formGroup.confirm_password}>
                            </IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>

                    </IonCardContent>
                  </IonCard>

                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>
                        <IonItem lines='none' className='item-no-padding-all'>
                          <IonIcon icon={personOutline} slot="start" size='small' />
                          <IonLabel>{TEXT.personalInfo}</IonLabel>
                        </IonItem>
                      </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                      <IonRow>
                        <IonCol size='12' sizeMd='6'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.name}<span className='form-required'>*</span></IonLabel>
                            <IonInput type="text" autocomplete="off" autofocus
                              {...formGroup.name}
                            >
                            </IonInput>
                          </IonItem>
                        </IonCol>

                        <IonCol size='12' sizeMd='6'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.lastName}<span className='form-required'>*</span></IonLabel>
                            <IonInput type="text" autocomplete="off"
                              {...formGroup.last_name}
                            >
                            </IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>

                  {
                    data ?
                      <IonRow className='ion-margin-top'>
                        <IonCol size='12' className='ion-text-center'>
                          <IonButton type="button" color='danger' size='default' onClick={() => { removeAdministrator(data.id, data.name); }}>
                            <IonIcon slot="start" icon={trashOutline} />
                            {TEXT.removeAdmin}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                      : <></>
                  }

                </IonCol>
              </IonRow>

            </IonGrid>
          </form>
          : <></>

      }

    </>
  );

};