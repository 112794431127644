import { 
  URL_CANCEL_ORDER_PRODUCT,
} from '../constances/Rest';

import axios from '../configs/axios';

import RestService from './RestService';

const CancelOrderProductService = {
  list: async (
    cancelID: string,
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null,
    status: string|null = null,
    returnReferences: any = false,
    warehouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {
      
      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (status) {
        if (status === 'left') {
          options.left = '1';
        } else if (status === 'over') {
          options.over = '1';
        } else {
          options.status = status;
        }
			}
      if (cancelID) {
        options.cancel = cancelID;
      }
      if (warehouseID) {
        options.warehouse = warehouseID;
      }
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
			if (search) {
				payload.extensions = [
          {
            conjunction: '',
            key: 'product.name',
            operator: 'LIKE',
            value: `'%${search}%'`,
          },
          {
            conjunction: 'OR',
            key: 'product.sku',
            operator: 'LIKE',
            value: `'%${search}%'`,
          },
          {
            conjunction: 'OR',
            key: 'product.barcode',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        ];
			}

      await axios.post(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}list${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  count: async (
    cancelID: string,
    active: boolean|null = null, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null,
    status: string|null = null,
    warehouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (status) {
        if (status === 'left') {
          options.left = '1';
        } else if (status === 'over') {
          options.over = '1';
        } else {
          options.status = status;
        }
			}
      if (cancelID) {
        options.cancel = cancelID;
      }
      if (warehouseID) {
        options.warehouse = warehouseID;
      }
      let payload: any = {};
			if (search) {
				payload.extensions = [
          {
            conjunction: '',
            key: 'product.name',
            operator: 'LIKE',
            value: `'%${search}%'`,
          },
          {
            conjunction: 'OR',
            key: 'product.sku',
            operator: 'LIKE',
            value: `'%${search}%'`,
          },
          {
            conjunction: 'OR',
            key: 'product.barcode',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        ];
			}

      await axios.post(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}count${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response.result);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  get: async (id: string|number, active: boolean|null = null, returnReferences: any = false) => {
		return new Promise(async (resolve, reject) => {

      let options: any = {};
      if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }

      await axios.get(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}${id}${RestService.handleQuery(options)}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  create: async (values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.post(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}create`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  update: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;
      payload.id = id;

      await axios.put(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  delete: async (id: string|number) => {
		return new Promise(async (resolve, reject) => {

      await axios.delete(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}${id}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  patch: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.patch(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  changeStatus: async (id: string|number, values: any, counted: any = null) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = {
        status: values,
        counted: counted
      };

      await axios.patch(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}${id}/status`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  upload: async (id: string|number, values: any) => {
		return new Promise(async (resolve, reject) => {
      
      await axios.post(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}${id}/upload`),
        await RestService.createFormData(values),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  import: async (expectedDate: any, file: any, vendorID: any, fromString = false) => {
		return new Promise(async (resolve, reject) => {

      let formData = null;
      if (!fromString) {
        formData = new FormData();
        formData.append('csv', file);
        formData.append('vendor', vendorID);
        formData.append('expect_date', expectedDate.toString());
      } else {
        formData = {
          csv: file,
          vendor: vendorID,
          expect_date: expectedDate.toString(),
          from_string: '1'
        }
      }

      await axios.post(
        RestService.route(`${URL_CANCEL_ORDER_PRODUCT}csv`),
        formData,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
};

export default CancelOrderProductService;