import '../../css/Modal.scss';

import { useState } from "react";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent
} from "@ionic/react";
import {
  closeOutline,
  saveOutline
} from "ionicons/icons";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import { BrandsForm } from '../forms/BrandsForm';

export const ModalBrandsAdd: React.FC<{
  name: string;
  onDismiss: (data?: any) => any;
}> = ({ name = '', onDismiss }) => {

  const [doSubmit, setDoSubmit] = useState<boolean>(false);

  return (
    <>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{TEXT.brandAdd}</IonTitle>
          <IonButtons slot="end">
            <IonButton className='md-only' onClick={() => setDoSubmit(true)}>
              <IonIcon slot="start" icon={saveOutline} />
              {BUTTON.save}
            </IonButton>
            <IonButton onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <BrandsForm
          name={name}
          isSubmit={doSubmit}
          onDidSubmit={
            (data: any) => {
              if (data) {
                onDismiss(data);
              }
              setDoSubmit(false);
            }
          }
          />
      </IonContent>

    </>
  );

};