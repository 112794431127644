import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonBackButton,
  IonPage,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import {
  saveOutline
} from "ionicons/icons";

import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import MESSAGE from '../constances/Messages.json';

import { GoBack, RoutePath } from '../components/RoutePath';
import { AdministratorForm } from "../components/forms/AdministratorForm";
import Error from "../components/Error";

import UserService from "../services/UserService";

const AdministratorsEdit: React.FC<{ data?: any; }> = ({ data = null }) => {

  const location = useLocation();

  const { id } = useParams<{ id: string; }>();
  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [doSubmit, setDoSubmit] = useState<boolean>(false);
  const [administrator, setAdministrator] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data, id]);

  useEffect(() => {
    if (location.pathname === RoutePath.AdministratorsEdit.replace(':id', id)) {
      if (initialized) {
        initialize();
      }
    }
  }, [location]);

  const initialize = async () => {
    setInitialized(false);
    setAdministrator(null);
    if (data) {
      setAdministrator(data);
    } else {
      await load(id);
    }
    setInitialized(true);
  }

  const load = async (id: string | null | undefined) => {
    if (id) {
      await UserService.get(
        id,
        null,
        ['members']
      ).then((data) => {
        setAdministrator(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className='ion-hide-lg-up'>
            <IonBackButton className="ios-only" defaultHref={RoutePath.administrator} text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.administratorEdit}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setDoSubmit(true)}>
              <IonIcon slot="start" icon={saveOutline} />
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
              {
                administrator ?
                  <AdministratorForm data={administrator} isSubmit={doSubmit} onDidSubmit={(data: any) => { data && GoBack(); setDoSubmit(false) }} />
                  :
                  initialized ?
                    <Error header={MESSAGE.error.not_found} />
                    : <></>
              }
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

    </IonPage>
  );

};

export default AdministratorsEdit;