import '../../css/Modal.scss';

import { useEffect, useState } from "react";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonFooter
} from "@ionic/react";
import {
  addCircle,
  closeOutline,
  saveOutline
} from "ionicons/icons";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import { CountedForm } from '../forms/CountedForm';
import { useParams } from 'react-router';
import ASNProductService from '../../services/ASNProductService';
import WithdrawProductService from '../../services/WithdrawProductService';

export const ModalCounted: React.FC<{
  asnProductID?: any;
  withdrawProductID?: any;
  data?: any;
  onDismiss: (data?: any) => any;
}> = ({ asnProductID = null, withdrawProductID = null, data = null, onDismiss }) => {

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [doSubmit, setDoSubmit] = useState<boolean>(false);
  const [doAppend, setDoAppend] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => {}
  });

  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => {}
  });

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data, asnProductID, withdrawProductID]);

  const initialize = async () => {
    setInitialized(false);
    setProduct(null);
    if (data) {
      setProduct(data);
    } else {
      if (asnProductID) {
        await loadASN(asnProductID);
      } else if (withdrawProductID) {
        await loadWithdraw(withdrawProductID);
      }
    }
    setInitialized(true);
  }
  
  const loadASN = async (id: string|null|undefined) => {
    if (id) {
      await ASNProductService.get(id, null, ['product']).then((data) => {
        setProduct(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true, 
          status: false, 
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }
  
  const loadWithdraw = async (id: string|null|undefined) => {
    if (id) {
      await WithdrawProductService.get(id, null, ['product']).then((data) => {
        setProduct(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true, 
          status: false, 
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  return (
    <>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{TEXT.countedManage}</IonTitle>
          <IonButtons slot="end">
            <IonButton slot="start" onClick={() => setDoSubmit(true)}>
              <IonIcon slot="start" icon={saveOutline} />
              {BUTTON.save}
            </IonButton>
            <IonButton slot="start" className='md-only' onClick={() => onDismiss()}>
              <IonIcon slot='start' icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <CountedForm
          data={product}
          isASN={asnProductID ? true : false}
          isWithdraw={withdrawProductID ? true : false}
          isSubmit={doSubmit}
          isAppend={doAppend}
          onDidSubmit={
            (data: any) => {
              if (data) {
                onDismiss(data);
              }
              setDoSubmit(false);
            }
          }
        />
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButton expand='block' fill='solid' className='ion-margin-start ion-margin-end'
            onClick={async () => {
              await setDoAppend(true); setDoAppend(false);
            }}
          >
            <IonIcon icon={addCircle} slot="start" />
            {BUTTON.add}
          </IonButton>
        </IonToolbar>
      </IonFooter>

    </>
  );

};