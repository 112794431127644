import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import {
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonInput,
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonText,
  IonButtons,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonModal,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {
  alertCircleOutline,
  addCircle,
  removeCircle
} from 'ionicons/icons';

import MESSAGE from '../../constances/Messages.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import { ToastStatus } from '../toasts/ToastStatus';
import { AlertStatus } from '../alerts/AlertStatus';

import ASNService from "../../services/ASNService";
import ASNProductService from '../../services/ASNProductService';
import { AlertPrompt } from '../alerts/AlertPrompt';
import NumberFormat from 'react-number-format';


import { ModalProducts } from '../modal/Products';
import moment from 'moment';
import UserService from '../../services/UserService';
import VendorService from '../../services/VendorService';

export const ASNForm: React.FC<{
  data?: any;
  name?: string;
  isSubmit?: boolean;
  isBack?: boolean;
  onDidSubmit?: (data?: any) => any;
  onBack?: (data?: any) => any;
}> = ({ data = null, name = '', isSubmit = false, isBack = false, onDidSubmit, onBack }) => {

  const slideOptsImage = {
    initialSlide: 0
  };

  const [showLoading, setShowLoading] = useState(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const [vendor, setVendor] = useState<any>(null);
  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<any>>([]);
  const [modalProductData, setModalProductData] = useState<any>(null);

  const { register, handleSubmit, setValue, getValues, trigger, reset, control, formState: { errors } } = useForm();
  const { fields, append, remove } = useFieldArray(
    {
      name: "items",
      control
    }
  );
  const [formGroup, setFormGroup] = useState<any>(null);

  const appendInput = () => {
    append({
      product: null,
      quantity: 1,
      manufactured_date: null,
      expire_date: null,
      lot_number: null
    });
  };

  const initialize = async () => {
    if (!initialized) {

      const userData: any = await UserService.getSession();
      if (userData) {
        await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
          setVendor(vendorData);
        }).catch(() => { });
      }

      setInitialized(false);

      if (data) {
        loadProducts(data.id);
      }

      setFormGroup(
        {
          expect_date: register('expect_date', {
            value: data && data.expect_date ? moment.utc(data.expect_date).local().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
          }),
          items: register('items', {
            value: [],
            required: MESSAGE.error.input_required
          })
        }
      );

    }
  }

  const resetFormValues = () => {
    reset();
  }

  const loadProducts = async (id: string) => {
    if (id) {
      setShowLoading(true);
      await ASNProductService.list(
        id,
        null,
        null,
        null,
        null,
        null,
        null,
        ['product']
      ).then(async (data: any) => {
        if (data.length) {
          let index = 0;
          setProducts(
            data.map((item: any) => item.product_reference)
          );
          for (let item of data) {
            append({
              product: item.product,
              quantity: item.quantity,
              manufactured_date: item.manufactured_date ? item.manufactured_date : '',
              expire_date: item.expire_date ? item.expire_date : '',
              lot_number: item.lot_number ? item.lot_number : ''
            });
            if (index + 1 >= data.length) {
              setShowLoading(false);
            }
            index += 1;
          }
        } else {
          setShowLoading(false);
        }
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  const onSubmit = async (value: any) => {

    setShowLoading(true);
    onDidSubmit && onDidSubmit(false);

    const onSuccess = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    const onError = (message: any) => {

      setPresentToast({
        isPresent: true,
        status: false,
        message: message,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit();
      }
    }

    if (data) {
      await ASNService.import(value, data.id, vendor ? vendor.id : null).then(async (result: any) => {
        onSuccess(result);
      }).catch((error) => {
        onError(error);
      });
    } else {
      await ASNService.import(value, null, vendor ? vendor.id : null).then(async (result: any) => {
        onSuccess(result);
        resetFormValues();
      }).catch((error) => {
        onError(error);
      });
    }

    setShowLoading(false);

  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
        }
      }
    });
    if (onDidSubmit) {
      onDidSubmit();
    }
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    resetFormValues();
    initialize();
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit, onError)();
    }
  }, [isSubmit]);

  return (
    <>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <AlertPrompt
        isPresent={presentAlertPrompt.isPresent}
        message={presentAlertPrompt.message}
        onDismiss={presentAlertPrompt.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonModal
        canDismiss
        swipeToClose
        isOpen={modalProductData || modalProductData === 0 ? true : false}
        onDidDismiss={() => setModalProductData(null)}
      >
        <ModalProducts
          title={TEXT.productAddASN}
          reference={modalProductData}
          onDismiss={(data: any, reference: any) => {
            if (data) {
              if (!products.map((item: any) => item.id).includes(data.id)) {
                const productsCurrent = [data, ...products];
                setProducts(productsCurrent);
              }
              setValue(`items.${reference}.product`, data.id);
              trigger();
            } else if (data === false) {
              setValue(`items.${reference}.product`, null);
              trigger();
            }
            setModalProductData(null);
          }}
        />
      </IonModal>

      {
        formGroup ?
          <form onSubmit={handleSubmit(onSubmit, onError)}>

            {/* Card Content */}
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonCard>
                    {
                      data ?
                        <IonCardHeader>
                          <IonCardTitle>
                            {TEXT.asn} - {data.id}
                            {
                              data.vendor_reference ?
                                ` (${data.vendor_reference.name})`
                                : ''
                            }
                          </IonCardTitle>
                        </IonCardHeader>
                        : <></>
                    }
                    <IonCardContent>
                      <IonGrid>
                        <IonRow>

                          <IonCol size='12'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.expectedDate}</IonLabel>
                              <IonInput type='date' {...formGroup.expect_date}></IonInput>
                            </IonItem>
                          </IonCol>

                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>

              {
                fields.map((element: any, index) => (
                  <IonRow key={element.id}>
                    <IonCol size='12'>
                      <IonCard>

                        <IonToolbar mode="md">
                          <IonTitle slot='start'>
                            <h4 className="text-full">
                              {TEXT.item} - {index + 1}
                            </h4>
                          </IonTitle>
                          <IonButtons slot='end'>
                            <IonButton fill='clear' onClick={() => {
                              remove(index);
                              trigger();
                            }}>
                              <IonIcon icon={removeCircle} color='danger' slot="icon-only" />
                            </IonButton>
                          </IonButtons>
                        </IonToolbar>

                        <IonCardContent className='ion-no-padding'>
                          <IonGrid>
                            <IonRow>
                              <IonCol size='12'>
                                <IonGrid>
                                  <IonRow>

                                    <IonCol size='12' sizeMd='8'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.product}</IonLabel>
                                        <IonInput
                                          onClick={() => setModalProductData(index)}
                                          value={
                                            products.length ?
                                              products.filter(
                                                (item: any) => item.id === getValues(`items.${index}.product`)
                                              ).map(
                                                (item: any, productIndex: number) => item.name
                                              ).join('')
                                              : ''
                                          }
                                        >
                                        </IonInput>
                                      </IonItem>
                                      <ErrorMessage errors={errors} name={`items.${index}.product`}
                                        render={({ message }) =>
                                          <div className='form-help'>
                                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                            {message ? message : ''}
                                          </div>
                                        }
                                      />
                                    </IonCol>

                                    <IonCol size='12' sizeMd='4'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.qty}</IonLabel>
                                        <IonInput type='number' min={1}
                                          {
                                          ...register(
                                            `items.${index}.quantity`,
                                            { required: MESSAGE.error.input_required }
                                          )
                                          }
                                        >
                                        </IonInput>
                                      </IonItem>
                                      <ErrorMessage errors={errors} name={`items.${index}.quantity`}
                                        render={({ message }) =>
                                          <div className='form-help'>
                                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                            {message ? message : ''}
                                          </div>
                                        }
                                      />
                                    </IonCol>

                                    <IonCol size='12' sizeMd='4'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.manufacturerDate}</IonLabel>
                                        <IonInput type='date' {...register(`items.${index}.manufactured_date`)}>
                                        </IonInput>
                                      </IonItem>
                                    </IonCol>

                                    <IonCol size='12' sizeMd='4'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.productExpire}</IonLabel>
                                        <IonInput type='date' {...register(`items.${index}.expire_date`)}>
                                        </IonInput>
                                      </IonItem>
                                    </IonCol>

                                    <IonCol size='12' sizeMd='4'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.lotNumber}</IonLabel>
                                        <IonInput type='text' {...register(`items.${index}.lot_number`)}></IonInput>
                                      </IonItem>
                                    </IonCol>

                                    {/* Product */}
                                    {
                                      products.length ?
                                        products.filter(
                                          (item: any) => item.id === getValues(`items.${index}.product`)
                                        ).map((item: any, productIndex: number) => (
                                          <IonCol size='12' sizeMd='12' key={`product-${index}-${productIndex}`}>
                                            {/* Product */}
                                            <IonCard>
                                              <IonCardContent>
                                                <IonRow
                                                >
                                                  <IonCol size='12' sizeMd="10" className='product-info tiny'>
                                                    <h4 className='ion-text-left'>{item.name}</h4>
                                                    <IonText color="medium" className='ion-text-left'>
                                                      {
                                                        item.category_reference ?
                                                          <p>{TEXT.category}: {item.category_reference.name}</p>
                                                          : <></>
                                                      }
                                                      <p>{TEXT.productSku} - {item.sku}</p>
                                                      <p>
                                                        {TEXT.qtyOrder} -
                                                        <NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} />
                                                      </p>
                                                    </IonText>
                                                  </IonCol>
                                                  <IonCol size='12' sizeMd='2' className='product-info tiny'>
                                                    {
                                                      item.figure_reference && item.figure_reference.length ?
                                                        <IonSlides options={slideOptsImage} className="product-info-figure-slide">
                                                          {item.figure_reference.map((item: any, figureI: number) => {
                                                            return (
                                                              <IonSlide key={`image-slide-${index}-${figureI}`}>
                                                                <div>
                                                                  <IonImg className='content profile-image' src={item.original} />
                                                                </div>
                                                              </IonSlide>
                                                            )
                                                          })}
                                                        </IonSlides>
                                                        : <></>
                                                    }
                                                  </IonCol>
                                                </IonRow>
                                              </IonCardContent>
                                            </IonCard>

                                          </IonCol>
                                        ))
                                        : <></>
                                    }

                                  </IonRow>
                                </IonGrid>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                ))
              }

              <IonRow>
                <IonCol size='12'>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonButton expand='block' color="primary" onClick={appendInput}>
                          <IonIcon icon={addCircle} slot="start" />
                          {BUTTON.add}
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>

            </IonGrid>

          </form>
          : <></>

      }

    </>
  );

};