export const BASE_URL: string = 'https://warehouse.beyond365.biz/';
export const BASE_URL_DEVELOP: string = 'https://warehouse.beyond365.biz/develop/';
export const BASE_URL_TESTING: string = 'https://warehouse.beyond365.biz/testing/';
export const BASE_URL_LOCAL: string = 'http://localhost/beyond-warehouse/';

export const URL_ENDPOINT_DIRECTORY: string = '';
export const URL_ENDPOINT_DIRECTORY_DEVELOP: string = '';
export const URL_ENDPOINT_DIRECTORY_TESTING: string = '';
export const URL_ENDPOINT_DIRECTORY_LOCAL: string = '';

export const URL_ENDPOINT: string = 'api/';
export const URL_ENDPOINT_PUBLIC: string = 'public-data/';
export const URL_CORE: string = URL_ENDPOINT + 'core/1.0/';

export const URL_LOCATION = URL_ENDPOINT_PUBLIC + 'location/';
export const URL_CSV_TEMPLATES = URL_ENDPOINT_PUBLIC + 'csv-templates/';

export const URL_USER = URL_ENDPOINT + 'user/';
export const URL_PRODUCT = URL_ENDPOINT + 'product/';
export const URL_BRAND = URL_ENDPOINT + 'brand/';
export const URL_CATEGORY = URL_ENDPOINT + 'category/';
export const URL_LOG = URL_ENDPOINT + 'log/';
export const URL_MODULE = URL_ENDPOINT + 'module/';
export const URL_DELIVERY_ORDER = URL_ENDPOINT + 'delivery_order/';
export const URL_VENDOR = URL_ENDPOINT + 'vendor/';
export const URL_VENDOR_USER = URL_ENDPOINT + 'vendor-user/';
export const URL_ASN = URL_ENDPOINT + 'asn/';
export const URL_ASN_PRODUCT = URL_ENDPOINT + 'asn-product/';
export const URL_ASN_PRODUCT_PUTAWAY = URL_ENDPOINT + 'asn-product-putaway/';
export const URL_ORDER = URL_ENDPOINT + 'order/';
export const URL_ORDER_PRODUCT = URL_ENDPOINT + 'order-product/';
export const URL_PACK = URL_ENDPOINT + 'pack/';
export const URL_PACK_PRODUCT = URL_ENDPOINT + 'pack-product/';
export const URL_QUANTITY = URL_ENDPOINT + 'product-quantity/';
export const URL_TRANSFER = URL_ENDPOINT + 'transfer/';
export const URL_TRANSFER_PRODUCT = URL_ENDPOINT + 'transfer-product/';
export const URL_COURIER = URL_ENDPOINT + 'courier/';
export const URL_JOB = URL_ENDPOINT + 'job/';
export const URL_MANUFACTURER = URL_ENDPOINT + 'manufacturer/';
export const URL_DISTRIBUTOR = URL_ENDPOINT + 'distributor/';
export const URL_WAREHOUSE = URL_ENDPOINT + 'warehouse/';
export const URL_PRODUCT_WAREHOUSE = URL_ENDPOINT + 'product-warehouse/';
export const URL_WITHDRAW = URL_ENDPOINT + 'withdraw/';
export const URL_WITHDRAW_PRODUCT = URL_ENDPOINT + 'withdraw-product/';
export const URL_WITHDRAW_PRODUCT_TAKEAWAY = URL_ENDPOINT + 'withdraw-product-takeaway/';
export const URL_CANCEL = URL_ENDPOINT + 'cancel/';
export const URL_CANCEL_ORDER_PRODUCT = URL_ENDPOINT + 'cancel-order-product/';