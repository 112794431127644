import { IonPage, IonContent, IonGrid, IonCol, IonLabel, IonRow, IonText, IonButton, IonBadge } from "@ionic/react";
import { useHistory } from "react-router-dom";
import NumberFormat from 'react-number-format';
import DataTable from 'react-data-table-component';

import { RoutePath } from "../components/RoutePath";

import { productCancelView } from "../components/InterfaceData";

import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TEXT from '../constances/Text.json';
import MENU from '../constances/Menu.json';
import { tableStylesE5 } from "../components/TableStyle";
import { ToolbarWithBack } from "../components/ToolbarWithBack";
import { useState } from "react";

// interface ProductViewPageProps extends RouteComponentProps<{ id: string; }> { }

const SendParcelView: React.FC = () => {
    const history = useHistory();

    const [products, setProducts] = useState<Array<any>>([]);

    const asnView: Array<any> = [
        {
            id: 0,
            sku: 'SKU00001',
            name: 'Product XXX',
            qty: 2,
            note: 'ดีเยี่ยม'
        },
        {
            id: 1,
            sku: 'SKU00001',
            name: 'Product XXX',
            qty: 2,
            note: 'ดีเยี่ยม'
        },
        {
            id: 2,
            sku: 'SKU00001',
            name: 'Product XXX',
            qty: 2,
            note: 'ดีเยี่ยม'
        },
        {
            id: 3,
            sku: 'SKU00001',
            name: 'Product XXX',
            qty: 2,
            note: 'ดีเยี่ยม'
        },
        {
            id: 4,
            sku: 'SKU00001',
            name: 'Product XXX',
            qty: 2,
            note: 'ดีเยี่ยม'
        },
    ];

    // DataTable
    const columns: Array<any> = [
        {
            name: TEXT.productSku,
            selector: (row: { sku: any; }) => row.sku,
            cell: (row: { sku: string }) => (
                <IonButton fill='clear' routerLink={RoutePath.productView.replace(':id', row.sku)}>{row.sku}</IonButton>
            ),
            width: '20%',
            sortable: true,
        },
        {
            name: TEXT.productName,
            selector: (row: { name: any; }) => row.name,
            sortable: true,
        },
        {
            name: TEXT.qtyPiece,
            selector: (row: { qty: any; }) => row.qty,
            cell: (row: { qty: number }) => (
                <NumberFormat value={row.qty} displayType={'text'} thousandSeparator={true} />
            ),
            width: '20%',
            sortable: true,
        },
    ];

    return (
        <IonPage>
            <ToolbarWithBack menuName={MENU.sendParcelView} />

            <IonContent>
                <IonGrid>
                    <IonRow className='ion-margin-horizontal'>
                        <IonCol size='12'>
                            <IonText color="dark">
                                <h3>{TEXT.orderNumber}: SGO-001</h3>
                            </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-horizontal">
                        <IonCol size='6' sizeXl="3">
                            <IonText color="medium">{TEXT.dateSendParcel}</IonText>
                        </IonCol>
                        <IonCol size='6' sizeXl="3">
                            <IonText color="dark">XX-XX-XXXX</IonText>
                        </IonCol>

                        <IonCol size='6' sizeXl="3">
                            <IonText color="medium">{TEXT.trackingNo}</IonText>
                        </IonCol>
                        <IonCol size='6' sizeXl="3">
                            <IonText color="dark">TH8982903473478</IonText>
                        </IonCol>

                        <IonCol size='6' sizeXl="3">
                            <IonText color="medium">{TEXT.boxParcel}</IonText>
                        </IonCol>
                        <IonCol size='6' sizeXl="3">
                            <IonText color="dark">SGO-03</IonText>
                        </IonCol>

                        <IonCol size='6' sizeXl="3">
                            <IonText color="medium">{TEXT.weightParcel}</IonText>
                        </IonCol>
                        <IonCol size='6' sizeXl="3">
                            <IonText color="dark"><NumberFormat value="1900" displayType={'text'} thousandSeparator={true} /></IonText>
                        </IonCol>

                        <IonCol size='6' sizeXl="3">
                            <IonText color="medium">{TEXT.courier}</IonText>
                        </IonCol>
                        <IonCol size='6' sizeXl="3">
                            <IonText color="dark">Flash Express</IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <div className="ion-margin">
                    <DataTable
                        columns={columns}
                        customStyles={tableStylesE5}
                        data={asnView}
                        pagination
                    />
                </div>

            </IonContent>
        </IonPage>
    );
}

export default SendParcelView;