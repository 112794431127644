const UtilitiesService = {
  updateURL: async (key: string, value: any) => {

    const options = new URLSearchParams(window.location.search);
    if (value) {
      if (options.has(key) && options.get(key)) {
        options.set(key, value);
      } else {
        options.append(key, value);
      }
    } else {
      options.delete(key);
    }
    let prefix = '';
    if (Array.from(options).length) {
      prefix = '?';
    }
    
    window.history.replaceState({}, '', `${window.location.pathname}${prefix}${options}`);

  }
};

export default UtilitiesService;