import { useEffect, useState } from 'react';

import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {
  checkmarkCircleOutline
} from 'ionicons/icons';

import MESSAGE from '../constances/Messages.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';

import { GoBack, RoutePath } from '../components/RoutePath';
import Error from "../components/Error";

import { TransferForm } from '../components/forms/TransferForm';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import TransferService from '../services/TransferService';

const TransfersEdit: React.FC<{data?: any;}> = ({data = null}) => {

  const location = useLocation();

  const { id } = useParams<{ id: string; }>();
  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [doSubmit, setDoSubmit] = useState<boolean>(false);
  const [transfer, setTransfer] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => {}
  });

  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => {}
  });

  const history = useHistory();

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data, id]);

  useEffect(() => {
    if (location.pathname === RoutePath.transferEdit.replace(':id', id)) {
      if (initialized) {
        initialize();
      }
    }
  }, [location]);

  const initialize = async () => {
    setInitialized(false);
    setTransfer(null);
    if (data) {
      setTransfer(data);
    } else {
      await load(id);
    }
    setInitialized(true);
  }
  
  const load = async (id: string|null|undefined) => {
    if (id) {
      await TransferService.get(id).then((data) => {
        setTransfer(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true, 
          status: false, 
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.transferManage}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => setDoSubmit(true)}>
              <IonIcon slot="start" icon={checkmarkCircleOutline} />
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
              {
                transfer ?
                <TransferForm data={transfer} isSubmit={doSubmit} onDidSubmit={
                  (data: any) => { 
                    if (data) {
                      GoBack();
                    }
                    setDoSubmit(false) 
                  }
                } />
                :
                initialized ?
                <Error header={MESSAGE.error.not_found} />
                : <></>
              }
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default TransfersEdit;