import { 
  URL_MODULE,
} from '../constances/Rest';

import axios from '../configs/axios';

import RestService from './RestService';

const ModuleService = {
  list: async (
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    returnReferences: any = false
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      await axios.get(
        RestService.route(`${URL_MODULE}list${RestService.handleQuery(options)}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  count: async (
    active: boolean|null = null, 
    start: number|null = null,
    limit: number|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}

      await axios.get(
        RestService.route(`${URL_MODULE}count${RestService.handleQuery(options)}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response.result);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  }
};

export default ModuleService;