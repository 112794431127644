import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonLabel,
  IonRow,
  IonIcon,
  IonBadge,
  IonBackButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonLoading,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonHeader,
  IonFab,
  IonFabButton,
  IonFabList,
  IonButton,
  IonCardContent,
  IonCard,
  IonProgressBar,
  IonSpinner
} from "@ionic/react";
import {
  alertCircle,
  banOutline,
  barcodeOutline,
  checkmarkCircle,
  qrCodeOutline,
  scanOutline
} from "ionicons/icons";

import moment from 'moment';

import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import MESSAGE from '../constances/Messages.json';
import TYPES from '../constances/Types.json';

import { Direction, RoutePath } from "../components/RoutePath";
import { AlertPrompt } from "../components/alerts/AlertPrompt";
import { AlertStatus } from "../components/alerts/AlertStatus";
import { ToastStatus } from "../components/toasts/ToastStatus";
import Order from "../components/Order";
import ASN from "../components/ASN";

import UserService from "../services/UserService";
import JobService from "../services/JobService";
import ASNService from "../services/ASNService";
import OrderService from "../services/OrderService";
import WithdrawService from "../services/WithdrawService";
import Withdraw from "../components/Withdraw";
import TransferService from "../services/TransferService";
import Transfer from "../components/Transfer";
import CancelService from "../services/CancelService";
import Cancel from "../components/Cancel";

const JobsView: React.FC<{ data?: any; }> = ({ data = null }) => {

  const colors: any = COLORS.job;
  const statuses: any = STATUS.job;
  const types: any = TYPES.job;

  const { id } = useParams<{ id: string; }>();

  const [job, setJob] = useState<any>(null);
  const [asn, setASN] = useState<any>(null);
  const [asnStatus, setASNStatus] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const [orderStatus, setOrderStatus] = useState<any>(null);
  const [withdraw, setWithdraw] = useState<any>(null);
  const [withdrawStatus, setWithdrawStatus] = useState<any>(null);
  const [transfer, setTransfer] = useState<any>(null);
  const [transferStatus, setTransferStatus] = useState<any>(null);
  const [cancel, setCancel] = useState<any>(null);
  const [cancelStatus, setCancelStatus] = useState<any>(null);
  const [doChangeStatus, setDoChangeStatus] = useState<any>(null);
  const [packAvailable, setPackAvailable] = useState<any>(false);
  const [scannerReady, setScannerReady] = useState<boolean>(false);
  const [warehouse, setWarehouse] = useState<any>(null);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showProgressLoading, setShowProgressLoading] = useState(false);
  const [reload, setReload] = useState<any>(new Date().getTime());

  const [modalScannerIsOpen, setModalScannerIsOpen] = useState<boolean>(false);
  const [modalBarcodeIsOpen, setModalBarcodeIsOpen] = useState<boolean>(false);

  const { register, getValues, setValue } = useForm();
  const [jobStatus] = useState<any>(
    register('jobStatus', {
      value: '',
      onChange: (e: any) => {
        let referenceStatus = null;
        if (e.job) {
          if (e.job.type === 'inbound') {
            referenceStatus = e.asnStatus;
          } else if (e.job.type === 'outbound') {
            referenceStatus = e.orderStatus;
          } else if (e.job.type === 'withdraw') {
            referenceStatus = e.withdrawStatus;
          } else if (e.job.type === 'transfer') {
            referenceStatus = e.transferStatus;
          } else if (e.job.type === 'cancel') {
            referenceStatus = e.cancelStatus;
          }
          changeStatus(
            e.job,
            referenceStatus,
            e.detail.value,
            e.ready
          );
        }
      }
    })
  );

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const location = useLocation();

  const initialize = async (id: any) => {
    setInitialized(false);
    setShowLoading(true);
    setJob(null);
    setASN(null);
    setOrder(null);
    setTransfer(null);
    setWithdraw(null);
    setCancel(null);
    setScannerReady(false);
    const userData: any = await UserService.getSession();
    if (userData) {
      await load(id);
    }
    setInitialized(true);
  }

  const load = async (id: string | null | undefined) => {
    if (id) {
      await JobService.get(id).then(async (data: any) => {
        setJob(data);
        setValue('jobStatus', data.status);
        if (data.type === 'inbound') {
          await ASNService.listByJob(id, null, null, 0, 1, null).then(async (data: any) => {
            const asnData: any = data[0];
            setASN(asnData);
            setASNStatus(asnData.status);
          }).catch((error) => {
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
        } else if (data.type === 'outbound') {
          await OrderService.list(null, null, 0, 1, null, null, null, null, null, id).then(async (data: any) => {
            const orderData: any = data[0];
            setOrder(orderData);
            setOrderStatus(orderData.status);
          }).catch((error) => {
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
        } else if (data.type === 'withdraw') {
          await WithdrawService.listByJob(id, null, null, 0, 1, null).then(async (data: any) => {
            const withdrawData: any = data[0];
            setWithdraw(withdrawData);
            setWithdrawStatus(withdrawData.status);
          }).catch((error) => {
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
        } else if (data.type === 'transfer') {
          await TransferService.listByJob(id, null, null, 0, 1, null).then(async (data: any) => {
            const transferData: any = data[0];
            setTransfer(transferData);
            setTransferStatus(transferData.status);
          }).catch((error) => {
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
        } else if (data.type === 'cancel') {
          await CancelService.listByJob(id, null, null, 0, 1, null).then(async (data: any) => {
            const cancelData: any = data[0];
            setCancel(cancelData);
            setCancelStatus(cancelData.status);
          }).catch((error) => {
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
        }
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
      setReload(new Date().getTime());
    }
  }

  const changeStatus = async (
    job: any,
    referenceStatus: any,
    value: any,
    ready: any
  ) => {
    if (job && job.status !== value) {

      const success = async (jobID: any) => {
        await load(jobID);
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (jobID: any, value: any) => {
        setShowLoading(true);
        await JobService.patch(jobID, { status: value }).then(async () => {
          success(jobID);
        }).catch((error) => {
          setValue('jobStatus', job.status);
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        setShowLoading(false);
      }

      if (job.type === 'inbound') {
        if (value === 'complete' && referenceStatus !== 'complete') {
          setPresentAlert({
            isPresent: true,
            status: false,
            message: MESSAGE.info.asnStatusCompleteRequire,
            onDismiss: () => {
              if (ready) {
                setValue('jobStatus', job.status);
                setPresentAlert(
                  {
                    isPresent: false,
                    status: presentAlert.status,
                    message: presentAlertPrompt.message,
                    onDismiss: presentAlertPrompt.onDismiss
                  }
                );
              }
            }
          });
        } else {
          patch(job.id, value);
        }
      } else if (job.type === 'outbound') {
        if (value === 'complete' && referenceStatus !== 'complete') {
          setPresentAlert({
            isPresent: true,
            status: false,
            message: MESSAGE.info.orderStatusCompleteRequire,
            onDismiss: () => {
              if (ready) {
                setValue('jobStatus', job.status);
                setPresentAlert(
                  {
                    isPresent: false,
                    status: presentAlert.status,
                    message: presentAlertPrompt.message,
                    onDismiss: presentAlertPrompt.onDismiss
                  }
                );
              }
            }
          });
        } else {
          patch(job.id, value);
        }
      } else if (job.type === 'withdraw') {
        if (value === 'complete' && referenceStatus !== 'complete') {
          setPresentAlert({
            isPresent: true,
            status: false,
            message: MESSAGE.info.withdrawStatusCompleteRequire,
            onDismiss: () => {
              if (ready) {
                setValue('jobStatus', job.status);
                setPresentAlert(
                  {
                    isPresent: false,
                    status: presentAlert.status,
                    message: presentAlertPrompt.message,
                    onDismiss: presentAlertPrompt.onDismiss
                  }
                );
              }
            }
          });
        } else {
          patch(job.id, value);
        }
      } else if (job.type === 'transfer') {
        if (value === 'complete' && referenceStatus !== 'complete') {
          setPresentAlert({
            isPresent: true,
            status: false,
            message: MESSAGE.info.transferStatusCompleteRequire,
            onDismiss: () => {
              if (ready) {
                setValue('jobStatus', job.status);
                setPresentAlert(
                  {
                    isPresent: false,
                    status: presentAlert.status,
                    message: presentAlertPrompt.message,
                    onDismiss: presentAlertPrompt.onDismiss
                  }
                );
              }
            }
          });
        } else {
          patch(job.id, value);
        }
      } else if (job.type === 'cancel') {
        if (value === 'complete' && referenceStatus !== 'complete') {
          setPresentAlert({
            isPresent: true,
            status: false,
            message: MESSAGE.info.cancelStatusCompleteRequire,
            onDismiss: () => {
              if (ready) {
                setValue('jobStatus', job.status);
                setPresentAlert(
                  {
                    isPresent: false,
                    status: presentAlert.status,
                    message: presentAlertPrompt.message,
                    onDismiss: presentAlertPrompt.onDismiss
                  }
                );
              }
            }
          });
        } else {
          patch(job.id, value);
        }
      }

    }
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (data) {
      initialize(data.id);
    }
  }, [data]);

  useEffect(() => {
    initialize(id);
  }, [id]);

  useEffect(() => {
    if (location.pathname === RoutePath.jobView.replace(':id', id)) {
      if (initialized) {
        load(id);
      }
    }
  }, [location]);

  return (
    <IonPage>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <AlertPrompt
        isPresent={presentAlertPrompt.isPresent}
        message={presentAlertPrompt.message}
        onDismiss={presentAlertPrompt.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.jobView}</IonTitle>
          {
            job ?
              <IonButtons slot="end">
                {
                  job.type === 'inbound' && asnStatus !== 'cancel' ?
                    <IonButton color='danger' onClick={() => setDoChangeStatus('cancel')}>
                      <IonIcon slot="start" icon={banOutline} />
                      {BUTTON.cancel}
                    </IonButton>
                    : <></>
                }
                {
                  job.type === 'outbound' && orderStatus === 'pending' ?
                    !packAvailable ?
                      <IonButton color="success" routerLink={RoutePath.orderPackAdd.replace(':id', packAvailable)}
                        routerDirection={Direction()}
                        disabled={!packAvailable ? true : false}>
                        <IonIcon slot="start" icon={checkmarkCircle} />
                        {TEXT.parcelCompleteShort}
                      </IonButton>
                      :
                      <IonButton color="danger" routerLink={RoutePath.orderPackAdd.replace(':id', packAvailable)}
                        routerDirection={Direction()}
                        disabled={!packAvailable ? true : false}>
                        <IonIcon slot="start" icon={alertCircle} />
                        {BUTTON.sendParcelAdd}
                      </IonButton>
                    : <></>
                }
              </IonButtons>
              : <></>
          }
        </IonToolbar>
        {
          showProgressLoading &&
          <IonProgressBar type="indeterminate" className='fixed'></IonProgressBar>
        }
      </IonHeader>

      <IonContent>

        <IonGrid>
          {
            job ?
              <IonRow>
                <IonCol>
                  <IonToolbar className="toolbar-title">
                    <IonTitle size="large">
                      {TEXT.jobNo} - {job.id} <IonBadge color="primary" className="f-16">&nbsp;{types[job.type]}&nbsp;</IonBadge>
                    </IonTitle>
                  </IonToolbar>
                  <IonCard>
                    <IonCardContent>

                      <IonGrid>
                        <IonRow>

                          <IonCol size='12' sizeMd="5" sizeXl="3">
                            <IonItem lines="none" className="item-no-padding">
                              <IonLabel class="ion-text-wrap" color="medium">{TEXT.dateCreateJob}</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol size='12' sizeMd="7" sizeXl="3">
                            <IonItem lines="none">
                              <IonLabel class="ion-text-wrap" color="dark">{job.create_at ? moment.utc(job.create_at).local().format('LLL') : '-'}</IonLabel>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeMd="5" sizeXl="3">
                            <IonItem lines="none" className="item-no-padding">
                              <IonLabel class="ion-text-wrap" color="medium">{TEXT.jobStatus}</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol size='12' sizeMd="7" sizeXl="3">
                            <IonItem className="item-hidden-label">
                              <IonLabel class="ion-text-wrap">
                                <IonBadge color={colors[job.status]}>{statuses[job.status]}</IonBadge>
                              </IonLabel>
                              <IonLabel class="ion-text-wrap">{TEXT.jobStatus}</IonLabel>
                              <IonSelect
                                interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                {...jobStatus} value={getValues('jobStatus')}
                                onIonChange={(e: any) => {
                                  e.job = job;
                                  e.asnStatus = asnStatus;
                                  e.orderStatus = orderStatus;
                                  e.withdrawStatus = withdrawStatus;
                                  e.transferStatus = transferStatus;
                                  e.cancelStatus = cancelStatus;
                                  e.ready = ready;
                                  jobStatus.onChange(e)
                                }}
                              >
                                <IonSelectOption value="pending">{statuses['pending']}</IonSelectOption>
                                <IonSelectOption value="on-process">{statuses['on-process']}</IonSelectOption>
                                <IonSelectOption value="complete">{statuses['complete']}</IonSelectOption>
                                <IonSelectOption value="cancel">{statuses['cancel']}</IonSelectOption>
                              </IonSelect>
                            </IonItem>
                          </IonCol>

                        </IonRow>

                      </IonGrid>

                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              : <></>
          }
        </IonGrid>

        {
          initialized ?
            <>

              {
                asn ?
                  <ASN
                    id={asn.id}
                    onStatusChange={(status: any) => { setDoChangeStatus(null); setASNStatus(status); }}
                    doChangeStatus={doChangeStatus}
                    displayInitialLoading={false}
                    scannerIsOpen={modalScannerIsOpen}
                    barcodeIsOpen={modalBarcodeIsOpen}
                    onDidLoad={() => setShowLoading(false)}
                    onLoad={(data: any) => setShowProgressLoading(data)}
                    onCancelStatusChange={() => setDoChangeStatus(null)}
                    onDidScannerReady={() => setScannerReady(true)}
                    reload={reload}
                  />
                  : <></>
              }

              {
                asn ?
                  <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton disabled={!scannerReady}>
                      {
                        scannerReady ?
                          <IonIcon icon={scanOutline} />
                          : <IonSpinner></IonSpinner>
                      }
                    </IonFabButton>
                    <IonFabList side="top">
                      <IonFabButton onClick={async () => { await setModalBarcodeIsOpen(true); setModalBarcodeIsOpen(false) }}><IonIcon icon={barcodeOutline} /></IonFabButton>
                      <IonFabButton onClick={async () => { await setModalScannerIsOpen(true); setModalScannerIsOpen(false) }}><IonIcon icon={qrCodeOutline} /></IonFabButton>
                    </IonFabList>
                  </IonFab>
                  : <></>
              }

              {
                order ?
                  <Order id={order.id}
                    onStatusChange={(status: any) => setOrderStatus(status)}
                    onCheckPackAvailable={(available: any) => setPackAvailable(available)}
                    displayInitialLoading={false}
                    onDidLoad={() => setShowLoading(false)}
                    onLoad={(data: any) => setShowProgressLoading(data)}
                    reload={reload}
                  />
                  : <></>
              }

              {
                withdraw ?
                  <Withdraw
                    id={withdraw.id}
                    onStatusChange={(status: any) => { setDoChangeStatus(null); setWithdrawStatus(status); }}
                    doChangeStatus={doChangeStatus}
                    displayInitialLoading={false}
                    scannerIsOpen={modalScannerIsOpen}
                    barcodeIsOpen={modalBarcodeIsOpen}
                    onDidLoad={() => setShowLoading(false)}
                    onLoad={(data: any) => setShowProgressLoading(data)}
                    onCancelStatusChange={() => setDoChangeStatus(null)}
                    onDidScannerReady={() => setScannerReady(true)}
                    reload={reload}
                  />
                  : <></>
              }

              {
                transfer ?
                  <Transfer
                    id={transfer.id}
                    onStatusChange={(status: any) => { setDoChangeStatus(null); setTransferStatus(status); }}
                    doChangeStatus={doChangeStatus}
                    displayInitialLoading={false}
                    onDidLoad={() => setShowLoading(false)}
                    onLoad={(data: any) => setShowProgressLoading(data)}
                    reload={reload}
                  />
                  : <></>
              }

              {
                withdraw ?
                  <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton disabled={!scannerReady}>
                      {
                        scannerReady ?
                          <IonIcon icon={scanOutline} />
                          : <IonSpinner></IonSpinner>
                      }
                    </IonFabButton>
                    <IonFabList side="top">
                      <IonFabButton onClick={async () => { await setModalBarcodeIsOpen(true); setModalBarcodeIsOpen(false) }}><IonIcon icon={barcodeOutline} /></IonFabButton>
                      <IonFabButton onClick={async () => { await setModalScannerIsOpen(true); setModalScannerIsOpen(false) }}><IonIcon icon={qrCodeOutline} /></IonFabButton>
                    </IonFabList>
                  </IonFab>
                  : <></>
              }

              {
                cancel ?
                  <Cancel
                    id={cancel.id}
                    onStatusChange={(status: any) => { setDoChangeStatus(null); setCancelStatus(status); }}
                    doChangeStatus={doChangeStatus}
                    displayInitialLoading={false}
                    onDidLoad={() => setShowLoading(false)}
                    onLoad={(data: any) => setShowProgressLoading(data)}
                    reload={reload}
                  />
                  : <></>
              }

            </>
            : <></>
        }

      </IonContent>
    </IonPage>
  );
}

export default JobsView;