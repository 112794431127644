import { 
  URL_COURIER,
} from '../constances/Rest';

import axios from '../configs/axios';

import RestService from './RestService';

const CourierService = {
  list: async (
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null,
    returnReferences: any = false
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
			if (search) {
				payload.extensions = [
          {
            conjunction: '',
            key: 'name',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        ];
			}

      await axios.post(
        RestService.route(`${URL_COURIER}list${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  count: async (
    active: boolean|null = null, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
      let payload: any = {};
			if (search) {
				payload.extensions = [
          {
            conjunction: '',
            key: 'name',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        ];
			}

      await axios.post(
        RestService.route(`${URL_COURIER}count${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response.result);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  get: async (id: string|number, active: boolean|null = null, returnReferences: any = false) => {
		return new Promise(async (resolve, reject) => {

      let options: any = {};
      if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }

      await axios.get(
        RestService.route(`${URL_COURIER}${id}${RestService.handleQuery(options)}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
		});
  },
  create: async (values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.post(
        RestService.route(`${URL_COURIER}create`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  update: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;
      payload.id = id;

      await axios.put(
        RestService.route(`${URL_COURIER}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  delete: async (id: string|number) => {
		return new Promise(async (resolve, reject) => {

      await axios.delete(
        RestService.route(`${URL_COURIER}${id}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  patch: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.patch(
        RestService.route(`${URL_COURIER}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  upload: async (id: string|number, values: any) => {
		return new Promise(async (resolve, reject) => {

      await axios.post(
        RestService.route(`${URL_COURIER}${id}/upload`),
        await RestService.createFormData(values),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  notify: async (
    id: string|number, 
    warehouseNo: any = null, 
    specificAddress: any = {}, 
    estimateParcelNumber: any, 
    pickupStaffInfoId: string|null, 
    remark: string = ''
  ) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = {
        courier_warehouse_no: warehouseNo,
        specific_address: specificAddress,
        estimate_parcel_number: estimateParcelNumber ? parseInt(estimateParcelNumber) : 0,
        pickup_staff_infoId: pickupStaffInfoId, 
        remark: remark
      };

      await axios.post(
        RestService.route(`${URL_COURIER}${id}/notify`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  cancelNotification: async (id: string|number, ticketPickupID: any) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = {
        ticket_pickup_id: ticketPickupID
      };

      await axios.post(
        RestService.route(`${URL_COURIER}${id}/cancel_notification`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  notifications: async (id: string|number, date: any) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = {
        date: date
      };

      await axios.post(
        RestService.route(`${URL_COURIER}${id}/notifications`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  warehouses: async (id: string|number) => {
		return new Promise(async (resolve, reject) => {
      await axios.get(
        RestService.route(`${URL_COURIER}${id}/warehouses`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
		});
  },
};

export default CourierService;