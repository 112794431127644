import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { 
  IonButton, 
  IonButtons, 
  IonCol, 
  IonContent, 
  IonGrid, 
  IonHeader, 
  IonIcon, 
  IonItem, 
  IonLabel, 
  IonLoading, 
  IonMenuButton, 
  IonPage, 
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform
} from '@ionic/react';
import { 
  add, 
  createOutline,
  cubeOutline,
  trashOutline
} from 'ionicons/icons';

import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import MESSAGE from '../constances/Messages.json';

import { Direction, RoutePath } from '../components/RoutePath';
import { ToolbarStart } from '../components/ToolbarStart';
import { pageTable } from '../components/TableStyle';
import { AlertPrompt } from '../components/alerts/AlertPrompt';
import { ToastStatus } from '../components/toasts/ToastStatus';

import WarehouseService from '../services/WarehouseService';

const Warehouses: React.FC = () => {

  const offset: number = 50;

  const defaultSort: any = {
    by: 'name', 
    direction: 'asc'
  }

  const [warehouses, setWarehouses] = useState<Array<any>>([]);
  
  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [sort, setSort] = useState<any>(defaultSort);
  const [total, setTotal] = useState<number>(0);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => {}
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => {}
  });

  const columns: Array<any> = [
    {
      id: 'name',
      name: TEXT.warehouse,
      selector: (row: { name: string }) => row.name,
      cell: (row: { id: any, name: string }) => (
        <IonItem button lines="none" detail={false} routerLink={RoutePath.warehouseProducts.replace(':id', row.id)} 
        routerDirection={Direction()}>
          <IonLabel>{row.name}</IonLabel>
        </IonItem>
      ),
      sortable: true,
    },
    {
      id: 'action',
      name: '',
      cell: (row: { id: any, name: string }) => (
        <IonButtons>
          <IonButton fill='outline' routerLink={RoutePath.warehouseProducts.replace(':id', row.id)} 
          routerDirection={Direction()}>
            <IonIcon slot="start" icon={cubeOutline} />
            <IonLabel>{BUTTON.product}</IonLabel>
          </IonButton>
          <IonButton fill='outline' routerLink={RoutePath.warehouseEdit.replace(':id', row.id)} 
          routerDirection={Direction()}>
            <IonIcon slot="start" icon={createOutline} />
            <IonLabel>{BUTTON.edit}</IonLabel>
          </IonButton>
        </IonButtons>
      ),
      right: true
    }
  ];
  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const location = useLocation();

  const initialize = async () => {
    setInitialized(false);
    setShowLoading(true);
    await WarehouseService.count().then(async (data: any) => {
      setTotal(data.result);
      await loadWarehouses(0, pageLimit, defaultSort.by, defaultSort.direction, data);
    });
    setInitialized(true);
    setShowLoading(false);
  }

  const loadWarehouses = async (
    start: number = 0, 
    limit: number|null = pageLimit, 
    sortBy: string = defaultSort.by, 
    sortDirection: string = defaultSort.direction,
    totalRows: number = total
  ) => {
    if (totalRows < offset) {
      start = 0;
      limit = null;
      sortBy = defaultSort.by;
      sortDirection = defaultSort.direction;
    }
    await WarehouseService.list(
      true, 
      { by: sortBy, direction: sortDirection }, 
      start, 
      limit
    ).then((data: any) => {
      setWarehouses(data);
    });
  }
  
  const handlePageChange = async (currentPage: number) => {
    setPage(currentPage);
    loadWarehouses((currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
	};

	const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
    setPage(currentPage);
    setPageLimit(newPerPage);
    loadWarehouses((currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
	};

  const handleSort = async (column: any, sortDirection: any) => {
    setSort({ by: column.id, direction: sortDirection });
    loadWarehouses((page - 1) * pageLimit, pageLimit, column.id, sortDirection);
  };

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.warehouse) {
      if (initialized) {
        loadWarehouses();
      }
    }
  }, [location]);

  return (
    <IonPage>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertPrompt
      isPresent={presentAlert.isPresent} 
      message={presentAlert.message} 
      onDismiss={presentAlert.onDismiss} 
      />

      <ToastStatus 
      isPresent={presentToast.isPresent} 
      status={presentToast.status} 
      message={presentToast.message} 
      onDismiss={presentToast.onDismiss} 
      />

      {/* <ToolbarStart menuName={MENU.warehouse} /> */}
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{MENU.warehouse}</IonTitle>
          <IonButtons slot='end'>
            <IonButton routerLink={RoutePath.warehouseAdd} slot="end" 
            routerDirection={Direction()}>
              <IonIcon slot="start" icon={add} /> {BUTTON.add}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <IonGrid className='grid-main grid-has-ios-header'>
          <IonRow>
            <IonCol>
              <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large" slot="start">{MENU.warehouse}</IonTitle>
                </IonToolbar>
              </IonHeader>
            </IonCol>
          </IonRow>
        </IonGrid>

        {
          initialized ? 
            (total < offset) ?
            <DataTable
              columns={columns}
              data={warehouses}
              customStyles={pageTable}
              pagination
              noDataComponent={TEXT.noDataComponent}
              paginationComponentOptions={paginationComponentOptions}
              defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
              defaultSortFieldId={defaultSort.by}
            />
            :
            <DataTable
            columns={columns}
            data={warehouses}
            customStyles={pageTable}
            pagination
            noDataComponent={TEXT.noDataComponent}
            paginationComponentOptions={paginationComponentOptions}
            paginationServer
            paginationTotalRows={total}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            sortServer
            defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
            defaultSortFieldId={defaultSort.by}
            onSort={handleSort}
            />
          : <></>
        }

      </IonContent>
    </IonPage>
  );
};

export default Warehouses;
