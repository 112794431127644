import '../../css/Modal.scss';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonThumbnail,
  IonImg,
  SearchbarCustomEvent
} from "@ionic/react";
import {
  barcodeOutline,
  closeOutline
} from "ionicons/icons";

import { useEffect, useState } from "react";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import ProductService from "../../services/ProductService";
import UserService from '../../services/UserService';
import VendorService from '../../services/VendorService';

export const ModalProducts: React.FC<{
  title: string;
  selected?: any;
  search?: string;
  reference?: any;
  onDismiss: (product?: any, reference?: any) => any;
}> = ({
  title = 'Product',
  selected = null,
  search = '',
  reference = null,
  onDismiss
}) => {

  const pageLimit = 30;

  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [products, setProducts] = useState<Array<any>>([]);
  const [vendor, setVendor] = useState<any>(null);
  const [searchText, setSearchText] = useState<string>('');

  const searchProducts = async (search = '', event: any = null) => {

    const userData: any = await UserService.getSession();
    if (userData) {
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
      }).catch(() => { });
    }
    
    setSearchText(search);
    let start = 0;
    if (event) {
      start = products.length;
    }
    ProductService.list(
      true,
      { by: 'name', direction: 'asc' },
      start, pageLimit,
      search,
      ['figure'],
      vendor ? vendor.id : null
    ).then((data: any) => {
      if (event) {
        setProducts([
          ...products,
          ...data
        ]);
        event.target.complete();
        if (!data.length) {
          setInfiniteDisabled(true);
        } else {
          setInfiniteDisabled(false);
        }
      } else {
        setProducts(data);
      }
    });
  }

  const setProduct = (product: any) => {
    onDismiss(product, reference);
  }

  useEffect(() => {
    searchProducts();
  }, []);

  return (
    <>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss(selected, reference)}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton className='md-only' onClick={() => onDismiss(selected, reference)}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder={
              `${TEXT.productName}/${TEXT.productSku}/${TEXT.barcode}`
            }
            inputmode="none"
            searchIcon={barcodeOutline}
            onIonChange={(e: SearchbarCustomEvent) => searchProducts(e.detail.value)}
          >
          </IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent className="search">
        <IonList>
          {
            (products.length && !searchText) ?
              <IonItem button detail={false} onClick={() => setProduct(false)}>
                <IonLabel>{TEXT.productUnselect}</IonLabel>
                <IonIcon slot="end" icon={closeOutline} />
              </IonItem>
              : <></>
          }
          {
            products.map((item, index: number) => (
              <IonItem button detail={false} key={`product-${index}`} onClick={() => setProduct(item)}
                className={selected && selected.id === item.id ? 'selected' : ''}>
                <IonThumbnail slot="end">
                  {
                    item.logo &&
                    <IonImg src={item.logo_reference.thumbnail ? item.logo_reference.thumbnail : item.logo_reference.original} />
                  }
                </IonThumbnail>
                <IonLabel>{item.name}</IonLabel>
              </IonItem>
            ))
          }
        </IonList>

        <IonInfiniteScroll
          onIonInfinite={e => searchProducts(searchText, e)}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>

      </IonContent>

    </>
  );

};