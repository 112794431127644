import { useEffect, useState } from "react";

import { 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonButtons, 
  IonButton, 
  IonIcon, 
  IonContent,
  IonBackButton,
  IonPage,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import { 
  saveOutline 
} from "ionicons/icons";

import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

import MESSAGE from '../constances/Messages.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';

import { DistributorForm } from '../components/forms/DistributorForm';
import { GoBack, RoutePath } from '../components/RoutePath';
import { AlertPrompt } from "../components/alerts/AlertPrompt";
import { ToastStatus } from "../components/toasts/ToastStatus";
import Error from "../components/Error";

import DistributorService from "../services/DistributorService";

const DistributorsEdit: React.FC<{data?: any;}> = ({data = null}) => {

  const location = useLocation();

  const { id } = useParams<{ id: string; }>();
  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [doSubmit, setDoSubmit] = useState<boolean>(false);
  const [distributor, setDistributor] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => {}
  });

  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => {}
  });

  const initialize = async () => {
    setInitialized(false);
    setDistributor(null);
    if (data) {
      setDistributor(data);
    } else {
      await load(id);
    }
    setInitialized(true);
  }
  
  const load = async (id: string|null|undefined) => {
    if (id) {
      await DistributorService.get(id).then((data) => {
        setDistributor(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true, 
          status: false, 
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data, id]);

  useEffect(() => {
    if (location.pathname === RoutePath.distributorEdit.replace(':id', id)) {
      if (initialized) {
        initialize();
      }
    }
  }, [location]);

  return (
    <IonPage>

      <AlertPrompt
      isPresent={presentAlert.isPresent} 
      message={presentAlert.message} 
      onDismiss={presentAlert.onDismiss} 
      />

      <ToastStatus 
      isPresent={presentToast.isPresent} 
      status={presentToast.status} 
      message={presentToast.message} 
      onDismiss={presentToast.onDismiss} 
      />

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.distributorEdit}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => { setDoSubmit(true); }}>
              <IonIcon slot="start" icon={saveOutline}/>
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
              {
                distributor ?
                <DistributorForm data={distributor} isSubmit={doSubmit} onDidSubmit={(data: any) => { data && GoBack(); setDoSubmit(false) }} />
                :
                initialized ?
                <Error header={MESSAGE.error.not_found} />
                : <></>
              }
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

    </IonPage>
  );

};

export default DistributorsEdit;