import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonText,
  IonInput,
  IonItem,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonProgressBar,
  IonSpinner,
  IonBadge
} from "@ionic/react";
import { closeOutline, cloudUpload, download } from "ionicons/icons";

import moment from 'moment';

import { URL_CSV_TEMPLATES } from '../../constances/Rest';
import MESSAGE from '../../constances/Messages.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';
import MENU from '../../constances/Menu.json';

import { AlertStatus } from '../alerts/AlertStatus';
import { ToastStatus } from '../toasts/ToastStatus';
import Error from "../Error";

import UserService from '../../services/UserService';
import VendorService from '../../services/VendorService';
import ASNService from '../../services/ASNService';
import RestService from '../../services/RestService';

export const ModalASNImport: React.FC<{
  asnID?: string;
  onDismiss: (data?: any) => any;
}> = ({ asnID = null, onDismiss }) => {

  const [vendor, setVendor] = useState<any>(null);
  const [template, setTemplate] = useState<string>('');

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [formGroup, setFormGroup] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  const initialize = async () => {

    setInitialized(false);

    setTemplate(RestService.routePublic(`${URL_CSV_TEMPLATES}asn.csv`));

    resetFormValues();

    const userData: any = await UserService.getSession();
    if (userData) {
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
      }).catch(() => { });
    }

    setFormGroup(
      {
        expect_date: register('expect_date', {
          value: moment().format('YYYY-MM-DD'),
          required: MESSAGE.error.input_required
        }),
        csv: register('csv', {
          value: null,
          required: MESSAGE.error.input_required
        })
      }
    );

    setInitialized(true);

  }

  const resetFormValues = () => {
    reset();
  }

  const onSubmit = async (value: any) => {
    if (vendor) {

      setShowLoading(true);

      const onSuccess = (result: any) => {
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.submit_complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
        onDismiss(result);
      }

      const onError = (message: any) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: message,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
        onDismiss();
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const result: any = reader.result;
        const rows = result.split('\n');
        const rowsFormatted = rows.map((row: any) => {
          const columns = row.split(',');
          if (columns[2]) {
            if (moment(columns[2]).isValid()) {
              columns[2] = moment.utc(columns[2]).local().format('YYYY-MM-DD');
            }
          }
          return columns.join(',');
        });
        const resultFormatted = rowsFormatted.join('\n');

        await ASNService.csv(
          (asnID ? asnID : null),
          value.expect_date,
          resultFormatted,
          vendor.id,
          true
        ).then(async (result: any) => {
          onSuccess(result);
          resetFormValues();
        }).catch((error) => {
          onError(error);
        });

        setShowLoading(false);

      };
      reader.readAsBinaryString(value.csv[0]);

    }
  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
  }

  return (
    <>

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{BUTTON.uploadCSV} <IonBadge className='ion-hide-sm-down' color="primary">{MENU.productImport}</IonBadge></IonTitle>
          <IonButtons slot="end">
            <IonButton className='md-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {
          showLoading &&
          <IonProgressBar type="indeterminate" className='fixed'></IonProgressBar>
        }
      </IonHeader>

      <IonContent>

        {
          vendor ?
            <div className='ion-text-center container-block'>
              <div>
                <IonGrid>
                  <IonRow>
                    <IonCol>

                      {
                        formGroup ?
                          <form onSubmit={handleSubmit(onSubmit, onError)}>

                            <IonLabel>{TEXT.csvImportUTF8Detail}</IonLabel>

                            <p className="template-download">
                              <IonText color="medium">{TEXT.downloadTemplate}&nbsp;</IonText>
                              <a href={template} download>
                                <IonButton color="secondary" size="small">
                                  <IonIcon slot="start" icon={download} />
                                  {BUTTON.downloadTemplate}
                                </IonButton>
                              </a>
                            </p>

                            <div className="box ion-text-center">
                              <IonItem
                                disabled={showLoading}>
                                <IonLabel position="stacked">{TEXT.dateComeToWarehouse}</IonLabel>
                                <IonInput type='date' {...formGroup.expect_date}></IonInput>
                              </IonItem>

                              <IonItem lines="none"
                                disabled={showLoading}>
                                <IonLabel position="stacked" className="mb-10">{TEXT.selectFile}&nbsp;</IonLabel>
                                <input type="file" accept="text/csv" className="mb-10" {...formGroup.csv}></input>
                              </IonItem>
                            </div>

                            <div>
                              <IonButton type="submit" color="primary"
                                disabled={showLoading}>
                                {
                                  !showLoading ?
                                    <IonIcon slot="start" icon={cloudUpload} />
                                    : <IonSpinner></IonSpinner>
                                }
                                {BUTTON.import}
                              </IonButton>
                            </div>

                          </form>
                          : <></>

                      }

                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            : initialized ?
              <Error header={MESSAGE.error.invalid_vendor} />
              : <></>
        }
      </IonContent>
    </>
  );
};