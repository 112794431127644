import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';

import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  add
} from 'ionicons/icons';

import moment from 'moment';

import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import MESSAGE from '../constances/Messages.json';

import { Direction, RoutePath } from '../components/RoutePath';
import { pageTableExpandable } from '../components/TableStyle';
import { AlertPrompt } from '../components/alerts/AlertPrompt';
import { ToastStatus } from '../components/toasts/ToastStatus';

import LogService from '../services/LogService';
import UtilitiesService from '../services/UtilitiesService';
import ModuleService from '../services/ModuleService';

const Logs: React.FC = () => {

  const offset: number = 50;

  const defaultSort: any = {
    by: 'create_at',
    direction: 'desc'
  }

  const [logs, setLogs] = useState<Array<any>>([]);
  const [modules, setModules] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showProgressLoading, setShowProgressLoading] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [sort, setSort] = useState<any>(defaultSort);
  const [total, setTotal] = useState<number>(0);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const { register, getValues, setValue } = useForm();
  const [filterModule] = useState<any>(
    register('module', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, e.detail.value, getValues('user'), getValues('dateFrom'), getValues('dateTo'));
        UtilitiesService.updateURL('module', e.detail.value);
      }
    })
  );
  const [filterUser] = useState<any>(
    register('user', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('module'), e.detail.value, getValues('dateFrom'), getValues('dateTo'));
        UtilitiesService.updateURL('user', e.detail.value);
      }
    })
  );
  const [filterDateFrom] = useState<any>(
    register('dateFrom', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('module'), getValues('user'), e.detail.value, getValues('dateTo'));
        UtilitiesService.updateURL('from', e.detail.value);
      }
    })
  );
  const [filterDateTo] = useState<any>(
    register('dateTo', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('module'), getValues('user'), getValues('dateFrom'), e.detail.value);
        UtilitiesService.updateURL('to', e.detail.value);
      }
    })
  );

  const columns: Array<any> = [
    {
      id: 'name',
      name: TEXT.action,
      selector: (row: { name: string }) => row.name,
      cell: (row: { id: any; name: string; module_id: string; module_id_reference: any; module_key: string; module_value: string; }) => (
        <IonItem lines="none" detail={false}
          routerDirection={Direction()}>
          <IonLabel>
            {row.name}
            {
              row.module_id_reference ?
                <div className='subtitle'>
                  {row.module_id_reference.name}
                  {
                    row.module_key ?
                      ' > ' + row.module_key.toUpperCase() + ' - ' + row.module_value
                      : ''
                  }
                </div>
                :
                <div className='subtitle'>
                  Module: {row.module_id}
                  {
                    row.module_key ?
                      ' > ' + row.module_key.toUpperCase() + ' - ' + row.module_value
                      : ''
                  }
                </div>
            }
          </IonLabel>
        </IonItem>
      ),
      sortable: true,
    },
    {
      id: 'user_id',
      name: TEXT.action_user,
      selector: (row: { id: any; user_id: string; user_id_reference: any }) => row.user_id_reference ? row.user_id_reference.name : row.user_id,
      cell: (row: { id: any; user_id_reference: any }) => (
        <IonItem lines="none" detail={false}>
          {
            row.user_id_reference ?
              <IonLabel className='text-full'>
                {row.user_id_reference.name} {row.user_id_reference.last_name}
                <div className='subtitle'>
                  {row.user_id_reference.display_name}
                  {row.user_id_reference.email ? '(' + row.user_id_reference.email + ')' : ''}
                </div>
              </IonLabel>
              : '-'
          }
        </IonItem>
      ),
      sortable: true,
    },
    {
      id: 'create_at',
      name: TEXT.date,
      selector: (row: { create_at: any }) => row.create_at,
      cell: (row: { id: any; create_at: any }) => (
        <IonItem lines="none" detail={false}
          routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-right'>
            {row.create_at ? moment.utc(row.create_at).local().format('LL') : ''}
            <div className='subtitle'>
              {row.create_at ? moment.utc(row.create_at).local().format('LT') : ''}
            </div>
          </IonLabel>
        </IonItem>
      ),
      sortable: true,
      right: true,
      width: '250px'
    }
  ];
  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const location = useLocation();

  const initialize = async () => {

    setInitialized(false);
    setShowLoading(true);

    const urlParams = new URLSearchParams(location.search);
    const statusParam = urlParams.get('module')
    if (statusParam) {
      await setValue('module', statusParam);
    }
    const searchParam = urlParams.get('user')
    if (searchParam) {
      await setValue('user', searchParam);
    }
    const dateFromParam = urlParams.get('from')
    if (dateFromParam) {
      await setValue('dateFrom', dateFromParam);
    }
    const dateToParam = urlParams.get('to')
    if (dateToParam) {
      await setValue('dateTo', dateToParam);
    }

    await loadModules();

    await load(
      0,
      pageLimit,
      defaultSort.by,
      defaultSort.direction,
      getValues('module'),
      getValues('user'),
      getValues('dateFrom'),
      getValues('dateTo')
    );

    setInitialized(true);
    setShowLoading(false);

  }

  const load = async (
    start: number = 0,
    limit: number | null = pageLimit,
    sortBy: string = defaultSort.by,
    sortDirection: string = defaultSort.direction,
    module: string = getValues('module'),
    user: string = getValues('user'),
    dateFrom: string = getValues('dateFrom'),
    dateTo: string = getValues('dateTo')
  ) => {
    setShowProgressLoading(true);
    const count = () => {
      return new Promise(async (resolve) => {
        await LogService.count(
          null,
          null,
          module,
          user,
          dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
          dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null
        ).then(async (rows: any) => {
          resolve(rows);
        });
      });
    }
    await count().then(async (rows: any) => {
      setTotal(rows);
      if (rows < offset) {
        start = 0;
        limit = null;
        sortBy = defaultSort.by;
        sortDirection = defaultSort.direction;
      }
      await LogService.list(
        { by: sortBy, direction: sortDirection },
        start,
        limit,
        module,
        user,
        dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        true
      ).then(async (data: any) => {
        setLogs(data);
      });
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });
    setShowProgressLoading(false);
  }

  const loadModules = async () => {
    await ModuleService.list(
      true,
      { by: 'name', direction: 'asc' }
    ).then((data: any) => {
      setModules(data);
    });
  }

  const handlePageChange = async (currentPage: number) => {
    setPage(currentPage);
    load((currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
  };

  const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
    setPage(currentPage);
    setPageLimit(newPerPage);
    load((currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSort({ by: column.id, direction: sortDirection });
    load((page - 1) * pageLimit, pageLimit, column.id, sortDirection);
  };

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.log) {
      if (initialized) {
        load();
      }
    }
  }, [location]);

  return (
    <IonPage>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertPrompt
        isPresent={presentAlert.isPresent}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      {/* <ToolbarStart menuName={MENU.log} /> */}
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{MENU.log}</IonTitle>
        </IonToolbar>
        {
          showProgressLoading &&
          <IonProgressBar type="indeterminate" className='fixed'></IonProgressBar>
        }
      </IonHeader>

      <IonContent fullscreen>

        <IonGrid className='grid-main grid-has-ios-header'>
          <IonRow>
            <IonCol>
              <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large" slot="start">{MENU.log}</IonTitle>
                </IonToolbar>
              </IonHeader>
            </IonCol>
          </IonRow>
        </IonGrid>

        {
          initialized ?

            <>

              <IonGrid>
                <IonRow className='row-toolbar ion-margin-top'>
                  <IonCol size='12' sizeMd='6'>
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines='none' className="item-hidden-label">
                        <IonLabel position="fixed">{TEXT.module}</IonLabel>
                        <IonSelect
                          interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                          placeholder={TEXT.module} value={getValues('module')}
                          {...filterModule}
                          onIonChange={(e: any) => { filterModule.onChange(e) }}>
                          <IonSelectOption value="">{TEXT.allModule}</IonSelectOption>
                          {
                            modules.map((item: any, index: number) => (
                              <IonSelectOption key={`modules-${index}`} value={item.id}>{item.name}</IonSelectOption>
                            ))
                          }
                        </IonSelect>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                  <IonCol size='12' sizeMd='3'>
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines='none'>
                        <IonLabel position="fixed">{TEXT.dateFrom}</IonLabel>
                        <IonInput type="date" value={getValues('dateFrom')}
                          {...filterDateFrom}
                          onIonChange={(e: any) => { filterDateFrom.onChange(e) }}>
                        </IonInput>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                  <IonCol size='12' sizeMd='3'>
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines='none'>
                        <IonLabel position="fixed">{TEXT.dateTo}</IonLabel>
                        <IonInput type="date" value={getValues('dateTo')}
                          {...filterDateTo}
                          onIonChange={(e: any) => { filterDateTo.onChange(e) }}>
                        </IonInput>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                </IonRow>
              </IonGrid>

              {
                (total < offset) ?
                  <DataTable
                    columns={columns}
                    data={logs}
                    customStyles={pageTableExpandable}
                    pagination
                    noDataComponent={TEXT.noDataComponent}
                    paginationComponentOptions={paginationComponentOptions}
                    defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                    defaultSortFieldId={defaultSort.by}
                    expandableRows
                    expandableRowExpanded={() => false}
                    expandableRowsComponent={
                      (row: any) =>
                        <IonCard>
                          <IonCardContent>
                            <pre>
                              {JSON.stringify(row.data.content_hash, null, 2)}
                            </pre>
                          </IonCardContent>
                        </IonCard>
                    }
                  />
                  :
                  <DataTable
                    columns={columns}
                    data={logs}
                    customStyles={pageTableExpandable}
                    pagination
                    noDataComponent={TEXT.noDataComponent}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationServer
                    paginationTotalRows={total}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    sortServer
                    defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                    defaultSortFieldId={defaultSort.by}
                    expandableRows
                    expandableRowExpanded={() => false}
                    expandableRowsComponent={
                      (row: any) =>
                        <IonCard>
                          <IonCardContent>
                            <pre>
                              {JSON.stringify(row.data.content_hash, null, 2)}
                            </pre>
                          </IonCardContent>
                        </IonCard>
                    }
                    onSort={handleSort}
                  />
              }

            </>
            : <></>

        }

      </IonContent>
    </IonPage>
  );
};

export default Logs;
