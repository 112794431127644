import { API_CACHE } from "../constances/Configs";
import { TOKEN } from "../constances/Credentials";
import { 
  BASE_URL, 
  BASE_URL_DEVELOP, 
  BASE_URL_LOCAL, 
  BASE_URL_TESTING,
  URL_ENDPOINT_DIRECTORY,
  URL_ENDPOINT_DIRECTORY_DEVELOP,
  URL_ENDPOINT_DIRECTORY_LOCAL,
  URL_ENDPOINT_DIRECTORY_TESTING,
} from "../constances/Rest";
import MESSAGE from '../constances/Messages.json';

import StorageService from "./StorageService";
import { AxiosRequestHeaders } from "axios";

const RestService = {
  route: (url: string) => {
    let baseURL: string = BASE_URL + URL_ENDPOINT_DIRECTORY;
    if (process.env.REACT_APP_ENV === 'develop') {
      baseURL = BASE_URL_DEVELOP + URL_ENDPOINT_DIRECTORY_DEVELOP;
    } else if (process.env.REACT_APP_ENV === 'testing') {
      baseURL = BASE_URL_TESTING + URL_ENDPOINT_DIRECTORY_TESTING;
    } else if (process.env.REACT_APP_ENV === 'local') {
      baseURL = BASE_URL_LOCAL + URL_ENDPOINT_DIRECTORY_LOCAL;
    }
    let suffix = '';
    if (!API_CACHE) {
      const timestap = `v=${new Date().getTime()}`
      if (url.indexOf('?') >= 0) {
        suffix = `&${timestap}`
      } else {
        suffix = `?${timestap}`
      }
    }
    return baseURL + url + suffix;
  },
  routePublic: (url: string) => {
    let baseURL: string = BASE_URL;
    if (process.env.REACT_APP_ENV === 'develop') {
      baseURL = BASE_URL_DEVELOP;
    } else if (process.env.REACT_APP_ENV === 'testing') {
      baseURL = BASE_URL_TESTING;
    }
    let suffix = '';
    if (!API_CACHE) {
      const timestap = `v=${new Date().getTime()}`
      if (url.indexOf('?') >= 0) {
        suffix = `&${timestap}`
      } else {
        suffix = `?${timestap}`
      }
    }
    return baseURL + url + suffix;
  },
  retrieveHeader: async (
    requireSession: boolean = false,
    requireCredtial: boolean = true,
    encodedFormData: boolean = false
  ) => {
    const language: any = await StorageService.get('language') || navigator.language.substring(0, 2) || 'th';
    const getLoginToken = async () => {
      return await StorageService.get('token');
    }
    let headers: any = {
      'Language': language
    };
    if (encodedFormData) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    if (requireCredtial) {
      headers['Token'] = TOKEN;
    }
    if (requireSession) {
      const loginToken = await getLoginToken();
      if (loginToken) {
        headers['Authorization'] = `Bearer ${loginToken}`;
      }
    }
    return headers;
  },
  retrieveHeaderWithCredential: async (
    username: any,
    password: any,
    encodedFormData: boolean = false
  ) => {
    const language: any = await StorageService.get('language') || navigator.language.substring(0, 2) || 'th';
    let headers: any = {
      'Authorization': `Basic ${window.btoa(`${username}:${password}`)}`,
      'Token': TOKEN,
      'Language': language
    };
    if (encodedFormData) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return headers;
  },
  handleQuery: (options: any) => {
    let prefix = '';
    const optionQuery = new URLSearchParams(options);
    if (Object.keys(options).length) {
      prefix = '?';
    }
    return `${prefix}${optionQuery}`;
  },
  handleResponse: async (axiosResponse: any) => {
    return new Promise(async (resolve, reject) => {
      if (axiosResponse.status === 200) {
        if (axiosResponse.data) {
          resolve(axiosResponse.data);
        } else {
          reject(MESSAGE.error.invalid_response);
        }
      } else {
        reject(axiosResponse.statusText);
      }
    });
  },
  async createFormData(values: any = null) {
    let formData = new FormData();
    if (values !== null && typeof values === 'object') {
      for (const key in values) {
        await formData.append(
          (values instanceof FileList) ? 
          key.endsWith("[]") ? key : `${key}[]`
          : key
          , 
          (
            values[key] !== null 
            && typeof values[key] === 'object' 
            && !(values[key] instanceof FileList)
            && !(values[key] instanceof Blob || values[key] instanceof File)
          ) ? 
          JSON.stringify(values[key]) 
          : values[key]
        );
      }
    }
    return formData;
  }
}

export default RestService;