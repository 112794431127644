import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonContent, IonItem, IonLabel, IonLoading, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useHistory } from "react-router-dom";
import { IonButton, IonAlert } from '@ionic/react';
import { Imagires } from '../components/ImageVariable';
import { Direction, GoBack, RoutePath } from '../components/RoutePath';

import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import TERM from '../constances/Term.json';
import MESSAGE from '../constances/Messages.json';

const TermCondition: React.FC = () => {

  const history = useHistory();

  const [iserror, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [showLoading, setShowLoading] = useState(false);

  const [checked, setChecked] = useState(false);

  const GoToRegister = () => {
    if (checked) {
      history.push(RoutePath.register);
    } else {
      setMessage(MESSAGE.info.termAllow);
      setIserror(true);
    }

  }

  function NewlineText(props: { text: any; }) {
    const text = props.text;
    const newText = text.split('\n').map(
      (str: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, i: number) =>
        <p key={`line-${i}`} className='condition'>{str}</p>
    );
    return newText;
  }

  return (
    <IonPage>
      <IonContent className="ion-padding term">
        <IonGrid>
          <IonRow class="">
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
              <IonRow>
                <IonCol>
                  <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    cssClass="my-custom-class"
                    header={TEXT.header_alert}
                    message={message}
                    buttons={["Dismiss"]}
                  />

                  <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={TEXT.pleaseWait}
                    duration={5000}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size='6' sizeMd='4' sizeLg='2'>
                  <div className="logo">
                    <img src={Imagires.logo}></img>
                  </div>
                </IonCol>

                <IonCol size='12' sizeMd='8' sizeLg='10' className='ion-text-center ion-text-md-right'>
                  <h2>{TEXT.term_condition}</h2>
                </IonCol>

                <IonCol size='12' className='card'>
                  <IonCard>
                    <IonCardContent><NewlineText text={TERM.termCondition} /></IonCardContent>
                  </IonCard>

                  <IonItem lines="none">
                    <IonLabel>{BUTTON.termConditionAllow}</IonLabel>
                    <IonCheckbox slot="start" checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className='ion-text-center'>
                  <IonButton onClick={GoToRegister} color="primary">{BUTTON.submitTerm}</IonButton>
                  <IonButton routerLink={RoutePath.login} routerDirection={Direction()} color="medium">{BUTTON.cancelRegister}</IonButton>
                </IonCol>
              </IonRow>

            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent >
    </IonPage>
  );
};

export default TermCondition;
