import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import { 
  IonLabel, 
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonInput,
  IonButton,
  IonIcon,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonModal,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { 
  addCircle, 
  alertCircleOutline, 
  archiveOutline, 
  qrCodeOutline,
  removeCircle
} from 'ionicons/icons';

import MESSAGE from '../../constances/Messages.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import { ToastStatus } from '../toasts/ToastStatus';
import { AlertStatus } from '../alerts/AlertStatus';
import { AlertPrompt } from '../alerts/AlertPrompt';
import { ModalLocation } from '../modal/Location';
import { ModalLocationScanner } from '../modal/LocationScanner';

import TransferService from "../../services/TransferService";
import ProductService from '../../services/ProductService';
import TransferProductService from '../../services/TransferProductService';

export const TransferForm: React.FC<{
  data?: any;
  name?: string;
  isSubmit?: boolean;
  onDidSubmit?: (data?: any) => any;
}> = ({data = null, name = '', isSubmit = false, onDidSubmit}) => {

  const slideOptsImage = {
    initialSlide: 0
  };

  const [showLoading, setShowLoading] = useState(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => {}
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => {}
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => {}
  });

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [importResult, setImportResult] = useState<any>(null);

  const { register, handleSubmit, setValue, getValues, trigger, clearErrors, reset, control, formState: { errors } } = useForm();
  const { fields, append, remove } = useFieldArray(
    {
      name: "items",
      control
    }
  );
  const [formGroup, setFormGroup] = useState<any>(null);

  const appendInput = () => {
    append({
      location_from: '',
      location_to: '',
      products: []
    });
  };

  const [modalLocationData, setModalLocationData] = useState<any>(null);
  const [modalScannerIsOpen, setModalScannerIsOpen] = useState<any>(null);

  const initialize = async () => {
    setFormGroup(
      {
        items: register('items', {
          value: [],
          required: MESSAGE.error.input_required
        })
      }
    );
    if (data) {
      loadProducts(data.id);
    }
  }

  const resetFormValues = () => {
    reset();
  }

  const loadItemProducts = async (locationCode: string = '', index: number) => {

    const locationCodeParts = locationCode.split('.');
    await ProductService.listByWarehouse(
      locationCodeParts[0] ? locationCodeParts[0] : null,
      true,
      null,
      null,
      null,
      null,
      null,
      null,
      locationCodeParts[1] ? locationCodeParts[1] : null,
      locationCodeParts[2] ? locationCodeParts[2] : null,
      locationCodeParts[3] ? locationCodeParts[3] : null,
      locationCodeParts[4] ? locationCodeParts[4] : null,
      locationCodeParts[5] ? locationCodeParts[5] : null,
      ['figure', 'warehouses'],
      true
    ).then(async (data: any) => {
      const products = data.map(
        (item: any) => {
          if (item.warehouses && item.warehouses.length) {
            for (let warehouseItem of item.warehouses) {
              if (warehouseItem.id === item.product_warehouse_id) {
                let newItem: any = {...item};
                newItem.quantity = 1;
                newItem.max_quantity = warehouseItem.quantity;
                return newItem;
              }
            }
          }
        }
      );
      await setValue(`items.${index}.products`, products);
      trigger();
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });

  }

  const loadProducts = async (id: string) => {
    if (id) {
      await TransferProductService.list(
        id,
        null,
        null,
        null,
        null,
        null,
        null,
        ['product', 'origin_product_warehouse']
      ).then(async (data: any) => {

        for (let item of data) {

          let locationCodeFrom: string = '';
          if (item.origin_product_warehouse_reference) {
            locationCodeFrom = (item.origin_product_warehouse_reference.warehouse ? item.origin_product_warehouse_reference.warehouse : '')
            + '.' + (item.origin_product_warehouse_reference.floor ? item.origin_product_warehouse_reference.floor : '')
            + '.' + (item.origin_product_warehouse_reference.aisle ? item.origin_product_warehouse_reference.aisle : '')
            + '.' + (item.origin_product_warehouse_reference.bay ? item.origin_product_warehouse_reference.bay : '')
            + '.' + (item.origin_product_warehouse_reference.level ? item.origin_product_warehouse_reference.level : '')
            + '.' + (item.origin_product_warehouse_reference.bin ? item.origin_product_warehouse_reference.bin : '');
          }

          let locationCodeTo: string = (item.destination_warehouse ? item.destination_warehouse : '')
          + '.' + (item.destination_floor ? item.destination_floor : '')
          + '.' + (item.destination_aisle ? item.destination_aisle : '')
          + '.' + (item.destination_bay ? item.destination_bay : '')
          + '.' + (item.destination_level ? item.destination_level : '')
          + '.' + (item.destination_bin ? item.destination_bin : '');

          const product = {...item.product_reference};
          if (item.origin_product_warehouse_reference) {
            product.max_quantity = item.origin_product_warehouse_reference.quantity;
            product.quantity = item.quantity;
          } else {
            product.max_quantity = 0;
            product.quantity = 0;
          }
          append({
            location_from: locationCodeFrom,
            location_to: locationCodeTo,
            products: [product]
          });

        }

      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  const onSubmit = async (value: any) => {

    setShowLoading(true);

    const onSuccess = (result: any) => {
      setPresentToast({
        isPresent: true, 
        status: true, 
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    const onError = (message: any) => {
      setPresentToast({
        isPresent: true, 
        status: false, 
        message: message,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit();
      }
    }
    
    if (data) {
      await TransferService.import(value, data.id).then(async (result: any) => {
        onSuccess(result);
      }).catch((error) => {
        onError(error);
      });
    } else {
      await TransferService.import(value).then(async (result: any) => {
        onSuccess(result);
        resetFormValues();
      }).catch((error) => {
        onError(error);
      });
    }

    setShowLoading(false);

  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true, 
      status: false, 
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
    if (onDidSubmit) {
      onDidSubmit();
    }
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    resetFormValues();
    initialize();
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit, onError)();
    }
  }, [isSubmit]);

  return (
    <>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus 
      isPresent={presentAlert.isPresent} 
      status={presentAlert.status} 
      message={presentAlert.message} 
      onDismiss={presentAlert.onDismiss} 
      />

      <AlertPrompt
      isPresent={presentAlertPrompt.isPresent} 
      message={presentAlertPrompt.message} 
      onDismiss={presentAlertPrompt.onDismiss} 
      />

      <ToastStatus 
      isPresent={presentToast.isPresent} 
      status={presentToast.status} 
      message={presentToast.message} 
      onDismiss={presentToast.onDismiss} 
      />

      {
        formGroup ? 
        <form onSubmit={handleSubmit(onSubmit, onError)}>

          {/* Card Content */}
          <IonGrid>
            {fields.map((element, index) => (
              <IonRow key={element.id}>
                <IonCol size='12'>
                  <IonCard>

                    <IonToolbar mode="md">
                      <IonTitle slot='start'>
                        <h4 className="text-full">
                          {TEXT.item} - {index + 1}
                        </h4>
                      </IonTitle>
                      <IonButtons slot='end'>
                        <IonButton fill='clear' onClick={() => {
                          remove(index);
                          trigger();
                        }}>
                          <IonIcon icon={removeCircle} color='danger' slot="icon-only" />
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>

                    <IonCardContent className='ion-no-padding'>
                      <IonGrid>
                        <IonRow>
                          {/* Location From */}
                          <IonCol size='12' sizeMd='6'>
                            <IonCard>
                              <IonCardHeader>
                                <IonCardTitle>{TEXT.slotFrom}{TEXT.location}</IonCardTitle>
                              </IonCardHeader>
                              <IonCardContent>
                                <IonGrid>
                                  <IonRow>
                                    <IonCol size='12'>
                                      <IonItem className='item-no-padding'>
                                        <IonIcon slot="start" size='small' icon={archiveOutline} />
                                        <IonInput
                                          type='text'
                                          placeholder='Ex. W.F.A.B.L.B'
                                          readonly={true}
                                          {...register(`items.${index}.location_from`, {
                                            required: MESSAGE.error.input_required
                                          })}
                                          onClick={() => {
                                            setModalLocationData({
                                              input: 'location_from',
                                              index: index
                                            });
                                          }}
                                          >
                                          </IonInput>
                                          <IonButtons slot='end'>
                                            <IonButton expand='block' color="tertiary"
                                              onClick={() => !data &&  setModalScannerIsOpen({
                                                input: 'location_from',
                                                index: index
                                              })}>
                                              <IonIcon slot="icon-only" icon={qrCodeOutline}></IonIcon>
                                            </IonButton>
                                          </IonButtons>
                                      </IonItem>
                                      <ErrorMessage errors={errors} name={`items.${index}.location_from`}
                                        render={({ message }) =>
                                          <div className='form-help'>
                                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                            {message ? message : ''}
                                          </div>
                                        } />
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </IonCardContent>
                            </IonCard>
                          </IonCol>

                          {/* Location To */}
                          <IonCol size='12' sizeMd='6'>
                            <IonCard>
                              <IonCardHeader>
                                <IonCardTitle>{TEXT.slotTo}{TEXT.location}</IonCardTitle>
                              </IonCardHeader>
                              <IonCardContent>
                                <IonGrid>
                                  <IonRow>
                                    <IonCol size='12'>
                                      <IonItem className='item-no-padding'>
                                        <IonIcon slot="start" size='small' icon={archiveOutline} />
                                        <IonInput
                                          type='text'
                                          placeholder='Ex. W.F.A.B.L.B'
                                          readonly={true}
                                          {...register(`items.${index}.location_to`, { required: MESSAGE.error.input_required })}
                                          onClick={() => {
                                            setModalLocationData({
                                              input: 'location_to',
                                              index: index
                                            });
                                          }}
                                        >
                                        </IonInput>
                                        <IonButtons slot='end'>
                                          <IonButton expand='block' color="tertiary"
                                            onClick={() => !data &&  setModalScannerIsOpen({
                                              input: 'location_to',
                                              index: index
                                            })}>
                                            <IonIcon slot="icon-only" icon={qrCodeOutline}></IonIcon>
                                          </IonButton>
                                        </IonButtons>
                                      </IonItem>
                                      <ErrorMessage errors={errors} name={`items.${index}.location_to`}
                                        render={({ message }) =>
                                          <div className='form-help'>
                                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                            {message ? message : ''}
                                          </div>
                                        } 
                                        />
                                    </IonCol>

                                  </IonRow>
                                </IonGrid>
                              </IonCardContent>
                            </IonCard>
                          </IonCol>

                          {/* Product in Location From */}
                          {
                            getValues(`items.${index}.products`) && getValues(`items.${index}.products`).length ? 
                            <IonCol size='12' sizeMd='12'>
                              
                              {/* Product */}
                              <IonCard>
                                <IonCardHeader>
                                  <IonCardTitle>{TEXT.productTransfer}</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                {
                                  getValues(`items.${index}.products`).map((item: any, productIndex: number) => (
                                    <IonRow key={`product-${index}-${productIndex}`}
                                    >
                                      <IonCol size='12' sizeMd="7"
                                    className={
                                      getValues(`items.${index}.products[${productIndex}].quantity`) !== 0 
                                      && getValues(`items.${index}.products[${productIndex}].quantity`) !== '0' 
                                      && getValues(`items.${index}.products[${productIndex}].quantity`) !== ''
                                      && getValues(`items.${index}.products[${productIndex}].quantity`) !== null
                                      ? 'product-info tiny' : 'product-info tiny disabled'
                                    }>
                                        <h4 className='ion-text-left'>{item.name}</h4>
                                        <IonText color="medium" className='ion-text-left'>
                                          {
                                            item.category_reference ? 
                                            <p>{TEXT.category}: {item.category_reference.name}</p>
                                            : <></>
                                          }
                                          <p>{TEXT.productSku} - {item.sku}</p>
                                        </IonText>
                                      </IonCol>
                                      <IonCol size='12' sizeMd='2'
                                    className={
                                      getValues(`items.${index}.products[${productIndex}].quantity`) !== 0 
                                      && getValues(`items.${index}.products[${productIndex}].quantity`) !== '0' 
                                      && getValues(`items.${index}.products[${productIndex}].quantity`) !== ''
                                      && getValues(`items.${index}.products[${productIndex}].quantity`) !== null
                                      ? 'product-info tiny' : 'product-info tiny disabled'
                                    }>
                                        {
                                          item.figure_reference && item.figure_reference.length ?
                                          <IonSlides options={slideOptsImage} className="product-info-figure-slide">
                                            {item.figure_reference.map((item: any, figureI: number) => {
                                              return (
                                                <IonSlide key={`image-slide-${index}-${productIndex}-${figureI}`}>
                                                <div>
                                                  <IonImg className='content profile-image' src={item.original} />
                                                </div>
                                                </IonSlide>
                                              )
                                            })}
                                          </IonSlides>
                                          : <></>
                                        }
                                      </IonCol>
                                      <IonCol size='12' sizeMd='3'>
                                        <IonItem>
                                          <IonLabel position="stacked" >{TEXT.qty}</IonLabel>
                                          <IonInput type='number' min={0} value={item.quantity} max={item.max_quantity}
                                          { 
                                            ...register(
                                              `items.${index}.products[${productIndex}].quantity`, 
                                              { required: MESSAGE.error.input_required }
                                            )
                                          }
                                          onIonChange={(e) => {
                                            if (getValues(`items.${index}.products[${productIndex}].quantity`) !== e.detail.value) {
                                              setValue(`items.${index}.products[${productIndex}].quantity`, e.detail.value);
                                              trigger();
                                            }
                                          }}>
                                          </IonInput>
                                        </IonItem>
                                      </IonCol>
                                    </IonRow>
                                  ))
                                }
                                </IonCardContent>
                              </IonCard>
    
                            </IonCol>
                            : <></>
                          }
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            ))}

            <IonRow>
              <IonCol size='12'>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonButton expand='block' color="primary" onClick={appendInput}>
                        <IonIcon icon={addCircle} slot="start" />
                        {BUTTON.add}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>

          {/* Modal PutAway */}
          <IonModal canDismiss swipeToClose isOpen={modalLocationData ? true : false}
            onDidDismiss={() => setModalLocationData(null)}>
            <ModalLocation
              reference={modalLocationData}
              lockWarehouse={false}
              currentLocationCode={modalLocationData ? getValues(`items.${modalLocationData.index}.${modalLocationData.input}`) : null}
              onDismiss={async (locationData: any, reference: any) => {
                if (locationData) {
                  const locationCode: string = 
                  (locationData.warehouse ? locationData.warehouse : '')
                  + '.' + (locationData.floor ? locationData.floor : '')
                  + '.' + (locationData.aisle ? locationData.aisle : '')
                  + '.' + (locationData.bay ? locationData.bay : '')
                  + '.' + (locationData.level ? locationData.level : '')
                  + '.' + (locationData.bin ? locationData.bin : '');
                  if (reference && reference.input) {
                    if (reference.input === 'location_from') {
                      setValue(`items.${reference.index}.location_from`, locationCode);
                      loadItemProducts(locationCode, reference.index);
                    } else if (reference.input === 'location_to') {
                      setValue(`items.${reference.index}.location_to`, locationCode);
                    }
                  }
                }
                setModalLocationData(null);
              }}
            />
          </IonModal>

          {/* Scanner */}
          <IonModal canDismiss swipeToClose isOpen={modalScannerIsOpen || modalScannerIsOpen === 0 ? true : false}
            onDidDismiss={() => setModalScannerIsOpen(null)}>
            <ModalLocationScanner
              reference={modalScannerIsOpen}
              onDismiss={(locationCode: any = null, reference: any = null) => {
                if (reference || reference === 0) {
                  if (reference.input === 'location_from') {
                    setValue(`items.${reference.index}.location_from`, locationCode);
                    loadItemProducts(locationCode, reference.index);
                  } else if (reference.input === 'location_to') {
                    setValue(`items.${reference.index}.location_to`, locationCode);
                  }
                }
                setModalScannerIsOpen(null);
              }}
            />
          </IonModal>
  
        </form>
        : <></>

      }

    </>
  );

};