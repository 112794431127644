import {
  IonPage,
  IonContent,
  IonCol,
  IonRow,
  IonSearchbar,
  IonLabel,
  IonButton,
  IonGrid,
  IonButtons,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonLoading,
  IonHeader,
  IonMenuButton,
  IonTitle,
  SearchbarCustomEvent
} from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";

import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import { Direction, RoutePath } from "../components/RoutePath";
import DataTable from "react-data-table-component";
import { pageTable } from "../components/TableStyle";
import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { AlertPrompt } from '../components/alerts/AlertPrompt';
import { ToastStatus } from '../components/toasts/ToastStatus';

import UserService from '../services/UserService';
import VendorService from '../services/VendorService';

import moment from 'moment';
import CancelService from "../services/CancelService";

const ProductCancel: React.FC = () => {

  const offset: number = 50;

  const defaultSort: any = {
    by: ['receive_date', 'create_at'],
    direction: 'desc'
  }

  const [vendor, setVendor] = useState<any>(null);
  const [cancels, setCancels] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [importResult, setImportResult] = useState<any>(null);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [sort, setSort] = useState<any>(defaultSort);
  const [total, setTotal] = useState<number>(0);

  const { register, getValues, setValue } = useForm();
  const [filterStatus] = useState<any>(
    register('status', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, e.detail.value, getValues('search'), getValues('dateFrom'), getValues('dateTo'), e.vendorID);
      }
    })
  );
  const [filterSearch] = useState<any>(
    register('search', {
      value: ''
    })
  );
  const [filterDateFrom] = useState<any>(
    register('dateFrom', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('status'), getValues('search'), e.detail.value, getValues('dateTo'), e.vendorID);
      }
    })
  );
  const [filterDateTo] = useState<any>(
    register('dateTo', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('status'), getValues('search'), getValues('dateFrom'), e.detail.value, e.vendorID);
      }
    })
  );

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const history = useHistory();

  const location = useLocation();

  const colors: any = COLORS.cancel;
  const statuses: any = STATUS.cancel;

  const columns: any = [
    {
      id: 'cancel.id',
      name: TEXT.cancelNo,
      selector: (row: { id: any; }) => row.id,
      cell: (row: { id: any }) => (
        <IonItem lines="none" detail={false}
          routerLink={RoutePath.productCancelView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='ion-text-center'>
            {row.id}
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      width: '120px'
    },
    {
      id: 'cancel.ref_code',
      name: TEXT.refCode,
      selector: (row: { ref_code: any; }) => row.ref_code,
      cell: (row: { id: any, ref_code: any, order: any, order_reference: any }) => (
        <IonItem lines="none" detail={false}
          routerLink={RoutePath.orderView.replace(':id', row.order)}
          routerDirection={Direction()}>
          <IonLabel className="text-full ion-text-center">
            <h2>
              {
                row.order_reference ?
                  row.order_reference.order_no
                  : `ID: ${row.order}`
              }
              {
                row.ref_code ?
                  <div className='subtitle'>
                    ({row.ref_code})
                  </div>
                  : <></>
              }
            </h2>
          </IonLabel>
        </IonItem>
      ),
      sortable: true,
      center: true,
      minWidth: '200px',
    },
    {
      id: 'cancel.create_at',
      name: TEXT.dateNotified,
      selector: (row: { create_at: any; }) => row.create_at,
      cell: (row: { id: any, create_at: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productCancelView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className="text-full ion-text-center">
            <h2>
              {row.create_at ? moment.utc(row.create_at).local().format('LL') : '-'}
              <div className='subtitle'>
                {row.create_at ? moment.utc(row.create_at).local().format('LT') : ''}
              </div>
            </h2>
          </IonLabel>
        </IonItem>
      ),
      sortable: true,
      center: true,
      minWidth: '250px',
    },
    {
      id: 'cancel.receive_date',
      name: TEXT.receivedDate,
      selector: (row: { receive_date: any; }) => row.receive_date,
      cell: (row: { id: any, receive_date: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productCancelView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className="text-full ion-text-center">
            <h2>
              {row.receive_date ? moment.utc(row.receive_date).local().format('LL') : '-'}
              <div className='subtitle'>
                {row.receive_date ? moment.utc(row.receive_date).local().format('LT') : ''}
              </div>
            </h2>
          </IonLabel>
        </IonItem>
      ),
      sortable: true,
      center: true,
      minWidth: '250px',
    },
    {
      id: 'cancel.tracking',
      name: TEXT.trackingNo,
      selector: (row: { tracking: any }) => row.tracking,
      cell: (row: { id: any, tracking: any, courier_reference: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productCancelView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className="text-full ion-text-center">
            <h2>
              {row.tracking ? row.tracking : '-'}
              {
                row.courier_reference ?
                  <div className='subtitle'>
                    ({row.courier_reference.name})
                  </div>
                  : <></>
              }
            </h2>
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      minWidth: '250px',
    },
    {
      id: 'cancel.status',
      name: TEXT.status,
      selector: (row: { status: any }) => row.status,
      cell: (row: { status: any }) => (
        <IonButtons>
          <IonButton fill='solid' color={colors[row.status]}>
            <IonLabel>{statuses[row.status]}</IonLabel>
          </IonButton>
        </IonButtons>
      ),
      width: '150px',
      center: true,
      sortable: true,
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const initialize = async () => {
    setInitialized(false);
    setShowLoading(true);
    setImportResult(null);
    const userData: any = await UserService.getSession();
    if (userData) {
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
        await load(
          0,
          pageLimit,
          defaultSort.by,
          defaultSort.direction,
          getValues('status'),
          getValues('search'),
          getValues('dateFrom'),
          getValues('dateTo'),
          vendorData.id
        );
      }).catch(() => { });
    }
    setInitialized(true);
    setShowLoading(false);
  }

  const load = async (
    start: number = 0,
    limit: number | null = pageLimit,
    sortBy: string = defaultSort.by,
    sortDirection: string = defaultSort.direction,
    status: string = getValues('status'),
    search: string = getValues('search'),
    dateFrom: string = getValues('dateFrom'),
    dateTo: string = getValues('dateTo'),
    vendorID: any = vendor ? vendor.id : null
  ) => {
    const count = () => {
      return new Promise(async (resolve) => {
        await CancelService.countByVendor(
          vendorID,
          true,
          null,
          null,
          status,
          search,
          dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
          dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null
        ).then(async (rows: any) => {
          resolve(rows);
        });
      });
    }
    await count().then(async (rows: any) => {
      setTotal(rows);
      if (rows < offset) {
        start = 0;
        limit = null;
        sortBy = defaultSort.by;
        sortDirection = defaultSort.direction;
      }
      await CancelService.listByVendor(
        vendorID,
        true,
        { by: sortBy, direction: sortDirection },
        start,
        limit,
        status,
        search,
        dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        ['courier', 'order']
      ).then(async (data: any) => {
        setCancels(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    });
  }

  const handlePageChange = async (currentPage: number) => {
    setPage(currentPage);
    load((currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
  };

  const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
    setPage(currentPage);
    setPageLimit(newPerPage);
    load((currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSort({ by: column.id, direction: sortDirection });
    load((page - 1) * pageLimit, pageLimit, column.id, sortDirection);
  };

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.withdraw) {
      if (initialized) {
        load();
      }
    }
  }, [location]);

  return (
    <IonPage>
      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertPrompt
        isPresent={presentAlert.isPresent}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{MENU.productCancel}</IonTitle>
          {/* 
          <IonButtons slot='end'>
      <IonButton routerLink={RoutePath.productCancelAdd} slot="end"
        routerDirection={Direction()}>
        <IonIcon slot="start" icon={add} /> {BUTTON.add}
      </IonButton>
      </IonButtons> 
      */}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow className='row-toolbar ion-margin-top'>
            <IonCol size='12' sizeMd='3'>
              <IonToolbar className='toolbar-page'>
                <IonItem lines="none">
                  <IonSearchbar
                    placeholder={TEXT.cancelNo}
                    {...filterSearch}
                    onIonChange={(e: SearchbarCustomEvent) => { setValue('search', e.detail.value) }}
                    onKeyUp={(e: KeyboardEvent) => {
                      if (e.key === "Enter") {
                        load(
                          0,
                          pageLimit,
                          sort.by,
                          sort.direction,
                          getValues('status'),
                          (e.target as HTMLTextAreaElement).value,
                          getValues('dateFrom'),
                          getValues('dateTo'),
                          vendor.id
                        );
                      }
                    }}
                    onIonClear={async () => {
                      setValue('search', '');
                      load(
                        0,
                        pageLimit,
                        sort.by,
                        sort.direction,
                        getValues('status'),
                        '',
                        getValues('dateFrom'),
                        getValues('dateTo'),
                        vendor.id
                      );
                    }}
                  >
                  </IonSearchbar>
                </IonItem>
              </IonToolbar>
            </IonCol>
            <IonCol size='12' sizeMd='3'>
              <IonToolbar className='toolbar-page'>
                <IonItem lines='none' className="item-hidden-label">
                  <IonLabel position="fixed">{TEXT.status}</IonLabel>
                  <IonSelect
                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                    placeholder={TEXT.status} value={getValues('status')}
                    {...filterStatus}
                    onIonChange={(e: any) => { e.vendorID = vendor.id; filterStatus.onChange(e) }}>
                    <IonSelectOption value="">{TEXT.allStatus}</IonSelectOption>
                    {
                      Object.keys(statuses).map((item: any, index: number) => (
                        <IonSelectOption key={`status-${index}`} value={item}>{statuses[item]}</IonSelectOption>
                      ))
                    }
                  </IonSelect>
                </IonItem>
              </IonToolbar>
            </IonCol>
            <IonCol size='12' sizeMd='3'>
              <IonToolbar className='toolbar-page'>
                <IonItem lines='none'>
                  <IonLabel position="fixed">{TEXT.dateFrom}</IonLabel>
                  <IonInput type="date" value={getValues('dateFrom')}
                    {...filterDateFrom}
                    onIonChange={(e: any) => { e.vendorID = vendor.id; filterDateFrom.onChange(e) }}>
                  </IonInput>
                </IonItem>
              </IonToolbar>
            </IonCol>
            <IonCol size='12' sizeMd='3'>
              <IonToolbar className='toolbar-page'>
                <IonItem lines='none'>
                  <IonLabel position="fixed">{TEXT.dateTo}</IonLabel>
                  <IonInput type="date" value={getValues('dateTo')}
                    {...filterDateTo}
                    onIonChange={(e: any) => { e.vendorID = vendor.id; filterDateTo.onChange(e) }}>
                  </IonInput>
                </IonItem>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>

        {
          initialized ?
            (total < offset) ?
              <DataTable
                columns={columns}
                data={cancels}
                customStyles={pageTable}
                pagination
                noDataComponent={TEXT.noDataComponent}
                paginationComponentOptions={paginationComponentOptions}
                defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                defaultSortFieldId={defaultSort.by}
              />
              :
              <DataTable
                columns={columns}
                data={cancels}
                customStyles={pageTable}
                pagination
                noDataComponent={TEXT.noDataComponent}
                paginationComponentOptions={paginationComponentOptions}
                paginationServer
                paginationTotalRows={total}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                sortServer
                defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                defaultSortFieldId={defaultSort.by}
                onSort={handleSort}
              />
            : <></>
        }

      </IonContent>
    </IonPage>
  );
}

export default ProductCancel;