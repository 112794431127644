import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';

import moment from 'moment';

import TEXT from '../constances/Text.json';
import { pageTable } from './TableStyle';
import { Direction, RoutePath } from './RoutePath';

import UserService from '../services/UserService';
import { ToastStatus } from './toasts/ToastStatus';
import { copyOutline } from 'ionicons/icons';

const ProductLocation: React.FC<{data: any;}> = ({ data = [] }) => {
  
  const [members, setMembers] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => {}
  });

  const columns: any = [
    {
      name: TEXT.location,
      selector: (row: any) => 
      row.warehouse
      + '.' + row.floor
      + '.' +  row.aisle
      + '.' +  row.bay
      + '.' +  row.level 
      + '.' +  row.bin,
      cell: (row: any) => (
        <CopyToClipboard text={
          row.warehouse
          + '.' + row.floor
          + '.' +  row.aisle
          + '.' +  row.bay
          + '.' +  row.level 
          + '.' +  row.bin 
        } onCopy={() => {
          setPresentToast({
            isPresent: true, 
            status: true, 
            message: `${
              row.warehouse
              + '.' +  row.floor
              + '.' +  row.aisle
              + '.' +  row.bay
              + '.' +  row.level 
              + '.' +  row.bin 
            } ${TEXT.wasCopied}`,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        }}>
          <IonButton fill='clear'>
            {
              row.warehouse
              + '.' +  row.floor
              + '.' +  row.aisle
              + '.' +  row.bay
              + '.' +  row.level 
              + '.' +  row.bin 
            }
            <IonIcon size="small" slot="end" icon={copyOutline} />
          </IonButton>
        </CopyToClipboard>
      ),
      sortable: true,
    },
    {
      name: TEXT.qtyPiece,
      selector: (row: { quantity: any; }) => row.quantity,
      cell: (row: { quantity: any }) => (
        <NumberFormat className={
          (parseInt(row.quantity) <= 0 ? 'text-danger' : 'text-success') + ' text-number' 
        } value={row.quantity} displayType={'text'} thousandSeparator={true} />
      ),
      right: true,
      sortable: true,
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const initialize = async () => {
    const userData: any = await UserService.getSession();
    if (userData) {
      setMembers(
        userData.members.map(
          ({ group_id }: any) => (
            group_id
          )
        )
      );
    }
    setInitialized(true);
  }

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  return (
    <>

      <ToastStatus 
      isPresent={presentToast.isPresent} 
      status={presentToast.status} 
      message={presentToast.message} 
      onDismiss={presentToast.onDismiss} 
      />

      {
        data && initialized ? 
        <DataTable
          columns={columns}
          data={data.filter((item: any) => {
            return parseInt(item.quantity) > 0;
          })}
          customStyles={pageTable}
          pagination
          noDataComponent={TEXT.noDataComponent}
          paginationComponentOptions={paginationComponentOptions}
        />
      : <></>
      }

    </>
  );
};

export default ProductLocation;