import React, { useState } from 'react';
import { IonBackButton, IonRow } from '@ionic/react';
import { useHistory } from "react-router-dom";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';

import MENU from '../constances/Menu.json';
import BUTTON from '../constances/Button.json';

import {
  checkmarkCircleOutline
} from 'ionicons/icons';
import { RoutePath } from '../components/RoutePath';
import { CancelForm } from '../components/forms/CancelForm';

const ProductCancelAdd: React.FC = () => {

  const [doSubmit, setDoSubmit] = useState<boolean>(false);

  const history = useHistory();

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.productCancelAdd}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => setDoSubmit(true)}>
              <IonIcon slot="start" icon={checkmarkCircleOutline} />
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
              <CancelForm isSubmit={doSubmit} onDidSubmit={
                (data: any) => {
                  if (data) {
                    history.replace(RoutePath.jobView.replace(':id', data.job));
                  }
                  setDoSubmit(false);
                }
              } />
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default ProductCancelAdd;