import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { WithContext as ReactTags } from 'react-tag-input';
import Dropzone from 'react-dropzone';
import { ErrorMessage } from '@hookform/error-message';

import {
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonButton,
  IonCheckbox,
  IonIcon,
  IonList,
  IonListHeader,
  useIonModal,
  IonImg,
  IonThumbnail,
  IonModal,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle
} from "@ionic/react";
import {
  addCircle,
  alertCircleOutline,
  closeCircle,
  removeCircle,
  trashOutline
} from 'ionicons/icons';

import { ToastStatus } from './../toasts/ToastStatus';
import { AlertStatus } from './../alerts/AlertStatus';
import { AlertPrompt } from '../alerts/AlertPrompt';
import { GoBack } from '../RoutePath';
import { ModalBrands } from '../modal/Brands';
import { ModalManufacturers } from '../modal/Manufacturers';
import { ModalDistributors } from '../modal/Distributors';

import MESSAGE from '../../constances/Messages.json';
import MENU from '../../constances/Menu.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import ProductService from "../../services/ProductService";
import LocationService from '../../services/LocationService';
import CategoryService from '../../services/CategoryService';
import ManufacturerService from '../../services/ManufacturerService';
import DistributorService from '../../services/DistributorService';
import VendorService from '../../services/VendorService';
import UserService from '../../services/UserService';

export const ProductsForm: React.FC<{
  data?: any;
  isSubmit?: boolean;
  slide?: string;
  // onSlideChange?: (data?: any) => any;
  onDidSubmit?: (data?: any) => any;
}> = ({ data = null, slide = 0, isSubmit = false, onDidSubmit }) => {

  const defaultCountry: string = 'TH';

  const KeyCodes = {
    enter: 13
  };
  const delimiters = [
    KeyCodes.enter
  ];

  const [vendor, setVendor] = useState<any>(null);
  const [brand, setBrand] = useState<string>('');
  const [subBrand, setSubBrand] = useState<string>('');
  const [categories, setCategories] = useState<Array<any>>([]);
  const [countries, setCountries] = useState<Array<any>>([]);
  const [tags, setTags] = useState<Array<any>>([]);
  const [tagSuggestions, setTagSuggestions] = useState<Array<any>>([]);
  const [manufacturer, setManufacturer] = useState<any>(null);
  const [manufacturerProvinces, setManufacturerProvinces] = useState<Array<any>>([]);
  const [manufacturerCities, setManufacturerCities] = useState<Array<any>>([]);
  const [manufacturerSubDistricts, setManufacturerSubDistricts] = useState<Array<any>>([]);
  const [distributor, setDistributor] = useState<any>(null);
  const [distributorProvinces, setDistributorProvinces] = useState<Array<any>>([]);
  const [distributorCities, setDistributorCities] = useState<Array<any>>([]);
  const [distributorSubDistricts, setDistributorSubDistricts] = useState<Array<any>>([]);
  const [flammable, setFlammable] = useState<boolean>(false);
  const [fragile, setFragile] = useState<boolean>(false);
  const [collapsible, setCollapsible] = useState<boolean>(false);
  const [temperature_control, setTemperatureControl] = useState<boolean>(false);
  const [figure, setFigure] = useState<Array<any>>([]);
  const [figureUploaded, setFigureUploaded] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState<boolean>(false);

  const { control, register, getValues, setValue, handleSubmit, reset, formState: { errors } } = useForm();
  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: "attributes"
    }
  );
  const [formGroupProduct, setFormGroupProduct] = useState<any>(null);
  const [formGroupManufacturer, setFormGroupProductManufacturer] = useState<any>(null);
  const [formGroupDistributor, setFormGroupProductDistributor] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const [modalBrandIsOpen, setModalBrandIsOpen] = useState<boolean>(false);
  const [modalSubBrandIsOpen, setModalSubBrandIsOpen] = useState<boolean>(false);

  const slideOpts = {
    initialSlide: slide,
    autoHeight: true,
    allowTouchMove: false
  };
  const slider = useRef<HTMLIonSlidesElement>(null);

  const initialize = async () => {

    setShowLoading(true);

    resetFormValues();

    const userData: any = await UserService.getSession();
    if (userData) {
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
      }).catch(() => { });
    }

    await getCategories().then(async (categoriesData: any) => {
      setCategories(categoriesData);
    });

    // await getTagsSuggestions().then(async (suggestionsData: any) => {
    //   const suggestions = suggestionsData.map((item: any) => {
    //     return {
    //       id: item.id,
    //       text: item.name
    //     };
    //   });
    //   setTagSuggestions(suggestions);
    // });

    const searchNameEn = (attributes: any) => {
      return new Promise(resolve => {
        if (attributes) {
          let exist: any = null;
          let index = 0;
          for (let item of attributes) {
            if (item.key === 'name_en') {
              exist = item.value;
              resolve(item.value);
              attributes.splice(index, 1);
            }
            index += 1;
          }
          if (exist) {
            resolve(exist);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
    }

    if (data) {

      setFragile(data.fragile && data.fragile !== '0' ? true : false);
      setCollapsible(data.collapsible && data.collapsible !== '0' ? true : false);
      setTemperatureControl(data.temperature_control && data.temperature_control !== '0' ? true : false);
      setFlammable(data.flammable && data.flammable !== '0' ? true : false);

      if (data.tags) {
        const tags = data.tags.map((item: any) => {
          return {
            id: item,
            text: item
          };
        });
        setTags(tags);
      }

      if (data.brand_reference) {
        setBrand(data.brand_reference.name);
      }

      if (data.subbrand_reference) {
        setSubBrand(data.subbrand_reference.name);
      }

      for (let item of data.attributes) {
        if (item.key !== 'name_en') {
          append({ key: item.key, value: item.value })
        }
      }

      if (data.figure_reference) {
        setFigureUploaded(data.figure_reference);
      }

      if (data.manufacturer) {
        setManufacturer(data.manufacturer);
      }

      if (data.distributor) {
        setDistributor(data.distributor);
      }

    }

    setFormGroupProduct(
      {
        sku: register('sku', {
          value: data && data.sku ? data.sku : null,
          required: MESSAGE.error.input_required
        }),
        barcode: register('barcode', {
          value: data && data.barcode ? data.barcode : null,
          required: MESSAGE.error.input_required
        }),
        name: register('name', {
          value: data && data.name ? data.name : null,
          required: MESSAGE.error.input_required
        }),
        nameEn: register('nameEn', {
          value: await searchNameEn(data && data.attributes ? data.attributes : null)
        }),
        brand: register('brand', {
          value: data && data.brand ? data.brand : null
        }),
        subbrand: register('subbrand', {
          value: data && data.subbrand ? data.subbrand : null
        }),
        short_description: register('short_description', {
          value: data && data.short_description ? data.short_description : null
        }),
        description: register('description', {
          value: data && data.description ? data.description : null
        }),
        category: register('category', {
          value: data && data.category ? data.category : ''
        }),
        attributes: register('attributes', {
          value: data && data.attributes ? data.attributes : []
        }),
        tags: register('tags', {
          value: data && data.tags ? data.tags : []
        }),
        sales_unit: register('sales_unit', {
          value: data && data.sales_unit ? data.sales_unit : null
        }),
        piece_dimension_width: register('piece_dimension_width', {
          value: data && data.piece_dimension_width ? data.piece_dimension_width : null
        }),
        piece_dimension_depth: register('piece_dimension_depth', {
          value: data && data.piece_dimension_depth ? data.piece_dimension_depth : null
        }),
        piece_dimension_height: register('piece_dimension_height', {
          value: data && data.piece_dimension_height ? data.piece_dimension_height : null
        }),
        piece_weight: register('piece_weight', {
          value: data && data.piece_weight ? data.piece_weight : null
        }),
        box_dimension_width: register('box_dimension_width', {
          value: data && data.box_dimension_width ? data.box_dimension_width : null
        }),
        box_dimension_depth: register('box_dimension_depth', {
          value: data && data.box_dimension_depth ? data.box_dimension_depth : null
        }),
        box_dimension_height: register('box_dimension_height', {
          value: data && data.box_dimension_height ? data.box_dimension_height : null
        }),
        box_weight: register('box_weight', {
          value: data && data.box_weight ? data.box_weight : null
        }),
        carton_dimension_width: register('carton_dimension_width', {
          value: data && data.carton_dimension_width ? data.carton_dimension_width : null
        }),
        carton_dimension_depth: register('carton_dimension_depth', {
          value: data && data.carton_dimension_depth ? data.carton_dimension_depth : null
        }),
        carton_dimension_height: register('carton_dimension_height', {
          value: data && data.carton_dimension_height ? data.carton_dimension_height : null
        }),
        carton_weight: register('carton_weight', {
          value: data && data.carton_weight ? data.carton_weight : null
        }),
        piece_per_box: register('piece_per_box', {
          value: data && data.piece_per_box ? data.piece_per_box : null
        }),
        piece_per_carton: register('piece_per_carton', {
          value: data && data.piece_per_carton ? data.piece_per_carton : null
        }),
        box_per_carton: register('box_per_carton', {
          value: data && data.box_per_carton ? data.box_per_carton : null
        }),
        inspection_criteria: register('inspection_criteria', {
          value: data && data.inspection_criteria ? data.inspection_criteria : null
        }),
        fragile: register('fragile', {
          value: data && data.fragile ? data.fragile : null
        }),
        flammable: register('flammable', {
          value: data && data.flammable ? data.flammable : null
        }),
        collapsible: register('collapsible', {
          value: data && data.collapsible ? data.collapsible : null
        }),
        temperature_control: register('temperature_control', {
          value: data && data.temperature_control ? data.temperature_control : null
        }),
        temperature_min: register('temperature_min', {
          value: data && data.temperature_min ? data.temperature_min : null
        }),
        temperature_max: register('temperature_max', {
          value: data && data.temperature_max ? data.temperature_max : null
        }),
        lot_control: register('lot_control', {
          value: data && data.lot_control ? true : null
        }),
        packing_remark: register('packing_remark', {
          value: data && data.packing_remark ? data.packing_remark : null
        }),
        quantity: register('quantity', {
          value: data && data.quantity ? data.quantity : null
        }),
        distributor: register('distributor', {
          value: data && data.distributor ? data.distributor : null
        }),
        manufacturer: register('manufacturer', {
          value: data && data.manufacturer ? data.manufacturer : null
        }),
        vendor: register('vendor', {
          value: data && data.vendor ? data.vendor : null
        }),
        active: register('active', {
          value: '2'
        }),
      }
    );

    await getCountries().then(async (countriesData: any) => {
      setCountries(countriesData);
      await getProvinces(defaultCountry).then(async (provincesData: any) => {
        setManufacturerProvinces(provincesData);
        setDistributorProvinces(provincesData);
      });

      await setFormsAddress(
        'manufacturer',
        data && data.manufacturer_reference ? data.manufacturer_reference : null
      ).then((valueAddress: any) => {
        setFormGroupProductManufacturer(
          {
            manufacturer_name: register('manufacturer_name', {
              value: data && data.manufacturer_reference && data.manufacturer_reference.name
                ? data.manufacturer_reference.name : ''
            }),
            manufacturer_tax_id: register('manufacturer_tax_id', {
              value: data && data.manufacturer_reference && data.manufacturer_reference.tax_id
                ? data.manufacturer_reference.tax_id : ''
            }),
            manufacturer_address: register('manufacturer_address', {
              value: data && data.manufacturer_reference && data.manufacturer_reference.address
                ? data.manufacturer_reference.address : ''
            }),
            manufacturer_subdistrict: register('manufacturer_subdistrict', {
              value: valueAddress.subdistrict ? valueAddress.subdistrict : '',
              onChange: async (e) => {
                if (e.target.value) {
                  await getSubDistricts(
                    getValues('manufacturer_country'),
                    getValues('manufacturer_city')
                  ).then((subDistrictsData: any) => {
                    if (subDistrictsData && subDistrictsData.length) {
                      for (let subDistrict of subDistrictsData) {
                        if (subDistrict.id === e.target.value && subDistrict.zip_code) {
                          setValue('manufacturer_postcode', subDistrict.zip_code.toString());
                        }
                      }
                    }
                  });
                }
              }
            }),
            manufacturer_city: register('manufacturer_city', {
              value: valueAddress.city ? valueAddress.city : '',
              onChange: async (e) => {
                if (e.target.value) {
                  await getSubDistricts(getValues('manufacturer_country'), e.target.value).then((subDistrictsData: any) => {
                    setValue('manufacturer_subdistrict', '');
                    setValue('manufacturer_postcode', '');
                    setManufacturerSubDistricts(subDistrictsData);
                  });
                }
              }
            }),
            manufacturer_province: register('manufacturer_province', {
              value: valueAddress.province ? valueAddress.province : '',
              onChange: async (e) => {
                if (e.target.value) {
                  await getCities(getValues('manufacturer_country'), e.target.value).then((citiesData: any) => {
                    setValue('manufacturer_city', '');
                    setValue('manufacturer_subdistrict', '');
                    setValue('manufacturer_postcode', '');
                    setManufacturerCities(citiesData);
                  });
                }
              }
            }),
            manufacturer_country: register('manufacturer_country', {
              value: valueAddress.country ? valueAddress.country : defaultCountry,
              onChange: async (e) => {
                if (e.target.value) {
                  await getProvinces(e.target.value).then((provincesData: any) => {
                    setValue('manufacturer_province', '');
                    setValue('manufacturer_city', '');
                    setValue('manufacturer_subdistrict', '');
                    setValue('manufacturer_postcode', '');
                    setManufacturerProvinces(provincesData);
                  });
                }
              }
            }),
            manufacturer_postcode: register('manufacturer_postcode', {
              value: data && data.manufacturer_reference && data.manufacturer_reference.postcode
                ? data.manufacturer_reference.postcode : ''
            })
          }
        );
      }).catch(() => { });

      await setFormsAddress(
        'distributor',
        data && data.distributor_reference ? data.distributor_reference : null
      ).then((valueAddress: any) => {
        setFormGroupProductDistributor(
          {
            distributor_name: register('distributor_name', {
              value: data && data.distributor_reference && data.distributor_reference.name
                ? data.distributor_reference.name : ''
            }),
            distributor_tax_id: register('distributor_tax_id', {
              value: data && data.distributor_reference && data.distributor_reference.tax_id
                ? data.distributor_reference.tax_id : ''
            }),
            distributor_address: register('distributor_address', {
              value: data && data.distributor_reference && data.distributor_reference.address
                ? data.distributor_reference.address : ''
            }),
            distributor_subdistrict: register('distributor_subdistrict', {
              value: valueAddress.subdistrict ? valueAddress.subdistrict : '',
              onChange: async (e) => {
                if (e.target.value) {
                  await getSubDistricts(
                    getValues('distributor_country'),
                    getValues('distributor_city')
                  ).then((subDistrictsData: any) => {
                    if (subDistrictsData && subDistrictsData.length) {
                      for (let subDistrict of subDistrictsData) {
                        if (subDistrict.id === e.target.value && subDistrict.zip_code) {
                          setValue('distributor_postcode', subDistrict.zip_code.toString());
                        }
                      }
                    }
                  });
                }
              }
            }),
            distributor_city: register('distributor_city', {
              value: valueAddress.city ? valueAddress.city : '',
              onChange: async (e) => {
                if (e.target.value) {
                  await getSubDistricts(getValues('distributor_country'), e.target.value).then((subDistrictsData: any) => {
                    setValue('distributor_subdistrict', '');
                    setValue('distributor_postcode', '');
                    setDistributorSubDistricts(subDistrictsData);
                  });
                }
              }
            }),
            distributor_province: register('distributor_province', {
              value: valueAddress.province ? valueAddress.province : '',
              onChange: async (e) => {
                if (e.target.value) {
                  await getCities(getValues('distributor_country'), e.target.value).then((citiesData: any) => {
                    setValue('city', '');
                    setValue('subdistrict', '');
                    setValue('postcode', '');
                    setDistributorCities(citiesData);
                  });
                }
              }
            }),
            distributor_country: register('distributor_country', {
              value: valueAddress.country ? valueAddress.country : defaultCountry,
              onChange: async (e) => {
                if (e.target.value) {
                  await getProvinces(e.target.value).then((provincesData: any) => {
                    setValue('distributor_province', '');
                    setValue('distributor_city', '');
                    setValue('distributor_subdistrict', '');
                    setValue('distributor_postcode', '');
                    setDistributorProvinces(provincesData);
                  });
                }
              }
            }),
            distributor_postcode: register('distributor_postcode', {
              value: data && data.distributor_reference && data.distributor_reference.postcode
                ? data.distributor_reference.postcode : ''
            })
          }
        );
      }).catch(() => { });

    }).catch(() => { });

    setShowLoading(false);

  }

  const setFormsAddress = async (form: any = 'manufacturer', value: any) => {
    return new Promise(async resolve => {

      let countryValue: any;
      let provinceValue: any;
      let cityValue: any;
      let subdistrictValue: any;

      await getCountries().then(async (countriesData: any) => {

        if (value) {

          const searchCountry = (countryTarget: string) => {
            return new Promise(resolve => {
              for (let country of countriesData) {
                if (
                  country.name === countryTarget
                  || country.enName === countryTarget
                  || country.alpha2 === countryTarget
                ) {
                  resolve(country.alpha2);
                }
              }
            });
          }
          await searchCountry(value.country).then(async (countryData: any) => {
            countryValue = countryData;
            const searchProvince = (provinceTarget: string) => {
              return new Promise(resolve => {
                getProvinces(countryValue).then(async (provincesData: any) => {
                  if (form === 'manufacturer') {
                    setManufacturerProvinces(provincesData);
                  } else if (form === 'distributor') {
                    setDistributorProvinces(provincesData);
                  }
                  for (let province of provincesData) {
                    if (
                      province.name_th === provinceTarget
                      || province.name_en === provinceTarget
                    ) {
                      resolve(province.id);
                    }
                  }
                });
              });
            }
            await searchProvince(value.province).then(async (provinceData: any) => {
              provinceValue = provinceData;
              const searchCity = (cityTarget: string) => {
                return new Promise(resolve => {
                  getCities(countryValue, provinceValue).then(async (citiesData: any) => {
                    if (form === 'manufacturer') {
                      setManufacturerCities(citiesData);
                    } else if (form === 'distributor') {
                      setDistributorCities(citiesData);
                    }
                    for (let city of citiesData) {
                      if (
                        city.name_th === cityTarget
                        || city.name_en === cityTarget
                      ) {
                        resolve(city.id);
                      }
                    }
                  });
                });
              }
              await searchCity(value.city).then(async (cityData: any) => {
                cityValue = cityData;
                const searchSubDistrict = async (subdistrictTarget: string) => {
                  return new Promise(resolve => {
                    getSubDistricts(countryValue, cityValue).then(async (subDistrictsData: any) => {
                      if (form === 'manufacturer') {
                        setManufacturerSubDistricts(subDistrictsData);
                      } else if (form === 'distributor') {
                        setDistributorSubDistricts(subDistrictsData);
                      }
                      for (let subDistrict of subDistrictsData) {
                        if (
                          subDistrict.name_th === subdistrictTarget
                          || subDistrict.name_en === subdistrictTarget
                        ) {
                          resolve(subDistrict.id);
                        }
                      }
                    });
                  });
                }
                await searchSubDistrict(value.subdistrict).then((subdistrictData: any) => {
                  subdistrictValue = subdistrictData;
                });
              });
            });
          });

        }

      });

      resolve(
        {
          country: countryValue,
          province: provinceValue,
          city: cityValue,
          subdistrict: subdistrictValue
        }
      );

    });

  }

  const resetFormValues = () => {
    setTags([]);
    setFigure([]);
    setFigureUploaded([]);
    setManufacturer(null);
    setDistributor(null);
    reset();
  }

  const getCategories = async () => {
    return new Promise(resolve => {
      CategoryService.list(
        true,
        { by: 'name', direction: 'asc' }
      ).then((data: any) => {
        resolve(data);
      });
    });
  }

  const getCountries = async () => {
    return new Promise(async (resolve) => {
      LocationService.getCountries().then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getProvinces = async (countryCode: string) => {
    return new Promise(async (resolve) => {
      LocationService.getProvincesByCountry(countryCode).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getCities = async (countryCode: string, provinceID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getCitiesByProvince(
        countryCode,
        provinceID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getSubDistricts = async (countryCode: string, cityID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getSubDistrictsByCity(
        countryCode,
        cityID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const onSubmit = async (value: any) => {

    setShowLoading(true);

    if (value.vendor === null && vendor && vendor.id) {
      value.vendor = vendor.id;
    }

    if (value.lot_control) {
      value.lot_control = '1';
    } else {
      value.lot_control = null;
    }

    if (value.fragile) {
      value.fragile = '1';
    } else {
      value.fragile = null;
    }

    if (value.flammable) {
      value.flammable = '1';
    } else {
      value.flammable = null;
    }

    if (value.collapsible) {
      value.collapsible = '1';
    } else {
      value.collapsible = null;
    }

    if (value.temperature_control) {
      value.temperature_control = '1';
    } else {
      value.temperature_control = null;
    }

    value.manufacturer_postcode = value.manufacturer_postcode.toString();
    value.distributor_postcode = value.distributor_postcode.toString();

    if (!value.attributes || !value.attributes.length) {
      value.attributes = [];
    }

    const setValuesAddress = async (type: string = 'manufacturer') => {

      const searchProvince = async (id: number, provinces: Array<any>, country: string) => {
        return new Promise(resolve => {
          for (let province of provinces) {
            if (province.id === id) {
              if (country === 'TH') {
                resolve(province.name_th);
              } else {
                resolve(province.name_en);
              }
            }
          }
        });
      }
      if (type === 'manufacturer' && value.manufacturer_province) {
        value.manufacturer_province = await searchProvince(
          value.manufacturer_province,
          manufacturerProvinces,
          value.manufacturer_country
        );
      } else if (type === 'distributor' && value.distributor_province) {
        value.distributor_province = await searchProvince(
          value.distributor_province,
          distributorProvinces,
          value.distributor_country
        );
      }

      const searchCity = async (id: number, cities: Array<any>, country: string) => {
        return new Promise(resolve => {
          for (let city of cities) {
            if (city.id === id) {
              if (country === 'TH') {
                resolve(city.name_th);
              } else {
                resolve(city.name_en);
              }
            }
          }
        });
      }
      if (type === 'manufacturer' && value.manufacturer_city) {
        value.manufacturer_city = await searchCity(
          value.manufacturer_city,
          manufacturerCities,
          value.manufacturer_country
        );
      } else if (type === 'distributor' && value.distributor_city) {
        value.distributor_city = await searchCity(
          value.distributor_city,
          distributorCities,
          value.distributor_country
        );
      }

      const searchSubDistrict = async (id: number, subDistricts: Array<any>, country: string) => {
        return new Promise(resolve => {
          for (let subDistrict of subDistricts) {
            if (subDistrict.id === id) {
              if (country === 'TH') {
                resolve(subDistrict.name_th);
              } else {
                resolve(subDistrict.name_en);
              }
            }
          }
        });
      }
      if (type === 'manufacturer' && value.manufacturer_subdistrict) {
        value.manufacturer_subdistrict = await searchSubDistrict(
          value.manufacturer_subdistrict,
          manufacturerSubDistricts,
          value.manufacturer_country
        );
      } else if (type === 'distributor' && value.distributor_subdistrict) {
        value.distributor_subdistrict = await searchSubDistrict(
          value.distributor_subdistrict,
          distributorSubDistricts,
          value.distributor_country
        );
      }

      const searchCountry = async (countryCode: string, countries: Array<any>) => {
        return new Promise(resolve => {
          for (let country of countries) {
            if (country.alpha2 === countryCode) {
              if (countryCode === 'TH') {
                resolve(country.name);
              } else {
                resolve(country.enName);
              }
            }
          }
        });
      }
      if (type === 'manufacturer' && value.manufacturer_country) {
        value.manufacturer_country = await searchCountry(value.manufacturer_country, countries);
      } else if (type === 'distributor' && value.distributor_country) {
        value.distributor_country = await searchCountry(value.distributor_country, countries);
      }

    }

    await setValuesAddress('manufacturer');
    await setValuesAddress('distributor');

    const setValuesSub = async (type: string = 'manufacturer') => {
      return new Promise(async resolve => {
        let valuesSub: any = {};
        for (let key of Object.keys(value)) {
          if (key.indexOf(`${type}_`) >= 0) {
            valuesSub[await key.replace(`${type}_`, '')] = value[key];
          }
        }
        resolve(valuesSub);
      });
    }
    const manufacturerValue: any = await setValuesSub('manufacturer');
    const distributorValue: any = await setValuesSub('distributor');

    manufacturerValue.phone = '';
    manufacturerValue.email = '';
    distributorValue.phone = '';
    manufacturerValue.email = '';

    if (value.nameEn) {
      await value.attributes.push(
        {
          key: 'name_en',
          value: value.nameEn
        }
      );
      delete value.nameEn;
    }

    const onSuccess = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    const onError = (message: any) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: message,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit();
      }
    }

    const upload = async (id: string, files: any) => {
      for (let file of files) {
        await ProductService.upload(id, { 'figure[]': file }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      }
    }

    if (data) {
      await ProductService.update(data.id, value).then(async (result: any) => {
        if (!value.manufacturer && manufacturerValue.name) {
          await ManufacturerService.create(manufacturerValue).then(async (resultManufacturer: any) => {
            await ProductService.patch(data.id, { manufacturer: resultManufacturer.id }).catch((error) => {
              onError(error);
            });
          }).catch((error) => {
            onError(error);
          });
        }
        if (!value.distributor && distributorValue.name) {
          await DistributorService.create(distributorValue).then(async (resultDistributor: any) => {
            await ProductService.patch(data.id, { distributor: resultDistributor.id }).catch((error) => {
              onError(error);
            });
          }).catch((error) => {
            onError(error);
          });
        }
        if (figure.length) {
          await upload(result.id, figure);
        }
        onSuccess(result);
      }).catch((error) => {
        onError(error);
      });
    } else {
      await ProductService.create(value).then(async (result: any) => {
        if (!value.manufacturer && manufacturerValue.name) {
          await ManufacturerService.create(manufacturerValue).then(async (resultManufacturer: any) => {
            await ProductService.patch(data.id, { manufacturer: resultManufacturer.id }).catch((error) => {
              onError(error);
            });
          }).catch((error) => {
            onError(error);
          });
        }
        if (!value.distributor && distributorValue.name) {
          await DistributorService.create(distributorValue).then(async (resultDistributor: any) => {
            await ProductService.patch(data.id, { distributor: resultDistributor.id }).catch((error) => {
              onError(error);
            });
          }).catch((error) => {
            onError(error);
          });
        }
        if (figure.length) {
          await upload(result.id, figure);
        }
        onSuccess(result);
        resetFormValues();
      }).catch((error) => {
        onError(error);
      });
    }

    setShowLoading(false);

  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
    if (onDidSubmit) {
      onDidSubmit();
    }
  }

  const onFigureDrop = async (files: any) => {
    const formatted = files.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
    setFigure([...figure, ...formatted]);
  }

  const onFigureRemove = (i: number) => {
    const figureRemoved = figure.filter((tag, index) => index !== i);
    setFigure(figureRemoved);
  };

  const removeFigure = async (i: number) => {
    if (data) {
      await ProductService.removeFile(data.id, { figure: figureUploaded[i].id }).then(async (result: any) => {
        const figureRemoved = figureUploaded.filter((tag, index) => index !== i);
        setFigureUploaded(figureRemoved);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  const removeManufacturer = () => {
    setManufacturer(null);
    setManufacturerCities([]);
    setManufacturerProvinces([]);
    setManufacturerSubDistricts([]);
    setValue('manufacturer', '');
    setValue('manufacturer_name', '');
    setValue('manufacturer_tax_id', '');
    setValue('manufacturer_address', '');
    setValue('manufacturer_country', defaultCountry);
    setValue('manufacturer_province', '');
    setValue('manufacturer_city', '');
    setValue('manufacturer_subdistrict', '');
    setValue('manufacturer_postcode', '');
  }

  const removeDistributor = () => {
    setDistributor(null);
    setDistributorCities([]);
    setDistributorProvinces([]);
    setDistributorSubDistricts([]);
    setValue('distributor', '');
    setValue('distributor_name', '');
    setValue('distributor_tax_id', '');
    setValue('distributor_address', '');
    setValue('distributor_country', defaultCountry);
    setValue('distributor_province', '');
    setValue('distributor_city', '');
    setValue('distributor_subdistrict', '');
    setValue('distributor_postcode', '');
  }

  const removeProduct = async (id: any, name: string) => {

    const success = () => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.remove_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      GoBack();
    }

    setPresentAlertPrompt({
      isPresent: true,
      message: `${TEXT.removePrompt} "${name}"?`,
      onDismiss: (data: boolean) => {
        if (ready) {
          setPresentAlertPrompt(
            {
              isPresent: false,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          if (data) {
            ProductService.patch(id, { active: false }).then(async () => {
              success();
            }).catch((error) => {
              setPresentToast({
                isPresent: true,
                status: false,
                message: error,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            });
          }
        }
      }
    });

  }

  // const handleSlideChange = async (event: any) => {
  //   let index: number = 0;
  //   await event.target.getActiveIndex().then((value: any) => (index = value));
  //   onSlideChange && onSlideChange(index);
  // }

  const handleDelete = (i: number) => {
    const data = tags.filter((tag, index) => index !== i);
    setTags(data);
    setValue('tags', data.map((item: any) => {
      return item.id;
    }));
  };

  const handleAddition = (tag: any) => {
    const data = [...tags, tag];
    setTags([...tags, tag]);
    setValue('tags', data.map((item: any) => {
      return item.id;
    }));
  };

  const [presentModalManufacturers, dismissModalManufacturers] = useIonModal(
    ModalManufacturers, {
    options: {
      canDismiss: true,
      swipeToClose: true
    },
    selected: getValues('manufacturer'),
    onDismiss: async (manufacturer: any) => {
      if (manufacturer) {
        setManufacturer(manufacturer);
        setValue('manufacturer', manufacturer.id);
        setValue('manufacturer_name', manufacturer.name);
        setValue('manufacturer_tax_id', manufacturer.tax_id);
        setValue('manufacturer_address', manufacturer.address);
        setAddressLoading(true);
        setFormsAddress('manufacturer', manufacturer).then((valueAddress: any) => {
          setValue('manufacturer_country', valueAddress.country);
          setValue('manufacturer_province', valueAddress.province);
          setValue('manufacturer_city', valueAddress.city);
          setValue('manufacturer_subdistrict', valueAddress.subdistrict);
          setValue('manufacturer_postcode', manufacturer.postcode);
          setAddressLoading(false);
        });
      } else if (manufacturer === false) {
        setManufacturer(null);
        setValue('manufacturer', '');
        setValue('manufacturer_name', '');
        setValue('manufacturer_tax_id', '');
        setValue('manufacturer_address', '');
        setValue('manufacturer_country', defaultCountry);
        setValue('manufacturer_province', '');
        setValue('manufacturer_city', '');
        setValue('manufacturer_subdistrict', '');
        setValue('manufacturer_postcode', '');
      }
      dismissModalManufacturers();
    }
  }
  );

  const [presentModalDistributors, dismissModalDistributors] = useIonModal(
    ModalDistributors, {
    options: {
      canDismiss: true,
      swipeToClose: true
    },
    selected: getValues('distributor'),
    onDismiss: async (distributor: any) => {
      if (distributor) {
        setDistributor(distributor);
        setValue('distributor', distributor.id);
        setValue('distributor_name', distributor.name);
        setValue('distributor_tax_id', distributor.tax_id);
        setValue('distributor_address', distributor.address);
        setAddressLoading(true);
        setFormsAddress('distributor', distributor).then((valueAddress: any) => {
          setValue('distributor_country', valueAddress.country);
          setValue('distributor_province', valueAddress.province);
          setValue('distributor_city', valueAddress.city);
          setValue('distributor_subdistrict', valueAddress.subdistrict);
          setValue('distributor_postcode', distributor.postcode);
          setAddressLoading(false);
        });
      } else if (distributor === false) {
        setDistributor(null);
        setValue('distributor', '');
        setValue('distributor_name', '');
        setValue('distributor_tax_id', '');
        setValue('distributor_address', '');
        setValue('distributor_country', defaultCountry);
        setValue('distributor_province', '');
        setValue('distributor_city', '');
        setValue('distributor_subdistrict', '');
        setValue('distributor_postcode', '');
      }
      dismissModalDistributors();
    }
  }
  );

  useEffect(() => {
    setReady(true);
    return () => {
      figure.forEach((file: any) => URL.revokeObjectURL(file.preview));
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
    // window.onresize = () => {
    //   slider && slider.current! && slider.current!.update();
    // }
    return () => {
      window.removeEventListener("resize", () => { });
    }
  }, [data]);

  // useEffect(() => {
  //   slider && slider.current! && slider.current!.slideTo(slide);
  // }, [slide]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit, onError)();
    }
  }, [isSubmit]);

  return (
    <>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <AlertPrompt
        isPresent={presentAlertPrompt.isPresent}
        message={presentAlertPrompt.message}
        onDismiss={presentAlertPrompt.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonModal canDismiss swipeToClose isOpen={modalBrandIsOpen} onDidDismiss={() => setModalBrandIsOpen(false)}>
        <ModalBrands
          title={TEXT.brand}
          selected={getValues('brand')}
          onDismiss={(brand: any) => {
            if (brand) {
              setValue('brand', brand.id);
              setBrand(brand.name);
            } else if (brand === false) {
              setValue('brand', '');
              setBrand('');
            }
            setModalBrandIsOpen(false);
          }}
        />
      </IonModal>

      <IonModal canDismiss swipeToClose isOpen={modalSubBrandIsOpen} onDidDismiss={() => setModalSubBrandIsOpen(false)}>
        <ModalBrands
          title={'Sub-brand'}
          selected={getValues('subbrand')}
          onDismiss={(brand: any) => {
            if (brand) {
              setValue('subbrand', brand.id);
              setSubBrand(brand.name);
            } else if (brand === false) {
              setValue('subbrand', '');
              setSubBrand('');
            }
            setModalSubBrandIsOpen(false);
          }}
        />
      </IonModal>

      <form onSubmit={handleSubmit(onSubmit, onError)}>

        {/* 1 */}
        {
          slide && slide === 'general' ?
            <IonGrid>
              <IonRow>
                <IonCol size='12' sizeMd='12' sizeLg='12' sizeXl='10' offset='0' offsetMd='0' offsetLg='0' offsetXl='1'>
                  {
                    formGroupProduct ?
                      <IonCard>

                        <IonCardHeader>
                          <IonCardTitle>{MENU.productView}</IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonList>
                            {/* ข้อมูลสินค้า */}
                            <IonRow>
                              <IonCol size='12'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productNameTH}<span className='form-required'>*</span></IonLabel>
                                  <IonInput type="text" {...formGroupProduct.name}></IonInput>
                                </IonItem>
                                <ErrorMessage errors={errors} name="name"
                                  render={({ message }) =>
                                    <div className='form-help'>
                                      <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                      {message ? message : ''}
                                    </div>
                                  } />
                              </IonCol>

                              <IonCol size='12'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productNameEN}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.nameEn}></IonInput>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productSku}<span className='form-required'>*</span></IonLabel>
                                  <IonInput type="text" {...formGroupProduct.sku}></IonInput>
                                </IonItem>
                                <ErrorMessage errors={errors} name="sku"
                                  render={({ message }) =>
                                    <div className='form-help'>
                                      <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                      {message ? message : ''}
                                    </div>
                                  } />
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.barcode}<span className='form-required'>*</span></IonLabel>
                                  <IonInput type="text" {...formGroupProduct.barcode}></IonInput>
                                </IonItem>
                                <ErrorMessage errors={errors} name="barcode"
                                  render={({ message }) =>
                                    <div className='form-help'>
                                      <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                      {message ? message : ''}
                                    </div>
                                  } />
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol size='12' sizeMd='4'>
                                <IonItem button detail={false} onClick={() => setModalBrandIsOpen(true)}>
                                  <IonLabel position="stacked">{TEXT.brand}</IonLabel>
                                  <IonInput type="text" name="productBrand" value={brand} required></IonInput>
                                  <input type="hidden" {...formGroupProduct.brand} />
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='4'>
                                <IonItem button detail={false} onClick={() => setModalSubBrandIsOpen(true)}>
                                  <IonLabel position="stacked">{TEXT.brandSub}</IonLabel>
                                  <IonInput type="text" name="productSubBrand" value={subBrand} required></IonInput>
                                  <input type="hidden" {...formGroupProduct.subbrand} />
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='4'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.category}</IonLabel>
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    name="category"
                                    value={getValues('category')}
                                    {...formGroupProduct.category}
                                    disabled={!categories.length}
                                  >
                                    <IonSelectOption key="category-0" value="">-</IonSelectOption>
                                    {categories.map((item, index: number) => (
                                      <IonSelectOption key={`category-${index}`} value={item.id}>{item.name}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productDetailShort}</IonLabel>
                                  <IonTextarea autoGrow {...formGroupProduct.short_description}></IonTextarea>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productDetail}</IonLabel>
                                  <IonTextarea autoGrow {...formGroupProduct.description}></IonTextarea>
                                </IonItem>
                              </IonCol>
                            </IonRow>

                            <IonRow className='ion-margin-top'>
                              <IonCol size='12'>
                                <IonListHeader>
                                  <h2>{TEXT.image}</h2>
                                </IonListHeader>
                                <Dropzone accept={{ 'image/*': [] }} onDrop={(acceptedFiles: any) => onFigureDrop(acceptedFiles)}>
                                  {({ getRootProps, getInputProps }) => (
                                    <section className="drop-zone">
                                      <div {...getRootProps()} className='drop-label'>
                                        <input {...getInputProps()} />
                                        {
                                          (!figure.length && !figureUploaded.length) &&
                                          <p>{BUTTON.dropHere}</p>
                                        }
                                      </div>
                                      <aside className='preview'>
                                        {
                                          figureUploaded.map((file: any, index: number) => (
                                            <div className='drop-image' key={`figure-uploaded-${index}`}>
                                              <IonThumbnail>
                                                <IonImg src={file.original} />
                                              </IonThumbnail>
                                              <IonButton type="button" fill='clear'
                                                className='button-compact'
                                                onClick={() => removeFigure(index)}>
                                                <IonIcon slot='icon-only' icon={closeCircle} />
                                              </IonButton>
                                            </div>
                                          ))
                                        }
                                        {
                                          figure.map((file: any, index: number) => (
                                            <div className='drop-image' key={`figure-preview-${index}`}>
                                              <IonThumbnail>
                                                <IonImg src={file.preview} />
                                              </IonThumbnail>
                                              <IonButton type="button" fill='clear'
                                                className='button-compact'
                                                onClick={() => onFigureRemove(index)}>
                                                <IonIcon slot='icon-only' icon={closeCircle} />
                                              </IonButton>
                                            </div>
                                          ))
                                        }
                                      </aside>
                                    </section>
                                  )}
                                </Dropzone>
                              </IonCol>
                            </IonRow>

                          </IonList>
                        </IonCardContent>

                      </IonCard>
                      :
                      <></>
                  }

                  {
                    formGroupManufacturer ?
                      <IonCard>

                        <IonCardHeader>
                          <IonCardTitle>
                            <IonItem lines='none' className='item-no-padding-all'>
                              {TEXT.manufacturerInfo}
                              {
                                manufacturer ?
                                  <IonButton type="button" fill='clear'
                                    className='button-compact'
                                    onClick={() => removeManufacturer()}>
                                    <IonIcon slot='icon-only' icon={removeCircle} />
                                  </IonButton>
                                  :
                                  <IonButton type="button" fill='clear'
                                    className='button-compact'
                                    onClick={() => presentModalManufacturers()}>
                                    <IonIcon slot='icon-only' icon={addCircle} />
                                  </IonButton>
                              }
                            </IonItem>
                          </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonList>
                            {/* ข้อมูลผู้ผลิต */}
                            <IonRow>

                              <input type="hidden" {...formGroupProduct.manufacturer} />

                              <IonCol size='12' sizeMd='6'>
                                {/* ชื่อ */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.manufacturerName}</IonLabel>
                                  <IonInput type="text" {...formGroupManufacturer.manufacturer_name}
                                    disabled={getValues('manufacturer') ? true : false}></IonInput>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* เลขประจำตัวผู้เสียภาษี */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.manufacturerTaxID}</IonLabel>
                                  <IonInput type="text" {...formGroupManufacturer.manufacturer_tax_id}
                                    disabled={getValues('manufacturer') ? true : false}></IonInput>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* ที่อยู่ */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.manufacturerAddress}</IonLabel>
                                  <IonInput type="text" {...formGroupManufacturer.manufacturer_address}
                                    disabled={getValues('manufacturer') ? true : false}></IonInput>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* ประเทศ */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.country}<span className='form-required'>*</span></IonLabel>
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    placeholder="เลือกประเทศ"
                                    value={getValues('manufacturer_country')} disabled
                                    onIonChange={(e: any) => { !addressLoading && formGroupManufacturer.manufacturer_country.onChange(e) }}
                                    {...formGroupManufacturer.manufacturer_country}
                                  >
                                    {countries.map((item: any, index: number) => (
                                      <IonSelectOption key={`manufacturer-country-${index}`} value={item.alpha2}>{item.name}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* จังหวัด */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.province}</IonLabel>
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    name="manufacturerProvince" placeholder="เลือกจังหวัด"
                                    value={getValues('manufacturer_province')}
                                    {...formGroupManufacturer.manufacturer_province}
                                    disabled={!manufacturerProvinces.length || (getValues('manufacturer') ? true : false)}
                                    onIonChange={(e: any) => { !addressLoading && formGroupManufacturer.manufacturer_province.onChange(e) }}
                                  >
                                    {manufacturerProvinces.map((item, index: number) => (
                                      <IonSelectOption key={`manufacturer-province-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* อำเภอ/เขต */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.city}</IonLabel>
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    placeholder="เลือกเขต/อำเภอ"
                                    value={getValues('manufacturer_city')}
                                    {...formGroupManufacturer.manufacturer_city}
                                    disabled={!manufacturerCities.length || (getValues('manufacturer') ? true : false)}
                                    onIonChange={(e: any) => { !addressLoading && formGroupManufacturer.manufacturer_city.onChange(e) }}
                                  >
                                    {manufacturerCities.map((item: any, index: number) => (
                                      <IonSelectOption key={`manufacturer-city-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* ตำบล/แขวง */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.subDistrict}</IonLabel>
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    placeholder="เลือกแขวง/ตำบล"
                                    value={getValues('manufacturer_subdistrict')}
                                    {...formGroupManufacturer.manufacturer_subdistrict}
                                    disabled={!manufacturerSubDistricts.length || (getValues('manufacturer') ? true : false)}
                                    onIonChange={(e: any) => { !addressLoading && formGroupManufacturer.manufacturer_subdistrict.onChange(e) }}
                                  >
                                    {manufacturerSubDistricts.map((item: any, index: number) => (
                                      <IonSelectOption key={`manufacturer-subdistrict-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* รหัสไปรษณีย์ */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.postcode}</IonLabel>
                                  <IonInput type="text" {...formGroupManufacturer.manufacturer_postcode}
                                    disabled={getValues('manufacturer') ? true : false}></IonInput>
                                </IonItem>
                              </IonCol>

                            </IonRow>
                          </IonList>
                        </IonCardContent>

                      </IonCard>
                      :
                      <></>
                  }

                  {
                    formGroupDistributor ?
                      <IonCard>

                        <IonCardHeader>
                          <IonCardTitle>
                            <IonItem lines='none' className='item-no-padding-all'>
                              {TEXT.distributorInfo}
                              {
                                distributor ?
                                  <IonButton type="button" fill='clear'
                                    className='button-compact'
                                    onClick={() => removeDistributor()}>
                                    <IonIcon slot='icon-only' icon={removeCircle} />
                                  </IonButton>
                                  :
                                  <IonButton type="button" fill='clear'
                                    className='button-compact'
                                    onClick={() => presentModalDistributors()}>
                                    <IonIcon slot='icon-only' icon={addCircle} />
                                  </IonButton>
                              }
                            </IonItem>
                          </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonList>
                            {/* ข้อมูลผู้จัดจำหน่าย */}
                            <IonRow>

                              <input type="hidden" {...formGroupProduct.distributor} />

                              <IonCol size='12' sizeMd='6'>
                                {/* ชื่อ */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.distributorName}</IonLabel>
                                  <IonInput type="text" {...formGroupDistributor.distributor_name}
                                    disabled={getValues('distributor') ? true : false}></IonInput>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* เลขประจำตัวผู้เสียภาษี */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.distributorTaxID}</IonLabel>
                                  <IonInput type="text" {...formGroupDistributor.distributor_tax_id}
                                    disabled={getValues('distributor') ? true : false}></IonInput>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* ที่อยู่ */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.distributorAddress}</IonLabel>
                                  <IonInput type="text" {...formGroupDistributor.distributor_address}
                                    disabled={getValues('distributor') ? true : false}></IonInput>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* ประเทศ */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.country}<span className='form-required'>*</span></IonLabel>
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    placeholder="เลือกประเทศ"
                                    value={getValues('distributor_country')} disabled
                                    onIonChange={(e: any) => { !addressLoading && formGroupDistributor.distributor_country.onChange(e) }}
                                    {...formGroupDistributor.distributor_country}
                                  >
                                    {countries.map((item: any, index: number) => (
                                      <IonSelectOption key={`distributor-country-${index}`} value={item.alpha2}>{item.name}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* จังหวัด */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.province}</IonLabel>
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    name="distributorProvince" placeholder="เลือกจังหวัด"
                                    {...formGroupDistributor.distributor_province}
                                    disabled={!distributorProvinces.length || (getValues('distributor') ? true : false)}
                                    onIonChange={(e: any) => { !addressLoading && formGroupDistributor.distributor_province.onChange(e) }}
                                  >
                                    {distributorProvinces.map((item, index: number) => (
                                      <IonSelectOption key={`distributor-province-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* อำเภอ/เขต */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.city}</IonLabel>
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    placeholder="เลือกเขต/อำเภอ"
                                    {...formGroupDistributor.distributor_city}
                                    disabled={!distributorCities.length || (getValues('distributor') ? true : false)}
                                    onIonChange={(e: any) => { !addressLoading && formGroupDistributor.distributor_city.onChange(e) }}
                                  >
                                    {distributorCities.map((item: any, index: number) => (
                                      <IonSelectOption key={`distributor-city-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* ตำบล/แขวง */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.subDistrict}</IonLabel>
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    placeholder="เลือกแขวง/ตำบล"
                                    {...formGroupDistributor.distributor_subdistrict}
                                    disabled={!distributorSubDistricts.length || (getValues('distributor') ? true : false)}
                                    onIonChange={(e: any) => { !addressLoading && formGroupDistributor.distributor_subdistrict.onChange(e) }}
                                  >
                                    {distributorSubDistricts.map((item: any, index: number) => (
                                      <IonSelectOption key={`distributor-subdistrict-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                </IonItem>
                              </IonCol>

                              <IonCol size='12' sizeMd='6'>
                                {/* รหัสไปรษณีย์ */}
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.postcode}</IonLabel>
                                  <IonInput type="text" {...formGroupDistributor.distributor_postcode}
                                    disabled={getValues('distributor') ? true : false}></IonInput>
                                </IonItem>
                              </IonCol>

                            </IonRow>

                          </IonList>
                        </IonCardContent>

                      </IonCard>
                      :
                      <></>
                  }
                </IonCol>
              </IonRow>

              {
                data ?
                  <IonRow className='ion-margin-top'>
                    <IonCol size='12' className='ion-text-center'>
                      <IonButton type="button" color='danger' size='default' onClick={() => { removeProduct(data.id, data.name); }}>
                        <IonIcon slot="start" icon={trashOutline} />
                        {TEXT.removeProduct}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  : <></>
              }

              <IonRow>
                <IonCol>
                  <div className='ion-margin-top'></div>
                </IonCol>
              </IonRow>

            </IonGrid>
            : <></>
        }

        {/* 2 */}
        {
          slide && slide === 'attribute' ?
            <IonGrid>
              <IonRow>
                <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
                  {
                    formGroupProduct ?
                      <IonCard>

                        <IonCardHeader>
                          <IonCardTitle>
                            {TEXT.appearance}
                          </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonList key="apperance" className='ion-text-left'>
                            <IonRow>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem lines="none">
                                  <IonLabel className="text-full">{TEXT.productTag} </IonLabel>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='9'>
                                <IonItem>
                                  <ReactTags
                                    tags={tags}
                                    placeholder=""
                                    suggestions={tagSuggestions}
                                    delimiters={delimiters}
                                    handleDelete={handleDelete}
                                    handleAddition={handleAddition}
                                    inputFieldPosition="bottom"
                                    autocomplete
                                  />
                                </IonItem>
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem lines="none">
                                  <IonLabel className="text-full">{TEXT.productAttribute}</IonLabel>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='9'>
                                {fields.map((field, index) => (
                                  <IonRow key={`attribute-${field.id}`}>
                                    <IonCol size='12' sizeMd='4'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.attributeKey}</IonLabel>
                                        <IonInput type="text" {...register(`attributes.${index}.key`)} ></IonInput>
                                      </IonItem>
                                    </IonCol>
                                    <IonCol size='12' sizeMd='8'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.attributeValue}</IonLabel>
                                        <IonInput type="text" {...register(`attributes.${index}.value`)} ></IonInput>
                                        <IonButton type="button" fill='clear'
                                          className='button-compact' slot="end"
                                          onClick={() => remove(index)}>
                                          <IonIcon slot='icon-only' icon={removeCircle} />
                                        </IonButton>
                                      </IonItem>
                                    </IonCol>
                                  </IonRow>
                                ))}
                                <IonButton type="button" fill='clear'
                                  className='button-compact'
                                  onClick={() => append({ key: "", value: "" })}>
                                  <IonIcon slot='icon-only' icon={addCircle} />
                                </IonButton>
                              </IonCol>

                            </IonRow>

                            {/* Size Piece */}
                            <IonRow className='ion-margin-top'>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem lines="none">
                                  <IonLabel className="text-full">{TEXT.productSizePiece}</IonLabel>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.width}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.piece_dimension_width}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.depth}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.piece_dimension_depth}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.height}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.piece_dimension_height}></IonInput>
                                </IonItem>
                              </IonCol>
                            </IonRow>

                            {/* Size Box */}
                            <IonRow>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem lines="none">
                                  <IonLabel className="text-full">{TEXT.productSizeBox} </IonLabel>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.width}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.box_dimension_width}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.depth}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.box_dimension_depth}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.height}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.box_dimension_height}></IonInput>
                                </IonItem>
                              </IonCol>
                            </IonRow>

                            {/* Size Carton */}
                            <IonRow>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem lines="none">
                                  <IonLabel className="text-full">{TEXT.productSizeCarton}</IonLabel>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.width}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.carton_dimension_width}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.depth}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.carton_dimension_depth}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.height}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.carton_dimension_height}></IonInput>
                                </IonItem>
                              </IonCol>
                            </IonRow>

                            {/* Contain Per... */}
                            <IonRow>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem lines="none">
                                  <IonLabel className="text-full">{TEXT.productContain}</IonLabel>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productPiecePerBox}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.piece_per_box}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productPiecePerCarton}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.piece_per_carton}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productBoxPerCarton}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.box_per_carton}></IonInput>
                                </IonItem>
                              </IonCol>
                            </IonRow>

                            {/* Weight */}
                            <IonRow>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem lines="none">
                                  <IonLabel className="text-full">{TEXT.productWeight}</IonLabel>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productWeightPiece}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.piece_weight}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productWeightBox}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.box_weight}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productWeightCarton}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.carton_weight}></IonInput>
                                </IonItem>
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol size='12' sizeMd='3'>
                                <IonItem lines="none">
                                  <IonLabel className="text-full">{TEXT.lot}</IonLabel>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='9'>
                                <IonItem lines='none'>
                                  <IonLabel>{TEXT.controlLot}</IonLabel>
                                  <IonCheckbox slot="start"
                                    checked={getValues('lot_control')}
                                    onIonChange={e => setValue('lot_control', e.detail.checked)}
                                    {...formGroupProduct.lot_control}
                                  />
                                </IonItem>
                              </IonCol>
                            </IonRow>

                          </IonList>
                        </IonCardContent>

                      </IonCard>
                      :
                      <></>
                  }
                </IonCol>
              </IonRow>
            </IonGrid>
            : <></>
        }

        {/* 3 */}
        {
          slide && slide === 'warning' ?
            <IonGrid>
              <IonRow>
                <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
                  {
                    formGroupProduct ?
                      <IonCard>

                        <IonCardHeader>
                          <IonCardTitle>
                            {TEXT.warning}
                          </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonList key="warning" className='ion-text-left w-100'>
                            {/* Warning */}
                            <IonRow>
                              <IonCol size='12' sizeMd='4' className='ion-float-left'>
                                <IonItem lines="none">
                                  <IonLabel>{TEXT.isWarningFire}</IonLabel>
                                  <IonCheckbox slot="start"
                                    checked={getValues('flammable')}
                                    onIonChange={e => setValue('flammable', e.detail.checked)} />
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='4' className='ion-float-left'>
                                <IonItem lines="none">
                                  <IonLabel>{TEXT.isWarningFold}</IonLabel>
                                  <IonCheckbox slot="start"
                                    checked={getValues('collapsible')}
                                    onIonChange={e => setValue('collapsible', e.detail.checked)} />
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='4' className='ion-float-left'>
                                <IonItem lines="none">
                                  <IonLabel>{TEXT.isWarningFragile}</IonLabel>
                                  <IonCheckbox slot="start"
                                    checked={getValues('fragile')}
                                    onIonChange={e => setValue('fragile', e.detail.checked)} />
                                </IonItem>
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol size='12' sizeMd='4'>
                                <IonItem lines="none">
                                  <IonLabel>{TEXT.isWarningTemp}</IonLabel>
                                  <IonCheckbox slot="start"
                                    checked={getValues('temperature_control')}
                                    onIonChange={e => setValue('temperature_control', e.detail.checked)} />
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='4'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.tempLower}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.temperature_min}></IonInput>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12' sizeMd='4'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.tempHigher}</IonLabel>
                                  <IonInput type="text" {...formGroupProduct.temperature_max}></IonInput>
                                </IonItem>
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol size='12'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productNoticeCheck}</IonLabel>
                                  <IonTextarea autoGrow {...formGroupProduct.inspection_criteria}></IonTextarea>
                                </IonItem>
                              </IonCol>
                              <IonCol size='12'>
                                <IonItem>
                                  <IonLabel position="stacked">{TEXT.productNoticePack}</IonLabel>
                                  <IonTextarea autoGrow {...formGroupProduct.packing_remark}></IonTextarea>
                                </IonItem>
                              </IonCol>
                            </IonRow>
                          </IonList>
                        </IonCardContent>

                      </IonCard>
                      :
                      <></>
                  }
                </IonCol>
              </IonRow>
            </IonGrid>
            : <></>
        }
      </form>

    </>
  );

};