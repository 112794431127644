import '../../css/Modal.scss';

import { useEffect, useRef, useState } from "react";
import { useForm } from 'react-hook-form';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonItem,
  IonInput,
  IonFooter,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonList,
  IonModal,
} from "@ionic/react";
import {
  barcodeOutline,
  closeOutline,
  qrCodeOutline
} from "ionicons/icons";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';
import WarehouseService from '../../services/WarehouseService';
import { ModalLocationScanner } from './LocationScanner';

export const ModalLocation: React.FC<{
  reference: any;
  currentLocationCode?: any;
  lockWarehouse?: boolean;
  onDismiss: (location?: any, reference?: any) => any;
}> = ({
  reference = null,
  currentLocationCode = null,
  lockWarehouse = false,
  onDismiss
}) => {

    const barcodeInputRef = useRef<any>(null);

    const [currentInput, setCurrentInput] = useState<string>('');

    const [warehouses, setWarehouses] = useState<Array<any>>([]);

    const [initialized, setInitialized] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState(false);
    const [modalScannerData, setModalScannerData] = useState<any>(null);

    const { register, getValues, setValue } = useForm();
    const [warehouse] = useState<any>(
      register('warehouse', {
        value: ''
      })
    );
    const [floor] = useState<any>(
      register('floor', {
        value: ''
      })
    );
    const [aisle] = useState<any>(
      register('aisle', {
        value: ''
      })
    );
    const [bay] = useState<any>(
      register('bay', {
        value: ''
      })
    );
    const [level] = useState<any>(
      register('level', {
        value: ''
      })
    );
    const [bin] = useState<any>(
      register('bin', {
        value: ''
      })
    );

    const [presentToast, setPresentToast] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });

    const set = async (text: string = '', product: any = null) => {
      const submit = (product: any, productLocation: any) => {
        onDismiss && onDismiss({
          warehouse: productLocation.warehouse,
          floor: productLocation.floor,
          aisle: productLocation.aisle,
          bay: productLocation.bay,
          level: productLocation.level,
          bin: productLocation.bin
        }, product);
      }
      if (!text) {
        if (barcodeInputRef) {
          text = barcodeInputRef.current.value;
        }
      }
      if (text) {
        const locationCodeParts: any = text.split('.');
        await submit(product, {
          warehouse: locationCodeParts[0] ? locationCodeParts[0] : '',
          floor: locationCodeParts[1] ? locationCodeParts[1] : '',
          aisle: locationCodeParts[2] ? locationCodeParts[2] : '',
          bay: locationCodeParts[3] ? locationCodeParts[3] : '',
          level: locationCodeParts[4] ? locationCodeParts[4] : '',
          bin: locationCodeParts[5] ? locationCodeParts[5] : ''
        });
      }
      if (barcodeInputRef) {
        barcodeInputRef.current.value = '';
        barcodeInputRef.current.setFocus();
      }
    }

    const updateValue = async (
      warehouse: any = '',
      floor: any = '',
      aisle: any = '',
      bay: any = '',
      level: any = '',
      bin: any = ''
    ) => {
      let text: string = '';
      if (warehouse) {
        text += warehouse;
      }
      if (floor) {
        text += '.' + floor;
      }
      if (aisle) {
        text += '.' + aisle;
      }
      if (bay) {
        text += '.' + bay;
      }
      if (level) {
        text += '.' + level;
      }
      if (bin) {
        text += '.' + bin;
      }
      if (barcodeInputRef) {
        barcodeInputRef.current.value = text;
      }
    }

    const updateForm = (text: string = '') => {
      if (barcodeInputRef) {
        text = barcodeInputRef.current.value;
      }
      if (text) {
        const locationCodeParts: any = text.split('.');
        setValue('warehouse', locationCodeParts[0] ? locationCodeParts[0] : '');
        setValue('floor', locationCodeParts[1] ? locationCodeParts[1] : '');
        setValue('aisle', locationCodeParts[2] ? locationCodeParts[2] : '');
        setValue('bay', locationCodeParts[3] ? locationCodeParts[3] : '');
        setValue('level', locationCodeParts[4] ? locationCodeParts[4] : '');
        setValue('bin', locationCodeParts[5] ? locationCodeParts[5] : '');
      }
    }

    const subscribeInput = () => {
      const chatsObserver = new MutationObserver(async (mutations) => {
        mutations.forEach(async (mutation) => {
          if (mutation.addedNodes && mutation.addedNodes.length) {
            const nodeList: NodeList = mutation.addedNodes;
            nodeList.forEach(async (node: Node) => {
              const element = node as HTMLElement;
              if (element) {
                if (element.classList) {
                  const classList: DOMTokenList = element.classList;
                  if (classList.contains('line')) {
                  }
                }
              }
            });
          }
        });
      });
    }

    useEffect(() => {
      subscribeInput();
      initialize();
      setTimeout(() => {
        if (barcodeInputRef) {
          barcodeInputRef.current.setFocus();
        }
      }, 1000);
    }, []);

    const initialize = async () => {
      setInitialized(false);
      setShowLoading(true);
      await loadWarehouses();
      setInitialized(true);
      setShowLoading(false);
    }

    const loadWarehouses = async () => {
      await WarehouseService.list(
        true,
        { by: 'name', direction: 'asc' }
      ).then((data: any) => {
        setWarehouses(data);
      });
    }

    useEffect(() => {
      if (currentLocationCode && currentLocationCode !== true) {
        const locationCodeParts: any = currentLocationCode.split('.');
        setValue('warehouse', locationCodeParts[0] ? locationCodeParts[0] : '');
        setValue('floor', locationCodeParts[1] ? locationCodeParts[1] : '');
        setValue('aisle', locationCodeParts[2] ? locationCodeParts[2] : '');
        setValue('bay', locationCodeParts[3] ? locationCodeParts[3] : '');
        setValue('level', locationCodeParts[4] ? locationCodeParts[4] : '');
        setValue('bin', locationCodeParts[5] ? locationCodeParts[5] : '');
        setCurrentInput(currentLocationCode);
        updateValue(
          locationCodeParts[0] ? locationCodeParts[0] : '',
          locationCodeParts[1] ? locationCodeParts[1] : '',
          locationCodeParts[2] ? locationCodeParts[2] : '',
          locationCodeParts[3] ? locationCodeParts[3] : '',
          locationCodeParts[4] ? locationCodeParts[4] : '',
          locationCodeParts[5] ? locationCodeParts[5] : '',
        );
      }
    }, [currentLocationCode]);

    return (
      <>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton className='ios-only' onClick={() => onDismiss()}>
                <IonIcon slot="start" icon={closeOutline} />
                {BUTTON.close}
              </IonButton>
            </IonButtons>
            <IonTitle>{TEXT.putawayLocation}</IonTitle>
            <IonButtons slot="end">
              <IonButton className='md-only' onClick={() => onDismiss()}>
                <IonIcon slot='start' icon={closeOutline} />
                {BUTTON.close}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {
            initialized ?
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.warehouse}</IonLabel>
                  <IonSelect disabled={lockWarehouse}
                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                    {...warehouse}
                    onIonChange={(e: any) => {
                      updateValue(
                        e.detail.value,
                        getValues('floor'),
                        getValues('aisle'),
                        getValues('bay'),
                        getValues('level'),
                        getValues('bin')
                      );
                    }}>
                    <IonSelectOption value="">{TEXT.notSpecfic}</IonSelectOption>
                    {warehouses.map((item: any, index: number) => (
                      <IonSelectOption key={`warehouse-${index}`} value={item.id}>{item.name}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.floor}</IonLabel>
                  <IonInput placeholder={TEXT.floor}
                    {...floor}
                    onIonChange={(e: any) => {
                      updateValue(
                        getValues('warehouse'),
                        e.detail.value,
                        getValues('aisle'),
                        getValues('bay'),
                        getValues('level'),
                        getValues('bin')
                      );
                    }}>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.aisle}</IonLabel>
                  <IonInput placeholder={TEXT.aisle}
                    {...aisle}
                    onIonChange={(e: any) => {
                      updateValue(
                        getValues('warehouse'),
                        getValues('floor'),
                        e.detail.value,
                        getValues('bay'),
                        getValues('level'),
                        getValues('bin')
                      );
                    }}>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.bay}</IonLabel>
                  <IonInput placeholder={TEXT.bay}
                    {...bay}
                    onIonChange={(e: any) => {
                      updateValue(
                        getValues('warehouse'),
                        getValues('floor'),
                        getValues('aisle'),
                        e.detail.value,
                        getValues('level'),
                        getValues('bin')
                      );
                    }}>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.level}</IonLabel>
                  <IonInput placeholder={TEXT.level}
                    {...level}
                    onIonChange={(e: any) => {
                      updateValue(
                        getValues('warehouse'),
                        getValues('floor'),
                        getValues('aisle'),
                        getValues('bay'),
                        e.detail.value,
                        getValues('bin')
                      );
                    }}>
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.bin}</IonLabel>
                  <IonInput placeholder={TEXT.bin}
                    {...bin}
                    onIonChange={(e: any) => {
                      updateValue(
                        getValues('warehouse'),
                        getValues('floor'),
                        getValues('aisle'),
                        getValues('bay'),
                        getValues('level'),
                        e.detail.value
                      );
                    }}>
                  </IonInput>
                </IonItem>
              </IonList>
              : <></>
          }

          {/* Scanner */}
          <IonModal canDismiss swipeToClose isOpen={modalScannerData || modalScannerData === 0 ? true : false}
            onDidDismiss={() => setModalScannerData(null)}>
            <ModalLocationScanner
              reference={modalScannerData}
              onDismiss={(locationCode: any = null) => {
                if (locationCode && locationCode !== true) {
                  const locationCodeParts: any = locationCode.split('.');
                  setValue('warehouse', locationCodeParts[0] ? locationCodeParts[0] : '');
                  setValue('floor', locationCodeParts[1] ? locationCodeParts[1] : '');
                  setValue('aisle', locationCodeParts[2] ? locationCodeParts[2] : '');
                  setValue('bay', locationCodeParts[3] ? locationCodeParts[3] : '');
                  setValue('level', locationCodeParts[4] ? locationCodeParts[4] : '');
                  setValue('bin', locationCodeParts[5] ? locationCodeParts[5] : '');
                  setCurrentInput(locationCode);
                  updateValue(
                    locationCodeParts[0] ? locationCodeParts[0] : '',
                    locationCodeParts[1] ? locationCodeParts[1] : '',
                    locationCodeParts[2] ? locationCodeParts[2] : '',
                    locationCodeParts[3] ? locationCodeParts[3] : '',
                    locationCodeParts[4] ? locationCodeParts[4] : '',
                    locationCodeParts[5] ? locationCodeParts[5] : '',
                  );
                }
                setModalScannerData(null);
              }}
            />
          </IonModal>

        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonItem slot='start' className='w-100' lines='none'>
              <IonIcon slot="start" size='small' icon={barcodeOutline} />
              <IonInput
                placeholder={TEXT.enterLocationCode}
                ref={barcodeInputRef}
                onFocus={(e: any) => e.target.select()}
                onIonChange={async (e: any) => {
                  if (e.detail.value) {
                    updateForm(e.detail.value);
                  }
                  if (
                    (
                      e.detail.value && e.detail.value.length > 1 && !currentInput
                    ) || (
                      lockWarehouse && currentInput && currentInput === e.detail.value.split('.')[0]
                    )
                  ) {
                    set(e.detail.value, reference);
                  }
                  setCurrentInput(e.detail.value);
                }}>
              </IonInput>
              <IonButtons slot="end">
                <IonButton color="tertiary"
                  onClick={() => setModalScannerData(true)}>
                  <IonIcon slot="icon-only" icon={qrCodeOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonItem>
            <IonButton slot='end' color="primary" fill='solid' onClick={(e: any) => set('', reference)}>{BUTTON.ok}</IonButton>
          </IonToolbar>
        </IonFooter>

      </>
    );

  };