import { useState } from "react";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonBackButton,
  IonPage,
  IonLabel,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import {
  saveOutline
} from "ionicons/icons";

import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import TEXT from '../constances/Text.json';

import { ProductsForm } from '../components/forms/ProductsForm';
import { GoBack, RoutePath } from '../components/RoutePath';

const ProductsAdd: React.FC = () => {

  const [doSubmit, setDoSubmit] = useState<boolean>(false);

  const [segment, setSegment] = useState<string>('general');

  const handleSegmentChange = (e: any) => {
    setSegment(e.detail.value);
  };

  // const handleSlideChange = (e: any) => {
  //   setSegment(e);
  // };

  return (
    <IonPage>

      <IonHeader>

        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.productAdd}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setDoSubmit(true)}>
              <IonIcon slot="start" icon={saveOutline} />
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonToolbar>
          <IonSegment value={segment} onIonChange={e => handleSegmentChange(e)}>
            <IonSegmentButton value="general">
              <IonLabel>{TEXT.generalData}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="attribute">
              <IonLabel>{TEXT.appearance}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="warning">
              <IonLabel>{TEXT.warning}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

      </IonHeader>

      <IonContent>
        <ProductsForm
          slide={segment}
          isSubmit={doSubmit}
          onDidSubmit={
            (data: any) => {
              if (data) {
                GoBack();
              }
              setDoSubmit(false);
            }
          }
        // onSlideChange={handleSlideChange}
        />
      </IonContent>

    </IonPage>
  );

};

export default ProductsAdd;