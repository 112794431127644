import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NumberFormat from 'react-number-format';
import DataTable from 'react-data-table-component';

import {
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonLabel,
  IonRow,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonLoading,
  IonImg,
  IonItem,
  IonThumbnail,
  useIonModal,
  IonSearchbar,
  IonHeader,
  IonMenuButton,
  IonProgressBar,
  IonButton,
  IonIcon,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonList,
  IonCheckbox,
  IonFooter,
  IonSpinner,
  SearchbarCustomEvent
} from "@ionic/react";
import {
  closeOutline,
  copyOutline,
  downloadOutline,
  funnelOutline,
  qrCodeOutline
} from "ionicons/icons";

import moment from 'moment';

import TEXT from '../constances/Text.json';
import MENU from '../constances/Menu.json';
import BUTTON from '../constances/Button.json';

import { Direction, RoutePath } from "../components/RoutePath";
import { pageTable } from "../components/TableStyle";

import { ModalASNPutAwayImport } from "../components/modal/ASNPutAwayImport";
import { ToastStatus } from "../components/toasts/ToastStatus";

import UserService from "../services/UserService";
import ProductService from "../services/ProductService";
import WarehouseService from "../services/WarehouseService";
import UtilitiesService from "../services/UtilitiesService";
import VendorService from "../services/VendorService";
import BrandService from "../services/BrandService";
import { ModalLocation } from "../components/modal/Location";

const WarehouseProducts: React.FC<{ data?: any; }> = ({ data = null }) => {

  const offset = 50;

  const defaultSort: any = {
    by: 'product_warehouse.warehouse,product_warehouse.floor,product_warehouse.aisle,product_warehouse.bay,product_warehouse.level,product_warehouse.bin',
    direction: 'asc'
  }

  const { id } = useParams<{ id: string; }>();

  const [members, setMembers] = useState<Array<any>>([]);
  const [warehouse, setWarehouse] = useState<any>(null);
  const [products, setProducts] = useState<any>([]);
  const [vendors, setVendors] = useState<Array<any>>([]);
  const [brands, setBrands] = useState<Array<any>>([]);
  const [currentLocationCode, setCurrentLocation] = useState<any>(null);
  const [columnsExport, setColumnsExport] = useState<any>({
    'SKU': false,
    'NAME_TH': false,
    'NAME_EN': false,
    'BARCODE': false,
    'BRAND': false,
    'SUBBRAND': false,
    'CATEGORY': false,
    'SHORT_DESCRIPTION': false,
    'DESCRIPTION': false,
    'IMAGES': false,
    'TAGS': false,
    'ATTRIBUTE_NAME_1': false,
    'ATTRIBUTE_VALUE_1': false,
    'ATTRIBUTE_NAME_2': false,
    'ATTRIBUTE_VALUE_2': false,
    'ATTRIBUTE_NAME_3': false,
    'ATTRIBUTE_VALUE_3': false,
    'ATTRIBUTE_NAME_4': false,
    'ATTRIBUTE_VALUE_4': false,
    'ATTRIBUTE_NAME_5': false,
    'ATTRIBUTE_VALUE_5': false,
    'WIDTH (CM)': false,
    'DEPTH (CM)': false,
    'HEIGHT (CM)': false,
    'BOX_WIDTH (CM)': false,
    'BOX_DEPTH (CM)': false,
    'BOX_HEIGHT (CM)': false,
    'CARTON_WIDTH (CM)': false,
    'CARTON_DEPTH (CM)': false,
    'CARTON_HEIGHT (CM)': false,
    'PIECE_PER_BOX': false,
    'PIECE_PER_CARTON': false,
    'BOX_PER_CARTON': false,
    'WEIGHT_PIECE (KG)': false,
    'WEIGHT_BOX (KG)': false,
    'WEIGHT_CARTON (KG)': false,
    'FLAMMABLE': false,
    'COLLAPSIBLE': false,
    'FRAGILE': false,
    'TEMPERATURE_CONTROL': false,
    'TEMPERATURE_MIN': false,
    'TEMPERATURE_MAX': false,
    'INSPECTION_CRITERIA': false,
    'LOT_CONTROL': false,
    'PACKING_REMARK': false,
    'QUANTITY': false,
    'LOCATION': false
  });
  const [modalDownloadIsOpen, setModalDownloadIsOpen] = useState<boolean>(false);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [importResult, setImportResult] = useState<any>(null);
  const [groupByLocation, setGroupByLocation] = useState<boolean>(true);
  const [showProgressLoading, setShowProgressLoading] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [sort, setSort] = useState<any>(defaultSort);
  const [total, setTotal] = useState<number>(0);

  const { register, getValues, setValue } = useForm();
  const [filterGroupByLocation] = useState<any>(
    register('groupByLocation', {
      value: true
    })
  );
  const [filterSearch] = useState<any>(
    register('search', {
      value: ''
    })
  );
  const [filterVendor] = useState<any>(
    register('vendor', {
      value: '',
      onChange: async (e: any) => {

        let locationCodeParts: any = '';
        if (e.currentLocationCode) {
          locationCodeParts = e.currentLocationCode.split('.');
        }

        loadProducts(
          0,
          pageLimit,
          sort.by,
          sort.direction,
          e.search ? e.search : null,
          e.detail.value ? e.detail.value : null,
          e.brand ? e.brand : null,
          e.groupByLocation ? e.groupByLocation : false,
          locationCodeParts[1] ? locationCodeParts[1] : null,
          locationCodeParts[2] ? locationCodeParts[2] : null,
          locationCodeParts[3] ? locationCodeParts[3] : null,
          locationCodeParts[4] ? locationCodeParts[4] : null,
          locationCodeParts[5] ? locationCodeParts[5] : null
        );
        UtilitiesService.updateURL('search', e.search ? e.search : null,);
        UtilitiesService.updateURL('vendor', e.detail.value ? e.detail.value : null);
        UtilitiesService.updateURL('brand', e.brand ? e.brand : null);
        UtilitiesService.updateURL('location', e.groupByLocation ? '1' : '0');
        UtilitiesService.updateURL('floor', locationCodeParts[1] ? locationCodeParts[1] : null);
        UtilitiesService.updateURL('aisle', locationCodeParts[2] ? locationCodeParts[2] : null);
        UtilitiesService.updateURL('bay', locationCodeParts[3] ? locationCodeParts[3] : null);
        UtilitiesService.updateURL('level', locationCodeParts[4] ? locationCodeParts[4] : null);
        UtilitiesService.updateURL('bin', locationCodeParts[5] ? locationCodeParts[5] : null);

      }
    })
  );
  const [filterBrand] = useState<any>(
    register('brand', {
      value: '',
      onChange: async (e: any) => {

        let locationCodeParts: any = '';
        if (e.currentLocationCode) {
          locationCodeParts = e.currentLocationCode.split('.');
        }

        loadProducts(
          0,
          pageLimit,
          sort.by,
          sort.direction,
          e.search ? e.search : null,
          e.vendor ? e.vendor : null,
          e.detail.value ? e.detail.value : null,
          e.groupByLocation ? e.groupByLocation : false,
          locationCodeParts[1] ? locationCodeParts[1] : null,
          locationCodeParts[2] ? locationCodeParts[2] : null,
          locationCodeParts[3] ? locationCodeParts[3] : null,
          locationCodeParts[4] ? locationCodeParts[4] : null,
          locationCodeParts[5] ? locationCodeParts[5] : null
        );
        UtilitiesService.updateURL('search', e.search ? e.search : null);
        UtilitiesService.updateURL('vendor', e.vendor ? e.vendor : null);
        UtilitiesService.updateURL('brand', e.detail.value ? e.detail.value : null);
        UtilitiesService.updateURL('location', e.groupByLocation ? '1' : '0');
        UtilitiesService.updateURL('floor', locationCodeParts[1] ? locationCodeParts[1] : null);
        UtilitiesService.updateURL('aisle', locationCodeParts[2] ? locationCodeParts[2] : null);
        UtilitiesService.updateURL('bay', locationCodeParts[3] ? locationCodeParts[3] : null);
        UtilitiesService.updateURL('level', locationCodeParts[4] ? locationCodeParts[4] : null);
        UtilitiesService.updateURL('bin', locationCodeParts[5] ? locationCodeParts[5] : null);

      }
    })
  );

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });
  const [modalLocationData, setModalLocationData] = useState<any>(null);

  const location = useLocation();

  const columns: Array<any> = [
    {
      id: 'product.sku',
      name: TEXT.productSku,
      selector: (row: { sku: any }) => row.sku,
      cell: (row: { id: any, sku: string }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel color="medium">
            <p className='text-full'>{row.sku}</p>
          </IonLabel>
        </IonItem>
      ),
      width: '250px',
      sortable: true,
    },
    {
      id: 'product.name',
      name: TEXT.productName,
      selector: (row: { sku: any }) => row.sku,
      cell: (row: { id: any, sku: string, name: string, figure: any, figure_reference: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productView.replace(':id', row.id)}
          routerDirection={Direction()}>
          {
            row.figure_reference && row.figure_reference.length ?
              <IonThumbnail slot="end">
                <IonImg src={row.figure_reference[0].thumbnail ? row.figure_reference[0].thumbnail : row.figure_reference[0].original} />
              </IonThumbnail>
              : <></>
          }
          <IonLabel>
            <h2 className='text-full'>{row.name}</h2>
          </IonLabel>
        </IonItem>
      ),
      minWidth: '400px',
      sortable: true
    },
    {
      id: 'product.quantity',
      name: TEXT.qtyInStock,
      selector: (row: { quantity: any }) => row.quantity,
      cell: (row: { id: any, quantity: any }) => (
        <NumberFormat className={`text-number ${row.quantity <= 0 ? 'text-danger' : ''}`} value={row.quantity} displayType={'text'} thousandSeparator={true} />
      ),
      right: true,
      sortable: true,
    },
    {
      id: 'product_warehouse.warehouse,product_warehouse.floor,product_warehouse.aisle,product_warehouse.bay,product_warehouse.level,product_warehouse.bin',
      name: TEXT.warehouseLocation,
      selector: (row: { warehouses: any }) => row.warehouses ? row.warehouses.map((item: any) => (item.code)).join(', ') : '',
      cell: (row: { id: any, warehouses: any }) => (
        row.warehouses ?
          <div>
            {
              row.warehouses.map((item: any, index: number) =>
                item.code ?
                  <CopyToClipboard key={`copy_code-${row.id}-${index}`} text={item.code} onCopy={() => {
                    setPresentToast({
                      isPresent: true,
                      status: true,
                      message: `${item.code} ${TEXT.wasCopied}`,
                      onDismiss: () => {
                        if (ready) {
                          setPresentToast(
                            {
                              isPresent: false,
                              status: presentToast.status,
                              message: presentToast.message,
                              onDismiss: presentToast.onDismiss
                            }
                          );
                        }
                      }
                    });
                  }}>
                    <IonButton fill='clear' expand="block">
                      {item.code}
                      <IonIcon size="small" slot="end" icon={copyOutline} />
                    </IonButton>
                  </CopyToClipboard>
                  :
                  <CopyToClipboard key={`copy_code-${row.id}-${index}`} text={
                    item.warehouse + '.'
                    + item.floor + '.'
                    + item.aisle + '.'
                    + item.bay + '.'
                    + item.level + '.'
                    + item.bin
                  } onCopy={() => {
                    setPresentToast({
                      isPresent: true,
                      status: true,
                      message: `${item.warehouse + '.'
                        + item.floor + '.'
                        + item.aisle + '.'
                        + item.bay + '.'
                        + item.level + '.'
                        + item.bin
                        } ${TEXT.wasCopied}`,
                      onDismiss: () => {
                        if (ready) {
                          setPresentToast(
                            {
                              isPresent: false,
                              status: presentToast.status,
                              message: presentToast.message,
                              onDismiss: presentToast.onDismiss
                            }
                          );
                        }
                      }
                    });
                  }}>
                    <IonButton fill='clear' expand="block">
                      {
                        item.warehouse + '.'
                        + item.floor + '.'
                        + item.aisle + '.'
                        + item.bay + '.'
                        + item.level + '.'
                        + item.bin
                      }
                      <IonIcon size="small" slot="end" icon={copyOutline} />
                    </IonButton>
                  </CopyToClipboard>
              )
            }
          </div>
          : ''
      ),
      sortable: true,
      center: true
    }
  ];

  const columnsLocation: Array<any> = [
    {
      id: 'product.sku',
      name: TEXT.productSku,
      selector: (row: { sku: any }) => row.sku,
      cell: (row: { product_id: any, sku: string }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productView.replace(':id', row.product_id)}
          routerDirection={Direction()}>
          <IonLabel color="medium">
            <p className='text-full'>{row.sku}</p>
          </IonLabel>
        </IonItem>
      ),
      width: '250px',
      sortable: true,
    },
    {
      id: 'product.name',
      name: TEXT.productName,
      selector: (row: { sku: any }) => row.sku,
      cell: (row: { product_id: any, sku: string, name: string, figure: any, figure_reference: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productView.replace(':id', row.product_id)}
          routerDirection={Direction()}>
          {
            row.figure_reference && row.figure_reference.length ?
              <IonThumbnail slot="end">
                <IonImg src={row.figure_reference[0].thumbnail ? row.figure_reference[0].thumbnail : row.figure_reference[0].original} />
              </IonThumbnail>
              : <></>
          }
          <IonLabel>
            <h2 className='text-full'>{row.name}</h2>
          </IonLabel>
        </IonItem>
      ),
      minWidth: '400px',
      sortable: true
    },
    {
      id: 'product_warehouse.quantity',
      name: TEXT.qtyInStock,
      selector: (row: { location_quantity: any }) => row.location_quantity,
      cell: (row: { id: any, location_quantity: any }) => (
        <NumberFormat className={`text-number ${row.location_quantity <= 0 ? 'text-danger' : ''}`} value={row.location_quantity} displayType={'text'} thousandSeparator={true} />
      ),
      right: true,
      sortable: true,
    },
    {
      id: 'product_warehouse.warehouse,product_warehouse.floor,product_warehouse.aisle,product_warehouse.bay,product_warehouse.level,product_warehouse.bin',
      name: TEXT.warehouseLocation,
      selector: (row: {
        product_warehouse_id: any,
        warehouse: any,
        floor: any,
        aisle: any,
        bay: any,
        level: any,
        bin: any
      }) => row.product_warehouse_id,
      cell: (row: {
        id: any,
        warehouse: any,
        floor: any,
        aisle: any,
        bay: any,
        level: any,
        bin: any
      }) => (
        <div>
          <CopyToClipboard text={
            `${row.warehouse}.${row.floor}.${row.aisle}.${row.bay}.${row.level}.${row.bin}`
          } onCopy={() => {
            setPresentToast({
              isPresent: true,
              status: true,
              message: `${row.warehouse}.${row.floor}.${row.aisle}.${row.bay}.${row.level}.${row.bin} ${TEXT.wasCopied}`,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          }}>
            <IonButton fill='clear' expand="block">
              {
                `${row.warehouse}.${row.floor}.${row.aisle}.${row.bay}.${row.level}.${row.bin}`
              }
              <IonIcon size="small" slot="end" icon={copyOutline} />
            </IonButton>
          </CopyToClipboard>
        </div>
      ),
      sortable: true,
      center: true,
      width: '170px',
    }
  ];
  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const initialize = async () => {

    setInitialized(false);
    setShowLoading(true);

    const urlParams = new URLSearchParams(location.search);
    const searchParam = urlParams.get('search');
    if (searchParam) {
      await setValue('search', searchParam);
    }
    const vendorParam = urlParams.get('vendor');
    if (vendorParam) {
      await setValue('vendor', vendorParam);
    }
    const brandParam = urlParams.get('brand');
    if (brandParam) {
      await setValue('brand', brandParam);
    }
    const locationParam = urlParams.get('location');
    if (locationParam === '1' || locationParam === '0') {
      if (locationParam === '1') {
        await setValue('groupByLocation', true);
        setGroupByLocation(true);
      } else {
        await setValue('groupByLocation', false);
        setGroupByLocation(false);
      }
    } else {
      UtilitiesService.updateURL('location', '1');
      setGroupByLocation(true);
    }

    const floorParam = urlParams.get('floor');
    const aisleParam = urlParams.get('aisle');
    const bayParam = urlParams.get('bay');
    const levelParam = urlParams.get('level');
    const binParam = urlParams.get('bin');

    const locationCode: string =
      (id ? id : '')
      + '.' + (floorParam ? floorParam : '')
      + '.' + (aisleParam ? aisleParam : '')
      + '.' + (bayParam ? bayParam : '')
      + '.' + (levelParam ? levelParam : '')
      + '.' + (binParam ? binParam : '');

    setCurrentLocation(locationCode);

    setWarehouse(null);
    setProducts([]);
    const userData: any = await UserService.getSession();
    if (userData) {
      const membersData = await userData.members.map(
        ({ group_id }: any) => (
          group_id
        )
      );
      setMembers(membersData);
      if (
        membersData.length && (
          membersData.includes('1')
          || membersData.includes('2')
          || membersData.includes('3')
        )
      ) {
        await loadVendors();
      }
    }
    await loadBrands();
    if (data) {
      setProducts(data);
    } else {
      await load(id, locationCode);
    }

    setInitialized(true);
    setShowLoading(false);

  }

  const load = async (id: string | null | undefined, locationCode: any = currentLocationCode) => {
    if (id) {
      await WarehouseService.get(id).then((data: any) => {
        setWarehouse(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });

      const locationCodeParts: any = locationCode.split('.');

      await loadProducts(
        0,
        pageLimit,
        defaultSort.by,
        defaultSort.direction,
        getValues('search'),
        getValues('vendor'),
        getValues('brand'),
        getValues('groupByLocation') ? true : false,
        locationCodeParts[1] ? locationCodeParts[1] : null,
        locationCodeParts[2] ? locationCodeParts[2] : null,
        locationCodeParts[3] ? locationCodeParts[3] : null,
        locationCodeParts[4] ? locationCodeParts[4] : null,
        locationCodeParts[5] ? locationCodeParts[5] : null,
        id
      );

    }
  }

  const loadProducts = async (
    start: number = 0,
    limit: number | null = pageLimit,
    sortBy: string = defaultSort.by,
    sortDirection: string = defaultSort.direction,
    search: string = getValues('search'),
    vendor: string = getValues('vendor'),
    brand: string = getValues('brand'),
    groupByLocation: boolean = getValues('groupByLocation') ? true : false,
    floor: string = getValues('floor'),
    aisle: string = getValues('aisle'),
    bay: string = getValues('bay'),
    level: string = getValues('level'),
    bin: string = getValues('bin'),
    warehouseID: any = id
  ) => {
    setShowProgressLoading(true);
    await ProductService.countByWarehouse(
      warehouseID,
      true,
      null,
      null,
      search,
      vendor,
      brand,
      floor,
      aisle,
      bay,
      level,
      bin,
      groupByLocation
    ).then(async (rows: any) => {
      setTotal(rows);
      if (rows < offset) {
        start = 0;
        limit = null;
        sortBy = defaultSort.by;
        sortDirection = defaultSort.direction;
      }
      await ProductService.listByWarehouse(
        warehouseID,
        true,
        { by: sortBy, direction: sortDirection },
        start,
        limit,
        search,
        vendor,
        brand,
        floor,
        aisle,
        bay,
        level,
        bin,
        ['figure', 'warehouses'],
        groupByLocation
      ).then((data: any) => {
        if (data && data.length) {
          const productData: any = data.map((item: any) => {
            if (groupByLocation) {
              let newItem = { ...item };
              newItem.id = item.product_warehouse_id;
              newItem.product_id = item.id;
              return newItem;
            } else {
              return item;
            }
          });
          setProducts(productData);
        } else {
          setProducts([]);
        }
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    });
    setShowProgressLoading(false);
  }

  const loadVendors = async () => {
    await VendorService.list(
      true,
      { by: 'name', direction: 'asc' }
    ).then((data: any) => {
      setVendors(data);
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });
  }

  const loadBrands = async () => {
    await BrandService.list(
      true,
      { by: 'name', direction: 'asc' }
    ).then((data: any) => {
      setBrands(data);
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });
  }

  const handlePageChange = async (currentPage: number) => {
    setPage(currentPage);
    loadProducts((currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
  };

  const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
    setPage(currentPage);
    setPageLimit(newPerPage);
    loadProducts((currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    if (column.id !== 'warehouses') {
      loadProducts((page - 1) * pageLimit, pageLimit, column.id, sortDirection);
      setSort({ by: column.id, direction: sortDirection });
    }
  };

  const [presentImport, dismissImport] = useIonModal(ModalASNPutAwayImport, {
    options: {
      canDismiss: true,
      swipeToClose: true
    },
    warehouse: warehouse ? warehouse : null,
    onDismiss: async (data: any) => {
      dismissImport();
      if (data) {
        setShowLoading(true);
        setImportResult(data);
        setPage(1);
        await loadProducts(0, pageLimit, sort.by, sort.direction);
        setShowLoading(false);
      }
    }
  });

  const doFilter = async (locationCode: any = currentLocationCode) => {
    const locationCodeParts: any = locationCode.split('.');
    UtilitiesService.updateURL('search', getValues('search'));
    UtilitiesService.updateURL('vendor', getValues('vendor'));
    UtilitiesService.updateURL('brand', getValues('brand'));
    UtilitiesService.updateURL('location', getValues('location') ? '1' : '');
    UtilitiesService.updateURL('floor', locationCodeParts[1] ? locationCodeParts[1] : '');
    UtilitiesService.updateURL('aisle', locationCodeParts[2] ? locationCodeParts[2] : '');
    UtilitiesService.updateURL('bay', locationCodeParts[3] ? locationCodeParts[3] : '');
    UtilitiesService.updateURL('level', locationCodeParts[4] ? locationCodeParts[4] : '');
    UtilitiesService.updateURL('bin', locationCodeParts[5] ? locationCodeParts[5] : '');
    await loadProducts(
      0,
      pageLimit,
      defaultSort.by,
      defaultSort.direction,
      getValues('search'),
      getValues('vendor'),
      getValues('brand'),
      getValues('groupByLocation') ? true : false,
      locationCodeParts[1] ? locationCodeParts[1] : null,
      locationCodeParts[2] ? locationCodeParts[2] : null,
      locationCodeParts[3] ? locationCodeParts[3] : null,
      locationCodeParts[4] ? locationCodeParts[4] : null,
      locationCodeParts[5] ? locationCodeParts[5] : null,
      id
    );
  };

  const clearFilter = async () => {
    setGroupByLocation(true);
    await setValue('vendor', '');
    await setValue('brand', '');
    await setValue('groupByLocation', true);
    await setCurrentLocation(id);
    UtilitiesService.updateURL('search', null);
    UtilitiesService.updateURL('vendor', null);
    UtilitiesService.updateURL('brand', null);
    UtilitiesService.updateURL('location', '1');
    UtilitiesService.updateURL('floor', null);
    UtilitiesService.updateURL('aisle', null);
    UtilitiesService.updateURL('bay', null);
    UtilitiesService.updateURL('level', null);
    UtilitiesService.updateURL('bin', null);
    await loadProducts(
      0,
      pageLimit,
      defaultSort.by,
      defaultSort.direction,
      '',
      '',
      '',
      true,
      '',
      '',
      '',
      '',
      '',
      id
    );
  };

  const checkColumnsExport = async (check: any) => {
    let selectedColumns: any = { ...columnsExport };
    if (check === true) {
      Object.keys(columnsExport).map((key: any) => {
        selectedColumns[key] = true;
        setColumnsExport(selectedColumns);
      });
    } else if (check === false) {
      Object.keys(columnsExport).map((key: any) => {
        selectedColumns[key] = false;
        setColumnsExport(selectedColumns);
      });
    } else {
      Object.keys(columnsExport).map((key: any) => {
        if (key === check.value && selectedColumns[key] !== check.checked) {
          selectedColumns[key] = check.checked;
          setColumnsExport(selectedColumns);
        }
      });
    }
  }

  const downloadReport = async () => {

    setShowLoading(true);

    const locationCodeParts: any = currentLocationCode.split('.');

    const searchText: string = getValues('search');
    const warehouseID: string = id;

    const csv = async (content: any) => {

      let rows: any = [];

      await rows.push(
        await Object.keys(columnsExport).filter((item: any) => {
          return columnsExport[item] === true;
        })
      );

      for (let item of content) {

        let name_en = '';
        let attributes: any = [];

        if (item.attributes && item.attributes.length) {
          for (let attribute of item.attributes) {
            if (attribute.key === 'name_en') {
              name_en = attribute.value;
            } else if (attributes.length < 5) {
              await attributes.push(attribute);
            }
          }
        }

        let values = [
          (item.sku ? item.sku : ''),
          (item.name ? item.name : ''),
          (name_en ? name_en : ''),
          (item.barcode ? item.barcode : ''),
          (item.brand_reference ? item.brand_reference.name : ''),
          (item.subbrand_reference ? item.subbrand_reference.name : ''),
          (item.category_reference ? item.category_reference.name : ''),
          (item.short_description ? item.short_description : ''),
          (item.description ? item.description : ''),
          (item.figure_reference ?
            item.figure_reference.map((item_reference: any) => {
              return item_reference.original;
            }).join(',')
            : ''),
          (item.tags ? item.tags.join(',') : ''),
          (attributes.length && attributes[0] ? attributes[0].key : ''),
          (attributes.length && attributes[0] ? attributes[0].value : ''),
          (attributes.length && attributes[1] ? attributes[1].key : ''),
          (attributes.length && attributes[1] ? attributes[1].value : ''),
          (attributes.length && attributes[2] ? attributes[2].key : ''),
          (attributes.length && attributes[2] ? attributes[2].value : ''),
          (attributes.length && attributes[3] ? attributes[3].key : ''),
          (attributes.length && attributes[3] ? attributes[3].value : ''),
          (attributes.length && attributes[4] ? attributes[4].key : ''),
          (attributes.length && attributes[4] ? attributes[4].value : ''),
          (item.piece_dimension_width ? item.piece_dimension_width : ''),
          (item.piece_dimension_depth ? item.piece_dimension_depth : ''),
          (item.piece_dimension_height ? item.piece_dimension_height : ''),
          (item.box_dimension_width ? item.box_dimension_width : ''),
          (item.box_dimension_depth ? item.box_dimension_depth : ''),
          (item.box_dimension_height ? item.box_dimension_height : ''),
          (item.carton_dimension_width ? item.carton_dimension_width : ''),
          (item.carton_dimension_depth ? item.carton_dimension_depth : ''),
          (item.carton_dimension_height ? item.carton_dimension_height : ''),
          (item.piece_per_box ? item.piece_per_box : ''),
          (item.piece_per_carton ? item.piece_per_carton : ''),
          (item.box_per_carton ? item.box_per_carton : ''),
          (item.piece_weight ? item.piece_weight : ''),
          (item.box_weight ? item.box_weight : ''),
          (item.carton_weight ? item.carton_weight : ''),
          (item.flammable ? item.flammable : ''),
          (item.collapsible ? item.collapsible : ''),
          (item.fragile ? item.fragile : ''),
          (item.temperature_control ? item.temperature_control : ''),
          (item.temperature_min ? item.temperature_min : ''),
          (item.temperature_max ? item.temperature_max : ''),
          (item.inspection_criteria ? item.inspection_criteria : ''),
          (item.lot_control ? item.lot_control : ''),
          (item.packing_remark ? item.packing_remark : ''),
          (groupByLocation ? item.location_quantity ? item.location_quantity : '' : item.quantity ? item.quantity : ''),
          (
            !groupByLocation ?
              (item.warehouses ? item.warehouses.map((item: any) => (item.code)).join(',') : '')
              : `${item.warehouse}.${item.floor}.${item.aisle}.${item.bay}.${item.level}.${item.bin}`
          )
        ];

        await rows.push(
          await values.filter((item: any, index: number) => {
            return Object.values(columnsExport)[index] === true;
          }).map((item: any) => {
            return '"' + item.toString().replace(/"/g, '""') + '"';
          })
        );

      };

      let header = 'data:text/csv; charset=utf-8,';
      let downloadContent = '';
      for (let rowArray of rows) {
        let row = await rowArray.join(",");
        downloadContent += row + "\r\n";
      }

      const name = `${warehouse ? warehouse.name : TEXT.warehouse}${currentLocationCode ? ` (${currentLocationCode})` : ''
        } - ${moment().format('yyyy-MM-DD')}${searchText ? ` [${TEXT.searchFor} ${searchText}]` : ''
        }.csv`;
      const universalBOM = "\uFEFF";
      const encodedUri = header + encodeURIComponent(universalBOM + downloadContent);
      let link = document.createElement("a");
      await link.setAttribute('href', encodedUri);
      await link.setAttribute('download', name);
      await document.body.appendChild(link);
      await link.click();

    }

    await ProductService.listByWarehouse(
      warehouseID,
      true,
      { by: 'product.sku', direction: 'asc' },
      null,
      null,
      searchText,
      getValues('vendor'),
      getValues('brand'),
      locationCodeParts[1] ? locationCodeParts[1] : null,
      locationCodeParts[2] ? locationCodeParts[2] : null,
      locationCodeParts[3] ? locationCodeParts[3] : null,
      locationCodeParts[4] ? locationCodeParts[4] : null,
      locationCodeParts[5] ? locationCodeParts[5] : null,
      ['brand', 'subbrand', 'category', 'vendor', 'warehouses'],
      getValues('groupByLocation') ? true : false
    ).then(async (data: any) => {
      await csv(data);
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });

    setShowLoading(false);

  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data, id]);

  useEffect(() => {
    if (location.pathname === RoutePath.warehouseProducts.replace(':id', id)) {
      if (initialized) {

        const urlParams = new URLSearchParams(location.search);
        const locationParam = urlParams.get('location');
        if (locationParam === '1' || locationParam === '0') {
          if (locationParam === '1') {
            setValue('groupByLocation', true);
            setGroupByLocation(true);
          } else {
            setValue('groupByLocation', false);
            setGroupByLocation(false);
          }
        } else {
          UtilitiesService.updateURL('location', '1');
          setGroupByLocation(true);
        }

        load(id);

      }
    }
  }, [location]);

  return (
    <IonPage>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{MENU.warehouseProduct}</IonTitle>
        </IonToolbar>
        {
          showProgressLoading &&
          <IonProgressBar type="indeterminate" className='fixed'></IonProgressBar>
        }
      </IonHeader>

      <IonContent>

        <IonModal canDismiss swipeToClose isOpen={modalDownloadIsOpen} onDidDismiss={() => setModalDownloadIsOpen(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{BUTTON.download}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setModalDownloadIsOpen(false)}>
                  <IonIcon slot="start" icon={closeOutline} />
                  {BUTTON.close}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonList>
                    <IonItem>
                      <IonLabel>
                        {TEXT.selectAllRowsItem}
                      </IonLabel>
                      <IonCheckbox onIonChange={(e) => checkColumnsExport(e.detail.checked)}></IonCheckbox>
                    </IonItem>
                    {
                      Object.keys(columnsExport).map((key: any, index: number) => (
                        <IonItem key={index}>
                          <IonLabel>
                            {key}
                          </IonLabel>
                          <IonCheckbox value={key}
                            checked={columnsExport[key]}
                            onIonChange={(e: any) => checkColumnsExport(e.detail)}>
                          </IonCheckbox>
                        </IonItem>
                      ))
                    }
                  </IonList>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <IonFooter>
            <IonToolbar>
              <IonButton expand="block" color="primary" className='ion-margin-start ion-margin-end' fill='solid' onClick={() => downloadReport()}>
                <IonIcon slot="start" icon={downloadOutline} />
                {BUTTON.download}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        <IonModal canDismiss swipeToClose isOpen={modalLocationData ? true : false} onDidDismiss={() => setModalLocationData(null)}>
          <ModalLocation
            reference={modalLocationData}
            lockWarehouse={true}
            currentLocationCode={currentLocationCode}
            onDismiss={async (locationData: any) => {
              if (locationData) {
                const locationCode: string =
                  (locationData.warehouse ? locationData.warehouse : '')
                  + '.' + (locationData.floor ? locationData.floor : '')
                  + '.' + (locationData.aisle ? locationData.aisle : '')
                  + '.' + (locationData.bay ? locationData.bay : '')
                  + '.' + (locationData.level ? locationData.level : '')
                  + '.' + (locationData.bin ? locationData.bin : '');
                setCurrentLocation(locationCode);
                doFilter(locationCode);
              }
              setModalLocationData(null);
            }}
          />
        </IonModal>

        {
          warehouse ?
            <IonGrid>

              <IonToolbar className="toolbar-title">
                <IonTitle size="large" slot="start">{warehouse.name}</IonTitle>
              </IonToolbar>

              <IonRow className='row-toolbar'>
                <IonCol size='12' sizeMd='5'>
                  <IonToolbar className='toolbar-page'>
                    <IonItem lines="none">
                      <IonSearchbar
                        placeholder={
                          `${TEXT.productName}/${TEXT.productSku}/${TEXT.barcode}`
                        }
                        inputmode="search"
                        {...filterSearch}
                        onIonChange={(e: SearchbarCustomEvent) => { setValue('search', e.detail.value); }}
                        onKeyUp={(e: KeyboardEvent) => {
                          if (e.key === "Enter") {
                            doFilter(currentLocationCode);
                          }
                        }}
                        onIonClear={async () => {
                          await setValue('search', '');
                          doFilter(currentLocationCode);
                        }}
                      >
                      </IonSearchbar>
                    </IonItem>
                  </IonToolbar>
                </IonCol>
                <IonCol size='12' sizeMd='4'>
                  <IonToolbar className='toolbar-page'>
                    <IonItem lines="none">
                      <IonLabel>
                        {TEXT.groupByLocation}
                      </IonLabel>
                      <IonCheckbox slot="start" {...filterGroupByLocation}
                        disabled={showProgressLoading}
                        onIonChange={async (e: any) => {

                          if (groupByLocation !== e.detail.checked) {

                            await setValue('groupByLocation', e.detail.checked);

                            setGroupByLocation(e.detail.checked);

                            setProducts([]);

                            let locationCodeParts: any = '';
                            if (currentLocationCode) {
                              locationCodeParts = currentLocationCode.split('.');
                            }

                            loadProducts(
                              0,
                              pageLimit,
                              sort.by,
                              sort.direction,
                              getValues('search') ? getValues('search') : null,
                              getValues('vendor') ? getValues('vendor') : null,
                              getValues('brand') ? getValues('brand') : null,
                              e.detail.checked,
                              locationCodeParts[1] ? locationCodeParts[1] : null,
                              locationCodeParts[2] ? locationCodeParts[2] : null,
                              locationCodeParts[3] ? locationCodeParts[3] : null,
                              locationCodeParts[4] ? locationCodeParts[4] : null,
                              locationCodeParts[5] ? locationCodeParts[5] : null
                            );
                            UtilitiesService.updateURL('search', getValues('search') ? getValues('search') : null);
                            UtilitiesService.updateURL('vendor', getValues('vendor') ? getValues('vendor') : null);
                            UtilitiesService.updateURL('brand', getValues('brand') ? getValues('brand') : null);
                            UtilitiesService.updateURL('location', e.detail.checked ? '1' : '0');
                            UtilitiesService.updateURL('floor', locationCodeParts[1] ? locationCodeParts[1] : null);
                            UtilitiesService.updateURL('aisle', locationCodeParts[2] ? locationCodeParts[2] : null);
                            UtilitiesService.updateURL('bay', locationCodeParts[3] ? locationCodeParts[3] : null);
                            UtilitiesService.updateURL('level', locationCodeParts[4] ? locationCodeParts[4] : null);
                            UtilitiesService.updateURL('bin', locationCodeParts[5] ? locationCodeParts[5] : null);

                          }

                        }} checked={groupByLocation}>
                      </IonCheckbox>
                    </IonItem>
                  </IonToolbar>
                </IonCol>
                <IonCol size='12' sizeMd='3'>
                  <IonToolbar className='toolbar-page'>
                    <IonButtons slot="end" className='w-100'>
                      <IonButton fill='outline' color="secondary" onClick={() => { setModalDownloadIsOpen(true); }}
                        disabled={showProgressLoading}>
                        <IonIcon slot="start" icon={downloadOutline} />&nbsp;{BUTTON.download}
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonCol>
              </IonRow>

              <IonTitle size="small" className='ion-margin-top'>
                <IonLabel>
                  <IonIcon slot="start" icon={funnelOutline} />
                  {TEXT.filter}
                </IonLabel>
              </IonTitle>

              <IonRow className='row-toolbar'>
                {
                  members.length && (
                    members.includes('1')
                    || members.includes('2')
                    || members.includes('3')
                  ) && vendors
                    ?
                    <IonCol size='12' sizeMd='3'>
                      <IonToolbar className='toolbar-page'>
                        <IonItem lines="none">
                          <IonLabel>{TEXT.vendor}</IonLabel>
                          <IonSelect
                            interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                            {...filterVendor} value={getValues('vendor')}
                            onIonChange={(e: any) => {
                              e.currentLocationCode = currentLocationCode;
                              e.search = getValues('search');
                              e.brand = getValues('brand');
                              e.groupByLocation = getValues('groupByLocation') ? true : false;
                              filterVendor.onChange(e)
                            }}>
                            <IonSelectOption value="">{TEXT.notSpecfic}</IonSelectOption>
                            {vendors.map((item: any, index: number) => (
                              <IonSelectOption key={`vendor-${index}`} value={item.id}>{item.name}</IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                      </IonToolbar>
                    </IonCol>
                    : <></>
                }
                {
                  brands ?
                    <IonCol size='12' sizeMd=
                      {
                        members.length && (
                          members.includes('1')
                          || members.includes('2')
                          || members.includes('3')
                        )
                          ? '3' : '5'
                      }>
                      <IonToolbar className='toolbar-page'>
                        <IonItem lines="none">
                          <IonLabel>{TEXT.brand}</IonLabel>
                          <IonSelect
                            interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                            {...filterBrand} value={getValues('brand')}
                            onIonChange={(e: any) => {
                              e.currentLocationCode = currentLocationCode;
                              e.vendor = getValues('vendor');
                              e.brand = getValues('brand');
                              e.groupByLocation = getValues('groupByLocation') ? true : false;
                              filterBrand.onChange(e)
                            }}>
                            <IonSelectOption value="">{TEXT.notSpecfic}</IonSelectOption>
                            {brands.map((item: any, index: number) => (
                              <IonSelectOption key={`brand-${index}`} value={item.id}>{item.name}</IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                      </IonToolbar>
                    </IonCol>
                    : <></>
                }
                <IonCol size='12' sizeMd='4'>
                  <IonToolbar className='toolbar-page'>
                    <IonItem className='w-100 item-no-padding-end' lines='none'>
                      <IonInput
                        placeholder={TEXT.putawayLocation}
                        readonly={true}
                        value={currentLocationCode}
                        onClick={() => {
                          setModalLocationData(true);
                        }}>
                      </IonInput>
                      <IonButtons>
                        <IonButton 
                          color="tertiary"
                          className="ion-no-padding"
                          size="small"
                          onClick={() => { }}
                        >
                          <IonIcon size="small" slot="icon-only" icon={qrCodeOutline}></IonIcon>
                        </IonButton>
                      </IonButtons>
                    </IonItem>
                  </IonToolbar>
                </IonCol>
                <IonCol size='12' sizeMd='2'>
                  <IonToolbar className='toolbar-page'>
                    <IonButtons>
                      <IonButton fill="outline" expand="block" color="danger" size="small" onClick={() => clearFilter()}
                        disabled={showProgressLoading}>
                        <IonIcon slot="start" icon={closeOutline} />
                        {BUTTON.clear}
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonCol>
              </IonRow>

            </IonGrid>
            : <></>
        }

        {
          initialized ?
            (total < offset) ?
              <DataTable
                columns={groupByLocation ? columnsLocation : columns}
                data={products}
                customStyles={pageTable}
                pagination
                noDataComponent={TEXT.noDataComponent}
                paginationComponentOptions={paginationComponentOptions}
                defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                defaultSortFieldId={defaultSort.by}
                onSort={handleSort}
                progressPending={showProgressLoading}
                progressComponent={<IonSpinner color="primary" />}
              />
              :
              <DataTable
                columns={groupByLocation ? columnsLocation : columns}
                data={products}
                customStyles={pageTable}
                pagination
                noDataComponent={TEXT.noDataComponent}
                paginationComponentOptions={paginationComponentOptions}
                paginationServer
                paginationTotalRows={total}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                sortServer
                defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                defaultSortFieldId={defaultSort.by}
                onSort={handleSort}
                progressPending={showProgressLoading}
                progressComponent={<IonSpinner color="primary" />}
              />
            : <></>
        }

      </IonContent>
    </IonPage>
  );
}

export default WarehouseProducts;