import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";

import { 
  IonFab, 
  IonFabButton, 
  IonFabList, 
  IonModal, 
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {
  barcodeOutline,
  checkmarkCircleOutline,
  qrCodeOutline,
  scanOutline
} from 'ionicons/icons';

import MENU from '../constances/Menu.json';
import BUTTON from '../constances/Button.json';

import { GoBack, RoutePath } from '../components/RoutePath';
import { ToastStatus } from '../components/toasts/ToastStatus';
import { ModalScanner } from '../components/modal/Scanner';
import { ModalBarcode } from '../components/modal/Barcode';
import MESSAGE from '../constances/Messages.json';
import PackProductsForm from '../components/forms/PackProductsForm';

import OrderService from '../services/OrderService';
import UserService from '../services/UserService';
import PackService from '../services/PackService';

const SendParcelEdit: React.FC<{ data?: any; }> = ({ data = null }) => {

  const location = useLocation();

  const [modalScannerIsOpen, setModalScannerIsOpen] = useState<boolean>(false);
  const [modalBarcodeIsOpen, setModalBarcodeIsOpen] = useState<boolean>(false);

  const [doSubmit, setDoSubmit] = useState<boolean>(false);

  const { id } = useParams<{ id: string; }>();
  const { packID } = useParams<{ packID: string; }>();

  const [order, setOrder] = useState<any>(null);
  const [pack, setPack] = useState<any>(null);
  const [products, setProducts] = useState<Array<any>>([]);
  const [packs, setPacks] = useState<any>([]);
  const [packed, setPacked] = useState<any>([]);
  const [check, setCheck] = useState<any>([]);
  const [warehouse, setWarehouse] = useState<any>(null);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data, id]);

  useEffect(() => {
    if (location.pathname === RoutePath.orderPackEdit.replace(':id', id)) {
      if (initialized) {
        initialize();
      }
    }
  }, [location]);

  const initialize = async () => {
    setInitialized(false);
    setOrder(null);
    if (data) {
      setOrder(data);
    } else {
      const userData: any = await UserService.getSession();
      if (userData) {
        await load(id);
      }
    }
    setInitialized(true);
  }

  const load = async (id: string|null|undefined) => {
    if (id) {
      await OrderService.get(id, null, ['products', 'product.product']).then(async (data: any) => {
        setOrder(data);
        await loadPack();
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  const loadPack = async () => {
    if (packID) {
      await PackService.get(packID, null, ['products', 'product.product']).then((data: any) => {
        setPack(data);
      }).catch(() => { });
    }
  }

  return (
    <IonPage>

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.sendParcelEdit}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setDoSubmit(true)}>
              <IonIcon slot="start" icon={checkmarkCircleOutline} />
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        <IonModal canDismiss swipeToClose isOpen={modalScannerIsOpen} onDidDismiss={() => setModalScannerIsOpen(false)}>
          <ModalScanner
            productsData={JSON.parse(JSON.stringify(products))}
            checksData={JSON.parse(JSON.stringify(packs))}
            checkedData={JSON.parse(JSON.stringify(packed))}
            onCheck={
              async (product: any) => {
                await setCheck(product);
                setCheck(null);
                setPresentToast({
                  isPresent: true,
                  status: true,
                  message: MESSAGE.success.product_in_list,
                  onDismiss: () => {
                    if (ready) {
                      setPresentToast(
                        {
                          isPresent: false,
                          status: presentToast.status,
                          message: presentToast.message,
                          onDismiss: presentToast.onDismiss
                        }
                      );
                    }
                  }
                });
              }
            }
            onDismiss={() => setModalScannerIsOpen(false)}
          />
        </IonModal>

        <IonModal canDismiss swipeToClose isOpen={modalBarcodeIsOpen} onDidDismiss={() => setModalBarcodeIsOpen(false)}>
          <ModalBarcode
            productsData={JSON.parse(JSON.stringify(products))}
            checksData={JSON.parse(JSON.stringify(packs))}
            checkedData={JSON.parse(JSON.stringify(packed))}
            onCheck={
              async (product: any) => {
                await setCheck(product);
                setCheck(null);
                setPresentToast({
                  isPresent: true,
                  status: true,
                  message: MESSAGE.success.product_in_list,
                  onDismiss: () => {
                    if (ready) {
                      setPresentToast(
                        {
                          isPresent: false,
                          status: presentToast.status,
                          message: presentToast.message,
                          onDismiss: presentToast.onDismiss
                        }
                      );
                    }
                  }
                });
              }
            }
            onDismiss={() => setModalBarcodeIsOpen(false)}
          />
        </IonModal>
        
        {
          order && pack ?
            <PackProductsForm 
            orderData={JSON.parse(JSON.stringify(order))} 
            packData={JSON.parse(JSON.stringify(pack))} 
            checkData={JSON.parse(JSON.stringify(check))} 
            onProductsUpdate={(data: any) => { setProducts(JSON.parse(JSON.stringify(data))); }}
            onPacksUpdate={(data: any) => { setPacks(JSON.parse(JSON.stringify(data))); }}
            onPackedUpdate={(data: any) => { setPacked(JSON.parse(JSON.stringify(data))); }}
            isSubmit={doSubmit} 
            onDidSubmit={(data: any) => { if (data) { GoBack() }; setDoSubmit(false); }} 
            />
            : <></>
        }

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={scanOutline} />
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton onClick={() => setModalBarcodeIsOpen(true)}><IonIcon icon={barcodeOutline} /></IonFabButton>
            <IonFabButton onClick={() => setModalScannerIsOpen(true)}><IonIcon icon={qrCodeOutline} /></IonFabButton>
          </IonFabList>
        </IonFab>

      </IonContent>

    </IonPage>
  );
};

export default SendParcelEdit;