import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { 
  IonBackButton,
  IonButton, 
  IonButtons, 
  IonCol, 
  IonContent, 
  IonGrid, 
  IonHeader, 
  IonIcon, 
  IonMenuButton, 
  IonPage, 
  IonRow, 
  IonTitle, 
  IonToolbar 
} from '@ionic/react';
import { 
  checkmarkCircleOutline,
} from 'ionicons/icons';

import MENU from '../constances/Menu.json';
import BUTTON from '../constances/Button.json';

import { GoBack, RoutePath } from '../components/RoutePath';
import { WithdrawForm } from '../components/forms/WithdrawForm';

const WithdrawsAdd: React.FC = () => {

  const [doSubmit, setDoSubmit] = useState<boolean>(false);

  const history = useHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.productWithdrawAdd}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => setDoSubmit(true)}>
              <IonIcon slot="start" icon={checkmarkCircleOutline} />
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
              <WithdrawForm isSubmit={doSubmit} onDidSubmit={
                (data: any) => {
                  if (data) {
                    GoBack();
                  }
                  setDoSubmit(false);
                }
              } />
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default WithdrawsAdd;