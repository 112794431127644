import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import {
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonButton
} from "@ionic/react";

import MESSAGE from '../../constances/Messages.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';
import { PATTERN_EMAIL } from '../../constances/RegularExpressions';
import BUSINESS_TYPES from '../../constances/BusinessTypes.json';

import { ToastStatus } from '../toasts/ToastStatus';
import { AlertStatus } from '../alerts/AlertStatus';

import VendorService from "../../services/VendorService";
import LocationService from '../../services/LocationService';
import { AlertPrompt } from '../alerts/AlertPrompt';
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  documentOutline,
  downloadOutline,
  personOutline,
  shieldOutline,
  storefrontOutline
} from 'ionicons/icons';
import UserService from '../../services/UserService';

export const VendorForm: React.FC<{
  data?: any;
  name?: string;
  isSubmit?: boolean;
  onDidSubmit?: (data?: any) => any;
}> = ({ data = null, name = '', isSubmit = false, onDidSubmit }) => {

  const defaultCountry: string = 'TH';

  const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm();

  const [countries, setCountries] = useState<Array<any>>([]);
  const [provinces, setProvinces] = useState<Array<any>>([]);
  const [cities, setCities] = useState<Array<any>>([]);
  const [subDistricts, setSubDistricts] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [formGroup, setFormGroup] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const initialize = async () => {
    setShowLoading(true);
    resetFormValues();

    await getCountries().then(async (countriesData: any) => {

      setCountries(countriesData);

      let countryValue: any;
      let provinceValue: any;
      let cityValue: any;
      let subdistrictValue: any;
      const searchCountry = (countryStored: string) => {
        return new Promise(resolve => {
          if (countryStored) {
            for (let country of countriesData) {
              if (
                country.name === countryStored
                || country.enName === countryStored
                || country.alpha2 === countryStored
              ) {
                resolve(country.alpha2);
              }
            }
          } else {
            resolve(defaultCountry);
          }
        });
      }
      await searchCountry(
        data && data.country ? data.country : defaultCountry
      ).then(async (countryData: any) => {
        countryValue = countryData;
        const searchProvince = (provinceStored: string) => {
          return new Promise(resolve => {
            getProvinces(countryValue).then(async (provincesData: any) => {
              setProvinces(provincesData);
              if (provinceStored) {
                for (let province of provincesData) {
                  if (
                    province.name_th === provinceStored
                    || province.name_en === provinceStored
                  ) {
                    resolve(province.id);
                  }
                }
              } else {
                resolve('');
              }
            });
          });
        }
        await searchProvince(
          data && data.province ? data.province : ''
        ).then(async (provinceData: any) => {
          provinceValue = provinceData;
          const searchCity = (cityStored: string) => {
            return new Promise(resolve => {
              getCities(countryValue, provinceValue).then(async (citiesData: any) => {
                setCities(citiesData);
                if (cityStored) {
                  for (let city of citiesData) {
                    if (
                      city.name_th === cityStored
                      || city.name_en === cityStored
                    ) {
                      resolve(city.id);
                    }
                  }
                } else {
                  resolve('');
                }
              });
            });
          }
          await searchCity(
            data && data.city ? data.city : ''
          ).then(async (cityData: any) => {
            cityValue = cityData;
            const searchSubDistrict = async (subdistrictStored: string) => {
              return new Promise(resolve => {
                getSubDistricts(countryValue, cityValue).then(async (subDistrictsData: any) => {
                  setSubDistricts(subDistrictsData);
                  if (subdistrictStored) {
                    for (let subDistrict of subDistrictsData) {
                      if (
                        subDistrict.name_th === subdistrictStored
                        || subDistrict.name_en === subdistrictStored
                      ) {
                        resolve(subDistrict.id);
                      }
                    }
                  } else {
                    resolve('');
                  }
                });
              });
            }
            await searchSubDistrict(
              data && data.subdistrict ? data.subdistrict : ''
            ).then((subdistrictData: any) => {
              subdistrictValue = subdistrictData;
            });
          });
        });
      });

      setFormGroup(
        {
          name: register('name', {
            value: name ? name : data && data.name ? data.name : '',
            required: MESSAGE.error.input_required
          }),
          password: register('password', {
            value: '',
            required: !data ? MESSAGE.error.input_required : false
          }),
          confirm_password: register('confirm_password', {
            value: '',
            required: !data ? MESSAGE.error.input_required : false
          }),
          tax_id: register('tax_id', {
            value: data ? data.tax_id : '',
            required: MESSAGE.error.input_required
          }),
          address: register('address', {
            value: data ? data.address : ''
          }),
          subdistrict: register('subdistrict', {
            value: subdistrictValue,
            onChange: (e) => {
              if (e.target.value) {
                getSubDistricts(
                  getValues('country'),
                  getValues('city')
                ).then((subDistrictsData: any) => {
                  if (subDistrictsData && subDistrictsData.length) {
                    for (let subDistrict of subDistrictsData) {
                      if (subDistrict.id === e.target.value && subDistrict.zip_code) {
                        setValue('postcode', subDistrict.zip_code.toString());
                      }
                    }
                  }
                });
              }
            }
          }),
          city: register('city', {
            value: cityValue,
            onChange: (e) => {
              if (e.target.value) {
                getSubDistricts(getValues('country'), e.target.value).then((subDistrictsData: any) => {
                  setValue('subdistrict', '');
                  setValue('postcode', '');
                  setSubDistricts(subDistrictsData);
                });
              }
            }
          }),
          province: register('province', {
            value: provinceValue,
            onChange: async (e) => {
              if (e.target.value) {
                getCities(getValues('country'), e.target.value).then((citiesData: any) => {
                  setValue('city', '');
                  setValue('subdistrict', '');
                  setValue('postcode', '');
                  setCities(citiesData);
                });
              }
            }
          }),
          country: register('country', {
            value: countryValue,
            onChange: (e) => {
              if (e.target.value) {
                getProvinces(e.target.value).then((provincesData: any) => {
                  setValue('province', '');
                  setValue('city', '');
                  setValue('subdistrict', '');
                  setValue('postcode', '');
                  setProvinces(provincesData);
                });
              }
            }
          }),
          postcode: register('postcode', {
            value: data ? data.postcode : ''
          }),
          phone: register('phone', {
            value: data ? data.phone : ''
          }),
          email: register('email', {
            value: data ? data.email : ''
          }),
          business_type: register('business_type', {
            value: data ? data.business_type : '',
            required: MESSAGE.error.input_required
          }),
          contact_person_name: register('contact_person_name', {
            value: data ? data.contact_person_name : ''
          }),
          contact_person_phone: register('contact_person_phone', {
            value: data ? data.contact_person_phone : ''
          }),
          contact_person_email: register('contact_person_email', {
            value: data ? data.contact_person_email : '',
            pattern: {
              value: PATTERN_EMAIL,
              message: MESSAGE.error.email_invalid,
            }
          }),
          contact_person_secondary_name: register('contact_person_secondary_name', {
            value: data ? data.contact_person_secondary_name : ''
          }),
          contact_person_secondary_phone: register('contact_person_secondary_phone', {
            value: data ? data.contact_person_secondary_phone : ''
          }),
          contact_person_secondary_email: register('contact_person_secondary_email', {
            value: data ? data.contact_person_secondary_email : '',
            pattern: {
              value: PATTERN_EMAIL,
              message: MESSAGE.error.email_invalid,
            }
          }),
          active: register('active', {
            value: data ? data.active !== '0' ? '1' : '0' : '1'
          })
        }
      );

    });

    setShowLoading(false);

  }

  const resetFormValues = () => {
    reset();
  }

  const onSubmit = async (value: any) => {
    if (!data || value.password === value.confirm_password) {

      setShowLoading(true);

      value.postcode = value.postcode.toString();

      const searchProvince = async (id: number) => {
        return new Promise(resolve => {
          for (let province of provinces) {
            if (province.id === id) {
              if (value.country === 'TH') {
                resolve(province.name_th);
              } else {
                resolve(province.name_en);
              }
            }
          }
        });
      }
      if (value.province) {
        value.province = await searchProvince(value.province);
      }

      const searchCity = async (id: number) => {
        return new Promise(resolve => {
          for (let city of cities) {
            if (city.id === id) {
              if (value.country === 'TH') {
                resolve(city.name_th);
              } else {
                resolve(city.name_en);
              }
            }
          }
        });
      }
      if (value.city) {
        value.city = await searchCity(value.city);
      }

      const searchSubDistrict = async (id: number) => {
        return new Promise(resolve => {
          for (let subDistrict of subDistricts) {
            if (subDistrict.id === id) {
              if (value.country === 'TH') {
                resolve(subDistrict.name_th);
              } else {
                resolve(subDistrict.name_en);
              }
            }
          }
        });
      }
      if (value.subdistrict) {
        value.subdistrict = await searchSubDistrict(value.subdistrict);
      }

      const searchCountry = async (countryCode: string) => {
        return new Promise(resolve => {
          for (let country of countries) {
            if (country.alpha2 === countryCode) {
              if (countryCode === 'TH') {
                resolve(country.name);
              } else {
                resolve(country.enName);
              }
            }
          }
        });
      }
      if (value.country) {
        value.country = await searchCountry(value.country);
      }

      const onSuccess = (result: any) => {
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.submit_complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
        if (onDidSubmit) {
          onDidSubmit(result);
        }
      }

      const onError = (message: any) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: message,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
        if (onDidSubmit) {
          onDidSubmit();
        }
      }

      value.phone = value.contact_person_phone ? value.contact_person_phone : '';

      if (data) {
        await VendorService.update(data.id, value).then(async (result: any) => {
          onSuccess(result);
        }).catch((error) => {
          onError(error);
        });
      } else {

        value.last_name = '';
        value.nick_name = '';
        value.passcode = '';

        await UserService.signup(value).then(async (result: any) => {
          onSuccess(result);
          resetFormValues();
        }).catch((error) => {
          onError(error);
        });
      }

      setShowLoading(false);

    } else {
      setPresentToast({
        isPresent: true,
        status: false,
        message: MESSAGE.error.password_mismatched,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    }
  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
    if (onDidSubmit) {
      onDidSubmit();
    }
  }

  const getCountries = async () => {
    return new Promise(async (resolve) => {
      LocationService.getCountries().then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getProvinces = async (countryCode: string) => {
    return new Promise(async (resolve) => {
      LocationService.getProvincesByCountry(countryCode).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getCities = async (countryCode: string, provinceID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getCitiesByProvince(
        countryCode,
        provinceID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getSubDistricts = async (countryCode: string, cityID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getSubDistrictsByCity(
        countryCode,
        cityID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit, onError)();
    }
  }, [isSubmit]);

  return (
    <>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <AlertPrompt
        isPresent={presentAlertPrompt.isPresent}
        message={presentAlertPrompt.message}
        onDismiss={presentAlertPrompt.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      {
        formGroup ?
          <form onSubmit={handleSubmit(onSubmit, onError)}>

            <IonCard>

              <IonCardHeader>
                <IonCardTitle>
                  <IonItem lines='none' className='item-no-padding-all'>
                    <IonIcon icon={storefrontOutline} slot="start" size='small' />
                    <IonLabel>{TEXT.generalData}</IonLabel>
                  </IonItem>
                </IonCardTitle>
              </IonCardHeader>

              <IonCardContent>

                <IonGrid>
                  <IonRow>

                    <IonCol size="12" sizeMd='6'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.vendorName}<span className='form-required'>*</span></IonLabel>
                        <IonInput type="text" {...formGroup.name}></IonInput>
                      </IonItem>
                      <ErrorMessage errors={errors} name="name"
                        render={({ message }) =>
                          <div className='form-help'>
                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                            {message ? message : ''}
                          </div>
                        } />
                    </IonCol>

                    <IonCol size="12" sizeMd='6'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.taxID}<span className='form-required'>*</span></IonLabel>
                        <IonInput type="text" {...formGroup.tax_id}></IonInput>
                      </IonItem>
                      <ErrorMessage errors={errors} name="tax_id"
                        render={({ message }) =>
                          <div className='form-help'>
                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                            {message ? message : ''}
                          </div>
                        } />
                    </IonCol>

                    <IonCol size='12'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.address}<span className='form-required'>*</span></IonLabel>
                        <IonInput type="text" {...formGroup.address}></IonInput>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      {/* ประเทศ */}
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.country}<span className='form-required'>*</span></IonLabel>
                        <IonSelect
                          interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                          placeholder="เลือกประเทศ"
                          value={getValues('country')} disabled
                          onIonChange={(e: any) => { formGroup.country.onChange(e) }}
                          {...formGroup.country}
                        >
                          {countries.map((item: any, index: number) => (
                            <IonSelectOption key={`country-${index}`} value={item.alpha2}>{item.name}</IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      {/* จังหวัด */}
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.province}<span className='form-required'>*</span></IonLabel>
                        <IonSelect
                          interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                          placeholder="เลือกจังหวัด"
                          value={getValues('province')}
                          {...formGroup.province}
                          disabled={!provinces.length}
                          onIonChange={(e: any) => { formGroup.province.onChange(e) }}
                        >
                          {provinces.map((item: any, index: number) => (
                            <IonSelectOption key={`province-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      {/* อำเภอ/เขต */}
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.city}<span className='form-required'>*</span></IonLabel>
                        <IonSelect
                          interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                          placeholder="เลือกเขต/อำเภอ"
                          value={getValues('city')}
                          {...formGroup.city}
                          disabled={!cities.length}
                          onIonChange={(e: any) => { formGroup.city.onChange(e) }}
                        >
                          {cities.map((item: any, index: number) => (
                            <IonSelectOption key={`city-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      {/* ตำบล/แขวง */}
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.subDistrict}<span className='form-required'>*</span></IonLabel>
                        <IonSelect
                          interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                          placeholder="เลือกแขวง/ตำบล"
                          value={getValues('subdistrict')}
                          {...formGroup.subdistrict}
                          disabled={!subDistricts.length}
                          onIonChange={(e: any) => { formGroup.subdistrict.onChange(e) }}
                        >
                          {subDistricts.map((item: any, index: number) => (
                            <IonSelectOption key={`subdistrict-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      {/* รหัสไปรษณีย์ */}
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.postcode}<span className='form-required'>*</span></IonLabel>
                        <IonInput type="text" {...formGroup.postcode}></IonInput>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      {/* ประเภทธุรกิจ */}
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.vendorBusinessType}<span className='form-required'>*</span></IonLabel>
                        <IonSelect
                          interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                          placeholder={TEXT.vendorBusinessType}
                          value={getValues('business_type')}
                          {...formGroup.business_type}
                        >
                          {BUSINESS_TYPES.map((item: any, index: number) => (
                            <IonSelectOption key={`business-${index}`} value={item.key}>{item.value}</IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <ErrorMessage errors={errors} name="business_type"
                        render={({ message }) =>
                          <div className='form-help'>
                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                            {message ? message : ''}
                          </div>
                        } />
                    </IonCol>

                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>

            {/* บุคคลสำหรับติดต่อ */}
            <IonCard>

              <IonCardHeader>
                <IonCardTitle>
                  <IonItem lines='none' className='item-no-padding-all'>
                    <IonIcon icon={personOutline} slot="start" size='small' />
                    <IonLabel>{TEXT.vendorContactInfo}</IonLabel>
                  </IonItem>
                </IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <IonGrid>
                  <IonRow>
                    {/* Name 1 */}
                    <IonCol size='12'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.vendorContactName}</IonLabel>
                        <IonInput type="text" {...formGroup.contact_person_name}></IonInput>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.phone}</IonLabel>
                        <IonInput type="text" {...formGroup.contact_person_phone}></IonInput>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.email}</IonLabel>
                        <IonInput type="email" {...formGroup.contact_person_email}></IonInput>
                      </IonItem>
                      <ErrorMessage errors={errors} name="contact_person_email"
                        render={({ message }) =>
                          <div className='form-help'>
                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                            {message ? message : ''}
                          </div>
                        } />
                    </IonCol>


                    {/* Name 2 */}

                    <IonCol size='12'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.vendorContactName2}</IonLabel>
                        <IonInput type="text" {...formGroup.contact_person_secondary_name}></IonInput>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.phone}</IonLabel>
                        <IonInput type="text" {...formGroup.contact_person_secondary_phone}></IonInput>
                      </IonItem>
                    </IonCol>

                    <IonCol size='12' sizeMd='6'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.email}</IonLabel>
                        <IonInput type="email" {...formGroup.contact_person_secondary_email}></IonInput>
                      </IonItem>
                      <ErrorMessage errors={errors} name="contact_person_secondary_email"
                        render={({ message }) =>
                          <div className='form-help'>
                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                            {message ? message : ''}
                          </div>
                        } />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>

            {
              data && (data.company_certificate_reference || data.vat_license_reference || data.contract_reference) ?
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      <IonItem lines='none' className='item-no-padding-all'>
                        <IonIcon icon={documentOutline} slot="start" size='small' />
                        <IonLabel>{TEXT.uploadFileHeader}</IonLabel>
                      </IonItem>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        {/* Name 1 */}
                        <IonCol size='12'>
                          <IonList>
                            {
                              data.company_certificate_reference ?
                                <IonItem lines='none'>
                                  <IonIcon icon={documentOutline} slot="start" size='small' />
                                  <IonLabel>{TEXT.uploadComCerFile}</IonLabel>
                                  <a href={data.company_certificate_reference.path} target="_blank" download>
                                    <IonButton fill="outline" slot='end'>
                                      <IonIcon icon={downloadOutline} slot="start" size='small' />
                                      {BUTTON.download}
                                    </IonButton>
                                  </a>
                                </IonItem>
                                : ''
                            }
                            {
                              data.vat_license_reference ?
                                <IonItem lines='none'>
                                  <IonIcon icon={documentOutline} slot="start" size='small' />
                                  <IonLabel>{TEXT.upload20File}</IonLabel>
                                  <a href={data.vat_license_reference.path} target="_blank" download>
                                    <IonButton fill="outline" slot='end'>
                                      <IonIcon icon={downloadOutline} slot="start" size='small' />
                                      {BUTTON.download}
                                    </IonButton>
                                  </a>
                                </IonItem>
                                : ''
                            }
                            {
                              data.contract_reference ?
                                <IonItem lines='none'>
                                  <IonIcon icon={documentOutline} slot="start" size='small' />
                                  <IonLabel>{TEXT.uploadWarehouseFile}</IonLabel>
                                  <a href={data.contract_reference.path} target="_blank" download>
                                    <IonButton fill="outline" slot='end'>
                                      <IonIcon icon={downloadOutline} slot="start" size='small' />
                                      {BUTTON.download}
                                    </IonButton>
                                  </a>
                                </IonItem>
                                : ''
                            }
                          </IonList>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
                : <></>
            }

            {
              !data ?
                <IonCard>

                  <IonCardHeader>
                    <IonCardTitle>
                      <IonItem lines='none' className='item-no-padding-all'>
                        <IonIcon icon={shieldOutline} slot="start" size='small' />
                        <IonLabel>{TEXT.usernameAndPasswordHeader}</IonLabel>
                      </IonItem>
                    </IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>

                    <IonGrid>
                      <IonRow>

                        <IonCol size='12' sizeMd='12'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.email}<span className='form-required'>*</span></IonLabel>
                            <IonInput type="email" {...formGroup.email}></IonInput>
                          </IonItem>
                          <ErrorMessage errors={errors} name="email"
                            render={({ message }) =>
                              <div className='form-help'>
                                <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                {message ? message : ''}
                              </div>
                            } />
                        </IonCol>

                        <IonCol size='12' sizeMd='6'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.password}<span className='form-required'>*</span></IonLabel>
                            <IonInput type="password" {...formGroup.password} autocomplete="off" ></IonInput>
                          </IonItem>
                          <ErrorMessage errors={errors} name="password"
                            render={({ message }) =>
                              <div className='form-help'>
                                <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                {message ? message : ''}
                              </div>
                            } />
                        </IonCol>

                        <IonCol size='12' sizeMd='6'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.passwordConfirm}<span className='form-required'>*</span></IonLabel>
                            <IonInput type="password" {...formGroup.confirm_password} autocomplete="off" ></IonInput>
                          </IonItem>
                          <ErrorMessage errors={errors} name="confirm_password"
                            render={({ message }) =>
                              <div className='form-help'>
                                <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                {message ? message : ''}
                              </div>
                            } />
                        </IonCol>

                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
                :
                <IonCard>

                  <IonCardHeader>
                    <IonCardTitle>
                      <IonItem lines='none' className='item-no-padding-all'>
                        <IonIcon icon={shieldOutline} slot="start" size='small' />
                        <IonLabel>{TEXT.usernameHeader}</IonLabel>
                      </IonItem>
                    </IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>

                    <IonGrid>
                      <IonRow>

                        <IonCol size='12' sizeMd='12'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.email}<span className='form-required'>*</span></IonLabel>
                            <IonInput type="email" {...formGroup.email} disabled></IonInput>
                          </IonItem>
                          <ErrorMessage errors={errors} name="email"
                            render={({ message }) =>
                              <div className='form-help'>
                                <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                {message ? message : ''}
                              </div>
                            } />
                        </IonCol>

                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
            }

            <IonCard>

              <IonCardHeader>
                <IonCardTitle>
                  <IonItem lines='none' className='item-no-padding-all'>
                    <IonIcon icon={checkmarkCircleOutline} slot="start" size='small' />
                    <IonLabel>{TEXT.status}</IonLabel>
                  </IonItem>
                </IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <IonGrid>
                  <IonRow>
                    <IonCol size='12'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.status}</IonLabel>
                        <IonSelect
                          interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                          value={getValues('active')}
                          {...formGroup.active}
                        >
                          <IonSelectOption value="1">{TEXT.vendorActive}</IonSelectOption>
                          <IonSelectOption value="0">{TEXT.vendorNotActive}</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>

          </form>
          : <></>

      }

    </>
  );

};