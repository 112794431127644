import '../../css/Modal.scss';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonThumbnail,
  IonImg,
  SearchbarCustomEvent
} from "@ionic/react";
import {
  barcodeOutline,
  closeOutline
} from "ionicons/icons";

import { useEffect, useRef, useState } from "react";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import OrderService from "../../services/OrderService";
import moment from 'moment';

export const ModalOrders: React.FC<{
  title: string;
  selected?: any;
  search?: string;
  reference?: any;
  onDismiss: (order?: any, reference?: any) => any;
}> = ({
  title = 'Order',
  selected = null,
  search = '',
  reference = null,
  onDismiss
}) => {

  const barcodeInputRef = useRef<any>(null);

  const pageLimit = 30;

  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [orders, setOrders] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState<string>('');

  const searchOrders = async (search = '', event: any = null) => {
    setSearchText(search);
    let start = 0;
    if (event) {
      start = orders.length;
    }
    OrderService.list(
      true,
      { by: 'order_date', direction: 'desc' },
      start,
      pageLimit,
      'complete',
      search,
      null,
      null,
      ['products', 'product.product']
    ).then((data: any) => {
      if (event) {
        setOrders([
          ...orders,
          ...data
        ]);
        event.target.complete();
        if (!data.length) {
          setInfiniteDisabled(true);
        } else {
          setInfiniteDisabled(false);
        }
      } else {
        setOrders(data);
      }
    });
  }

  const setOrder = (order: any) => {
    onDismiss(order, reference);
  }

  useEffect(() => {
    searchOrders();
    setTimeout(() => {
      if (barcodeInputRef) {
        barcodeInputRef.current.setFocus();
      }
    }, 500);
  }, []);

  return (
    <>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss(selected, reference)}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton className='md-only' onClick={() => onDismiss(selected, reference)}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder={`${TEXT.orderNumber}`}
            inputmode="none"
            ref={barcodeInputRef}
            searchIcon={barcodeOutline}
            onFocus={(e: any) => (e.target as HTMLTextAreaElement).select()}
            onIonChange={(e: SearchbarCustomEvent) => searchOrders(e.detail.value)}
          >
          </IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent className="search">
        <IonList>
          {
            (orders.length && !searchText) ?
              <IonItem button detail={false} onClick={() => setOrder(false)}>
                <IonLabel>{TEXT.order}</IonLabel>
                <IonIcon slot="end" icon={closeOutline} />
              </IonItem>
              : <></>
          }
          {
            orders.map((item, index: number) => (
              <IonItem button detail={false} key={`order-${index}`} onClick={() => setOrder(item)}
                className={selected && selected.id === item.id ? 'selected' : ''}>
                <IonLabel>
                  {item.order_no}
                  {
                    item.order_date ?
                      <div className='subtitle'>{moment.utc(item.order_date).local().format('LL')}</div>
                      : <></>
                  }
                </IonLabel>
              </IonItem>
            ))
          }
        </IonList>

        <IonInfiniteScroll
          onIonInfinite={e => searchOrders(searchText, e)}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>

      </IonContent>

    </>
  );

};