import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import {
  IonContent,
  IonIcon,
  IonLoading,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonNote,
  InputCustomEvent
} from '@ionic/react';
import {
  alertCircleOutline, eyeOffOutline, eyeOutline, logInOutline, personAddOutline
} from 'ionicons/icons';

import { Imagires } from '../components/ImageVariable';
import { Direction, GoBack, RoutePath } from '../components/RoutePath';

import TEXT from '../constances/Text.json';
import MESSAGE from '../constances/Messages.json';
import BUTTON from '../constances/Button.json';
import ContactUs from '../components/ContactUs';
import { PATTERN_EMAIL } from '../constances/RegularExpressions';

import Authentication from '../components/providers/AuthenticationProvider';

import UserService from '../services/UserService';
import StorageService from '../services/StorageService';
import VendorService from '../services/VendorService';
import { AlertStatus } from '../components/alerts/AlertStatus';
import { ToastStatus } from '../components/toasts/ToastStatus';

const Login: React.FC = () => {

  const history = useHistory();
  const { register, setValue, handleSubmit, formState: { errors } } = useForm();

  const authenticationProvider = useContext(Authentication);

  const [ready, setReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [passwordReveal, setPasswordReveal] = useState<boolean>(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  const initialize = async () => {
  }

  const formGroup: any = {
    email: register('email', {
      required: MESSAGE.error.input_required,
      pattern: {
        value: PATTERN_EMAIL,
        message: MESSAGE.error.email_invalid,
      }
    }),
    password: register('password', {
      required: MESSAGE.error.input_required
    })
  }

  const redirectAuthenticated = async () => {
    const token = await StorageService.get('token');
    if (token) {
      UserService.authenticate().then(async () => {
        let members = [];
        const userData: any = await UserService.getSession();
        if (userData) {
          members = userData.members.map(
            ({ group_id }: any) => (
              group_id
            )
          );
        }
        if (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          )
        ) {
          history.replace(`${RoutePath.job}?status=pending`);
        } else {
          history.replace(RoutePath.home);
        }
      }).catch(() => {
        // UserService.logout().catch(() => {});
      });
    }
  }

  const onSubmit = async (value: any) => {

    setShowLoading(true);

    await UserService.login(value.email, value.password).then(async (userData: any) => {
      let members = [];
      authenticationProvider.update(true);
      authenticationProvider.setUser(userData);
      if (userData) {
        members = userData.members.map(
          ({ group_id }: any) => (
            group_id
          )
        );
        await VendorService.getByUser(userData.id).then((vendorData: any) => {
          if (vendorData) {
            authenticationProvider.setVendor(vendorData);
          }
        }).catch(() => { });
      }
      if (
        members.length && (
          members.includes('1')
          || members.includes('2')
          || members.includes('3')
        )
      ) {
        history.replace(`${RoutePath.job}`);
      } else {
        history.replace(RoutePath.home);
      }
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });

    setShowLoading(false);

  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
  }

  useEffect(() => {
    setReady(true);
    redirectAuthenticated();
    return () => {
      setReady(false);
    }
  }, []);

  return (
    <IonPage>
      <IonContent className="ion-padding ion-text-center welcome">

        <IonLoading
          isOpen={showLoading}
          message={TEXT.loginProcess}
        />

        <AlertStatus
          isPresent={presentAlert.isPresent}
          status={presentAlert.status}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonGrid className='container-block'>
          <IonRow className='ion-justify-content-center ion-align-items-center login'>
            <IonCol size="12" size-md="8" size-lg="4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <IonGrid>
                  <IonRow>
                    <IonCol size='12'>
                      <div className="logo">
                        <img src={Imagires.logo}></img>
                      </div>
                      <div className="sub-text">
                        {TEXT.welcome}<br></br>
                        {TEXT.app_name}
                      </div>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size='12'>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.email}</IonLabel>
                        <IonInput type="email" {...formGroup.email}
                          onIonChange={(e: InputCustomEvent) => {
                            setValue('email', e.detail.value);
                          }}></IonInput>
                      </IonItem>
                      <ErrorMessage errors={errors} name="email"
                        render={({ message }) =>
                          <div className='form-help'>
                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                            {message ? message : ''}
                          </div>
                        } />
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size='12'>
                      <IonItem>
                        <IonLabel position="stacked"> {TEXT.password}</IonLabel>
                        <IonInput type={passwordReveal ? 'text' : 'password'}
                          {...formGroup.password}
                          onIonChange={(e: InputCustomEvent) => {
                            setValue('password', e.detail.value);
                          }}
                        >
                        </IonInput>
                        <IonButtons slot="end">
                          <IonButton onClick={() => setPasswordReveal(!passwordReveal)}>
                            <IonIcon slot="icon-only" icon={passwordReveal ? eyeOutline : eyeOffOutline} />
                          </IonButton>
                        </IonButtons>
                      </IonItem>
                      <ErrorMessage errors={errors} name="password"
                        render={({ message }) =>
                          <div className='form-help'>
                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon' color="primary"></IonIcon>
                            {message ? message : ''}
                          </div>
                        } />
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol className='ion-margin-top'>
                      <IonButton type="submit" size='large' fill="solid" shape='round' expand="block" color="primary">
                        <IonIcon slot="start" icon={logInOutline}></IonIcon>
                        {BUTTON.login}
                      </IonButton>
                      <p>
                        <IonButton type="button" routerLink={RoutePath.termCondition} routerDirection={Direction()} fill="clear">
                          <IonIcon slot="start" icon={personAddOutline}></IonIcon>
                          {BUTTON.register}
                        </IonButton>
                      </p>
                    </IonCol>
                  </IonRow>

                  {/* <ContactUs /> */}
                </IonGrid>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
