import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  IonContent,
  IonGrid,
  IonCol,
  IonLabel,
  IonRow,
  IonButton,
  IonIcon,
  IonBadge,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonLoading,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonThumbnail,
  IonImg,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonPopover,
  IonRadioGroup,
  IonRadio,
  IonModal,
} from "@ionic/react";
import {
  copyOutline,
  createOutline,
  printOutline
} from "ionicons/icons";

import moment from 'moment';

import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MESSAGE from '../constances/Messages.json';
import { TOKEN } from '../constances/Credentials';

import { Direction, RoutePath } from "../components/RoutePath";
import { pageTable, pageTableCompact, pageTableExpandable } from "../components/TableStyle";
import { AlertPrompt } from "../components/alerts/AlertPrompt";
import { AlertStatus } from "../components/alerts/AlertStatus";
import { ToastStatus } from "../components/toasts/ToastStatus";

import UserService from "../services/UserService";
import OrderService from "../services/OrderService";
import OrderProductService from "../services/OrderProductService";
import PackService from "../services/PackService";
import StorageService from "../services/StorageService";
import RestService from "../services/RestService";
import { URL_PACK } from "../constances/Rest";

import { ModalProductPreview } from '../components/modal/ProductPreview';

const Order: React.FC<{
  id?: any;
  vendorID?: any;
  displayInitialLoading?: boolean;
  onStatusChange?: (data?: any) => any;
  onCheckPackAvailable?: (data?: any) => any;
  onCheckPacksRequired?: (data?: any) => any;
  onLoad?: (data?: boolean) => any;
  onDidLoad?: (data?: boolean) => any;
  reload?: any;
  className?: string;
}> = ({
  id = null,
  vendorID = null,
  displayInitialLoading = true,
  onStatusChange,
  onCheckPackAvailable,
  onCheckPacksRequired,
  onLoad,
  onDidLoad,
  reload = null,
  className = '',
}) => {

    const defaultSort: any = {
      by: 'create_at',
      direction: 'desc'
    }

    const colors: any = COLORS.order;
    const statuses: any = STATUS.order;
    const colorsProduct: any = COLORS.orderProduct;
    const statusesProduct: any = STATUS.orderProduct;
    const statusesPack: any = STATUS.pack;
    const colorsPack: any = COLORS.pack;

    const [loginToken, setLoginToken] = useState<string>('');
    const [members, setMembers] = useState<Array<any>>([]);
    const [order, setOrder] = useState<any>(null);
    const [status, setStatus] = useState<any>(null);
    const [warehouse, setWarehouse] = useState<any>(null);

    const [ready, setReady] = useState(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState(false);

    const [page, setPage] = useState<number>(1);
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [sort, setSort] = useState<any>(defaultSort);
    const [total, setTotal] = useState<number>(0);

    const [modalProductPreviewIsOpen, setModalProductPreviewIsOpen] = useState<boolean>(false);
    const [idProductPreview, setIdProductPreview] = useState<any>(null);

    const openProductPreviewModal = (id: any) => {
      if (id) {
        setIdProductPreview(id);
        setModalProductPreviewIsOpen(true);
      }
    };

    const { register, getValues, setValue } = useForm();
    const [orderStatus] = useState<any>(
      register('orderStatus', {
        value: '',
        onChange: (e: any) => {
          changeStatus(e.order, e.detail.value, e.ready);
        }
      })
    );

    const [presentToast, setPresentToast] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlert, setPresentAlert] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
      isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, message: '', onDismiss: () => { }
    });

    const columns: Array<any> = [
      {
        id: 'product',
        name: TEXT.productSku,
        selector: (row: {
          product: any, product_reference: any;
        }) => row.product_reference ? row.product_reference.sku : row.product,
        cell: (row: { id: any, product: any, product_reference: any }) => (
          // <IonItem lines="none" detail={false} routerLink={
          //   row.product_reference ?
          //     row.product_reference.active === '2' ?
          //       RoutePath.productImportAdd + '?sku=' + row.product_reference.sku
          //       : RoutePath.productView.replace(':id', row.product_reference.id)
          //     : RoutePath.productView.replace(':id', row.product)
          // } routerDirection={Direction()}>
          <IonItem lines="none" className="pointer" onClick={() => { openProductPreviewModal(row.product_reference.id) }}>
            {
              row.product_reference && row.product_reference.figure_reference && row.product_reference.figure_reference.length ?
                <IonThumbnail slot="end">
                  <IonImg src={
                    row.product_reference.figure_reference[0].thumbnail
                      ? row.product_reference.figure_reference[0].thumbnail
                      : row.product_reference.figure_reference[0].original
                  } />
                </IonThumbnail>
                : <></>
            }
            <IonLabel class="tb-product">
              <div className="name text-full">
                {row.product_reference ? row.product_reference.name : `${TEXT.productNotFound} (${row.product})`}
              </div>
              {
                row.product_reference ?
                  <p className='text-full subtitle'>{row.product_reference.sku}</p>
                  : <></>
              }
            </IonLabel>
          </IonItem>
        ),
        width: '250px',
        sortable: true
      },
      {
        id: 'active',
        name: TEXT.status,
        selector: (row: { active: any }) => row.active,
        cell: (row: { id: any, product: any, active: any, quantity: any, product_reference: any }) =>
        (
          <>
            <IonButtons className="w-100">
              <IonButton className="w-100" id={`status-${row.id}`} fill='solid'
                disabled={status === 'complete' || status === 'cancel' ? true : false}
                color={
                  row.active === false ? colorsProduct['cancel']
                    : !row.product_reference ? colorsProduct['noProduct']
                      : order.packs.length && countPackedProduct(row.product, order.packs) >= parseInt(row.quantity)
                        && row.active === true ? colorsProduct['complete']
                        : row.product_reference && row.product_reference.active === '2' ? colorsProduct['noASN']
                          : row.product_reference && parseInt(row.quantity) - countPackedProduct(row.product, order.packs) > parseInt(row.product_reference.quantity) ? colorsProduct['short']
                            : row.active === true ? colorsProduct['active']
                              : ''
                }>
                {
                  row.active === false ?
                    <IonLabel>{statusesProduct['cancel']}</IonLabel>
                    : !row.product_reference ?
                      <IonLabel>{statusesProduct['noProduct']}</IonLabel>
                      : order.packs.length && countPackedProduct(row.product, order.packs) >= parseInt(row.quantity)
                        && row.active === true ?
                        <IonLabel>{statusesProduct['complete']}</IonLabel>
                        : row.product_reference && row.product_reference.active === '2' ?
                          <IonLabel>{statusesProduct['noASN']}</IonLabel>
                          :
                          row.product_reference && parseInt(row.quantity) - countPackedProduct(row.product, order.packs) > parseInt(row.product_reference.quantity) ?
                            <IonLabel>{statusesProduct['short']}</IonLabel>
                            : row.active === true ?
                              <IonLabel>{statusesProduct['active']}</IonLabel>
                              :
                              <></>
                }
              </IonButton>
            </IonButtons>
            {
              members.length && (
                members.includes('1')
                || members.includes('2')
                || members.includes('3')
              )
                ?
                <IonPopover trigger={`status-${row.id}`} dismissOnSelect={true} side="end">
                  <IonContent>
                    <IonList>
                      <IonRadioGroup value={row.active}>
                        <IonItem lines="none" button={true} detail={false}
                          onClick={() => changeProductStatus(row.id, true)}>
                          {
                            !row.product_reference ?
                              <IonLabel>{statusesProduct['noProduct']}</IonLabel>
                              : order.packs.length && countPackedProduct(row.product, order.packs) >= parseInt(row.quantity)
                                && row.active === true ?
                                <IonLabel>{statusesProduct['complete']}</IonLabel>
                                : row.product_reference && row.product_reference.active === '2' ?
                                  <IonLabel>{statusesProduct['noASN']}</IonLabel>
                                  :
                                  row.product_reference && parseInt(row.quantity) - countPackedProduct(row.product, order.packs) > parseInt(row.product_reference.quantity) ?
                                    <IonLabel>{statusesProduct['short']}</IonLabel>
                                    :
                                    <IonLabel>{statusesProduct['active']}</IonLabel>
                          }
                          <IonRadio slot="start" value={true} />
                        </IonItem>
                        <IonItem lines="none" button={true} detail={false}
                          onClick={() => changeProductStatus(row.id, false)}>
                          <IonLabel>{statusesProduct['cancel']}</IonLabel>
                          <IonRadio slot="start" value={false} />
                        </IonItem>
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonPopover>
                : <></>
            }
          </>
        ),
        width: '150px',
        center: true
      },
      {
        id: 'quantity',
        name: TEXT.qtyPiece,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { quantity: number }) => (
          <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true}
            className="text-number" />
        ),
        right: true,
        sortable: true,
        minWidth: '100px',
      },
      {
        id: 'quantity_parcel',
        name: TEXT.qtyPieceParcel,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { product: any, quantity: any, product_reference: any }) => (
          row.product_reference ?
            <NumberFormat value={
              countPackedProduct(row.product, order.packs)
            } displayType={'text'} thousandSeparator={true}
              className={
                order.packs.length && countPackedProduct(row.product, order.packs) >= parseInt(row.quantity)
                  ? 'text-success text-number' : 'text-danger text-number'
              } />
            : '-'
        ),
        right: true,
        minWidth: '100px',
      },
      {
        id: 'quantity_stock',
        name: TEXT.qtyPieceStock,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { quantity: any, product_reference: any }) => (
          row.product_reference ?
            <NumberFormat value={row.product_reference.quantity} displayType={'text'} thousandSeparator={true}
              className={(row.quantity > row.product_reference.quantity ? 'text-danger' : 'text-success') + ' text-number'} />
            : '-'
        ),
        right: true,
        minWidth: '100px',
      },
      {
        id: 'subtotal_no_vat',
        name: TEXT.subtotalPerPiece,
        selector: (row: { subtotal_no_vat: any }) => row.subtotal_no_vat,
        cell: (row: { subtotal_no_vat: string }) => (
          <NumberFormat value={row.subtotal_no_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}
            className="text-number" />
        ),
        right: true,
        sortable: true,
        minWidth: '100px',
      },
      {
        id: 'total_vat',
        name: TEXT.vatPerPiece,
        selector: (row: { total_vat: any }) => row.total_vat,
        cell: (row: { total_vat: string }) => (
          <NumberFormat value={row.total_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}
            className="text-number" />
        ),
        right: true,
        sortable: true,
        minWidth: '100px',
      },
      {
        id: 'total_with_vat',
        name: TEXT.totalPerPiece,
        selector: (row: { total_with_vat: any }) => row.total_with_vat,
        cell: (row: { total_with_vat: string }) => (
          <NumberFormat value={row.total_with_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}
            className="text-number" />
        ),
        right: true,
        sortable: true,
        minWidth: '100px',
      }
    ];

    const columnsAdmin: Array<any> = [
      {
        id: 'product',
        name: TEXT.productSku,
        selector: (row: {
          product: any, product_reference: any;
        }) => row.product_reference ? row.product_reference.sku : row.product,
        cell: (row: { id: any, product: any, product_reference: any }) => (
          // <IonItem lines="none" detail={false} routerLink={
          //   row.product_reference ?
          //     row.product_reference.active === '2' ?
          //       RoutePath.productImportAdd + '?sku=' + row.product_reference.sku
          //       : RoutePath.productView.replace(':id', row.product_reference.id)
          //     : RoutePath.productView.replace(':id', row.product)
          // } routerDirection={Direction()}>
          <IonItem lines="none" className="pointer" onClick={() => { openProductPreviewModal(row.product_reference.id) }}>
            {
              row.product_reference && row.product_reference.figure_reference && row.product_reference.figure_reference.length ?
                <IonThumbnail slot="end">
                  <IonImg src={
                    row.product_reference.figure_reference[0].thumbnail
                      ? row.product_reference.figure_reference[0].thumbnail
                      : row.product_reference.figure_reference[0].original
                  } />
                </IonThumbnail>
                : <></>
            }
            <IonLabel class="tb-product">
              <div className="name text-full">
                {row.product_reference ? row.product_reference.name : `${TEXT.productNotFound} (${row.product})`}
              </div>
              {
                row.product_reference ?
                  <p className='text-full subtitle'>{row.product_reference.sku}</p>
                  : <></>
              }
            </IonLabel>
          </IonItem>
        ),
        minWidth: '300px',
        sortable: true
      },
      {
        id: 'active',
        name: TEXT.status,
        selector: (row: { active: any }) => row.active,
        cell: (row: { id: any, product: any, active: any, quantity: any, product_reference: any }) =>
        (
          <>
            <IonButtons className="w-100">
              <IonButton className="w-100" id={`status-${row.id}`} fill='solid'
                disabled={status === 'complete' || status === 'cancel' ? true : false}
                color={
                  row.active === false ? colorsProduct['cancel']
                    : !row.product_reference ? colorsProduct['noProduct']
                      : order.packs.length && countPackedProduct(row.product, order.packs) >= parseInt(row.quantity)
                        && row.active === true ? colorsProduct['complete']
                        : row.product_reference && row.product_reference.active === '2' ? colorsProduct['noASN']
                          : row.product_reference && parseInt(row.quantity) - countPackedProduct(row.product, order.packs) > parseInt(row.product_reference.quantity) ? colorsProduct['short']
                            : row.active === true ? colorsProduct['active']
                              : ''
                }>
                {
                  row.active === false ?
                    <IonLabel>{statusesProduct['cancel']}</IonLabel>
                    : !row.product_reference ?
                      <IonLabel>{statusesProduct['noProduct']}</IonLabel>
                      : order.packs.length && countPackedProduct(row.product, order.packs) >= parseInt(row.quantity)
                        && row.active === true ?
                        <IonLabel>{statusesProduct['complete']}</IonLabel>
                        : row.product_reference && row.product_reference.active === '2' ?
                          <IonLabel>{statusesProduct['noASN']}</IonLabel>
                          :
                          row.product_reference && parseInt(row.quantity) - countPackedProduct(row.product, order.packs) > parseInt(row.product_reference.quantity) ?
                            <IonLabel>{statusesProduct['short']}</IonLabel>
                            : row.active === true ?
                              <IonLabel>{statusesProduct['active']}</IonLabel>
                              :
                              <></>
                }
              </IonButton>
            </IonButtons>
            {
              members.length && (
                members.includes('1')
                || members.includes('2')
                || members.includes('3')
              )
                ?
                <IonPopover trigger={`status-${row.id}`} dismissOnSelect={true} side="end">
                  <IonContent>
                    <IonList>
                      <IonRadioGroup value={row.active}>
                        <IonItem lines="none" button={true} detail={false}
                          onClick={() => changeProductStatus(row.id, true)}>
                          {
                            !row.product_reference ?
                              <IonLabel>{statusesProduct['noProduct']}</IonLabel>
                              : order.packs.length && countPackedProduct(row.product, order.packs) >= parseInt(row.quantity)
                                && row.active === true ?
                                <IonLabel>{statusesProduct['complete']}</IonLabel>
                                : row.product_reference && row.product_reference.active === '2' ?
                                  <IonLabel>{statusesProduct['noASN']}</IonLabel>
                                  :
                                  row.product_reference && parseInt(row.quantity) - countPackedProduct(row.product, order.packs) > parseInt(row.product_reference.quantity) ?
                                    <IonLabel>{statusesProduct['short']}</IonLabel>
                                    :
                                    <IonLabel>{statusesProduct['active']}</IonLabel>
                          }
                          <IonRadio slot="start" value={true} />
                        </IonItem>
                        <IonItem lines="none" button={true} detail={false}
                          onClick={() => changeProductStatus(row.id, false)}>
                          <IonLabel>{statusesProduct['cancel']}</IonLabel>
                          <IonRadio slot="start" value={false} />
                        </IonItem>
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonPopover>
                : <></>
            }
          </>
        ),
        width: '150px',
        center: true
      },
      {
        id: 'quantity',
        name: TEXT.qtyPiece,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { quantity: number }) => (
          <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true}
            className="text-number" />
        ),
        right: true,
        sortable: true
      },
      {
        id: 'quantity_parcel',
        name: TEXT.qtyPieceParcel,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { product: any, quantity: any, product_reference: any }) => (
          row.product_reference ?
            <NumberFormat value={
              countPackedProduct(row.product, order.packs)
            } displayType={'text'} thousandSeparator={true}
              className={
                order.packs.length && countPackedProduct(row.product, order.packs) >= parseInt(row.quantity)
                  ? 'text-success text-number' : 'text-danger text-number'
              } />
            : '-'
        ),
        right: true
      },
      {
        id: 'quantity_stock',
        name: TEXT.qtyPieceStock,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { quantity: any, product_reference: any }) => (
          row.product_reference ?
            <NumberFormat value={row.product_reference.quantity} displayType={'text'} thousandSeparator={true}
              className={(row.quantity > row.product_reference.quantity ? 'text-danger' : 'text-success') + ' text-number'} />
            : '-'
        ),
        right: true
      },
      {
        id: 'product_warehouse_reference',
        name: TEXT.location,
        cell: (row: { product_warehouse_reference: any }) => (
          row.product_warehouse_reference &&
            members.length && (
              members.includes('1')
              || members.includes('2')
              || members.includes('3')
            ) ?
            <div>
              {
                row.product_warehouse_reference.map((item: any, index: number) =>
                  <CopyToClipboard key={index} text={item.code} onCopy={() => {
                    setPresentToast({
                      isPresent: true,
                      status: true,
                      message: `${item.code} ${TEXT.wasCopied}`,
                      onDismiss: () => {
                        if (ready) {
                          setPresentToast(
                            {
                              isPresent: false,
                              status: presentToast.status,
                              message: presentToast.message,
                              onDismiss: presentToast.onDismiss
                            }
                          );
                        }
                      }
                    });
                  }}>
                    <IonButton fill='clear'>
                      {item.code}
                      <IonIcon size="small" slot="end" icon={copyOutline} />
                    </IonButton>
                  </CopyToClipboard>
                )
              }
            </div>
            : '-'
        ),
        width: '180px',
        center: true
      },
      {
        id: 'subtotal_no_vat',
        name: TEXT.subtotalPerPiece,
        selector: (row: { subtotal_no_vat: any }) => row.subtotal_no_vat,
        cell: (row: { subtotal_no_vat: string }) => (
          <NumberFormat value={row.subtotal_no_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}
            className="text-number" />
        ),
        right: true,
        sortable: true,
        hide: 'md',
      },
      {
        id: 'total_vat',
        name: TEXT.vatPerPiece,
        selector: (row: { total_vat: any }) => row.total_vat,
        cell: (row: { total_vat: string }) => (
          <NumberFormat value={row.total_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}
            className="text-number" />
        ),
        right: true,
        sortable: true,
        hide: 'md',
      },
      {
        id: 'total_with_vat',
        name: TEXT.totalPerPiece,
        selector: (row: { total_with_vat: any }) => row.total_with_vat,
        cell: (row: { total_with_vat: string }) => (
          <NumberFormat value={row.total_with_vat} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}
            className="text-number" />
        ),
        right: true,
        sortable: true,
        hide: 'md',
      }
    ];

    const columnsPackProducts: Array<any> = [
      {
        name: TEXT.productSku,
        selector: (row: { product: any }) => row.product,
        cell: (row: { product: any, product_reference: any }) => (
          // <IonItem lines="none" detail={false} routerLink={
          //   row.product_reference ?
          //     row.product_reference.active === '2' ?
          //       RoutePath.productImportAdd + '?sku=' + row.product_reference.sku
          //       : RoutePath.productView.replace(':id', row.product_reference.id)
          //     : RoutePath.productView.replace(':id', row.product)
          // } routerDirection={Direction()}>
          <IonItem lines="none" className="pointer" onClick={() => { openProductPreviewModal(row.product_reference.id) }}>
            <IonLabel class="tb-product">
              <div className="name text-full">
                {row.product_reference ? row.product_reference.name : `${TEXT.productNotFound} (${row.product})`}
              </div>
              {
                row.product_reference ?
                  <p className='text-full subtitle'>{row.product_reference.sku}</p>
                  : <></>
              }
            </IonLabel>
          </IonItem>
        ),
        sortable: true,
        minWidth: '400px',
      },
      {
        name: TEXT.qtyPiece,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { quantity: any }) => (
          <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true} className="text-number" />
        ),
        right: true,
        sortable: true,
        minWidth: '150px'
      },
      {
        name: TEXT.lot,
        selector: (row: { product_quantity: any }) => (
          row.product_quantity && row.product_quantity.length ?
            row.product_quantity.map((item: any) =>
              item.asn_product_putaway && item.asn_product_putaway.length ?
                item.asn_product_putaway.map((itemPutaway: any) =>
                  itemPutaway.asn_product_reference && itemPutaway.asn_product_reference.lot_number
                    ? itemPutaway.asn_product_reference.lot_number
                    : ''
                )
                : ''
            )
            : ''
        ),
        cell: (row: { product_quantity: any }) => (
          row.product_quantity && row.product_quantity.length &&
            row.product_quantity.map((item: any) =>
              item.asn_product_putaway && item.asn_product_putaway.length &&
                item.asn_product_putaway.map((itemPutaway: any) =>
                  itemPutaway.asn_product_reference && itemPutaway.asn_product_reference.lot_number ? true : false
                ).includes(true) ? true : false
            ).includes(true) ?
            <div>
              {
                row.product_quantity.map((item: any) =>
                  item.asn_product_putaway && item.asn_product_putaway.length ?
                    item.asn_product_putaway.map((itemPutaway: any, itemIndex: number) =>
                      itemPutaway.asn_product_reference && itemPutaway.asn_product_reference.lot_number ?
                        <CopyToClipboard key={itemIndex} text={itemPutaway.asn_product_reference.lot_number} onCopy={() => {
                          setPresentToast({
                            isPresent: true,
                            status: true,
                            message: `${itemPutaway.asn_product_reference.lot_number} ${TEXT.wasCopied}`,
                            onDismiss: () => {
                              if (ready) {
                                setPresentToast(
                                  {
                                    isPresent: false,
                                    status: presentToast.status,
                                    message: presentToast.message,
                                    onDismiss: presentToast.onDismiss
                                  }
                                );
                              }
                            }
                          });
                        }}>
                          <IonButton fill='clear'>
                            {itemPutaway.asn_product_reference.lot_number}
                            <IonIcon size="small" slot="end" icon={copyOutline} />
                          </IonButton>
                        </CopyToClipboard>
                        : ''
                    )
                    : ''
                )
              }
            </div>
            : '-'
        ),
        center: true,
        sortable: true,
        minWidth: '150px'
      },
      {
        name: TEXT.location,
        selector: (row: { product_quantity: any }) => (
          row.product_quantity && row.product_quantity.length ?
            row.product_quantity.map((item: any) =>
              item.product_warehouse_reference ?
                item.product_warehouse_reference.warehouse + '.'
                + item.product_warehouse_reference.floor + '.'
                + item.product_warehouse_reference.aisle + '.'
                + item.product_warehouse_reference.bay + '.'
                + item.product_warehouse_reference.level + '.'
                + item.product_warehouse_reference.bin
                : ''
            )
            : ''
        ),
        cell: (row: { product_quantity: any }) => (
          row.product_quantity && row.product_quantity.length ?
            <div>
              {
                row.product_quantity.map((item: any, index: number) =>
                  <CopyToClipboard key={index} text={
                    item.product_warehouse_reference ?
                      item.product_warehouse_reference.warehouse + '.'
                      + item.product_warehouse_reference.floor + '.'
                      + item.product_warehouse_reference.aisle + '.'
                      + item.product_warehouse_reference.bay + '.'
                      + item.product_warehouse_reference.level + '.'
                      + item.product_warehouse_reference.bin
                      : ''
                  } onCopy={() => {
                    setPresentToast({
                      isPresent: true,
                      status: true,
                      message: `${item.product_warehouse_reference ?
                        item.product_warehouse_reference.warehouse + '.'
                        + item.product_warehouse_reference.floor + '.'
                        + item.product_warehouse_reference.aisle + '.'
                        + item.product_warehouse_reference.bay + '.'
                        + item.product_warehouse_reference.level + '.'
                        + item.product_warehouse_reference.bin
                        : ''
                        } ${TEXT.wasCopied}`,
                      onDismiss: () => {
                        if (ready) {
                          setPresentToast(
                            {
                              isPresent: false,
                              status: presentToast.status,
                              message: presentToast.message,
                              onDismiss: presentToast.onDismiss
                            }
                          );
                        }
                      }
                    });
                  }}>
                    <IonButton fill='clear'>
                      {
                        item.product_warehouse_reference ?
                          item.product_warehouse_reference.warehouse + '.'
                          + item.product_warehouse_reference.floor + '.'
                          + item.product_warehouse_reference.aisle + '.'
                          + item.product_warehouse_reference.bay + '.'
                          + item.product_warehouse_reference.level + '.'
                          + item.product_warehouse_reference.bin
                          : ''
                      }
                      <IonIcon size="small" slot="end" icon={copyOutline} />
                    </IonButton>
                  </CopyToClipboard>
                )
              }
            </div>
            : ''
        ),
        center: true,
        sortable: true,
        width: '165px',
      }
    ];

    const columnsSuggestions: Array<any> = [
      {
        name: TEXT.location,
        selector: (row: { reference: any }) => (
          row.reference ?
            row.reference.warehouse + '.'
            + row.reference.floor + '.'
            + row.reference.aisle + '.'
            + row.reference.bay + '.'
            + row.reference.level + '.'
            + row.reference.bin
            : ''
        ),
        cell: (row: { reference: any }) => (
          row.reference ?
            <CopyToClipboard text={
              row.reference.warehouse + '.'
              + row.reference.floor + '.'
              + row.reference.aisle + '.'
              + row.reference.bay + '.'
              + row.reference.level + '.'
              + row.reference.bin
            } onCopy={() => {
              setPresentToast({
                isPresent: true,
                status: true,
                message: `${row.reference.warehouse + '.'
                  + row.reference.floor + '.'
                  + row.reference.aisle + '.'
                  + row.reference.bay + '.'
                  + row.reference.level + '.'
                  + row.reference.bin
                  } ${TEXT.wasCopied}`,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            }}>
              <IonButton fill='clear'>
                {
                  row.reference.warehouse + '.'
                  + row.reference.floor + '.'
                  + row.reference.aisle + '.'
                  + row.reference.bay + '.'
                  + row.reference.level + '.'
                  + row.reference.bin
                }
                <IonIcon size="small" slot="end" icon={copyOutline} />
              </IonButton>
            </CopyToClipboard>
            : '-'
        ),
        sortable: true
      },
      {
        name: TEXT.qtyPiece,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { quantity: any }) => (
          <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true} className="text-number" />
        ),
        right: true,
        sortable: true
      },
      {
        name: TEXT.lot,
        selector: (row: { lot_number: any }) => row.lot_number,
        cell: (row: { lot_number: any }) => (
          row.lot_number ?
            <CopyToClipboard text={row.lot_number} onCopy={() => {
              setPresentToast({
                isPresent: true,
                status: true,
                message: `${row.lot_number} ${TEXT.wasCopied}`,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            }}>
              <IonButton fill='clear'>
                {row.lot_number}
                <IonIcon size="small" slot="end" icon={copyOutline} />
              </IonButton>
            </CopyToClipboard>
            : '-'
        ),
        center: true,
        sortable: true
      },
      {
        name: TEXT.manufacturerDate,
        selector: (row: { manufactured_date: any }) => row.manufactured_date,
        cell: (row: { manufactured_date: any }) => (
          row.manufactured_date ?
            <IonItem lines="none" detail={false}>
              <IonLabel className='text-full ion-text-center'>
                {moment.utc(row.manufactured_date).local().format('LL')}
              </IonLabel>
            </IonItem>
            : '-'
        ),
        center: true,
        sortable: true
      },
      {
        name: TEXT.expireDate,
        selector: (row: { expire_date: any }) => row.expire_date,
        cell: (row: { expire_date: any }) => (
          row.expire_date ?
            <IonItem lines="none" detail={false}>
              <IonLabel className='text-full ion-text-center'>
                {moment.utc(row.expire_date).local().format('LL')}
              </IonLabel>
            </IonItem>
            : '-'
        ),
        center: true,
        sortable: true
      }
    ];

    const paginationComponentOptions = {
      rowsPerPageText: TEXT.rowsPerPage,
      rangeSeparatorText: TEXT.rangeSeparator,
      selectAllRowsItemText: TEXT.selectAllRowsItem,
    };

    const initialize = async () => {
      setInitialized(false);
      if (displayInitialLoading) {
        setShowLoading(true);
      }
      setOrder(null);
      setStatus(null);
      setTotal(0);
      const userData: any = await UserService.getSession();
      if (userData) {
        const token: any = await StorageService.get('token');
        setLoginToken(token);
        setMembers(
          userData.members.map(
            ({ group_id }: any) => (
              group_id
            )
          )
        );
        await load(id);
      }
      setInitialized(true);
      onDidLoad && onDidLoad(true);
      setShowLoading(false);
    }

    const load = async (id: string | null | undefined) => {
      if (id) {
        onLoad && onLoad(true);
        await OrderService.get(id, null, [
          'products',
          'product.product',
          'product.product_warehouse',
          'product.suggestion',
          'quantity',
          'packs',
          'pack.courier',
          'pack.products',
          'pack.product.product',
          'pack.product.product_quantity',
          'pack.quantity'
        ]).then(async (data: any) => {
          const setInstrument = async (data: any) => {
            return new Promise(async (resolve) => {
              let i: number = 0;
              for (let pack of data.packs) {
                data.packs[i].cod = 0;
                if (pack.products && pack.products.length) {
                  if (data.cod_total) {
                    let cod = pack.products.map((product: any) => {
                      for (let orderProduct of data.products) {
                        if (orderProduct.product === product.product) {
                          const totalEach = parseFloat(orderProduct.total_with_vat) / parseInt(orderProduct.quantity);
                          return totalEach * parseInt(product.quantity);
                        }
                      }
                    }).reduce((partialSum: any, a: any) => (partialSum ? parseFloat(partialSum) : 0) + (a ? parseFloat(a) : 0), 0);
                    if (i === 0) {
                      data.packs[i].cod = cod + parseFloat(data.shipping_fee);
                    } else {
                      data.packs[i].cod = cod;
                    }
                  }
                }
                i++;
              }
              resolve(data);
            });
          }
          await setInstrument(data).then((data: any) => {
            setOrder(data);
            setStatus(data.status);
            setValue('orderStatus', data.status);
            onCheckPacksRequired && onCheckPacksRequired(checkPacksUpdateRequired(data.packs));
            if (onCheckPackAvailable) {
              if (
                data.products.map((product: any) => {
                  return data.packs.length && countPackedProduct(product.product, data.packs) >= parseInt(product.quantity)
                    ? true : false
                }).includes(false)
              ) {
                onCheckPackAvailable(data.id);
              } else {
                onCheckPackAvailable(false);
              }
            }
          });
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        onLoad && onLoad(false);
      }
    }

    const changeStatus = (order: any, value: any, ready: any) => {
      if (order && order.status !== value) {

        const success = async () => {
          onStatusChange && onStatusChange(value);
          setPresentToast({
            isPresent: true,
            status: true,
            message: MESSAGE.success.complete,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        }

        const patch = async (id: any, status: any) => {
          setShowLoading(true);
          await OrderService.changeStatus(id, status).then(async () => {
            success();
            await load(id);
          }).catch((error) => {
            setValue('orderStatus', order.status);
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
          setShowLoading(false);
        }

        if (value === 'complete' || value === 'cancel') {
          if (value === 'complete' && checkPacksUpdateRequired(order.packs)) {
            setPresentAlert({
              isPresent: true,
              status: false,
              message: MESSAGE.info.packRequiredUpdate,
              onDismiss: () => {
                if (ready) {
                  setValue('orderStatus', order.status);
                  setPresentAlert(
                    {
                      isPresent: false,
                      status: presentAlert.status,
                      message: presentAlertPrompt.message,
                      onDismiss: presentAlertPrompt.onDismiss
                    }
                  );
                }
              }
            });
          } else if (value === 'complete' && checkPacksParcelRequired(order.packs)) {
            setPresentAlert({
              isPresent: true,
              status: false,
              message: MESSAGE.info.packRequiredParcel,
              onDismiss: () => {
                if (ready) {
                  setValue('orderStatus', order.status);
                  setPresentAlert(
                    {
                      isPresent: false,
                      status: presentAlert.status,
                      message: presentAlertPrompt.message,
                      onDismiss: presentAlertPrompt.onDismiss
                    }
                  );
                }
              }
            });
          } else {
            setPresentAlertPrompt({
              isPresent: true,
              message: value === 'complete'
                ? order.products.map((product: any) => {
                  return order.packs.length
                    && countPackedProduct(product.product, order.packs) >= parseInt(product.quantity)
                    ? true : false
                }).includes(false)
                  ? `<h4 class="ion-text-center no-margin">${TEXT.warningPrompt
                  }</h4><br />${MESSAGE.info.orderChangeStatusWarningComplete}`
                  : MESSAGE.info.orderChangeStatusComplete
                : MESSAGE.info.orderChangeStatusCancel,
              onDismiss: (data: boolean) => {
                if (ready) {
                  setPresentAlertPrompt(
                    {
                      isPresent: false,
                      message: presentAlertPrompt.message,
                      onDismiss: presentAlertPrompt.onDismiss
                    }
                  );
                  if (data) {
                    patch(order.id, value);
                  } else {
                    setValue('orderStatus', order.status);
                  }
                }
              }
            });
          }
        } else {
          patch(order.id, value);
        }

      }
    }

    const changeProductStatus = (orderProductID: any, value: any) => {

      const success = async (orderID: any) => {
        if (orderID) {
          await load(orderID);
        }
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (orderProductID: any, status: any) => {
        setShowLoading(true);
        await OrderProductService.changeStatus(orderProductID, status).then(async () => {
          success(order ? order.id : null);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        setShowLoading(false);
      }

      if (value === false) {
        setPresentAlertPrompt({
          isPresent: true,
          message: MESSAGE.info.orderProductChangeStatusCancel,
          onDismiss: (data: boolean) => {
            if (ready) {
              setPresentAlertPrompt(
                {
                  isPresent: false,
                  message: presentAlertPrompt.message,
                  onDismiss: presentAlertPrompt.onDismiss
                }
              );
              if (data) {
                patch(orderProductID, value);
              }
            }
          }
        });
      } else {
        patch(orderProductID, value);
      }

    }

    const changePackStatus = (order: any, pack: any, packID: any, value: any) => {

      if (pack && pack.status !== value) {

        const success = async () => {
          if (order) {
            let index: number = 0;
            for (let item of order.packs) {
              if (item.id === packID) {
                order.packs[index].status = value;
                setOrder(order);
                setStatus(order.status);
                break;
              }
              index++;
            }
          }
          setPresentToast({
            isPresent: true,
            status: true,
            message: MESSAGE.success.complete,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        }

        const patch = async (id: any, status: any) => {
          setShowLoading(true);
          await PackService.status(id, status).then(async () => {
            success();
          }).catch((error) => {
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
          setShowLoading(false);
        }

        patch(packID, value);

      }

    }

    const countPackedProduct = (productID: any, packsData: Array<any> = order.packs) => {
      return packsData.map((pack: any) => {
        return pack.active === true ? pack.products.map((packProduct: any) => {
          return packProduct.product === productID
            ? parseInt(packProduct.quantity) : 0
        }).reduce((partialSum: any, a: any) => partialSum + a, 0) : 0;
      }).reduce((partialSum: any, a: any) => partialSum + a, 0)
    }

    const checkPacksUpdateRequired = (packsData: Array<any>) => {
      return packsData.map((pack: any) => {
        return pack.active;
      }).includes(false);
    }

    const checkPacksParcelRequired = (packsData: Array<any>) => {
      return packsData.map((pack: any) => {
        return pack.status === 'complete' ? true : false;
      }).includes(false);
    }

    useEffect(() => {
      setReady(true);
      return () => {
        setReady(false);
      }
    }, []);

    useEffect(() => {
      initialize();
    }, [id]);

    useEffect(() => {
      if (initialized) {
        load(id);
      }
    }, [reload]);

    return (
      <>

        <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        />

        <AlertStatus
          isPresent={presentAlert.isPresent}
          status={presentAlert.status}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <AlertPrompt
          isPresent={presentAlertPrompt.isPresent}
          message={presentAlertPrompt.message}
          onDismiss={presentAlertPrompt.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonModal
          canDismiss
          swipeToClose
          isOpen={modalProductPreviewIsOpen}
          onDidDismiss={() => setModalProductPreviewIsOpen(false)}
        >
          <ModalProductPreview
            productId={idProductPreview}
            onDismiss={() => setModalProductPreviewIsOpen(false)}
          />
        </IonModal>

        {
          order ?

            <>

              <IonGrid className={className}>
                <IonRow>
                  {
                    initialized ?
                      order.products.map((product: any) => {
                        return countPackedProduct(product.product, order.packs)
                      }).reduce((partialSum: any, a: any) => partialSum + a, 0)
                        >= order.products.map((product: any) => {
                          return product.quantity ? parseInt(product.quantity) : 0;
                        }).reduce((partialSum: any, a: any) => partialSum + a, 0)
                        ?
                        <IonCol size="12">
                          <IonCard class="ion-text-center" color="success">
                            <IonCardContent>
                              <h2>{TEXT.parcelComplete}</h2>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                        :
                        <IonCol size="12">
                          <IonCard class="ion-text-center" color="danger">
                            <IonCardContent>
                              <h2><b>{TEXT.warningText}:</b>&nbsp;{TEXT.parcelLack}</h2>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      : <></>
                  }

                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          <div>
                            {TEXT.order} - {order.id}
                            {
                              order.id ? <>&nbsp;<IonBadge slot="start">&nbsp;{order.order_no}&nbsp;</IonBadge></> : ''
                            }
                          </div>
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>

                            <IonCol size='5' sizeMd="6" sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.orderSource}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='7' sizeMd="6" sizeXl="3">
                              <IonItem lines="none">
                                <IonLabel class="ion-text-wrap" color="dark">{order.source}</IonLabel>
                              </IonItem>
                            </IonCol>

                            <IonCol size='5' sizeMd="6" sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.orderDateCreate}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='7' sizeMd="6" sizeXl="3">
                              <IonItem lines="none">
                                <IonLabel class="ion-text-wrap" color="dark">{order.order_date ? moment.utc(order.order_date).local().format('LLL') : '-'}</IonLabel>
                              </IonItem>
                            </IonCol>

                            <IonCol size='5' sizeMd="6" sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.orderDateDepart}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='7' sizeMd="6" sizeXl="3">
                              <IonItem lines="none">
                                <IonLabel class="ion-text-wrap" color="dark">{order.create_at ? moment.utc(order.create_at).local().format('LLL') : '-'}</IonLabel>
                              </IonItem>
                            </IonCol>

                            <IonCol size='5' sizeMd="6" sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.orderStatus}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='7' sizeMd="6" sizeXl="3">
                              {
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                )
                                  ?
                                  <IonItem className="item-hidden-label">
                                    <IonLabel class="ion-text-wrap">
                                      <IonBadge color={colors[order.status]}>{statuses[order.status]}</IonBadge>
                                    </IonLabel>
                                    <IonLabel class="ion-text-wrap">{TEXT.orderStatus}</IonLabel>
                                    <IonSelect
                                      interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                      {...orderStatus} value={getValues('orderStatus')}
                                      onIonChange={(e: any) => { e.ready = ready; e.order = order; orderStatus.onChange(e) }}>
                                      <IonSelectOption value="pending">{statuses['pending']}</IonSelectOption>
                                      <IonSelectOption value="complete">{statuses['complete']}</IonSelectOption>
                                      <IonSelectOption value="cancel">{statuses['cancel']}</IonSelectOption>
                                    </IonSelect>
                                  </IonItem>
                                  :
                                  <IonItem lines="none">
                                    <IonLabel class="ion-text-wrap">
                                      <IonBadge color={colors[order.status]}>{statuses[order.status]}</IonBadge>
                                    </IonLabel>
                                  </IonItem>
                              }
                            </IonCol>
                          </IonRow>
                        </IonGrid>

                      </IonCardContent>
                    </IonCard>
                  </IonCol>

                </IonRow>
              </IonGrid>

              {
                members.length && (
                  members.includes('1')
                  || members.includes('2')
                  || members.includes('3')
                )
                  ?
                  <IonGrid>
                    <IonRow>
                      <IonCol size='12' sizeMd="6">
                        <IonCard>
                          <IonCardHeader>
                            <IonCardTitle>
                              {TEXT.shippingAddress}
                            </IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent>
                            <dl>
                              <dt><b>{TEXT.name}:</b></dt>
                              <dd>
                                {
                                  order.shipping_first_name || order.shipping_last_name ?
                                    `${order.shipping_first_name} ${order.shipping_last_name}`
                                    : '-'
                                }
                              </dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.address}:</b></dt>
                              <dd>{order.shipping_address ? order.shipping_address : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.subDistrict}:</b></dt>
                              <dd>{order.shipping_subdistrict ? order.shipping_subdistrict : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.city}:</b></dt>
                              <dd>{order.shipping_city ? order.shipping_city : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.province}:</b></dt>
                              <dd>{order.shipping_province ? order.shipping_province : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.postcode}:</b></dt>
                              <dd>{order.shipping_postcode ? order.shipping_postcode : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.phone}:</b></dt>
                              <dd>{order.shipping_phone ? order.shipping_phone : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.pin}:</b></dt>
                              <dd>
                                {
                                  order.shipping_latitude && order.shipping_longitude ?
                                    `${order.shipping_latitude} ${order.shipping_longitude}`
                                    : '-'
                                }
                              </dd>
                            </dl>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                      <IonCol size='12' sizeMd="6">
                        <IonCard>
                          <IonCardHeader>
                            <IonCardTitle>
                              {TEXT.billingAddress}
                            </IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent>
                            <dl>
                              <dt><b>{TEXT.name}:</b></dt>
                              <dd>
                                {
                                  order.billing_first_name || order.billing_last_name ?
                                    `${order.billing_first_name} ${order.billing_last_name}`
                                    : '-'
                                }
                              </dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.address}:</b></dt>
                              <dd>{order.billing_address ? order.billing_address : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.subDistrict}:</b></dt>
                              <dd>{order.billing_subdistrict ? order.billing_subdistrict : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.city}:</b></dt>
                              <dd>{order.billing_city ? order.billing_city : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.province}:</b></dt>
                              <dd>{order.billing_province ? order.billing_province : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.postcode}:</b></dt>
                              <dd>{order.billing_postcode ? order.billing_postcode : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.phone}:</b></dt>
                              <dd>{order.billing_phone ? order.billing_phone : '-'}</dd>
                            </dl>
                            <dl>
                              <dt><b>{TEXT.taxID}:</b></dt>
                              <dd>{order.billing_tax_id ? order.billing_tax_id : '-'}</dd>
                            </dl>
                          </IonCardContent>
                        </IonCard>

                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  : <></>
              }

              {
                initialized ?
                  <>
                    <IonGrid>
                      <h3 className="ion-margin-start">{TEXT.productList}</h3>
                    </IonGrid>

                    {
                      members.length && (
                        members.includes('1')
                        || members.includes('2')
                        || members.includes('3')
                      )
                        ?
                        <DataTable
                          columns={columnsAdmin}
                          data={order.products}
                          customStyles={pageTableExpandable}
                          pagination
                          noDataComponent={TEXT.noDataComponent}
                          paginationComponentOptions={paginationComponentOptions}
                          defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                          defaultSortFieldId={defaultSort.by}
                          expandableRows
                          expandableRowExpanded={() => true}
                          expandableRowsComponent={
                            (row: any) =>
                              <IonCard>
                                <IonCardHeader>
                                  <IonCardTitle>
                                    {TEXT.packingRemark}
                                    {
                                      row.data.product_reference
                                        && row.data.product_reference.lot_control
                                        && row.data.product_reference.lot_control !== '0' ?
                                        <span className="text-danger">
                                          {` *${TEXT.controlLot}`}
                                        </span>
                                        : <></>
                                    }
                                  </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                  {
                                    row.data.product_reference
                                      && (
                                        (row.data.suggestion && row.data.suggestion.length)
                                        || row.data.product_reference.packing_remark
                                      ) ?
                                      <>
                                        {
                                          row.data.product_reference.packing_remark ?
                                            <p>
                                              {row.data.product_reference.packing_remark}
                                            </p>
                                            : <></>
                                        }
                                        {
                                          row.data.suggestion && row.data.suggestion.length ?
                                            <DataTable
                                              columns={columnsSuggestions}
                                              data={row.data.suggestion}
                                              customStyles={pageTableCompact}
                                              pagination
                                              noDataComponent={TEXT.noDataComponent}
                                              paginationComponentOptions={paginationComponentOptions}
                                            />
                                            : <></>
                                        }
                                      </>
                                      :
                                      <p>
                                        {TEXT.notSpecfic}
                                      </p>
                                  }
                                </IonCardContent>
                              </IonCard>
                          }
                        />
                        :
                        <DataTable
                          columns={columns}
                          data={order.products}
                          customStyles={pageTable}
                          pagination
                          noDataComponent={TEXT.noDataComponent}
                          paginationComponentOptions={paginationComponentOptions}
                          defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                          defaultSortFieldId={defaultSort.by}
                        />
                    }

                    {
                      order.products ?
                        <IonGrid>
                          <IonRow>
                            <IonCol size='12' sizeMd="6">
                              <IonCard>
                                <IonCardContent>
                                  <dl className="medium">
                                    <dt>{TEXT.sumQty}:</dt>
                                    <dd className="ion-text-right">
                                      <NumberFormat displayType={'text'} thousandSeparator={true} value={
                                        order.products.length ?
                                          order.products.map((product: any) => {
                                            return product.quantity ? parseInt(product.quantity) : 0;
                                          }).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                          : 0
                                      }
                                      />
                                    </dd>
                                  </dl>

                                  <dl className="medium">
                                    <dt>{TEXT.sumParcelQty}:</dt>
                                    <dd className="ion-text-right">
                                      <NumberFormat displayType={'text'} thousandSeparator={true}
                                        className={
                                          order.products.map((product: any) => {
                                            return countPackedProduct(product.product, order.packs)
                                          }).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                            >= order.products.map((product: any) => {
                                              return product.quantity ? parseInt(product.quantity) : 0;
                                            }).reduce((partialSum: any, a: any) => partialSum + a, 0) ? 'text-success' : 'text-danger'
                                        }
                                        value={
                                          order.products.map((product: any) => {
                                            return countPackedProduct(product.product, order.packs)
                                          }).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                        }
                                      />
                                    </dd>
                                  </dl>

                                  <dl className="medium">
                                    <dt>{TEXT.paymentMethod}:</dt>
                                    <dd className="ion-text-right">{order.payment_method ? order.payment_method : '-'}</dd>
                                  </dl>

                                  <dl className="medium">
                                    <dt>{TEXT.cod}:</dt>
                                    <dd className="ion-text-right">
                                      <NumberFormat displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={
                                        order.cod_total ? order.cod_total : 0
                                      } />
                                    </dd>
                                  </dl>

                                  <dl className="medium">
                                    <dt>{TEXT.shippingMethod}:</dt>
                                    <dd className="ion-text-right">{order.shipping_method ? order.shipping_method : '-'}</dd>
                                  </dl>

                                </IonCardContent>
                              </IonCard>
                            </IonCol>
                            <IonCol size='12' sizeMd="6">
                              <IonCard>
                                <IonCardContent>
                                  <dl className="medium">
                                    <dt>{TEXT.productSubtotal}:</dt>
                                    <dd className="ion-text-right">
                                      <NumberFormat displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={
                                        order.products.length ?
                                          order.products.map((product: any) => {
                                            return product.subtotal_with_vat ? parseFloat(product.subtotal_with_vat) : 0;
                                          }).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                          : 0
                                      }
                                      />
                                    </dd>
                                  </dl>

                                  <dl className="medium">
                                    <dt>{TEXT.productDiscount}:</dt>
                                    <dd className="ion-text-right">
                                      <NumberFormat displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={
                                        order.products.length ?
                                          order.products.map((product: any) => {
                                            return product.discount ? parseFloat(product.discount) : 0;
                                          }).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                          : 0
                                      }
                                      />
                                    </dd>
                                  </dl>

                                  <dl className="medium">
                                    <dt><b>{TEXT.productTotal}:</b></dt>
                                    <dd className="ion-text-right">
                                      <NumberFormat displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={
                                        order.products.length ?
                                          order.products.map((product: any) => {
                                            return product.total_with_vat ? parseFloat(product.total_with_vat) : 0;
                                          }).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                          : 0
                                      }
                                      />
                                    </dd>
                                  </dl>

                                  <dl className="medium">
                                    <dt>{TEXT.shippingTotal}:</dt>
                                    <dd className="ion-text-right">
                                      <NumberFormat displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={
                                        order.shipping_fee ? order.shipping_fee : 0
                                      } />
                                    </dd>
                                  </dl>

                                  <dl className="medium">
                                    <dt><b>{TEXT.total}:</b></dt>
                                    <dd className="ion-text-right">
                                      <NumberFormat displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={
                                        order.products.length ?
                                          order.products.map((product: any) => {
                                            return product.total_with_vat ? parseFloat(product.total_with_vat) : 0;
                                          }).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                          + (order.shipping_fee ? parseFloat(order.shipping_fee) : 0)
                                          : 0
                                      }
                                      />
                                    </dd>
                                  </dl>
                                </IonCardContent>
                              </IonCard>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonGrid>

                                {
                                  order.remark ?
                                    <IonRow className="row-cards">
                                      <IonCol size='12'>
                                        <IonCard>
                                          <IonCardHeader>
                                            <IonCardTitle>
                                              {TEXT.note}
                                            </IonCardTitle>
                                          </IonCardHeader>
                                          <IonCardContent>
                                            {order.remark ? order.remark : '-'}
                                          </IonCardContent>
                                        </IonCard>
                                      </IonCol>
                                    </IonRow>
                                    : <></>
                                }

                                {/* Packs */}
                                {
                                  order.packs.length ?
                                    order.packs.map((data: any, i: number) => {
                                      return (
                                        <IonRow key={`pack-${i}`} className="row-cards">
                                          <IonCol size='12'>
                                            <IonCard>
                                              <IonToolbar mode="md">
                                                <IonTitle slot='start' color={data.active === true ? '' : 'danger'}>
                                                  <h4 className="text-full">
                                                    {
                                                      data.active === true ?
                                                        '' : '* '
                                                    }
                                                    {TEXT.package} {i + 1} / {order.packs.length}
                                                    {
                                                      !members.length || (
                                                        !members.includes('1')
                                                        && !members.includes('2')
                                                        && !members.includes('3')
                                                      ) &&
                                                        data.active !== true ?
                                                        ` (${BUTTON.editRequire})`
                                                        : ''
                                                    }
                                                  </h4>
                                                </IonTitle>
                                                {
                                                  members.length && (
                                                    members.includes('1')
                                                    || members.includes('2')
                                                    || members.includes('3')
                                                  )
                                                    ?
                                                    <IonButtons slot='end'>
                                                      <IonButton fill='clear' color="primary" className="ion-hide-sm-down"
                                                        onClick={() => {
                                                          window.open(
                                                            RestService.route(`${URL_PACK}${data.id}/label?a=${loginToken}&t=${TOKEN}`),
                                                            'popUpWindow',
                                                            'height=600,width=480,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                                                          );
                                                        }}>
                                                        <IonIcon icon={printOutline} slot="start" />
                                                        <IonLabel>{BUTTON.shippingLabel}</IonLabel>
                                                      </IonButton>
                                                      <IonButton fill='clear' color="primary" className="ion-hide-sm-up"
                                                        onClick={() => {
                                                          window.open(
                                                            RestService.route(`${URL_PACK}${data.id}/label?a=${loginToken}&t=${TOKEN}`),
                                                            'popUpWindow',
                                                            'height=600,width=480,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                                                          );
                                                        }}>
                                                        <IonIcon icon={printOutline} slot="icon-only" />
                                                      </IonButton>
                                                      <IonButton fill='clear' color={data.active === true ? 'primary' : 'danger'}
                                                       className="ion-hide-sm-down"
                                                        routerLink={RoutePath.orderPackEdit.replace(':id', order.id).replace(':packID', data.id)}
                                                        routerDirection={Direction()}
                                                        disabled={status === 'complete' || status === 'cancel' ? true : false}>
                                                        <IonIcon icon={createOutline} slot="start" />
                                                        <IonLabel>
                                                          {
                                                            data.active === true ?
                                                              BUTTON.edit : BUTTON.editRequire
                                                          }
                                                        </IonLabel>
                                                      </IonButton>
                                                      <IonButton fill='clear' color={data.active === true ? 'primary' : 'danger'}
                                                       className="ion-hide-sm-up"
                                                        routerLink={RoutePath.orderPackEdit.replace(':id', order.id).replace(':packID', data.id)}
                                                        routerDirection={Direction()}
                                                        disabled={status === 'complete' || status === 'cancel' ? true : false}>
                                                        <IonIcon icon={createOutline} slot="icon-only" />
                                                      </IonButton>
                                                    </IonButtons>
                                                    : <></>
                                                }
                                              </IonToolbar>
                                              <IonCardContent>
                                                <IonRow>

                                                  <IonCol size="12" sizeMd="6">
                                                    <dl className="medium">
                                                      <dt>{TEXT.courier}:</dt>
                                                      <dd className="ion-text-right">{data.courier_reference ? data.courier_reference.name : '-'}
                                                      </dd>
                                                    </dl>
                                                    <dl className="medium">
                                                      <dt>{TEXT.trackingNo}</dt>
                                                      <dd className="ion-text-right">{data.tracking_no}</dd>
                                                    </dl>
                                                    <dl className="medium">
                                                      <dt>{TEXT.packageLPNo}</dt>
                                                      <dd className="ion-text-right">{`PACK-${data.id}`}</dd>
                                                    </dl>
                                                    {
                                                      !members.length || (
                                                        !members.includes('1')
                                                        && !members.includes('2')
                                                        && !members.includes('3')
                                                      )
                                                        ?
                                                        <dl className="medium">
                                                          <dt>{TEXT.parcelStatus}</dt>
                                                          <dd className="ion-text-right">
                                                            <IonBadge color={colorsPack[data.status]}>{statusesPack[data.status]}</IonBadge>
                                                          </dd>
                                                        </dl>
                                                        :
                                                        <></>
                                                    }
                                                  </IonCol>

                                                  <IonCol size="12" sizeMd="6">
                                                    <dl className="medium">
                                                      <dt>{TEXT.packSize}</dt>
                                                      <dd className="ion-text-right">{TEXT.packW} = {data.dimension_width}, {TEXT.packD} = {data.dimension_depth}, {TEXT.packH} = {data.dimension_height}</dd>
                                                    </dl>
                                                    <dl className="medium">
                                                      <dt>{TEXT.packWeight}</dt>
                                                      <dd className="ion-text-right"><NumberFormat value={data.weight} displayType={'text'} thousandSeparator={true} /></dd>
                                                    </dl>
                                                    {
                                                      data.products && data.products.length ?
                                                        <>
                                                          <dl className="medium">
                                                            <dt>{TEXT.packItemQty}</dt>
                                                            <dd className="ion-text-right"><NumberFormat value={data.quantity} displayType={'text'} thousandSeparator={true} /></dd>
                                                          </dl>
                                                          {
                                                            data.cod ?
                                                              <dl className="medium">
                                                                <dt>{TEXT.packCOD}</dt>
                                                                <dd className="ion-text-right"><NumberFormat value={data.cod} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></dd>
                                                              </dl>
                                                              : <></>
                                                          }
                                                        </>
                                                        : <></>
                                                    }
                                                  </IonCol>

                                                  <IonCol size='12'>
                                                    {
                                                      members.length && (
                                                        members.includes('1')
                                                        || members.includes('2')
                                                        || members.includes('3')
                                                      )
                                                        ?
                                                        <IonCard color={data.status === "complete" ? '' : "warning"}>
                                                          <IonCardContent>
                                                            <IonItem className="ion-no-padding ion-no-margin">
                                                              <IonLabel position="stacked">{TEXT.parcelStatus}</IonLabel>
                                                              <IonSelect
                                                                interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                                                placeholder={TEXT.parcelStatus}
                                                                value={data.status}
                                                                disabled={status === 'complete' || status === 'cancel' ? true : false}
                                                                onIonChange={(e) => { changePackStatus(order, data, data.id, e.detail.value); }}
                                                              >
                                                                {
                                                                  Object.keys(statusesPack).map((key: any, index: number) => (
                                                                    <IonSelectOption key={`pack-status-${index}`} value={key}>{statusesPack[key]}</IonSelectOption>
                                                                  ))
                                                                }
                                                              </IonSelect>
                                                            </IonItem>
                                                          </IonCardContent>
                                                        </IonCard>
                                                        :
                                                        <></>
                                                    }
                                                  </IonCol>

                                                </IonRow>
                                              </IonCardContent>

                                              {
                                                data.products ?
                                                  <DataTable
                                                    columns={columnsPackProducts}
                                                    customStyles={pageTable}
                                                    data={data.products}
                                                    pagination
                                                    noDataComponent={TEXT.noDataComponent}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                  />
                                                  : <></>
                                              }

                                            </IonCard>
                                          </IonCol>
                                        </IonRow>
                                      )
                                    })
                                    : <></>
                                }

                              </IonGrid>
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                        : <></>
                    }
                  </>
                  : <></>
              }

            </>

            : <></>
        }

      </>
    );
  }

export default Order;