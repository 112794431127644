import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import Barcode from "react-hooks-barcode";

import {
  IonContent,
  IonGrid,
  IonCol,
  IonLabel,
  IonRow,
  IonButton,
  IonIcon,
  IonBadge,
  IonButtons,
  IonToolbar,
  IonLoading,
  useIonModal,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonThumbnail,
  IonImg,
  IonAccordionGroup,
  IonAccordion,
  IonChip,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonList,
  IonPopover,
  IonRadioGroup,
  IonRadio,
  IonInput,
  IonCardContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonModal,
  SearchbarCustomEvent,
  IonSpinner,
  IonAlert
} from "@ionic/react";
import {
  cloudUploadOutline,
  copyOutline,
  cubeOutline,
  downloadOutline
} from "ionicons/icons";

import moment from 'moment';
import XLSX from 'sheetjs-style';

import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MESSAGE from '../constances/Messages.json';
import MENU from '../constances/Menu.json';
import CONTACT from '../constances/Contact.json';

import { Direction, RoutePath } from "../components/RoutePath";
import { pageTable } from "../components/TableStyle";
import { AlertPrompt } from "../components/alerts/AlertPrompt";
import { AlertStatus } from "../components/alerts/AlertStatus";
import { ToastStatus } from "../components/toasts/ToastStatus";
import { ModalASNPutAwayImport } from "../components/modal/ASNPutAwayImport";
import { ModalASNImport } from "./modal/ASNImport";
import { ModalScanner } from "../components/modal/Scanner";
import { ModalBarcode } from "../components/modal/Barcode";

import UserService from "../services/UserService";
import ASNService from "../services/ASNService";
import ASNProductService from "../services/ASNProductService";
import { ModalLocation } from "./modal/Location";
import { ModalProductPreview } from "./modal/ProductPreview";
import { ModalCounted } from "./modal/Counted";
import ASNProductPutawayService from "../services/ASNProductPutawayService";

const ASN: React.FC<{
  id?: any;
  doChangeStatus?: any;
  doCheck?: any;
  displayInitialLoading?: boolean;
  onStatusChange?: (data?: any) => any;
  onCancelStatusChange?: (data?: any) => any;
  onDidLoad?: (data?: boolean) => any;
  onDidScannerReady?: (data?: boolean) => any;
  onLoad?: (data?: boolean) => any;
  reload?: any;
  className?: string;
  scannerIsOpen?: boolean;
  barcodeIsOpen?: boolean;
}> = ({
  id = null,
  doChangeStatus = null,
  doCheck = null,
  displayInitialLoading = true,
  onStatusChange,
  onCancelStatusChange,
  onDidLoad,
  onDidScannerReady,
  onLoad,
  reload = null,
  className = '',
  scannerIsOpen = false,
  barcodeIsOpen = false
}) => {

    const offset: number = 50;
    const defaultSort: any = {
      by: 'lack',
      direction: 'desc'
    }
    const columnsReport: any = {
      'SKU': true,
      'BARCODE': true,
      'NAME': true,
      'QUANTITY': true,
      'COUNT': true,
      'LACK': true,
      'OVER': true,
      'EXPIRE': true,
      'LOT': true,
      'STATUS': true
    }
    const columnsPutaway: any = {
      'LOCATION': true,
      'BARCODE': true,
      'SKU': true,
      'QUANTITY': true
    }
    const columnsReceipt: any = {
      'SKU': true,
      'BARCODE': true,
      'NAME': true,
      'QUANTITY': true
    }

    const colors: any = COLORS.asn;
    const statuses: any = STATUS.asn;
    const colorsProduct: any = COLORS.asnProduct;
    const statusesProduct: any = STATUS.asnProduct;

    const [members, setMembers] = useState<Array<any>>([]);
    const [asn, setASN] = useState<any>(null);
    const [products, setProducts] = useState<any>([]);
    const [productsAll, setProductsAll] = useState<any>([]);
    const [productsChecks, setProductsChecks] = useState<any>([]);
    const [status, setStatus] = useState<any>(null);
    const [asnLocation, setASNLocation] = useState<any>(true);
    const [warehouse, setWarehouse] = useState<any>(null);

    const [ready, setReady] = useState(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showProgressLoading, setShowProgressLoading] = useState(false);
    const [importResult, setImportResult] = useState<any>(null);
    const [focusBarcode, setFocusBarcode] = useState<boolean>(false);
    const [downloadPrompt, setDownloadPrompt] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [sort, setSort] = useState<any>(defaultSort);
    const [total, setTotal] = useState<number>(0);
    const [totalAll, setTotalAll] = useState<number>(0);
    const [totalCounted, setTotalCounted] = useState<number>(0);
    const [totalLack, setTotalLack] = useState<number>(0);
    const [totalOver, setTotalOver] = useState<number>(0);
    const [totalPending, setTotalPending] = useState<number>(0);
    const [totalComplete, setTotalComplete] = useState<number>(0);
    const [totalEmpty, setTotalEmpty] = useState<number>(0);

    const [modalScannerIsOpen, setModalScannerIsOpen] = useState<boolean>(false);
    const [modalBarcodeIsOpen, setModalBarcodeIsOpen] = useState<boolean>(false);
    const [modalLocationData, setModalLocationData] = useState<any>(null);
    const [modalCountedData, setModalCountedData] = useState<any>(null);
    const [scannerReady, setScannerReady] = useState<boolean>(false);

    const [modalProductPreviewIsOpen, setModalProductPreviewIsOpen] = useState<boolean>(false);
    const [idProductPreview, setIdProductPreview] = useState<any>(null);

    const openProductPreviewModal = (id: any) => {
      if (id) {
        setIdProductPreview(id);
        setModalProductPreviewIsOpen(true);
      }
    };

    const { register, getValues, setValue } = useForm();
    const [asnStatus] = useState<any>(
      register('asnStatus', {
        value: '',
        onChange: (e: any) => {
          changeStatus(e.asn, e.detail.value, e.ready);
        }
      })
    );
    const [asnReceiveDate] = useState<any>(
      register('asnReceiveDate', {
        value: ''
      })
    );
    const [asnExpectDate] = useState<any>(
      register('asnExpectDate', {
        value: ''
      })
    );
    const [filterSearch] = useState<any>(
      register('search', {
        value: ''
      })
    );
    const [filterStatus] = useState<any>(
      register('status', {
        value: '',
        onChange: (e: any) => {
          loadProducts(e.asnID, 0, pageLimit, sort.by, sort.direction, getValues('search'), e.detail.value);
        }
      })
    );

    const [presentToast, setPresentToast] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlert, setPresentAlert] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
      isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, message: '', onDismiss: () => { }
    });

    const columnsProducts: Array<any> = [
      {
        id: 'barcode',
        name: TEXT.barcode,
        selector: (row: { barcode: any }) => row.barcode,
        cell: (row: { product_reference: any }) => (
          <div className='barcode ion-text-center'>
            <Barcode value={row.product_reference ? row.product_reference.barcode : ''} />
          </div>
        ),
        width: '150px',
        center: true,
        sortable: true,
      },
      {
        id: 'product.sku',
        name: TEXT.productHeaderNameSKU,
        selector: (row: {
          product: any, product_reference: any
        }) => row.product_reference ? row.product_reference.sku : row.product,
        cell: (row: { product: any, product_reference: any }) => (
          // <IonItem lines="none" routerLink={
          //   row.product_reference ? RoutePath.productView.replace(':id', row.product_reference.id)
          //     : null
          // } routerDirection={Direction()}>
          <IonItem lines="none" className="pointer" onClick={() => { openProductPreviewModal(row.product_reference.id) }}>
            {
              row.product_reference && row.product_reference.figure_reference && row.product_reference.figure_reference.length ?
                <IonThumbnail slot="end">
                  <IonImg src={
                    row.product_reference.figure_reference[0].thumbnail
                      ? row.product_reference.figure_reference[0].thumbnail
                      : row.product_reference.figure_reference[0].original
                  } />
                </IonThumbnail>
                : <></>
            }
            <IonLabel>
              <h2 className='text-full'>
                {row.product_reference ? row.product_reference.name : `${TEXT.productNotFound} (${row.product})`}
              </h2>
              {
                row.product_reference ?
                  <p className='text-full subtitle'>{row.product_reference.sku}</p>
                  : <></>
              }
            </IonLabel>
          </IonItem>
        ),
        width: '250px',
        sortable: true
      },
      {
        id: 'asn_product.quantity',
        name: TEXT.qtyPiece,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { id: any, quantity: any }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true}
                  className="text-number" />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true}
              className="text-number" />
        ),
        right: true,
        sortable: true
      },
      {
        id: 'asn_product_putaway.quantity',
        name: TEXT.qtyCount,
        selector: (row: { counted: number }) => row.counted,
        cell: (row: { id: any, quantity: number, counted: number }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.counted} displayType={'text'} thousandSeparator={true}
                  className={(row.quantity - row.counted > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.counted} displayType={'text'} thousandSeparator={true}
              className={(row.quantity - row.counted > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
        ),
        right: true,
        sortable: true
      },
      {
        id: 'lack',
        name: TEXT.qtyLack,
        selector: (row: { lack: any }) => (row.lack),
        cell: (row: { id: any, lack: any }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.lack} displayType={'text'} thousandSeparator={true}
                  className={(row.lack > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.lack} displayType={'text'} thousandSeparator={true}
              className={(row.lack > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
        ),
        right: true,
        sortable: true
      },
      {
        id: 'over',
        name: TEXT.qtyOver,
        selector: (row: { over: any }) => (row.over),
        cell: (row: { id: any, over: any }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.over} displayType={'text'} thousandSeparator={true}
                  className={(row.over > 0 ? 'text-danger' : 'text-success') + ' text-number ion-text-right'} />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.over} displayType={'text'} thousandSeparator={true}
              className={(row.over > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
        ),
        right: true,
        sortable: true
      },
      {
        id: 'asn_product.expire_date',
        name: TEXT.expireDate,
        selector: (row: { expire_date: any }) => row.expire_date,
        cell: (row: { expire_date: any }) =>
        (
          row.expire_date
            ? moment.utc(row.expire_date).local().format('LL')
            : '-'
        ),
        center: true,
        sortable: true
      },
      {
        id: 'asn_product.lot_number',
        name: TEXT.lotNumber,
        selector: (row: { lot_number: any }) => row.lot_number,
        cell: (row: { lot_number: any }) => row.lot_number ? row.lot_number : '-',
        center: true,
        sortable: true
      },
      {
        id: 'asn_product.status,asn_product.active',
        name: TEXT.status,
        selector: (row: { status: any }) => row.status,
        cell: (row: { id: any, status: any, active: any }) =>
        (
          <>
            <IonButtons className="w-100">
              <IonButton className="w-100" id={`status-${row.id}`} fill='solid' color={
                row.status === 'pending' && !row.active ?
                  'warning' : colorsProduct[row.status]
              }
                disabled={status === 'complete' || status === 'cancel' ? true : false}
                onClick={
                  () => {
                    {
                      !members.length || (
                        !members.includes('1')
                        && !members.includes('2')
                        && !members.includes('3')
                      )
                        && setValue('status', row.status === 'pending' && !row.active ? 'empty' : row.status)
                    }
                  }
                }>
                <IonLabel>{
                  row.status === 'pending' && !row.active ?
                    TEXT.asnProductNotFound : statusesProduct[row.status]
                }</IonLabel>
              </IonButton>
            </IonButtons>
            {
              members.length && (
                members.includes('1')
                || members.includes('2')
                || members.includes('3')
              )
                ?
                <IonPopover trigger={`status-${row.id}`} dismissOnSelect={true} side="end">
                  <IonContent>
                    <IonList>
                      <IonRadioGroup value={row.status}>
                        {
                          Object.keys(statusesProduct).map((key: any, index: number) => (
                            key !== 'counted' ?
                              <IonItem key={`status-option-${index}`} lines="none" button={true} detail={false}
                                onClick={() => changeProductStatus(row.id, key)}
                              >
                                <IonLabel>{statusesProduct[key]}</IonLabel>
                                <IonRadio slot="start" value={key} />
                              </IonItem>
                              :
                              <IonItem key={`status-option-${index}`} lines="none" button={true} detail={false}
                                onClick={() => setModalCountedData(row.id)}
                              >
                                <IonLabel>{statusesProduct[key]}</IonLabel>
                                <IonRadio slot="start" value={key} />
                              </IonItem>
                          ))
                        }
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonPopover>
                : <></>
            }
          </>
        ),
        width: '150px',
        center: true,
        sortable: true
      }
    ];
    const columnsProductsAdmin: Array<any> = [
      {
        id: 'barcode',
        name: TEXT.barcode,
        selector: (row: { barcode: any }) => row.barcode,
        cell: (row: { product_reference: any }) => (
          <div className='barcode ion-text-center'>
            <Barcode value={row.product_reference ? row.product_reference.barcode : ''} />
          </div>
        ),
        width: '150px',
        center: true,
        sortable: true,
      },
      {
        id: 'product.sku',
        name: TEXT.productHeaderNameSKU,
        selector: (row: {
          product: any, product_reference: any
        }) => row.product_reference ? row.product_reference.sku : row.product,
        cell: (row: { product: any, product_reference: any }) => (
          // <IonItem lines="none" routerLink={
          //   row.product_reference ? RoutePath.productView.replace(':id', row.product_reference.id)
          //     : null
          // } routerDirection={Direction()}>
          <IonItem lines="none" className="pointer" onClick={() => { openProductPreviewModal(row.product_reference.id) }}>
            {
              row.product_reference && row.product_reference.figure_reference && row.product_reference.figure_reference.length ?
                <IonThumbnail slot="end">
                  <IonImg src={
                    row.product_reference.figure_reference[0].thumbnail
                      ? row.product_reference.figure_reference[0].thumbnail
                      : row.product_reference.figure_reference[0].original
                  } />
                </IonThumbnail>
                : <></>
            }
            <IonLabel>
              <h2 className='text-full'>
                {row.product_reference ? row.product_reference.name : `${TEXT.productNotFound} (${row.product})`}
              </h2>
              {
                row.product_reference ?
                  <p className='text-full subtitle'>{row.product_reference.sku}</p>
                  : <></>
              }
            </IonLabel>
          </IonItem>
        ),
        width: '250px',
        sortable: true
      },
      {
        id: 'asn_product.quantity',
        name: TEXT.qtyPiece,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { id: any, quantity: any }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true}
                  className="text-number" />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true}
              className="text-number" />
        ),
        right: true,
        sortable: true
      },
      {
        id: 'asn_product_putaway.quantity',
        name: TEXT.qtyCount,
        selector: (row: { counted: number }) => row.counted,
        cell: (row: { id: any, quantity: number, counted: number }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.counted} displayType={'text'} thousandSeparator={true}
                  className={(row.quantity - row.counted > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.counted} displayType={'text'} thousandSeparator={true}
              className={(row.quantity - row.counted > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
        ),
        right: true,
        sortable: true
      },
      {
        id: 'lack',
        name: TEXT.qtyLack,
        selector: (row: { lack: any }) => (row.lack),
        cell: (row: { id: any, lack: any }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.lack} displayType={'text'} thousandSeparator={true}
                  className={(row.lack > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.lack} displayType={'text'} thousandSeparator={true}
              className={(row.lack > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
        ),
        right: true,
        sortable: true
      },
      {
        id: 'over',
        name: TEXT.qtyOver,
        selector: (row: { over: any }) => (row.over),
        cell: (row: { id: any, over: any }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.over} displayType={'text'} thousandSeparator={true}
                  className={(row.over > 0 ? 'text-danger' : 'text-success') + ' text-number ion-text-right'} />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.over} displayType={'text'} thousandSeparator={true}
              className={(row.over > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
        ),
        right: true,
        sortable: true
      },
      {
        id: 'asn_product.expire_date',
        name: TEXT.expireDate,
        selector: (row: { expire_date: any }) => row.expire_date,
        cell: (row: { expire_date: any }) =>
        (
          row.expire_date
            ? moment.utc(row.expire_date).local().format('LL')
            : '-'
        ),
        center: true,
        sortable: true
      },
      {
        id: 'asn_product.lot_number',
        name: TEXT.lotNumber,
        selector: (row: { lot_number: any }) => row.lot_number,
        cell: (row: { lot_number: any }) => row.lot_number ? row.lot_number : '-',
        center: true,
        sortable: true
      },
      {
        id: 'asn_product.locations',
        name: TEXT.location,
        selector: (row: { locations: any }) => row.locations.join(','),
        cell: (row: { id: any, locations: any }) => (
          row.locations ?
            <div>
              {
                row.locations.map((item: any, index: number) =>
                  <CopyToClipboard key={`location-${row.id}-${index}`}
                    text={item} onCopy={() => {
                      setPresentToast({
                        isPresent: true,
                        status: true,
                        message: `${item} ${TEXT.wasCopied}`,
                        onDismiss: () => {
                          if (ready) {
                            setPresentToast(
                              {
                                isPresent: false,
                                status: presentToast.status,
                                message: presentToast.message,
                                onDismiss: presentToast.onDismiss
                              }
                            );
                          }
                        }
                      });
                    }}>
                    <IonButton fill='clear'>
                      {item}
                      <IonIcon size="small" slot="end" icon={copyOutline} />
                    </IonButton>
                  </CopyToClipboard>
                )
              }
            </div>
            : <></>
        ),
        center: true,
        sortable: false
      },
      {
        id: 'asn_product.status,asn_product.active',
        name: TEXT.status,
        selector: (row: { status: any }) => row.status,
        cell: (row: { id: any, status: any, active: any }) =>
        (
          <>
            <IonButtons className="w-100">
              <IonButton className="w-100" id={`status-${row.id}`} fill='solid' color={
                row.status === 'pending' && !row.active ?
                  'warning' : colorsProduct[row.status]
              }
                disabled={status === 'complete' || status === 'cancel' ? true : false}
                onClick={
                  () => {
                    {
                      !members.length || (
                        !members.includes('1')
                        && !members.includes('2')
                        && !members.includes('3')
                      )
                        && setValue('status', row.status === 'pending' && !row.active ? 'empty' : row.status)
                    }
                  }
                }>
                <IonLabel>{
                  row.status === 'pending' && !row.active ?
                    TEXT.asnProductNotFound : statusesProduct[row.status]
                }</IonLabel>
              </IonButton>
            </IonButtons>
            {
              members.length && (
                members.includes('1')
                || members.includes('2')
                || members.includes('3')
              )
                ?
                <IonPopover trigger={`status-${row.id}`} dismissOnSelect={true} side="end">
                  <IonContent>
                    <IonList>
                      <IonRadioGroup value={row.status}>
                        {
                          Object.keys(statusesProduct).map((key: any, index: number) => (
                            key !== 'counted' ?
                              <IonItem key={`status-option-${index}`} lines="none" button={true} detail={false}
                                onClick={() => changeProductStatus(row.id, key)}
                              >
                                <IonLabel>{statusesProduct[key]}</IonLabel>
                                <IonRadio slot="start" value={key} />
                              </IonItem>
                              :
                              <IonItem key={`status-option-${index}`} lines="none" button={true} detail={false}
                                onClick={() => setModalCountedData(row.id)}
                              >
                                <IonLabel>{statusesProduct[key]}</IonLabel>
                                <IonRadio slot="start" value={key} />
                              </IonItem>
                          ))
                        }
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonPopover>
                : <></>
            }
          </>
        ),
        width: '150px',
        center: true,
        sortable: true
      }
    ];
    const paginationComponentOptions = {
      rowsPerPageText: TEXT.rowsPerPage,
      rangeSeparatorText: TEXT.rangeSeparator,
      selectAllRowsItemText: TEXT.selectAllRowsItem,
    };

    const initialize = async () => {
      setInitialized(false);
      if (displayInitialLoading) {
        setShowLoading(true);
      }
      setImportResult(null);
      setASN(null);
      setProducts([]);
      setProductsChecks([]);
      setTotal(0);
      setTotalAll(0);
      setTotalCounted(0);
      setTotalLack(0);
      setTotalOver(0);
      setTotalPending(0);
      setTotalComplete(0);
      setTotalEmpty(0);
      const userData: any = await UserService.getSession();
      if (userData) {
        setMembers(
          userData.members.map(
            ({ group_id }: any) => (
              group_id
            )
          )
        );
        await load(id);
      }
      loadProductsAll(id);
      setInitialized(true);
      onDidLoad && onDidLoad(true);
      setShowLoading(false);
    }

    const load = async (id: string | null | undefined = null, force: boolean = true) => {
      if (id) {
        await ASNService.get(id, null, true).then(async (data: any) => {
          setASN(data);
          setStatus(data.status);
          setValue('asnStatus', data.status);
          setValue('asnReceiveDate', data.receive_date ? moment.utc(data.receive_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
          setValue('asnExpectDate', data.expect_date ? moment.utc(data.expect_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        await loadProducts(id, 0, pageLimit, defaultSort.by, defaultSort.direction, getValues('search'), getValues('status'));
      }
    }

    const loadProducts = async (
      id: string,
      start: number = 0,
      limit: number | null = pageLimit,
      sortBy: string = defaultSort.by,
      sortDirection: string = defaultSort.direction,
      search: string = getValues('search'),
      status: string = getValues('status')
    ) => {
      if (id) {
        onLoad && onLoad(true);
        setShowProgressLoading(true);
        if (status && status === 'empty') {
          sortBy = 'pending'
        }
        const count = (countStatus: any = '', active: boolean | null = null) => {
          return new Promise(async (resolve) => {
            await ASNProductService.count(
              id,
              active,
              null,
              null,
              search,
              countStatus
            ).then(async (rows: any) => {
              resolve(rows);
            });
          });
        }
        await count(
          status === 'empty' ? 'pending' : status,
          status && status !== 'empty' ? true : status === 'empty' ? false : null
        ).then(async (rows: any) => {
          setTotal(rows);
          if (rows < offset) {
            start = 0;
            limit = null;
            sortBy = defaultSort.by;
            sortDirection = defaultSort.direction;
          }
          await ASNProductService.list(
            id,
            status && status !== 'empty' ? true : status === 'empty' ? false : null,
            { by: sortBy, direction: sortDirection },
            start,
            limit,
            search,
            status === 'empty' ? 'pending' : status,
            ['product']
          ).then(async (data: any) => {
            if (
              members.length && (
                members.includes('1')
                || members.includes('2')
                || members.includes('3')
              )
            ) {
              let index = 0;
              for (let item of data) {
                let locations: Array<any> = [];
                await ASNProductPutawayService.list(
                  item.id,
                  null,
                  { by: 'warehouse', direction: 'asc' }
                ).then(async (dataPutaway: any) => {
                  for (let itemPutaway of dataPutaway) {
                    await locations.push(
                      (
                        itemPutaway.warehouse + '.'
                        + itemPutaway.floor + '.'
                        + itemPutaway.aisle + '.'
                        + itemPutaway.bay + '.'
                        + itemPutaway.level + '.'
                        + itemPutaway.bin
                      )
                    );
                  }
                }).catch((error) => { });
                data[index].locations = locations;
                index += 1;
              }
              setProducts(data);
            } else {
              setProducts(data);
            }
          }).catch((error) => {
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
        });
        onLoad && onLoad(false);
        setShowProgressLoading(false);
        count().then(async (rows: any) => {
          setTotalAll(rows);
        });
        count('counted', true).then(async (rows: any) => {
          setTotalCounted(rows);
        });
        count('lack', true).then(async (rows: any) => {
          setTotalLack(rows);
        });
        count('over', true).then(async (rows: any) => {
          setTotalOver(rows);
        });
        count('pending', true).then(async (rows: any) => {
          setTotalPending(rows);
        });
        count('complete', true).then(async (rows: any) => {
          setTotalComplete(rows);
        });
        count('pending', false).then(async (rows: any) => {
          setTotalEmpty(rows);
        });
      }
    }

    const loadProductsAll = async (id: string) => {
      if (id) {
        await ASNProductService.list(
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          ['product']
        ).then(async (data: any) => {
          setProductsAll(data);
          const dataChecks = JSON.parse(JSON.stringify(data));
          const productsChecksMap = await Promise.all(
            dataChecks.map((item: any) => {
              item.quantity = item.counted;
              return item;
            })
          );
          setProductsChecks(productsChecksMap);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        onDidScannerReady && onDidScannerReady(true);
        setScannerReady(true);
      }
    }

    const changeStatus = (asn: any, value: any, ready: any) => {
      if (asn && asn.status !== value) {

        const success = async () => {
          onStatusChange && onStatusChange(value);
          setPresentToast({
            isPresent: true,
            status: true,
            message: MESSAGE.success.complete,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        }

        const patch = async (id: any, status: any) => {
          setShowLoading(true);
          await ASNService.changeStatus(id, status).then(async () => {
            success();
            await load(id);
          }).catch((error) => {
            setValue('asnStatus', asn.status);
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
          setShowLoading(false);
        }

        if (value === 'complete' || value === 'cancel') {
          setPresentAlertPrompt({
            isPresent: true,
            message: value === 'complete' ? MESSAGE.info.asnChangeStatusComplete : MESSAGE.info.asnChangeStatusCancel,
            onDismiss: (data: boolean) => {
              onCancelStatusChange && onCancelStatusChange(true);
              if (ready) {
                setPresentAlertPrompt(
                  {
                    isPresent: false,
                    message: presentAlertPrompt.message,
                    onDismiss: presentAlertPrompt.onDismiss
                  }
                );
                if (data) {
                  patch(asn.id, value);
                } else {
                  setValue('asnStatus', asn.status);
                }
              }
            }
          });
        } else {
          patch(asn.id, value);
        }

      }
    }

    const changeProductStatus = (asnProductID: any, value: any) => {

      const success = async (asnID: any) => {
        if (asnID) {
          await load(asnID);
        }
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (asnProductID: any, status: any, counted: any = null) => {
        setShowLoading(true);
        await ASNProductService.changeStatus(asnProductID, status, counted).then(async () => {
          success(asn ? asn.id : null);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        setShowLoading(false);
      }

      if (value === 'complete' || value === 'cancel') {
        setPresentAlertPrompt({
          isPresent: true,
          message: value === 'complete' ? MESSAGE.info.asnProductChangeStatusComplete : MESSAGE.info.asnChangeStatusCancel,
          onDismiss: (data: boolean) => {
            if (ready) {
              setPresentAlertPrompt(
                {
                  isPresent: false,
                  message: presentAlertPrompt.message,
                  onDismiss: presentAlertPrompt.onDismiss
                }
              );
              if (data) {
                patch(asnProductID, value);
              }
            }
          }
        });
      } else {
        patch(asnProductID, value);
      }

    }

    const changeReceiveDate = (asn: any, value: any, ready: any) => {

      const success = async () => {
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (id: any, date: any) => {
        await ASNService.patch(id, { 'receive_date': date }).then(async () => {
          success();
        }).catch((error) => {
          setValue('asnReceiveDate', asn.receive_date ? moment.utc(asn.receive_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      }

      patch(asn.id, value ? value.replace('T', ' ') : null);

    }

    const changeExpectDate = (asn: any, value: any, ready: any) => {

      const success = async () => {
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (id: any, date: any) => {
        await ASNService.patch(id, { 'expect_date': date }).then(async () => {
          success();
        }).catch((error) => {
          setValue('asnExpectDate', asn.expect_date ? moment.utc(asn.expect_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      }

      patch(asn.id, value ? value.replace('T', ' ') : null);

    }

    const updateProductsChecks = async (productUpdate: any, locationUpdate: any = null) => {
      if (asn) {
        const productsAllData = JSON.parse(JSON.stringify(productsAll));
        const productsChecksData = JSON.parse(JSON.stringify(productsChecks));
        for (let i = 0; i < productsChecksData.length; i++) {
          if (productsChecksData[i].product === productUpdate.product) {
            for (let j = 0; j < productsAllData.length; j++) {
              if (productsAllData[j].product === productUpdate.product) {

                productsChecksData[i].quantity = parseInt(productsChecksData[i].quantity) + 1;

                await ASNService.putaway(
                  asn.id,
                  productsChecksData[i].product,
                  null,
                  null,
                  1,
                  locationUpdate
                ).then(async () => {
                  setProductsChecks(productsChecksData);
                  setPresentToast({
                    isPresent: true,
                    status: true,
                    message: MESSAGE.success.product_in_list,
                    onDismiss: () => {
                      if (ready) {
                        setPresentToast(
                          {
                            isPresent: false,
                            status: presentToast.status,
                            message: presentToast.message,
                            onDismiss: presentToast.onDismiss
                          }
                        );
                      }
                    }
                  });
                }).catch((error) => {
                  setPresentToast({
                    isPresent: true,
                    status: false,
                    message: error,
                    onDismiss: () => {
                      if (ready) {
                        setPresentToast(
                          {
                            isPresent: false,
                            status: presentToast.status,
                            message: presentToast.message,
                            onDismiss: presentToast.onDismiss
                          }
                        );
                      }
                    }
                  });
                });

                break;

              }

            }

            break;
          }
        }
      }
    }

    const downloadReport = async () => {

      setShowLoading(true);

      const status = getValues('status');
      let statusText: string = '';
      if (status === 'empty') {
        statusText = TEXT.asnProductNotFound;
      } else if (status === 'lack') {
        statusText = TEXT.lack;
      } else if (status === 'over') {
        statusText = TEXT.over;
      }

      const searchText = getValues('search');

      const csv = async (content: any) => {

        let rows: any = [];

        await rows.push(
          await Object.keys(columnsReport).filter((item: any) => {
            return columnsReport[item] === true;
          })
        );

        for (let item of content) {

          let values = [
            (item.product_reference && item.product_reference.sku ? item.product_reference.sku : ''),
            (item.product_reference && item.product_reference.barcode ? item.product_reference.barcode : ''),
            (item.product_reference && item.product_reference.name ? item.product_reference.name : ''),
            (item.quantity ? item.quantity : ''),
            (item.counted ? item.counted : ''),
            (item.lack ? item.lack : ''),
            (item.over ? item.over : ''),
            (item.expire_date ? item.expire_date : ''),
            (item.lot_number ? item.lot_number : ''),
            (item.status && item.active !== false ? statusesProduct[item.status] : item.active === false ? TEXT.asnProductNotFound : '')
          ];

          await rows.push(
            await values.filter((item: any, index: number) => {
              return Object.values(columnsReport)[index] === true;
            }).map((item: any) => {
              return '"' + item.toString().replace(/"/g, '""') + '"';
            })
          );

        };

        let header = 'data:text/csv; charset=utf-8,';
        let downloadContent = '';
        for (let rowArray of rows) {
          let row = await rowArray.join(",");
          downloadContent += row + "\r\n";
        }

        const name = `ASN-${id}${statusText ? ` (${statusText})` : ''
          } - ${moment().format('yyyy-MM-DD')}${searchText ? ` [${TEXT.searchFor} ${searchText}]` : ''
          }.csv`;
        const universalBOM = "\uFEFF";
        const encodedUri = header + encodeURIComponent(universalBOM + downloadContent);
        let link = document.createElement("a");
        await link.setAttribute('href', encodedUri);
        await link.setAttribute('download', name);
        await document.body.appendChild(link);
        await link.click();

      }

      await ASNProductService.list(
        id,
        status && status !== 'empty' ? true : status === 'empty' ? false : null,
        { by: 'product.sku', direction: 'asc' },
        null,
        null,
        getValues('search'),
        status === 'empty' ? 'pending' : status,
        ['product']
      ).then(async (data: any) => {
        await csv(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });

      setShowLoading(false);

    }

    const downloadPutaway = async () => {

      setShowLoading(true);

      const status = getValues('status');
      let statusText: string = '';
      if (status === 'empty') {
        statusText = TEXT.asnProductNotFound;
      } else if (status === 'lack') {
        statusText = TEXT.lack;
      } else if (status === 'over') {
        statusText = TEXT.over;
      }

      const csv = async (content: any) => {

        let rows: any = [];

        await rows.push(
          await Object.keys(columnsPutaway).filter((item: any) => {
            return columnsPutaway[item] === true;
          })
        );

        for (let item of content) {

          let values = [
            (
              item.warehouse + '.'
              + item.floor + '.'
              + item.aisle + '.'
              + item.bay + '.'
              + item.level + '.'
              + item.bin
            ),
            (item.product_reference && item.product_reference.sku ? item.product_reference.sku : ''),
            (item.product_reference && item.product_reference.barcode ? item.product_reference.barcode : ''),
            (item.quantity ? item.quantity : '')
          ];

          await rows.push(
            await values.filter((item: any, index: number) => {
              return Object.values(columnsPutaway)[index] === true;
            }).map((item: any) => {
              return '"' + item.toString().replace(/"/g, '""') + '"';
            })
          );

        };

        let header = 'data:text/csv; charset=utf-8,';
        let downloadContent = '';
        for (let rowArray of rows) {
          let row = await rowArray.join(",");
          downloadContent += row + "\r\n";
        }

        const name = `ASN-${id} Putaway - ${moment().format('yyyy-MM-DD')}
          }.csv`;
        const universalBOM = "\uFEFF";
        const encodedUri = header + encodeURIComponent(universalBOM + downloadContent);
        let link = document.createElement("a");
        await link.setAttribute('href', encodedUri);
        await link.setAttribute('download', name);
        await document.body.appendChild(link);
        await link.click();

      }

      let dataPutaways: Array<any> = [];
      await ASNProductService.list(
        id,
        status && status !== 'empty' ? true : status === 'empty' ? false : null,
        { by: 'product.sku', direction: 'asc' },
        null,
        null,
        getValues('search'),
        status === 'empty' ? 'pending' : status,
        ['product']
      ).then(async (data: any) => {
        for (let item of data) {
          await ASNProductPutawayService.list(
            item.id,
            null,
            { by: 'warehouse', direction: 'asc' }
          ).then(async (dataPutaway: any) => {
            for (let itemPutaway of dataPutaway) {
              itemPutaway.product_reference = item.product_reference;
              await dataPutaways.push(itemPutaway);
            }
          }).catch((error) => { });
        }
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });

      if (dataPutaways) {
        await csv(dataPutaways);
      }

      setShowLoading(false);

    }

    const downloadReceipt = async () => {

      setShowLoading(true);

      const status = getValues('status');
      let statusText: string = '';
      if (status === 'empty') {
        statusText = TEXT.asnProductNotFound;
      } else if (status === 'lack') {
        statusText = TEXT.lack;
      } else if (status === 'over') {
        statusText = TEXT.over;
      }

      const searchText = getValues('search');

      const download = async (content: any) => {

        let rows: any = [];
        await rows.push(
          await Object.keys(columnsReceipt).filter((item: any) => {
            return columnsReceipt[item] === true;
          })
        );
        for (let item of content) {
          let values = [
            (item.product_reference && item.product_reference.sku ? item.product_reference.sku : ''),
            (item.product_reference && item.product_reference.barcode ? item.product_reference.barcode : ''),
            (item.product_reference && item.product_reference.name ? item.product_reference.name : ''),
            (item.quantity ? item.quantity : ''),
            (item.expire_date ? item.expire_date : ''),
            (item.lot_number ? item.lot_number : ''),
            (item.status && item.active !== false ? statusesProduct[item.status] : item.active === false ? TEXT.asnProductNotFound : '')
          ];
          await rows.push(
            await values.filter((item: any, index: number) => {
              return Object.values(columnsReceipt)[index] === true;
            })
          );
        };

        const fileNameDescription = `${moment().format('yyyy-MM-DD')}${searchText ? ` [${TEXT.searchFor} ${searchText}]` : ''
          }`;
        const name = `ASN-${id}${statusText ? ` (${statusText})` : ''
          } - ${fileNameDescription}.xlsx`;

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';

        const sheetContentHeader: any = {
          '!ref': `A1:F${(rows.length + 13)}`,
          '!merges': [
            {
              s: {
                r: 0,
                c: 0
              },
              e: {
                r: 0,
                c: 3
              }
            },
            {
              s: {
                r: 1,
                c: 0
              },
              e: {
                r: 1,
                c: 3
              }
            },
            {
              s: {
                r: 2,
                c: 0
              },
              e: {
                r: 2,
                c: 3
              }
            },
            {
              s: {
                r: 3,
                c: 0
              },
              e: {
                r: 3,
                c: 3
              }
            },
            {
              s: {
                r: 4,
                c: 1
              },
              e: {
                r: 4,
                c: 3
              }
            },
            {
              s: {
                r: 5,
                c: 1
              },
              e: {
                r: 5,
                c: 3
              }
            },
            {
              s: {
                r: 6,
                c: 1
              },
              e: {
                r: 6,
                c: 3
              }
            },
            {
              s: {
                r: 7,
                c: 1
              },
              e: {
                r: 7,
                c: 3
              }
            },
            {
              s: {
                r: 8,
                c: 0
              },
              e: {
                r: 8,
                c: 3
              }
            },
            {
              s: {
                r: (rows.length + 9),
                c: 0
              },
              e: {
                r: (rows.length + 9),
                c: 3
              }
            },
            {
              s: {
                r: (rows.length + 10),
                c: 1
              },
              e: {
                r: (rows.length + 10),
                c: 3
              }
            },
            {
              s: {
                r: (rows.length + 11),
                c: 0
              },
              e: {
                r: (rows.length + 11),
                c: 3
              }
            },
            {
              s: {
                r: (rows.length + 12),
                c: 1
              },
              e: {
                r: (rows.length + 12),
                c: 3
              }
            }
          ],
          '!cols': [
            {
              width: 20
            },
            {
              width: 20
            },
            {
              width: 50
            },
            {
              width: 10
            },
            {
              width: 10
            }
          ],
          'A1': {
            t: 's',
            v: TEXT.asnReceiptHeader,
            s: {
              alignment: {
                horizontal: 'center'
              }
            }
          },
          'A2': {
            t: 's',
            v: CONTACT.company,
            s: {
              alignment: {
                horizontal: 'center'
              }
            }
          },
          'A3': {
            t: 's',
            v: `${CONTACT.address}, ${CONTACT.phone}`,
            s: {
              alignment: {
                horizontal: 'center'
              }
            }
          },
          'A4': {
            t: 's',
            v: ''
          },
          'A5': {
            t: 's',
            v: TEXT.dateNotified
          },
          'B5': {
            t: asn && asn.create_at ? 'd' : 's',
            v: `${asn && asn.create_at ? moment.utc(asn.create_at).local().format('yyyy-MM-DD') : ''}`
          },
          'A6': {
            t: 's',
            v: TEXT.asnNumber
          },
          'B6': {
            t: 's',
            v: `${asn && asn.id ?
              `ASN-${asn.id}`
              : ''
              }`
          },
          'A7': {
            t: 's',
            v: TEXT.vendor
          },
          'B7': {
            t: 's',
            v: `${asn && asn.vendor_reference ?
              `${asn.vendor_reference.name}`
              : ''
              }`
          },
          'A8': {
            t: 's',
            v: TEXT.dateTakeFromWarehouse
          },
          'B8': {
            t: asn && asn.receive_date ? 'd' : 's',
            v: `${asn && asn.receive_date ? moment.utc(asn.receive_date).local().format('yyyy-MM-DD') : ''}`
          },
          'A9': {
            t: 's',
            v: ''
          }
        };
        const sheetContentBody: any = {};
        const sheetContentFooter: any = {
          [`A${(rows.length + 10)}`]: {
            t: 's',
            v: ''
          },
          [`A${(rows.length + 11)}`]: {
            t: 's',
            v: TEXT.asnSender
          },
          [`B${(rows.length + 11)}`]: {
            t: 's',
            v: '',
            s: {
              border: {
                bottom: {
                  style: 'thin',
                  color: '00000000'
                }
              }
            }
          },
          [`A${(rows.length + 12)}`]: {
            t: 's',
            v: ''
          },
          [`A${(rows.length + 13)}`]: {
            t: 's',
            v: TEXT.asnReceiver
          },
          [`B${(rows.length + 13)}`]: {
            t: 's',
            v: '',
            s: {
              border: {
                bottom: {
                  style: 'thin',
                  color: '00000000'
                }
              }
            }
          }
        };

        let r = 10;
        for (let row of rows) {
          let c = 0
          for (let column of row) {
            let type = 's';
            let column_index = 'A';
            if (c === 1) {
              column_index = 'B';
            } else if (c === 2) {
              column_index = 'C';
            } else if (c === 3) {
              column_index = 'D';
              if (r > 10) {
                type = 'n';
              }
            } else if (c === 4) {
              column_index = 'E';
              if (r > 10) {
                type = 'n';
              }
            } else if (c === 5) {
              column_index = 'F';
              if (r > 10) {
                type = 'n';
              }
            }
            sheetContentBody[`${column_index}${r}`] = {
              t: type,
              v: column,
              s: {
                border: {
                  top: {
                    style: 'thin',
                    color: '00000000'
                  },
                  bottom: {
                    style: 'thin',
                    color: '00000000'
                  },
                  left: {
                    style: 'thin',
                    color: '00000000'
                  },
                  right: {
                    style: 'thin',
                    color: '00000000'
                  }
                }
              }
            }
            c += 1;
          }
          r += 1;
        }

        const sheetContent: any = {
          ...sheetContentHeader,
          ...sheetContentBody,
          ...sheetContentFooter
        }

        const excelBuffer: any = await XLSX.writeFile({
          SheetNames: [`ถอนสินค้า-${id}`],
          Sheets: {
            [`ถอนสินค้า-${id}`]: sheetContent
          }
        }, name);

        new Blob([excelBuffer], { type: fileType });

      }

      await ASNProductService.list(
        id,
        status && status !== 'empty' ? true : status === 'empty' ? false : null,
        { by: 'product.sku', direction: 'asc' },
        null,
        null,
        getValues('search'),
        status === 'empty' ? 'pending' : status,
        ['product']
      ).then(async (data: any) => {
        download(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });

      setShowLoading(false);

    }

    const [presentImport, dismissImport] = useIonModal(ModalASNPutAwayImport, {
      options: {
        canDismiss: true,
        swipeToClose: true
      },
      asn: asn ? asn : null,
      onDismiss: async (data: any) => {
        dismissImport();
        if (data) {
          setShowLoading(true);
          setImportResult(data);
          await load(id);
          setShowLoading(false);
        }
      }
    });

    const [presentCSV, dismissCSV] = useIonModal(ModalASNImport, {
      options: {
        canDismiss: true,
        swipeToClose: true
      },
      asnID: id,
      onDismiss: async (data: any) => {
        dismissCSV();
        if (data) {
          setShowLoading(true);
          setImportResult(data);
          await load(id);
          setShowLoading(false);
        }
      }
    });

    const handlePageChange = async (currentPage: number) => {
      setPage(currentPage);
      loadProducts(asn.id, (currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
    };

    const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
      setPage(currentPage);
      setPageLimit(newPerPage);
      loadProducts(asn.id, (currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
    };

    const handleSort = async (column: any, sortDirection: any) => {
      setSort({ by: column.id, direction: sortDirection });
      loadProducts(asn.id, (page - 1) * pageLimit, pageLimit, column.id, sortDirection);
    };

    useEffect(() => {
      setReady(true);
      return () => {
        setReady(false);
      }
    }, []);

    useEffect(() => {
      initialize();
    }, [id]);

    useEffect(() => {
      if (initialized) {
        load(id);
      }
    }, [reload]);

    useEffect(() => {
      if (doChangeStatus) {
        changeStatus(asn, doChangeStatus, ready);
      }
    }, [doChangeStatus]);

    useEffect(() => {
      if (doCheck) {

      }
    }, [doCheck]);

    useEffect(() => {
      if (scannerIsOpen) {
        setModalScannerIsOpen(true);
      }
    }, [scannerIsOpen]);

    useEffect(() => {
      if (barcodeIsOpen) {
        setModalBarcodeIsOpen(true);
      }
    }, [barcodeIsOpen]);

    return (
      <>
        <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        />

        <AlertStatus
          isPresent={presentAlert.isPresent}
          status={presentAlert.status}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <AlertPrompt
          isPresent={presentAlertPrompt.isPresent}
          message={presentAlertPrompt.message}
          onDismiss={presentAlertPrompt.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonModal canDismiss swipeToClose isOpen={modalProductPreviewIsOpen} onDidDismiss={() => setModalProductPreviewIsOpen(false)}>
          <ModalProductPreview
            productId={idProductPreview}
            onDismiss={() => setModalProductPreviewIsOpen(false)}
          />
        </IonModal>

        <IonModal canDismiss swipeToClose isOpen={modalCountedData ? true : false} onDidDismiss={() => setModalCountedData(false)}>
          <ModalCounted
            asnProductID={modalCountedData}
            onDismiss={() => {
              setModalCountedData(false);
              loadProducts(id, 0, pageLimit, sort.by, sort.direction, getValues('search'), getValues('status'));
              loadProductsAll(id);
            }}
          />
        </IonModal>

        <IonAlert
          isOpen={downloadPrompt}
          onDidDismiss={() => setDownloadPrompt(false)}
          header="เลือกรูปแบบเอกสาร"
          inputs={
            members.length && (
              members.includes('1')
              || members.includes('2')
              || members.includes('3')
            ) ?
              [
                {
                  label: TEXT.report,
                  type: 'radio',
                  value: 'report'
                },
                {
                  label: TEXT.asnPutaway,
                  type: 'radio',
                  value: 'putaway'
                },
                {
                  label: TEXT.asnReceipt,
                  type: 'radio',
                  value: 'receipt'
                }
              ]
              : [
                {
                  label: TEXT.report,
                  type: 'radio',
                  value: 'report'
                },
                {
                  label: TEXT.asnReceipt,
                  type: 'radio',
                  value: 'receipt'
                }
              ]
          }
          buttons={[
            {
              text: BUTTON.cancel,
              role: 'cancel',
            },
            {
              text: BUTTON.ok,
              handler: (data: any) => {
                if (data === 'report') {
                  downloadReport();
                } else if (data === 'receipt') {
                  downloadReceipt();
                } else if (data === 'putaway') {
                  downloadPutaway();
                }
              }
            }
          ]}
        />

        {
          asn ?
            <>

              {
                scannerReady ?
                  <IonModal canDismiss swipeToClose isOpen={modalScannerIsOpen} onDidDismiss={() => setModalScannerIsOpen(false)}>
                    <ModalScanner
                      productsData={JSON.parse(JSON.stringify(productsAll))}
                      checkedData={JSON.parse(JSON.stringify(productsChecks))}
                      locationSet={asnLocation}
                      locationScannerUsing={modalLocationData ? true : false}
                      asnData={asn}
                      onLocationSet={
                        () => {
                          setModalLocationData(true);
                        }
                      }
                      onCheck={
                        async (product: any) => {
                          await updateProductsChecks(product, asnLocation);
                          loadProducts(asn.id, page, pageLimit, sort.by, sort.direction);
                        }
                      }
                      onUpdate={
                        () => loadProducts(asn.id, page, pageLimit, sort.by, sort.direction)
                      }
                      onDismiss={() => setModalScannerIsOpen(false)}
                    />
                  </IonModal>
                  : <></>
              }

              {
                scannerReady ?
                  <IonModal canDismiss swipeToClose isOpen={modalBarcodeIsOpen} onDidDismiss={() => setModalBarcodeIsOpen(false)}>
                    <ModalBarcode
                      productsData={JSON.parse(JSON.stringify(productsAll))}
                      checkedData={JSON.parse(JSON.stringify(productsChecks))}
                      focusBarcode={focusBarcode}
                      locationSet={asnLocation}
                      onLocationSet={
                        () => {
                          setModalLocationData(true);
                        }
                      }
                      asnData={asn}
                      onCheck={
                        async (product: any) => {
                          await updateProductsChecks(product, asnLocation);
                          loadProducts(asn.id, page, pageLimit, sort.by, sort.direction);
                        }
                      }
                      onUpdate={
                        () => loadProducts(asn.id, page, pageLimit, sort.by, sort.direction)
                      }
                      onDismiss={() => setModalBarcodeIsOpen(false)}
                    />
                  </IonModal>
                  : <></>
              }

              <IonModal canDismiss swipeToClose isOpen={modalLocationData ? true : false} onDidDismiss={() => setModalLocationData(null)}>
                <ModalLocation
                  reference={modalLocationData}
                  currentLocationCode={asnLocation}
                  onDismiss={async (locationData: any) => {
                    if (locationData) {
                      const locationCode: string =
                        (locationData.warehouse ? locationData.warehouse : '')
                        + '.' + (locationData.floor ? locationData.floor : '')
                        + '.' + (locationData.aisle ? locationData.aisle : '')
                        + '.' + (locationData.bay ? locationData.bay : '')
                        + '.' + (locationData.level ? locationData.level : '')
                        + '.' + (locationData.bin ? locationData.bin : '');
                      setASNLocation(locationCode);
                      await setFocusBarcode(true);
                      setFocusBarcode(false);
                    }
                    setModalLocationData(null);
                  }}
                />
              </IonModal>

              <IonGrid className={className}>

                <IonRow>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          {TEXT.asn} - {asn.id}
                          {
                            asn.vendor_reference ?
                              ` (${asn.vendor_reference.name})`
                              : ''
                          }
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.dateNotified}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none">
                                <IonLabel class="ion-text-wrap" color="dark">{asn.create_at ? moment.utc(asn.create_at).local().format('LLL') : '-'}</IonLabel>
                              </IonItem>
                            </IonCol>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.dateComeToWarehouse}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              {
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                )
                                  ?
                                  <>
                                    <IonItem>
                                      <IonInput
                                        type="datetime-local"
                                        {...asnExpectDate}
                                        disabled={status === 'complete' || status === 'cancel' ? true : false}
                                        onIonChange={(e: any) => {
                                          let dateValue = null;
                                          if (moment(e.detail.value).isValid()) {
                                            dateValue = e.detail.value;
                                          }
                                          if (dateValue) {
                                            setValue('asnExpectDate', moment(dateValue).format('YYYY-MM-DDTHH:mm'));
                                            changeExpectDate(asn, moment(dateValue).utc().format('YYYY-MM-DDTHH:mm:ss'), ready);
                                          } else {
                                            setValue('asnExpectDate', null);
                                            changeExpectDate(asn, null, ready);
                                          }
                                        }}>
                                      </IonInput>
                                    </IonItem>
                                  </>
                                  :
                                  <IonItem lines="none">
                                    <IonLabel class="ion-text-wrap" color="dark">{asn.expect_date ? moment.utc(asn.expect_date).local().format('LLL') : '-'}</IonLabel>
                                  </IonItem>
                              }
                            </IonCol>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.dateToWarehouse}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              {
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                )
                                  ?
                                  <>
                                    <IonItem>
                                      <IonInput
                                        type="datetime-local"
                                        {...asnReceiveDate}
                                        disabled={status === 'complete' || status === 'cancel' ? true : false}
                                        onIonChange={(e: any) => {
                                          let dateValue = null;
                                          if (moment(e.detail.value).isValid()) {
                                            dateValue = e.detail.value;
                                          }
                                          if (dateValue) {
                                            setValue('asnReceiveDate', moment(e.detail.value).format('YYYY-MM-DDTHH:mm'));
                                            changeReceiveDate(asn, moment(e.detail.value).utc().format('YYYY-MM-DDTHH:mm:ss'), ready);
                                          } else {
                                            setValue('asnReceiveDate', null);
                                            changeReceiveDate(asn, null, ready);
                                          }
                                        }}>
                                      </IonInput>
                                    </IonItem>
                                  </>
                                  :
                                  <IonItem lines="none">
                                    <IonLabel class="ion-text-wrap" color="dark">{asn.receive_date ? moment.utc(asn.receive_date).local().format('LLL') : '-'}</IonLabel>
                                  </IonItem>
                              }
                            </IonCol>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.asnStatus}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              {
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                )
                                  ?
                                  <IonItem className="item-hidden-label">
                                    <IonLabel>
                                      <IonBadge color={colors[asn.status]}>{statuses[asn.status]}</IonBadge>
                                    </IonLabel>
                                    <IonLabel>{TEXT.asnStatus}</IonLabel>
                                    <IonSelect
                                      interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                      {...asnStatus} value={getValues('asnStatus')}
                                      onIonChange={(e: any) => { e.ready = ready; e.asn = asn; asnStatus.onChange(e) }}>
                                      <IonSelectOption value="pending">{statuses['pending']}</IonSelectOption>
                                      <IonSelectOption value="complete">{statuses['complete']}</IonSelectOption>
                                      <IonSelectOption value="cancel">{statuses['cancel']}</IonSelectOption>
                                    </IonSelect>
                                  </IonItem>
                                  :
                                  <IonItem lines="none">
                                    <IonLabel>
                                      <IonBadge color={colors[asn.status]}>{statuses[asn.status]}</IonBadge>
                                    </IonLabel>
                                  </IonItem>
                              }
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>

                <IonRow className='row-toolbar ion-margin-top'>
                  <IonCol size='12' sizeMd='6'>
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines="none">
                        <IonSearchbar
                          placeholder={
                            `${TEXT.productName}/${TEXT.productSku}/${TEXT.barcode}`
                          }
                          inputmode="search"
                          {...filterSearch}
                          onIonChange={(e: SearchbarCustomEvent) => { setValue('search', e.detail.value); }}
                          onKeyUp={(e: KeyboardEvent) => {
                            if (e.key === "Enter") {
                              loadProducts(
                                asn.id,
                                0,
                                pageLimit,
                                sort.by,
                                sort.direction,
                                (e.target as HTMLTextAreaElement).value,
                                getValues('status')
                              );
                            }
                          }}
                          onIonClear={async () => {
                            setValue('search', '');
                            loadProducts(
                              asn.id,
                              0,
                              pageLimit,
                              sort.by,
                              sort.direction,
                              '',
                              getValues('status')
                            );
                          }}
                        >
                        </IonSearchbar>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                  <IonCol size='12' sizeMd='6'>
                    <IonToolbar className='toolbar-page'>
                      <IonButtons slot="end" className={
                        members.length && (
                          members.includes('1')
                          || members.includes('2')
                          || members.includes('3')
                        ) ? 'w-100' : ''
                      }>
                        {
                          members.length && (
                            members.includes('1')
                            || members.includes('2')
                            || members.includes('3')
                          )
                            ?
                            <>
                              <IonButton fill='outline' color="primary" onClick={() => { setDownloadPrompt(true); }} disabled={showProgressLoading}>
                                <IonIcon slot="start" icon={downloadOutline} />&nbsp;{BUTTON.download}
                              </IonButton>
                              <IonButton fill='outline' color="tertiary" onClick={() => { presentImport(); }}
                                disabled={status === 'complete' || status === 'cancel' || showProgressLoading ? true : false}>
                                <IonIcon slot="start" icon={cloudUploadOutline} />&nbsp;{BUTTON.uploadPutAwayCSV}
                              </IonButton>
                            </>
                            :
                            <>
                              <IonButton fill='outline' color="primary"
                                disabled={status === 'complete' || status === 'cancel' || showProgressLoading ? true : false}
                                routerLink={RoutePath.productImportEdit.replace(':id', id)}
                                routerDirection={Direction()}
                              >
                                <IonIcon slot="start" icon={cubeOutline} />&nbsp;{MENU.productImportManage}
                              </IonButton>
                              <IonButton fill='outline' color="secondary" onClick={() => { setDownloadPrompt(true); }} disabled={showProgressLoading}>
                                <IonIcon slot="start" icon={downloadOutline} />&nbsp;{BUTTON.download}
                              </IonButton>
                              <IonButton fill='outline' color="tertiary" onClick={() => { presentCSV(); }}
                                disabled={status === 'complete' || status === 'cancel' || showProgressLoading ? true : false}>
                                <IonIcon slot="start" icon={cloudUploadOutline} />&nbsp;{BUTTON.uploadASN}
                              </IonButton>
                            </>
                        }
                      </IonButtons>
                    </IonToolbar>
                  </IonCol>
                </IonRow>

              </IonGrid>

              <IonGrid className='grid-main'>
                <IonRow>
                  <IonCol>
                    <IonSegment className='table-segment' disabled={showProgressLoading} mode='md' scrollable={true}
                      value={getValues('status') ? getValues('status') : ''}
                      {...filterStatus}
                      onIonChange={(e: any) => { e.asnID = asn.id; filterStatus.onChange(e) }}>
                      <IonSegmentButton value="">
                        <IonLabel>
                          {TEXT.all}
                          {
                            totalAll ?
                              <IonBadge>
                                <NumberFormat value={totalAll} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="pending">
                        <IonLabel>
                          {statuses["pending"]}
                          {
                            totalPending ?
                              <IonBadge color="medium">
                                <NumberFormat value={totalPending} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="counted">
                        <IonLabel>
                          {TEXT.counted}
                          {
                            totalCounted ?
                              <IonBadge color="danger">
                                <NumberFormat value={totalCounted} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="lack">
                        <IonLabel>
                          {TEXT.lack}
                          {
                            totalLack ?
                              <IonBadge color="danger">
                                <NumberFormat value={totalLack} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="over">
                        <IonLabel>
                          {TEXT.over}
                          {
                            totalOver ?
                              <IonBadge color="danger">
                                <NumberFormat value={totalOver} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="empty">
                        <IonLabel>
                          {TEXT.asnProductNotFound}
                          {
                            totalEmpty ?
                              <IonBadge color="warning">
                                <NumberFormat value={totalEmpty} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="complete">
                        <IonLabel>
                          {statuses["complete"]}
                          {
                            totalComplete ?
                              <IonBadge color="success">
                                <NumberFormat value={totalComplete} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                    </IonSegment>
                  </IonCol>
                </IonRow>
              </IonGrid>

              {
                importResult ?
                  <IonAccordionGroup>
                    <IonAccordion value="updates">
                      <IonItem slot="header" color="success">
                        <IonLabel>{importResult.updates ? importResult.updates.length : 0} {TEXT.updates}</IonLabel>
                      </IonItem>
                      {
                        importResult.updates && importResult.updates.length ?
                          <div className="ion-padding" slot="content">
                            {
                              importResult.updates.map((item: any, index: number) => (
                                <CopyToClipboard text={item.sku ? item.sku : ''} key={`updates-${index}`}
                                  onCopy={() => {
                                    setPresentToast({
                                      isPresent: true,
                                      status: true,
                                      message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                      onDismiss: () => {
                                        if (ready) {
                                          setPresentToast(
                                            {
                                              isPresent: false,
                                              status: presentToast.status,
                                              message: presentToast.message,
                                              onDismiss: presentToast.onDismiss
                                            }
                                          );
                                        }
                                      }
                                    });
                                  }}>
                                  <IonChip color="success">
                                    <IonLabel>{item.sku ? item.sku : ''}</IonLabel>
                                    <IonIcon icon={copyOutline}></IonIcon>
                                  </IonChip>
                                </CopyToClipboard>
                              ))
                            }
                          </div>
                          : <></>
                      }
                    </IonAccordion>
                    <IonAccordion value="errors">
                      <IonItem slot="header" color="danger">
                        <IonLabel>{importResult.errors ? importResult.errors.length : 0} {TEXT.errors}</IonLabel>
                      </IonItem>
                      {
                        importResult.errors && importResult.errors.length ?
                          <div className="ion-padding" slot="content">
                            {
                              importResult.errors.map((item: any, index: number) => (
                                <CopyToClipboard text={item.sku ? item.sku : ''} key={`errors-${index}`}
                                  onCopy={() => {
                                    setPresentToast({
                                      isPresent: true,
                                      status: true,
                                      message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                      onDismiss: () => {
                                        if (ready) {
                                          setPresentToast(
                                            {
                                              isPresent: false,
                                              status: presentToast.status,
                                              message: presentToast.message,
                                              onDismiss: presentToast.onDismiss
                                            }
                                          );
                                        }
                                      }
                                    });
                                  }}>
                                  <IonChip color="danger">
                                    <IonLabel>{`${item.sku ? item.sku : ''} ${item.reason ? `(${item.reason})` : ''}`}</IonLabel>
                                    <IonIcon icon={copyOutline}></IonIcon>
                                  </IonChip>
                                </CopyToClipboard>
                              ))
                            }
                          </div>
                          : <></>
                      }
                    </IonAccordion>
                  </IonAccordionGroup>
                  : <></>
              }

              {
                initialized ?
                  <>

                    {
                      (total < offset) ?
                        <DataTable
                          columns={
                            members.length && (
                              members.includes('1')
                              || members.includes('2')
                              || members.includes('3')
                            ) ?
                              columnsProductsAdmin
                              : columnsProducts
                          }
                          data={products}
                          customStyles={pageTable}
                          pagination
                          noDataComponent={TEXT.noDataComponent}
                          paginationComponentOptions={paginationComponentOptions}
                          defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                          defaultSortFieldId={defaultSort.by}
                          progressPending={showProgressLoading}
                          progressComponent={<IonSpinner color="primary" />}
                        />
                        :
                        <DataTable
                          columns={
                            members.length && (
                              members.includes('1')
                              || members.includes('2')
                              || members.includes('3')
                            ) ?
                              columnsProductsAdmin
                              : columnsProducts
                          }
                          data={products}
                          customStyles={pageTable}
                          pagination
                          noDataComponent={TEXT.noDataComponent}
                          paginationComponentOptions={paginationComponentOptions}
                          paginationServer
                          paginationTotalRows={total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          sortServer
                          defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                          defaultSortFieldId={defaultSort.by}
                          onSort={handleSort}
                          progressPending={showProgressLoading}
                          progressComponent={<IonSpinner color="primary" />}
                        />
                    }

                  </>
                  : <></>
              }

            </>
            : <></>
        }

      </>
    );
  }

export default ASN;