import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonButton,
  IonIcon,
  IonListHeader,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle
} from "@ionic/react";
import { trashOutline } from 'ionicons/icons';

import Slim from "../../libraries/slim/slim.react";

import MESSAGE from '../../constances/Messages.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import { ToastStatus } from '../toasts/ToastStatus';
import { AlertStatus } from '../alerts/AlertStatus';
import { AlertPrompt } from '../alerts/AlertPrompt';

import BrandService from "../../services/BrandService";

export const BrandsForm: React.FC<{
  data?: any;
  name?: string;
  isSubmit?: boolean;
  onDidSubmit?: (data?: any) => any;
}> = ({ data = null, name = '', isSubmit = false, onDidSubmit }) => {

  const [brands, setBrands] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm();
  const [formGroup, setFormGroup] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const initialize = async () => {
    resetFormValues();
    setShowLoading(true);
    await getBrands().then(async (brands: any) => {
      setBrands(brands);
      setFormGroup(
        {
          name: register('name', {
            value: name ? name : data && data.name ? data.name : '',
            required: MESSAGE.error.input_required
          }),
          parent_brand: register('parent_brand', {
            value: data && data.parent_brand ? data.parent_brand : ''
          }),
          description: register('description', {
            value: data && data.description ? data.description : ''
          }),
          logo: register('logo', {
            value: data && data.logo ? data.logo : null
          })
        }
      );
    });
    setShowLoading(false);
  }

  const resetFormValues = () => {
    reset();
  }

  const getBrands = async () => {
    return new Promise(resolve => {
      BrandService.list(
        true,
        { by: 'name', direction: 'asc' }
      ).then((data: any) => {
        resolve(data);
      });
    });
  }

  const remove = async (id: any, name: string) => {

    const success = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.remove_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    setPresentAlertPrompt({
      isPresent: true,
      message: `${TEXT.removePrompt} "${name}"?`,
      onDismiss: (data: boolean) => {
        if (ready) {
          setPresentAlertPrompt(
            {
              isPresent: false,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          if (data) {
            BrandService.delete(id).then(async (result: any) => {
              success(result);
            }).catch((error) => {
              setPresentToast({
                isPresent: true,
                status: false,
                message: error,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            });
          }
        }
      }
    });

  }

  const onSubmit = async (value: any) => {

    setShowLoading(true);

    const onSuccess = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    const onError = (message: any) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: message,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit();
      }
    }

    if (data) {
      await BrandService.update(data.id, value).then(async (result: any) => {
        if (value.logo && typeof value.logo !== 'string') {
          await BrandService.upload(data.id, { logo: value.logo }).then(() => {
            onSuccess(result);
          });
        } else {
          onSuccess(result);
        }
      }).catch((error) => {
        onError(error);
      });
    } else {
      let formData = new FormData();
      const assign = async (value: any) => {
        for (let item of Object.keys(value)) {
          await formData.append(item, value[item]);
        }
      }
      await assign(value);
      await BrandService.create(formData).then(async (result: any) => {
        onSuccess(result);
        resetFormValues();
      }).catch((error) => {
        onError(error);
      });
    }

    setShowLoading(false);

  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
    if (onDidSubmit) {
      onDidSubmit();
    }
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit, onError)();
    }
  }, [isSubmit]);

  return (
    <>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <AlertPrompt
        isPresent={presentAlertPrompt.isPresent}
        message={presentAlertPrompt.message}
        onDismiss={presentAlertPrompt.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      {
        formGroup ?
          <form onSubmit={handleSubmit(onSubmit, onError)}>

            <IonGrid>
              <IonRow>
                <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>

                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>{TEXT.brandInfo}</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                      <IonGrid>

                        <IonRow>
                          <IonCol size='12' sizeLg='6'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.brandName}<span className='form-required'>*</span></IonLabel>
                              <IonInput type="text" {...formGroup.name}></IonInput>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeLg='6'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.brandParent}</IonLabel>
                              <IonSelect
                                interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                placeholder={TEXT.brandSelect}
                                value={getValues('parent_brand')}
                                {...formGroup.parent_brand}
                                disabled={!brands.length}
                              >
                                {brands.map((item: any, index: number) => (
                                  <IonSelectOption key={`brand-${index}`} value={item.id}>{item.name}</IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol size='12'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.description}</IonLabel>
                              <IonTextarea {...formGroup.description}></IonTextarea>
                            </IonItem>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol size="12" sizeMd='6' offsetMd='3' sizeLg="4" offsetLg='4'>
                            <div className='ion-text-center'>
                              <Slim
                                ratio="1:1"
                                initialImage={data && data.logo ? data.logo_reference.original : ''}
                                label={`<p>${TEXT.logo}</p>`}
                                push={true}
                                devicePixelRatio='auto'
                                service={
                                  (formdata: any, progress: any, success: any, failure: any, slim: any) => {
                                    slim._showButtons();
                                    slim._stopProgress();
                                    slim.requestOutput((fileData: File[]) => {
                                      setValue('logo', fileData[0]);
                                    });
                                  }
                                }
                                didRemove={
                                  () => {
                                    setValue('logo', null);
                                  }
                                }
                              >
                                <input type="file" {...formGroup.logo} accept="image/*" />
                              </Slim>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                    </IonCardContent>
                  </IonCard>

                </IonCol>
              </IonRow>

              {
                data ?
                  <IonRow className='ion-margin-top'>
                    <IonCol size='12' className='ion-text-center'>
                      <IonButton type="button" size='default' color='danger' onClick={() => { remove(data.id, data.name); }}>
                        <IonIcon slot="start" icon={trashOutline} />
                        {TEXT.removeBrand}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  : <></>
              }

            </IonGrid>

          </form>
          : <></>

      }

    </>
  );

};