import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonProgressBar,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {
  archive,
  archiveOutline,
  archiveSharp,
  arrowDownOutline,
  arrowUpOutline,
  clipboardOutline,
  copyOutline,
  createOutline,
  document,
  documentOutline,
  filterOutline
} from 'ionicons/icons';

import moment from 'moment';

import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';

import { Direction, RoutePath } from '../components/RoutePath';
import { AlertPrompt } from '../components/alerts/AlertPrompt';
import { ToastStatus } from '../components/toasts/ToastStatus';

import OrderProductService from "../services/OrderProductService";
import UserService from '../services/UserService';
import { ModalProductPreview } from '../components/modal/ProductPreview';

const Pick: React.FC = () => {

  const offset: number = 50;

  const defaultSort: any = {
    by: 'location',
    direction: 'asc'
  }

  const [members, setMembers] = useState<Array<any>>([]);
  const [items, setItems] = useState<Array<any>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showProgressLoading, setShowProgressLoading] = useState(false);

  // const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(0);
  const [sort, setSort] = useState<any>(defaultSort);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const location = useLocation();

  const { register, getValues, setValue } = useForm();

  const [modalProductPreviewIsOpen, setModalProductPreviewIsOpen] = useState<boolean>(false);
  const [idProductPreview, setIdProductPreview] = useState<any>(null);

  const openProductPreviewModal = (id: any) => {
    if (id) {
      setIdProductPreview(id);
      setModalProductPreviewIsOpen(true);
    }
  };
  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const columns: Array<any> = [
    {
      id: 'id',
      name: TEXT.orderNumber,
      selector: (row: { id: number }) => row.id,
      cell: (row: { id: any, job: any }) => (
        <IonItem lines="none" detail={false} routerLink={
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          )
            ? RoutePath.jobView.replace(':id', row.job)
            : RoutePath.orderView.replace(':id', row.id)
        } routerDirection={Direction()}>
          <IonLabel className='ion-text-center'>
            {row.id}
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      width: '120px'
    },
    {
      id: 'order_no',
      name: TEXT.reference,
      selector: (row: { id: number }) => row.id,
      cell: (row: { id: any, order_no: any, job: any }) => (
        <IonItem lines="none" detail={false} routerLink={
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          )
            ? RoutePath.jobView.replace(':id', row.job)
            : RoutePath.orderView.replace(':id', row.id)
        } routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-center'>
            {row.order_no}
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      width: '150px'
    },
    {
      id: 'create_at',
      name: TEXT.orderDateDepart,
      selector: (row: { create_at: any }) => row.create_at,
      cell: (row: { id: any; create_at: any, job: any }) => (
        <IonItem lines="none" detail={false} routerLink={
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          )
            ? RoutePath.jobView.replace(':id', row.job)
            : RoutePath.orderView.replace(':id', row.id)
        } routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-center'>
            {row.create_at ? moment.utc(row.create_at).local().format('LL') : ''}
            <div className='subtitle'>
              {row.create_at ? moment.utc(row.create_at).local().format('LT') : ''}
            </div>
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      width: '250px'
    },
    {
      id: 'status',
      name: TEXT.status,
      selector: (row: { status: any }) => row.status,
      cell: (row: { id: any; status: any }) => (
        <IonButtons>
          <IonButton fill='solid' color={colors[row.status]} onClick={() => setValue('status', row.status)}>
            <IonLabel>{statuses[row.status]}</IonLabel>
          </IonButton>
        </IonButtons>
      ),
      center: true,
      sortable: true,
    },
    {
      id: 'action',
      name: '',
      cell: (row: { id: any, name: string, job: any }) => (
        <IonButtons>
          {
            members.length && (members.includes('1') || members.includes('2') || members.includes('3')) ?
              <IonButton fill='outline' routerLink={RoutePath.jobView.replace(':id', row.job)}
                routerDirection={Direction()}>
                <IonIcon slot="start" icon={clipboardOutline} />
                <IonLabel>{BUTTON.job}</IonLabel>
              </IonButton>
              :
              <IonButton fill='outline' routerLink={RoutePath.orderView.replace(':id', row.id)}
                routerDirection={Direction()}>
                <IonIcon slot="start" icon={createOutline} />
                <IonLabel>{BUTTON.manage}</IonLabel>
              </IonButton>
          }
        </IonButtons>
      ),
      right: true,
      width: members.length && (members.includes('1') || members.includes('2')) ?
        '220px' : '110px'
    }
  ];
  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const colors: any = COLORS.order;
  const statuses: any = STATUS.order;

  const initialize = async () => {
    setInitialized(false);
    setShowLoading(true);

    const urlParams = new URLSearchParams(location.search);
    const typeParam = urlParams.get('type')
    if (typeParam) {
      await setValue('type', typeParam);
    }
    const statusParam = urlParams.get('status')
    if (statusParam) {
      await setValue('status', statusParam);
    }
    const searchParam = urlParams.get('search')
    if (searchParam) {
      await setValue('search', searchParam);
    }
    const dateFromParam = urlParams.get('from')
    if (dateFromParam) {
      await setValue('dateFrom', dateFromParam);
    }
    const dateToParam = urlParams.get('to')
    if (dateToParam) {
      await setValue('dateTo', dateToParam);
    }

    const userData: any = await UserService.getSession();
    if (userData) {
      setMembers(
        userData.members.map(
          ({ group_id }: any) => (
            group_id
          )
        )
      );
      await load(
        0,
        null,
        defaultSort.by,
        defaultSort.direction,
        getValues('status'),
        getValues('search'),
        getValues('dateFrom'),
        getValues('dateTo')
      );
    }

    setInitialized(true);
    setShowLoading(false);

  }

  const load = async (
    start: number = 0,
    limit: number | null = pageLimit,
    sortBy: string = defaultSort.by,
    sortDirection: string = defaultSort.direction,
    status: string = getValues('status'),
    search: string = getValues('search'),
    dateFrom: string = getValues('dateFrom'),
    dateTo: string = getValues('dateTo')
  ) => {
    setShowProgressLoading(true);
    setShowLoading(true);
    const count = () => {
      return new Promise(async (resolve) => {
        await OrderProductService.pickingCount(
          true,
          null,
          null,
          search
        ).then(async (rows: any) => {
          resolve(rows);
        });
      });
    }
    await count().then(async (rows: any) => {
      setTotal(rows);
      if (rows < offset) {
        start = 0;
        limit = null;
        sortBy = defaultSort.by;
        sortDirection = defaultSort.direction;
      }
      await OrderProductService.pickingList(
        true,
        { by: sortBy, direction: sortDirection },
        start,
        limit,
        search,
        ['product', 'order']
      ).then(async (data: any) => {
        setItems([]);
        setItems(data);
      });
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });
    setShowProgressLoading(false);
    setShowLoading(false);
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSort({ by: column.id, direction: sortDirection });
    load((page - 1) * pageLimit, pageLimit, column.id, sortDirection);
  };

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.pick) {
      if (initialized) {
        load();
      }
    }
  }, [location]);

  return (
    <IonPage>

      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{MENU.pick}</IonTitle>
          <IonButtons slot="end">
            <IonButton id="picking-sort">
              <IonIcon slot="icon-only" icon={filterOutline} />
            </IonButton>
            <IonPopover trigger="picking-sort" triggerAction="click" size="auto" dismissOnSelect>
              <IonContent>
                <IonList>
                  <IonItem button detail={false} onClick={() => handleSort(
                    { id: 'location' }, sort.by === 'location' ? sort.direction === 'desc' ? 'asc' : 'desc' : 'asc'
                  )
                  }>
                    <IonIcon slot="start" icon={sort.by === 'location' ? archive : archiveOutline} color={sort.by === 'location' ? 'primary' : 'dark'} />
                    <IonText color={sort.by === 'location' ? 'primary' : 'dark'}>{BUTTON.sortByLocation}</IonText>
                    <IonIcon slot="end" size="small" color={sort.by === 'location' ? 'primary' : 'dark'} icon={
                      sort.by === 'location' ? sort.direction === 'desc' ? arrowUpOutline : arrowDownOutline : arrowDownOutline
                    } />
                  </IonItem>
                  <IonItem button detail={false} lines='none' onClick={() => handleSort(
                    { id: 'create_at' }, sort.by === 'create_at' ? sort.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                  )
                  }>
                    <IonIcon slot="start" icon={sort.by === 'create_at' ? document : documentOutline} color={sort.by === 'create_at' ? 'primary' : 'dark'} />
                    <IonText color={sort.by === 'create_at' ? 'primary' : 'dark'}>{BUTTON.sortByOrder} </IonText>
                    <IonIcon slot="end" size="small" color={sort.by === 'create_at' ? 'primary' : 'dark'} icon={
                      sort.by === 'create_at' ? sort.direction === 'desc' ? arrowUpOutline : arrowDownOutline : arrowUpOutline
                    } />
                  </IonItem>
                </IonList>
              </IonContent>
            </IonPopover>
          </IonButtons>
        </IonToolbar>
        {
          showProgressLoading &&
          <IonProgressBar type="indeterminate" className='fixed'></IonProgressBar>
        }
      </IonHeader>

      <IonContent fullscreen>

        <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        />

        <AlertPrompt
          isPresent={presentAlert.isPresent}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonModal
          canDismiss
          swipeToClose
          isOpen={modalProductPreviewIsOpen}
          onDidDismiss={() => setModalProductPreviewIsOpen(false)}
        >
          <ModalProductPreview
            productId={idProductPreview}
            onDismiss={() => setModalProductPreviewIsOpen(false)}
          />
        </IonModal>

        <IonGrid className='grid-main grid-has-ios-header'>
          <IonRow>
            <IonCol>
              <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large" slot="start">{MENU.pick}</IonTitle>
                </IonToolbar>
              </IonHeader>
            </IonCol>
          </IonRow>
        </IonGrid>

        {
          initialized ?
            <>
              <IonGrid>
                <IonRow className='ion-margin-top'>

                  {/* Header */}
                  {
                    items && items.length ?
                      <IonCol size='12' className='ion-hide-lg-down'>
                        <IonCard className='table-header'>
                          <IonGrid>
                            <IonRow className='ion-align-items-center'>

                              {/* Location */}
                              <IonCol size='6' sizeMd='6' sizeLg='2'>
                                <IonLabel className='ion-margin-start'>
                                  {TEXT.location}
                                </IonLabel>
                              </IonCol>

                              {/* order_product_quantity_left */}
                              <IonCol size='6' sizeMd='6' sizeLg='1' className='ion-text-center'>
                                <IonLabel>
                                  {TEXT.pick}
                                </IonLabel>
                              </IonCol>

                              {/* Product */}
                              <IonCol size='12' sizeMd='6' sizeLg='4'>
                                <IonLabel className='ion-margin-start'>
                                  {TEXT.product}
                                </IonLabel>
                              </IonCol>

                              {/* Order */}
                              <IonCol size='12' sizeMd='6' sizeLg='3' className='ion-text-center'>
                                <IonLabel>
                                  {TEXT.order}
                                </IonLabel>
                              </IonCol>

                              {/* Checkmark */}
                              <IonCol size='12' sizeMd='6' sizeLg='2'>

                              </IonCol>

                            </IonRow>
                          </IonGrid>
                        </IonCard>
                      </IonCol>
                      : <></>
                  }

                  {/* Content */}
                  {
                    items && items.length ?
                      items.map((item: any, index: number) => {
                        return (
                          <IonCol key={index} size='12'>
                            <IonCard>
                              <IonGrid>
                                <IonRow className='ion-align-items-center'>

                                  {/* Location */}
                                  <IonCol size='6' sizeMd='6' sizeLg='2'>

                                    <CopyToClipboard text={item.location} onCopy={() => {
                                      setPresentToast({
                                        isPresent: true,
                                        status: true,
                                        message: `${item.location} ${TEXT.wasCopied}`,
                                        onDismiss: () => {
                                          if (ready) {
                                            setPresentToast(
                                              {
                                                isPresent: false,
                                                status: presentToast.status,
                                                message: presentToast.message,
                                                onDismiss: presentToast.onDismiss
                                              }
                                            );
                                          }
                                        }
                                      });
                                    }}
                                    >
                                      <IonButton fill='clear'>
                                        <IonIcon className='ion-hide-lg-up' slot='start' icon={archiveOutline}></IonIcon>
                                        {item.location}
                                        <IonIcon size="small" slot="end" icon={copyOutline} />
                                      </IonButton>
                                    </CopyToClipboard>

                                  </IonCol>

                                  {/* order_product_quantity_left */}
                                  <IonCol size='6' sizeMd='6' sizeLg='1'>

                                    <IonItem lines='none' className='ion-float-right'>
                                      <IonText>
                                        {item.order_product_quantity_left}
                                        <IonText color='medium' className='ion-hide-lg-up'>&nbsp;&nbsp;{TEXT.piece}</IonText>
                                      </IonText>
                                    </IonItem>
                                  </IonCol>

                                  {/* Product */}
                                  {
                                    item.product_reference ?
                                      <IonCol size='12' sizeMd='6' sizeLg='4'>
                                        <IonItem lines="none" className="pointer" onClick={() => { openProductPreviewModal(item.product_reference.id) }}>
                                          <IonText className='ion-text-wrap'>
                                            {
                                              item.product_reference.sku ?
                                                <>
                                                  <IonLabel className='ion-hide-sm-up'>
                                                    <IonText color='medium'>SKU:&nbsp;</IonText><b>{item.product_reference.sku}</b>
                                                  </IonLabel>
                                                </>
                                                : <></>
                                            }
                                            <div className='ion-hide-sm-down'>
                                              {item.product_reference ? item.product_reference.name : `${TEXT.productNotFound} (${item.product_reference.id})`}
                                            </div>
                                            {
                                              item.product_reference.sku ?
                                                <>
                                                  <IonLabel className='ion-hide-sm-down'>
                                                    <p className='text-full subtitle'>{item.product_reference.sku}</p>
                                                  </IonLabel>
                                                </>
                                                : <></>
                                            }
                                          </IonText>
                                        </IonItem>
                                      </IonCol>
                                      : <></>
                                  }

                                  {/* Order */}
                                  <IonCol size='12' sizeMd='6' sizeLg='3'>
                                    <IonItem lines='none' detail={false} routerLink={item.order_reference ? item.order_reference.job ? RoutePath.jobView.replace(':id', item.order_reference.job) : '' : ''} routerDirection={Direction()}>
                                      {/* Desktop */}
                                      <IonLabel className='ion-text-center ion-hide-lg-down'>
                                        {
                                          item.order_reference ?
                                            <>
                                              <IonText color='dark'>
                                                {
                                                  item.order_reference.id ? <h2>{TEXT.order} - {item.order_reference.id}</h2> : <></>
                                                }
                                              </IonText>

                                              <IonText color='medium'>
                                                {
                                                  item.order_reference.order_no ? <div className='subtitle'>({item.order_reference.order_no})</div> : <></>
                                                }
                                              </IonText>
                                            </>
                                            : <></>
                                        }
                                      </IonLabel>

                                      {/* Mobile */}
                                      <IonLabel className='ion-text-left ion-hide-lg-up'>
                                        {
                                          item.order_reference ?
                                            <>
                                              <IonText color='dark'>
                                                {
                                                  item.order_reference.id ? <span><b>{TEXT.order} - {item.order_reference.id}</b></span> : <></>
                                                }
                                              </IonText>

                                              <IonText color='medium'>
                                                {
                                                  item.order_reference.order_no ? <span className='subtitle'>&nbsp;({item.order_reference.order_no})</span> : <></>
                                                }
                                              </IonText>
                                            </>
                                            : <></>
                                        }
                                      </IonLabel>
                                    </IonItem>
                                  </IonCol>

                                  {/* Checkmark */}
                                  <IonCol size='12' sizeLg='2'>
                                    <IonItem lines='none' class='ion-float-md-right'>
                                      <IonCheckbox slot="start" color='success'
                                      checked={selectedItems.filter((selectedItem: any) => selectedItem === item.id).length > 0 ? true : false}
                                      onIonChange={(event: any) => {
                                        if (event.detail.checked) {
                                          setSelectedItems([item.id, ...selectedItems]);
                                        } else {
                                          setSelectedItems((selectedItems: Array<any>) => selectedItems.filter((selectedItem: any) => selectedItem !== item.id));
                                        }
                                      }}>
                                      </IonCheckbox>
                                      <IonLabel>{TEXT.pickAlready}</IonLabel>
                                    </IonItem>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonCard>
                          </IonCol>
                        )
                      })
                      :
                      <IonGrid>
                        <IonRow className='ion-text-center'>
                          <IonCol size='12'>
                            <IonText color='medium'>
                              <IonLabel>
                                {TEXT.noDataComponent}
                              </IonLabel>
                            </IonText>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                  }
                </IonRow>
              </IonGrid>
            </>
            : <></>
        }

      </IonContent>
    </IonPage >
  );
};

export default Pick;