//  Slide option product image
export const slideOptsImage = {
    initialSlide: 0,
    // speed: 400,
};

//  Slide option
export const slideOpts = {
    initialSlide: 0,
    autoHeight: true,
    allowTouchMove: false
};