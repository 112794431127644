import { isPlatform } from "@ionic/react";

export const RoutePath: any = {

  index: process.env.PUBLIC_URL + '/',
  login: process.env.PUBLIC_URL + '/login',
  home: process.env.PUBLIC_URL + '/',

  product: process.env.PUBLIC_URL + '/products',
  productAdd: process.env.PUBLIC_URL + '/products/add',
  productEdit: process.env.PUBLIC_URL + '/products/:id/edit',
  productView: process.env.PUBLIC_URL + '/products/:id/view',
  productLot: process.env.PUBLIC_URL + '/products/lot-location/:id',

  productImport: process.env.PUBLIC_URL + '/products/import',
  productImportAdd: process.env.PUBLIC_URL + '/products/import/add',
  productImportAddCheck: process.env.PUBLIC_URL + '/products/import/add/check',
  productImportEdit: process.env.PUBLIC_URL + '/products/import/:id/edit',
  productImportView: process.env.PUBLIC_URL + '/products/import/:id/view',

  productHistory: process.env.PUBLIC_URL + '/products/history',
  productHistoryView: process.env.PUBLIC_URL + '/products/history/:id/view',

  productCancel: process.env.PUBLIC_URL + '/products/cancel',
  productCancelAdd: process.env.PUBLIC_URL + '/products/cancel/add',
  productCancelAddCheck: process.env.PUBLIC_URL + '/products/cancel/add/check',
  productCancelEdit: process.env.PUBLIC_URL + '/products/cancel/:id/edit',
  productCancelView: process.env.PUBLIC_URL + '/products/cancel/:id/view',

  productWithdraw: process.env.PUBLIC_URL + '/products/withdraw',
  productWithdrawAdd: process.env.PUBLIC_URL + '/products/withdraw/add',
  productWithdrawEdit: process.env.PUBLIC_URL + '/products/withdraw/:id/edit',
  productWithdrawAddCheck: process.env.PUBLIC_URL + '/products/withdraw/add/check',
  productWithdrawView: process.env.PUBLIC_URL + '/products/withdraw/:id/view',

  productReturn: process.env.PUBLIC_URL + '/products/return',
  productReturnAdd: process.env.PUBLIC_URL + '/products/return/add',
  productReturnAddCheck: process.env.PUBLIC_URL + '/products/return/add/check',
  productReturnView: process.env.PUBLIC_URL + '/products/return/:id/view',

  order: process.env.PUBLIC_URL + '/orders',
  orderView: process.env.PUBLIC_URL + '/orders/:id/view',
  orderPackAdd: process.env.PUBLIC_URL + '/orders/:id/pack/add',
  orderPackEdit: process.env.PUBLIC_URL + '/orders/:id/pack/:packID/edit',

  vendor: process.env.PUBLIC_URL + '/vendors',
  vendorView: process.env.PUBLIC_URL + '/vendors/:id/view',
  vendorAdd: process.env.PUBLIC_URL + '/vendors/add',
  vendorEdit: process.env.PUBLIC_URL + '/vendors/:id/edit',

  manufacturer: process.env.PUBLIC_URL + '/manufacturers',
  manufacturerAdd: process.env.PUBLIC_URL + '/manufacturers/add',
  manufacturerEdit: process.env.PUBLIC_URL + '/manufacturers/:id/edit',

  distributor: process.env.PUBLIC_URL + '/distributors',
  distributorAdd: process.env.PUBLIC_URL + '/distributors/add',
  distributorEdit: process.env.PUBLIC_URL + '/distributors/:id/edit',

  brand: process.env.PUBLIC_URL + '/brands',
  brandAdd: process.env.PUBLIC_URL + '/brands/add',
  brandEdit: process.env.PUBLIC_URL + '/brands/:id/edit',

  warehouse: process.env.PUBLIC_URL + '/warehouses',
  warehouseAdd: process.env.PUBLIC_URL + '/warehouses/add',
  warehouseEdit: process.env.PUBLIC_URL + '/warehouses/:id/edit',
  warehouseProducts: process.env.PUBLIC_URL + '/warehouses/:id/products',

  job: process.env.PUBLIC_URL + '/jobs',
  jobAdd: process.env.PUBLIC_URL + '/jobs/add',
  jobEdit: process.env.PUBLIC_URL + '/jobs/:id/edit',
  jobView: process.env.PUBLIC_URL + '/jobs/:id/view',

  category: process.env.PUBLIC_URL + '/categories',
  categoryAdd: process.env.PUBLIC_URL + '/categories/add',
  categoryEdit: process.env.PUBLIC_URL + '/categories/:id/edit',

  sendParcel: process.env.PUBLIC_URL + '/pack',
  sendParcelAdd: process.env.PUBLIC_URL + '/pack/add',
  sendParcelAddCheck: process.env.PUBLIC_URL + '/pack/add/check',
  sendParcelView: process.env.PUBLIC_URL + '/pack/:id/view',

  administrator: process.env.PUBLIC_URL + '/administrators',
  administratorAdd: process.env.PUBLIC_URL + '/administrators/add',
  administratorEdit: process.env.PUBLIC_URL + '/administrators/:id/edit',
  administratorView: process.env.PUBLIC_URL + '/administrators/:id/view',

  setting: process.env.PUBLIC_URL + '/setting',
  termCondition: process.env.PUBLIC_URL + '/register',

  register: process.env.PUBLIC_URL + '/register/new',
  registerWait: process.env.PUBLIC_URL + '/register/approve',
  registerWelcome: process.env.PUBLIC_URL + '/welcome',

  courierNotify: process.env.PUBLIC_URL + '/courier',

  transferAdd: process.env.PUBLIC_URL + '/transfers/add',
  transferEdit: process.env.PUBLIC_URL + '/transfers/:id/edit',
  transferView: process.env.PUBLIC_URL + '/transfers/:id/view',

  pick: process.env.PUBLIC_URL + '/pick',

  log: process.env.PUBLIC_URL + '/logs',

};

export const RoutePathPublic: Array<any> = [
  RoutePath.index,
  RoutePath.login,
  RoutePath.termCondition,
  RoutePath.register,
  RoutePath.registerWait,
  RoutePath.registerWelcome
]

export const GoBack = (fallbackPath: string = '') => {
  window.history.go(-1);
}

export const Direction = () => {
  return !isPlatform('desktop') ? 'forward' : 'none';
}