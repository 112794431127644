import '../../css/Modal.scss';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonThumbnail,
  IonImg,
  SearchbarCustomEvent,
  IonModal
} from "@ionic/react";
import {
  addOutline,
  closeOutline
} from "ionicons/icons";

import { useEffect, useState } from "react";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import { ModalBrandsAdd } from "./BrandsAdd";

import BrandService from "../../services/BrandService";

export const ModalBrands: React.FC<{
  title: string;
  selected?: string;
  onDismiss: (brand?: any) => any;
}> = ({ title = TEXT.brand, selected = '', onDismiss }) => {

  const pageLimit = 30;

  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [brands, setBrands] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [modalBrandIsOpen, setModalBrandIsOpen] = useState<boolean>(false);

  const searchBrands = async (search: any = '', event: any = null) => {
    setSearchText(search);
    let start = 0;
    if (event) {
      start = brands.length;
    }
    BrandService.list(
      true,
      { by: 'name', direction: 'asc' },
      start, pageLimit,
      search,
      ['logo']
    ).then((data: any) => {
      if (event) {
        setBrands([
          ...brands,
          ...data
        ]);
        event.target.complete();
        if (!data.length) {
          setInfiniteDisabled(true);
        } else {
          setInfiniteDisabled(false);
        }
      } else {
        setBrands(data);
      }
    });
  }

  const setBrand = (brand: any) => {
    onDismiss(brand);
  }

  useEffect(() => {
    searchBrands();
  }, []);

  return (
    <>

      <IonModal canDismiss swipeToClose isOpen={modalBrandIsOpen} onDidDismiss={() => setModalBrandIsOpen(false)}>
        <ModalBrandsAdd
          name={searchText}
          onDismiss={
            (data: any) => {
              if (data) {
                searchBrands(data.name);
              }
              setModalBrandIsOpen(false);
            }
          }
        />
      </IonModal>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton className='md-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder={TEXT.brandName}
            inputmode="none"
            onIonChange={(e: SearchbarCustomEvent) => searchBrands(e.detail.value)}
          >
          </IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent className="search">

        <IonList>
          {
            (searchText) ?
              <IonItem button detail={false} onClick={() => setModalBrandIsOpen(true)}>
                <IonIcon slot="start" size='small' icon={addOutline} />
                <IonLabel>{TEXT.add} "{searchText}"</IonLabel>
              </IonItem>
              : <></>
          }
          {
            (brands.length && !searchText) ?
              <IonItem button detail={false} onClick={() => setBrand(false)}>
                <IonLabel>{TEXT.brandUnbranded}</IonLabel>
                <IonIcon slot="end" icon={closeOutline} />
              </IonItem>
              : <></>
          }
          {
            brands.map((item, index: number) => (
              <IonItem button detail={false} key={`brand-${index}`} onClick={() => setBrand(item)}
                className={selected && selected === item.id ? 'selected' : ''}>
                <IonThumbnail slot="start">
                  {
                    item.logo &&
                    <IonImg src={item.logo_reference.thumbnail ? item.logo_reference.thumbnail : item.logo_reference.original} />
                  }
                </IonThumbnail>
                <IonLabel>{item.name}</IonLabel>
              </IonItem>
            ))
          }
        </IonList>

        <IonInfiniteScroll
          onIonInfinite={e => searchBrands(searchText, e)}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>

      </IonContent>

    </>
  );

};