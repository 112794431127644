import { 
  URL_CANCEL,
} from '../constances/Rest';

import axios from '../configs/axios';

import RestService from './RestService';

const CancelService = {
  list: async (
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    status: string|null = null,
    search: string|null = null,
    dateFrom: any = null,
    dateTo: any = null,
    returnReferences: any = false,
    vendorID: string|null = null,
    warehouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (status) {
				options.status = status;
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'id',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        );
        conjunction = 'AND';
			}
			if (dateFrom) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '>=',
            value: `'${dateFrom}'`
          }
        );
        conjunction = 'AND';
			}
			if (dateTo) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '<=',
            value: `'${dateTo}'`
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_CANCEL}list${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  listByJob: async (
    jobID: string,
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null,
    returnReferences: any = false,
    vendorID: any = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
      if (jobID) {
        payload.job = jobID;
      }
			if (search) {
				payload.extensions = [
          {
            conjunction: '',
            key: 'name',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        ];
			}

      await axios.post(
        RestService.route(`${URL_CANCEL}list${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  listByVendor: async (
    vendorID: string,
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    status: string|null = null,
    search: string|null = null,
    dateFrom: any = null,
    dateTo: any = null,
    returnReferences: any = false,
    jobID: string|null = null,
    warehouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options['order.active'] = true;
			} else if (active === false) {
				options['order.active'] = false;
			} else if (active) {
				options['order.active'] = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (status) {
				options['order.status'] = status;
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
      if (jobID) {
        options.job = jobID;
      }
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'order.id',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'order.order_no',
                operator: 'LIKE',
                value: `'%${search}%'`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
			if (dateFrom) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'create_at',
                operator: '>=',
                value: `'${dateFrom}'`
              },
              {
                conjunction: 'OR',
                key: 'order.order_date',
                operator: '>=',
                value: `'${dateFrom}'`
              }
            ]
          }
        );
        conjunction = 'AND';
			}
			if (dateTo) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'order.create_at',
                operator: '<=',
                value: `'${dateTo}'`
              },
              {
                conjunction: 'OR',
                key: 'order.order_date',
                operator: '<=',
                value: `'${dateTo}'`
              }
            ]
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_CANCEL}list_by_vendor${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  count: async (
    active: boolean|null = null, 
    start: number|null = null,
    limit: number|null = null,
    status: string|null = null,
    search: string|null = null,
    dateFrom: any = null,
    dateTo: any = null,
    vendorID: string|null = null,
    warehouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (status) {
				options.status = status;
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'id',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        );
        conjunction = 'AND';
			}
			if (dateFrom) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '>=',
            value: `'${dateFrom}'`
          }
        );
        conjunction = 'AND';
			}
			if (dateTo) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '<=',
            value: `'${dateTo}'`
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_CANCEL}count${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response.result);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  countByVendor: async (
    vendorID: string|null = null,
    active: boolean|null = null, 
    start: number|null = null,
    limit: number|null = null,
    status: string|null = null,
    search: string|null = null,
    dateFrom: any = null,
    dateTo: any = null,
    jobID: string|null = null,
    warehouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options['order.active'] = true;
			} else if (active === false) {
				options['order.active'] = false;
			} else if (active) {
				options['order.active'] = active;
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (status) {
				options['order.status'] = status;
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
      if (jobID) {
        options.job = jobID;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'id',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'order_no',
                operator: 'LIKE',
                value: `'%${search}%'`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
			if (dateFrom) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'create_at',
                operator: '>=',
                value: `'${dateFrom}'`
              },
              {
                conjunction: 'OR',
                key: 'order_date',
                operator: '>=',
                value: `'${dateFrom}'`
              }
            ]
          }
        );
        conjunction = 'AND';
			}
			if (dateTo) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'create_at',
                operator: '<=',
                value: `'${dateTo}'`
              },
              {
                conjunction: 'OR',
                key: 'order_date',
                operator: '<=',
                value: `'${dateTo}'`
              }
            ]
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_CANCEL}count_by_vendor${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response.result);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  get: async (id: string|number, active: boolean|null = null, returnReferences: any = false) => {
		return new Promise(async (resolve, reject) => {

      let options: any = {};
      if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }

      await axios.get(
        RestService.route(`${URL_CANCEL}${id}${RestService.handleQuery(options)}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
		});
  },
  create: async (values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.post(
        RestService.route(`${URL_CANCEL}create`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  update: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;
      payload.id = id;

      await axios.put(
        RestService.route(`${URL_CANCEL}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  delete: async (id: string|number) => {
		return new Promise(async (resolve, reject) => {

      await axios.delete(
        RestService.route(`${URL_CANCEL}${id}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  patch: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.patch(
        RestService.route(`${URL_CANCEL}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  changeStatus: async (id: string|number, values: any) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = {
        status: values
      };

      await axios.patch(
        RestService.route(`${URL_CANCEL}${id}/status`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  upload: async (id: string|number, values: any) => {
		return new Promise(async (resolve, reject) => {

      await axios.post(
        RestService.route(`${URL_CANCEL}${id}/upload`),
        await RestService.createFormData(values),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  import: async (values: any, id: any = null) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      if (id) {
        await axios.post(
          RestService.route(`${URL_CANCEL}${id}/import`),
          payload,
          { headers: await RestService.retrieveHeader(true) }
        ).then(async (response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error.message);
        });
      } else {
        await axios.post(
          RestService.route(`${URL_CANCEL}import`),
          payload,
          { headers: await RestService.retrieveHeader(true) }
        ).then(async (response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error.message);
        });
      }

		});
  },
};

export default CancelService;