import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent
} from "@ionic/react";
import {
  closeOutline
} from "ionicons/icons";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';
import { ToastStatus } from '../toasts/ToastStatus';

export const ModalLocationScanner: React.FC<{
  reference?: any;
  onDismiss: (checks: any, reference?: any) => any;
}> = ({
  reference = null,
  onDismiss
}) => {

    const [presentToast, setPresentToast] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });

    useEffect(() => {
    }, []);

    return (
      <>

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton className='ios-only' onClick={() => onDismiss(true)}>
                <IonIcon slot="start" icon={closeOutline} />
                {BUTTON.close}
              </IonButton>
            </IonButtons>
            <IonTitle>{TEXT.scan}</IonTitle>
          <IonButtons slot="end">
            <IonButton className='md-only' onClick={() => onDismiss(true)}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className='content-scanner'>
          <div className='scanner'>
            <BarcodeScannerComponent
              onUpdate={(error: any, result: any) => {
                if (result) {
                  onDismiss(result.text, reference);
                }
              }}
            />
          </div>
          <div className='scanner-guideline-wrapper'>
            <div className='square scanner-qr-guideline'>
            </div>
            <div className='scanner-barcode-guideline'>
            </div>
          </div>
        </IonContent>
      </>
    );

  };