import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import Barcode from "react-hooks-barcode";

import {
  IonContent,
  IonGrid,
  IonCol,
  IonLabel,
  IonRow,
  IonButton,
  IonIcon,
  IonBadge,
  IonButtons,
  IonToolbar,
  IonLoading,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonThumbnail,
  IonImg,
  IonAccordionGroup,
  IonAccordion,
  IonChip,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonList,
  IonPopover,
  IonRadioGroup,
  IonRadio,
  IonInput,
  IonCardContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  useIonAlert,
  SearchbarCustomEvent,
  IonModal
} from "@ionic/react";
import {
  copyOutline,
  cubeOutline,
  downloadOutline
} from "ionicons/icons";

import moment from 'moment';

import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MESSAGE from '../constances/Messages.json';

import { Direction, RoutePath } from "./RoutePath";
import { pageTable } from "./TableStyle";
import { AlertPrompt } from "./alerts/AlertPrompt";
import { AlertStatus } from "./alerts/AlertStatus";
import { ToastStatus } from "./toasts/ToastStatus";

import UserService from "../services/UserService";
import TransferService from "../services/TransferService";
import TransferProductService from "../services/TransferProductService";
import { useHistory } from "react-router-dom";
import { ModalProductPreview } from "./modal/ProductPreview";

const Transfer: React.FC<{
  id?: any;
  doChangeStatus?: any;
  doCheck?: any;
  displayInitialLoading?: boolean;
  onStatusChange?: (data?: any) => any;
  onCancelStatusChange?: (data?: any) => any;
  onDidLoad?: (data?: boolean) => any;
  onDidScannerReady?: (data?: boolean) => any;
  onLoad?: (data?: boolean) => any;
  reload?: any;
  className?: string;
}> = ({
  id = null,
  doChangeStatus = null,
  doCheck = null,
  displayInitialLoading = true,
  onStatusChange,
  onCancelStatusChange,
  onDidLoad,
  onDidScannerReady,
  onLoad,
  reload = null,
  className = ''
}) => {

    const offset: number = 50;
    const defaultSort: any = {
      by: 'create_at',
      direction: 'asc'
    }

    const colors: any = COLORS.transfer;
    const statuses: any = STATUS.transfer;
    const colorsProduct: any = COLORS.transferProduct;
    const statusesProduct: any = STATUS.transferProduct;

    const [members, setMembers] = useState<Array<any>>([]);
    const [transfer, setTransfer] = useState<any>(null);
    const [products, setProducts] = useState<any>([]);
    const [status, setStatus] = useState<any>(null);
    const [warehouse, setWarehouse] = useState<any>(null);

    const [ready, setReady] = useState(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState(false);
    const [importResult, setImportResult] = useState<any>(null);

    const [page, setPage] = useState<number>(1);
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [sort, setSort] = useState<any>(defaultSort);
    const [total, setTotal] = useState<number>(0);
    const [totalAll, setTotalAll] = useState<number>(0);
    const [totalPending, setTotalPending] = useState<number>(0);
    const [totalComplete, setTotalComplete] = useState<number>(0);
    const [columnsExport, setColumnsExport] = useState<any>({
      'SKU': true,
      'BARCODE': true,
      'NAME': true,
      'QUANTITY': true,
      'LOCATION_FROM': true,
      'LOCATION_TO': true,
      'STATUS': true
    });

    const [modalProductPreviewIsOpen, setModalProductPreviewIsOpen] = useState<boolean>(false);
    const [idProductPreview, setIdProductPreview] = useState<any>(null);

    const openProductPreviewModal = (id: any) => {
      if (id) {
        setIdProductPreview(id);
        setModalProductPreviewIsOpen(true);
      }
    };

    const { register, getValues, setValue } = useForm();
    const [transferStatus] = useState<any>(
      register('transferStatus', {
        value: '',
        onChange: (e: any) => {
          changeStatus(e.transfer, e.detail.value, e.ready);
        }
      })
    );
    const [transferDate] = useState<any>(
      register('transferDate', {
        value: ''
      })
    );
    const [filterSearch] = useState<any>(
      register('search', {
        value: ''
      })
    );
    const [filterStatus] = useState<any>(
      register('status', {
        value: '',
        onChange: (e: any) => {
          loadProducts(e.transferID, 0, pageLimit, sort.by, sort.direction, getValues('search'), e.detail.value);
        }
      })
    );

    const [presentToast, setPresentToast] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlert, setPresentAlert] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
      isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, message: '', onDismiss: () => { }
    });
    const [presentAlertCounted, dismissAlertCounted] = useIonAlert();

    const columnsProducts: Array<any> = [
      {
        id: 'barcode',
        name: TEXT.barcode,
        selector: (row: { barcode: any }) => row.barcode,
        cell: (row: { product_reference: any }) => (
          <div className='barcode ion-text-center'>
            <Barcode value={row.product_reference ? row.product_reference.barcode : ''} />
          </div>
        ),
        width: '150px',
        center: true
      },
      {
        id: 'product.sku',
        name: TEXT.productHeaderNameSKU,
        selector: (row: {
          product: any, product_reference: any
        }) => row.product_reference ? row.product_reference.sku : row.product,
        cell: (row: { product: any, product_reference: any }) => (
          // <IonItem lines="none" routerLink={
          //   row.product_reference ? RoutePath.productView.replace(':id', row.product_reference.id)
          //     : null
          // } routerDirection={Direction()}>
          <IonItem lines="none" className="pointer" onClick={() => { openProductPreviewModal(row.product_reference.id) }}>
            {
              row.product_reference && row.product_reference.figure_reference && row.product_reference.figure_reference.length ?
                <IonThumbnail slot="end">
                  <IonImg src={
                    row.product_reference.figure_reference[0].thumbnail
                      ? row.product_reference.figure_reference[0].thumbnail
                      : row.product_reference.figure_reference[0].original
                  } />
                </IonThumbnail>
                : <></>
            }
            <IonLabel>
              <h2 className='text-full'>
                {row.product_reference ? row.product_reference.name : `${TEXT.productNotFound} (${row.product})`}
              </h2>
              {
                row.product_reference ?
                  <p className='text-full subtitle'>{row.product_reference.sku}</p>
                  : <></>
              }
            </IonLabel>
          </IonItem>
        ),
        minWidth: '300px',
      },
      {
        id: 'origin_product_warehouse',
        name: `${TEXT.slotFrom}${TEXT.location}`,
        selector: (row: any) => (
          row.origin_product_warehouse_reference ?
            `${row.origin_product_warehouse_reference.warehouse ? row.origin_product_warehouse_reference.warehouse : ''
            }.${row.origin_product_warehouse_reference.floor ? row.origin_product_warehouse_reference.floor : ''
            }.${row.origin_product_warehouse_reference.aisle ? row.origin_product_warehouse_reference.aisle : ''
            }.${row.origin_product_warehouse_reference.bay ? row.origin_product_warehouse_reference.bay : ''
            }.${row.origin_product_warehouse_reference.level ? row.origin_product_warehouse_reference.level : ''
            }.${row.origin_product_warehouse_reference.bin ? row.origin_product_warehouse_reference.bin : ''
            }`
            : row.origin_product_warehouse
        ),
        cell: (row: any) => (
          row.origin_product_warehouse_reference ?
            `${row.origin_product_warehouse_reference.warehouse ? row.origin_product_warehouse_reference.warehouse : ''
            }.${row.origin_product_warehouse_reference.floor ? row.origin_product_warehouse_reference.floor : ''
            }.${row.origin_product_warehouse_reference.aisle ? row.origin_product_warehouse_reference.aisle : ''
            }.${row.origin_product_warehouse_reference.bay ? row.origin_product_warehouse_reference.bay : ''
            }.${row.origin_product_warehouse_reference.level ? row.origin_product_warehouse_reference.level : ''
            }.${row.origin_product_warehouse_reference.bin ? row.origin_product_warehouse_reference.bin : ''
            }`
            : '-'
        ),
        center: true,
        minWidth: '100px',
      },
      {
        id: 'destination_warehouse',
        name: `${TEXT.slotTo}${TEXT.location}`,
        selector: (row: any) => (
          row.destination_warehouse ?
            `${row.destination_warehouse ? row.destination_warehouse : ''
            }.${row.destination_floor ? row.destination_floor : ''
            }.${row.destination_aisle ? row.destination_aisle : ''
            }.${row.destination_bay ? row.destination_bay : ''
            }.${row.destination_level ? row.destination_level : ''
            }.${row.destination_bin ? row.destination_bin : ''
            }`
            : '-'
        ),
        cell: (row: any) => (
          row.destination_warehouse ?
            `${row.destination_warehouse ? row.destination_warehouse : ''
            }.${row.destination_floor ? row.destination_floor : ''
            }.${row.destination_aisle ? row.destination_aisle : ''
            }.${row.destination_bay ? row.destination_bay : ''
            }.${row.destination_level ? row.destination_level : ''
            }.${row.destination_bin ? row.destination_bin : ''
            }`
            : '-'
        ),
        center: true,
        minWidth: '100px',
      },
      {
        id: 'quantity',
        name: TEXT.qtyPiece,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { quantity: any }) => (
          <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true}
            className="text-number" />
        ),
        right: true,
        minWidth: '100px',
      },
      {
        id: 'status',
        name: TEXT.status,
        selector: (row: { status: any }) => row.status,
        cell: (row: { id: any, status: any, counted: any, quantity: any }) =>
        (
          <>
            <IonButtons className="w-100">
              <IonButton className="w-100" id={`status-${row.id}`} fill='solid' color={colorsProduct[row.status]}
                disabled={status === 'complete' || status === 'cancel' ? true : false}
                onClick={
                  () => {
                    {
                      !members.length || (
                        !members.includes('1')
                        && !members.includes('2')
                        && !members.includes('3')
                      )
                        && setValue('status', row.status)
                    }
                  }
                }>
                <IonLabel>{statusesProduct[row.status]}</IonLabel>
              </IonButton>
            </IonButtons>
            {
              members.length && (
                members.includes('1')
                || members.includes('2')
                || members.includes('3')
              )
                ?
                <IonPopover trigger={`status-${row.id}`} dismissOnSelect={true} side="end">
                  <IonContent>
                    <IonList>
                      <IonRadioGroup value={row.status}>
                        {
                          Object.keys(statusesProduct).map((key: any, index: number) => (
                            <IonItem key={`status-option-${index}`} lines="none" button={true} detail={false}
                              onClick={() => changeProductStatus(row.id, key, row.counted ? row.counted : 0, row.quantity ? row.quantity : 0)}>
                              <IonLabel>{statusesProduct[key]}</IonLabel>
                              <IonRadio slot="start" value={key} />
                            </IonItem>
                          ))
                        }
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonPopover>
                : <></>
            }
          </>
        ),
        minWidth: '150px',
        center: true
      }
    ];

    const paginationComponentOptions = {
      rowsPerPageText: TEXT.rowsPerPage,
      rangeSeparatorText: TEXT.rangeSeparator,
      selectAllRowsItemText: TEXT.selectAllRowsItem,
    };

    const initialize = async () => {
      setInitialized(false);
      if (displayInitialLoading) {
        setShowLoading(true);
      }
      setImportResult(null);
      setTransfer(null);
      setProducts([]);
      setTotal(0);
      setTotalAll(0);
      setTotalPending(0);
      setTotalComplete(0);
      const userData: any = await UserService.getSession();
      if (userData) {
        setMembers(
          userData.members.map(
            ({ group_id }: any) => (
              group_id
            )
          )
        );
        await load(id);
      }
      setInitialized(true);
      onDidLoad && onDidLoad(true);
      setShowLoading(false);
    }

    const load = async (id: string | null | undefined = null, force: boolean = true) => {
      if (id) {
        await TransferService.get(id, null, true).then(async (data: any) => {
          setTransfer(data);
          setStatus(data.status);
          setValue('transferStatus', data.status);
          setValue('transferDate', data.transfer_date ? moment.utc(data.transfer_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        await loadProducts(id, 0, pageLimit, defaultSort.by, defaultSort.direction, getValues('search'), getValues('status'));
      }
    }

    const loadProducts = async (
      id: string,
      start: number = 0,
      limit: number | null = pageLimit,
      sortBy: string = defaultSort.by,
      sortDirection: string = defaultSort.direction,
      search: string = getValues('search'),
      status: string = getValues('status')
    ) => {
      if (id) {
        onLoad && onLoad(true);
        const count = (countStatus: any = '') => {
          return new Promise(async (resolve) => {
            await TransferProductService.count(
              id,
              null,
              null,
              null,
              search,
              countStatus
            ).then(async (rows: any) => {
              resolve(rows);
            });
          });
        }
        await count(status).then(async (rows: any) => {
          setTotal(rows);
          if (rows < offset) {
            start = 0;
            limit = null;
            sortBy = defaultSort.by;
            sortDirection = defaultSort.direction;
          }
          await TransferProductService.list(
            id,
            null,
            { by: sortBy, direction: sortDirection },
            start,
            limit,
            search,
            status,
            ['product', 'origin_product_warehouse']
          ).then((data: any) => {
            setProducts(data);
          }).catch((error) => {
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
        });
        onLoad && onLoad(false);
        count().then(async (rows: any) => {
          setTotalAll(rows);
        });
        count('pending').then(async (rows: any) => {
          setTotalPending(rows);
        });
        count('complete').then(async (rows: any) => {
          setTotalComplete(rows);
        });
      }
    }

    const changeStatus = (transfer: any, value: any, ready: any) => {
      if (transfer && transfer.status !== value) {

        const success = async () => {
          onStatusChange && onStatusChange(value);
          setPresentToast({
            isPresent: true,
            status: true,
            message: MESSAGE.success.complete,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        }

        const patch = async (id: any, status: any) => {
          setShowLoading(true);
          await TransferService.changeStatus(id, status).then(async () => {
            success();
            await load(id);
          }).catch((error) => {
            setValue('transferStatus', transfer.status);
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
          setShowLoading(false);
        }

        if (value === 'complete' || value === 'cancel') {
          setPresentAlertPrompt({
            isPresent: true,
            message: value === 'complete' ? MESSAGE.info.transferChangeStatusComplete : MESSAGE.info.transferChangeStatusCancel,
            onDismiss: (data: boolean) => {
              onCancelStatusChange && onCancelStatusChange(true);
              if (ready) {
                setPresentAlertPrompt(
                  {
                    isPresent: false,
                    message: presentAlertPrompt.message,
                    onDismiss: presentAlertPrompt.onDismiss
                  }
                );
                if (data) {
                  patch(transfer.id, value);
                } else {
                  setValue('transferStatus', transfer.status);
                }
              }
            }
          });
        } else {
          patch(transfer.id, value);
        }

      }
    }

    const changeProductStatus = (transferProductID: any, value: any, counted: any = 0, maxCounted: any = null) => {

      const success = async (transferID: any) => {
        if (transferID) {
          await load(transferID);
        }
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (transferProductID: any, status: any, counted: any = null) => {
        setShowLoading(true);
        await TransferProductService.changeStatus(transferProductID, status, counted).then(async () => {
          success(transfer ? transfer.id : null);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        setShowLoading(false);
      }

      if (value === 'complete' || value === 'cancel') {
        setPresentAlertPrompt({
          isPresent: true,
          message: value === 'complete' ? MESSAGE.info.transferProductChangeStatusComplete : MESSAGE.info.transferChangeStatusCancel,
          onDismiss: (data: boolean) => {
            if (ready) {
              setPresentAlertPrompt(
                {
                  isPresent: false,
                  message: presentAlertPrompt.message,
                  onDismiss: presentAlertPrompt.onDismiss
                }
              );
              if (data) {
                patch(transferProductID, value);
              }
            }
          }
        });
      } else {
        patch(transferProductID, value);
      }

    }

    const changeTransferDate = (transfer: any, value: any, ready: any) => {

      const success = async () => {
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (id: any, date: any) => {
        await TransferService.patch(id, { 'transfer_date': date }).then(async () => {
          success();
        }).catch((error) => {
          setValue('transferDate', transfer.transfer_date ? moment.utc(transfer.transfer_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      }

      patch(transfer.id, value ? value.replace('T', ' ') : null);

    }

    const changeExpectDate = (transfer: any, value: any, ready: any) => {

      const success = async () => {
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (id: any, date: any) => {
        await TransferService.patch(id, { 'expect_date': date }).then(async () => {
          success();
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      }

      patch(transfer.id, value ? value.replace('T', ' ') : null);

    }

    const downloadReport = async () => {

      setShowLoading(true);

      const status = getValues('status');
      let statusText: string = '';
      if (status === 'empty') {
        statusText = TEXT.asnProductNotFound;
      } else if (status === 'lack') {
        statusText = TEXT.lack;
      } else if (status === 'over') {
        statusText = TEXT.over;
      }

      const searchText = getValues('search');

      const csv = async (content: any) => {

        let rows: any = [];

        await rows.push(
          await Object.keys(columnsExport).filter((item: any) => {
            return columnsExport[item] === true;
          })
        );

        for (let item of content) {

          let locationCodeFrom: string = '';
          if (item.origin_product_warehouse_reference) {
            locationCodeFrom = (item.origin_product_warehouse_reference.warehouse ? item.origin_product_warehouse_reference.warehouse : '')
              + '.' + (item.origin_product_warehouse_reference.floor ? item.origin_product_warehouse_reference.floor : '')
              + '.' + (item.origin_product_warehouse_reference.aisle ? item.origin_product_warehouse_reference.aisle : '')
              + '.' + (item.origin_product_warehouse_reference.bay ? item.origin_product_warehouse_reference.bay : '')
              + '.' + (item.origin_product_warehouse_reference.level ? item.origin_product_warehouse_reference.level : '')
              + '.' + (item.origin_product_warehouse_reference.bin ? item.origin_product_warehouse_reference.bin : '');
          }

          let locationCodeTo: string = (item.destination_warehouse ? item.destination_warehouse : '')
            + '.' + (item.destination_floor ? item.destination_floor : '')
            + '.' + (item.destination_aisle ? item.destination_aisle : '')
            + '.' + (item.destination_bay ? item.destination_bay : '')
            + '.' + (item.destination_level ? item.destination_level : '')
            + '.' + (item.destination_bin ? item.destination_bin : '');

          let values = [
            (item.product_reference && item.product_reference.sku ? item.product_reference.sku : ''),
            (item.product_reference && item.product_reference.barcode ? item.product_reference.barcode : ''),
            (item.product_reference && item.product_reference.name ? item.product_reference.name : ''),
            (item.quantity ? item.quantity : ''),
            locationCodeFrom,
            locationCodeTo,
            (item.status && item.active !== false ? statusesProduct[item.status] : item.active === false ? TEXT.asnProductNotFound : '')
          ];

          await rows.push(
            await values.filter((item: any, index: number) => {
              return Object.values(columnsExport)[index] === true;
            }).map((item: any) => {
              return '"' + item.toString().replace(/"/g, '""') + '"';
            })
          );

        };

        let header = 'data:text/csv; charset=utf-8,';
        let downloadContent = '';
        for (let rowArray of rows) {
          let row = await rowArray.join(",");
          downloadContent += row + "\r\n";
        }

        const name = `TRF-${id}${statusText ? ` (${statusText})` : ''
          } - ${moment().format('yyyy-MM-DD')}${searchText ? ` [${TEXT.searchFor} ${searchText}]` : ''
          }.csv`;
        const universalBOM = "\uFEFF";
        const encodedUri = header + encodeURIComponent(universalBOM + downloadContent);
        let link = document.createElement("a");
        await link.setAttribute('href', encodedUri);
        await link.setAttribute('download', name);
        await document.body.appendChild(link);
        await link.click();

      }

      await TransferProductService.list(
        id,
        status && status !== 'empty' ? true : status === 'empty' ? false : null,
        { by: 'product.sku', direction: 'asc' },
        null,
        null,
        getValues('search'),
        status === 'empty' ? 'pending' : status,
        ['product', 'origin_product_warehouse']
      ).then(async (data: any) => {
        await csv(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });

      setShowLoading(false);

    }

    const handlePageChange = async (currentPage: number) => {
      setPage(currentPage);
      loadProducts(transfer.id, (currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
    };

    const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
      setPage(currentPage);
      setPageLimit(newPerPage);
      loadProducts(transfer.id, (currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
    };

    const handleSort = async (column: any, sortDirection: any) => {
      setSort({ by: column.id, direction: sortDirection });
      loadProducts(transfer.id, (page - 1) * pageLimit, pageLimit, column.id, sortDirection);
    };

    const history = useHistory();

    useEffect(() => {
      setReady(true);
      return () => {
        setReady(false);
      }
    }, []);

    useEffect(() => {
      initialize();
    }, [id]);

    useEffect(() => {
      if (initialized) {
        load(id);
      }
    }, [reload]);

    useEffect(() => {
      if (doChangeStatus) {
        changeStatus(transfer, doChangeStatus, ready);
      }
    }, [doChangeStatus]);

    useEffect(() => {
      if (doCheck) {

      }
    }, [doCheck]);

    return (
      <>

        <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        />

        <AlertStatus
          isPresent={presentAlert.isPresent}
          status={presentAlert.status}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <AlertPrompt
          isPresent={presentAlertPrompt.isPresent}
          message={presentAlertPrompt.message}
          onDismiss={presentAlertPrompt.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonModal canDismiss swipeToClose isOpen={modalProductPreviewIsOpen} onDidDismiss={() => setModalProductPreviewIsOpen(false)}>
          <ModalProductPreview
            productId={idProductPreview}
            onDismiss={() => setModalProductPreviewIsOpen(false)}
          />
        </IonModal>

        {
          transfer ?
            <>

              <IonGrid className={className}>
                <IonRow>

                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          {TEXT.transfer} - {transfer.id}
                          {
                            transfer.vendor_reference ?
                              ` (${transfer.vendor_reference.name})`
                              : ''
                          }
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.dateNotified}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none">
                                <IonLabel class="ion-text-wrap" color="dark">{transfer.create_at ? moment.utc(transfer.create_at).local().format('LLL') : '-'}</IonLabel>
                              </IonItem>
                            </IonCol>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.dateToTransfer}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              {
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                )
                                  ?
                                  <>
                                    <IonItem>
                                      <IonInput
                                        type="datetime-local"
                                        {...transferDate}
                                        disabled={status === 'complete' || status === 'cancel' ? true : false}
                                        onIonChange={(e: any) => {
                                          let dateValue = null;
                                          if (moment(e.detail.value).isValid()) {
                                            dateValue = e.detail.value;
                                          }
                                          if (dateValue) {
                                            setValue('transferDate', moment(e.detail.value).format('YYYY-MM-DDTHH:mm'));
                                            changeTransferDate(transfer, moment(e.detail.value).utc().format('YYYY-MM-DDTHH:mm:ss'), ready);
                                          } else {
                                            setValue('transferDate', null);
                                            changeTransferDate(transfer, null, ready);
                                          }
                                        }}>
                                      </IonInput>
                                    </IonItem>
                                  </>
                                  :
                                  <IonItem lines="none">
                                    <IonLabel class="ion-text-wrap" color="dark">{transfer.transfer_date ? moment.utc(transfer.transfer_date).local().format('LLL') : '-'}</IonLabel>
                                  </IonItem>
                              }
                            </IonCol>

                            <IonCol size='6' sizeXl="3" offsetXl="6">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.transferStatus}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              {
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                )
                                  ?
                                  <IonItem className="item-hidden-label">
                                    <IonLabel>
                                      <IonBadge color={colors[transfer.status]}>{statuses[transfer.status]}</IonBadge>
                                    </IonLabel>
                                    <IonLabel>{TEXT.transferStatus}</IonLabel>
                                    <IonSelect
                                      interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                      {...transferStatus} value={getValues('transferStatus')}
                                      onIonChange={(e: any) => { e.ready = ready; e.transfer = transfer; transferStatus.onChange(e) }}>
                                      <IonSelectOption value="pending">{statuses['pending']}</IonSelectOption>
                                      <IonSelectOption value="complete">{statuses['complete']}</IonSelectOption>
                                      <IonSelectOption value="cancel">{statuses['cancel']}</IonSelectOption>
                                    </IonSelect>
                                  </IonItem>
                                  :
                                  <IonItem lines="none">
                                    <IonLabel>
                                      <IonBadge color={colors[transfer.status]}>{statuses[transfer.status]}</IonBadge>
                                    </IonLabel>
                                  </IonItem>
                              }
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>

                </IonRow>

                <IonRow className='row-toolbar ion-margin-top'>
                  <IonCol size='12' sizeMd="8">
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines="none">
                        <IonSearchbar
                          placeholder={
                            `${TEXT.productName}/${TEXT.productSku}/${TEXT.barcode}`
                          }
                          inputmode="search"
                          {...filterSearch}
                          onIonChange={(e: SearchbarCustomEvent) => { setValue('search', e.detail.value); }}
                          onKeyUp={(e: KeyboardEvent) => {
                            if (e.key === "Enter") {
                              loadProducts(
                                transfer.id,
                                0,
                                pageLimit,
                                sort.by,
                                sort.direction,
                                (e.target as HTMLTextAreaElement).value,
                                getValues('status')
                              );
                            }
                          }}
                          onIonClear={async () => {
                            setValue('search', '');
                            loadProducts(
                              transfer.id,
                              0,
                              pageLimit,
                              sort.by,
                              sort.direction,
                              '',
                              getValues('status')
                            );
                          }}
                        >
                        </IonSearchbar>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                  <IonCol size='12' sizeMd="4">
                    <IonToolbar className='toolbar-page'>
                      <IonButtons slot="end" className="w-100">
                        <IonButton fill='outline' color="primary"
                          disabled={status === 'complete' || status === 'cancel' ? true : false}
                          routerLink={RoutePath.transferEdit.replace(':id', id)} routerDirection={Direction()} >
                          <IonIcon slot="start" icon={cubeOutline} />&nbsp;{BUTTON.manage}
                        </IonButton>
                        <IonButton fill='outline' color="success" onClick={() => { downloadReport() }}>
                          <IonIcon slot="start" icon={downloadOutline} />&nbsp;{BUTTON.download}
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonCol>
                </IonRow>

              </IonGrid>

              <IonGrid className='grid-main'>
                <IonRow>
                  <IonCol>
                    <IonSegment className='table-segment' mode='md' scrollable={true}
                      value={getValues('status') ? getValues('status') : ''}
                      {...filterStatus}
                      onIonChange={(e: any) => { e.transferID = transfer.id; filterStatus.onChange(e) }}>
                      <IonSegmentButton value="">
                        <IonLabel>
                          {TEXT.all}
                          {
                            totalAll ?
                              <IonBadge>
                                <NumberFormat value={totalAll} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="pending">
                        <IonLabel>
                          {statuses["pending"]}
                          {
                            totalPending ?
                              <IonBadge color="medium">
                                <NumberFormat value={totalPending} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="complete">
                        <IonLabel>
                          {statuses["complete"]}
                          {
                            totalComplete ?
                              <IonBadge color="success">
                                <NumberFormat value={totalComplete} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                    </IonSegment>
                  </IonCol>
                </IonRow>
              </IonGrid>

              {
                importResult ?
                  <IonAccordionGroup>
                    <IonAccordion value="updates">
                      <IonItem slot="header" color="success">
                        <IonLabel>{importResult.updates ? importResult.updates.length : 0} {TEXT.updates}</IonLabel>
                      </IonItem>
                      {
                        importResult.updates && importResult.updates.length ?
                          <div className="ion-padding" slot="content">
                            {
                              importResult.updates.map((item: any, index: number) => (
                                <CopyToClipboard text={item.sku ? item.sku : ''} key={`updates-${index}`}
                                  onCopy={() => {
                                    setPresentToast({
                                      isPresent: true,
                                      status: true,
                                      message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                      onDismiss: () => {
                                        if (ready) {
                                          setPresentToast(
                                            {
                                              isPresent: false,
                                              status: presentToast.status,
                                              message: presentToast.message,
                                              onDismiss: presentToast.onDismiss
                                            }
                                          );
                                        }
                                      }
                                    });
                                  }}>
                                  <IonChip color="success">
                                    <IonLabel>{item.sku ? item.sku : ''}</IonLabel>
                                    <IonIcon icon={copyOutline}></IonIcon>
                                  </IonChip>
                                </CopyToClipboard>
                              ))
                            }
                          </div>
                          : <></>
                      }
                    </IonAccordion>
                    <IonAccordion value="errors">
                      <IonItem slot="header" color="danger">
                        <IonLabel>{importResult.errors ? importResult.errors.length : 0} {TEXT.errors}</IonLabel>
                      </IonItem>
                      {
                        importResult.errors && importResult.errors.length ?
                          <div className="ion-padding" slot="content">
                            {
                              importResult.errors.map((item: any, index: number) => (
                                <CopyToClipboard text={item.sku ? item.sku : ''} key={`errors-${index}`}
                                  onCopy={() => {
                                    setPresentToast({
                                      isPresent: true,
                                      status: true,
                                      message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                      onDismiss: () => {
                                        if (ready) {
                                          setPresentToast(
                                            {
                                              isPresent: false,
                                              status: presentToast.status,
                                              message: presentToast.message,
                                              onDismiss: presentToast.onDismiss
                                            }
                                          );
                                        }
                                      }
                                    });
                                  }}>
                                  <IonChip color="danger">
                                    <IonLabel>{`${item.sku ? item.sku : ''} ${item.reason ? `(${item.reason})` : ''}`}</IonLabel>
                                    <IonIcon icon={copyOutline}></IonIcon>
                                  </IonChip>
                                </CopyToClipboard>
                              ))
                            }
                          </div>
                          : <></>
                      }
                    </IonAccordion>
                  </IonAccordionGroup>
                  : <></>
              }

              {
                initialized ?
                  (total < offset) ?
                    <DataTable
                      columns={columnsProducts}
                      data={products}
                      customStyles={pageTable}
                      pagination
                      noDataComponent={TEXT.noDataComponent}
                      paginationComponentOptions={paginationComponentOptions}
                      defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                      defaultSortFieldId={defaultSort.by}
                    />
                    :
                    <DataTable
                      columns={columnsProducts}
                      data={products}
                      customStyles={pageTable}
                      pagination
                      noDataComponent={TEXT.noDataComponent}
                      paginationComponentOptions={paginationComponentOptions}
                      paginationServer
                      paginationTotalRows={total}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      sortServer
                      defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                      defaultSortFieldId={defaultSort.by}
                      onSort={handleSort}
                    />
                  : <></>
              }

            </>
            : <></>
        }

      </>
    );
  }

export default Transfer;