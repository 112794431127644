import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { ErrorMessage } from '@hookform/error-message';

import {
  IonImg,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSlide,
  IonSlides,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonText,
  IonTitle,
  IonToolbar,
  IonListHeader,
  IonCardHeader,
  IonCardTitle
} from '@ionic/react';
import {
  addCircle,
  alertCircleOutline,
  removeCircle,
  trashOutline
} from 'ionicons/icons';

import moment from 'moment';

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';
import MESSAGE from '../../constances/Messages.json';
import TYPES from '../../constances/Types.json';
import { PATTERN_EMAIL } from '../../constances/RegularExpressions';

import { ToastStatus } from '../../components/toasts/ToastStatus';
import { AlertPrompt } from '../alerts/AlertPrompt';
import { AlertStatus } from '../alerts/AlertStatus';
import { Direction, RoutePath } from '../RoutePath';

import CourierService from '../../services/CourierService';
import PackService from '../../services/PackService';
import LocationService from '../../services/LocationService';
import { useHistory } from 'react-router-dom';

const PackProductsForm: React.FC<{
  orderData: any;
  packData?: any;
  checkData?: any;
  isSubmit?: boolean;
  onDidSubmit?: (data?: any) => any;
  onProductsUpdate?: (data?: any) => any;
  onPacksUpdate?: (data?: any) => any;
  onPackedUpdate?: (data?: any) => any;
}> = ({
  orderData,
  packData = false,
  checkData = null,
  isSubmit = false,
  onDidSubmit,
  onProductsUpdate,
  onPacksUpdate,
  onPackedUpdate
}) => {

    const defaultCountry: string = 'TH';

    const [order, setOrder] = useState<any>(null);
    const [products, setProducts] = useState<any>([]);
    const [pack, setPack] = useState<any>(null);
    const [packs, setPacks] = useState<any>([]);
    const [packed, setPacked] = useState<any>([]);
    const [checks, setChecks] = useState<any>([]);
    const [couriers, setCouriers] = useState<any>([]);
    const [warehouse, setWarehouse] = useState<any>(null);
    const [countries, setCountries] = useState<Array<any>>([]);
    const [provinces, setProvinces] = useState<Array<any>>([]);
    const [cities, setCities] = useState<Array<any>>([]);
    const [subDistricts, setSubDistricts] = useState<Array<any>>([]);

    const [ready, setReady] = useState(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState(false);

    const types: any = TYPES.box;

    const {
      control,
      register,
      formState: { errors },
      reset,
      trigger,
      setValue,
      getValues,
      handleSubmit
    } = useForm({
      mode: 'onChange',
    });
    const [formGroup, setFormGroup] = useState<any>(null);
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'products',
    });
    const [productToAdd] = useState<any>(
      register('product', {
        value: ''
      })
    );

    const [presentToast, setPresentToast] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlert, setPresentAlert] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
      isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, message: '', onDismiss: () => { }
    });

    const slideOptsImage = {
      initialSlide: 0
    };

    const history = useHistory();

    const initialize = async (force: boolean = false) => {
      if (!initialized || force) {
        setInitialized(false);
        setShowLoading(true);
        setPack(null);
        setPacks([]);
        setPacked([]);
        setProducts([]);
        setValue('products', []);
        setOrder(orderData);
        setPack(packData);
        setProducts(orderData.products);
        onProductsUpdate && onProductsUpdate(JSON.parse(JSON.stringify(orderData.products)));
        await loadPacks(orderData);
        if (packData && packData.products) {
          for (let item of packData.products) {
            await appendProduct(item.product, item.quantity, 0, true, orderData.products, packed);
          }
        }
        await loadCouriers();

        await getCountries().then(async (countriesData: any) => {

          setCountries(countriesData);

          let countryValue: any;
          let provinceValue: any;
          let cityValue: any;
          let subdistrictValue: any;
          const searchCountry = (countryStored: string) => {
            return new Promise(resolve => {
              if (countryStored) {
                for (let country of countriesData) {
                  if (
                    country.name === countryStored
                    || country.enName === countryStored
                    || country.alpha2 === countryStored
                  ) {
                    resolve(country.alpha2);
                  }
                }
              } else {
                resolve('');
              }
            });
          }
          await searchCountry(
            packData ? packData.shipping_country ? packData.shipping_country : defaultCountry : orderData
              && orderData.shipping_country ? orderData.shipping_country : defaultCountry
          ).then(async (countryData: any) => {
            countryValue = countryData;
            const searchProvince = (provinceStored: string) => {
              return new Promise(resolve => {
                getProvinces(countryValue).then(async (provincesData: any) => {
                  setProvinces(provincesData);
                  if (provinceStored) {
                    for (let province of provincesData) {
                      if (
                        province.name_th === provinceStored
                        || province.name_en === provinceStored
                      ) {
                        resolve(province.id);
                      }
                    }
                  } else {
                    resolve('');
                  }
                });
              });
            }
            await searchProvince(
              packData ? packData.shipping_province : orderData && orderData.shipping_province ? orderData.shipping_province : ''
            ).then(async (provinceData: any) => {
              provinceValue = provinceData;
              const searchCity = (cityStored: string) => {
                return new Promise(resolve => {
                  getCities(countryValue, provinceValue).then(async (citiesData: any) => {
                    setCities(citiesData);
                    if (cityStored) {
                      for (let city of citiesData) {
                        if (
                          city.name_th === cityStored
                          || city.name_en === cityStored
                        ) {
                          resolve(city.id);
                        }
                      }
                    } else {
                      resolve('');
                    }
                  });
                });
              }
              await searchCity(
                packData ? packData.shipping_city : orderData && orderData.shipping_city ? orderData.shipping_city : ''
              ).then(async (cityData: any) => {
                cityValue = cityData;
                const searchSubDistrict = async (subdistrictStored: string) => {
                  return new Promise(resolve => {
                    getSubDistricts(countryValue, cityValue).then(async (subDistrictsData: any) => {
                      setSubDistricts(subDistrictsData);
                      if (subdistrictStored) {
                        for (let subDistrict of subDistrictsData) {
                          if (
                            subDistrict.name_th === subdistrictStored
                            || subDistrict.name_en === subdistrictStored
                          ) {
                            resolve(subDistrict.id);
                          }
                        }
                      } else {
                        resolve('');
                      }
                    });
                  });
                }
                await searchSubDistrict(
                  packData ? packData.shipping_subdistrict : orderData && orderData.shipping_subdistrict ? orderData.shipping_subdistrict : ''
                ).then((subdistrictData: any) => {
                  subdistrictValue = subdistrictData;
                });
              });
            });
          });

          setFormGroup(
            {
              job: register('job', {
                value: orderData && orderData.job ? orderData.job : '',
                required: MESSAGE.error.input_required
              }),
              order: register('order', {
                value: orderData && orderData.id ? orderData.id : '',
                required: MESSAGE.error.input_required
              }),
              type: register('type', {
                value: packData && packData.type ? packData.type : '',
                required: MESSAGE.error.input_required
              }),
              weight: register('weight', {
                value: packData && packData.weight ? packData.weight : '',
                required: MESSAGE.error.input_required
              }),
              courier: register('courier', {
                value: packData && packData.courier ? packData.courier : '',
                required: MESSAGE.error.input_required
              }),
              tracking_no: register('tracking_no', {
                value: packData && packData.tracking_no ? packData.tracking_no : ''
              }),
              status: register('status', {
                value: packData && packData.status ? packData.status : 'pending'
              }),
              shipping_first_name: register('shipping_first_name', {
                value: packData ? packData.shipping_first_name : orderData && orderData.shipping_first_name ? orderData.shipping_first_name : ''
              }),
              shipping_last_name: register('shipping_last_name', {
                value: packData ? packData.shipping_last_name : orderData && orderData.shipping_last_name ? orderData.shipping_last_name : ''
              }),
              shipping_address: register('shipping_address', {
                value: packData ? packData.shipping_address : orderData && orderData.shipping_address ? orderData.shipping_address : ''
              }),
              shipping_subdistrict: register('shipping_subdistrict', {
                value: subdistrictValue,
                onChange: (e) => {
                  if (e.target.value) {
                    getSubDistricts(
                      getValues('shipping_country'),
                      getValues('shipping_city')
                    ).then((subDistrictsData: any) => {
                      if (subDistrictsData && subDistrictsData.length) {
                        for (let subDistrict of subDistrictsData) {
                          if (subDistrict.id === e.target.value && subDistrict.zip_code) {
                            setValue('shipping_postcode', subDistrict.zip_code.toString());
                          }
                        }
                      }
                    });
                  }
                }
              }),
              shipping_city: register('shipping_city', {
                value: cityValue,
                onChange: (e) => {
                  if (e.target.value) {
                    getSubDistricts(getValues('shipping_country'), e.target.value).then((subDistrictsData: any) => {
                      setValue('shipping_subdistrict', '');
                      setValue('shipping_postcode', '');
                      setSubDistricts(subDistrictsData);
                    });
                  }
                }
              }),
              shipping_province: register('shipping_province', {
                value: provinceValue,
                onChange: async (e) => {
                  if (e.target.value) {
                    getCities(getValues('shipping_country'), e.target.value).then((citiesData: any) => {
                      setValue('shipping_city', '');
                      setValue('shipping_subdistrict', '');
                      setValue('shipping_postcode', '');
                      setCities(citiesData);
                    });
                  }
                }
              }),
              shipping_country: register('shipping_country', {
                value: countryValue,
                onChange: (e) => {
                  if (e.target.value) {
                    getProvinces(e.target.value).then((provincesData: any) => {
                      setValue('shipping_province', '');
                      setValue('shipping_city', '');
                      setValue('shipping_subdistrict', '');
                      setValue('shipping_postcode', '');
                      setProvinces(provincesData);
                    });
                  }
                }
              }),
              shipping_postcode: register('shipping_postcode', {
                value: packData ? packData.shipping_postcode : orderData && orderData.shipping_postcode ? orderData.shipping_postcode : ''
              }),
              shipping_phone: register('shipping_phone', {
                value: packData ? packData.shipping_phone : orderData && orderData.shipping_phone ? orderData.shipping_phone : ''
              }),
              shipping_email: register('shipping_email', {
                value: packData ? packData.shipping_email : orderData && orderData.shipping_email ? orderData.shipping_email : '',
                pattern: {
                  value: PATTERN_EMAIL,
                  message: MESSAGE.error.email_invalid,
                }
              }),
              shipping_longitude: register('shipping_longitude', {
                value: packData ? packData.shipping_longitude : orderData && orderData.shipping_longitude ? orderData.shipping_longitude : ''
              }),
              shipping_latitude: register('shipping_latitude', {
                value: packData ? packData.shipping_latitude : orderData && orderData.shipping_latitude ? orderData.shipping_latitude : ''
              })
            }
          );

        });
        setInitialized(true);
        setShowLoading(false);
      }
    }

    const loadPacks = async (order: any) => {
      if (order) {
        await PackService.list(
          order.id,
          null,
          { by: 'create_at', direction: 'desc' },
          null,
          null,
          null,
          null,
          null,
          null,
          ['products']
        ).then(async (data: any) => {
          setPacks(data);
          onPacksUpdate && onPacksUpdate(JSON.parse(JSON.stringify(data)));
        }).catch(() => { });
      }
    }

    const loadCouriers = async () => {
      await CourierService.list(
        true,
        { by: 'name', direction: 'asc' }
      ).then((couriers: any) => {
        setCouriers(couriers);
      }).catch(() => { });
    }

    const appendProduct = async (
      productID: any,
      quantity: number | null = null,
      checked: number = 0,
      editMode: boolean = false,
      productsData: any = products,
      packedData: any = [...packed]
    ) => {

      const add = async (product: any) => {
        let available: number = parseInt(product.quantity) - countPackedProduct(
          product.product, [...packs, ...[{ products: packed }]]
        );
        if (!available || available <= 0) {
          available = 0;
        }
        if (available || editMode) {
          if ((!quantity || quantity > available) && !editMode) {
            quantity = available;
          }
          if (fields.map((field: any) => field.product).includes(productID)) {
            fields.map((field: any, index: number) => {
              if (field.product === productID) {
                const currentQuantity: number = parseInt(getValues(`products.${index}.quantity`));
                setValue(`products.${index}.quantity`, currentQuantity + (quantity ? quantity : 0));
                changeProductQuantity(index, product, currentQuantity + (quantity ? quantity : 0), editMode);
              }
            });
          } else {
            const parameters = {
              product: product.product,
              quantity: quantity,
              maximum: available,
              checked: checked,
              editMode: editMode
            };
            append(parameters);
            await trigger();
            if (!editMode) {
              await packedData.push(parameters);
              setPacked(packedData);
              onPackedUpdate && onPackedUpdate(JSON.parse(JSON.stringify(packedData)));
            }
          }
          setValue('product', '');
        } else {
          if (checked) {
            packedData.map((field: any, index: number) => {
              if (field.product === product.product) {
                const currentChecked = getValues(`products.${index}.checked`);
                const checks = (currentChecked ? currentChecked : 0) + checked;
                setValue(`products.${index}.checked`, checks);
                packedData[index].checked = checks;
                setPacked(packedData);
                onPackedUpdate && onPackedUpdate(JSON.parse(JSON.stringify(packedData)));
              }
            });
          }
        }
      }

      for (let i = 0; i < productsData.length; i++) {
        if (productID === productsData[i].product) {
          if (
            productsData[i].product_reference
            && productsData[i].product_reference.active === '2'
            && !editMode
          ) {
            setPresentAlert({
              isPresent: true,
              status: false,
              message: `<h4 class="ion-text-center no-margin">${TEXT.warningPrompt
                }</h4><br /><strong>${productsData[i].product_reference.sku
                } - ${productsData[i].product_reference.name
                }</strong> ${TEXT.noASNPrompt}`,
              onDismiss: async () => {
                if (ready) {
                  setPresentAlert(
                    {
                      isPresent: false,
                      status: presentAlert.status,
                      message: presentAlert.message,
                      onDismiss: presentAlert.onDismiss
                    }
                  );
                }
              }
            });
          } else if (
            productsData[i].product_reference
            && parseInt(productsData[i].quantity) - countPackedProduct(productsData[i].product, [...packs, ...[{ products: packed }]])
            > parseInt(productsData[i].product_reference.quantity)
            && !editMode
          ) {
            setPresentAlert({
              isPresent: true,
              status: false,
              message: `<h4 class="ion-text-center no-margin">${TEXT.warningPrompt
                }</h4><br /><strong>${productsData[i].product_reference.sku
                } - ${productsData[i].product_reference.name
                }</strong> ${TEXT.notEfficientPrompt}<br /><br />${TEXT.requireQty
                }: ${parseInt(productsData[i].quantity) - countPackedProduct(productsData[i].product, [...packs, ...[{ products: packed }]])
                }<br />${TEXT.fromQty
                }: ${productsData[i].product_reference.quantity
                }`,
              onDismiss: async () => {
                if (ready) {
                  setPresentAlert(
                    {
                      isPresent: false,
                      status: false,
                      message: presentAlert.message,
                      onDismiss: presentAlert.onDismiss
                    }
                  );
                }
              }
            });
          } else {
            await add(productsData[i]);
          }
          break;
        }
      }

    }

    const removeProduct = async (index: any, productID: any, editMode: boolean) => {
      remove(index);
      if (editMode && packData) {
        const packsData = [...packs];
        for (let i = 0; i < packsData.length; i++) {
          if (packData.id === packsData[i].id) {
            for (let j = 0; j < packsData[i].products.length; j++) {
              if (packsData[i].products[j].product === productID) {
                await packsData[i].products.splice(j, 1);
                setPacks(packsData);
                onPacksUpdate && onPacksUpdate(JSON.parse(JSON.stringify(packsData)));
                break;
              }
            }
          }
        }
      } else {
        const packedData: any = [...packed];
        packedData.map(async (field: any, index: number) => {
          if (field.product === productID) {
            await packedData.splice(index, 1);
            setPacked(packedData);
            onPackedUpdate && onPackedUpdate(JSON.parse(JSON.stringify(packedData)));
          }
        });
      }
    }

    const changeProductQuantity = (index: number, value: any, quantity: any, editMode: boolean) => {
      const check = (quantity: any) => {
        const maximum = getValues(`products.${index}.maximum`);
        if (parseInt(quantity) > parseInt(maximum)) {
          setValue(`products.${index}.quantity`, maximum);
        }
      }
      if (!quantity) {
        quantity = 0;
      }
      if (editMode && packData) {
        const packsData = [...packs];
        for (let i = 0; i < packsData.length; i++) {
          if (packData.id === packsData[i].id) {
            for (let j = 0; j < packsData[i].products.length; j++) {
              if (packsData[i].products[j].product === value.product) {
                packsData[i].products[j].quantity = quantity;
                setPacks(packsData);
                onPacksUpdate && onPacksUpdate(JSON.parse(JSON.stringify(packsData)));
                check(quantity);
                break;
              }
            }
          }
        }
      } else {
        const packedData: any = [...packed];
        packedData.map((field: any, index: number) => {
          if (field.product === value.product) {
            setValue(`products.${index}.quantity`, quantity);
            packedData[index].quantity = quantity;
            setPacked(packedData);
            onPackedUpdate && onPackedUpdate(JSON.parse(JSON.stringify(packedData)));
            check(quantity);
          }
        });
      }
    }

    const removePack = async (id: any) => {

      const success = (result: any) => {
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.submit_complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
        history.replace(RoutePath.jobView.replace(':id', result.job));
      }

      setPresentAlertPrompt({
        isPresent: true,
        message: `${TEXT.removePrompt} "PACK-${id}"?`,
        onDismiss: (data: boolean) => {
          if (ready) {
            setPresentAlertPrompt(
              {
                isPresent: false,
                message: presentAlert.message,
                onDismiss: presentAlert.onDismiss
              }
            );
            if (data) {
              PackService.delete(id).then(async (result: any) => {
                success(result);
              }).catch((error) => {
                setPresentToast({
                  isPresent: true,
                  status: false,
                  message: error,
                  onDismiss: () => {
                    if (ready) {
                      setPresentToast(
                        {
                          isPresent: false,
                          status: presentToast.status,
                          message: presentToast.message,
                          onDismiss: presentToast.onDismiss
                        }
                      );
                    }
                  }
                });
              });
            }
          }
        }
      });

    }

    const countPackedProduct = (productID: any, packsData: Array<any> = [...packs, ...[{ products: packed }]]) => {
      return packsData.length ? packsData.map((pack: any) => {
        return pack.products.length ? pack.products.map((packProduct: any) => {
          return packProduct.product === productID
            ? packProduct.quantity ? parseInt(packProduct.quantity) : 0 : 0
        }).reduce((partialSum: any, a: any) => partialSum + a, 0) : 0;
      }).reduce((partialSum: any, a: any) => partialSum + a, 0) : 0;
    }

    const resetFormValues = () => {
      reset();
    }

    const getCountries = async () => {
      return new Promise(async (resolve) => {
        LocationService.getCountries().then(async (response: any) => {
          resolve(response);
        }).catch(() => {
          resolve([]);
        });
      });
    }

    const getProvinces = async (countryCode: string) => {
      return new Promise(async (resolve) => {
        LocationService.getProvincesByCountry(countryCode).then(async (response: any) => {
          resolve(response);
        }).catch(() => {
          resolve([]);
        });
      });
    }

    const getCities = async (countryCode: string, provinceID: number) => {
      return new Promise(async (resolve) => {
        LocationService.getCitiesByProvince(
          countryCode,
          provinceID
        ).then(async (response: any) => {
          resolve(response);
        }).catch(() => {
          resolve([]);
        });
      });
    }

    const getSubDistricts = async (countryCode: string, cityID: number) => {
      return new Promise(async (resolve) => {
        LocationService.getSubDistrictsByCity(
          countryCode,
          cityID
        ).then(async (response: any) => {
          resolve(response);
        }).catch(() => {
          resolve([]);
        });
      });
    }

    const onSubmit = async (value: any) => {

      if (fields.length) {

        setShowLoading(true);

        delete value.product;

        value.active = true;

        value.shipping_postcode = value.shipping_postcode.toString();

        const searchProvince = async (id: number) => {
          return new Promise(resolve => {
            for (let province of provinces) {
              if (province.id === id) {
                if (value.shipping_country === 'TH') {
                  resolve(province.name_th);
                } else {
                  resolve(province.name_en);
                }
              }
            }
          });
        }
        if (value.shipping_province) {
          value.shipping_province = await searchProvince(value.shipping_province);
        }

        const searchCity = async (id: number) => {
          return new Promise(resolve => {
            for (let city of cities) {
              if (city.id === id) {
                if (value.shipping_country === 'TH') {
                  resolve(city.name_th);
                } else {
                  resolve(city.name_en);
                }
              }
            }
          });
        }
        if (value.shipping_city) {
          value.shipping_city = await searchCity(value.shipping_city);
        }

        const searchSubDistrict = async (id: number) => {
          return new Promise(resolve => {
            for (let subDistrict of subDistricts) {
              if (subDistrict.id === id) {
                if (value.shipping_country === 'TH') {
                  resolve(subDistrict.name_th);
                } else {
                  resolve(subDistrict.name_en);
                }
              }
            }
          });
        }
        if (value.shipping_subdistrict) {
          value.shipping_subdistrict = await searchSubDistrict(value.shipping_subdistrict);
        }

        const searchCountry = async (countryCode: string) => {
          return new Promise(resolve => {
            for (let country of countries) {
              if (country.alpha2 === countryCode) {
                if (countryCode === 'TH') {
                  resolve(country.name);
                } else {
                  resolve(country.enName);
                }
              }
            }
          });
        }
        if (value.shipping_country) {
          value.shipping_country = await searchCountry(value.shipping_country);
        }

        if (value.type === 'BEYOND_S') {
          value.dimension_width = 20;
          value.dimension_depth = 35;
          value.dimension_height = 2;
        } else if (value.type === 'BEYOND_B_A') {
          value.dimension_width = 17;
          value.dimension_depth = 25;
          value.dimension_height = 9;
        } else if (value.type === 'BEYOND_B_B') {
          value.dimension_width = 14;
          value.dimension_depth = 20;
          value.dimension_height = 6;
        } else if (value.type === 'BEYOND_B_00') {
          value.dimension_width = 10;
          value.dimension_depth = 14;
          value.dimension_height = 6;
        } else if (value.type === 'BEYOND_B_0') {
          value.dimension_width = 11;
          value.dimension_depth = 17;
          value.dimension_height = 10;
        } else if (value.type === 'BEYOND_B_0+4') {
          value.dimension_width = 11;
          value.dimension_depth = 17;
          value.dimension_height = 10;
        } else if (value.type === 'BEYOND_B_AA') {
          value.dimension_width = 13;
          value.dimension_depth = 17;
          value.dimension_height = 7;
        } else if (value.type === 'BEYOND_B_2A') {
          value.dimension_width = 14;
          value.dimension_depth = 20;
          value.dimension_height = 12;
        } else if (value.type === 'BEYOND_B_2B') {
          value.dimension_width = 17;
          value.dimension_depth = 25;
          value.dimension_height = 18;
        } else if (value.type === 'BEYOND_B_C') {
          value.dimension_width = 20;
          value.dimension_depth = 30;
          value.dimension_height = 11;
        } else if (value.type === 'BEYOND_B_D') {
          value.dimension_width = 22;
          value.dimension_depth = 35;
          value.dimension_height = 14;
        } else if (value.type === 'BEYOND_B_E') {
          value.dimension_width = 24;
          value.dimension_depth = 40;
          value.dimension_height = 17;
        } else if (value.type === 'BEYOND_B_F') {
          value.dimension_width = 30;
          value.dimension_depth = 45;
          value.dimension_height = 20;
        } else if (value.type === 'BEYOND_B_G') {
          value.dimension_width = 31;
          value.dimension_depth = 36;
          value.dimension_height = 26;
        } else if (value.type === 'BEYOND_B_H') {
          value.dimension_width = 41;
          value.dimension_depth = 45;
          value.dimension_height = 35;
        } else if (value.type === 'BEYOND_B_S+') {
          value.dimension_width = 24;
          value.dimension_depth = 37;
          value.dimension_height = 14;
        } else if (value.type === 'BEYOND_B_M') {
          value.dimension_width = 27;
          value.dimension_depth = 43;
          value.dimension_height = 20;
        } else if (value.type === 'BEYOND_B_M+') {
          value.dimension_width = 35;
          value.dimension_depth = 45;
          value.dimension_height = 25;
        } else if (value.type === 'BEYOND_B_L+') {
          value.dimension_width = 40;
          value.dimension_depth = 50;
          value.dimension_height = 30;
        }

        const onSuccess = (result: any) => {
          setPresentToast({
            isPresent: true,
            status: true,
            message: MESSAGE.success.submit_complete,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
          if (onDidSubmit) {
            onDidSubmit(result);
          }
        }

        const onError = (message: any) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: message,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
          if (onDidSubmit) {
            onDidSubmit();
          }
        }

        const checks = value.products.map((product: any) => product.checked);
        if (!checks.includes(false)) {
          if (packData) {
            await PackService.update(packData.id, value).then(async (result: any) => {
              onSuccess(result);
            }).catch((error) => {
              onError(error);
            });
          } else {
            await PackService.create(value).then(async (result: any) => {
              onSuccess(result);
              resetFormValues();
            }).catch((error) => {
              onError(error);
            });
          }
        } else {
          setPresentAlert({
            isPresent: true,
            status: false,
            message: MESSAGE.error.check_all_requires,
            onDismiss: () => {
              if (ready) {
                setPresentAlert(
                  {
                    isPresent: false,
                    status: presentAlert.status,
                    message: presentAlert.message,
                    onDismiss: presentAlert.onDismiss
                  }
                );
              }
            }
          });
          if (onDidSubmit) {
            onDidSubmit();
          }
        }

        setShowLoading(false);

      } else {
        setPresentAlert({
          isPresent: true,
          status: false,
          message: MESSAGE.error.form_invalid,
          onDismiss: () => {
            if (ready) {
              setPresentAlert(
                {
                  isPresent: false,
                  status: presentAlert.status,
                  message: presentAlert.message,
                  onDismiss: presentAlert.onDismiss
                }
              );
            }
          }
        });
        if (onDidSubmit) {
          onDidSubmit();
        }
      }

    }
    const onError = (errors: any) => {
      setPresentAlert({
        isPresent: true,
        status: false,
        message: MESSAGE.error.form_invalid,
        onDismiss: () => {
          if (ready) {
            setPresentAlert(
              {
                isPresent: false,
                status: presentAlert.status,
                message: presentAlert.message,
                onDismiss: presentAlert.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit();
      }
    }

    useEffect(() => {
      setReady(true);
      return () => {
        setReady(false);
      }
    }, []);

    useEffect(() => {
      if (JSON.stringify(orderData) !== JSON.stringify(order)) {
        if ((orderData && !order) || (orderData.id !== order.id)) {
          resetFormValues();
          initialize(true);
        }
      }
    }, [orderData]);

    useEffect(() => {
      if (checkData) {
        appendProduct(checkData.product, 1, 1);
      }
    }, [checkData]);

    useEffect(() => {
      if (isSubmit) {
        handleSubmit(onSubmit, onError)();
      }
    }, [isSubmit]);

    return (
      <>

        <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        />

        <AlertStatus
          isPresent={presentAlert.isPresent}
          status={presentAlert.status}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <AlertPrompt
          isPresent={presentAlertPrompt.isPresent}
          message={presentAlertPrompt.message}
          onDismiss={presentAlertPrompt.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <form>
          {
            formGroup ?
              <IonGrid>

                <IonRow>
                  <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
                    <IonCard className='ion-margin-top'>
                      <IonCardHeader>
                        <IonCardTitle>
                          {TEXT.packProduct}
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonToolbar mode="md" className="transparent">
                          <IonItem slot='start' className='w-100'>
                            <IonLabel position="stacked">{TEXT.productSku}</IonLabel>
                            <IonSelect
                              interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                              value={getValues('product')}
                              {...productToAdd}
                              placeholder={TEXT.product}
                            >
                              {
                                order.products.filter((item: any) => item.active !== false).map((item: any, index: number) => (
                                  <IonSelectOption key={`product-${index}`} value={item.product} className='text-full'
                                    disabled={
                                      item.product_reference
                                      && countPackedProduct(item.product, [...packs, ...[{ products: packed }]]) >= parseInt(item.quantity)
                                    }>
                                    {"["}{TEXT.qtyParcel + ": "}{
                                      <NumberFormat value={
                                        countPackedProduct(item.product, [...packs, ...[{ products: packed }]])
                                      } displayType={'text'} thousandSeparator={true}
                                        className="text-number" />
                                    }{"/"}{
                                      item.product_reference ?
                                        <NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true}
                                          className="text-number" />
                                        : <></>
                                    }{"] "}
                                    {
                                      item.product_reference ?
                                        `${item.product_reference.sku} - ${item.product_reference.name}`
                                        : item.product
                                    }
                                  </IonSelectOption>
                                ))
                              }
                            </IonSelect>
                          </IonItem>
                          <IonButton type="button" slot='end'
                            onClick={() => appendProduct(getValues('product'))}>
                            <IonIcon slot='start' icon={addCircle} />
                            {BUTTON.add}
                          </IonButton>
                        </IonToolbar>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>

                {
                  fields.map((data: any, index: number) => (
                    <IonRow key={data.id}>
                      <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
                        <IonCard>
                          <IonToolbar mode="md" className="transparent">
                            <IonTitle slot='start'>
                              <h4 className='text-full'>
                                {TEXT.item} - {index + 1}
                              </h4>
                            </IonTitle>
                            <IonButtons slot='end'>
                              <IonButton className='button-compact' fill='clear'
                                onClick={() => {
                                  removeProduct(index, data.product, data.editMode);
                                }}>
                                <IonIcon icon={removeCircle} color='danger' slot="icon-only" />
                              </IonButton>
                            </IonButtons>
                          </IonToolbar>
                          <IonCardContent>
                            {
                              order.products.filter((item: any) => item.product === data.product && item.product_reference).map((item: any, indexProduct: number) => (
                                <IonCard key={`preview-${index}-${indexProduct}`} routerLink={
                                  item.product_reference ? RoutePath.productView.replace(':id', item.product_reference.id)
                                    : null
                                } routerDirection={Direction()}>
                                  <IonCardContent>
                                    <IonRow className='product-info small'>
                                      <IonCol size='12'
                                        sizeMd={item.product_reference.figure_reference && item.product_reference.figure_reference.length ? '8' : '12'}>
                                        <IonText color="medium">
                                          <p>{TEXT.productId} - {item.id}</p>
                                        </IonText>
                                        <h3>{item.product_reference.name}</h3>
                                        {
                                          item.product_reference.attributes.length
                                            && item.product_reference.attributes.reduce((items: any, value: any) => ({ ...items, [value.key]: value.value }), {}).name_en ?
                                            <h4>
                                              {
                                                item.product_reference.attributes.reduce((items: any, value: any) => ({ ...items, [value.key]: value.value }), {}).name_en
                                              }
                                            </h4>
                                            : <></>
                                        }

                                        <IonText color="medium">
                                          {
                                            item.product_reference.category_reference ?
                                              <p>{TEXT.category}: {item.product_reference.category_reference.name}</p>
                                              : <></>
                                          }
                                          <p>{TEXT.productSku}: {item.product_reference.sku}</p>
                                        </IonText>

                                      </IonCol>
                                      {
                                        item.product_reference.figure_reference && item.product_reference.figure_reference.length &&
                                        <IonCol size='12' sizeMd='4'>
                                          {
                                            item.product_reference.figure_reference && item.product_reference.figure_reference.length ?
                                              <IonSlides pager={true} options={slideOptsImage} className="product-info-figure-slide">
                                                {item.product_reference.figure_reference.map((item: any, figureI: number) => {
                                                  return (
                                                    <IonSlide key={`image-slide-${index}-${indexProduct}-${figureI}`}>
                                                      <IonImg className='content profile-image' src={item.original} />
                                                    </IonSlide>
                                                  )
                                                })}
                                              </IonSlides>
                                              : <></>
                                          }
                                        </IonCol>
                                      }
                                    </IonRow>
                                  </IonCardContent>
                                </IonCard>
                              ))
                            }
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.qty}</IonLabel>
                              <IonInput type='number' min={1} max={data.maximum}
                                {...register(`products.${index}.quantity`, {
                                  valueAsNumber: true,
                                  required: MESSAGE.error.input_required
                                })}
                                onIonChange={e => {
                                  changeProductQuantity(index, getValues(`products.${index}`), e.detail.value, data.editMode);
                                }}
                              >
                              </IonInput>
                            </IonItem>
                            <IonItem lines="none">
                              <IonLabel>{TEXT.checkItem}</IonLabel>
                              <IonCheckbox slot="start" {...register(`products.${index}.checked`)}
                                checked={packed.length && packed[index] && parseInt(packed[index].checked) >= parseInt(packed[index].quantity)}
                                onIonChange={() => {
                                  const quantity = getValues(`products.${index}.quantity`);
                                  setValue(`products.${index}.checked`, quantity ? parseInt(quantity) : 0);
                                }} />
                            </IonItem>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                  ))
                }

                <IonRow>
                  <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          {TEXT.packageDetail}
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.dateSendParcel}</IonLabel>
                                <IonInput type='date' value={moment().format('YYYY-MM-DD')}></IonInput>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.orderNumber} ({TEXT.reference})</IonLabel>
                                <IonInput type='text' value={order ? order.order_no : ''} readonly></IonInput>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.boxParcel} <span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder={TEXT.boxParcel}
                                  value={getValues('type')}
                                  {...formGroup.type}>
                                  {
                                    Object.keys(types).map((key: any, index: number) => (
                                      <IonSelectOption key={`type-${index}`} value={key}>{types[key]}</IonSelectOption>
                                    ))
                                  }
                                </IonSelect>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.weightParcel} <span className='form-required'>*</span></IonLabel>
                                <IonInput type='text' placeholder={TEXT.weightParcel} {...formGroup.weight}></IonInput>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.courier} <span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder={TEXT.courier}
                                  value={getValues('courier')}
                                  {...formGroup.courier}>
                                  <IonSelectOption value="0">{TEXT.pickup}</IonSelectOption>
                                  {
                                    couriers.map((item: any, index: number) => (
                                      <IonSelectOption key={`courier-${index}`} value={item.id}>{item.name}</IonSelectOption>
                                    ))
                                  }
                                </IonSelect>
                              </IonItem>
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.trackingNo}</IonLabel>
                                <IonInput type='text' placeholder={TEXT.trackingNo} {...formGroup.tracking_no}></IonInput>
                              </IonItem>
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          {TEXT.shippingAddress}
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>

                            <IonCol size='12' sizeMd='6'>
                              {/* ชื่อ */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.name}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="text" {...formGroup.shipping_first_name}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="shipping_first_name"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* นามสกุล */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.lastName}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="text" {...formGroup.shipping_last_name}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="shipping_last_name"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* ที่อยู่ผู้ค้า */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.address}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="text" {...formGroup.shipping_address}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="shipping_address"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* ประเทศ */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.country}<span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder="เลือกประเทศ"
                                  value={getValues('shipping_country')}
                                  disabled
                                  onIonChange={(e: any) => { formGroup.shipping_country.onChange(e) }}
                                  {...formGroup.shipping_country}
                                >
                                  {countries.map((item: any, index: number) => (
                                    <IonSelectOption key={`country-${index}`} value={item.alpha2}>{item.name}</IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                              <ErrorMessage errors={errors} name="shipping_country"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* จังหวัด */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.province}<span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder="เลือกจังหวัด"
                                  value={getValues('shipping_province')}
                                  {...formGroup.shipping_province}
                                  disabled={!provinces.length}
                                  onIonChange={(e: any) => { formGroup.shipping_province.onChange(e) }}
                                >
                                  {provinces.map((item: any, index: number) => (
                                    <IonSelectOption key={`province-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                              <ErrorMessage errors={errors} name="shipping_province"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* อำเภอ/เขต */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.city}<span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder="เลือกเขต/อำเภอ"
                                  value={getValues('shipping_city')}
                                  {...formGroup.shipping_city}
                                  disabled={!cities.length}
                                  onIonChange={(e: any) => { formGroup.shipping_city.onChange(e) }}
                                >
                                  {cities.map((item: any, index: number) => (
                                    <IonSelectOption key={`city-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                              <ErrorMessage errors={errors} name="shipping_city"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* ตำบล/แขวง */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.subDistrict}<span className='form-required'>*</span></IonLabel>
                                <IonSelect
                                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                  placeholder="เลือกแขวง/ตำบล"
                                  value={getValues('shipping_subdistrict')}
                                  {...formGroup.shipping_subdistrict}
                                  disabled={!subDistricts.length}
                                  onIonChange={(e: any) => { formGroup.shipping_subdistrict.onChange(e) }}
                                >
                                  {subDistricts.map((item: any, index: number) => (
                                    <IonSelectOption key={`subdistrict-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                              <ErrorMessage errors={errors} name="shipping_subdistrict"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                            <IonCol size='12' sizeMd='6'>
                              {/* รหัสไปรษณีย์ */}
                              <IonItem>
                                <IonLabel position="stacked">{TEXT.postcode}<span className='form-required'>*</span></IonLabel>
                                <IonInput type="text" {...formGroup.shipping_postcode}></IonInput>
                              </IonItem>
                              <ErrorMessage errors={errors} name="shipping_postcode"
                                render={({ message }) =>
                                  <div className='form-help'>
                                    <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                    {message ? message : ''}
                                  </div>
                                } />
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>

                {
                  pack ?
                    <IonRow className='ion-margin-top'>
                      <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
                        <IonListHeader>
                          <h2>{TEXT.removePack}</h2>
                        </IonListHeader>
                      </IonCol>
                      <IonCol size='12' sizeMd='10' offset='0' offsetMd='1' className='ion-text-left'>
                        <IonItem lines='none'>
                          <IonButton type="button" size='default' onClick={() => { removePack(pack.id); }}>
                            <IonIcon slot="start" icon={trashOutline} />
                            {BUTTON.delete}
                          </IonButton>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    : <></>
                }

                <IonRow>
                  <IonCol>
                    <div className='ion-margin-top'></div>
                  </IonCol>
                </IonRow>

              </IonGrid>
              : <></>
          }
        </form>

      </>
    );
  };

export default PackProductsForm;