import '../../css/Modal.scss';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  SearchbarCustomEvent
} from "@ionic/react";
import {
  closeOutline
} from "ionicons/icons";

import { useEffect, useState } from "react";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import ManufacturerService from "../../services/ManufacturerService";

export const ModalManufacturers: React.FC<{
  selected?: string;
  onDismiss: (manufacturer?: any) => any;
}> = ({ selected = '', onDismiss }) => {

  const pageLimit = 30;

  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [manufacturers, setManufacturers] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    searchManufacturers();
  }, []);

  const searchManufacturers = async (search = '', event: any = null) => {
    setSearchText(search);
    let start = 0;
    if (event) {
      start = manufacturers.length;
    }
    ManufacturerService.list(
      true,
      { by: 'name', direction: 'asc' },
      start, pageLimit,
      search
    ).then((data: any) => {
      if (event) {
        setManufacturers([
          ...manufacturers,
          ...data
        ]);
        event.target.complete();
        if (!data.length) {
          setInfiniteDisabled(true);
        } else {
          setInfiniteDisabled(false);
        }
      } else {
        setManufacturers(data);
      }
    });
  }

  const setManufacturer = (manufacturer: any) => {
    onDismiss(manufacturer);
  }

  return (
    <>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{TEXT.manufacturerInfo}</IonTitle>
          <IonButtons slot="end">
            <IonButton className='md-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder={TEXT.manufacturerName}
            inputmode="none"
            onIonChange={(e: SearchbarCustomEvent) => searchManufacturers(e.detail.value)}
          >
          </IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent className="search">
        <IonList>
          {
            manufacturers.map((item: any, index: number) => (
              <IonItem detail={false} button key={`manufacturer-${index}`} onClick={() => setManufacturer(item)}
                className={selected && selected === item.id ? 'selected' : ''}>
                <IonLabel>{item.name}</IonLabel>
              </IonItem>
            ))
          }
        </IonList>

        <IonInfiniteScroll
          onIonInfinite={e => searchManufacturers(searchText, e)}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>

      </IonContent>

    </>
  );

};