import { URL_LOCATION } from '../constances/Rest';

import axios from '../configs/axios';

import RestService from './RestService';

const LocationService = {
  getCountries: async () => {
    return new Promise(async (resolve, reject) => {
      await axios.get(
        RestService.routePublic(`${URL_LOCATION}country.json`)
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
    });
  },
  getProvincesByCountry: async (countryCode: string) => {
    return new Promise(async (resolve, reject) => {
      await axios.get(
        RestService.routePublic(`${URL_LOCATION}${countryCode}/province.json`)
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
    });
  },
  getCitiesByProvince: async (countryCode: string, province_id: number) => {
    return new Promise(async (resolve, reject) => {
      await axios.get(
        RestService.routePublic(`${URL_LOCATION}${countryCode}/city.json`)
      ).then(async (response: any) => {
        let cities: Array<any> = [];
        const query = async (province_id: number) => {
          for (let city of response) {
            if (city.province_id === province_id) {
              await cities.push(city);
            }
          }
        }
        await query(province_id);
        resolve(cities);
      }).catch((error) => {
        reject(error.message);
      });
    });
  },
  getSubDistrictsByCity: async (countryCode: string, city_id: number) => {
    return new Promise(async (resolve, reject) => {
      await axios.get(
        RestService.routePublic(`${URL_LOCATION}${countryCode}/subdistrict.json`)
      ).then(async (response: any) => {
        let subdistricts: Array<any> = [];
        const query = async (city_id: number) => {
          for (let subdistrict of response) {
            if (subdistrict.city_id === city_id) {
              await subdistricts.push(subdistrict);
            }
          }
        }
        await query(city_id);
        resolve(subdistricts);
      }).catch((error) => {
        reject(error.message);
      });
    });
  }
};

export default LocationService;