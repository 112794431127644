import '../../css/Modal.scss';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonLabel,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  SearchbarCustomEvent
} from "@ionic/react";
import {
  closeOutline
} from "ionicons/icons";

import { useEffect, useState } from "react";

import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import DistributorService from "../../services/DistributorService";

export const ModalDistributors: React.FC<{
  selected?: string;
  onDismiss: (distributorID?: any, distributorName?: string) => any;
}> = ({ selected = '', onDismiss }) => {

  const pageLimit = 30;

  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [distributors, setDistributors] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState<string>('');

  const searchDistributors = async (search = '', event: any = null) => {
    setSearchText(search);
    let start = 0;
    if (event) {
      start = distributors.length;
    }
    DistributorService.list(
      true,
      { by: 'name', direction: 'asc' },
      start, pageLimit,
      search
    ).then((data: any) => {
      if (event) {
        setDistributors([
          ...distributors,
          ...data
        ]);
        event.target.complete();
        if (!data.length) {
          setInfiniteDisabled(true);
        } else {
          setInfiniteDisabled(false);
        }
      } else {
        setDistributors(data);
      }
    });
  }

  const setDistributor = (distributor: any) => {
    onDismiss(distributor);
  }

  useEffect(() => {
    searchDistributors();
  }, []);

  return (
    <>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{TEXT.distributorInfo}</IonTitle>
          <IonButtons slot="end">
            <IonButton className='md-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder={TEXT.distributorName}
            inputmode="none"
            onIonChange={(e: SearchbarCustomEvent) => searchDistributors(e.detail.value)}
          >
          </IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent className="search">
        <IonList>
          {
            distributors.map((item: any, index: number) => (
              <IonItem button detail={false} key={`distributor-${index}`} onClick={() => setDistributor(item)}
                className={selected && selected === item.id ? 'selected' : ''}>
                <IonLabel>{item.name}</IonLabel>
              </IonItem>
            ))
          }
        </IonList>

        <IonInfiniteScroll
          onIonInfinite={e => searchDistributors(searchText, e)}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>

      </IonContent>

    </>
  );

};