import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect } from "react";

import { 
  useIonToast
} from "@ionic/react";

import MESSAGE from '../../constances/Messages.json';
import BUTTON from '../../constances/Button.json';

export const ToastStatus: React.FC<{
  status: boolean;
  message?: string;
  duration?: number;
  isPresent?: boolean;
  isDismiss?: boolean;
  onDismiss?: (data?: any) => any; 
}> = ({ status, message = '', duration = 2000, isPresent = false, isDismiss = false, onDismiss }) => {

  const [presentToastSuccess, dismissToastSuccess] = useIonToast();
  const presentSuccess = async (message: string = '', duration: number = 2000) => {
    if (!message) {
      message = MESSAGE.success.complete;
    }
    let option: any = {
      message: message,
      position: 'top',
      duration: duration,
      buttons: [
        {
          text: BUTTON.close
        }
      ],
      cssClass: 'success',
      onDidDismiss: () => {
        onDismiss && onDismiss(true);
      }
    }
    presentToastSuccess(option);
  }

  const [presentToastError, dismissToastError] = useIonToast();
  const presentError = async (message: string = '', duration: number = 2000) => {
    if (!message) {
      message = MESSAGE.error.incomplete;
    }
    let option: any = {
      message: message,
      position: 'top',
      duration: duration,
      buttons: [
        {
          text: BUTTON.close
        }
      ],
      cssClass: 'error',
      onDidDismiss: () => {
        onDismiss && onDismiss(true);
      }
    }
    presentToastError(option);
  }

  useEffect(() => {
    if (isPresent) {
      if (status === true) {
        presentSuccess(message, duration);
      } else if (status === false) {
        presentError(message, duration);
      }
    }
  }, [isPresent]);

  useEffect(() => {
    if (isDismiss === true) {
      if (status === true) {
        dismissToastSuccess();
      } else if (status === false) {
        dismissToastError();
      }
    }
  }, [isDismiss]);

  return (
    <></>
  );

};