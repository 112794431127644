import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import NumberFormat from 'react-number-format';

import {
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonInput,
  IonButton,
  IonIcon,
  IonListHeader,
  IonButtons,
  IonCard,
  IonCardContent,
  IonImg,
  IonModal,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
  IonToolbar,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonTextarea
} from "@ionic/react";
import {
  addCircle,
  removeCircle
} from 'ionicons/icons';

import Slim from "../../libraries/slim/slim.react";

import MESSAGE from '../../constances/Messages.json';
import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';

import { ToastStatus } from '../toasts/ToastStatus';
import { AlertStatus } from '../alerts/AlertStatus';
import { AlertPrompt } from '../alerts/AlertPrompt';
import { ModalOrders } from '../modal/Orders';

import CancelService from "../../services/CancelService";
import CourierService from '../../services/CourierService';
import CancelOrderProductService from '../../services/CancelOrderProductService';
import WarehouseService from '../../services/WarehouseService';

export const CancelForm: React.FC<{
  data?: any;
  name?: string;
  isSubmit?: boolean;
  onDidSubmit?: (data?: any) => any;
}> = ({ data = null, name = '', isSubmit = false, onDidSubmit }) => {

  const slideOptsImage = {
    initialSlide: 0
  };

  const [showLoading, setShowLoading] = useState(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [modalOrderData, setModalOrderData] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const [couriers, setCouriers] = useState<any>([]);
  const [warehouses, setWarehouses] = useState<any>([]);

  const { register, handleSubmit, setValue, getValues, trigger, reset, control, formState: { errors } } = useForm();
  const { fields, append, remove, replace } = useFieldArray(
    {
      name: "products",
      control
    }
  );
  const [formGroup, setFormGroup] = useState<any>(null);

  const appendInput = () => {
    append({
      product: null,
      action: null,
      quantity: 1,
      picture: null,
      remark: null
    });
  };

  const initialize = async () => {
    if (!initialized) {

      setInitialized(false);

      await loadCouriers();
      await loadWarehouses();

      if (data) {
        if (data.order_reference) {
          setOrder(data.order_reference);
          loadProducts(data.id);
        }
      }

      setFormGroup(
        {
          ref_code: register('ref_code', {
            value: data && data.ref_code ? data.ref_code : ''
          }),
          courier: register('courier', {
            value: data && data.courier ? data.courier : ''
          }),
          tracking_no: register('tracking_no', {
            value: data && data.tracking_no ? data.tracking_no : ''
          }),
          warehouse: register('warehouse', {
            value: data && data.warehouse ? data.warehouse : ''
          }),
          products: register('products', {
            value: [],
            required: MESSAGE.error.input_required
          })
        }
      );

    }
  }

  const resetFormValues = () => {
    reset();
  }

  const loadCouriers = async () => {
    await CourierService.list(
      true,
      { by: 'name', direction: 'asc' }
    ).then((couriers: any) => {
      setCouriers(couriers);
    }).catch(() => { });
  }

  const loadWarehouses = async () => {
    await WarehouseService.list(
      true,
      { by: 'name', direction: 'asc' }
    ).then((data: any) => {
      setWarehouses(data);
    });
  }

  const loadProducts = async (id: string) => {
    if (id) {
      await CancelOrderProductService.list(
        id,
        null,
        null,
        null,
        null,
        null,
        null,
        ['product', 'order_product']
      ).then(async (data: any) => {
        replace(
          data.map((item: any) => (
            {
              product: item.product,
              quantity: item.quantity,
              action: item.action,
              picture: item.picture,
              picture_current: item.picture && item.picture_reference ? item.picture_reference.original : null,
              remark: item.remark
            }
          ))
        );
        trigger();
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  const onSubmit = async (value: any) => {

    setShowLoading(true);
    onDidSubmit && onDidSubmit(false);

    const onSuccess = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.submit_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit(result);
      }
    }

    const onError = (message: any) => {

      setPresentToast({
        isPresent: true,
        status: false,
        message: message,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      if (onDidSubmit) {
        onDidSubmit();
      }
    }

    if (order) {
      value.order_no = order.order_no;
    }

    let pictures: Array<any> = [];
    if (
      value && value.products && value.products.length
      && order && order.products && order.products.length
    ) {
      let products = [...value.products];
      pictures = products.map((item: any) => {
        let productsAvailable = order.products.filter(
          (itemAvailable: any) => item.product === itemAvailable.product
        ).map((itemAvailable: any) => {
          return itemAvailable.product_reference ? itemAvailable.product_reference : null;
        });
        if (productsAvailable.length) {
          productsAvailable[0].picture = item.picture;
          return productsAvailable[0];
        } else {
          return null;
        }
      });
    }

    let products: Array<any> = [];
    if (
      value && value.products && value.products.length
      && order && order.products && order.products.length
    ) {
      products = value.products.map((item: any, index: number) => {
        let productsAvailable: any = order.products.filter(
          (itemAvailable: any) => item.product === itemAvailable.product
        ).map((itemAvailable: any) => {
          return itemAvailable.product_reference ? itemAvailable.product_reference : null;
        });
        if (productsAvailable.length) {
          let productAvailable: any = productsAvailable[0];
          return {...productAvailable, ...item};
        } else {
          return null;
        }
      });
    }
    value.products = [...products];

    const upload = async (result: any, pictures: Array<any>) => {
      if (result && pictures.length) {
        await CancelOrderProductService.list(
          result.id,
          null,
          null,
          null,
          null,
          null,
          null,
          ['product', 'order_product']
        ).then(async (data: any) => {
          if (data && data.length) {
            for (let item of data) {
              if (item.product_reference) {
                for (let itemPicture of pictures) {
                  if (item.product_reference.id === itemPicture.id && itemPicture.picture && typeof itemPicture.picture !== 'string') {
                    await CancelOrderProductService.upload(item.id, { picture: itemPicture.picture });
                  }
                }
              }
            }
          }
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      }
    }

    if (data) {
      await CancelService.import(value, data.id).then(async (result: any) => {
        await upload(result, pictures);
        onSuccess(result);
      }).catch((error) => {
        onError(error);
      });
    } else {
      await CancelService.import(value).then(async (result: any) => {
        await upload(result, pictures);
        onSuccess(result);
        resetFormValues();
      }).catch((error) => {
        onError(error);
      });
    }

    setShowLoading(false);

  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
        }
      }
    });
    if (onDidSubmit) {
      onDidSubmit();
    }
  }

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit, onError)();
    }
  }, [isSubmit]);

  return (
    <>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertStatus
        isPresent={presentAlert.isPresent}
        status={presentAlert.status}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <AlertPrompt
        isPresent={presentAlertPrompt.isPresent}
        message={presentAlertPrompt.message}
        onDismiss={presentAlertPrompt.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonModal canDismiss swipeToClose isOpen={modalOrderData || modalOrderData === 0 ? true : false}
        onDidDismiss={() => {
          setModalOrderData(null);
        }}>
        <ModalOrders
          title={TEXT.order}
          selected={modalOrderData && modalOrderData != true ? modalOrderData : null}
          onDismiss={async (data: any) => {
            if (data) {
              setOrder(data);
              await trigger();
            }
            setModalOrderData(null);
          }}
        />
      </IonModal>

      {
        formGroup ?
          <form onSubmit={handleSubmit(onSubmit, onError)}>

            {/* Card Content */}
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonCard>
                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                          <IonCol size='12' sizeMd='6'>
                            <IonItem
                              onClick={
                                async () => {
                                  await trigger();
                                  setModalOrderData(order ? order.id : true);
                                }
                              }>
                              <IonLabel position="stacked">{TEXT.orderNumber} ({TEXT.reference}) <span className='form-required'>*</span></IonLabel>
                              <IonInput type='text' disabled={data && data.ref_code ? true : false}
                                value={order ? order.order_no : ''}></IonInput>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.refCode}{TEXT.other}</IonLabel>
                              <IonInput type='text' {...formGroup.ref_code} disabled={data ? true : false}></IonInput>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeMd='4'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.warehouseCancel}</IonLabel>
                              <IonSelect
                                interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                placeholder={TEXT.warehouse}
                                {...formGroup.warehouse}>
                                {
                                  warehouses.map((item: any, index: number) => (
                                    <IonSelectOption key={`warehouse-${index}`} value={item.id}>{item.name}</IonSelectOption>
                                  ))
                                }
                              </IonSelect>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeMd='4'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.courier}</IonLabel>
                              <IonSelect
                                interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                placeholder={TEXT.courier}
                                {...formGroup.courier}>
                                {
                                  couriers.map((item: any, index: number) => (
                                    <IonSelectOption key={`courier-${index}`} value={item.id}>{item.name}</IonSelectOption>
                                  ))
                                }
                              </IonSelect>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeMd='4'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.trackingNo}</IonLabel>
                              <IonInput type='text' {...formGroup.tracking_no}></IonInput>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>

              {
                fields.map((element: any, index) => (
                  <IonRow key={element.id}>
                    <IonCol size='12'>
                      <IonCard>

                        <IonToolbar mode="md">
                          <IonTitle slot='start'>
                            <h4 className="text-full">
                              {TEXT.item} - {index + 1}
                            </h4>
                          </IonTitle>
                          <IonButtons slot='end'>
                            <IonButton fill='clear' onClick={() => {
                              remove(index);
                              trigger();
                            }}>
                              <IonIcon icon={removeCircle} color='danger' slot="icon-only" />
                            </IonButton>
                          </IonButtons>
                        </IonToolbar>

                        <IonCardContent className='ion-no-padding'>
                          <IonGrid>
                            <IonRow>
                              <IonCol size='12'>
                                <IonGrid>
                                  <IonRow>
                                    <IonCol size='12' sizeMd='8'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.product}</IonLabel>
                                        <IonSelect
                                          interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                          placeholder={TEXT.product}
                                          {
                                          ...register(`products.${index}.product`, {
                                            required: MESSAGE.error.input_required
                                          })
                                          }
                                          value={
                                            getValues(`products.${index}.product`) ? getValues(`products.${index}.product`)
                                              : null
                                          }
                                          onIonChange={async (e: any) => {
                                            if (order && order.products && order.products.length) {
                                              const products = order.products.filter(
                                                (item: any) => item.product === e.detail.value
                                              );
                                              if (
                                                products && products.length
                                                && (getValues(`products.${index}.product`) !== e.detail.value)
                                              ) {
                                                await setValue(`products.${index}.quantity`, products[0].quantity);
                                                await setValue(`products.${index}.product`, e.detail.value);
                                                trigger();
                                              }
                                            }
                                          }}>
                                          {
                                            order && order.products && order.products.length ?
                                              order.products.map((item: any, index: number) => (
                                                <IonSelectOption key={`product-${index}`} value={item.product}>
                                                  {
                                                    item.product_reference && item.product_reference.sku ? `[${item.product_reference.sku}] ` : ''
                                                  }
                                                  {item.product_reference ? item.product_reference.name : ''}
                                                </IonSelectOption>
                                              ))
                                              : <></>
                                          }
                                        </IonSelect>
                                      </IonItem>
                                    </IonCol>

                                    <IonCol size='12' sizeMd='4'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.qty}</IonLabel>
                                        <IonInput type='number' min={1}
                                          max={
                                            order && order.products && order.products.length ?
                                              order.products.filter(
                                                (item: any) => item.product === getValues(`products.${index}.product`)
                                              ).map(
                                                (item: any) => {
                                                  if (item.product_reference) {
                                                    return item.quantity;
                                                  } else {
                                                    return null;
                                                  }
                                                }
                                              ) : null
                                          }
                                          {
                                          ...register(`products.${index}.quantity`, {
                                            required: MESSAGE.error.input_required
                                          })
                                          }
                                        >
                                        </IonInput>
                                      </IonItem>
                                    </IonCol>

                                    {/* Product */}
                                    {
                                      order && order.products && order.products.length ?
                                        order.products.filter(
                                          (item: any) => item.product_reference
                                            && (item.product === getValues(`products.${index}.product`))
                                        ).map((item: any, productIndex: number) => (
                                          <IonCol size='12' sizeMd='12' key={`product-${index}-${productIndex}`}>
                                            {/* Product */}
                                            <IonCard>
                                              <IonCardContent>
                                                <IonRow
                                                >
                                                  <IonCol size='12' sizeMd="10" className='product-info tiny'>
                                                    <h4 className='ion-text-left'>{item.product_reference.name}</h4>
                                                    <IonText color="medium" className='ion-text-left'>
                                                      {
                                                        item.product_reference.category_reference ?
                                                          <p>{TEXT.category}: {item.product_reference.category_reference.name}</p>
                                                          : <></>
                                                      }
                                                      <p>{TEXT.productSku} - {item.product_reference.sku}</p>
                                                      <p>
                                                        {TEXT.qtyOrder} -
                                                        <NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} />
                                                      </p>
                                                    </IonText>
                                                  </IonCol>
                                                  <IonCol size='12' sizeMd='2' className='product-info tiny'>
                                                    {
                                                      item.product_reference.figure_reference && item.product_reference.figure_reference.length ?
                                                        <IonSlides options={slideOptsImage} className="product-info-figure-slide">
                                                          {item.product_reference.figure_reference.map((item: any, figureI: number) => {
                                                            return (
                                                              <IonSlide key={`image-slide-${index}-${figureI}`}>
                                                                <div>
                                                                  <IonImg className='content profile-image' src={item.original} />
                                                                </div>
                                                              </IonSlide>
                                                            )
                                                          })}
                                                        </IonSlides>
                                                        : <></>
                                                    }
                                                  </IonCol>
                                                </IonRow>
                                              </IonCardContent>
                                            </IonCard>

                                          </IonCol>
                                        ))
                                        : <></>
                                    }

                                    <IonCol size='12'>
                                      <IonItem>
                                        <IonLabel position="stacked">{TEXT.cancelRemark}</IonLabel>
                                        <IonTextarea {...register(`products.${index}.remark`)}></IonTextarea>
                                      </IonItem>
                                    </IonCol>

                                    <IonCol size='12'>
                                      <IonGrid>
                                        <IonRow>
                                          <IonCol>
                                            <IonGrid>
                                              <IonRow>
                                                <IonCol>
                                                  <IonLabel position="stacked">{TEXT.picture}</IonLabel>
                                                  <Slim
                                                    initialImage={
                                                      getValues(`products.${index}.picture_current`) ? 
                                                      getValues(`products.${index}.picture_current`) : null
                                                    }
                                                    label={`<p>${TEXT.picture}</p>`}
                                                    push={true}
                                                    devicePixelRatio='auto'
                                                    service={(formdata: any, progress: any, success: any, failure: any, slim: any) => {
                                                      slim._showButtons();
                                                      slim._stopProgress();
                                                      slim.requestOutput((fileData: File[]) => {
                                                        setValue(`products.${index}.picture`, fileData[0]);
                                                      });
                                                    }}
                                                    didRemove={
                                                      () => {
                                                        setValue(`products.${index}.picture`, null);
                                                      }
                                                    }
                                                  >
                                                    <input type="file"
                                                      {...register(`products.${index}.picture`)}
                                                      accept="image/*"
                                                    />
                                                  </Slim>
                                                </IonCol>
                                              </IonRow>
                                            </IonGrid>
                                          </IonCol>
                                        </IonRow>
                                      </IonGrid>
                                    </IonCol>

                                    <IonCol size='12'>
                                      <IonList>
                                        <IonRadioGroup
                                          value={getValues(`products.${index}.action`)}
                                          {
                                          ...register(`products.${index}.action`, {
                                            required: MESSAGE.error.input_required
                                          })
                                          }
                                          onIonChange={(e: any) => {
                                            setValue(`products.${index}.action`, e.detail.value);
                                            trigger();
                                          }}>
                                          <IonListHeader>
                                            <IonLabel>{TEXT.manageProduct}</IonLabel>
                                          </IonListHeader>
                                          <IonGrid>
                                            <IonRow>
                                              <IonCol size='12' sizeMd='12'>
                                                <IonItem lines="none">
                                                  <IonLabel className='text-full'>{TEXT.restoreBack}</IonLabel>
                                                  <IonRadio slot="start" value="transfer" />
                                                </IonItem>
                                              </IonCol>
                                              <IonCol size='12' sizeMd='12'>
                                                <IonItem lines="none">
                                                  <IonLabel className='text-full'>{TEXT.withdrawBack}</IonLabel>
                                                  <IonRadio slot="start" value="withdraw" />
                                                </IonItem>
                                              </IonCol>
                                            </IonRow>
                                          </IonGrid>
                                        </IonRadioGroup>
                                      </IonList>
                                    </IonCol>

                                  </IonRow>
                                </IonGrid>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                ))
              }

              <IonRow>
                <IonCol size='12'>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonButton expand='block' color="primary" onClick={appendInput}
                          disabled={!(order && order.products && order.products.length)}>
                          <IonIcon icon={addCircle} slot="start" />
                          {BUTTON.add}
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>

            </IonGrid>

          </form>
          : <></>

      }

    </>
  );

};