import '../css/Page.scss';

import {
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';

import HomeProductImport from '../components/HomeProductImport';
import HomeOrders from '../components/HomeOrders';
import HomeProduct from '../components/HomeProduct';
import HomeProductCancel from '../components/HomeProductCancel';
import HomeProductReturn from '../components/HomeProductReturn';
import { ToolbarStart } from '../components/ToolbarStart';

import MENU from '../constances/Menu.json';

const Home: React.FC = () => {
    return (
        <IonPage>
            <ToolbarStart menuName={MENU.dashboard} />
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            {/* <HomeProduct />
                            <HomeOrders />
                            <HomeProductImport />
                            <HomeProductCancel />
                            <HomeProductReturn /> */}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Home;
