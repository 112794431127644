import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import Barcode from "react-hooks-barcode";

import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonLoading
} from '@ionic/react';
import {
  checkmarkCircle,
  createOutline,
  ellipsisHorizontal,
  removeCircleOutline
} from 'ionicons/icons';

import { Direction, RoutePath } from '../components/RoutePath';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import MESSAGE from '../constances/Messages.json';
import Error from "../components/Error";
import ProductLocation from "../components/ProductLocation";

import ProductService from '../services/ProductService';
import UserService from '../services/UserService';
import VendorService from '../services/VendorService';
import ProductLots from '../components/ProductLots';
import ProductQuantities from '../components/ProductQuantities';
import NumberFormat from 'react-number-format';

const ProductVIew: React.FC<{ data?: any; }> = ({ data = null }) => {

  const location = useLocation();

  const { id } = useParams<{ id: string; }>();
  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [segment, setSegment] = useState<string>('0');
  const [product, setProduct] = useState<any>(null);
  const [barcode, setBarcode] = useState<string | null>(null);
  const [members, setMembers] = useState<Array<any>>([]);
  const [quantity, setQuantity] = useState<string>('on-hand');

  const slider = useRef<HTMLIonSlidesElement>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const initialize = async () => {
    setInitialized(false);
    setShowLoading(true);
    setSegment('0');
    setProduct(null);
    setBarcode(null);
    const userData: any = await UserService.getSession();
    if (userData) {
      setMembers(
        userData.members.map(
          ({ group_id }: any) => (
            group_id
          )
        )
      );
      if (data) {
        setProduct(data);
        if (data.barcode) {
          setBarcode(data.barcode);
        }
      } else {
        await load(id);
      }
    }
    setInitialized(true);
    setShowLoading(false);
  }

  const load = async (id: string | null | undefined) => {
    if (id) {
      await ProductService.get(id, null, true).then((data: any) => {
        setProduct(data);
        if (data.barcode) {
          setBarcode(data.barcode);
        }
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    }
  }

  const slideOpts = {
    initialSlide: 0,
    autoHeight: true,
    allowTouchMove: false
  };

  const slideOptsImage = {
    initialSlide: 0
  };

  const handleSegmentChange = (e: any) => {
    setSegment(e.detail.value);
    slider && slider.current! && slider.current!.slideTo(e.detail.value);
  };

  useEffect(() => {
    setReady(true);
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    initialize();
    window.onresize = () => {
      slider && slider.current! && slider.current!.update();
    }
    return () => {
      window.removeEventListener("resize", () => { });
    }
  }, [data, id]);

  useEffect(() => {
    if (location.pathname === RoutePath.productView.replace(':id', id)) {
      if (initialized) {
        load(id);
      }
    }
  }, [location]);

  return (
    <IonPage className='product-view'>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <IonHeader>

        <IonToolbar>
          <IonButtons slot="start" className="ion-hide-lg-up">
            <IonBackButton defaultHref={RoutePath.product} text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.productView}</IonTitle>
          {
            product ?
              <IonButtons slot="end">
                <IonButton routerLink={RoutePath.productEdit.replace(':id', product.id)}
                  routerDirection={Direction()}>
                  <IonIcon slot="start" icon={createOutline} />
                  {BUTTON.edit}
                </IonButton>
              </IonButtons>
              : <></>
          }
        </IonToolbar>

      </IonHeader>

      <IonContent>

        {
          product ?
            <>
              <IonGrid className='ion-margin-top'>
                <IonRow>
                  <IonCol size='12' sizeMd='12' sizeLg='12' sizeXl='10' offset='0' offsetMd='0' offsetLg='0' offsetXl='1'>
                    <IonCard>
                      <IonToolbar mode="md">
                        <IonTitle slot='start'>
                          <h4 className='text-full'>
                            {product.name}
                          </h4>
                        </IonTitle>
                        {/* <IonButtons slot='end'>
                          <IonButton fill='clear'>
                            <IonIcon icon={ellipsisHorizontal} slot="icon-only" />
                          </IonButton>
                        </IonButtons> */}
                      </IonToolbar>
                      <IonCardContent>
                        <IonRow className='product-info'>
                          <IonCol size='12'
                            sizeMd={product.figure_reference && product.figure_reference.length ? '8' : '12'}>

                            <IonText color="medium">
                              <p>{TEXT.productId} - {product.id}</p>
                              <p>
                                {
                                  product.brand_reference ?
                                    <a>
                                      {product.brand_reference.name}
                                    </a>
                                    : <></>
                                }
                                {
                                  product.subbrand_reference ?
                                    <>
                                      {product.brand_reference ? <>&nbsp;/&nbsp;</> : <></>}
                                      <a>
                                        {product.subbrand_reference.name}
                                      </a>
                                    </>
                                    : <></>
                                }
                              </p>
                            </IonText>

                            {
                              product.attributes.length
                                && product.attributes.reduce((items: any, value: any) => ({ ...items, [value.key]: value.value }), {}).name_en ?
                                <h4>
                                  {
                                    product.attributes.reduce((items: any, value: any) => ({ ...items, [value.key]: value.value }), {}).name_en
                                  }
                                </h4>
                                : <></>
                            }

                            <IonText color="medium">
                              {
                                product.category_reference ?
                                  <p>{TEXT.category}: {product.category_reference.name}</p>
                                  : <></>
                              }
                              <p>{TEXT.productSku}: {product.sku}</p>
                            </IonText>

                            {
                              barcode ?
                                <div className='barcode ion-text-center'>
                                  <Barcode value={barcode} />
                                </div>
                                : <></>
                            }

                          </IonCol>
                          <IonCol size='12' sizeMd='4'>
                            {
                              product.figure_reference && product.figure_reference.length ?
                                <IonSlides pager={true} options={slideOptsImage}>
                                  {product.figure_reference.map((item: any, index: number) => {
                                    return (
                                      <IonSlide key={index}>
                                        <div className='square'>
                                          <IonImg className='content profile-image' src={item.original} />
                                        </div>
                                      </IonSlide>
                                    )
                                  })}
                                </IonSlides>
                                : <></>
                            }
                          </IonCol>
                        </IonRow>
                      </IonCardContent>

                      {/* Header Tab */}
                      <IonSegment value={segment} mode='md' scrollable={true} onIonChange={e => handleSegmentChange(e)}>
                        <IonSegmentButton value="0">
                          <IonLabel>{TEXT.generalData}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="1">
                          <IonLabel>{TEXT.appearance}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="2">
                          <IonLabel>{TEXT.warning}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="3">
                          <IonLabel>{TEXT.productStock}</IonLabel>
                        </IonSegmentButton>
                      </IonSegment>

                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonGrid>

              {/* Slides */}
              <IonSlides options={slideOpts} ref={slider}>

                {/* General Tab */}
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='12' sizeMd='12' sizeLg='12' sizeXl='10' offset='0' offsetMd='0' offsetLg='0' offsetXl='1'>
                        <IonList key="info" className='ion-text-left w-100'>
                          {/* Price */}
                          <IonRow>

                            {/* <IonCol size='12'>
                              <IonCard className='ion-text-center'>
                                <IonRow>

                                  <IonCol size='4'>
                                    <IonText color="danger">
                                      <h4><NumberFormat value={product.cost} displayType={'text'} thousandSeparator={true} /></h4>
                                    </IonText>
                                    <p>{TEXT.costPrice}</p>
                                  </IonCol>
            
                                  <IonCol size='4'>
                                    <IonText color="success">
                                      <h4><NumberFormat value={product.price} displayType={'text'} thousandSeparator={true} /></h4>
                                    </IonText>
                                    <p>{TEXT.regularPrice}</p>
                                  </IonCol>
            
                                  <IonCol size='4'>
                                    <IonText color="primary">
                                      <h4><NumberFormat value={product.salePrice} displayType={'text'} thousandSeparator={true} /></h4>
                                    </IonText>
                                    <p>{TEXT.salePrice}</p>
                                  </IonCol>

                                </IonRow>
                              </IonCard>
                            </IonCol> */}

                            {/* Vendor */}
                            {
                              product.vendor_reference &&
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                ) ?
                                <IonCol size='12' className='col-no-padding'>
                                  <IonCard>
                                    <IonCardHeader>
                                      <IonCardTitle>{TEXT.vendor}</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                      {product.vendor_reference.name}
                                    </IonCardContent>
                                  </IonCard>
                                </IonCol>
                                : <></>
                            }

                            {/* Short Description */}
                            {
                              product.short_description ?
                                <IonCol size='12' className='col-no-padding'>
                                  <IonCard>
                                    <IonCardHeader>
                                      <IonCardTitle>{TEXT.productDetailShort}</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                      {product.short_description}
                                    </IonCardContent>
                                  </IonCard>
                                </IonCol>
                                : <></>
                            }

                            {/* Long Description */}
                            {
                              product.description ?
                                <IonCol size='12' className='col-no-padding'>
                                  <IonCard>
                                    <IonCardHeader>
                                      <IonCardTitle>{TEXT.productDetail}</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                      {product.description}
                                    </IonCardContent>
                                  </IonCard>
                                </IonCol>
                                :
                                <></>
                            }

                            {
                              product.manufacturer_reference ?
                                <IonCol size='12' sizeMd='6' className='col-no-padding'>
                                  <IonCard>
                                    <IonCardHeader>
                                      <IonCardTitle>{TEXT.manufacturerInfo}</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                      {
                                        product.manufacturer_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.name}: </b>
                                            {product.manufacturer_reference.name}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.manufacturer_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.taxID}: </b>
                                            {product.manufacturer_reference.tax_id}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.manufacturer_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.address}: </b>
                                            {product.manufacturer_reference.address}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.manufacturer_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.subDistrict}: </b>
                                            {product.manufacturer_reference.subdistrict}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.manufacturer_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.city}: </b>
                                            {product.manufacturer_reference.city}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.manufacturer_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.province}: </b>
                                            {product.manufacturer_reference.province}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.manufacturer_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.postcode}: </b>
                                            {product.manufacturer_reference.postcode}
                                          </IonLabel>
                                        </p>
                                      }
                                    </IonCardContent>
                                  </IonCard>
                                </IonCol>
                                : <></>
                            }

                            {
                              product.distributor_reference ?
                                <IonCol size='12' sizeMd='6' className='col-no-padding'>
                                  <IonCard>
                                    <IonCardHeader>
                                      <IonCardTitle>{TEXT.distributorInfo}</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                      {
                                        product.distributor_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.name}: </b>
                                            {product.distributor_reference.name}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.distributor_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.taxID}: </b>
                                            {product.distributor_reference.tax_id}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.distributor_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.address}: </b>
                                            {product.distributor_reference.address}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.distributor_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.subDistrict}: </b>
                                            {product.distributor_reference.subdistrict}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.distributor_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.city}: </b>
                                            {product.distributor_reference.city}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.distributor_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.province}: </b>
                                            {product.distributor_reference.province}
                                          </IonLabel>
                                        </p>
                                      }
                                      {
                                        product.distributor_reference.name &&
                                        <p>
                                          <IonLabel>
                                            <b>{TEXT.postcode}: </b>
                                            {product.distributor_reference.postcode}
                                          </IonLabel>
                                        </p>
                                      }
                                    </IonCardContent>
                                  </IonCard>
                                </IonCol>
                                : <></>
                            }
                          </IonRow>
                        </IonList>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>

                {/* Attribute Tab */}
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='12' sizeMd='12' sizeLg='12' sizeXl='10' offset='0' offsetMd='0' offsetLg='0' offsetXl='1'>
                        <IonList key="apperance" className='ion-text-left'>

                          <IonCard>

                            <IonCardHeader>
                              <IonCardTitle>{TEXT.appearance}</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>

                              {
                                product.tags ?
                                  <IonRow>
                                    <IonCol size='12' sizeMd='3' sizeXl='3'>
                                      <IonItem lines="none" className='item-no-padding'>
                                        <IonLabel className='text-full'>{TEXT.productTag} </IonLabel>
                                      </IonItem>
                                    </IonCol>
                                    <IonCol size='12' sizeMd='9' sizeXl='9'>
                                      {
                                        product.tags.map((data: any, index: number) => {
                                          return (
                                            <a key={index}>
                                              <IonChip color="primary">
                                                <IonLabel>{data}</IonLabel>
                                              </IonChip>
                                            </a>
                                          )
                                        })
                                      }
                                    </IonCol>
                                  </IonRow>
                                  :
                                  <></>
                              }

                              <IonRow>
                                <IonCol size='12' sizeMd='3' sizeXl='3'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    <IonLabel className='text-full'>{TEXT.productAttribute}</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='12' sizeMd='9' sizeXl='9'>
                                  <IonList>
                                    {
                                      product.attributes.map((data: any, index: number) => {
                                        return (
                                          data.key !== 'name_en' ?
                                            <IonItem key={index} className='item-no-padding'>
                                              <IonLabel>{data.key}</IonLabel>
                                              <IonInput type="text" value={data.value} readonly></IonInput>
                                            </IonItem>
                                            : <div key={index}></div>
                                        )
                                      })
                                    }
                                  </IonList>
                                </IonCol>
                              </IonRow>

                              {/* Size Piece */}
                              <IonRow className='ion-margin-top'>
                                <IonCol size='12' sizeMd='3' sizeXl='3'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    <IonLabel className='text-full'>{TEXT.productSizePiece}</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.width}</IonLabel>
                                    <IonInput type="text" name="widthPiece" value={product.piece_dimension_width} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.depth}</IonLabel>
                                    <IonInput type="text" name="depthPiece" value={product.piece_dimension_depth} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.height}</IonLabel>
                                    <IonInput type="text" name="heightPiece" value={product.piece_dimension_height} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                              </IonRow>

                              {/* Size Box */}
                              <IonRow>
                                <IonCol size='12' sizeMd='3' sizeXl='3'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    <IonLabel className='text-full'>{TEXT.productSizeBox}</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.width}</IonLabel>
                                    <IonInput type="text" name="widthBox" value={product.box_dimension_width} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.depth}</IonLabel>
                                    <IonInput type="text" name="depthBox" value={product.box_dimension_depth} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.height}</IonLabel>
                                    <IonInput type="text" name="heightBox" value={product.box_dimension_height} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                              </IonRow>

                              {/* Size Carton */}
                              <IonRow>
                                <IonCol size='12' sizeMd='3' sizeXl='3'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    <IonLabel className='text-full'>{TEXT.productSizeCarton}</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.width}</IonLabel>
                                    <IonInput type="text" name="widthCarton" value={product.carton_dimension_width} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.depth}</IonLabel>
                                    <IonInput type="text" name="depthCarton" value={product.carton_dimension_depth} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.height}</IonLabel>
                                    <IonInput type="text" name="heightCarton" value={product.carton_dimension_height} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                              </IonRow>

                              {/* Contain Per */}
                              <IonRow>
                                <IonCol size='12' sizeMd='3' sizeXl='3'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    <IonLabel className='text-full'>{TEXT.productContain}</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.productPiecePerBox}</IonLabel>
                                    <IonInput type="text" name="piecePerBox" value={product.piece_per_box} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.productPiecePerCarton}</IonLabel>
                                    <IonInput type="text" name="piecePerCarton" value={product.piece_per_carton} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.productBoxPerCarton}</IonLabel>
                                    <IonInput type="text" name="boxPerCarton" value={product.box_per_carton} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                              </IonRow>

                              {/* Weight */}
                              <IonRow>
                                <IonCol size='12' sizeMd='3' sizeXl='3'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    <IonLabel className='text-full'>{TEXT.productWeight}</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.productWeightPiece}</IonLabel>
                                    <IonInput type="text" name="weightPiece" value={product.piece_weight} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.productWeightBox}</IonLabel>
                                    <IonInput type="text" name="weightBox" value={product.box_weight} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='4' sizeMd='3' sizeXl='3'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.productWeightCarton}</IonLabel>
                                    <IonInput type="text" name="weightCarton" value={product.carton_weight} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                              </IonRow>

                            </IonCardContent>
                          </IonCard>

                        </IonList>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>

                {/* Warning Tab */}
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='12' sizeMd='12' sizeLg='12' sizeXl='10' offset='0' offsetMd='0' offsetLg='0' offsetXl='1'>
                        <IonList key="warning" className='ion-text-left'>

                          <IonCard>

                            <IonCardHeader>
                              <IonCardTitle>{TEXT.warning}</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>

                              {/* Warning */}
                              <IonRow>
                                <IonCol size='12' sizeMd='4' className='ion-float-left'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    {product.flammable &&
                                      <IonIcon icon={checkmarkCircle}></IonIcon>
                                    }
                                    {!product.flammable &&
                                      <IonIcon icon={removeCircleOutline}></IonIcon>
                                    }
                                    <IonLabel className='icon-left'>{TEXT.isWarningFire}</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='12' sizeMd='4' className='ion-float-left'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    {product.collapsible &&
                                      <IonIcon icon={checkmarkCircle}></IonIcon>
                                    }
                                    {!product.collapsible &&
                                      <IonIcon icon={removeCircleOutline}></IonIcon>
                                    }
                                    <IonLabel className='icon-left'>{TEXT.isWarningFold}</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='12' sizeMd='4' className='ion-float-left'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    {product.fragile &&
                                      <IonIcon icon={checkmarkCircle}></IonIcon>
                                    }
                                    {!product.fragile &&
                                      <IonIcon icon={removeCircleOutline}></IonIcon>
                                    }
                                    <IonLabel className='icon-left'>{TEXT.isWarningFragile}</IonLabel>
                                  </IonItem>
                                </IonCol>
                              </IonRow>

                              <IonRow>
                                <IonCol size='12' sizeMd='4'>
                                  <IonItem lines="none" className='item-no-padding'>
                                    {product.temperature_control &&
                                      <IonIcon icon={checkmarkCircle}></IonIcon>
                                    }
                                    {!product.temperature_control &&
                                      <IonIcon icon={removeCircleOutline}></IonIcon>
                                    }
                                    <IonLabel className='icon-left'>{TEXT.isWarningTemp}</IonLabel>
                                  </IonItem>
                                </IonCol>
                                {
                                  product.temperature_control ?
                                    <>
                                      <IonCol size='12' sizeMd='4'>
                                        <IonItem className='item-no-padding'>
                                          <IonLabel position="stacked">{TEXT.tempLower}</IonLabel>
                                          <IonInput type="text" name="temperatureMin" value={product.temperature_max} readonly></IonInput>
                                        </IonItem>
                                      </IonCol>
                                      <IonCol size='12' sizeMd='4'>
                                        <IonItem className='item-no-padding'>
                                          <IonLabel position="stacked">{TEXT.tempHigher}</IonLabel>
                                          <IonInput type="text" name="temperatureMax" value={product.temperature_min} readonly></IonInput>
                                        </IonItem>
                                      </IonCol>
                                    </>
                                    : <></>
                                }
                              </IonRow>

                              <IonRow>
                                <IonCol size='12'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.productNoticeCheck}</IonLabel>
                                    <IonInput type="text" name="inspectionCriteria" value={product.inspection_criteria} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                                <IonCol size='12'>
                                  <IonItem className='item-no-padding'>
                                    <IonLabel position="stacked">{TEXT.productNoticePack}</IonLabel>
                                    <IonInput type="text" name="packingRemark" value={product.packing_remark} readonly></IonInput>
                                  </IonItem>
                                </IonCol>
                              </IonRow>

                            </IonCardContent>
                          </IonCard>

                        </IonList>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>

                {/* Location Tab */}
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='12' sizeXl='10' offsetXl='1'>
                        <IonList key="productStock" className='ion-text-left w-100'>
                          <IonRow>
                            {
                              product.vendor_reference &&
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                ) ?
                                <IonCol size='12' className='col-no-padding'>
                                  <IonCard>
                                    <IonCardHeader>
                                      <IonCardTitle>
                                        {TEXT.stockCard}
                                      </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                      <IonRow>
                                        <IonCol size='6' sizeMd='6' className='col-no-padding'>
                                          <IonCard button className={quantity === 'on-hand' ? 'stock-card selected' : ''}
                                            onClick={() => {
                                              setQuantity('on-hand')
                                            }}>
                                            <IonCardHeader>
                                              <h4>
                                                {TEXT.qtyOnHand}
                                              </h4>
                                            </IonCardHeader>
                                            <IonCardContent>
                                              <NumberFormat value={product.quantity} displayType={'text'} thousandSeparator={true}
                                                className={(parseInt(product.quantity) > 0 ? 'text-success' : 'text-danger') + ' text-number'} />
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                        <IonCol size='6' sizeMd='6' className='col-no-padding'>
                                          <IonCard button className={quantity === 'available' ? 'stock-card selected' : ''}
                                            onClick={() => {
                                              setQuantity('available')
                                            }}>
                                            <IonCardHeader>
                                              <h4>
                                                {TEXT.qtyAvailable}
                                              </h4>
                                            </IonCardHeader>
                                            <IonCardContent>
                                              <NumberFormat value={product.quantity_available} displayType={'text'} thousandSeparator={true}
                                                className={(parseInt(product.quantity_available) > 0 ? 'text-success' : 'text-danger') + ' text-number'} />
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                        <IonCol size='6' sizeMd='2' className='col-no-padding'>
                                          <IonCard button className={quantity === 'inbound-pending' ? 'stock-card selected' : ''}
                                            onClick={() => {
                                              setQuantity('inbound-pending')
                                            }}>
                                            <IonCardHeader>
                                              <h4>
                                                {TEXT.qtyInboundPending}
                                              </h4>
                                            </IonCardHeader>
                                            <IonCardContent>
                                              <NumberFormat value={product.quantity_inbound_pending} displayType={'text'} thousandSeparator={true}
                                                className={(parseInt(product.quantity_inbound_pending) > 0 ? 'text-success' : 'text-danger') + ' text-number'} />
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                        <IonCol size='6' sizeMd='2' className='col-no-padding'>
                                          <IonCard button className={quantity === 'inbound-processing' ? 'stock-card selected' : ''}
                                            onClick={() => {
                                              setQuantity('inbound-processing')
                                            }}>
                                            <IonCardHeader>
                                              <h4>
                                                {TEXT.qtyInboundProcessing}
                                              </h4>
                                            </IonCardHeader>
                                            <IonCardContent>
                                              <NumberFormat value={product.quantity_inbound_processing} displayType={'text'} thousandSeparator={true}
                                                className={(parseInt(product.quantity_inbound_processing) > 0 ? 'text-success' : 'text-danger') + ' text-number'} />
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                        <IonCol size='6' sizeMd='2' className='col-no-padding'>
                                          <IonCard button className={quantity === 'outbound-pending' ? 'stock-card selected' : ''}
                                            onClick={() => {
                                              setQuantity('outbound-pending')
                                            }}>
                                            <IonCardHeader>
                                              <h4>
                                                {TEXT.qtyOutboundPending}
                                              </h4>
                                            </IonCardHeader>
                                            <IonCardContent>
                                              <NumberFormat value={product.quantity_outbound_pending} displayType={'text'} thousandSeparator={true}
                                                className={(parseInt(product.quantity_outbound_pending) > 0 ? 'text-success' : 'text-danger') + ' text-number'} />
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                        <IonCol size='6' sizeMd='2' className='col-no-padding'>
                                          <IonCard button className={quantity === 'outbound-processing' ? 'stock-card selected' : ''}
                                            onClick={() => {
                                              setQuantity('outbound-processing')
                                            }}>
                                            <IonCardHeader>
                                              <h4>
                                                {TEXT.qtyOutboundProcessing}
                                              </h4>
                                            </IonCardHeader>
                                            <IonCardContent>
                                              <NumberFormat value={product.quantity_outbound_processing} displayType={'text'} thousandSeparator={true}
                                                className={(parseInt(product.quantity_outbound_processing) > 0 ? 'text-success' : 'text-danger') + ' text-number'} />
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                        <IonCol size='6' sizeMd='2' className='col-no-padding'>
                                          <IonCard button className={quantity === 'withdraw-pending' ? 'stock-card selected' : ''}
                                            onClick={() => {
                                              setQuantity('withdraw-pending')
                                            }}>
                                            <IonCardHeader>
                                              <h4>
                                                {TEXT.qtyWithdrawPending}
                                              </h4>
                                            </IonCardHeader>
                                            <IonCardContent>
                                              <NumberFormat value={product.quantity_withdraw_pending} displayType={'text'} thousandSeparator={true}
                                                className={(parseInt(product.quantity_withdraw_pending) > 0 ? 'text-success' : 'text-danger') + ' text-number'} />
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                        <IonCol size='6' sizeMd='2' className='col-no-padding'>
                                          <IonCard button className={quantity === 'withdraw-processing' ? 'stock-card selected' : ''}
                                            onClick={() => {
                                              setQuantity('withdraw-processing')
                                            }}>
                                            <IonCardHeader>
                                              <h4>
                                                {TEXT.qtyWithdrawProcessing}
                                              </h4>
                                            </IonCardHeader>
                                            <IonCardContent>
                                              <NumberFormat value={product.quantity_withdraw_processing} displayType={'text'} thousandSeparator={true}
                                                className={(parseInt(product.quantity_withdraw_processing) > 0 ? 'text-success' : 'text-danger') + ' text-number'} />
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                      </IonRow>
                                    </IonCardContent>
                                    {
                                      product && product.quantities ?
                                        // Stock Card
                                        <ProductQuantities data={
                                          quantity === 'on-hand' ?
                                            product.quantities :
                                            quantity === 'available' ?
                                              product.quantities_available :
                                              quantity === 'inbound-pending' ?
                                                product.quantities_inbound_pending :
                                                quantity === 'inbound-processing' ?
                                                  product.quantities_inbound_processing :
                                                  quantity === 'outbound-pending' ?
                                                    product.quantities_outbound_pending :
                                                    quantity === 'outbound-processing' ?
                                                      product.quantities_outbound_processing :
                                                      quantity === 'withdraw-pending' ?
                                                        product.quantities_withdraw_pending :
                                                        quantity === 'withdraw-processing' ?
                                                          product.quantities_withdraw_processing :
                                                          []
                                        } />
                                        :
                                        <IonCardContent className='ion-text-center'>
                                          {TEXT.productNeverAddASN}
                                        </IonCardContent>
                                    }
                                  </IonCard>
                                </IonCol>
                                : <></>
                            }
                            <IonCol size='12' className='col-no-padding'>
                              <IonCard>
                                <IonCardHeader className='ion-text-left'>
                                  <IonCardTitle>
                                    {TEXT.productLotView}
                                  </IonCardTitle>
                                </IonCardHeader>
                                {
                                  product && product.lots ?
                                    <ProductLots data={product.lots} />
                                    :
                                    <IonCardContent className='ion-text-center'>
                                      {TEXT.productNeverAddASN}
                                    </IonCardContent>
                                }
                              </IonCard>
                            </IonCol>
                            {
                              product.vendor_reference &&
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                ) ?
                                <IonCol size='12' className='col-no-padding'>
                                  <IonCard>
                                    <IonCardHeader>
                                      <IonCardTitle>
                                        {TEXT.warehouseLocation}
                                      </IonCardTitle>
                                    </IonCardHeader>
                                    {
                                      product && product.warehouses ?
                                        <ProductLocation data={product.warehouses} />
                                        :
                                        <IonCardContent className='ion-text-center'>
                                          {TEXT.productNeverAddASN}
                                        </IonCardContent>
                                    }
                                  </IonCard>
                                </IonCol>
                                : <></>
                            }
                          </IonRow>
                        </IonList>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>

              </IonSlides>
            </>
            :
            initialized ?
              <Error header={MESSAGE.error.not_found} />
              : <></>
        }
      </IonContent>
    </IonPage>
  );
};

export default ProductVIew;