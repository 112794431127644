// Stock Card
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
  IonText
} from '@ionic/react';
import {
  alertCircleOutline,
  arrowDownOutline,
  arrowUpOutline,
  closeOutline,
  copyOutline,
  moveOutline,
  removeOutline
} from 'ionicons/icons';

import moment from 'moment';

import TYPES from '../constances/Types.json';
import TEXT from '../constances/Text.json';
import { pageTable } from './TableStyle';
import { Direction, RoutePath } from './RoutePath';
import { ToastStatus } from './toasts/ToastStatus';

import UserService from '../services/UserService';

const ProductQuantities: React.FC<{ data: any; }> = ({ data = [] }) => {

  const types: any = TYPES.job;
  const [members, setMembers] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  const columns: any = [
    {
      name: TEXT.date,
      selector: (row: { create_at: any; }) => row.create_at,
      cell: (row: { create_at: any }) =>
      (
        row.create_at
          ? moment.utc(row.create_at).local().format('lll')
          : '-'
      ),
      left: true,
      sortable: true,
      width: '150px',
    },
    {
      name: TEXT.type,
      selector: (row: { job_reference: any }) => row.job_reference.type,
      cell: (row: { job_reference: any }) =>
      (
        <IonButtons>
          <IonButton fill='clear'>
            <IonIcon slot="start" icon={
              row.job_reference.type === 'inbound' ? arrowDownOutline
                : row.job_reference.type === 'outbound' ? arrowUpOutline
                  : row.job_reference.type === 'transfer' ? moveOutline
                    : row.job_reference.type === 'withdraw' ? removeOutline
                      : row.job_reference.type === 'cancel' ? closeOutline
                        : alertCircleOutline
            } />
            <IonLabel>{types[row.job_reference.type]}</IonLabel>
          </IonButton>
        </IonButtons>
      ),
      center: true,
      sortable: true,
      width: '200px',
    },
    {
      name: TEXT.jobNo,
      selector: (row: { job: any; }) => row.job,
      cell: (row: { job: any, job_reference: any, asn: any, order: any, transfer: any, withdraw: any, cancel: any }) =>
      (
        <IonButtons>
          <IonButton fill='clear' routerLink={RoutePath.jobView.replace(':id', row.job)} routerDirection={Direction()}>
            <IonLabel>
              {row.job}
              <p className='super-subtitle'>
                <IonText color="medium">(
                  {row.job_reference.type ?
                    row.job_reference.type === 'inbound' ? row.asn && row.asn.id ? TEXT.asn + '-' + row.asn.id : '' :
                      row.job_reference.type === 'outbound' ? row.order && row.order.order_no ? row.order.order_no : '' :
                        row.job_reference.type === 'transfer' ? row.transfer && row.transfer.id ? TEXT.transfer + '-' + row.transfer.id : '' :
                          row.job_reference.type === 'withdraw' ? row.withdraw && row.withdraw.id ? TEXT.withdraw + '-' + row.withdraw.id : '' :
                            row.job_reference.type === 'cancel' ? row.cancel && row.cancel.id ? TEXT.cancel + '-' + row.cancel.id : '' :
                              <></>
                    : <></>
                  }
                  )</IonText>
              </p>
            </IonLabel>
          </IonButton>
        </IonButtons>
      ),
      center: true,
      sortable: true,
      width: '150px',
    },
    {
      name: TEXT.qty,
      selector: (row: { piece: any; }) => row.piece,
      cell: (row: { piece: any, job_reference: any }) => (
        <NumberFormat value={row.piece} displayType={'text'} thousandSeparator={true} />
      ),
      center: true,
      sortable: true,
      width: '100px',
    },
    {
      name: TEXT.location,
      selector: (row: any) =>
        row.product_warehouse_reference ?
          row.product_warehouse_reference.warehouse
          + '.' + row.product_warehouse_reference.floor
          + '.' + row.product_warehouse_reference.aisle
          + '.' + row.product_warehouse_reference.bay
          + '.' + row.product_warehouse_reference.level
          + '.' + row.product_warehouse_reference.bin
          : row.suggestion && row.suggestion.length ?
            row.suggestion[0].reference.warehouse
            + '.' + row.suggestion[0].reference.floor
            + '.' + row.suggestion[0].reference.aisle
            + '.' + row.suggestion[0].reference.bay
            + '.' + row.suggestion[0].reference.level
            + '.' + row.suggestion[0].reference.bin
            : null,
      cell: (row: any) => (
        row.product_warehouse_reference ?
          <CopyToClipboard text={
            row.product_warehouse_reference.warehouse
            + '.' + row.product_warehouse_reference.floor
            + '.' + row.product_warehouse_reference.aisle
            + '.' + row.product_warehouse_reference.bay
            + '.' + row.product_warehouse_reference.level
            + '.' + row.product_warehouse_reference.bin
          } onCopy={() => {
            setPresentToast({
              isPresent: true,
              status: true,
              message: `${row.product_warehouse_reference.warehouse
                + '.' + row.product_warehouse_reference.floor
                + '.' + row.product_warehouse_reference.aisle
                + '.' + row.product_warehouse_reference.bay
                + '.' + row.product_warehouse_reference.level
                + '.' + row.product_warehouse_reference.bin
                } ${TEXT.wasCopied}`,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          }}>
            <IonButton fill='clear'>
              {
                row.product_warehouse_reference.warehouse
                + '.' + row.product_warehouse_reference.floor
                + '.' + row.product_warehouse_reference.aisle
                + '.' + row.product_warehouse_reference.bay
                + '.' + row.product_warehouse_reference.level
                + '.' + row.product_warehouse_reference.bin
              }
              <IonIcon size="small" slot="end" icon={copyOutline} />
            </IonButton>
          </CopyToClipboard>
          : row.suggestion && row.suggestion.length ?
            row.suggestion.map((item: any) => 
            <CopyToClipboard text={
              item.reference.warehouse
              + '.' + item.reference.floor
              + '.' + item.reference.aisle
              + '.' + item.reference.bay
              + '.' + item.reference.level
              + '.' + item.reference.bin
            } onCopy={() => {
              setPresentToast({
                isPresent: true,
                status: true,
                message: `${item.reference.warehouse
                  + '.' + item.reference.floor
                  + '.' + item.reference.aisle
                  + '.' + item.reference.bay
                  + '.' + item.reference.level
                  + '.' + item.reference.bin
                  } ${TEXT.wasCopied}`,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            }}>
              <IonButton fill='clear'>
                {
                  item.reference.warehouse
                  + '.' + item.reference.floor
                  + '.' + item.reference.aisle
                  + '.' + item.reference.bay
                  + '.' + item.reference.level
                  + '.' + item.reference.bin
                }
                <IonIcon size="small" slot="end" icon={copyOutline} />
              </IonButton>
            </CopyToClipboard>)
          : null
      ),
      center: true,
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const initialize = async () => {
    const userData: any = await UserService.getSession();
    if (userData) {
      setMembers(
        userData.members.map(
          ({ group_id }: any) => (
            group_id
          )
        )
      );
    }
    setInitialized(true);
  }

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  return (
    <>

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      {
        data && initialized ?
          <DataTable
            columns={columns}
            data={data}
            customStyles={pageTable}
            pagination
            noDataComponent={TEXT.noDataComponent}
            paginationComponentOptions={paginationComponentOptions}
          />
          : <></>
      }

    </>
  );
};

export default ProductQuantities;