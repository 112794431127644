import { 
  URL_ASN,
} from '../constances/Rest';

import axios from '../configs/axios';

import RestService from './RestService';

const ASNService = {
  list: async (
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    status: string|null = null,
    search: string|null = null,
    dateFrom: any = null,
    dateTo: any = null,
    returnReferences: any = false,
    vendorID: string|null = null,
    warehouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (status) {
				options.status = status;
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'id',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        );
        conjunction = 'AND';
			}
			if (dateFrom) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '>=',
            value: `'${dateFrom}'`
          }
        );
        conjunction = 'AND';
			}
			if (dateTo) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '<=',
            value: `'${dateTo}'`
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_ASN}list${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  listByJob: async (
    jobID: string,
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null,
    returnReferences: any = false,
    vendorID: any = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
      if (jobID) {
        payload.job = jobID;
      }
			if (search) {
				payload.extensions = [
          {
            conjunction: '',
            key: 'name',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        ];
			}

      await axios.post(
        RestService.route(`${URL_ASN}list${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  count: async (
    active: boolean|null = null, 
    start: number|null = null,
    limit: number|null = null,
    status: string|null = null,
    search: string|null = null,
    dateFrom: any = null,
    dateTo: any = null,
    vendorID: string|null = null,
    warehouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (status) {
				options.status = status;
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'id',
            operator: 'LIKE',
            value: `'%${search}%'`,
          }
        );
        conjunction = 'AND';
			}
			if (dateFrom) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '>=',
            value: `'${dateFrom}'`
          }
        );
        conjunction = 'AND';
			}
			if (dateTo) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'create_at',
            operator: '<=',
            value: `'${dateTo}'`
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_ASN}count${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response.result);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  get: async (id: string|number, active: boolean|null = null, returnReferences: any = false) => {
		return new Promise(async (resolve, reject) => {

      let options: any = {};
      if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }

      await axios.get(
        RestService.route(`${URL_ASN}${id}${RestService.handleQuery(options)}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  create: async (values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.post(
        RestService.route(`${URL_ASN}create`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  update: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;
      payload.id = id;

      await axios.put(
        RestService.route(`${URL_ASN}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  delete: async (id: string|number) => {
		return new Promise(async (resolve, reject) => {

      await axios.delete(
        RestService.route(`${URL_ASN}${id}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  patch: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.patch(
        RestService.route(`${URL_ASN}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  changeStatus: async (id: string|number, values: any) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = {
        status: values
      };

      await axios.patch(
        RestService.route(`${URL_ASN}${id}/status`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  putaway: async (
    id: string|number, 
    productID: any = null, 
    sku: any = null, 
    barcode: any = null, 
    quantity: any = 0, 
    location: any = ''
  ) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = {
        product: productID,
        sku: sku,
        barcode: barcode,
        quantity: quantity,
        location: location,
      };

      await axios.patch(
        RestService.route(`${URL_ASN}${id}/putaway`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  upload: async (id: string|number, values: any) => {
		return new Promise(async (resolve, reject) => {
      
      await axios.post(
        RestService.route(`${URL_ASN}${id}/upload`),
        await RestService.createFormData(values),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  import: async (values: any, id: any = null, vendorID: any = null) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      let options: any = {};
			if (vendorID) {
				options.vendor = vendorID;
			}

      if (id) {
        await axios.post(
          RestService.route(`${URL_ASN}${id}/import${RestService.handleQuery(options)}`),
          payload,
          { headers: await RestService.retrieveHeader(true) }
        ).then(async (response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error.message);
        });
      } else {
        await axios.post(
          RestService.route(`${URL_ASN}import${RestService.handleQuery(options)}`),
          payload,
          { headers: await RestService.retrieveHeader(true) }
        ).then(async (response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error.message);
        });
      }

		});
  },
  csv: async (asnID: any = null, expectedDate: any, file: any, vendorID: any, fromString = false) => {
		return new Promise(async (resolve, reject) => {

      let formData = null;
      if (!fromString) {
        formData = new FormData();
        formData.append('id', asnID);
        formData.append('csv', file);
        formData.append('vendor', vendorID);
        formData.append('expect_date', expectedDate.toString());
      } else {
        formData = {
          id: asnID,
          csv: file,
          vendor: vendorID,
          expect_date: expectedDate.toString(),
          from_string: '1'
        }
      }

      await axios.post(
        RestService.route(`${URL_ASN}csv`),
        formData,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  csvPutAway: async (asnID: any = null, file: any, fromString = false) => {
		return new Promise(async (resolve, reject) => {

      let formData = null;
      if (!fromString) {
        formData = new FormData();
        formData.append('id', asnID);
        formData.append('csv', file);
      } else {
        formData = {
          id: asnID,
          csv: file,
          from_string: '1'
        }
      }

      await axios.post(
        RestService.route(`${URL_ASN}csv_putaway`),
        formData,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
};

export default ASNService;