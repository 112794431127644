import '../../css/Modal.scss';

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardContent,
  IonLoading,
  IonImg,
  IonText,
  IonCardHeader,
  IonCardTitle,
  IonSpinner,
  IonLabel,
  IonIcon
} from "@ionic/react";


import TEXT from '../../constances/Text.json';
import BUTTON from '../../constances/Button.json';
import MENU from '../../constances/Menu.json';

import { Direction, RoutePath } from '../RoutePath';
import { useEffect, useState } from 'react';
import ProductService from '../../services/ProductService';
import { closeOutline, openOutline } from 'ionicons/icons';

export const ModalProductPreview: React.FC<{
  productId: any;
  onDismiss: () => any;
}> = ({ productId = null, onDismiss }) => {

  const [product, setProduct] = useState<any>(null);
  const [ready, setReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  useEffect(() => {
    if (productId) {
      load(productId);
    }
  }, [productId]);

  const load = async (id: any) => {
    setShowLoading(true);
    await ProductService.get(id, null, true).then((data: any) => {
      setProduct(data);
      setShowLoading(false);
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton className='ios-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
          <IonTitle>{MENU.productView}</IonTitle>
          <IonButtons slot="end">
            {
              product && productId ?
                <IonButton
                  routerLink={RoutePath.productView.replace(':id', productId)}
                  routerDirection={Direction()}
                  onClick={() => onDismiss()}
                >
                  <IonIcon slot="start" icon={openOutline} />
                  {BUTTON.seeMore}
                </IonButton>
                : <></>
            }
            <IonButton className='md-only' onClick={() => onDismiss()}>
              <IonIcon slot="start" icon={closeOutline} />
              {BUTTON.close}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {/* <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        /> */}

        {
          showLoading ?
            <div className='container-block ion-text-center'>
              <div>
                <IonGrid>
                  <IonRow>
                    <IonCol size='12'>
                      <IonSpinner color='medium' />
                      <IonText color='medium'>
                        <p className='ion-no-margin'>{TEXT.loading}</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>

            : <></>
        }
        <div>
          <IonGrid>
            <IonRow>
              {
                product ?
                  <>
                    <IonCol size='12'>
                      <IonCard>
                        <IonCardContent>
                          <IonGrid>
                            <IonRow>
                              {
                                product.figure_reference && product.figure_reference.length ?
                                  <IonCol size='12' sizeMd='4'>
                                    <div className='square'>
                                      <IonImg className='content profile-image' src={product.figure_reference[0].original} />
                                    </div>
                                  </IonCol>
                                  : <></>
                              }

                              <IonCol size='12' sizeMd={product.figure_reference && product.figure_reference.length ? '8' : '12'}>
                                <IonText color="dark">
                                  {/* Name */}
                                  <h2 className='text-full'><b>{product.name}</b></h2>
                                  <br></br>

                                  {/* ID */}
                                  <p><b>{TEXT.productId}:</b>&nbsp;{product.id}</p>

                                  {/* SKU */}
                                  <p><b>{TEXT.productSku}:</b>&nbsp;{product.sku}</p>

                                  {/* Barcode */}
                                  {
                                    product.barcode ? <p><b>{TEXT.barcode}:</b>&nbsp;{product.barcode} </p> : <></>
                                  }

                                  {/* Brand */}
                                  {
                                    product.brand_reference && product.subbrand_reference ?
                                      <>
                                        <p><b>{TEXT.brand}:</b>&nbsp;
                                          {
                                            product.brand_reference ?
                                              <>
                                                {product.brand_reference.name}
                                              </>
                                              : <></>
                                          }
                                          {
                                            product.subbrand_reference ?
                                              <>
                                                {product.brand_reference ? <>&nbsp;/&nbsp;</> : <></>}
                                                <>
                                                  {product.subbrand_reference.name}
                                                </>
                                              </>
                                              : <></>
                                          }
                                        </p>
                                      </>
                                      : <></>
                                  }

                                  {/* Category */}
                                  {
                                    product.category_reference ?
                                      <p><b>{TEXT.category}:</b>&nbsp;{product.category_reference.name}</p>
                                      : <></>
                                  }

                                  {/* Attributes */}
                                  {
                                    product.attributes.length ?
                                      <p><b>{TEXT.attribute}:</b>
                                        {
                                          product.attributes.map((items: any, index: number) => {
                                            return (
                                              <span key={index}>
                                                {
                                                  items.key !== 'name_en' ?
                                                    <>{index >= 1 ? '' : ''}&nbsp;&nbsp;{items.key} = {items.value}</>
                                                    : <></>
                                                }
                                              </span>
                                            )
                                          })
                                        }
                                      </p>
                                      : <></>
                                  }

                                  {/* Vendor */}
                                  {
                                    product.vendor_reference ?
                                      <p>
                                        <b>{TEXT.vendor}:</b>&nbsp;
                                        {product.vendor_reference.name}
                                      </p>
                                      : <></>}
                                </IonText>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>

                    <IonCol size='12'>
                      {/* Short Description */}
                      {
                        product.short_description ?
                          <IonCol size='12' className='col-no-padding'>
                            <IonCard>
                              <IonCardHeader>
                                <IonCardTitle>{TEXT.productDetailShort}</IonCardTitle>
                              </IonCardHeader>
                              <IonCardContent>
                                {product.short_description}
                              </IonCardContent>
                            </IonCard>
                          </IonCol>
                          : <></>
                      }
                    </IonCol>
                  </>
                  : <></>
              }
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </>
  );
};