import { IonPage, IonContent, IonBadge, IonCol, IonGrid, IonLabel, IonRow, IonText, IonButton, IonCard, IonCardContent, IonIcon, IonItem } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { ToolbarStart } from "../components/ToolbarStart";

import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import { pin } from "ionicons/icons";

import { productHistoryView, productHistoryViewPackage } from '../components/InterfaceData';
import { RoutePath } from "../components/RoutePath";
import DataTable from "react-data-table-component";
import { tableStylesE5 } from "../components/TableStyle";
import NumberFormat from "react-number-format";

// interface ProductViewPageProps extends RouteComponentProps<{ id: string; }> { }

const ProductHistoryView: React.FC = () => {
    const history = useHistory();

    const colors: any = COLORS.order;
    const statuses: any = STATUS.order;

    const dataItems: productHistoryView = {
        id: 0,
        orderNo: 'SGO-109',
        orderDate: '14-06-2022',
        departDate: '14-06-2022',
        receiveDate: '',
        courier: 'KERRY',
        status: 'pending',
        products: [
            {
                id: 0,
                sku: 'SKU00001',
                name: 'PRODUCT 1',
                qty: 3,
                subtotal: '186.92',
                vat: '13.08',
                total: '200',
            }
        ],
        shipping: {
            name: 'Beyond Success',
            address: '200 ลาดพร้าว 100',
            subDistrict: 'พลับพลา',
            city: 'วังทองหลาง',
            province: 'กรุงเทพมหานคร',
            postcode: '10310',
            phone: '0809998887',
            pin: 'XXXXXXXXXXX, XXXXXXXXXXX',
        },
        billing: {
            name: 'บริษัท Beyond Success จำกัด',
            address: '200 ลาดพร้าว 100',
            subDistrict: 'พลับพลา',
            city: 'วังทองหลาง',
            province: 'กรุงเทพมหานคร',
            postcode: '10310',
            phone: '0809998887',
            taxID: '1100600281999',
        },
        sumQty: 3,
        paymentMethod: 'ชำระปลายทาง',
        cod: '200',
        shippingMethod: 'ด่วนพิเศษ',
        subtotal: '200',
        discount: '50',
        total: '150',
        shippingTotal: '50',
        totalFinal: '200',
        note: 'ฝากไว้กับ รปภ. หน้าหมู่บ้าน',
    };

    const dataPackage: productHistoryViewPackage[] = [
        {
            id: 0,
            trackingNo: 'TH8018209812073',
            width: '10',
            depth: '10',
            height: '10',
            weight: '0.5',
            qty: 1,
            LPNo: 'LP089808391',
            cod: '200',
            products: [
                {
                    id: 0,
                    sku: 'SKU00001',
                    name: 'PRODUCT 1',
                    qty: 3,
                },
            ]
        },
    ];

    // DataTable
    const columns = [
        {
            name: TEXT.productSku,
            selector: (row: { sku: any; }) => row.sku,
            cell: (row: { sku: string }) => (
                <IonButton fill='clear' routerLink={RoutePath.productView.replace(':id', row.sku)}>{row.sku}</IonButton>
            ),
        },
        {
            name: TEXT.productHeaderNameSKU,
            selector: (row: { name: any; }) => row.name,
        },
        {
            name: TEXT.qtyPiece,
            selector: (row: { qty: any; }) => row.qty,
        },
        {
            name: TEXT.subtotalPerPiece,
            selector: (row: { subtotal: any; }) => row.subtotal,
            cell: (row: { subtotal: string }) => (
                <NumberFormat value={row.subtotal} displayType={'text'} thousandSeparator={true} />
            ),
        },
        {
            name: TEXT.vatPerPiece,
            selector: (row: { vat: any; }) => row.vat,
            cell: (row: { vat: string }) => (
                <NumberFormat value={row.vat} displayType={'text'} thousandSeparator={true} />
            ),
        },
        {
            name: TEXT.totalPerPiece,
            selector: (row: { total: any; }) => row.total,
            cell: (row: { total: string }) => (
                <NumberFormat value={row.total} displayType={'text'} thousandSeparator={true} />
            ),
        },
    ];

    const columnsProduct = [
        {
            name: TEXT.productSku,
            selector: (row: { sku: any; }) => row.sku,
            cell: (row: { sku: string }) => (
                <IonButton fill='clear' routerLink={RoutePath.productView.replace(':id', row.sku)}>{row.sku}</IonButton>
            ),
        },
        {
            name: TEXT.productName,
            selector: (row: { name: any; }) => row.name,
        },
        {
            name: TEXT.qtyPiece,
            selector: (row: { qty: any; }) => row.qty,
        }
    ];

    return (
        <IonPage>
            <ToolbarStart menuName={MENU.productHistoryView} />

            <IonContent>
                <IonGrid>
                    <IonRow className='ion-margin-horizontal'>
                        <IonCol size='12'>
                            <IonText color="dark">
                                <h3>{dataItems.orderNo}</h3>
                            </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-horizontal">
                        <IonCol size='6' sizeMd="3">
                            <IonText color="medium">{TEXT.orderDateCreate}</IonText>
                        </IonCol>
                        <IonCol size='6' sizeMd="3">
                            <IonText color="dark">{dataItems.orderDate}</IonText>
                        </IonCol>

                        <IonCol size='6' sizeMd="3">
                            <IonText color="medium">{TEXT.orderDateDepart}</IonText>
                        </IonCol>
                        <IonCol size='6' sizeMd="3">
                            <IonText color="dark">{dataItems.departDate}</IonText>
                        </IonCol>

                        <IonCol size='6' sizeMd="3">
                            <IonText color="medium">{TEXT.orderDateReceive}</IonText>
                        </IonCol>
                        <IonCol size='6' sizeMd="3">
                            <IonText color="dark">{dataItems.receiveDate}</IonText>
                        </IonCol>

                        <IonCol size='6' sizeMd="3">
                            <IonText color="medium">{TEXT.status}</IonText>
                        </IonCol>
                        <IonCol size='6' sizeMd="3">
                            <IonLabel>
                                <IonBadge color={colors[dataItems.status]}>{statuses[dataItems.status]}</IonBadge>
                            </IonLabel>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size='12' sizeMd="6">
                            <IonCard>
                                <IonItem>
                                    <IonLabel>{TEXT.shippingAddress}</IonLabel>
                                </IonItem>

                                <IonCardContent>
                                    <dl>
                                        <dt><b>{TEXT.name}:</b></dt>
                                        <dd>{dataItems.shipping.name}</dd>
                                        <dt><b>{TEXT.address}:</b></dt>
                                        <dd>{dataItems.shipping.address}</dd>
                                        <dt><b>{TEXT.subDistrict}:</b></dt>
                                        <dd>{dataItems.shipping.subDistrict}</dd>
                                        <dt><b>{TEXT.city}:</b></dt>
                                        <dd>{dataItems.shipping.city}</dd>
                                        <dt><b>{TEXT.province}:</b></dt>
                                        <dd>{dataItems.shipping.province}</dd>
                                        <dt><b>{TEXT.postcode}:</b></dt>
                                        <dd>{dataItems.shipping.postcode}</dd>
                                        <dt><b>{TEXT.phone}:</b></dt>
                                        <dd>{dataItems.shipping.phone}</dd>
                                        <dt><b>{TEXT.pin}:</b></dt>
                                        <dd>{dataItems.shipping.pin}</dd>
                                    </dl>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>

                        <IonCol size='12' sizeMd="6">
                            <IonCard>
                                <IonItem>
                                    <IonLabel>{TEXT.billingAddress}</IonLabel>
                                </IonItem>

                                <IonCardContent>
                                    <dl>
                                        <dt><b>{TEXT.name}:</b></dt>
                                        <dd>{dataItems.billing.name}</dd>
                                        <dt><b>{TEXT.address}:</b></dt>
                                        <dd>{dataItems.billing.address}</dd>
                                        <dt><b>{TEXT.subDistrict}:</b></dt>
                                        <dd>{dataItems.billing.subDistrict}</dd>
                                        <dt><b>{TEXT.city}:</b></dt>
                                        <dd>{dataItems.billing.city}</dd>
                                        <dt><b>{TEXT.province}:</b></dt>
                                        <dd>{dataItems.billing.province}</dd>
                                        <dt><b>{TEXT.postcode}:</b></dt>
                                        <dd>{dataItems.billing.postcode}</dd>
                                        <dt><b>{TEXT.phone}:</b></dt>
                                        <dd>{dataItems.billing.phone}</dd>
                                        <dt><b>{TEXT.taxID}:</b></dt>
                                        <dd>{dataItems.billing.taxID}</dd>
                                    </dl>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <div className="ion-margin">
                        <DataTable
                            columns={columns}
                            customStyles={tableStylesE5}
                            data={dataItems.products}
                            pagination
                        />
                    </div>

                    <IonRow className="ion-margin-horizontal">
                        <IonCol size='12' sizeMd="6">
                            <IonRow>
                                <dl className="medium">
                                    <dt>{TEXT.sumQty}:</dt>
                                    <dd><NumberFormat value={dataItems.sumQty} displayType={'text'} thousandSeparator={true} /></dd>

                                    <dt>{TEXT.paymentMethod}:</dt>
                                    <dd>{dataItems.paymentMethod}</dd>

                                    <dt>{TEXT.cod}:</dt>
                                    <dd><NumberFormat value={dataItems.cod} displayType={'text'} thousandSeparator={true} /></dd>

                                    <dt>{TEXT.shippingMethod}:</dt>
                                    <dd>{dataItems.shippingMethod}</dd>

                                    <dt>{TEXT.courier}:</dt>
                                    <dd>{dataItems.courier}</dd>
                                </dl>
                            </IonRow>
                        </IonCol>

                        <IonCol size='12' sizeMd="6">
                            <IonRow>
                                <dl className="medium">
                                    <dt>{TEXT.productSubtotal}:</dt>
                                    <dd><NumberFormat value={dataItems.subtotal} displayType={'text'} thousandSeparator={true} /></dd>

                                    <dt>{TEXT.productDiscount}:</dt>
                                    <dd><NumberFormat value={dataItems.discount} displayType={'text'} thousandSeparator={true} /></dd>

                                    <dt><b>{TEXT.productTotal}:</b></dt>
                                    <dd><NumberFormat value={dataItems.total} displayType={'text'} thousandSeparator={true} /></dd>

                                    <dt>{TEXT.shippingTotal}:</dt>
                                    <dd><NumberFormat value={dataItems.shippingTotal} displayType={'text'} thousandSeparator={true} /></dd>

                                    <dt><b>{TEXT.total}:</b></dt>
                                    <dd><NumberFormat value={dataItems.totalFinal} displayType={'text'} thousandSeparator={true} /></dd>
                                </dl>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size='12'>
                            <IonCard>
                                <IonItem>
                                    <IonLabel>{TEXT.note}</IonLabel>
                                </IonItem>
                                <IonCardContent>
                                    {dataItems.note}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    {/* Package */}
                    {dataPackage.map((data, index) => {
                        return (
                            <IonRow>
                                <IonCol size='12'>
                                    <IonCard>
                                        <IonItem>
                                            <IonLabel>{TEXT.package} {index + 1} / {dataPackage.length}</IonLabel>
                                        </IonItem>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol size="12" sizeMd="6">
                                                    <dl className="medium">
                                                        <dt>{TEXT.trackingNo}</dt>
                                                        <dd>{data.trackingNo}</dd>

                                                        <dt>{TEXT.packSize}</dt>
                                                        <dd>W = {data.width}, D = {data.depth}, H = {data.height}</dd>

                                                        <dt>{TEXT.packWeight}</dt>
                                                        <dd><NumberFormat value={data.weight} displayType={'text'} thousandSeparator={true} /></dd>
                                                    </dl>
                                                </IonCol>

                                                <IonCol size="12" sizeMd="6">
                                                    <dl className="medium">
                                                        <dt>{TEXT.packageLPNo}</dt>
                                                        <dd>{data.LPNo}</dd>

                                                        <dt>{TEXT.packItemQty}</dt>
                                                        <dd><NumberFormat value={data.qty} displayType={'text'} thousandSeparator={true} /></dd>

                                                        <dt>{TEXT.packCOD}</dt>
                                                        <dd><NumberFormat value={data.cod} displayType={'text'} thousandSeparator={true} /></dd>
                                                    </dl>
                                                </IonCol>
                                            </IonRow>

                                            <div className="ion-margin-top">
                                                <DataTable
                                                    columns={columnsProduct}
                                                    customStyles={tableStylesE5}
                                                    data={data.products}
                                                    pagination
                                                />
                                            </div>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        )
                    })}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ProductHistoryView;