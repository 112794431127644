import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, IonSlide, IonSlides, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { IonRow } from '@ionic/react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import TEXT from '../constances/Text.json';
import MENU from '../constances/Menu.json';
import BUTTON from '../constances/Button.json';

import { RoutePath } from '../components/RoutePath';

import { slideOptsImage } from '../components/Options';
import { productASNAdd } from '../components/InterfaceData';
import { closeCircle, saveOutline } from 'ionicons/icons';

const SendParcelAddCheck: React.FC = () => {

  const history = useHistory();

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  const productData: productASNAdd[] = [
    {
      id: 1,
      brand: 'Test',
      subBrand: 'Sub Test',
      nameTH: 'สินค้าทดสอบตัวอย่างที่ ' + 1,
      nameEN: 'Product Test Example ' + 1,
      category: 'เสื้อผ้า',
      sku: 'SKU000001',
      images: [
        {
          id: 1,
          url: 'https://picsum.photos/400',
        },
        {
          id: 2,
          url: 'https://picsum.photos/400',
        },
        {
          id: 3,
          url: 'https://picsum.photos/400',
        },
        {
          id: 4,
          url: 'https://picsum.photos/400',
        },
        {
          id: 5,
          url: 'https://picsum.photos/400',
        },
      ],
      attribute: [
        {
          id: 0,
          key: 'สี',
          value: 'ขาว',
        },
        {
          id: 1,
          key: 'ขนาด',
          value: 'XL',
        },
        {
          id: 2,
          key: 'ลาย',
          value: 'ต้นมะพร้าวกลางทะเลขอบฟ้าแดง',
        },
      ],
    }
  ];

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (value: any) => {
    history.replace(RoutePath.sendParcel);
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" className='ion-hide-lg-up'>
            <IonBackButton defaultHref={RoutePath.sendParcelAdd} text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.sendParcelAdd}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleSubmit(onSubmit)}>
              <IonIcon slot="start" icon={saveOutline} />
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form>
          <IonGrid>
            <IonRow className='ion-margin-horizontal'>
              <IonCol size='12' sizeMd='6'>
                <IonText color="dark">
                  <p>{TEXT.dateNotified}: {date}</p>
                </IonText>
              </IonCol>

              <IonCol size='12' sizeMd='6'>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.orderNumber}<span className='form-required'>*</span></IonLabel>
                  <IonInput type='text' {...register("orderNo")} value="SGO-001" readonly required></IonInput>
                </IonItem>
              </IonCol>

              <IonCol size='12' sizeMd='6'>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.trackingNo}<span className='form-required'>*</span></IonLabel>
                  <IonInput type='text' {...register("tracking")} required></IonInput>
                </IonItem>
              </IonCol>

              <IonCol size='12' sizeMd='6'>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.boxParcel}<span className='form-required'>*</span></IonLabel>
                  <IonInput type='text' {...register("box")} required></IonInput>
                </IonItem>
              </IonCol>

              <IonCol size='12' sizeMd='6'>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.weightParcel}<span className='form-required'>*</span></IonLabel>
                  <IonInput type='text' {...register("weight")} required></IonInput>
                </IonItem>
              </IonCol>

              <IonCol size='12' sizeMd='6'>
                <IonItem>
                  <IonLabel position="stacked">{TEXT.courier}<span className='form-required'>*</span></IonLabel>
                  <IonSelect 
                  interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel} 
                  placeholder={TEXT.courier} value="flashexpress">
                    <IonSelectOption value="flashexpress">Flash Express</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='12'>
                <IonCard>
                  <IonItem>
                    <IonLabel>ITEM 1</IonLabel>
                  </IonItem>

                  {productData.filter(productData => productData.sku === "SKU000001").map(filterData => (
                    <div key={filterData.id}>
                      <IonRow className='product-info ion-margin'>
                        {/* IMAGE */}
                        <IonCol size='12' sizeMd='6' sizeXl='3'>
                          <IonSlides options={slideOptsImage}>
                            {filterData.images.map((data, index) => {
                              return (
                                <IonSlide key={index}>
                                  <img src={data.url} width="100%" height="auto"></img>
                                </IonSlide>
                              )
                            })}
                          </IonSlides>
                        </IonCol>

                        <IonCol size='12' sizeMd='6' sizeXl='9'>
                          <div className='ion-margin'>
                            <IonText color="medium">
                              <p>{TEXT.productId} - {filterData.id}</p>
                              <p>{TEXT.productSku}: {filterData.sku}</p>
                              <p>{filterData.brand} / {filterData.subBrand}</p>
                            </IonText>

                            <h3>{filterData.nameTH}</h3>
                            <h4>{filterData.nameEN}</h4>

                            <IonText color="medium">
                              <p>{TEXT.category}: {filterData.category}</p>
                              <p>{TEXT.productAttribute}:<br></br>
                                {filterData.attribute.map((data, index) => {
                                  return (
                                    <span key={index}>
                                      -&nbsp;{data.key}:&nbsp;{data.value}<br></br>
                                    </span>
                                  )
                                })}
                              </p>
                            </IonText>
                          </div>
                        </IonCol>
                      </IonRow>
                    </div>
                  ))}

                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol size='12' sizeMd='6'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.productSku}</IonLabel>
                            <IonInput type='text' {...register("sku")} readonly required></IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol size='12' sizeMd='6'>
                          <IonItem>
                            <IonLabel position="stacked">{TEXT.qty}</IonLabel>
                            <IonInput type='number' min={1} {...register("qty")} readonly required></IonInput>
                          </IonItem>
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol size='12'>
                          <IonText color='danger' className='ion-float-right'>
                            <IonLabel><b>{TEXT.warningAddCheckData}</b></IonLabel>
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default SendParcelAddCheck;