import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import Barcode from "react-hooks-barcode";

import {
  IonContent,
  IonGrid,
  IonCol,
  IonLabel,
  IonRow,
  IonButton,
  IonIcon,
  IonBadge,
  IonButtons,
  IonToolbar,
  IonLoading,
  useIonModal,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonThumbnail,
  IonImg,
  IonAccordionGroup,
  IonAccordion,
  IonChip,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonList,
  IonPopover,
  IonRadioGroup,
  IonRadio,
  IonInput,
  IonCardContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonModal,
  useIonAlert,
  SearchbarCustomEvent,
  IonAlert
} from "@ionic/react";
import {
  cloudUploadOutline,
  copyOutline,
  cubeOutline,
  downloadOutline
} from "ionicons/icons";

import moment from 'moment';
import XLSX from 'sheetjs-style';

import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MESSAGE from '../constances/Messages.json';
import MENU from '../constances/Menu.json';
import CONTACT from '../constances/Contact.json';

import { Direction, RoutePath } from "./RoutePath";
import { pageTable, pageTableCompact, pageTableExpandable } from "./TableStyle";
import { AlertPrompt } from "./alerts/AlertPrompt";
import { AlertStatus } from "./alerts/AlertStatus";
import { ToastStatus } from "./toasts/ToastStatus";
import { ModalWithdrawImport } from "./modal/WithdrawImport";
import { ModalScanner } from "./modal/Scanner";
import { ModalBarcode } from "./modal/Barcode";

import { ModalWithdrawTakeAwayImport } from "./modal/WithdrawTakeAwayImport";
import { ModalProductPreview } from "./modal/ProductPreview";
import { ModalLocation } from "./modal/Location";
import { ModalCounted } from "./modal/Counted";

import UserService from "../services/UserService";
import WithdrawService from "../services/WithdrawService";
import WithdrawProductService from "../services/WithdrawProductService";
import WithdrawProductTakeawayService from "../services/WithdrawProductTakeawayService";
import ProductWarehouseService from "../services/ProductWarehouseService";


const Withdraw: React.FC<{
  id?: any;
  doChangeStatus?: any;
  doCheck?: any;
  displayInitialLoading?: boolean;
  onStatusChange?: (data?: any) => any;
  onCancelStatusChange?: (data?: any) => any;
  onDidLoad?: (data?: boolean) => any;
  onDidScannerReady?: (data?: boolean) => any;
  onLoad?: (data?: boolean) => any;
  reload?: any;
  className?: string;
  scannerIsOpen?: boolean;
  barcodeIsOpen?: boolean;
}> = ({
  id = null,
  doChangeStatus = null,
  doCheck = null,
  displayInitialLoading = true,
  onStatusChange,
  onCancelStatusChange,
  onDidLoad,
  onDidScannerReady,
  onLoad,
  reload = null,
  className = '',
  scannerIsOpen = false,
  barcodeIsOpen = false
}) => {

    const offset: number = 50;
    const defaultSort: any = {
      by: 'left',
      direction: 'desc'
    }
    const columnsReport: any = {
      'SKU': true,
      'BARCODE': true,
      'NAME': true,
      'QUANTITY': true,
      'COUNT': true,
      'LACK': true,
      'OVER': true,
      'EXPIRE': true,
      'LOT': true,
      'STATUS': true
    }
    const columnsReceipt: any = {
      'SKU': true,
      'BARCODE': true,
      'NAME': true,
      'STOCK': true,
      'ขอคืน': true,
      'STOCK คงเหลือ': true
    }

    const colors: any = COLORS.withdraw;
    const statuses: any = STATUS.withdraw;
    const colorsProduct: any = COLORS.withdrawProduct;
    const statusesProduct: any = STATUS.withdrawProduct;

    const [members, setMembers] = useState<Array<any>>([]);
    const [withdraw, setWithdraw] = useState<any>(null);
    const [products, setProducts] = useState<any>([]);
    const [productsAll, setProductsAll] = useState<any>([]);
    const [productsChecks, setProductsChecks] = useState<any>([]);
    const [status, setStatus] = useState<any>(null);
    const [withdrawLocation, setWithdrawLocation] = useState<any>(true);
    const [warehouse, setWarehouse] = useState<any>(null);

    const [ready, setReady] = useState(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showProgressLoading, setShowProgressLoading] = useState(false);
    const [importResult, setImportResult] = useState<any>(null);
    const [focusBarcode, setFocusBarcode] = useState<boolean>(false);
    const [downloadPrompt, setDownloadPrompt] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [sort, setSort] = useState<any>(defaultSort);
    const [total, setTotal] = useState<number>(0);
    const [totalAll, setTotalAll] = useState<number>(0);
    const [totalCounted, setTotalCounted] = useState<number>(0);
    const [totalLack, setTotalLack] = useState<number>(0);
    const [totalOver, setTotalOver] = useState<number>(0);
    const [totalPending, setTotalPending] = useState<number>(0);
    const [totalComplete, setTotalComplete] = useState<number>(0);

    const [modalScannerIsOpen, setModalScannerIsOpen] = useState<boolean>(false);
    const [modalBarcodeIsOpen, setModalBarcodeIsOpen] = useState<boolean>(false);
    const [modalLocationData, setModalLocationData] = useState<any>(null);
    const [modalCountedData, setModalCountedData] = useState<any>(null);
    const [scannerReady, setScannerReady] = useState<boolean>(false);

    const [showAlertCounted, setShowAlertCounted] = useState(false);

    const [modalProductPreviewIsOpen, setModalProductPreviewIsOpen] = useState<boolean>(false);
    const [idProductPreview, setIdProductPreview] = useState<any>(null);

    const openProductPreviewModal = (id: any) => {
      if (id) {
        setIdProductPreview(id);
        setModalProductPreviewIsOpen(true);
      }
    };

    const { register, getValues, setValue } = useForm();
    const [withdrawStatus] = useState<any>(
      register('withdrawStatus', {
        value: '',
        onChange: (e: any) => {
          changeStatus(e.withdraw, e.detail.value, e.ready);
        }
      })
    );
    const [withdrawDate] = useState<any>(
      register('withdrawDate', {
        value: ''
      })
    );
    const [withdrawExpectDate] = useState<any>(
      register('withdrawExpectDate', {
        value: ''
      })
    );
    const [filterSearch] = useState<any>(
      register('search', {
        value: ''
      })
    );
    const [filterStatus] = useState<any>(
      register('status', {
        value: '',
        onChange: (e: any) => {
          loadProducts(e.withdrawID, 0, pageLimit, sort.by, sort.direction, getValues('search'), e.detail.value);
        }
      })
    );

    const [presentToast, setPresentToast] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlert, setPresentAlert] = useState<{
      isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, status: false, message: '', onDismiss: () => { }
    });
    const [presentAlertPrompt, setPresentAlertPrompt] = useState<{
      isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
    }>({
      isPresent: false, message: '', onDismiss: () => { }
    });
    const [presentAlertCounted, dismissAlertCounted] = useIonAlert();

    const columnsProducts: Array<any> = [
      {
        id: 'barcode',
        name: TEXT.barcode,
        selector: (row: { barcode: any }) => row.barcode,
        cell: (row: { product_reference: any }) => (
          <div className='barcode ion-text-center'>
            <Barcode value={row.product_reference ? row.product_reference.barcode : ''} />
          </div>
        ),
        width: '150px',
        center: true,
        sortable: true,
      },
      {
        id: 'product.sku',
        name: TEXT.productHeaderNameSKU,
        selector: (row: {
          product: any, product_reference: any
        }) => row.product_reference ? row.product_reference.sku : row.product,
        cell: (row: { product: any, product_reference: any }) => (
          // <IonItem lines="none" routerLink={
          //   row.product_reference ? RoutePath.productView.replace(':id', row.product_reference.id)
          //     : null
          // } routerDirection={Direction()}>
          <IonItem lines="none" className="pointer" onClick={() => { openProductPreviewModal(row.product_reference.id) }}>
            {
              row.product_reference && row.product_reference.figure_reference && row.product_reference.figure_reference.length ?
                <IonThumbnail slot="end">
                  <IonImg src={
                    row.product_reference.figure_reference[0].thumbnail
                      ? row.product_reference.figure_reference[0].thumbnail
                      : row.product_reference.figure_reference[0].original
                  } />
                </IonThumbnail>
                : <></>
            }
            <IonLabel>
              <h2 className='text-full'>
                {row.product_reference ? row.product_reference.name : `${TEXT.productNotFound} (${row.product})`}
              </h2>
              {
                row.product_reference ?
                  <p className='text-full subtitle'>{row.product_reference.sku}</p>
                  : <></>
              }
            </IonLabel>
          </IonItem>
        ),
        minWidth: '250px',
        sortable: true
      },
      {
        id: 'withdraw_product.quantity',
        name: TEXT.qtyPiece,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { id: any, quantity: any }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true}
                  className="text-number" />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true}
              className="text-number" />
        ),
        right: true,
        sortable: true,
        minWidth: '100px',
      },
      {
        id: 'withdraw_product_takeaway.quantity',
        name: TEXT.qtyCount,
        selector: (row: { counted: any }) => row.counted,
        cell: (row: { id: any, quantity: number, counted: number }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.counted} displayType={'text'} thousandSeparator={true}
                  className={(row.quantity - row.counted > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.counted} displayType={'text'} thousandSeparator={true}
              className={(row.quantity - row.counted > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
        ),
        right: true,
        sortable: true,
        minWidth: '100px',
      },
      {
        id: 'left',
        name: TEXT.qtyLack,
        selector: (row: { left: any }) => (row.left),
        cell: (row: { id: any, left: any }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.left} displayType={'text'} thousandSeparator={true}
                  className={(row.left > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.left} displayType={'text'} thousandSeparator={true}
              className={(row.left > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
        ),
        right: true,
        sortable: true,
        minWidth: '100px',
      },
      {
        id: 'over',
        name: TEXT.qtyOver,
        selector: (row: { over: any }) => (row.over),
        cell: (row: { id: any, over: any }) => (
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          ) ?
            <IonItem lines="none" className="ion-text-right item-no-padding pointer" onClick={() => { setModalCountedData(row.id) }}
              disabled={status === 'complete' || status === 'cancel' ? true : false}>
              <IonLabel className="ion-text-right">
                <NumberFormat value={row.over} displayType={'text'} thousandSeparator={true}
                  className={(row.over > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
              </IonLabel>
            </IonItem>
            :
            <NumberFormat value={row.over} displayType={'text'} thousandSeparator={true}
              className={(row.over > 0 ? 'text-danger' : 'text-success') + ' text-number'} />
        ),
        right: true,
        sortable: true,
        minWidth: '100px',
      },
      {
        id: 'withdraw_product.expire_date',
        name: TEXT.expireDate,
        selector: (row: { expire_date: any }) => row.expire_date,
        cell: (row: { expire_date: any }) =>
        (
          row.expire_date
            ? moment.utc(row.expire_date).local().format('LL')
            : '-'
        ),
        center: true,
        sortable: true,
        minWidth: '150px',
      },
      {
        id: 'withdraw_product.lot_number',
        name: TEXT.lotNumber,
        selector: (row: { lot_number: any }) => row.lot_number,
        cell: (row: { lot_number: any }) => row.lot_number ? row.lot_number : '-',
        center: true,
        sortable: true,
        minWidth: '150px',
      },
      {
        id: 'withdraw_product.status',
        name: TEXT.status,
        selector: (row: { status: any }) => row.status,
        cell: (row: { id: any, status: any, counted: any, quantity: any }) =>
        (
          <>
            <IonButtons className="w-100">
              <IonButton className="w-100" id={`status-${row.id}`} fill='solid' color={colorsProduct[row.status]}
                disabled={status === 'complete' || status === 'cancel' ? true : false}
                onClick={
                  () => {
                    {
                      !members.length || (
                        !members.includes('1')
                        && !members.includes('2')
                        && !members.includes('3')
                      )
                        && setValue('status', row.status)
                    }
                  }
                }>
                <IonLabel>{statusesProduct[row.status]}</IonLabel>
              </IonButton>
            </IonButtons>
            {
              members.length && (
                members.includes('1')
                || members.includes('2')
                || members.includes('3')
              )
                ?
                <IonPopover trigger={`status-${row.id}`} dismissOnSelect={true} side="end">
                  <IonContent>
                    <IonList>
                      <IonRadioGroup value={row.status}>
                        {
                          Object.keys(statusesProduct).map((key: any, index: number) => (
                            key !== 'counted' ?
                              <IonItem key={`status-option-${index}`} lines="none" button={true} detail={false}
                                onClick={() => changeProductStatus(row.id, key)}
                              >
                                <IonLabel>{statusesProduct[key]}</IonLabel>
                                <IonRadio slot="start" value={key} />
                              </IonItem>
                              :
                              <IonItem key={`status-option-${index}`} lines="none" button={true} detail={false}
                                onClick={() => setModalCountedData(row.id)}
                              >
                                <IonLabel>{statusesProduct[key]}</IonLabel>
                                <IonRadio slot="start" value={key} />
                              </IonItem>
                          ))
                        }
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonPopover>
                : <></>
            }
          </>
        ),
        minWidth: '150px',
        center: true,
        sortable: true
      }
    ];

    const columnsSuggestions: Array<any> = [
      {
        name: TEXT.location,
        selector: (row: { reference: any }) => (
          row.reference ?
            row.reference.warehouse + '.'
            + row.reference.floor + '.'
            + row.reference.aisle + '.'
            + row.reference.bay + '.'
            + row.reference.level + '.'
            + row.reference.bin
            : ''
        ),
        cell: (row: { reference: any }) => (
          row.reference ?
            <CopyToClipboard text={
              row.reference.warehouse + '.'
              + row.reference.floor + '.'
              + row.reference.aisle + '.'
              + row.reference.bay + '.'
              + row.reference.level + '.'
              + row.reference.bin
            } onCopy={() => {
              setPresentToast({
                isPresent: true,
                status: true,
                message: `${row.reference.warehouse + '.'
                  + row.reference.floor + '.'
                  + row.reference.aisle + '.'
                  + row.reference.bay + '.'
                  + row.reference.level + '.'
                  + row.reference.bin
                  } ${TEXT.wasCopied}`,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            }}>
              <IonButton fill='clear'>
                {
                  row.reference.warehouse + '.'
                  + row.reference.floor + '.'
                  + row.reference.aisle + '.'
                  + row.reference.bay + '.'
                  + row.reference.level + '.'
                  + row.reference.bin
                }
                <IonIcon size="small" slot="end" icon={copyOutline} />
              </IonButton>
            </CopyToClipboard>
            : '-'
        ),
        sortable: true,
        minWidth: '150px',
      },
      {
        name: TEXT.qtyPiece,
        selector: (row: { quantity: any }) => row.quantity,
        cell: (row: { quantity: any }) => (
          <NumberFormat value={row.quantity} displayType={'text'} thousandSeparator={true} className="text-number" />
        ),
        right: true,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: TEXT.lot,
        selector: (row: { lot_number: any }) => row.lot_number,
        cell: (row: { lot_number: any }) => (
          row.lot_number ?
            <CopyToClipboard text={row.lot_number} onCopy={() => {
              setPresentToast({
                isPresent: true,
                status: true,
                message: `${row.lot_number} ${TEXT.wasCopied}`,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            }}>
              <IonButton fill='clear'>
                {row.lot_number}
                <IonIcon size="small" slot="end" icon={copyOutline} />
              </IonButton>
            </CopyToClipboard>
            : '-'
        ),
        center: true,
        sortable: true,
        minWidth: '150px',
      },
      {
        name: TEXT.manufacturerDate,
        selector: (row: { manufactured_date: any }) => row.manufactured_date,
        cell: (row: { manufactured_date: any }) => (
          row.manufactured_date ?
            <IonItem lines="none" detail={false}>
              <IonLabel class="ion-text-wrap" className='text-full ion-text-center'>
                {moment.utc(row.manufactured_date).local().format('LL')}
              </IonLabel>
            </IonItem>
            : '-'
        ),
        center: true,
        sortable: true,
        minWidth: '150px',
      },
      {
        name: TEXT.expireDate,
        selector: (row: { expire_date: any }) => row.expire_date,
        cell: (row: { expire_date: any }) => (
          row.expire_date ?
            <IonItem lines="none" detail={false}>
              <IonLabel class="ion-text-wrap" className='text-full ion-text-center'>
                {moment.utc(row.expire_date).local().format('LL')}
              </IonLabel>
            </IonItem>
            : '-'
        ),
        center: true,
        sortable: true,
        minWidth: '150px',
      }
    ];

    const paginationComponentOptions = {
      rowsPerPageText: TEXT.rowsPerPage,
      rangeSeparatorText: TEXT.rangeSeparator,
      selectAllRowsItemText: TEXT.selectAllRowsItem,
    };

    const initialize = async () => {
      setInitialized(false);
      if (displayInitialLoading) {
        setShowLoading(true);
      }
      setImportResult(null);
      setWithdraw(null);
      setProducts([]);
      setProductsChecks([]);
      setTotal(0);
      setTotalAll(0);
      setTotalCounted(0);
      setTotalLack(0);
      setTotalOver(0);
      setTotalPending(0);
      setTotalComplete(0);
      const userData: any = await UserService.getSession();
      if (userData) {
        setMembers(
          userData.members.map(
            ({ group_id }: any) => (
              group_id
            )
          )
        );
        await load(id);
      }
      loadProductsAll(id);
      setInitialized(true);
      onDidLoad && onDidLoad(true);
      setShowLoading(false);
    }

    const load = async (id: string | null | undefined = null, force: boolean = true) => {
      if (id) {
        await WithdrawService.get(id, null, true).then(async (data: any) => {
          setWithdraw(data);
          setStatus(data.status);
          setValue('withdrawStatus', data.status);
          setValue('withdrawDate', data.withdraw_date ? moment.utc(data.withdraw_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
          setValue('withdrawExpectDate', data.expect_date ? moment.utc(data.expect_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        await loadProducts(id, 0, pageLimit, defaultSort.by, defaultSort.direction, getValues('search'), getValues('status'));
      }
    }

    const loadProducts = async (
      id: string,
      start: number = 0,
      limit: number | null = pageLimit,
      sortBy: string = defaultSort.by,
      sortDirection: string = defaultSort.direction,
      search: string = getValues('search'),
      status: string = getValues('status')
    ) => {
      if (id) {
        onLoad && onLoad(true);
        setShowProgressLoading(true);
        const count = (countStatus: any = '') => {
          return new Promise(async (resolve) => {
            await WithdrawProductService.count(
              id,
              null,
              null,
              null,
              search,
              countStatus
            ).then(async (rows: any) => {
              resolve(rows);
            });
          });
        }
        await count(status).then(async (rows: any) => {
          setTotal(rows);
          if (rows < offset) {
            start = 0;
            limit = null;
            sortBy = defaultSort.by;
            sortDirection = defaultSort.direction;
          }
          await WithdrawProductService.list(
            id,
            null,
            { by: sortBy, direction: sortDirection },
            start,
            limit,
            search,
            status,
            ['product', 'suggestion']
          ).then((data: any) => {
            setProducts(data);
          }).catch((error) => {
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
        });
        onLoad && onLoad(false);
        setShowProgressLoading(false);
        count().then(async (rows: any) => {
          setTotalAll(rows);
        });
        count('counted').then(async (rows: any) => {
          setTotalCounted(rows);
        });
        count('left').then(async (rows: any) => {
          setTotalLack(rows);
        });
        count('over').then(async (rows: any) => {
          setTotalOver(rows);
        });
        count('pending').then(async (rows: any) => {
          setTotalPending(rows);
        });
        count('complete').then(async (rows: any) => {
          setTotalComplete(rows);
        });
      }
    }

    const loadProductsAll = async (id: string) => {
      if (id) {
        await WithdrawProductService.list(
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          ['product']
        ).then(async (data: any) => {
          setProductsAll(data);
          const dataChecks = JSON.parse(JSON.stringify(data));
          const productsChecksMap = await Promise.all(
            dataChecks.map((item: any) => {
              item.quantity = item.counted;
              return item;
            })
          );
          setProductsChecks(productsChecksMap);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        onDidScannerReady && onDidScannerReady(true);
        setScannerReady(true);
      }
    }

    const changeStatus = (withdraw: any, value: any, ready: any) => {
      if (withdraw && withdraw.status !== value) {

        const success = async () => {
          onStatusChange && onStatusChange(value);
          setPresentToast({
            isPresent: true,
            status: true,
            message: MESSAGE.success.complete,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        }

        const patch = async (id: any, status: any) => {
          setShowLoading(true);
          await WithdrawService.changeStatus(id, status).then(async () => {
            success();
            await load(id);
          }).catch((error) => {
            setValue('withdrawStatus', withdraw.status);
            setPresentToast({
              isPresent: true,
              status: false,
              message: error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          });
          setShowLoading(false);
        }

        if (value === 'complete' || value === 'cancel') {
          setPresentAlertPrompt({
            isPresent: true,
            message: value === 'complete' ? MESSAGE.info.withdrawChangeStatusComplete : MESSAGE.info.withdrawChangeStatusCancel,
            onDismiss: (data: boolean) => {
              onCancelStatusChange && onCancelStatusChange(true);
              if (ready) {
                setPresentAlertPrompt(
                  {
                    isPresent: false,
                    message: presentAlertPrompt.message,
                    onDismiss: presentAlertPrompt.onDismiss
                  }
                );
                if (data) {
                  patch(withdraw.id, value);
                } else {
                  setValue('withdrawStatus', withdraw.status);
                }
              }
            }
          });
        } else {
          patch(withdraw.id, value);
        }

      }
    }

    const changeProductStatus = (withdrawProductID: any, value: any, counted: any = 0, maxCounted: any = null) => {

      const success = async (withdrawID: any) => {
        if (withdrawID) {
          await load(withdrawID);
        }
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (withdrawProductID: any, status: any, counted: any = null) => {
        setShowLoading(true);
        await WithdrawProductService.changeStatus(withdrawProductID, status, counted).then(async () => {
          success(withdraw ? withdraw.id : null);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
        setShowLoading(false);
      }

      if (value === 'complete' || value === 'cancel') {
        setPresentAlertPrompt({
          isPresent: true,
          message: value === 'complete' ? MESSAGE.info.withdrawProductChangeStatusComplete : MESSAGE.info.withdrawChangeStatusCancel,
          onDismiss: (data: boolean) => {
            if (ready) {
              setPresentAlertPrompt(
                {
                  isPresent: false,
                  message: presentAlertPrompt.message,
                  onDismiss: presentAlertPrompt.onDismiss
                }
              );
              if (data) {
                patch(withdrawProductID, value);
              }
            }
          }
        });
      } else if (value === 'counted') {
        presentAlertCounted({
          header: statusesProduct['counted'],
          message: MESSAGE.info.withdrawChangeStatusCounted + (
            maxCounted ? ` (${MESSAGE.info.withdrawChangeStatusCountedQuantity
              } ${maxCounted
              })` : ''
          ),
          inputs: [
            maxCounted !== null ?
              {
                type: 'number',
                placeholder: TEXT.counted,
                value: parseInt(counted),
                min: 1,
                max: parseInt(maxCounted)
              } :
              {
                type: 'number',
                placeholder: TEXT.counted,
                min: 1,
                value: parseInt(counted)
              }
          ],
          buttons: [
            {
              text: BUTTON.cancel,
              role: 'cancel',
              handler: () => {
                dismissAlertCounted();
              }
            },
            {
              text: BUTTON.ok,
              handler: (data: any) => {
                patch(withdrawProductID, value, data[0]);
                dismissAlertCounted();
              }
            }
          ],
        });
      } else {
        patch(withdrawProductID, value);
      }

    }

    const changeWithdrawDate = (withdraw: any, value: any, ready: any) => {

      const success = async () => {
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (id: any, date: any) => {
        await WithdrawService.patch(id, { 'withdraw_date': date }).then(async () => {
          success();
        }).catch((error) => {
          setValue('withdrawDate', withdraw.withdraw_date ? moment.utc(withdraw.withdraw_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      }

      patch(withdraw.id, value ? value.replace('T', ' ') : null);

    }

    const changeExpectDate = (withdraw: any, value: any, ready: any) => {

      const success = async () => {
        setPresentToast({
          isPresent: true,
          status: true,
          message: MESSAGE.success.complete,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }

      const patch = async (id: any, date: any) => {
        await WithdrawService.patch(id, { 'expect_date': date }).then(async () => {
          success();
        }).catch((error) => {
          setValue('withdrawExpectDate', withdraw.expect_date ? moment.utc(withdraw.expect_date).local().format('YYYY-MM-DDTHH:mm:ss') : null);
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      }

      patch(withdraw.id, value ? value.replace('T', ' ') : null);

    }

    const updateProductsChecks = async (productUpdate: any, locationUpdate: any = null) => {
      if (withdraw) {
        const productsAllData = JSON.parse(JSON.stringify(productsAll));
        const productsChecksData = JSON.parse(JSON.stringify(productsChecks));
        for (let i = 0; i < productsChecksData.length; i++) {
          if (productsChecksData[i].product === productUpdate.product) {
            for (let j = 0; j < productsAllData.length; j++) {
              if (productsAllData[j].product === productUpdate.product) {
                if (parseInt(productsAllData[j].quantity) > parseInt(productsChecksData[i].quantity)) {

                  productsChecksData[i].quantity = parseInt(productsChecksData[i].quantity) + 1;
                
                  await WithdrawService.takeaway(
                    withdraw.id,
                    productsChecksData[i].product,
                    null,
                    null,
                    1,
                    locationUpdate
                  ).then(async () => {
                    setProductsChecks(productsChecksData);
                    setPresentToast({
                      isPresent: true,
                      status: true,
                      message: MESSAGE.success.product_in_list,
                      onDismiss: () => {
                        if (ready) {
                          setPresentToast(
                            {
                              isPresent: false,
                              status: presentToast.status,
                              message: presentToast.message,
                              onDismiss: presentToast.onDismiss
                            }
                          );
                        }
                      }
                    });
                  }).catch((error) => {
                    setPresentToast({
                      isPresent: true,
                      status: false,
                      message: error,
                      onDismiss: () => {
                        if (ready) {
                          setPresentToast(
                            {
                              isPresent: false,
                              status: presentToast.status,
                              message: presentToast.message,
                              onDismiss: presentToast.onDismiss
                            }
                          );
                        }
                      }
                    });
                  });
  
                  break;

                }
                break;
              }
            }
            break;
          }
        }
      }
    }

    const downloadReport = async () => {

      setShowLoading(true);

      const status = getValues('status');
      let statusText: string = '';
      if (status === 'empty') {
        statusText = TEXT.asnProductNotFound;
      } else if (status === 'lack') {
        statusText = TEXT.lack;
      } else if (status === 'over') {
        statusText = TEXT.over;
      }

      const searchText = getValues('search');

      const csv = async (content: any) => {

        let rows: any = [];

        await rows.push(
          await Object.keys(columnsReport).filter((item: any) => {
            return columnsReport[item] === true;
          })
        );

        for (let item of content) {

          let values = [
            (item.product_reference && item.product_reference.sku ? item.product_reference.sku : ''),
            (item.product_reference && item.product_reference.barcode ? item.product_reference.barcode : ''),
            (item.product_reference && item.product_reference.name ? item.product_reference.name : ''),
            (item.quantity ? item.quantity : ''),
            (item.counted ? item.counted : ''),
            (item.left ? item.left : ''),
            (item.over ? item.over : ''),
            (item.expire_date ? item.expire_date : ''),
            (item.lot_number ? item.lot_number : ''),
            (item.status && item.active !== false ? statusesProduct[item.status] : item.active === false ? TEXT.withdrawProductNotFound : '')
          ];

          await rows.push(
            await values.filter((item: any, index: number) => {
              return Object.values(columnsReport)[index] === true;
            }).map((item: any) => {
              return '"' + item.toString().replace(/"/g, '""') + '"';
            })
          );

        };

        let header = 'data:text/csv; charset=utf-8,';
        let downloadContent = '';
        for (let rowArray of rows) {
          let row = await rowArray.join(",");
          downloadContent += row + "\r\n";
        }

        const name = `ถอนสินค้า-${id}${statusText ? ` (${statusText})` : ''
          } - ${moment().format('yyyy-MM-DD')}${searchText ? ` [${TEXT.searchFor} ${searchText}]` : ''
          }.csv`;
        const universalBOM = "\uFEFF";
        const encodedUri = header + encodeURIComponent(universalBOM + downloadContent);
        let link = document.createElement("a");
        await link.setAttribute('href', encodedUri);
        await link.setAttribute('download', name);
        await document.body.appendChild(link);
        await link.click();

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        // const ws = XLSX.utils.json_to_sheet(content);
        // const wb = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, "");
        // const excelBuffer = XLSX.write(
        //   wb, 
        //   { 
        //     bookType: 'xlsx', 
        //     type: 'array' 
        //   });
        // const data = new Blob([excelBuffer], { type: fileType });
        // FileSaver.saveAs(data, name);

      }

      await WithdrawProductService.list(
        id,
        status && status !== 'empty' ? true : status === 'empty' ? false : null,
        { by: 'product.sku', direction: 'asc' },
        null,
        null,
        getValues('search'),
        status === 'empty' ? 'pending' : status,
        ['product']
      ).then(async (data: any) => {
        await csv(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });

      setShowLoading(false);

    }

    const downloadReceipt = async () => {

      setShowLoading(true);

      const status = getValues('status');
      let statusText: string = '';
      if (status === 'empty') {
        statusText = TEXT.asnProductNotFound;
      } else if (status === 'lack') {
        statusText = TEXT.lack;
      } else if (status === 'over') {
        statusText = TEXT.over;
      }

      const searchText = getValues('search');

      const download = async (content: any) => {

        let rows: any = [];
        await rows.push(
          await Object.keys(columnsReceipt).filter((item: any) => {
            return columnsReceipt[item] === true;
          })
        );
        for (let item of content) {
          let values = [
            (item.product_reference && item.product_reference.sku ? item.product_reference.sku : ''),
            (item.product_reference && item.product_reference.barcode ? item.product_reference.barcode : ''),
            (item.product_reference && item.product_reference.name ? item.product_reference.name : ''),
            (item.quantity ? item.quantity : ''),
            (item.counted ? item.counted : ''),
            (item.left ? item.left : ''),
            (item.over ? item.over : ''),
            (item.expire_date ? item.expire_date : ''),
            (item.lot_number ? item.lot_number : ''),
            (item.status && item.active !== false ? statusesProduct[item.status] : item.active === false ? TEXT.withdrawProductNotFound : '')
          ];
          await rows.push(
            await values.filter((item: any, index: number) => {
              return Object.values(columnsReceipt)[index] === true;
            })
          );
        };

        const fileNameDescription = `${moment().format('yyyy-MM-DD')}${searchText ? ` [${TEXT.searchFor} ${searchText}]` : ''
          }`;
        const name = `ถอนสินค้า-${id}${statusText ? ` (${statusText})` : ''
          } - ${fileNameDescription}.xlsx`;

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';

        const sheetContentHeader: any = {
          '!ref': `A1:F${(rows.length + 13)}`,
          '!merges': [
            {
              s: {
                r: 0,
                c: 0
              },
              e: {
                r: 0,
                c: 5
              }
            },
            {
              s: {
                r: 1,
                c: 0
              },
              e: {
                r: 1,
                c: 5
              }
            },
            {
              s: {
                r: 2,
                c: 0
              },
              e: {
                r: 2,
                c: 5
              }
            },
            {
              s: {
                r: 3,
                c: 0
              },
              e: {
                r: 3,
                c: 5
              }
            },
            {
              s: {
                r: 4,
                c: 1
              },
              e: {
                r: 4,
                c: 5
              }
            },
            {
              s: {
                r: 5,
                c: 1
              },
              e: {
                r: 5,
                c: 5
              }
            },
            {
              s: {
                r: 6,
                c: 1
              },
              e: {
                r: 6,
                c: 5
              }
            },
            {
              s: {
                r: 7,
                c: 1
              },
              e: {
                r: 7,
                c: 5
              }
            },
            {
              s: {
                r: 8,
                c: 0
              },
              e: {
                r: 8,
                c: 5
              }
            },
            {
              s: {
                r: (rows.length + 9),
                c: 0
              },
              e: {
                r: (rows.length + 9),
                c: 5
              }
            },
            {
              s: {
                r: (rows.length + 10),
                c: 1
              },
              e: {
                r: (rows.length + 10),
                c: 5
              }
            },
            {
              s: {
                r: (rows.length + 11),
                c: 0
              },
              e: {
                r: (rows.length + 11),
                c: 5
              }
            },
            {
              s: {
                r: (rows.length + 12),
                c: 1
              },
              e: {
                r: (rows.length + 12),
                c: 5
              }
            }
          ],
          '!cols': [
            {
              width: 20
            },
            {
              width: 20
            },
            {
              width: 50
            },
            {
              width: 10
            },
            {
              width: 10
            }
          ],
          'A1': {
            t: 's',
            v: TEXT.withdrawReceiptHeader,
            s: {
              alignment: {
                horizontal: 'center'
              }
            }
          },
          'A2': {
            t: 's',
            v: CONTACT.company,
            s: {
              alignment: {
                horizontal: 'center'
              }
            }
          },
          'A3': {
            t: 's',
            v: `${CONTACT.address}, ${CONTACT.phone}`,
            s: {
              alignment: {
                horizontal: 'center'
              }
            }
          },
          'A4': {
            t: 's',
            v: ''
          },
          'A5': {
            t: 's',
            v: TEXT.dateNotified
          },
          'B5': {
            t: withdraw && withdraw.create_at ? 'd' : 's',
            v: `${withdraw && withdraw.create_at ? moment.utc(withdraw.create_at).local().format('yyyy-MM-DD') : ''}`
          },
          'A6': {
            t: 's',
            v: TEXT.withdrawNumber
          },
          'B6': {
            t: 's',
            v: `${
              withdraw && withdraw.id ?
                `WTD-${withdraw.id}`
                : ''
            }`
          },
          'A7': {
            t: 's',
            v: TEXT.vendor
          },
          'B7': {
            t: 's',
            v: `${
              withdraw && withdraw.vendor_reference ?
                `${withdraw.vendor_reference.name}`
                : ''
            }`
          },
          'A8': {
            t: 's',
            v: TEXT.dateTakeFromWarehouse
          },
          'B8': {
            t: withdraw && withdraw.withdraw_date ? 'd' : 's',
            v: `${withdraw && withdraw.withdraw_date ? moment.utc(withdraw.withdraw_date).local().format('yyyy-MM-DD') : ''}`
          },
          'A9': {
            t: 's',
            v: ''
          }
        };
        const sheetContentBody: any = {};
        const sheetContentFooter: any = {
          [`A${(rows.length + 10)}`]: {
            t: 's',
            v: ''
          },
          [`A${(rows.length + 11)}`]: {
            t: 's',
            v: TEXT.withdrawSender
          },
          [`B${(rows.length + 11)}`]: {
            t: 's',
            v: '',
            s: {
              border: {
                bottom: {
                  style: 'thin',
                  color: '00000000'
                }
              }
            }
          },
          [`A${(rows.length + 12)}`]: {
            t: 's',
            v: ''
          },
          [`A${(rows.length + 13)}`]: {
            t: 's',
            v: TEXT.withdrawReceiver
          },
          [`B${(rows.length + 13)}`]: {
            t: 's',
            v: '',
            s: {
              border: {
                bottom: {
                  style: 'thin',
                  color: '00000000'
                }
              }
            }
          }
        };

        let r = 10;
        for (let row of rows) {
          let c = 0
          for (let column of row) {
            let type = 's';
            let column_index = 'A';
            if (c === 1) {
              column_index = 'B';
            } else if (c === 2) {
              column_index = 'C';
            } else if (c === 3) {
              column_index = 'D';
              if (r > 10) {
                type = 'n';
              }
            } else if (c === 4) {
              column_index = 'E';
              if (r > 10) {
                type = 'n';
              }
            } else if (c === 5) {
              column_index = 'F';
              if (r > 10) {
                type = 'n';
              }
            }
            sheetContentBody[`${column_index}${r}`] = {
              t: type,
              v: column,
              s: {
                border: {
                  top: {
                    style: 'thin',
                    color: '00000000'
                  },
                  bottom: {
                    style: 'thin',
                    color: '00000000'
                  },
                  left: {
                    style: 'thin',
                    color: '00000000'
                  },
                  right: {
                    style: 'thin',
                    color: '00000000'
                  }
                }
              }
            }
            c += 1;
          }
          r += 1;
        }

        const sheetContent: any = {
          ...sheetContentHeader,
          ...sheetContentBody,
          ...sheetContentFooter
        }

        const excelBuffer: any = await XLSX.writeFile({
          SheetNames: [`ถอนสินค้า-${id}`],
          Sheets: {
            [`ถอนสินค้า-${id}`]: sheetContent
          }
        }, name);

        new Blob([excelBuffer], { type: fileType });

      }

      await WithdrawProductService.list(
        id,
        status && status !== 'empty' ? true : status === 'empty' ? false : null,
        { by: 'product.sku', direction: 'asc' },
        null,
        null,
        getValues('search'),
        status === 'empty' ? 'pending' : status,
        ['product']
      ).then(async (data: any) => {
        download(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });

      setShowLoading(false);

    }

    const [presentCSV, dismissCSV] = useIonModal(ModalWithdrawImport, {
      options: {
        canDismiss: true,
        swipeToClose: true
      },
      withdrawID: id,
      onDismiss: async (data: any) => {
        dismissCSV();
        if (data) {
          setShowLoading(true);
          setImportResult(data);
          await load(id);
          setShowLoading(false);
        }
      }
    });

    const [presentImport, dismissImport] = useIonModal(ModalWithdrawTakeAwayImport, {
      options: {
        canDismiss: true,
        swipeToClose: true
      },
      withdraw: withdraw ? withdraw : null,
      onDismiss: async (data: any) => {
        dismissImport();
        if (data) {
          setShowLoading(true);
          setImportResult(data);
          await load(id);
          setShowLoading(false);
        }
      }
    });

    const handlePageChange = async (currentPage: number) => {
      setPage(currentPage);
      loadProducts(withdraw.id, (currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
    };

    const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
      setPage(currentPage);
      setPageLimit(newPerPage);
      loadProducts(withdraw.id, (currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
    };

    const handleSort = async (column: any, sortDirection: any) => {
      setSort({ by: column.id, direction: sortDirection });
      loadProducts(withdraw.id, (page - 1) * pageLimit, pageLimit, column.id, sortDirection);
    };

    useEffect(() => {
      setReady(true);
      return () => {
        setReady(false);
      }
    }, []);

    useEffect(() => {
      initialize();
    }, [id]);

    useEffect(() => {
      if (initialized) {
        load(id);
      }
    }, [reload]);

    useEffect(() => {
      if (doChangeStatus) {
        changeStatus(withdraw, doChangeStatus, ready);
      }
    }, [doChangeStatus]);

    useEffect(() => {
      if (doCheck) {

      }
    }, [doCheck]);

    useEffect(() => {
      if (scannerIsOpen) {
        setModalScannerIsOpen(true);
      }
    }, [scannerIsOpen]);

    useEffect(() => {
      if (barcodeIsOpen) {
        setModalBarcodeIsOpen(true);
      }
    }, [barcodeIsOpen]);

    return (
      <>

        <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        />

        <AlertStatus
          isPresent={presentAlert.isPresent}
          status={presentAlert.status}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <AlertPrompt
          isPresent={presentAlertPrompt.isPresent}
          message={presentAlertPrompt.message}
          onDismiss={presentAlertPrompt.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonModal canDismiss swipeToClose isOpen={modalProductPreviewIsOpen} onDidDismiss={() => setModalProductPreviewIsOpen(false)}>
          <ModalProductPreview
            productId={idProductPreview}
            onDismiss={() => setModalProductPreviewIsOpen(false)}
          />
        </IonModal>

        <IonModal canDismiss swipeToClose isOpen={modalCountedData ? true : false} onDidDismiss={() => setModalCountedData(false)}>
          <ModalCounted
            withdrawProductID={modalCountedData}
            onDismiss={() => {
              setModalCountedData(false);
              loadProducts(id, 0, pageLimit, sort.by, sort.direction, getValues('search'), getValues('status'));
              loadProductsAll(id);
            }}
          />
        </IonModal>

        <IonAlert
          isOpen={downloadPrompt}
          onDidDismiss={() => setDownloadPrompt(false)}
          header="เลือกรูปแบบเอกสาร"
          inputs={
            [
              {
                label: TEXT.report,
                type: 'radio',
                value: 'report'
              },
              {
                label: TEXT.withdrawReceipt,
                type: 'radio',
                value: 'receipt'
              }
            ]
          }
          buttons={[
            {
              text: BUTTON.cancel,
              role: 'cancel',
            },
            {
              text: BUTTON.ok,
              handler: (data: any) => {
                if (data === 'report') {
                  downloadReport();
                } else if (data === 'receipt') {
                  downloadReceipt();
                }
              }
            }
          ]}
        />

        {
          withdraw ?
            <>

              {
                scannerReady ?
                  <IonModal canDismiss swipeToClose isOpen={modalScannerIsOpen} onDidDismiss={() => setModalScannerIsOpen(false)}>
                    <ModalScanner
                      productsData={JSON.parse(JSON.stringify(productsAll))}
                      checkedData={JSON.parse(JSON.stringify(productsChecks))}
                      locationSet={withdrawLocation}
                      locationScannerUsing={modalLocationData ? true : false}
                      onLocationSet={
                        () => {
                          setModalLocationData(true);
                        }
                      }
                      onCheck={
                        async (product: any) => {
                          await updateProductsChecks(product, withdrawLocation);
                          loadProducts(withdraw.id, page, pageLimit, sort.by, sort.direction);
                        }
                      }
                      onDismiss={() => setModalScannerIsOpen(false)}
                    />
                  </IonModal>
                  : <></>
              }

              {
                scannerReady ?
                  <IonModal canDismiss swipeToClose isOpen={modalBarcodeIsOpen} onDidDismiss={() => setModalBarcodeIsOpen(false)}>
                    <ModalBarcode
                      productsData={JSON.parse(JSON.stringify(productsAll))}
                      checkedData={JSON.parse(JSON.stringify(productsChecks))}
                      locationSet={withdrawLocation}
                      onLocationSet={
                        () => {
                          setModalLocationData(true);
                        }
                      }
                      onCheck={
                        async (product: any) => {
                          await updateProductsChecks(product, withdrawLocation);
                          loadProducts(withdraw.id, page, pageLimit, sort.by, sort.direction);
                        }
                      }
                      onDismiss={() => setModalBarcodeIsOpen(false)}
                    />
                  </IonModal>
                  : <></>
              }

              <IonModal canDismiss swipeToClose isOpen={modalLocationData ? true : false} onDidDismiss={() => setModalLocationData(null)}>
                <ModalLocation
                  reference={modalLocationData}
                  currentLocationCode={withdrawLocation}
                  onDismiss={async (locationData: any) => {
                    if (locationData) {
                      const locationCode: string =
                        (locationData.warehouse ? locationData.warehouse : '')
                        + '.' + (locationData.floor ? locationData.floor : '')
                        + '.' + (locationData.aisle ? locationData.aisle : '')
                        + '.' + (locationData.bay ? locationData.bay : '')
                        + '.' + (locationData.level ? locationData.level : '')
                        + '.' + (locationData.bin ? locationData.bin : '');
                      setWithdrawLocation(locationCode);
                      await setFocusBarcode(true);
                      setFocusBarcode(false);
                    }
                    setModalLocationData(null);
                  }}
                />
              </IonModal>

              <IonGrid className={className}>
                <IonRow>

                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          {TEXT.withdraw} - {withdraw.id}
                          {
                            withdraw.vendor_reference ?
                              ` (${withdraw.vendor_reference.name})`
                              : ''
                          }
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.dateNotified}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none">
                                <IonLabel class="ion-text-wrap" color="dark">{withdraw.create_at ? moment.utc(withdraw.create_at).local().format('LLL') : '-'}</IonLabel>
                              </IonItem>
                            </IonCol>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.dateGoFromWarehouse}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              {
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                )
                                  ?
                                  <>
                                    <IonItem>
                                      <IonInput
                                        type="datetime-local"
                                        {...withdrawExpectDate}
                                        disabled={status === 'complete' || status === 'cancel' ? true : false}
                                        onIonChange={(e: any) => {
                                          let dateValue = null;
                                          if (moment(e.detail.value).isValid()) {
                                            dateValue = e.detail.value;
                                          }
                                          if (dateValue) {
                                            setValue('withdrawExpectDate', moment(dateValue).format('YYYY-MM-DDTHH:mm'));
                                            changeExpectDate(withdraw, moment(dateValue).utc().format('YYYY-MM-DDTHH:mm:ss'), ready);
                                          } else {
                                            setValue('withdrawExpectDate', null);
                                            changeExpectDate(withdraw, null, ready);
                                          }
                                        }}>
                                      </IonInput>
                                    </IonItem>
                                  </>
                                  :
                                  <IonItem lines="none">
                                    <IonLabel class="ion-text-wrap" color="dark">{withdraw.expect_date ? moment.utc(withdraw.expect_date).local().format('LLL') : '-'}</IonLabel>
                                  </IonItem>
                              }
                            </IonCol>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.dateFromWarehouse}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              {
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                )
                                  ?
                                  <>
                                    <IonItem>
                                      <IonInput
                                        type="datetime-local"
                                        {...withdrawDate}
                                        disabled={status === 'complete' || status === 'cancel' ? true : false}
                                        onIonChange={(e: any) => {
                                          let dateValue = null;
                                          if (moment(e.detail.value).isValid()) {
                                            dateValue = e.detail.value;
                                          }
                                          if (dateValue) {
                                            setValue('withdrawDate', moment(e.detail.value).format('YYYY-MM-DDTHH:mm'));
                                            changeWithdrawDate(withdraw, moment(e.detail.value).utc().format('YYYY-MM-DDTHH:mm:ss'), ready);
                                          } else {
                                            setValue('withdrawDate', null);
                                            changeWithdrawDate(withdraw, null, ready);
                                          }
                                        }}>
                                      </IonInput>
                                    </IonItem>
                                  </>
                                  :
                                  <IonItem lines="none">
                                    <IonLabel class="ion-text-wrap" color="dark">{withdraw.withdraw_date ? moment.utc(withdraw.withdraw_date).local().format('LLL') : '-'}</IonLabel>
                                  </IonItem>
                              }
                            </IonCol>

                            <IonCol size='6' sizeXl="3">
                              <IonItem lines="none" className="item-no-padding">
                                <IonLabel class="ion-text-wrap" color="medium">{TEXT.withdrawStatus}</IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol size='6' sizeXl="3">
                              {
                                members.length && (
                                  members.includes('1')
                                  || members.includes('2')
                                  || members.includes('3')
                                )
                                  ?
                                  <IonItem className="item-hidden-label">
                                    <IonLabel>
                                      <IonBadge color={colors[withdraw.status]}>{statuses[withdraw.status]}</IonBadge>
                                    </IonLabel>
                                    <IonLabel>{TEXT.withdrawStatus}</IonLabel>
                                    <IonSelect
                                      interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                      {...withdrawStatus} value={getValues('withdrawStatus')}
                                      onIonChange={(e: any) => { e.ready = ready; e.withdraw = withdraw; withdrawStatus.onChange(e) }}>
                                      <IonSelectOption value="pending">{statuses['pending']}</IonSelectOption>
                                      <IonSelectOption value="complete">{statuses['complete']}</IonSelectOption>
                                      <IonSelectOption value="cancel">{statuses['cancel']}</IonSelectOption>
                                    </IonSelect>
                                  </IonItem>
                                  :
                                  <IonItem lines="none">
                                    <IonLabel>
                                      <IonBadge color={colors[withdraw.status]}>{statuses[withdraw.status]}</IonBadge>
                                    </IonLabel>
                                  </IonItem>
                              }
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>

                </IonRow>

                <IonRow className='row-toolbar ion-margin-top'>
                  <IonCol size='12' sizeMd={
                    members.length && (
                      members.includes('1')
                      || members.includes('2')
                      || members.includes('3')
                    ) ? '8' : '7'
                  }>
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines="none">
                        <IonSearchbar
                          placeholder={
                            `${TEXT.productName}/${TEXT.productSku}/${TEXT.barcode}`
                          }
                          inputmode="search"
                          {...filterSearch}
                          onIonChange={(e: SearchbarCustomEvent) => { setValue('search', e.detail.value) }}
                          onKeyUp={(e: KeyboardEvent) => {
                            if (e.key === "Enter") {
                              loadProducts(
                                withdraw.id,
                                0,
                                pageLimit,
                                sort.by,
                                sort.direction,
                                (e.target as HTMLTextAreaElement).value,
                                getValues('status')
                              );
                            }
                          }}
                          onIonClear={async () => {
                            setValue('search', '');
                            loadProducts(
                              withdraw.id,
                              0,
                              pageLimit,
                              sort.by,
                              sort.direction,
                              '',
                              getValues('status')
                            );
                          }}
                        >
                        </IonSearchbar>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                  <IonCol size='12' sizeMd={
                    members.length && (
                      members.includes('1')
                      || members.includes('2')
                      || members.includes('3')
                    ) ? '4' : '5'
                  }>
                    <IonToolbar className='toolbar-page'>
                      <IonButtons slot="end" className={
                        members.length && (
                          members.includes('1')
                          || members.includes('2')
                          || members.includes('3')
                        ) ? 'w-100' : ''
                      }>
                        {
                          members.length && (
                            members.includes('1')
                            || members.includes('2')
                            || members.includes('3')
                          )
                            ?
                            <>
                              <IonButton fill='outline' color="primary" onClick={() => { setDownloadPrompt(true); }} disabled={showProgressLoading}>
                                <IonIcon slot="start" icon={downloadOutline} />&nbsp;{BUTTON.download}
                              </IonButton>
                              <IonButton fill='outline' color="tertiary" className="w-100" onClick={() => { presentImport(); }}
                                disabled={status === 'complete' || status === 'cancel' ? true : false || showProgressLoading ? true : false}>
                                <IonIcon slot="start" icon={cloudUploadOutline} />&nbsp;{BUTTON.uploadTakeAwayCSV}
                              </IonButton>
                            </>
                            :
                            <>
                              <IonButton fill='outline' color="primary"
                                disabled={status === 'complete' || status === 'cancel' ? true : false}
                                routerLink={RoutePath.productWithdrawEdit.replace(':id', id)}
                                routerDirection={Direction()}
                              >
                                <IonIcon slot="start" icon={cubeOutline} />&nbsp;{MENU.productWithdrawManage}
                              </IonButton>
                              <IonButton fill='outline' color="secondary" onClick={() => { setDownloadPrompt(true); }} disabled={showProgressLoading}>
                                <IonIcon slot="start" icon={downloadOutline} />&nbsp;{BUTTON.download}
                              </IonButton>
                              <IonButton fill='outline' color="tertiary" onClick={() => { presentCSV(); }}
                                disabled={status === 'complete' || status === 'cancel' ? true : false || showProgressLoading ? true : false}>
                                <IonIcon slot="start" icon={cloudUploadOutline} />&nbsp;{BUTTON.uploadCSV}
                              </IonButton>
                            </>
                        }
                      </IonButtons>
                    </IonToolbar>
                  </IonCol>
                </IonRow>

              </IonGrid>

              <IonGrid className='grid-main'>
                <IonRow>
                  <IonCol>
                    <IonSegment className='table-segment' disabled={showProgressLoading} mode='md' scrollable={true}
                      value={getValues('status') ? getValues('status') : ''}
                      {...filterStatus}
                      onIonChange={(e: any) => { e.withdrawID = withdraw.id; filterStatus.onChange(e) }}>
                      <IonSegmentButton value="">
                        <IonLabel>
                          {TEXT.all}
                          {
                            totalAll ?
                              <IonBadge>
                                <NumberFormat value={totalAll} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="counted">
                        <IonLabel>
                          {TEXT.counted}
                          {
                            totalCounted ?
                              <IonBadge color="danger">
                                <NumberFormat value={totalCounted} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="left">
                        <IonLabel>
                          {TEXT.lack}
                          {
                            totalLack ?
                              <IonBadge color="danger">
                                <NumberFormat value={totalLack} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="over">
                        <IonLabel>
                          {TEXT.over}
                          {
                            totalOver ?
                              <IonBadge color="danger">
                                <NumberFormat value={totalOver} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="pending">
                        <IonLabel>
                          {statuses["pending"]}
                          {
                            totalPending ?
                              <IonBadge color="medium">
                                <NumberFormat value={totalPending} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value="complete">
                        <IonLabel>
                          {statuses["complete"]}
                          {
                            totalComplete ?
                              <IonBadge color="success">
                                <NumberFormat value={totalComplete} displayType={'text'} thousandSeparator={true} />
                              </IonBadge>
                              : <></>
                          }
                        </IonLabel>
                      </IonSegmentButton>
                    </IonSegment>
                  </IonCol>
                </IonRow>
              </IonGrid>

              {
                importResult ?
                  <IonAccordionGroup>
                    <IonAccordion value="updates">
                      <IonItem slot="header" color="success">
                        <IonLabel>{importResult.updates ? importResult.updates.length : 0} {TEXT.updates}</IonLabel>
                      </IonItem>
                      {
                        importResult.updates && importResult.updates.length ?
                          <div className="ion-padding" slot="content">
                            {
                              importResult.updates.map((item: any, index: number) => (
                                <CopyToClipboard text={item.sku ? item.sku : ''} key={`updates-${index}`}
                                  onCopy={() => {
                                    setPresentToast({
                                      isPresent: true,
                                      status: true,
                                      message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                      onDismiss: () => {
                                        if (ready) {
                                          setPresentToast(
                                            {
                                              isPresent: false,
                                              status: presentToast.status,
                                              message: presentToast.message,
                                              onDismiss: presentToast.onDismiss
                                            }
                                          );
                                        }
                                      }
                                    });
                                  }}>
                                  <IonChip color="success">
                                    <IonLabel>{item.sku ? item.sku : ''}</IonLabel>
                                    <IonIcon icon={copyOutline}></IonIcon>
                                  </IonChip>
                                </CopyToClipboard>
                              ))
                            }
                          </div>
                          : <></>
                      }
                    </IonAccordion>
                    <IonAccordion value="errors">
                      <IonItem slot="header" color="danger">
                        <IonLabel>{importResult.errors ? importResult.errors.length : 0} {TEXT.errors}</IonLabel>
                      </IonItem>
                      {
                        importResult.errors && importResult.errors.length ?
                          <div className="ion-padding" slot="content">
                            {
                              importResult.errors.map((item: any, index: number) => (
                                <CopyToClipboard text={item.sku ? item.sku : ''} key={`errors-${index}`}
                                  onCopy={() => {
                                    setPresentToast({
                                      isPresent: true,
                                      status: true,
                                      message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                      onDismiss: () => {
                                        if (ready) {
                                          setPresentToast(
                                            {
                                              isPresent: false,
                                              status: presentToast.status,
                                              message: presentToast.message,
                                              onDismiss: presentToast.onDismiss
                                            }
                                          );
                                        }
                                      }
                                    });
                                  }}>
                                  <IonChip color="danger">
                                    <IonLabel>{`${item.sku ? item.sku : ''} ${item.reason ? `(${item.reason})` : ''}`}</IonLabel>
                                    <IonIcon icon={copyOutline}></IonIcon>
                                  </IonChip>
                                </CopyToClipboard>
                              ))
                            }
                          </div>
                          : <></>
                      }
                    </IonAccordion>
                  </IonAccordionGroup>
                  : <></>
              }

              {
                initialized ?
                  <>


                    {
                      members.length && (
                        members.includes('1')
                        || members.includes('2')
                        || members.includes('3')
                      ) ?
                        (total < offset) ?
                          <DataTable
                            columns={columnsProducts}
                            data={products}
                            customStyles={pageTableExpandable}
                            pagination
                            noDataComponent={TEXT.noDataComponent}
                            paginationComponentOptions={paginationComponentOptions}
                            defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                            defaultSortFieldId={defaultSort.by}
                            progressPending={showProgressLoading}
                            expandableRows
                            expandableRowExpanded={
                              (row: any) =>
                                row.product_reference
                                  && (
                                    (
                                      row.product_reference.lot_control
                                      && row.product_reference.lot_control !== '0'
                                    ) || row.product_reference.packing_remark
                                  )
                                  ? true : false
                            }
                            expandableRowsComponent={
                              (row: any) =>
                                <IonCard>
                                  <IonCardHeader>
                                    <IonCardTitle>
                                      {TEXT.packingRemark}
                                      {
                                        row.data.product_reference
                                          && row.data.product_reference.lot_control
                                          && row.data.product_reference.lot_control !== '0' ?
                                          <span className="text-danger">
                                            {` *${TEXT.controlLot}`}
                                          </span>
                                          : <></>
                                      }
                                    </IonCardTitle>
                                  </IonCardHeader>
                                  <IonCardContent>
                                    {
                                      row.data.product_reference
                                        && (
                                          (row.data.suggestion && row.data.suggestion.length)
                                          || row.data.product_reference.packing_remark
                                        ) ?
                                        <>
                                          {
                                            row.data.product_reference.packing_remark ?
                                              <p>
                                                {row.data.product_reference.packing_remark}
                                              </p>
                                              : <></>
                                          }
                                          {
                                            row.data.suggestion && row.data.suggestion.length ?
                                              <DataTable
                                                columns={columnsSuggestions}
                                                data={row.data.suggestion}
                                                customStyles={pageTableCompact}
                                                pagination
                                                noDataComponent={TEXT.noDataComponent}
                                                paginationComponentOptions={paginationComponentOptions}
                                              />
                                              : <></>
                                          }
                                        </>
                                        :
                                        <p>
                                          {TEXT.notSpecfic}
                                        </p>
                                    }
                                  </IonCardContent>
                                </IonCard>
                            }
                          />
                          :
                          <DataTable
                            columns={columnsProducts}
                            data={products}
                            customStyles={pageTableExpandable}
                            pagination
                            noDataComponent={TEXT.noDataComponent}
                            paginationComponentOptions={paginationComponentOptions}
                            paginationServer
                            paginationTotalRows={total}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            sortServer
                            defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                            defaultSortFieldId={defaultSort.by}
                            progressPending={showProgressLoading}
                            onSort={handleSort}
                            expandableRows
                            expandableRowExpanded={
                              (row: any) =>
                                row.product_reference
                                  && (
                                    (
                                      row.product_reference.lot_control
                                      && row.product_reference.lot_control !== '0'
                                    ) || row.product_reference.packing_remark
                                  )
                                  ? true : false
                            }
                            expandableRowsComponent={
                              (row: any) =>
                                <IonCard>
                                  <IonCardHeader>
                                    <IonCardTitle>
                                      {TEXT.packingRemark}
                                      {
                                        row.data.product_reference
                                          && row.data.product_reference.lot_control
                                          && row.data.product_reference.lot_control !== '0' ?
                                          <span className="text-danger">
                                            {` *${TEXT.controlLot}`}
                                          </span>
                                          : <></>
                                      }
                                    </IonCardTitle>
                                  </IonCardHeader>
                                  <IonCardContent>
                                    {
                                      row.data.product_reference
                                        && (
                                          (row.data.suggestion && row.data.suggestion.length)
                                          || row.data.product_reference.packing_remark
                                        ) ?
                                        <>
                                          {
                                            row.data.product_reference.packing_remark ?
                                              <p>
                                                {row.data.product_reference.packing_remark}
                                              </p>
                                              : <></>
                                          }
                                          {
                                            row.data.suggestion && row.data.suggestion.length ?
                                              <DataTable
                                                columns={columnsSuggestions}
                                                data={row.data.suggestion}
                                                customStyles={pageTableCompact}
                                                pagination
                                                noDataComponent={TEXT.noDataComponent}
                                                paginationComponentOptions={paginationComponentOptions}
                                              />
                                              : <></>
                                          }
                                        </>
                                        :
                                        <p>
                                          {TEXT.notSpecfic}
                                        </p>
                                    }
                                  </IonCardContent>
                                </IonCard>
                            }
                          />
                        :
                        (total < offset) ?
                          <DataTable
                            columns={columnsProducts}
                            data={products}
                            customStyles={pageTable}
                            pagination
                            noDataComponent={TEXT.noDataComponent}
                            paginationComponentOptions={paginationComponentOptions}
                            defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                            defaultSortFieldId={defaultSort.by}
                          />
                          :
                          <DataTable
                            columns={columnsProducts}
                            data={products}
                            customStyles={pageTable}
                            pagination
                            noDataComponent={TEXT.noDataComponent}
                            paginationComponentOptions={paginationComponentOptions}
                            paginationServer
                            paginationTotalRows={total}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            sortServer
                            defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                            defaultSortFieldId={defaultSort.by}
                            onSort={handleSort}
                          />
                    }

                  </>
                  : <></>
              }

            </>
            : <></>
        }

      </>
    );
  }

export default Withdraw;