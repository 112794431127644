import { 
  STORAGE_PREFIX, 
  STORAGE_PREFIX_DEVELOP, 
  STORAGE_PREFIX_TESTING 
} from "../constances/Configs";

const StorageService = {
  set: async (keyName: string = '', data: any, isObject: boolean = false) => {
    return new Promise(async (resolve, reject) => {
      let dataToSet: any = data;
      if (isObject) {
        dataToSet = await JSON.stringify(data);
      }
      try {
        let prefix: string = STORAGE_PREFIX;
        if (process.env.REACT_APP_ENV === 'develop') {
          prefix = STORAGE_PREFIX_DEVELOP;
        } else if (process.env.REACT_APP_ENV === 'testing') {
          prefix = STORAGE_PREFIX_TESTING;
        }
        await localStorage.setItem(prefix + keyName, dataToSet);
        resolve(true);
      } catch(error) {
        reject(error);
      }
    });
  },
  get: async (keyName: string = '', isObject: boolean = false) => {
    return new Promise(async (resolve, reject) => {
      try {
        let prefix: string = STORAGE_PREFIX;
        if (process.env.REACT_APP_ENV === 'develop') {
          prefix = STORAGE_PREFIX_DEVELOP;
        } else if (process.env.REACT_APP_ENV === 'testing') {
          prefix = STORAGE_PREFIX_TESTING;
        }
        let dataToGet: any = await localStorage.getItem(prefix + keyName);
        if (isObject) {
          dataToGet = await JSON.parse(dataToGet);
        }
        resolve(dataToGet);
      } catch(error) {
        reject(error);
      }
    });
  },
  remove: async (keyName: string = '') => {
    return new Promise(async (resolve, reject) => {
      try {
        let prefix: string = STORAGE_PREFIX;
        if (process.env.REACT_APP_ENV === 'develop') {
          prefix = STORAGE_PREFIX_DEVELOP;
        } else if (process.env.REACT_APP_ENV === 'testing') {
          prefix = STORAGE_PREFIX_TESTING;
        }
        await localStorage.removeItem(prefix + keyName);
        resolve(true);
      } catch(error) {
        reject(error);
      }
    });
  }
};

export default StorageService;