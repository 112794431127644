import '../css/Page.scss';

import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';

import {
  IonContent,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonText,
  IonButtons,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { alertCircleOutline, informationCircleOutline, lockClosedOutline, mailOutline, personOutline, pin, remove, removeCircle, saveOutline, storefrontOutline } from 'ionicons/icons';

import { Imagires } from '../components/ImageVariable';
import { ToolbarStart } from '../components/ToolbarStart';
import { GoBack, RoutePath } from '../components/RoutePath';
import { AlertStatus } from '../components/alerts/AlertStatus';
import { ToastStatus } from '../components/toasts/ToastStatus';

import { PATTERN_EMAIL } from '../constances/RegularExpressions';
import MESSAGE from '../constances/Messages.json';
import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import BUSINESS_TYPES from '../constances/BusinessTypes.json';
import MENU from '../constances/Menu.json';

import UserService from '../services/UserService';
import VendorService from '../services/VendorService';
import LocationService from '../services/LocationService';
import { ErrorMessage } from '@hookform/error-message';

const Register: React.FC = () => {

  const defaultCountry: string = 'TH';

  const [user, setUser] = useState<any>(null);
  const [vendor, setVendor] = useState<any>([]);
  const [countries, setCountries] = useState<Array<any>>([]);
  const [provinces, setProvinces] = useState<Array<any>>([]);
  const [cities, setCities] = useState<Array<any>>([]);
  const [subDistricts, setSubDistricts] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const { register, getValues, setValue, handleSubmit, formState: { errors } } = useForm();
  const {
    register: register2,
    getValues: getValues2,
    setValue: setValue2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();
  const {
    register: register3,
    getValues: getValues3,
    setValue: setValue3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm();
  const [formGroup, setFormGroup] = useState<any>(null);
  const [formGroupEmail, setFormGroupEmail] = useState<any>(null);
  const [formGroupPassword, setFormGroupPassword] = useState<any>(null);

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  const history = useHistory();

  const initialize = async () => {
    setShowLoading(true);
    const userData: any = await UserService.getSession();
    if (userData) {
      setUser(userData);
      setFormGroupEmail(
        {
          email: register2('email', {
            value: userData.email,
            required: MESSAGE.error.input_required,
            pattern: {
              value: PATTERN_EMAIL,
              message: MESSAGE.error.email_invalid,
            }
          })
        }
      );
      setFormGroupPassword(
        {
          password: register3('password', {
            required: MESSAGE.error.input_required
          }),
          password_new: register3('password_new', {
            required: MESSAGE.error.input_required
          }),
          confirm_password_new: register3('confirm_password_new', {
            required: MESSAGE.error.input_required
          })
        }
      );
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        if (vendorData) {

          setVendor(vendorData);

          await getCountries().then(async (countriesData: any) => {
            setCountries(countriesData);

            let countryValue: any;
            let provinceValue: any;
            let cityValue: any;
            let subdistrictValue: any;
            const searchCountry = (countryStored: string) => {
              return new Promise(resolve => {
                if (countryStored) {
                  for (let country of countriesData) {
                    if (
                      country.name === countryStored
                      || country.enName === countryStored
                      || country.alpha2 === countryStored
                    ) {
                      resolve(country.alpha2);
                    }
                  }
                } else {
                  resolve('');
                }
              });
            }
            await searchCountry(vendorData.country).then(async (countryData: any) => {
              countryValue = countryData;
              const searchProvince = (provinceStored: string) => {
                return new Promise(resolve => {
                  getProvinces(countryValue).then(async (provincesData: any) => {
                    setProvinces(provincesData);
                    if (provinceStored) {
                      for (let province of provincesData) {
                        if (
                          province.name_th === provinceStored
                          || province.name_en === provinceStored
                        ) {
                          resolve(province.id);
                        }
                      }
                    } else {
                      resolve('');
                    }
                  });
                });
              }
              await searchProvince(vendorData.province).then(async (provinceData: any) => {
                provinceValue = provinceData;
                const searchCity = (cityStored: string) => {
                  return new Promise(resolve => {
                    getCities(countryValue, provinceValue).then(async (citiesData: any) => {
                      setCities(citiesData);
                      if (cityStored) {
                        for (let city of citiesData) {
                          if (
                            city.name_th === cityStored
                            || city.name_en === cityStored
                          ) {
                            resolve(city.id);
                          }
                        }
                      } else {
                        resolve('');
                      }
                    });
                  });
                }
                await searchCity(vendorData.city).then(async (cityData: any) => {
                  cityValue = cityData;
                  const searchSubDistrict = async (subdistrictStored: string) => {
                    return new Promise(resolve => {
                      getSubDistricts(countryValue, cityValue).then(async (subDistrictsData: any) => {
                        setSubDistricts(subDistrictsData);
                        if (subdistrictStored) {
                          for (let subDistrict of subDistrictsData) {
                            if (
                              subDistrict.name_th === subdistrictStored
                              || subDistrict.name_en === subdistrictStored
                            ) {
                              resolve(subDistrict.id);
                            }
                          }
                        } else {
                          resolve('');
                        }
                      });
                    });
                  }
                  await searchSubDistrict(vendorData.subdistrict).then((subdistrictData: any) => {
                    subdistrictValue = subdistrictData;
                  });
                });
              });
            });

            setFormGroup(
              {
                name: register('name', {
                  value: vendorData.name,
                  required: MESSAGE.error.input_required
                }),
                tax_id: register('tax_id', {
                  value: vendorData.tax_id,
                  required: MESSAGE.error.input_required
                }),
                address: register('address', {
                  value: vendorData.address,
                  required: MESSAGE.error.input_required
                }),
                subdistrict: register('subdistrict', {
                  value: subdistrictValue,
                  required: MESSAGE.error.input_required,
                  onChange: (e) => {
                    if (e.target.value) {
                      getSubDistricts(
                        getValues('country'),
                        getValues('city')
                      ).then((subDistrictsData: any) => {
                        if (subDistrictsData && subDistrictsData.length) {
                          for (let subDistrict of subDistrictsData) {
                            if (subDistrict.id === e.target.value && subDistrict.zip_code) {
                              setValue('postcode', subDistrict.zip_code.toString());
                            }
                          }
                        }
                      });
                    }
                  }
                }),
                city: register('city', {
                  value: cityValue,
                  required: MESSAGE.error.input_required,
                  onChange: (e) => {
                    if (e.target.value) {
                      getSubDistricts(getValues('country'), e.target.value).then((subDistrictsData: any) => {
                        setValue('subdistrict', '');
                        setValue('postcode', '');
                        setSubDistricts(subDistrictsData);
                      });
                    }
                  }
                }),
                province: register('province', {
                  value: provinceValue,
                  required: MESSAGE.error.input_required,
                  onChange: async (e) => {
                    if (e.target.value) {
                      getCities(getValues('country'), e.target.value).then((citiesData: any) => {
                        setValue('city', '');
                        setValue('subdistrict', '');
                        setValue('postcode', '');
                        setCities(citiesData);
                      });
                    }
                  }
                }),
                country: register('country', {
                  value: countryValue,
                  required: MESSAGE.error.input_required,
                  onChange: (e) => {
                    if (e.target.value) {
                      getProvinces(e.target.value).then((provincesData: any) => {
                        setValue('province', '');
                        setValue('city', '');
                        setValue('subdistrict', '');
                        setValue('postcode', '');
                        setProvinces(provincesData);
                      });
                    }
                  }
                }),
                postcode: register('postcode', {
                  value: vendorData.postcode,
                  required: MESSAGE.error.input_required
                }),
                phone: register('phone', {
                  value: vendorData.phone
                }),
                business_type: register('business_type', {
                  value: vendorData.business_type,
                  required: MESSAGE.error.input_required
                }),
                contact_person_name: register('contact_person_name', {
                  value: vendorData.contact_person_name
                }),
                contact_person_phone: register('contact_person_phone', {
                  value: vendorData.contact_person_phone
                }),
                contact_person_email: register('contact_person_email', {
                  value: vendorData.contact_person_email,
                  pattern: {
                    value: PATTERN_EMAIL,
                    message: MESSAGE.error.email_invalid,
                  }
                }),
                contact_person_secondary_name: register('contact_person_secondary_name', {
                  value: vendorData.contact_person_secondary_name
                }),
                contact_person_secondary_phone: register('contact_person_secondary_phone', {
                  value: vendorData.contact_person_secondary_phone
                }),
                contact_person_secondary_email: register('contact_person_secondary_email', {
                  value: vendorData.contact_person_secondary_email,
                  pattern: {
                    value: PATTERN_EMAIL,
                    message: MESSAGE.error.email_invalid,
                  }
                }),
                active: register('active', {
                  value: '1',
                  required: MESSAGE.error.input_required
                }),
              }
            );

          });

        }
      }).catch(() => { });
    }
    setShowLoading(false);
  }

  const getCountries = async () => {
    return new Promise(async (resolve) => {
      LocationService.getCountries().then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getProvinces = async (countryCode: string) => {
    return new Promise(async (resolve) => {
      LocationService.getProvincesByCountry(countryCode).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getCities = async (countryCode: string, provinceID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getCitiesByProvince(
        countryCode,
        provinceID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const getSubDistricts = async (countryCode: string, cityID: number) => {
    return new Promise(async (resolve) => {
      LocationService.getSubDistrictsByCity(
        countryCode,
        cityID
      ).then(async (response: any) => {
        resolve(response);
      }).catch(() => {
        resolve([]);
      });
    });
  }

  const onSubmit = async (value: any) => {
    if (vendor && vendor.id) {
      if (value.password === value.confirm_password) {

        setShowLoading(true);

        delete value.company_certificate;
        delete value.vat_license;
        delete value.contract;

        value.postcode = value.postcode.toString();

        const searchProvince = async (id: number) => {
          return new Promise(resolve => {
            for (let province of provinces) {
              if (province.id === id) {
                if (value.country === 'TH') {
                  resolve(province.name_th);
                } else {
                  resolve(province.name_en);
                }
              }
            }
          });
        }
        if (value.province) {
          value.province = await searchProvince(value.province);
        }

        const searchCity = async (id: number) => {
          return new Promise(resolve => {
            for (let city of cities) {
              if (city.id === id) {
                if (value.country === 'TH') {
                  resolve(city.name_th);
                } else {
                  resolve(city.name_en);
                }
              }
            }
          });
        }
        if (value.city) {
          value.city = await searchCity(value.city);
        }

        const searchSubDistrict = async (id: number) => {
          return new Promise(resolve => {
            for (let subDistrict of subDistricts) {
              if (subDistrict.id === id) {
                if (value.country === 'TH') {
                  resolve(subDistrict.name_th);
                } else {
                  resolve(subDistrict.name_en);
                }
              }
            }
          });
        }
        if (value.subdistrict) {
          value.subdistrict = await searchSubDistrict(value.subdistrict);
        }

        const searchCountry = async (countryCode: string) => {
          return new Promise(resolve => {
            for (let country of countries) {
              if (country.alpha2 === countryCode) {
                if (countryCode === 'TH') {
                  resolve(country.name);
                } else {
                  resolve(country.enName);
                }
              }
            }
          });
        }
        if (value.country) {
          value.country = await searchCountry(value.country);
        }

        await VendorService.patch(vendor.id, value).then(async (data: any) => {
          if (data) {
            setPresentToast({
              isPresent: true,
              status: true,
              message: MESSAGE.success.complete,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          } else {
            setPresentToast({
              isPresent: true,
              status: false,
              message: MESSAGE.error.unidentified_error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          }
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });

        setShowLoading(false);

      } else {
        setPresentToast({
          isPresent: true,
          status: false,
          message: MESSAGE.error.password_mismatched,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }
    }
  }
  const onError = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message: MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
  }

  const onSubmitEmail = async (value: any) => {
    if (user && user.id) {

      setShowLoading(true);

      await UserService.patch(user.id, value).then(async (data: any) => {
        if (data) {
          setPresentToast({
            isPresent: true,
            status: true,
            message: MESSAGE.success.complete,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        } else {
          setPresentToast({
            isPresent: true,
            status: false,
            message: MESSAGE.error.unidentified_error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        }
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });

      setShowLoading(false);

    }
  }
  const onErrorEmail = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message:
        MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
  }

  const onSubmitPassword = async (value: any) => {
    if (user && user.id) {
      if (value.password_new === value.confirm_password_new) {

        setShowLoading(true);

        await UserService.updatePassword(
          value.password,
          value.password_new,
          value.confirm_password_new
        ).then(async (data: any) => {
          if (data) {
            setPresentToast({
              isPresent: true,
              status: true,
              message: MESSAGE.success.complete,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          } else {
            setPresentToast({
              isPresent: true,
              status: false,
              message: MESSAGE.error.unidentified_error,
              onDismiss: () => {
                if (ready) {
                  setPresentToast(
                    {
                      isPresent: false,
                      status: presentToast.status,
                      message: presentToast.message,
                      onDismiss: presentToast.onDismiss
                    }
                  );
                }
              }
            });
          }
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });

        setShowLoading(false);

      } else {
        setPresentToast({
          isPresent: true,
          status: false,
          message: MESSAGE.error.password_mismatched,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      }
    }
  }
  const onErrorPassword = (errors: any) => {
    setPresentAlert({
      isPresent: true,
      status: false,
      message:
        MESSAGE.error.form_invalid,
      onDismiss: () => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              status: presentAlert.status,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          let index = 0;
          for (let key of Object.keys(errors)) {
            if (index === 0) {
              if (errors[key] && errors[key].ref) {
                errors[key].ref.scrollIntoView();
              }
            }
            index += 1;
          }
        }
      }
    });
  }

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  return (
    <IonPage>
      <ToolbarStart menuName={MENU.setting} />

      <IonContent className="ion-padding">

        <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        />

        <AlertStatus
          isPresent={presentAlert.isPresent}
          status={presentAlert.status}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1' className='ion-no-padding ion-no-margin'>

              {
                (formGroup && vendor) &&
                <form key={1} onSubmit={handleSubmit(onSubmit, onError)}>

                  {/* ข้อมูลผู้ค้า */}
                  <IonCard>

                    <IonCardHeader>
                      <IonCardTitle>
                        <IonItem lines='none' className='item-no-padding-all'>
                          <IonIcon icon={storefrontOutline} slot="start" size='small' />
                          <IonLabel slot='start'>{TEXT.vendorInfo}</IonLabel>
                          {
                            vendor && vendor.id ?
                              <IonLabel slot='end'>{TEXT.vendorCode}: {vendor.id}</IonLabel>
                              : <></>
                          }
                        </IonItem>
                      </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                          <IonCol size='12' sizeMd='6'>
                            {/* ชื่อผู้ค้า */}
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.vendorName}<span className='form-required'>*</span></IonLabel>
                              <IonInput type="text" {...formGroup.name}></IonInput>
                            </IonItem>
                            <ErrorMessage errors={errors} name="name"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            {/* เลขประจำตัวผู้เสียภาษีผู้ค้า */}
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.vendorTaxID}<span className='form-required'>*</span></IonLabel>
                              <IonInput type="text" {...formGroup.tax_id}></IonInput>
                            </IonItem>
                            <ErrorMessage errors={errors} name="tax_id"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='12'>
                            {/* ที่อยู่ผู้ค้า */}
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.vendorAddress}<span className='form-required'>*</span></IonLabel>
                              <IonInput type="text" {...formGroup.address}></IonInput>
                            </IonItem>
                            <ErrorMessage errors={errors} name="address"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            {/* ประเทศ */}
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.country}<span className='form-required'>*</span></IonLabel>
                              {
                                countries.length ?
                                  <IonSelect
                                    interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                    placeholder="เลือกประเทศ"
                                    value={getValues('country')}
                                    disabled
                                    onIonChange={(e: any) => { formGroup.country.onChange(e) }}
                                    {...formGroup.country}
                                  >
                                    {countries.map((item: any, index: number) => (
                                      <IonSelectOption key={`country-${index}`} value={item.alpha2}>{item.name}</IonSelectOption>
                                    ))}
                                  </IonSelect>
                                  :
                                  <></>
                              }
                            </IonItem>
                            <ErrorMessage errors={errors} name="country"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            {/* จังหวัด */}
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.province}<span className='form-required'>*</span></IonLabel>
                              <IonSelect
                                interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                placeholder="เลือกจังหวัด"
                                value={getValues('province')}
                                {...formGroup.province}
                                disabled={!provinces.length}
                                onIonChange={(e: any) => { formGroup.province.onChange(e) }}
                              >
                                {provinces.map((item: any, index: number) => (
                                  <IonSelectOption key={`province-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                            <ErrorMessage errors={errors} name="province"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            {/* อำเภอ/เขต */}
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.city}<span className='form-required'>*</span></IonLabel>
                              <IonSelect
                                interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                placeholder="เลือกเขต/อำเภอ"
                                value={getValues('city')}
                                {...formGroup.city}
                                disabled={!cities.length}
                                onIonChange={(e: any) => { formGroup.city.onChange(e) }}
                              >
                                {cities.map((item: any, index: number) => (
                                  <IonSelectOption key={`city-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                            <ErrorMessage errors={errors} name="city"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            {/* ตำบล/แขวง */}
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.subDistrict}<span className='form-required'>*</span></IonLabel>
                              <IonSelect
                                interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                placeholder="เลือกแขวง/ตำบล"
                                value={getValues('subdistrict')}
                                {...formGroup.subdistrict}
                                disabled={!subDistricts.length}
                                onIonChange={(e: any) => { formGroup.subdistrict.onChange(e) }}
                              >
                                {subDistricts.map((item: any, index: number) => (
                                  <IonSelectOption key={`subdistrict-${index}`} value={item.id}>{item.name_th}</IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                            <ErrorMessage errors={errors} name="subdistrict"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            {/* รหัสไปรษณีย์ */}
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.postcode}<span className='form-required'>*</span></IonLabel>
                              <IonInput type="text" {...formGroup.postcode}></IonInput>
                            </IonItem>
                            <ErrorMessage errors={errors} name="postcode"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='6'>
                            {/* ประเภทธุรกิจ */}
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.vendorBusinessType}<span className='form-required'>*</span></IonLabel>
                              <IonSelect
                                interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                                placeholder={TEXT.vendorBusinessType}
                                value={getValues('business_type')}
                                {...formGroup.business_type}
                              >
                                {BUSINESS_TYPES.map((item: any, index: number) => (
                                  <IonSelectOption key={`business-${index}`} value={item.key}>{item.value}</IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                            <ErrorMessage errors={errors} name="business_type"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>

                  {/* บุคคลสำหรับติดต่อ */}
                  <IonCard>

                    <IonCardHeader>
                      <IonCardTitle>
                        <IonItem lines='none' className='item-no-padding-all'>
                          <IonIcon icon={personOutline} slot="start" size='small' />
                          <IonLabel>{TEXT.vendorContactInfo}</IonLabel>
                        </IonItem>
                      </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                          {/* Name 1 */}
                          <IonCol size='12'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.vendorContactName}</IonLabel>
                              <IonInput type="text" {...formGroup.contact_person_name}></IonInput>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.phone}</IonLabel>
                              <IonInput type="text" {...formGroup.contact_person_phone}></IonInput>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.email}</IonLabel>
                              <IonInput type="email" {...formGroup.contact_person_email}></IonInput>
                            </IonItem>
                            <ErrorMessage errors={errors} name="contact_person_email"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>


                          {/* Name 2 */}

                          <IonCol size='12'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.vendorContactName2}</IonLabel>
                              <IonInput type="text" {...formGroup.contact_person_secondary_name}></IonInput>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.phone}</IonLabel>
                              <IonInput type="text" {...formGroup.contact_person_secondary_phone}></IonInput>
                            </IonItem>
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.email}</IonLabel>
                              <IonInput type="email" {...formGroup.contact_person_secondary_email}></IonInput>
                            </IonItem>
                            <ErrorMessage errors={errors} name="contact_person_secondary_email"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>

                  <IonGrid>
                    <IonRow>
                      <IonCol className='ion-text-center'>
                        <p><IonButton type="submit" color="primary">{BUTTON.submitUpdate}</IonButton></p>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                </form>
              }

              {
                (formGroupEmail && user) &&
                <form key={2} onSubmit={handleSubmit2(onSubmitEmail, onErrorEmail)}>
                  {/* กำหนดชื่อผู้ใช้งาน และรหัสผ่าน */}
                  <IonCard className='ion-margin-top'>

                    <IonCardHeader>
                      <IonCardTitle>
                        <IonItem lines='none' className='item-no-padding-all'>
                          <IonIcon icon={mailOutline} slot="start" size='small' />
                          <IonLabel>{TEXT.usernameHeader}</IonLabel>
                          <IonButton type='submit' fill="solid" color='primary' slot="end">{BUTTON.save}</IonButton>
                        </IonItem>
                      </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                      <IonItem>
                        <IonLabel position="stacked">{TEXT.email}<span className='form-required'>*</span></IonLabel>
                        <IonInput type="email" {...formGroupEmail.email}></IonInput>
                      </IonItem>
                      <ErrorMessage errors={errors} name="email"
                        render={({ message }) =>
                          <div className='form-help'>
                            <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                            {message ? message : ''}
                          </div>
                        } />
                    </IonCardContent>
                  </IonCard>
                </form>
              }

              {
                (formGroupPassword && user) &&
                <form key={3} onSubmit={handleSubmit3(onSubmitPassword, onErrorPassword)}>

                  <IonCard>

                    <IonCardHeader>
                      <IonCardTitle>
                        <IonItem lines='none' className='item-no-padding-all'>
                          <IonIcon icon={lockClosedOutline} slot="start" size='small' />
                          <IonLabel>{TEXT.passwordHeader}</IonLabel>
                          <IonButton type='submit' fill="solid" color='primary' slot="end">{BUTTON.save}</IonButton>
                        </IonItem>
                      </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                      <IonGrid>
                        <IonRow>

                          <IonCol size='12' sizeMd='12'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.passwordCurrent}<span className='form-required'>*</span></IonLabel>
                              <IonInput type="password" {...formGroupPassword.password}></IonInput>
                            </IonItem>
                            <ErrorMessage errors={errors} name="password"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.passwordNew}<span className='form-required'>*</span></IonLabel>
                              <IonInput type="password" {...formGroupPassword.password_new}></IonInput>
                            </IonItem>
                            <ErrorMessage errors={errors} name="password_new"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                          <IonCol size='12' sizeMd='6'>
                            <IonItem>
                              <IonLabel position="stacked">{TEXT.passwordConfirm}<span className='form-required'>*</span></IonLabel>
                              <IonInput type="password" {...formGroupPassword.confirm_password_new}></IonInput>
                            </IonItem>
                            <ErrorMessage errors={errors} name="confirm_password_new"
                              render={({ message }) =>
                                <div className='form-help'>
                                  <IonIcon slot="icon-only" icon={alertCircleOutline} className='icon'></IonIcon>
                                  {message ? message : ''}
                                </div>
                              } />
                          </IonCol>

                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                </form>
              }

            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Register;