import { 
  URL_PRODUCT,
} from '../constances/Rest';

import axios from '../configs/axios';

import RestService from './RestService';
import { AxiosResponse } from 'axios';

const ProductService = {
  list: async (
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null,
    returnReferences: any = false,
    vendorID: string|null = null,
    wareHouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {
      let options: any = {};
			if (active === true) {
			} else if (active === false) {
				options.active = false;
			} else if (active && active !== true) {
				options.active = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'name',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'sku',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'barcode',
                operator: 'LIKE',
                value: `'%${search}%'`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
      if (active === true) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'active',
                operator: '=',
                value: `1`,
              },
              {
                conjunction: 'OR',
                key: 'active',
                operator: '=',
                value: `2`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
      
      await axios.post(
        RestService.route(`${URL_PRODUCT}list${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  count: async (
    active: boolean|null = null, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null,
    vendorID: string|null = null,
    wareHouseID: string|null = null
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
			} else if (active === false) {
				options.active = false;
			} else if (active && active !== true) {
				options.active = active;
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
      if (vendorID) {
        options.vendor = vendorID;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'name',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'sku',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'barcode',
                operator: 'LIKE',
                value: `'%${search}%'`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
      if (active === true) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'active',
                operator: '=',
                value: `1`,
              },
              {
                conjunction: 'OR',
                key: 'active',
                operator: '=',
                value: `2`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_PRODUCT}count${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response.result);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  listByWarehouse: async (
    warehouseID: string|null = null,
    active: boolean|null = null, 
    sort: any = { by: 'create_at', direction: 'desc' }, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null,
    vendorID: string|null = null,
    brandID: string|null = null,
    floor: string|null = null,
    aisle: string|null = null,
    bay: string|null = null,
    level: string|null = null,
    bin: string|null = null,
    returnReferences: any = false,
    groupByLocation: any = false
  ) => {
    return new Promise(async (resolve, reject) => {
      let options: any = {};
			if (active === true) {
			} else if (active === false) {
				options.active = false;
			} else if (active && active !== true) {
				options.active = active;
			}
			if (sort) {
        if (sort.by) {
          options.sort_by = Array.isArray(sort.by) ? sort.by.join(',') : sort.by;
        }
        if (sort.direction) {
          options.sort_direction = Array.isArray(sort.direction) ? sort.direction.join(',') : sort.direction;
        }
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (groupByLocation) {
				options.group_by = 'location';
			} else {
				options.group_by = null;
      }
      if (warehouseID) {
        options.warehouse = warehouseID;
      }
      if (vendorID) {
        options.vendor = vendorID;
      }
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'product.name',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'product.sku',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'product.barcode',
                operator: 'LIKE',
                value: `'%${search}%'`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
      if (active === true) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'product.active',
                operator: '=',
                value: `1`,
              },
              {
                conjunction: 'OR',
                key: 'product.active',
                operator: '=',
                value: `2`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
			if (brandID) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'product.brand',
                operator: '=',
                value: brandID,
              },
              {
                conjunction: 'OR',
                key: 'product.subbrand',
                operator: '=',
                value: brandID,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
			if (floor) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.floor',
            operator: 'LIKE',
            value: `'${floor}'`
          }
        );
        conjunction = 'AND';
			}
			if (aisle) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.aisle',
            operator: 'LIKE',
            value: `'${aisle}'`
          }
        );
        conjunction = 'AND';
			}
			if (bay) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.bay',
            operator: 'LIKE',
            value: `'${bay}'`
          }
        );
        conjunction = 'AND';
			}
			if (level) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.level',
            operator: 'LIKE',
            value: `'${level}'`
          }
        );
        conjunction = 'AND';
			}
			if (bin) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.bin',
            operator: 'LIKE',
            value: `'${bin}'`
          }
        );
        conjunction = 'AND';
			}
      
      await axios.post(
        RestService.route(`${URL_PRODUCT}list_by_warehouse${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  countByWarehouse: async (
    warehouseID: string|null = null,
    active: boolean|null = null, 
    start: number|null = null,
    limit: number|null = null,
    search: string|null = null,
    vendorID: string|null = null,
    brandID: string|null = null,
    floor: string|null = null,
    aisle: string|null = null,
    bay: string|null = null,
    level: string|null = null,
    bin: string|null = null,
    groupByLocation: any = false
  ) => {
    return new Promise(async (resolve, reject) => {

      let options: any = {};
			if (active === true) {
			} else if (active === false) {
				options.active = false;
			} else if (active && active !== true) {
				options.active = active;
			}
			if (limit) {
				options.limit = limit;
        if (start) {
          options.start = start;
        }
			}
			if (groupByLocation) {
				options.group_by = 'location';
			} else {
				options.group_by = null;
      }
      if (warehouseID) {
        options.warehouse = warehouseID;
      }
      if (vendorID) {
        options.vendor = vendorID;
      }
      let payload: any = {};
      let conjunction = '';
      payload.extensions = [];
			if (search) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'product.name',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'product.sku',
                operator: 'LIKE',
                value: `'%${search}%'`,
              },
              {
                conjunction: 'OR',
                key: 'product.barcode',
                operator: 'LIKE',
                value: `'%${search}%'`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
      if (active === true) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'product.active',
                operator: '=',
                value: `1`,
              },
              {
                conjunction: 'OR',
                key: 'product.active',
                operator: '=',
                value: `2`,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
			if (brandID) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            extensions: [
              {
                conjunction: '',
                key: 'product.brand',
                operator: '=',
                value: brandID,
              },
              {
                conjunction: 'OR',
                key: 'product.subbrand',
                operator: '=',
                value: brandID,
              }
            ]
          }
        );
        conjunction = 'AND';
			}
			if (floor) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.floor',
            operator: 'LIKE',
            value: `'${floor}'`
          }
        );
        conjunction = 'AND';
			}
			if (aisle) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.aisle',
            operator: 'LIKE',
            value: `'${aisle}'`
          }
        );
        conjunction = 'AND';
			}
			if (bay) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.bay',
            operator: 'LIKE',
            value: `'${bay}'`
          }
        );
        conjunction = 'AND';
			}
			if (level) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.level',
            operator: 'LIKE',
            value: `'${level}'`
          }
        );
        conjunction = 'AND';
			}
			if (bin) {
        await payload.extensions.push(
          {
            conjunction: conjunction,
            key: 'product_warehouse.bin',
            operator: 'LIKE',
            value: `'${bin}'`
          }
        );
        conjunction = 'AND';
			}

      await axios.post(
        RestService.route(`${URL_PRODUCT}count_by_warehouse${RestService.handleQuery(options)}`),
        payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response.result);
      }).catch((error) => {
        reject(error.message);
      });
      
    });
  },
  get: async (id: string|number, active: boolean|null = null, returnReferences: any = false) => {
		return new Promise(async (resolve, reject) => {

      let options: any = {};
      if (active === true) {
				options.active = true;
			} else if (active === false) {
				options.active = false;
			} else if (active) {
				options.active = active;
			}
			if (returnReferences === true) {
				options.return_references = '1';
			} else if (returnReferences) {
				options.return_references = Array.isArray(returnReferences) ? returnReferences.join(',') : returnReferences;
      }

      await axios.get(
        RestService.route(`${URL_PRODUCT}${id}${RestService.handleQuery(options)}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });
		});
  },
  create: async (values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.post(
        RestService.route(`${URL_PRODUCT}create`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  update: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;
      payload.id = id;

      await axios.put(
        RestService.route(`${URL_PRODUCT}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  delete: async (id: string|number) => {
		return new Promise(async (resolve, reject) => {

      await axios.delete(
        RestService.route(`${URL_PRODUCT}${id}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  patch: async (id: string|number, values: any, asFormData: boolean = false) => {
		return new Promise(async (resolve, reject) => {

			const payload: any = values;

      await axios.patch(
        RestService.route(`${URL_PRODUCT}${id}`),
        asFormData ? await RestService.createFormData(payload) : payload,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  upload: async (id: string|number, values: any) => {
		return new Promise(async (resolve, reject) => {

      await axios.post(
        RestService.route(`${URL_PRODUCT}${id}/upload`),
        await RestService.createFormData(values),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  removeFile: async (id: string, files: any) => {
		return new Promise(async (resolve, reject) => {

      await axios.delete(
        RestService.route(`${URL_PRODUCT}${id}/file${RestService.handleQuery(files)}`),
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
  import: async (file: File, vendorID: any) => {
		return new Promise(async (resolve, reject) => {

      let formData = new FormData();
      formData.append('csv', file);
      formData.append('vendor', vendorID);

      await axios.post(
        RestService.route(`${URL_PRODUCT}csv`),
        formData,
        { headers: await RestService.retrieveHeader(true) }
      ).then(async (response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error.message);
      });

		});
  },
};

export default ProductService;