import '../../libraries/slim/slim.min.css';
import '../../css/Modal.scss';

import { useEffect } from "react";

import { 
  useIonAlert
} from "@ionic/react";

import MESSAGE from '../../constances/Messages.json';
import BUTTON from '../../constances/Button.json';

export const AlertStatus: React.FC<{ 
  status: boolean;
  message?: string;
  isPresent?: boolean;
  isDismiss?: boolean;
  onDismiss?: (data?: any) => any; 
}> = ({ status, message = '', isPresent = false, isDismiss = false, onDismiss }) => {

  const [presentAlertSuccess, dismissAlertSuccess] = useIonAlert();
  const presentSuccess = async (message: string = '', icon: string = '') => {
    if (!message) {
      message = MESSAGE.success.complete;
    }
    let option: any = {
      message: `<div>${message}</div>`,
      translucent: true,
      buttons: [
        {
          text: BUTTON.ok,
          role: 'cancel'
        }
      ],
      cssClass: 'success',
      onDidDismiss: () => {
        onDismiss && onDismiss(true);
      }
    }
    presentAlertSuccess(option);
  }

  const [presentAlertError, dismissAlertError] = useIonAlert();
  const presentError = async (message: string = '', icon: string = '') => {
    if (!message) {
      message = MESSAGE.error.incomplete;
    }
    let option: any = {
      message: `<div>${message}</div>`,
      translucent: true,
      buttons: [
        {
          text: BUTTON.ok,
          role: 'cancel'
        }
      ],
      cssClass: 'error',
      onDidDismiss: () => {
        onDismiss && onDismiss(true);
      }
    }
    presentAlertError(option);
  }

  useEffect(() => {
    if (isPresent) {
      if (status === true) {
        presentSuccess(message);
      } else if (status === false) {
        presentError(message);
      }
    }
  }, [isPresent]);

  useEffect(() => {
    if (isDismiss === true) {
      if (status === true) {
        dismissAlertSuccess();
      } else if (status === false) {
        dismissAlertError();
      }
    }
  }, [isDismiss]);

  return (
    <></>
  );

};