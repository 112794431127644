import { useState } from "react";

import { 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonButtons, 
  IonButton, 
  IonIcon, 
  IonContent,
  IonBackButton,
  IonPage,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import { 
  saveOutline 
} from "ionicons/icons";

import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';

import { ManufacturerForm } from '../components/forms/ManufacturerForm';
import { GoBack } from '../components/RoutePath';

const ManufacturersAdd: React.FC = () => {

  const [doSubmit, setDoSubmit] = useState<boolean>(false);

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text={BUTTON.back} />
          </IonButtons>
          <IonTitle>{MENU.manufacturerAdd}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => { setDoSubmit(true); }}>
              <IonIcon slot="start" icon={saveOutline}/>
              {BUTTON.save}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='10' offset='0' offsetMd='1'>
              <ManufacturerForm isSubmit={doSubmit} onDidSubmit={(data: any) => { data && GoBack(); setDoSubmit(false) }} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

    </IonPage>
  );

};

export default ManufacturersAdd;