import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';

import {
  IonAccordion,
  IonAccordionGroup,
  IonBackButton,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSearchbar,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  SearchbarCustomEvent,
  useIonModal
} from '@ionic/react';
import {
  add,
  copyOutline,
  downloadOutline,
  trashOutline,
  createOutline,
  cloudDownloadOutline,
  cloudUploadOutline
} from 'ionicons/icons';

import moment from 'moment';

import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import MESSAGE from '../constances/Messages.json';

import { Direction, RoutePath } from '../components/RoutePath';
import { pageTable } from '../components/TableStyle';
import { ModalProductImport } from '../components/modal/ProductImport';
import { AlertPrompt } from '../components/alerts/AlertPrompt';
import { ToastStatus } from '../components/toasts/ToastStatus';

import UserService from '../services/UserService';
import VendorService from '../services/VendorService';
import ProductService from '../services/ProductService';

const Products: React.FC = () => {

  const offset: number = 50;

  const defaultSort: any = {
    by: ['active', 'sku'],
    direction: ['desc', 'asc']
  }

  const [vendor, setVendor] = useState<any>(null);
  const [products, setProducts] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [importResult, setImportResult] = useState<any>(null);
  const [columnsExport, setColumnsExport] = useState<any>({
    'SKU': true,
    'NAME_TH': true,
    'NAME_EN': true,
    'BARCODE': true,
    'BRAND': true,
    'SUBBRAND': true,
    'CATEGORY': true,
    'SHORT_DESCRIPTION': true,
    'DESCRIPTION': true,
    'IMAGES': true,
    'TAGS': true,
    'ATTRIBUTE_NAME_1': true,
    'ATTRIBUTE_VALUE_1': true,
    'ATTRIBUTE_NAME_2': true,
    'ATTRIBUTE_VALUE_2': true,
    'ATTRIBUTE_NAME_3': true,
    'ATTRIBUTE_VALUE_3': true,
    'ATTRIBUTE_NAME_4': true,
    'ATTRIBUTE_VALUE_4': true,
    'ATTRIBUTE_NAME_5': true,
    'ATTRIBUTE_VALUE_5': true,
    'WIDTH (CM)': true,
    'DEPTH (CM)': true,
    'HEIGHT (CM)': true,
    'BOX_WIDTH (CM)': true,
    'BOX_DEPTH (CM)': true,
    'BOX_HEIGHT (CM)': true,
    'CARTON_WIDTH (CM)': true,
    'CARTON_DEPTH (CM)': true,
    'CARTON_HEIGHT (CM)': true,
    'PIECE_PER_BOX': true,
    'PIECE_PER_CARTON': true,
    'BOX_PER_CARTON': true,
    'WEIGHT_PIECE (KG)': true,
    'WEIGHT_BOX (KG)': true,
    'WEIGHT_CARTON (KG)': true,
    'FLAMMABLE': true,
    'COLLAPSIBLE': true,
    'FRAGILE': true,
    'TEMPERATURE_CONTROL': true,
    'TEMPERATURE_MIN': true,
    'TEMPERATURE_MAX': true,
    'INSPECTION_CRITERIA': true,
    'LOT_CONTROL': true,
    'PACKING_REMARK': true,
    'QUANTITY': true
  });

  const [pageLimit, setPageLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<any>(defaultSort);
  const [total, setTotal] = useState<number>(0);

  const { register, getValues, setValue } = useForm();
  const [filterSearch] = useState<any>(
    register('search', {
      value: ''
    })
  );

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });
  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const columns: Array<any> = [
    {
      id: 'sku',
      name: TEXT.productSku,
      selector: (row: { sku: any }) => row.sku,
      cell: (row: { id: any, sku: string }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel color="medium">
            <p className='text-full'>{row.sku}</p>
          </IonLabel>
        </IonItem>
      ),
      minWidth: '220px',
      sortable: true,
    },
    {
      id: 'name',
      name: TEXT.productName,
      selector: (row: { sku: any }) => row.sku,
      cell: (row: { id: any, sku: string, name: string, figure: any, figure_reference: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.productView.replace(':id', row.id)}
          routerDirection={Direction()}>
          {
            row.figure_reference && row.figure_reference.length ?
              <IonThumbnail slot="end">
                <IonImg src={
                  row.figure_reference[0].thumbnail
                    ? row.figure_reference[0].thumbnail
                    : row.figure_reference[0].original
                } />
              </IonThumbnail>
              : <></>
          }
          <IonLabel>
            <h2 className='text-full'>{row.name}</h2>
          </IonLabel>
        </IonItem>
      ),
      minWidth: '300px',
      sortable: true,
    },
    {
      id: 'quantity',
      name: TEXT.qtyInStock,
      selector: (row: { quantity: any }) => row.quantity,
      cell: (row: { quantity: number }) => (
        <NumberFormat className={`text-number ${row.quantity <= 0 ? 'text-danger' : ''}`} value={row.quantity} displayType={'text'} thousandSeparator={true} />
      ),
      right: true,
      sortable: true,
      minWidth: '100px',
    },
    {
      id: 'active',
      name: TEXT.status,
      selector: (row: { active: any }) => row.active,
      cell: (row: { active: any }) => (
        row.active === '2' ?
          <IonLabel color="warning">&bull; {TEXT.productNeverAddASN}</IonLabel>
          :
          row.active === true ?
            <IonLabel color="success">&bull; {TEXT.productAddASN}</IonLabel>
            :
            <IonLabel color="danger">&bull; {TEXT.productInactive}</IonLabel>
      ),
      minWidth: '160px',
      center: true,
      sortable: true,
    },
    {
      id: 'action',
      cell: (row: { id: any, name: string, sku: string }) => (
        <IonButtons>
          <IonButton fill='outline' routerLink={RoutePath.productEdit.replace(':id', row.id)}
            routerDirection={Direction()}>
            <IonIcon slot="start" icon={createOutline} />
            <IonLabel>{BUTTON.edit}</IonLabel>
          </IonButton>
        </IonButtons>
      ),
      minWidth: '350px',
      right: true,
    }
  ];
  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const location = useLocation();

  const initialize = async () => {
    setInitialized(false);
    setShowLoading(true);
    setImportResult(null);
    const userData: any = await UserService.getSession();
    if (userData) {
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
        await load(0, pageLimit, defaultSort.by, defaultSort.direction, getValues('search'), vendorData.id);
      }).catch(() => { });
    }
    setInitialized(true);
    setShowLoading(false);
  }

  const load = async (
    start: number = 0,
    limit: number | null = pageLimit,
    sortBy: string = defaultSort.by,
    sortDirection: string = defaultSort.direction,
    search: string = getValues('search'),
    vendorID: any = (vendor ? vendor.id : null)
  ) => {
    if (vendorID) {
      await ProductService.count(
        true,
        null,
        null,
        search,
        vendorID
      ).then(async (rows: any) => {
        setTotal(rows);
        if (rows < offset) {
          start = 0;
          limit = null;
          sortBy = defaultSort.by;
          sortDirection = defaultSort.direction;
        }
        await ProductService.list(
          true,
          { by: sortBy, direction: sortDirection },
          start,
          limit,
          search,
          ['figure'],
          vendorID
        ).then((data: any) => {
          setProducts(data);
        }).catch((error) => {
          setPresentToast({
            isPresent: true,
            status: false,
            message: error,
            onDismiss: () => {
              if (ready) {
                setPresentToast(
                  {
                    isPresent: false,
                    status: presentToast.status,
                    message: presentToast.message,
                    onDismiss: presentToast.onDismiss
                  }
                );
              }
            }
          });
        });
      });
    }
  }

  const remove = async (id: any, name: string) => {

    const success = (result: any) => {
      setPresentToast({
        isPresent: true,
        status: true,
        message: MESSAGE.success.remove_complete,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
      for (let i = 0; i < products.length; i++) {
        if (result.id === products[i].id) {
          const data = [...products];
          data.splice(i, 1);
          setProducts(data);
          break;
        }
      }
    }

    setPresentAlert({
      isPresent: true,
      message: `${TEXT.removePrompt} "${name}"?`,
      onDismiss: (data: boolean) => {
        if (ready) {
          setPresentAlert(
            {
              isPresent: false,
              message: presentAlert.message,
              onDismiss: presentAlert.onDismiss
            }
          );
          if (data) {
            ProductService.patch(id, { active: false }).then(async (result: any) => {
              success(result);
            }).catch((error) => {
              setPresentToast({
                isPresent: true,
                status: false,
                message: error,
                onDismiss: () => {
                  if (ready) {
                    setPresentToast(
                      {
                        isPresent: false,
                        status: presentToast.status,
                        message: presentToast.message,
                        onDismiss: presentToast.onDismiss
                      }
                    );
                  }
                }
              });
            });
          }
        }
      }
    });

  }

  const downloadReport = async () => {

    setShowLoading(true);

    const searchText: string = getValues('search');

    const csv = async (content: any) => {

      let rows: any = [];

      await rows.push(
        await Object.keys(columnsExport).filter((item: any) => {
          return columnsExport[item] === true;
        })
      );

      for (let item of content) {

        let name_en = '';
        let attributes: any = [];

        if (item.attributes && item.attributes.length) {
          for (let attribute of item.attributes) {
            if (attribute.key === 'name_en') {
              name_en = attribute.value;
            } else if (attributes.length < 5) {
              await attributes.push(attribute);
            }
          }
        }

        let values = [
          (item.sku ? item.sku : ''),
          (item.name ? item.name : ''),
          (name_en ? name_en : ''),
          (item.barcode ? item.barcode : ''),
          (item.brand_reference ? item.brand_reference.name : ''),
          (item.subbrand_reference ? item.subbrand_reference.name : ''),
          (item.category_reference ? item.category_reference.name : ''),
          (item.short_description ? item.short_description : ''),
          (item.description ? item.description : ''),
          (item.figure_reference ?
            item.figure_reference.map((item_reference: any) => {
              return item_reference.original;
            }).join(',')
            : ''),
          (item.tags ? item.tags.join(',') : ''),
          (attributes.length && attributes[0] ? attributes[0].key : ''),
          (attributes.length && attributes[0] ? attributes[0].value : ''),
          (attributes.length && attributes[1] ? attributes[1].key : ''),
          (attributes.length && attributes[1] ? attributes[1].value : ''),
          (attributes.length && attributes[2] ? attributes[2].key : ''),
          (attributes.length && attributes[2] ? attributes[2].value : ''),
          (attributes.length && attributes[3] ? attributes[3].key : ''),
          (attributes.length && attributes[3] ? attributes[3].value : ''),
          (attributes.length && attributes[4] ? attributes[4].key : ''),
          (attributes.length && attributes[4] ? attributes[4].value : ''),
          (item.piece_dimension_width ? item.piece_dimension_width : ''),
          (item.piece_dimension_depth ? item.piece_dimension_depth : ''),
          (item.piece_dimension_height ? item.piece_dimension_height : ''),
          (item.box_dimension_width ? item.box_dimension_width : ''),
          (item.box_dimension_depth ? item.box_dimension_depth : ''),
          (item.box_dimension_height ? item.box_dimension_height : ''),
          (item.carton_dimension_width ? item.carton_dimension_width : ''),
          (item.carton_dimension_depth ? item.carton_dimension_depth : ''),
          (item.carton_dimension_height ? item.carton_dimension_height : ''),
          (item.piece_per_box ? item.piece_per_box : ''),
          (item.piece_per_carton ? item.piece_per_carton : ''),
          (item.box_per_carton ? item.box_per_carton : ''),
          (item.piece_weight ? item.piece_weight : ''),
          (item.box_weight ? item.box_weight : ''),
          (item.carton_weight ? item.carton_weight : ''),
          (item.flammable ? item.flammable : ''),
          (item.collapsible ? item.collapsible : ''),
          (item.fragile ? item.fragile : ''),
          (item.temperature_control ? item.temperature_control : ''),
          (item.temperature_min ? item.temperature_min : ''),
          (item.temperature_max ? item.temperature_max : ''),
          (item.inspection_criteria ? item.inspection_criteria : ''),
          (item.lot_control ? item.lot_control : ''),
          (item.packing_remark ? item.packing_remark : ''),
          (item.quantity ? item.quantity : '')
        ]

        await rows.push(
          await values.filter((item: any, index: number) => {
            return Object.values(columnsExport)[index] === true;
          }).map((item: any) => {
            return '"' + item.toString().replace(/"/g, '""') + '"';
          })
        );

      };

      let header = 'data:text/csv; charset=utf-8,';
      let downloadContent = '';
      for (let rowArray of rows) {
        let row = await rowArray.join(",");
        downloadContent += row + "\r\n";
      }

      const name = `${MENU.product}${vendor ? ` (${vendor.name})` : ''
        } - ${moment().format('yyyy-MM-DD')}${searchText ? ` [${TEXT.searchFor} ${searchText}]` : ''
        }.csv`;
      const universalBOM = "\uFEFF";
      const encodedUri = header + encodeURIComponent(universalBOM + downloadContent);
      let link = document.createElement("a");
      await link.setAttribute('href', encodedUri);
      await link.setAttribute('download', name);
      await document.body.appendChild(link);
      await link.click();

    }

    await ProductService.list(
      true,
      { by: 'product.sku', direction: 'asc' },
      null,
      null,
      searchText,
      ['figure'],
      vendor ? vendor.id : null
    ).then(async (data: any) => {
      await csv(data);
    }).catch((error) => {
      setPresentToast({
        isPresent: true,
        status: false,
        message: error,
        onDismiss: () => {
          if (ready) {
            setPresentToast(
              {
                isPresent: false,
                status: presentToast.status,
                message: presentToast.message,
                onDismiss: presentToast.onDismiss
              }
            );
          }
        }
      });
    });

    setShowLoading(false);

  }

  const handlePageChange = async (currentPage: number) => {
    setPage(currentPage);
    load((currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
  };

  const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
    setPage(currentPage);
    setPageLimit(newPerPage);
    load((currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSort({ by: column.id, direction: sortDirection });
    load((page - 1) * pageLimit, pageLimit, column.id, sortDirection);
  };

  const [presentImport, dismissImport] = useIonModal(ModalProductImport, {
    options: {
      canDismiss: true,
      swipeToClose: true
    },
    onDismiss: async (data: any) => {
      dismissImport();
      if (data) {
        setShowLoading(true);
        setImportResult(data);
        setPage(1);
        await load(0, pageLimit, sort.by, sort.direction);
        setShowLoading(false);
      }
    },
  });

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.product) {
      if (initialized) {
        load();
      }
    }
  }, [location]);

  return (
    <IonPage>

      <IonLoading
        isOpen={showLoading}
        message={TEXT.pleaseWait}
      />

      <AlertPrompt
        isPresent={presentAlert.isPresent}
        message={presentAlert.message}
        onDismiss={presentAlert.onDismiss}
      />

      <ToastStatus
        isPresent={presentToast.isPresent}
        status={presentToast.status}
        message={presentToast.message}
        onDismiss={presentToast.onDismiss}
      />

      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{MENU.product}</IonTitle>
          <IonButtons slot='end'>
            <IonButton routerLink={RoutePath.productAdd} slot="end" routerDirection={Direction()}>
              <IonIcon slot="start" icon={add} /> {BUTTON.add}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <IonGrid className='grid-main grid-has-ios-header'>
          <IonRow>
            <IonCol>
              <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large" slot="start">{MENU.product}</IonTitle>
                </IonToolbar>
              </IonHeader>
            </IonCol>
          </IonRow>
        </IonGrid>

        {
          vendor ?
            <IonGrid>
              <IonRow className='row-toolbar ion-margin-top'>
                <IonCol size='12' sizeMd='4'>
                  <IonToolbar className='toolbar-page'>
                    <IonItem lines="none">
                      <IonSearchbar
                        placeholder={`${TEXT.productName}/${TEXT.productSku}/${TEXT.barcode}`}
                        inputmode="search"
                        {...filterSearch}
                        onIonChange={(e: SearchbarCustomEvent) => { setValue('search', e.detail.value) }}
                        onKeyUp={(e: KeyboardEvent) => {
                          if (e.key === "Enter") {
                            load(
                              0,
                              pageLimit,
                              sort.by,
                              sort.direction,
                              (e.target as HTMLTextAreaElement).value,
                              vendor.id
                            );
                          }
                        }}
                        onIonClear={async () => {
                          setValue('search', '');
                          load(
                            0,
                            pageLimit,
                            sort.by,
                            sort.direction,
                            '',
                            vendor.id
                          );
                        }}
                      >
                      </IonSearchbar>
                    </IonItem>
                  </IonToolbar>
                </IonCol>
                <IonCol size='12' sizeMd='8'>
                  <IonToolbar className='toolbar-page'>
                    <IonButtons slot='end'>
                      <IonButton fill='outline' color="secondary" onClick={() => { downloadReport(); }}>
                        <IonIcon slot="start" icon={cloudDownloadOutline} /> {BUTTON.downloadAllProduct}
                      </IonButton>
                      <IonButton fill='outline' color="tertiary" onClick={() => { presentImport() }}>
                        <IonIcon slot="start" icon={cloudUploadOutline} /> {BUTTON.uploadCSV}
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonCol>
              </IonRow>
            </IonGrid>
            : <></>
        }

        {
          importResult ?
            <IonAccordionGroup>
              <IonAccordion value="creates">
                <IonItem slot="header" color="success">
                  <IonLabel>{importResult.creates ? importResult.creates.length : 0} {TEXT.creates}</IonLabel>
                </IonItem>
                {
                  importResult.creates && importResult.creates.length ?
                    <div className="ion-padding" slot="content">
                      {
                        importResult.creates.map((item: any, index: number) => (
                          <CopyToClipboard text={item.sku ? item.sku : ''} key={`creates-${index}`}
                            onCopy={() => {
                              setPresentToast({
                                isPresent: true,
                                status: true,
                                message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                onDismiss: () => {
                                  if (ready) {
                                    setPresentToast(
                                      {
                                        isPresent: false,
                                        status: presentToast.status,
                                        message: presentToast.message,
                                        onDismiss: presentToast.onDismiss
                                      }
                                    );
                                  }
                                }
                              });
                            }}>
                            <IonChip color="success">
                              <IonLabel>{item.sku ? item.sku : ''}</IonLabel>
                              <IonIcon icon={copyOutline}></IonIcon>
                            </IonChip>
                          </CopyToClipboard>
                        ))
                      }
                    </div>
                    : <></>
                }
              </IonAccordion>
              <IonAccordion value="updates">
                <IonItem slot="header" color="success">
                  <IonLabel>{importResult.updates ? importResult.updates.length : 0} {TEXT.updates}</IonLabel>
                </IonItem>
                {
                  importResult.updates && importResult.updates.length ?
                    <div className="ion-padding" slot="content">
                      {
                        importResult.updates.map((item: any, index: number) => (
                          <CopyToClipboard text={item.sku ? item.sku : ''} key={`updates-${index}`}
                            onCopy={() => {
                              setPresentToast({
                                isPresent: true,
                                status: true,
                                message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                onDismiss: () => {
                                  if (ready) {
                                    setPresentToast(
                                      {
                                        isPresent: false,
                                        status: presentToast.status,
                                        message: presentToast.message,
                                        onDismiss: presentToast.onDismiss
                                      }
                                    );
                                  }
                                }
                              });
                            }}>
                            <IonChip color="success">
                              <IonLabel>{item.sku ? item.sku : ''}</IonLabel>
                              <IonIcon icon={copyOutline}></IonIcon>
                            </IonChip>
                          </CopyToClipboard>
                        ))
                      }
                    </div>
                    : <></>
                }
              </IonAccordion>
              <IonAccordion value="errors">
                <IonItem slot="header" color="danger">
                  <IonLabel>{importResult.errors ? importResult.errors.length : 0} {TEXT.errors}</IonLabel>
                </IonItem>
                {
                  importResult.errors && importResult.errors.length ?
                    <div className="ion-padding" slot="content">
                      {
                        importResult.errors.map((item: any, index: number) => (
                          <CopyToClipboard text={item.sku ? item.sku : ''} key={`errors-${index}`}
                            onCopy={() => {
                              setPresentToast({
                                isPresent: true,
                                status: true,
                                message: `${item.sku ? item.sku : ''} ${TEXT.wasCopied}`,
                                onDismiss: () => {
                                  if (ready) {
                                    setPresentToast(
                                      {
                                        isPresent: false,
                                        status: presentToast.status,
                                        message: presentToast.message,
                                        onDismiss: presentToast.onDismiss
                                      }
                                    );
                                  }
                                }
                              });
                            }}>
                            <IonChip color="danger">
                              <IonLabel>{`${item.sku ? item.sku : ''} ${item.reason ? `(${item.reason})` : ''}`}</IonLabel>
                              <IonIcon icon={copyOutline}></IonIcon>
                            </IonChip>
                          </CopyToClipboard>
                        ))
                      }
                    </div>
                    : <></>
                }
              </IonAccordion>
            </IonAccordionGroup>
            : <></>
        }

        {
          initialized ?
            (total < offset) ?
              <DataTable
                columns={columns}
                data={products}
                customStyles={pageTable}
                pagination
                noDataComponent={TEXT.noDataComponent}
                paginationComponentOptions={paginationComponentOptions}
                defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                defaultSortFieldId={defaultSort.by}
              />
              :
              <DataTable
                columns={columns}
                data={products}
                customStyles={pageTable}
                pagination
                noDataComponent={TEXT.noDataComponent}
                paginationComponentOptions={paginationComponentOptions}
                paginationServer
                paginationTotalRows={total}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                sortServer
                defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                defaultSortFieldId={defaultSort.by}
                onSort={handleSort}
              />
            : <></>
        }

      </IonContent>
    </IonPage>
  );
};

export default Products;
