import { IonContent, IonPage, IonSearchbar, IonSlide, IonSlides, IonText, SearchbarCustomEvent } from '@ionic/react';
import React, { useState } from 'react';
import { IonRow, IonCol } from '@ionic/react';
import DataTable from 'react-data-table-component';

import TEXT from '../constances/Text.json';
import MENU from '../constances/Menu.json';

import { useBarcode } from 'react-barcodes';
import NumberFormat from 'react-number-format';
import { productData, productLot } from '../components/InterfaceData';
import { ToolbarWithBack } from '../components/ToolbarWithBack';

// interface ProductPageProps extends RouteComponentProps<{ id: string; }> { }

const ProductLot: React.FC = () => {

  const [searchText, setSearchText] = useState('');

  // Product Data
  const productData: productData =
  {
    id: '1',
    brand: 'Test',
    subBrand: 'Sub Test',
    nameTH: 'สินค้าทดสอบตัวอย่างที่ ' + '1',
    nameEN: 'Product Test Example ' + '1',
    category: 'เสื้อผ้า',
    sku: 'SKU000001',
    barcode: '8852509301220',
    cost: '70050',
    price: '10760',
    salePrice: '600',
    descriptionShort: 'Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16',
    description: 'Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง Lorem Ipsum อยู่ยงคงกระพันมาไม่ใช่แค่เพียงห้าศตวรรษ แต่อยู่มาจนถึงยุคที่พลิกโฉมเข้าสู่งานเรียงพิมพ์ด้วยวิธีทางอิเล็กทรอนิกส์ และยังคงสภาพเดิมไว้อย่างไม่มีการเปลี่ยนแปลง มันได้รับความนิยมมากขึ้นในยุค ค.ศ. 1960 เมื่อแผ่น Letraset วางจำหน่ายโดยมีข้อความบนนั้นเป็น Lorem Ipsum และล่าสุดกว่านั้น คือเมื่อซอฟท์แวร์การทำสื่อสิ่งพิมพ์ (Desktop Publishing) อย่าง Aldus PageMaker ได้รวมเอา Lorem Ipsum เวอร์ชั่นต่างๆ เข้าไว้ในซอฟท์แวร์ด้วย',

    manufacturerName: 'Name',
    manufacturerTaxID: 'TaxID',
    manufacturerAddress: 'Address',
    manufacturerSubDistrict: 'Sub District',
    manufacturerCity: 'City',
    manufacturerProvince: 'Bangkok',
    manufacturerPostcode: '10000',

    distributorName: 'Name',
    distributorTaxID: 'TaxID',
    distributorAddress: 'Address',
    distributorSubDistrict: 'Sub District',
    distributorCity: 'City',
    distributorProvince: 'Bangkok',
    distributorPostcode: '10000',

    images: [
      {
        id: 1,
        url: 'https://picsum.photos/400',
      },
      {
        id: 2,
        url: 'https://picsum.photos/400',
      },
      {
        id: 3,
        url: 'https://picsum.photos/400',
      },
      {
        id: 4,
        url: 'https://picsum.photos/400',
      },
      {
        id: 5,
        url: 'https://picsum.photos/400',
      },
    ],
    tag: [
      {
        id: 0,
        value: 'TEST_Piece',
      },
      {
        id: 1,
        value: 'TEST_Box',
      },
    ],
    attribute: [
      {
        id: 0,
        key: 'สี',
        value: 'ขาว',
      },
      {
        id: 1,
        key: 'ขนาด',
        value: 'XL',
      },
      {
        id: 2,
        key: 'ลาย',
        value: 'ต้นมะพร้าวกลางทะเลขอบฟ้าแดง',
      },
    ],

    widthPiece: '2',
    depthPiece: '3',
    heightPiece: '2',
    weightPiece: '1.5',

    widthBox: '20',
    depthBox: '30',
    heightBox: '20',
    weightBox: '5',

    widthCarton: '200',
    depthCarton: '300',
    heightCarton: '200',
    weightCarton: '8',

    piecePerBox: 10,
    piecePerCarton: 10,
    boxPerCarton: 10,

    flammable: 'true',
    collapsible: 'false',

    temperatureControl: 'true',
    temperatureMin: 10,
    temperatureMax: 23,

    inspectionCriteria: 'ตรวจๆ ไปเลยจ้า',
    packingRemark: 'พับสวยๆ หยิบเบาๆ ติดเทป 4 ด้าน ด้านละ 5 ซม.',
  };

  function randomNumberInRange(min: number, max: number) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const lotData: productLot[] = [
    {
      id: 0,
      lotNumber: 'AAAA0001',
      expireDate: '2022-05-31',
      location: 'KL04',
      qty: randomNumberInRange(50, 1000),
    },
    {
      id: 1,
      lotNumber: 'AAAA0002',
      expireDate: '2022-06-24',
      location: 'JG04',
      qty: randomNumberInRange(50, 1000),
    },
    {
      id: 2,
      lotNumber: 'AAAA0003',
      expireDate: '2022-07-31',
      location: 'TY04',
      qty: randomNumberInRange(50, 1000),
    },
    {
      id: 3,
      lotNumber: 'AAAA0004',
      expireDate: '2022-10-31',
      location: 'QR04',
      qty: randomNumberInRange(50, 1000),
    },
    {
      id: 4,
      lotNumber: 'AAAA0005',
      expireDate: '2022-12-31',
      location: 'BA04',
      qty: randomNumberInRange(50, 1000),
    },
    {
      id: 5,
      lotNumber: 'AAAA0006',
      expireDate: '2023-01-09',
      location: 'AL04',
      qty: randomNumberInRange(50, 1000),
    },
  ];

  // DataTable
  const columns = [
    {
      name: TEXT.lotNumber,
      selector: (row: { lotNumber: any; }) => row.lotNumber,
      sortable: true,
    },
    {
      name: TEXT.expireDate,
      selector: (row: { expireDate: any; }) => row.expireDate,
      sortable: true,
    },
    {
      name: TEXT.location,
      selector: (row: { location: any; }) => row.location,
      sortable: true,
    },
    {
      name: TEXT.qtyPiece,
      selector: (row: { qty: any; }) => row.qty,
      cell: (row: { qty: number }) => (
        <NumberFormat value={row.qty} displayType={'text'} thousandSeparator={true} />
      ),
      sortable: true,
    },
  ];

  //  Slide option product image
  const slideOptsImage = {
    initialSlide: 0,
    // speed: 400,
  };

  // BARCODE OPTION
  const { inputRef } = useBarcode({
    value: productData.barcode,
    options: {
      format: 'CODE128',
      // CODE128
      // EAN / UPC
      // CODE39
      // ITF-14
      // MSI
      // Pharmacode
      // Codabar
      // height: 50,
    }
  });

  return (
    <IonPage className='product-view'>
      <ToolbarWithBack menuName={MENU.productLotView} />

      <IonContent className="ion-padding">
        <IonRow className='product-info'>
          <IonCol size='12' sizeMd='8'>
            <div className='ion-margin'>
              <IonText color="medium">
                <p>{TEXT.productId} - 1</p>
                <p>{productData.brand} / {productData.subBrand}</p>
              </IonText>

              <h3>{productData.nameTH}</h3>
              <h4>{productData.nameEN}</h4>

              <IonText color="medium">
                <p>{TEXT.category}: {productData.category}</p>
                <p>{TEXT.productSku}: {productData.sku}</p>
              </IonText>

              <div>
                <svg className='barcode-product' ref={inputRef} />
              </div>
            </div>
          </IonCol>

          {/* IMAGE */}
          <IonCol size='12' sizeMd='4'>
            <IonSlides options={slideOptsImage} pager>
              {productData.images.map((data) => {
                return (
                  <IonSlide>
                    <img src={data.url} width="100%" height="auto"></img>
                  </IonSlide>
                )
              })}
            </IonSlides>
          </IonCol>
        </IonRow>
        <hr></hr>
        <IonRow className='ion-margin-top'>
          <IonCol size='12' sizeMd='4' offsetMd='8'>
            <IonSearchbar
              value={searchText}
              inputmode="search"
              onIonChange={(e: SearchbarCustomEvent) => setSearchText(e.detail.value!)}
            >
            </IonSearchbar>
          </IonCol>
          <IonCol size='12'>
            <DataTable
              columns={columns}
              data={lotData}
              pagination
            />
          </IonCol>
        </IonRow>

      </IonContent>
    </IonPage>
  );
};

export default ProductLot;