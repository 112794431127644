import { IonIcon } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";

const Error: React.FC<{header?: string; body?: string}> = ({header = 'Error', body = ''}) => {
  return (
    <div className="container-block">
      <div>
        <div>
          <IonIcon className="page-icon" slot="start" icon={alertCircleOutline} />
        </div>
        <h4>{header}</h4>
        {
          body &&
          <p>{body}</p>
        }
      </div>
    </div>
  );
};

export default Error;
