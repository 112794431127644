import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  SearchbarCustomEvent
} from '@ionic/react';

import moment from 'moment';

import TEXT from '../constances/Text.json';
import BUTTON from '../constances/Button.json';
import MENU from '../constances/Menu.json';
import COLORS from '../constances/Colors.json';
import STATUS from '../constances/Status.json';

import { Direction, RoutePath } from '../components/RoutePath';
import { pageTable } from '../components/TableStyle';
import { AlertPrompt } from '../components/alerts/AlertPrompt';
import { ToastStatus } from '../components/toasts/ToastStatus';

import OrderService from '../services/OrderService';
import UtilitiesService from '../services/UtilitiesService';
import UserService from '../services/UserService';
import VendorService from '../services/VendorService';

const ProductHistory: React.FC = () => {

  const offset: number = 50;

  const defaultSort: any = {
    by: ['order.create_at'],
    direction: 'desc'
  }

  const [vendor, setVendor] = useState<any>(null);

  const [members, setMembers] = useState<Array<any>>([]);
  const [orders, setOrders] = useState<Array<any>>([]);

  const [ready, setReady] = useState(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [sort, setSort] = useState<any>(defaultSort);
  const [total, setTotal] = useState<number>(0);

  const location = useLocation();

  const { register, getValues, setValue } = useForm();
  const [filterStatus] = useState<any>(
    register('status', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, e.detail.value, getValues('search'), getValues('dateFrom'), getValues('dateTo'));
        UtilitiesService.updateURL('status', e.detail.value);
      }
    })
  );
  const [filterSearch] = useState<any>(
    register('search', {
      value: ''
    })
  );
  const [filterDateFrom] = useState<any>(
    register('dateFrom', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('status'), getValues('search'), e.detail.value, getValues('dateTo'));
        UtilitiesService.updateURL('from', e.detail.value);
      }
    })
  );
  const [filterDateTo] = useState<any>(
    register('dateTo', {
      value: '',
      onChange: (e: any) => {
        load(0, pageLimit, sort.by, sort.direction, getValues('status'), getValues('search'), getValues('dateFrom'), e.detail.value);
        UtilitiesService.updateURL('to', e.detail.value);
      }
    })
  );

  const [presentToast, setPresentToast] = useState<{
    isPresent: boolean; status: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, status: false, message: '', onDismiss: () => { }
  });

  const [presentAlert, setPresentAlert] = useState<{
    isPresent: boolean; message?: string; onDismiss?: (data?: any) => any;
  }>({
    isPresent: false, message: '', onDismiss: () => { }
  });

  const columns: Array<any> = [
    {
      id: 'order.id',
      name: TEXT.orderNumber,
      selector: (row: { id: number }) => row.id,
      cell: (row: { id: any, job: any }) => (
        <IonItem lines="none" detail={false} routerLink={
          members.length && (
            members.includes('1')
            || members.includes('2')
            || members.includes('3')
          )
            ? RoutePath.jobView.replace(':id', row.job)
            : RoutePath.orderView.replace(':id', row.id)
        } routerDirection={Direction()}>
          <IonLabel className='ion-text-center'>
            {row.id}
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      minWidth: '120px'
    },
    {
      id: 'order.order_no',
      name: TEXT.reference,
      selector: (row: { id: number }) => row.id,
      cell: (row: { id: any, order_no: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.orderView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-center'>
            {row.order_no}
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      minWidth: '200px'
    },
    {
      id: 'order.order_date',
      name: TEXT.orderDateCreate,
      selector: (row: { create_at: any }) => row.create_at,
      cell: (row: { id: any; order_date: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.orderView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-center'>
            {row.order_date ? moment.utc(row.order_date).local().format('LL') : '-'}
            <div className='subtitle'>
              {row.order_date ? moment.utc(row.order_date).local().format('LT') : ''}
            </div>
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      minWidth: '200px'
    },
    {
      id: 'order.create_at',
      name: TEXT.orderDateDepart,
      selector: (row: { create_at: any }) => row.create_at,
      cell: (row: { id: any; create_at: any }) => (
        <IonItem lines="none" detail={false} routerLink={RoutePath.orderView.replace(':id', row.id)}
          routerDirection={Direction()}>
          <IonLabel className='text-full ion-text-center'>
            {row.create_at ? moment.utc(row.create_at).local().format('LL') : '-'}
            <div className='subtitle'>
              {row.create_at ? moment.utc(row.create_at).local().format('LT') : ''}
            </div>
          </IonLabel>
        </IonItem>
      ),
      center: true,
      sortable: true,
      minWidth: '200px'
    },
    {
      id: 'order.status',
      name: TEXT.status,
      selector: (row: { status: any }) => row.status,
      cell: (row: { id: any; status: any }) => (
        <IonButtons>
          <IonButton fill='solid' color={colors[row.status]}>
            <IonLabel>{statuses[row.status]}</IonLabel>
          </IonButton>
        </IonButtons>
      ),
      center: true,
      sortable: true,
      minWidth: '150px'
    }
  ];
  const paginationComponentOptions = {
    rowsPerPageText: TEXT.rowsPerPage,
    rangeSeparatorText: TEXT.rangeSeparator,
    selectAllRowsItemText: TEXT.selectAllRowsItem,
  };

  const colors: any = COLORS.order;
  const statuses: any = STATUS.order;

  const initialize = async () => {
    setInitialized(false);
    setShowLoading(true);

    const urlParams = new URLSearchParams(location.search);
    const typeParam = urlParams.get('type')
    if (typeParam) {
      await setValue('type', typeParam);
    }
    const statusParam = urlParams.get('status')
    if (statusParam) {
      await setValue('status', statusParam);
    }
    const searchParam = urlParams.get('search')
    if (searchParam) {
      await setValue('search', searchParam);
    }
    const dateFromParam = urlParams.get('from')
    if (dateFromParam) {
      await setValue('dateFrom', dateFromParam);
    }
    const dateToParam = urlParams.get('to')
    if (dateToParam) {
      await setValue('dateTo', dateToParam);
    }

    const userData: any = await UserService.getSession();
    if (userData) {
      setMembers(
        userData.members.map(
          ({ group_id }: any) => (
            group_id
          )
        )
      );
      await VendorService.getByUser(userData.id).then(async (vendorData: any) => {
        setVendor(vendorData);
        await load(
          0,
          pageLimit,
          defaultSort.by,
          defaultSort.direction,
          getValues('status'),
          getValues('search'),
          getValues('dateFrom'),
          getValues('dateTo'),
          vendorData.id,
        );
      }).catch(() => { });
    }

    setInitialized(true);
    setShowLoading(false);

  }

  const load = async (
    start: number = 0,
    limit: number | null = pageLimit,
    sortBy: string = defaultSort.by,
    sortDirection: string = defaultSort.direction,
    status: string = getValues('status'),
    search: string = getValues('search'),
    dateFrom: string = getValues('dateFrom'),
    dateTo: string = getValues('dateTo'),
    vendorID: any = vendor ? vendor.id : null
  ) => {
    const count = () => {
      return new Promise(async (resolve) => {
        await OrderService.countByVendor(
          vendorID,
          true,
          null,
          null,
          status,
          search,
          dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
          dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null
        ).then(async (rows: any) => {
          resolve(rows);
        });
      });
    }
    await count().then(async (rows: any) => {
      setTotal(rows);
      if (rows < offset) {
        start = 0;
        limit = null;
        sortBy = defaultSort.by;
        sortDirection = defaultSort.direction;
      }
      await OrderService.listByVendor(
        vendorID,
        true,
        { by: sortBy, direction: sortDirection },
        start,
        limit,
        status,
        search,
        dateFrom ? moment(`${dateFrom} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        dateTo ? moment(`${dateTo} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null
      ).then(async (data: any) => {
        setOrders(data);
      }).catch((error) => {
        setPresentToast({
          isPresent: true,
          status: false,
          message: error,
          onDismiss: () => {
            if (ready) {
              setPresentToast(
                {
                  isPresent: false,
                  status: presentToast.status,
                  message: presentToast.message,
                  onDismiss: presentToast.onDismiss
                }
              );
            }
          }
        });
      });
    });
  }

  const handlePageChange = async (currentPage: number) => {
    setPage(currentPage);
    load((currentPage - 1) * pageLimit, pageLimit, sort.by, sort.direction);
  };

  const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
    setPage(currentPage);
    setPageLimit(newPerPage);
    load((currentPage - 1) * newPerPage, newPerPage, sort.by, sort.direction);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    setSort({ by: column.id, direction: sortDirection });
    load((page - 1) * pageLimit, pageLimit, column.id, sortDirection);
  };

  useEffect(() => {
    setReady(true);
    initialize();
    return () => {
      setReady(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === RoutePath.order) {
      if (initialized) {
        load();
      }
    }
  }, [location]);

  return (
    <IonPage>

      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{MENU.productHistory}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <IonLoading
          isOpen={showLoading}
          message={TEXT.pleaseWait}
        />

        <AlertPrompt
          isPresent={presentAlert.isPresent}
          message={presentAlert.message}
          onDismiss={presentAlert.onDismiss}
        />

        <ToastStatus
          isPresent={presentToast.isPresent}
          status={presentToast.status}
          message={presentToast.message}
          onDismiss={presentToast.onDismiss}
        />

        <IonGrid className='grid-main grid-has-ios-header'>
          <IonRow>
            <IonCol>
              <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large" slot="start">{MENU.productHistory}</IonTitle>
                </IonToolbar>
              </IonHeader>
            </IonCol>
          </IonRow>
        </IonGrid>

        {
          initialized ?
            <>

              <IonGrid>
                <IonRow className='row-toolbar ion-margin-top'>
                  <IonCol size='12' sizeMd='3'>
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines='none'>
                        <IonSearchbar
                          placeholder={`${TEXT.orderNumber}/${TEXT.reference}`}
                          inputmode="search"
                          {...filterSearch}
                          onIonChange={(e: SearchbarCustomEvent) => { setValue('search', e.detail.value) }}
                          onKeyUp={(e: KeyboardEvent) => {
                            if (e.key === "Enter") {
                              load(
                                0, 
                                pageLimit, 
                                sort.by, 
                                sort.direction, 
                                getValues('status'), 
                                (e.target as HTMLTextAreaElement).value, 
                                getValues('dateFrom'), 
                                getValues('dateTo')
                              );
                              UtilitiesService.updateURL('search', (e.target as HTMLTextAreaElement).value);
                            }
                          }}
                          onIonClear={async () => {
                            setValue('search', '');
                            load(
                              0, 
                              pageLimit, 
                              sort.by, 
                              sort.direction, 
                              getValues('status'), 
                              '', 
                              getValues('dateFrom'), 
                              getValues('dateTo')
                            );
                            UtilitiesService.updateURL('search', '');
                          }}
                        >
                        </IonSearchbar>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                  <IonCol size='12' sizeMd='3'>
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines='none' className="item-hidden-label">
                        <IonLabel position="fixed">{TEXT.status}</IonLabel>
                        <IonSelect
                          interface="popover" okText={BUTTON.ok} cancelText={BUTTON.cancel}
                          placeholder={TEXT.status} value={getValues('status')}
                          {...filterStatus}
                          onIonChange={(e: any) => { filterStatus.onChange(e) }}>
                          <IonSelectOption value="">{TEXT.allStatus}</IonSelectOption>
                          {
                            Object.keys(statuses).map((item: any, index: number) => (
                              <IonSelectOption key={`status-${index}`} value={item}>{statuses[item]}</IonSelectOption>
                            ))
                          }
                        </IonSelect>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                  <IonCol size='12' sizeMd='3'>
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines='none'>
                        <IonLabel position="fixed">{TEXT.dateFrom}</IonLabel>
                        <IonInput type="date" value={getValues('dateFrom')}
                          {...filterDateFrom}
                          onIonChange={(e: any) => { filterDateFrom.onChange(e) }}>
                        </IonInput>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                  <IonCol size='12' sizeMd='3'>
                    <IonToolbar className='toolbar-page'>
                      <IonItem lines='none'>
                        <IonLabel position="fixed">{TEXT.dateTo}</IonLabel>
                        <IonInput type="date" value={getValues('dateTo')}
                          {...filterDateTo}
                          onIonChange={(e: any) => { filterDateTo.onChange(e) }}>
                        </IonInput>
                      </IonItem>
                    </IonToolbar>
                  </IonCol>
                </IonRow>
              </IonGrid>

              {
                (total < offset) ?
                  <DataTable
                    columns={columns}
                    data={orders}
                    customStyles={pageTable}
                    pagination
                    noDataComponent={TEXT.noDataComponent}
                    paginationComponentOptions={paginationComponentOptions}
                    defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                    defaultSortFieldId={defaultSort.by}
                  />
                  :
                  <DataTable
                    columns={columns}
                    data={orders}
                    customStyles={pageTable}
                    pagination
                    noDataComponent={TEXT.noDataComponent}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationServer
                    paginationTotalRows={total}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    sortServer
                    defaultSortAsc={defaultSort.direction === 'asc' ? true : false}
                    defaultSortFieldId={defaultSort.by}
                    onSort={handleSort}
                  />
              }

            </>
            : <></>
        }

      </IonContent>
    </IonPage>
  );
};

export default ProductHistory;